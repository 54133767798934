import { FETCH_MAILINGS_HISTORY, FETCH_MAILINGS_HOP_DETAILS, FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID } from 'state/mailings/history/actions'
import { start, resolved } from 'state/helpers'

const initialState = {
    fetching: false,
    fetchingMailingHopDetails: false,
    fetchingMailingHistoryDetails: false
}

export default (state = initialState, { type }) => {
	switch (type) {
		case start(FETCH_MAILINGS_HISTORY):
			return { ...state, fetching: true }
		case resolved(FETCH_MAILINGS_HISTORY):
            return { ...state, fetching: false }
        case start(FETCH_MAILINGS_HOP_DETAILS):
            return { ...state, fetchingMailingHopDetails: true }
        case resolved(FETCH_MAILINGS_HOP_DETAILS):
            return { ...state, fetchingMailingHopDetails: false }
        case start(FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID):
            return { ...state, fetchingMailingHistoryDetails: true }
        case resolved(FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID):
            return { ...state, fetchingMailingHistoryDetails: false }
		default:
			return state
	}
}
