import styled from 'styled-components'
import { Treemap, makeWidthFlexible } from 'react-vis'

const FlexibleTreemap = makeWidthFlexible(Treemap)

// Pulled styles from their included scss file
const StyledTreemap = styled(FlexibleTreemap)`
	position: relative;

	.rv-treemap {
		font-size: 12px;
		position: relative;
	}

	.rv-treemap__leaf {
		overflow: hidden;
		position: absolute;
		border: thin solid white;
		transition: transform 100ms;

		&:first-child {
			background: none !important;
		}

		&:not(:first-child):hover {
			transform: scale(1.01);
			z-index: 100;
			cursor: pointer;
		}
	}

	.rv-treemap__leaf--circle {
		align-items: center;
		border-radius: 100%;
		display: flex;
		justify-content: center;
	}

	.rv-treemap__leaf__content {
		overflow: hidden;
		padding: 10px;
		text-overflow: ellipsis;
		color: white;
	}
`

export default StyledTreemap