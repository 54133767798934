import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn, SearchField, } from 'react-bootstrap-table'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { feacthAllExceptionLogForAdmin } from 'state/admin/log/actions'
import { getAllExceptionLogForAdmin } from 'state/case-detail-view/admin/selectors'
import ViewLogDetailModalPopup from './view-logdetail-modalpopup'

const ManageExceptionLog = ({
    isFetchinAllExceptionLog,
    allExceptionLog = {},
    feacthAllExceptionLogForAdmin,
    userPermissions,
}) => {

    if (isFetchinAllExceptionLog) {
        allExceptionLog = [];
        allExceptionLog.excepionLogs = [];
        allExceptionLog.pageIndex = 0;
        allExceptionLog.pageSize = 10;
        allExceptionLog.sortColumn = 1;
        allExceptionLog.sortColumnDir = 'desc';
        allExceptionLog.totalCount = 0;
    }

    var {
        pageIndex = Object.keys(allExceptionLog).length > 0 ? allExceptionLog.pageIndex : 1,
        pageSize = Object.keys(allExceptionLog).length > 0 ? allExceptionLog.pageSize : 10,
        sortColumn = Object.keys(allExceptionLog).length > 0 ? allExceptionLog.sortColumn : 'errorNo',
        sortColumnDir = Object.keys(allExceptionLog).length > 0 ? allExceptionLog.sortColumnDir : 'desc',
        totalCount = Object.keys(allExceptionLog).length > 0 ? allExceptionLog.totalCount : 0,
        searchValue,
        data = [],
    } = allExceptionLog


    const [sortName, setSortName] = useState(Object.keys(allExceptionLog).length > 0 ? allExceptionLog.sortColumn : 'errorNo');
    const [sortOrder, setSortOrder] = useState(Object.keys(allExceptionLog).length > 0 ? allExceptionLog.sortColumndir : 'desc');

    const [Text, setText] = useState('')
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [filterType, setFilterType] = useState('E');
    const [showViewLogDetailModalPopup, setShowViewLogDetailModalPopup] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        try {

            allExceptionLog = [];
            feacthAllExceptionLogForAdmin(1, 10, sortName, sortOrder, null, filterType);
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [])


    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }


    const tableOptions = {
        toolBar: createCustomToolBar,
        sortIndicator: true,
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        onSortChange: onSortChange,
        page: pageIndex,
        sizePerPage: pageSize,
        sortName: sortName,
        sortOrder: sortOrder,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader()
    }

    function onSearchChange(searchText) {

        allExceptionLog = [];
        allExceptionLog.excepionLogs = [];

        searchValue = searchText
        setText(searchText)

        if (searchText == '') setText('')

        searchText = searchText == '' ? '' : searchText

        feacthAllExceptionLogForAdmin(1, pageSize, sortName, sortOrder, searchText, filterType);
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {

        allExceptionLog = [];
        allExceptionLog.excepionLogs = [];
        feacthAllExceptionLogForAdmin(page, sizePerPage, sortName, sortOrder, searchValue, filterType);
    }

    function onSizePerPageList(sizePerPage) {

        allExceptionLog = [];
        allExceptionLog.excepionLogs = [];
        feacthAllExceptionLogForAdmin(1, sizePerPage, sortName, sortOrder, searchValue, filterType);
    }


    function onSortChange(sortName, sortOrder) {


        allExceptionLog = [];
        allExceptionLog.excepionLogs = [];
        setSortName(sortName);
        setSortOrder(sortOrder);

        feacthAllExceptionLogForAdmin(1, pageSize, sortName, sortOrder, searchValue, filterType);
    }

    function showTableLoader() {
        if (isFetchinAllExceptionLog || showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }



    const onFilterChange = status => {

        allExceptionLog = [];
        allExceptionLog.excepionLogs = [];
        if (status == 'E') {
            setFilterType(status);
            feacthAllExceptionLogForAdmin(1, pageSize, sortColumn, sortColumnDir, searchValue, status);
        } else if (status == 'L') {
            setFilterType(status);
            feacthAllExceptionLogForAdmin(1, pageSize, sortColumn, sortColumnDir, searchValue, status);
        }
    };


    function actionFormatter(cell, row) {
        return (
            <a
                className="SimplurisBlueColor SimplurisPointer"
                href={null}
                onClick={() => handleCellClick(row)}
                title="View Log Detail"
            >
                <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
        );
    }


    const handleCellClick = (row) => {
        if (row) {
            OpenViewLogDetailModalPopUp(row.errorMessage);
        }
    }

    function OpenViewLogDetailModalPopUp(message) {
        setShowViewLogDetailModalPopup(true);
        setMessage(message);
    }

    const errorMessageColumnFormatter = (cell) => {        
        let messageLength = cell.length;
        if (messageLength >= 200) {
            cell = cell.toString().substring(0, 200)
            return  cell  + "..."
        }
        else {
            return cell;
        }
    }


    const defaultSorted = [
        {
            dataField: "errorNo",
            order: "desc"
        }
    ];


    return (
        <div>
            <div className="row mb-4">
                <div className="SimplurisExceptionGridFilterButtonWrraperDiv SimplurisExceptionManagementWrapperGrid">
                    {
                        <>
                            <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-0 mt-xl-0 mt-lg-2 ml-2 mr-0 mr-md-2 mr-xl-2 mr-lg-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                        ${filterType == 'E' ? 'active' : ''}`}
                                id="btnExceptionError"
                                onClick={e => {
                                    onFilterChange('E')
                                }}
                                aria-selected="false"> Type Error</button>
                            <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-0 mt-xl-0 mt-lg-2 ml-2 mr-0 mr-md-4 mr-xl-2 mr-lg-2 ml-0 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                        ${filterType == 'L' ? 'active' : ''}`}
                                id="btnExceptionLog"
                                onClick={e => {
                                    onFilterChange('L')
                                }}
                                aria-selected="false">Type Log</button>
                        </>}
                </div>
            </div>
            <div>
                {
                    Object.keys(allExceptionLog).length > 0 &&
                    <BootstrapTable
                        data={allExceptionLog.excepionLogs}
                        options={tableOptions}
                        tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover SimplurisMyUsersMainGrid SimplurisUserManagementAllUsersGridWrraper SimplurisExceptionManageGridWraper"                        
                        bodyContainerClass="text-grid-td"
                        hover
                        remote={true}
                        pagination={true}
                        search={true}
                        fetchInfo={{ dataTotalSize: totalCount }}
                        trClassName="SimplurisStatisticsGridMainTr SimplurisAdminCallReleaseChildMainTr"
                        defaultSorted={defaultSorted}
                    >
                        <TableHeaderColumn dataSort={true} isKey dataField='errorNo' width={'100'}>Error No.</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='className' width={'130'}>Class Name</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='errorMessage' dataFormat={errorMessageColumnFormatter}>Error Message</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='methodName' width={'125'}>Method Name</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='errorTypeCode' width={'140'}>Error Type Code</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='createdDate' width={'120'} dataFormat={createdDate => new Date(createdDate).toLocaleDateString()}>Created Date</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField=""
                            width="60"
                            dataFormat={actionFormatter}
                            searchable={false}
                        >Action</TableHeaderColumn>
                    </BootstrapTable>
                }
                <ViewLogDetailModalPopup OpenModalPopup={showViewLogDetailModalPopup} setShowViewLogDetailModalPopup={setShowViewLogDetailModalPopup} message={message} />
            </div>
        </div>
    )
}


export default connect(
    (state, props) => ({
        isFetchinAllExceptionLog: getAllExceptionLogForAdmin(state, props),
        allExceptionLog: state.adminData.AllLogs.adminExceptionLogObject,
        userPermissions: getPermissionsForCase(state, props)
    }),
    {
        feacthAllExceptionLogForAdmin,
    }
)(ManageExceptionLog)
