import { createSelector } from 'reselect'

const stateSelector = state => state.responses
const caseIDSelector = (state, { caseID }) => caseID

export const getFilters = createSelector(
  [stateSelector],
  ({ filters }) => filters
)

export const getResponsesForCase = createSelector(
  [stateSelector, caseIDSelector, getFilters],
  (state, caseID, filters) => {
    const responsesForCase = state[caseID] && state[caseID].responses

    if (!responsesForCase) {
      return null
    }
    
    return Object.values(responsesForCase)
      .filter(({ statusTypeCode }) => statusTypeCode !== 'Invalid')
      .filter(({ responseType }) => filters[responseType] !== false)
  }
)

export const getTotalRecordsForCase = createSelector(
  [stateSelector, caseIDSelector],
  (state, caseID) => state[caseID] && state[caseID].totalRecords
)

export const getDateRange = createSelector(
    [stateSelector],
    ({ dateRange }) => dateRange
)
