import React from 'react'
import Brand from '../header/brand'
import Nav from './nav'
import StatusIconBar from '../header/status-icon-bar'

export default function SecureHeader() {

    return <header id="header" className="page-header animate simplurisallcasesHeaderBackgroudColour bg-white sticky">
        <div className="navbar navbar-expand-lg SimplurisNavbar">
            <Brand />

            <Nav />

            <StatusIconBar />
        </div>
    </header>
}
