import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SectionSpinner } from 'react/components/spinners'
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function PDFReportViewer(PDFURL) {             
    const [numPages, setnumPages] = useState(null);
    const [pageNumber, setpageNumber] = useState(2);
    
    function onDocumentLoadSuccess(numPages) {                        
        setnumPages(numPages);
        localStorage.removeItem(global.ReportPDFURL);
        localStorage.removeItem(global.CaseIDForReportPDF);        
        localStorage.removeItem(global.ReportType);
        localStorage.removeItem(global.ReportCreatedDate);
        localStorage.removeItem(global.ReportTitle);
        localStorage.removeItem(global.OfficialCaseName);
    }  

    function ShowLoader() {        
        return <div className="row SimplurisPDFViewerSectionLoader"> <SectionSpinner /></div>
    }

    return (        
        <div>
            <div className="card SimplurisDashboardCard SimplurisPDFViewerWrapperDiv shadow-none">
                <div className="d-flex flex row">
                    <div className="col-md-12">
                        <div className="card d-flex flex p-3 mb-0 shadow-none">
                            <Document
                                file={`${PDFURL.PDFURL}`}                                
                                onLoadSuccess={() => onDocumentLoadSuccess()}
                                loading={() => ShowLoader()}
                                disableFontFace={false}
                            >
                                {[1, 2, 3,4,5,6,7,8,9,10].map(page => (
                                    <Page pageNumber={page} wrap={false} width="800"/>
                                ))}                              
                            </Document>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PDFReportViewer