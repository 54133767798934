export const CHANGE_DASHBOARD_CASE_FILTER = 'CHANGE_DASHBOARD_CASE_FILTER'
export const CHANGE_CASE_ACTIVITY_FILTER = 'CHANGE_CASE_ACTIVITY_FILTER'

export const changeDashboardCaseFilter = caseFilter => ({
	type: CHANGE_DASHBOARD_CASE_FILTER,
	caseFilter,
})

export const changeCaseActivityFilter = filter => ({
    type: CHANGE_CASE_ACTIVITY_FILTER,
    filter,
})
