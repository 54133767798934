import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../components/button-loader';
import { simplurisFetch } from 'helpers'
import { IsNullOrEmptyString } from '../../../global'
import DatePicker1 from  './datepicker/run-report-date-picker'
import LeftArrowPNG from  '../../../static/img/left-arrow.png'
import ConfirmCheckIcon from '../../../static/img/confirm_check_icon.png'
import { SectionSpinner } from '../../components/spinners'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let startDate = null, endDate = null;
var dataForReportSubscribed = {};
var setAmPmAfterBackFromCreateSubscription = '';
function AddSubscribeReportModalPopup(props) {
    
    const [reportSubscriptionTypeData, setReportSubscriptionTypeData] = useState([]);
    const [reportSubscriptionMilestones, setReportSubscriptionMilestones] = useState([]);
    const [milestonesValue, setMilestonesValue] = useState('');
    const [stopAfterDate, setstopAfterDate] = useState('');
    const [showButtonLoader, setShowButtonLoader] = useState(false);

    const [platformPersonID, setPlatformPersonID] = useState(props.platformPersonIDForReportSubscribe);

    const [caseID, setCaseID] = useState(props.caseIDForReportSubscribe);

    const [showPageLoader, setShowPageLoader] = useState(false);

    const [dropDownValue, setDropDownValue] = useState('');
    const [intervalTypeError, setIntervalTypeError] = useState(false);
    
    const [dayOff, setDayOff] = useState('');
    const [dayOffError, setDayOffError] = useState(false);
    const [ishideDayOfWeekddl, setIshideDayOfWeekddl] = useState(false);
    const [isShowInfoLableForMonthlySubscription, setIsShowInfoLableForMonthlySubscription] = useState(false);
    const [isShowDaynamicParamterModalPopUp, setIsShowDaynamicParamterModalPopUp] = useState(false);
    const [errorMessageForDynamicParamter, setErrorMessageForDynamicParamter] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [reportSubscriptionID, setReportSubscriptionID] = useState(0);
    const [isCreateSubscriptionDone, setIsCreateSubscriptionDone] = useState(false);
    
    const [ishideTimeOFDay, setIshideTimeOFDay] = useState(false);

    const [timeOfDay, setTimeOfDay] = useState('');
    const [timeOfDayError, setTimeOfDayError] = useState(false);

    const [hours, sethours] = useState('');
    const [hoursError, sethoursError] = useState(false);

    const [mintues, setmintues] = useState('');
    const [mintuesError, setmintuesError] = useState(false);

    const [paramResponseObj, setParamResponseObj] = useState([]);


    const [ampm, setampm] = useState(!IsNullOrEmptyString(setAmPmAfterBackFromCreateSubscription) ? setAmPmAfterBackFromCreateSubscription : 'AM');

    
    const [ErrorMessage, setErrorMessage] = useState('');

    function CloseReportSubscribedModalPopup() {
        setErrorMessage('');
        setDropDownValue('');
        setIntervalTypeError(false);

        setDayOff('');
        setDayOffError(false);

        setTimeOfDay('');
        setTimeOfDayError(false);

        sethours('');
        sethoursError(false);
        setmintues('');
        setmintuesError(false);
        setIshideDayOfWeekddl(false);
        setIshideTimeOFDay(false);
        setParamResponseObj([]);
        setIsShowInfoLableForMonthlySubscription(false);
        props.setShowReportSubscriptionModalPopup(false);
        props.setIsSubscribedReportByModalPopup(false);
    }

    function CloseRunReportTypeModalPopup() {
        
        setErrorMessage('');
        setSuccessMessage('');
        setErrorMessageForDynamicParamter('');
        startDate = '';
        endDate = '';
        setIsShowDaynamicParamterModalPopUp(false);
        setParamResponseObj([]);
        setdynamicTextBoxValues('');
        dataForReportSubscribed = {};
    }

    useEffect(() => {
        try {
            fetchAllSubscriptionReportType();
            fetchMilestonesForSubscriptionReport();
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [props.caseIDForReportSubscribe])


    const fetchAllSubscriptionReportType = async () => {
        simplurisFetch({
            url: `cases/bindreportsubscriptiontype`
        })
            .then(result => {
                if (result.resultCode == "SUCCESS") {
                    setReportSubscriptionTypeData(result.resultObject);
                }
            })
            .catch(e => {
                console.error("Error in fetch all reports for generate reports data ", e, e.errorObject);
            });
    }

    const fetchMilestonesForSubscriptionReport = async () => {
        simplurisFetch({
            url: `cases/${props.caseIDForReportSubscribe}/milestonesforreportsubscription`
        })
            .then(result => {
                if (result.resultCode == "SUCCESS") {
                    setReportSubscriptionMilestones(result.resultObject);
                }
            })
            .catch(e => {
                console.error("Error in fetch all reports for generate reports data ", e, e.errorObject);
            });
    }

    const setDropDownValueForMilestones = (e) => {
        setMilestonesValue(e.target.value);
    }

    const setDropDownValueForReportSubscribeType = (e) => {
        
        if (parseInt(e.target.value) == 2) {
            setIshideDayOfWeekddl(false);
            setIsShowInfoLableForMonthlySubscription(false);
        }
        else if (parseInt(e.target.value) == 3) {
            setIshideDayOfWeekddl(true);
            setIsShowInfoLableForMonthlySubscription(true);
        }
        else {
            setIsShowInfoLableForMonthlySubscription(false);
            setIshideDayOfWeekddl(true);
            setDayOff('');
        }


        if (parseInt(e.target.value) == 1) {
            setIshideTimeOFDay(false);
        }
        else if (parseInt(e.target.value) == 2) {
            setIshideTimeOFDay(false);
        }
        else {

            setIshideTimeOFDay(true);
            sethours('');
            setmintues('');
            setampm('');
        }

        setDropDownValue(e.target.value);
        setIntervalTypeError(false);
    }


    const setDropDownValueForHours = (e) => {
        sethours(e.target.value);
        sethoursError(false);
    }


    const setDropDownValueForMintues = (e) => {
        setmintues(e.target.value);
        setmintuesError(false);
    }

    const setDropDownValueForAMORPM = (e) => {
        setampm(e.target.value);
        sethoursError(false);
        setmintuesError(false);
    }

    const setDropdownValueForDayOfWeek = (e) => {
        setDayOff(e.target.value);
        setDayOffError(false);
    }   

    const saveIsSubscribedReportByUser = (reportID, caseID) => {
        dataForReportSubscribed = {};
        sethoursError(false);
        setmintuesError(false);
        if (!dropDownValue || (ishideDayOfWeekddl == false && !dayOff) || (ishideTimeOFDay == false && !hours) || (ishideTimeOFDay == false && !mintues)) {
            if (!dropDownValue) {
                setIntervalTypeError(true);
            }

            if (dropDownValue == "0") {
                setIntervalTypeError(true);
            }

            if (!dayOff) {
                setDayOffError(true);
            }

            if (!hours) {
                sethoursError(true);
            }

            if (!mintues) {
                setmintuesError(true);
            }

            return;
        }

        dataForReportSubscribed.CaseID = caseID.toString();
        dataForReportSubscribed.PlatformPersonID = parseInt(props.platformPersonIDForReportSubscribe);
        dataForReportSubscribed.ReportID = parseInt(reportID);
        dataForReportSubscribed.IsAdminByUpdateReportSubcribe = true;
        dataForReportSubscribed.IntervalDayOfWeek = !dayOff ? 0 : parseInt(dayOff);
        dataForReportSubscribed.ReportSubscriptionTypeID = parseInt(dropDownValue);
        dataForReportSubscribed.IntervalTimeOfDayForReportSubscribe = ((hours != "" && mintues != "" && ampm != "") ? (hours + ":" + mintues + " " + ampm) : 'null');
        dataForReportSubscribed.IsSubscribeReport = true;

        props.setShowReportSubscriptionModalPopup(false);
        props.setIsSubscribedReportByModalPopup(false);
        setIsShowDaynamicParamterModalPopUp(true);
        
        fetchParamsForReportByReportCode(props.reportCodeForSaveReportSubscribe);
    }


    function BackOpenChooseReportTypeModalPopup() {
        
        dataForReportSubscribed = {};
        setErrorMessageForDynamicParamter('');
        setSuccessMessage('');
        startDate = '';
        endDate = '';
        setParamResponseObj([]);
        setdynamicTextBoxValues('');
        setIsShowDaynamicParamterModalPopUp(false);
        
        setDayOff(dayOff);
        setDropDownValue(dropDownValue);
        sethours(hours);
        setmintues(mintues);
        setAmPmAfterBackFromCreateSubscription = ampm;
        //setampm(ampm);
        props.setShowReportSubscriptionModalPopup(true);
    }

    function updateDateRangeFilter(dateRange) {
        startDate = dateRange.start;
        endDate = dateRange.end;
    }

    function handleStopAfterDate(date) {
        setstopAfterDate(date);
    };

    const [dynamicTextBoxValues, setdynamicTextBoxValues] = useState('');

    function handleChange(reportParamData, i, e) {
        const { name, value } = e.target;
        let data = [...dynamicTextBoxValues];
        data[i] = { ...data[i], 'Value': value, 'Title': reportParamData.title, 'ReportParamID': reportParamData.reportParamID };
        setdynamicTextBoxValues(data);
    }

    const fetchParamsForReportByReportCode = async (reportCode) => {
        
        setShowPageLoader(true);
        simplurisFetch({
            url: `report/getparamsforreportbyreportcode/${reportCode}`
        }).then(result => {
            setShowPageLoader(false);

            if (result.resultCode == "SUCCESS") {
                
                setParamResponseObj(result.resultObject);
                setIsShowDaynamicParamterModalPopUp(true);
            }
        }).catch(e => {

            setShowPageLoader(false);
            console.error("Error in getparamsforreportbyreportcode ", e, e.errorObject);
        });
    }

    const CreateSubscription = async (caseID, reportID, reportTitle, reportCode) => {
        
        var array = [];
        
        for (var i = 0; i < paramResponseObj.length; i++) {
            
            var value = '';
            if (paramResponseObj[i].title == 'CaseID') {

                value = caseID;
            }
            else if (paramResponseObj[i].title == 'StartDate') {

                value = startDate;
            }
            else if (paramResponseObj[i].title == 'EndDate') {

                value = endDate;
            }
            else {

                for (var j = 0; j < dynamicTextBoxValues.length; j++) {

                    if (dynamicTextBoxValues[j] != undefined && paramResponseObj[i].reportParamID == dynamicTextBoxValues[j].ReportParamID
                        && paramResponseObj[i].title == dynamicTextBoxValues[j].Title) {

                        value = dynamicTextBoxValues[j].Value
                    }
                }
            }

            
            array.push({
                'ReportParamID': paramResponseObj[i].reportParamID,
                'ReportID': paramResponseObj[i].reportID,
                'ReportParamDataTypeID': paramResponseObj[i].reportParamDataTypeID,
                'ReportParamDataTypeTitle': paramResponseObj[i].reportParamDataTypeTitle,
                'Title': paramResponseObj[i].title,
                'Subtext': paramResponseObj[i].subtext,
                'AllowNull': paramResponseObj[i].allowNull,
                'Value': value
            })
        }

        
        dataForReportSubscribed.ReportCode = reportCode;
        dataForReportSubscribed.ReportsParams = JSON.stringify(array);
        dataForReportSubscribed.OptionalEndAtMilestoneID = parseInt(milestonesValue);
        if(stopAfterDate !== ''){
            dataForReportSubscribed.StopAfterDate = stopAfterDate;
        }
        
        setShowButtonLoader(true);
        simplurisFetch({
            url: `report/createsubscription`,
            method: 'POST', body: JSON.stringify(dataForReportSubscribed)
        }).then(data => {
            
            setShowButtonLoader(false);
            if (data.name == undefined && data.name != "FetchError") {
                if (data.resultCode == "SUCCESS") {
                    
                    startDate = '';
                    endDate = '';
                    dataForReportSubscribed = {};
                    setIsShowDaynamicParamterModalPopUp(false);
                    setIsCreateSubscriptionDone(true);
                }
            }
        }).catch(e => {
            
            setShowButtonLoader(false);
            console.error("Error in CreateSubscription ", e, e.errorObject);
            if (e.errorObject.resultMessage == "Object reference not set to an instance of an object.")
                setErrorMessageForDynamicParamter("Something went wrong.");
            else
                setErrorMessageForDynamicParamter(e.errorObject.resultMessage);
        });
    }

    function closeConfirmationReportSubscripionModalPopup() {
        setAmPmAfterBackFromCreateSubscription = '';
        setIsCreateSubscriptionDone(false);
        if (props.isTabFrom == "Mycases") {
            
            props.getAllSubscribedReportByUserID(props.platformPersonIDForReportSubscribe, props.caseIDForReportSubscribe, 1, 10, '');
        }
        else {
            props.getAllSubscribedReportByUserID(props.platformPersonIDForReportSubscribe, 1, 10, '');
        }
    }


    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseReportSubscribedModalPopup} size="lg" data-keyboard="false" data-backdrop="static">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">
                            Subscribe to {props.reportTitleForSaveReportSubscribe}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 SimplurisAddReportSubscribeModalPopUpBody">
                    <div className="row">
                        <div className="col-md-12 mb-1">
                            {ErrorMessage != "" ? <ErrorPlacement items={ErrorMessage} /> : ''}
                            <p className="modal-title SimplurisFontSize19 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                Case: {props.caseIDForReportSubscribe} - {localStorage.getItem(global.caseOfficialName)}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex row mt-2">
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className={`form-group position-relative ${intervalTypeError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Interval Type</label>
                                <div className="position-relative">
                                    {
                                        <select value={dropDownValue} className="form-control SimplurisNewCaseMemebersInputBox"
                                            onChange={setDropDownValueForReportSubscribeType}>
                                            <option value="0">Select Interval Type</option>
                                            {reportSubscriptionTypeData.map(item => (
                                                <option value={item.reportSubscriptionTypeID}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                    {intervalTypeError ? <span>Please select interval type</span> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${isShowInfoLableForMonthlySubscription == true ? '' : 'hide'}`}>
                        <i class="fa fa-info-circle" aria-hidden="true"></i> The report will generate on the 1st of each month at 12 noon pacific time.
                    </div>
                    <div className={`d-flex flex row mt-2 ${ishideDayOfWeekddl == true ? 'hide' : ''}`}>
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className={`form-group ${dayOffError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Day of Week</label>
                                <div className="position-relative">
                                    <select value={dayOff} className="form-control SimplurisNewCaseMemebersInputBox"
                                        onChange={setDropdownValueForDayOfWeek}>
                                        <option value="">Day of Week</option>
                                        <option value="1">Sunday</option>
                                        <option value="2">Monday</option>
                                        <option value="3">Tuesday</option>
                                        <option value="4">Wednesday</option>
                                        <option value="5">Thursday</option>
                                        <option value="6">Friday</option>
                                        <option value="7">Saturday</option>
                                    </select>
                                    {dayOffError ? <span>Please select the day of week</span> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex flex row mt-2 ${ishideTimeOFDay == true ? 'hide' : ''}`}>
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className="form-group">
                                <label className="SimplurisMutableTitle">Time of Day (Pacific Time, California)</label>
                                <div className="position-relative">
                                    <div className="d-flex">
                                        <select value={hours} className={`form-control SimplurisNewCaseMemebersInputBox ${hoursError ? 'has-error' : ''}`}
                                            onChange={setDropDownValueForHours}>
                                            <option value="">Hour</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                        <select value={mintues} className={`form-control SimplurisNewCaseMemebersInputBox ml-2 ${mintuesError ? 'has-error' : ''}`}
                                            onChange={setDropDownValueForMintues}>
                                            <option value="">Minute</option>
                                            <option value="00">00</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                            <option value="32">32</option>
                                            <option value="33">33</option>
                                            <option value="34">34</option>
                                            <option value="35">35</option>
                                            <option value="36">36</option>
                                            <option value="37">37</option>
                                            <option value="38">38</option>
                                            <option value="39">39</option>
                                            <option value="40">40</option>
                                            <option value="41">41</option>
                                            <option value="42">42</option>
                                            <option value="43">43</option>
                                            <option value="44">44</option>
                                            <option value="45">45</option>
                                            <option value="46">46</option>
                                            <option value="47">47</option>
                                            <option value="48">48</option>
                                            <option value="49">49</option>
                                            <option value="50">50</option>
                                            <option value="51">51</option>
                                            <option value="52">52</option>
                                            <option value="53">53</option>
                                            <option value="54">54</option>
                                            <option value="55">55</option>
                                            <option value="56">56</option>
                                            <option value="57">57</option>
                                            <option value="58">58</option>
                                            <option value="59">59</option>
                                        </select>

                                        <select value={ampm} className="form-control SimplurisNewCaseMemebersInputBox ml-2"
                                            onChange={setDropDownValueForAMORPM}>
                                            <option value="AM" >AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                    </div>
                                    {/*{mintuesError && hoursError ? <span className={`spnErrorLable ${mintuesError && hoursError ? 'has-error' : ''}`}>Please select time of week</span> : null}                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex row mt-2">
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className="form-group position-relative">
                                <label className="SimplurisMutableTitle">End Subscription After a Milestone (optional)</label>
                                <div className="position-relative">
                                    {
                                        <select value={milestonesValue} className="form-control SimplurisNewCaseMemebersInputBox"
                                            onChange={setDropDownValueForMilestones}>
                                            <option value="0">Select a Milestone (Optional)</option>
                                            {reportSubscriptionMilestones.map(item => (
                                                <option value={item.milestoneID}>
                                                    {item.milestoneTitle}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex row mt-2">
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className="form-group position-relative">
                                <label className="SimplurisMutableTitle">End Subscription After Date </label>
                                <div className="position-relative">
                                    {
                                        // <StopDatePicker className="form-control SimplurisNewCaseMemebersInputBox"
                                        // setPickerDate ={setStopAfterDate} />
                                        <DatePicker
                                            selected={stopAfterDate}
                                            dateFormat="yyyy-MM-dd"
                                            minDate={new Date()}
                                            onChange={handleStopAfterDate}
                                            className="form-control SimplurisNewCaseMemebersInputBox"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div className="row">
                        <div className="col-12">
                            <button type="button" disabled={showButtonLoader} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor btn-light w-xs py-3 mb-4 mt-3" onClick={() => saveIsSubscribedReportByUser(props.reportIDForReportSubscribe, props.caseIDForReportSubscribe)}>
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Next</span>}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isShowDaynamicParamterModalPopUp} onHide={CloseRunReportTypeModalPopup} size="lg" className="SimpluriRunReportTypeModalPopup" data-keyboard="false" data-backdrop="static">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle SimplurisFontSize22">
                            <a href={null} onClick={() => BackOpenChooseReportTypeModalPopup()}>
                                <img src={LeftArrowPNG} width="25px" className="pr-2 pb-2" /></a>
                            Create Subscription for {props.reportTitleForSaveReportSubscribe}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                {showPageLoader && <div className="row"><div className="col-md-11 ml-3"><SectionSpinner height='auto' /></div></div>}
                <Modal.Body className="px-5 SimplurisRunReportModalPopUpBody">
                    {successMessage != "" ? <SuccessPlacement items={successMessage} /> : ''}
                    {errorMessageForDynamicParamter != "" ? <ErrorPlacementForDynamicParameter items={errorMessageForDynamicParamter} /> : ''}
                    <div className="d-flex flex row mt-2">
                        <div className="col-12 col-md-6 col-xl-6 col-lg-6">
                            <div className="form-group">
                                <div className="SimlurisReportTypeLabel SimplurisFontSize17">Report Parameters
                                </div>
                                <div className="SimlurisReportTypeLabel mb-1">Case ID: #{props.caseIDForReportSubscribe}
                                </div>
                            </div>

                            {

                                paramResponseObj.length > 0 && paramResponseObj.map((item, i) =>
                                    <>
                                        {(
                                            () => {
                                                if (item.title != 'CaseID' && item.title != 'EndDate') {
                                                    if (item.reportParamDataTypeTitle == 'Integer' || item.reportParamDataTypeTitle == 'Decimal') {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">{item.title}:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <input type="number" className="form-control SimplurisNewCaseMemebersInputBox" onChange={e => handleChange(item, i, e)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    else if (item.reportParamDataTypeTitle == 'DateRange' && item.title == 'StartDate') {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">Date Range:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <DatePicker1 updateDateRangeFilter={updateDateRangeFilter} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    else if (item.reportParamDataTypeTitle == 'Boolean') {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">{item.title}:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <label className="ui-switch ui-switch-md mt-1 mr-2">
                                                                        <input type="checkbox" onChange={e => handleChange(item, i, e)} className="SimplurisNewCaseMemebersInputBox" />
                                                                        <i></i>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    else {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">{item.title}:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <input type="text" className="form-control SimplurisNewCaseMemebersInputBox" onChange={e => handleChange(item, i, e)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                }
                                            }
                                        )()}

                                    </>
                                )
                            }

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="button" disabled={showButtonLoader} onClick={() => CreateSubscription(props.caseIDForReportSubscribe, props.reportIDForReportSubscribe, props.reportTitleForSaveReportSubscribe, props.reportCodeForSaveReportSubscribe)} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor SimplurisFontSize14 SimplurisFontWeight400 w btn-light py-3 mb-4 mt-2">
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Create Subscription</span>}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={isCreateSubscriptionDone} onHide={closeConfirmationReportSubscripionModalPopup} backdrop="static" size="lg" data-keyboard="false" data-backdrop="static">
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body className="px-5">
                    <div className="text-center p-5">
                        <div className="pb-2">
                            <img src={ConfirmCheckIcon} />
                        </div>
                        <div className="SimplurisFontSize26 text-black pb-5 mb-3">
                            Report Subscription  Created
                                </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddSubscribeReportModalPopup

function ErrorPlacement(data) {
    if (data.items != "") {
        return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
            {data.items}
        </div>
    }
}

function ErrorPlacementForDynamicParameter(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvFailedMessage" className="alert alert-danger" role="alert">
            <button type="button" className="close SimpluriAlertMessageCloseIcon" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}

function SuccessPlacement(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvsuccessMessage" className="alert alert-success" role="success">
            <button type="button" className="close SimpluriAlertMessageCloseIcon" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}

