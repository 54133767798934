import React from 'react'
import ContentLoader from 'react-content-loader'

const CircleFakeLoader = () => (
    <ContentLoader
        height={50}
        width={200}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb" >
        <rect x="50" y="22" rx="4" ry="4" width="85" height="5" />
        <rect x="50" y="33" rx="3" ry="3" width="110" height="5" />
        <circle cx="30" cy="30" r="15" />
    </ContentLoader>
)

export default CircleFakeLoader
