import { simplurisFetch } from 'helpers'

export const FETCH_CASES_STAGECOUNT = 'FETCH_CASES_STAGECOUNT'

//Fetch Cases Stage Counts
export const fetchCasesCountByStage = () => async dispatch => {
    try {
        
        const url = 'cases/countsbystage'
        let { resultObject } = await simplurisFetch({ url })
        
        dispatch({
            type: FETCH_CASES_STAGECOUNT,
            casesStageCount: resultObject
        })
    } catch (e) {
        
        console.error('Error in fetch cases stage count', e, e.errorObject);
        
        dispatch({
            type: FETCH_CASES_STAGECOUNT,
            casesStageCount: [],
        })
    }
}
