import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { labFeatures } from '../../../../api-config'
import { getIsUserSysAdmin } from 'state/sign-in/selectors';

function Nav({ IsStatusPage, userIsSysAdmin }) {
    return (
        <div
            className="collapse navbar-collapse order-2 mt-3 mt-lg-0 order-lg-1"
            id="navbarToggler"
        >
            <ul className="navbar-nav SimplurismarginLeft170" data-nav>
                {localStorage.getItem(global.viewDashBoardpermission) !== "" &&
                    <li className="nav-item">
                        <NavLink
                            to={'/dashboard'}
                            activeClassName="active"
                            className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700 nav-link-transition"
                        >
                            <span className="nav-text">Dashboard</span>
                        </NavLink>
                    </li>
                }
                <li className="nav-item">
                    <NavLink
                        to={'/cases'}
                        activeClassName="active"
                        className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700 nav-link-transition"
                    >
                        <span className="nav-text">My Cases</span>
                    </NavLink>
                </li>
                {localStorage.getItem(global.IsSysAdmin) == "true" &&
                    <li className="nav-item">
                        <NavLink
                            to={'/admin'}
                            activeClassName="active"
                            className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700 nav-link-transition"
                        >
                            <span className="nav-text">Admin</span>
                        </NavLink>
                    </li>
                }
                {/*IsStatusPage ?
                    <li className="nav-item">
                        <NavLink
                            to={'/api-status'}
                            activeClassName="active"
                            className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700 nav-link-transition"
                        >
                            <span className="nav-text">System Status</span>
                        </NavLink>
                    </li> : null
                */}
                {/*labFeatures &&
                    <>
                        <li className="nav-item">
                            <a
                                href={null}
                                className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700 nav-link-transition"
                            >
                                <span className="nav-text">Services</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href={null}
                                className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700 nav-link-transition"
                            >
                                <span className="nav-text">Research</span>
                            </a>
                        </li>
                    </>
                */}
            </ul>
        </div>
    )
}

export default connect(
    (state, props) => ({
       
    }),
    {}
)(Nav)