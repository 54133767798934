import _ from 'lodash'

import { CHANGE_CASE_STAGE_FILTER } from './actions'
import { FETCH_CASE } from 'state/cases/data/actions'

const initialState = {
  caseStageFilter: 0,
  recentlyViewedCases: [],
}

export default (
  state = initialState,
  { type, caseStageFilter, singleCase, caseID }
) => {
  switch (type) {
    case CHANGE_CASE_STAGE_FILTER:
      return { ...state, caseStageFilter }
    case FETCH_CASE:
      const name =
        _.get(singleCase, 'details.officialName') ||
        _.get(singleCase, 'details.name', '')

      const updatedCases = [
        {
          name,
          ID: caseID,
        },
        ...state.recentlyViewedCases,
      ]
      const uniqueCases = _.uniqBy(updatedCases, 'ID')

      return {
        ...state,
        recentlyViewedCases: uniqueCases.slice(0, 3),
      }
    default:
      return state
  }
}
