import * as React from 'react'
import { connect } from 'react-redux'

import TeamMembers from './team-members'
import CaseTeamMemberDetail from './case-teammember-detail'
import Milestones from './milestones'
import CaseMembers from './case-members'
import CaseMemberDetail from './case-member-detail'
import CaseInfo from './case-info'
import LiveReport from './live-report'
import CaseDetailActivity from './case-detail-activity'
import MailTracking from './mail-tracking'
import Responses from './responses'
import Distributions from './distributions'
import CallCenter from './call-center'
import CaseReports from './case-reports'
import Files from './case-files'
import { AnlyticsPageLog } from "../../../../global";
import { getPermissionsForCase } from 'state/permissions/selectors'
import { setTimeout } from 'timers';
import CaseFileDetails from './case-files/files-detail'
import CaseReportsDetails from './case-reports/report-detail'
import CaseCampaigns from './campaigns'
import CaseCampaignDetail from './campaigns/campaign-detail'

const CaseDetailContent = ({
    caseSubtab,
    selectedMilestones,
    nextRemainingMilestones,
    mileStones,
    teamMembers,
    caseID,
    getTeamMemberComponent,
    teamMemberID,
    caseObject,
    caseActivities,
    hasNewPage,
    LoadMoreActivity,
    showButtonLoader,
    getCaseMemberDetailComponent,
    tabType,
    IscaseDetail,    
    userPermissions,
    getSecureFileDetailComponent,
    getReportsDetailComponent,
    secureFileID,
    caseSecureFileData,
    reportID,
    caseReportData,    
    reportTitle,
    reportGeneratedDate,
    updateSidebarMenuCounts,
    getCampaignDetailComponent,
    hash,
    campaignName,
    selectCampaignCaseID
}) => {
    var  viewLiveReportScreenPermission = userPermissions.find(
        (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_IN_MENU'
    )   
    
    if (caseSubtab == null) {        
        if (viewLiveReportScreenPermission && viewLiveReportScreenPermission.length > 0)
            caseSubtab = 'live-report'
        else
            caseSubtab = 'activity'
    }
    
    switch (caseSubtab) {
        case 'activity':
            AnlyticsPageLog("Click on Activity menu From Case Detail page")
            return (
                <CaseDetailActivity
                    caseObject={caseObject}
                    caseActivities={caseActivities}
                    hasNewPage={hasNewPage}
                    LoadMoreActivity={LoadMoreActivity}
                    showButtonLoader={showButtonLoader}
                    tabType={tabType}
                    IscaseDetail={IscaseDetail}
                    getCaseMemberDetailComponent={getCaseMemberDetailComponent}
                    getTeamMemberComponent={getTeamMemberComponent}
                />
            )
        case 'live-report':
            AnlyticsPageLog("Click on live-report menu From Case Detail page")
            return <LiveReport caseID={caseID} data={caseObject} />
        case 'mail-tracking':
            AnlyticsPageLog("Click on mail-tracking menu From Case Detail page")
            return <MailTracking caseID={caseID} data={caseObject} getCaseMemberDetailComponent={getCaseMemberDetailComponent}/>
        case 'milestone':
            AnlyticsPageLog("Click on Milestone menu From Case Detail page")
            return (
                <Milestones
                    dataForWeeklyMilestones={selectedMilestones}
                    dataForALLMilestones={mileStones}
                    caseID={caseID}
                    nextRemainingMilestones={nextRemainingMilestones}
                />
            )
        case 'team':
            AnlyticsPageLog("Click on Team menu From Case Detail page")
            return (
                <TeamMembers
                    data={teamMembers}
                    caseID={caseID}
                    getTeamMemberComponent={getTeamMemberComponent}
                />
            )
        case 'team-member-detail':
            AnlyticsPageLog("Click on Team-Member-Detail Screen From Case Detail page")
            return <CaseTeamMemberDetail caseID={caseID} personID={teamMemberID} />
        case 'responses':
            AnlyticsPageLog("Click on Responses menu From Case Detail page")
            return <Responses caseID={caseID} />
        case 'member':
            AnlyticsPageLog("Click on Member menu From Case Detail page")
            return (
                <CaseMembers
                    caseID={caseID}
                    page={1}
                    sizePerPage={10}
                    sortName={'firstName'}
                    sortOrder={'asc'}
                    totalMembers={caseObject != null ? caseObject.memberCount : 0}
                    getCaseMemberDetailComponent={getCaseMemberDetailComponent}
                />
            )
        case 'member-detail':            
            AnlyticsPageLog("Click on Member-Detail Screen From Case Detail page")
            return <CaseMemberDetail caseID={caseID} personID={teamMemberID} />
        case 'case-info':
            AnlyticsPageLog("Click on Case-Info menu From Case Detail page")
            return <CaseInfo caseID={caseID} />
        case 'distributions':
            AnlyticsPageLog("Click on Distributions menu From Case Detail page")
            return <Distributions caseID={caseID} data={caseObject} />
        case 'call center':
            AnlyticsPageLog("Click on Call Center menu From Case Detail page")
            return <CallCenter caseID={caseID} />
        case 'reports':
            AnlyticsPageLog("Click on Reports menu From Case Detail page")
            return <CaseReports caseID={caseID} getReportsDetailComponent={getReportsDetailComponent} />
        case 'files':
            AnlyticsPageLog("Click on Files menu From Case Detail page")
            return <Files caseID={caseID} getSecureFileDetailComponent={getSecureFileDetailComponent} updateSidebarMenuCounts={updateSidebarMenuCounts}/>
        case 'case-report-detail':            
            AnlyticsPageLog("Click on Report Detail Screen From Case Detail page")
            return <CaseReportsDetails caseID={caseID} reportID={reportID} caseReportData={caseReportData} reportTitle={reportTitle} reportGeneratedDate={reportGeneratedDate} />
        case 'case-file-detail':
            AnlyticsPageLog("Click on File Detail Screen From Case Detail page")
            return <CaseFileDetails caseID={caseID} secureFileID={secureFileID} caseSecureFileData={caseSecureFileData} />
        case 'campaigns':
            AnlyticsPageLog("Click on Campaigns menu From Case Detail page")
            return <CaseCampaigns caseID={caseID} getCampaignDetailComponent={getCampaignDetailComponent} />
        case 'campaign-detail':
            AnlyticsPageLog("Click on Campaigns menu From Case Detail page")
            return <CaseCampaignDetail caseID={caseID} hash={hash} selectCampaignCaseID={selectCampaignCaseID} campaignName={campaignName} />
        default:
            throw new Error('no such case sub tab component')
    }
}

//export default connect(state => ({
//	caseSubtab: state.caseDetailView.subtab,
//}))(CaseDetailContent)


export default connect(
    (state, props) => ({
        caseSubtab: state.caseDetailView.subtab == '' ? null : state.caseDetailView.subtab,
        userPermissions: getPermissionsForCase(state, props),
    }))(CaseDetailContent)
