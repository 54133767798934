import * as React from 'react'
import styled from 'styled-components'

import LogoSpinner from './logo-spinner'

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	height: ${props => `${props.sectionHeight}px`};
`

const SectionSpinner = ({ height = 300 }) => (
	<Wrapper sectionHeight={height} className="col-md-12 section-loader">
		<LogoSpinner size={30} />
	</Wrapper>
)

export default SectionSpinner
