import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import ButtonLoader from '../../components/button-loader';
import CaseSideBar from './dashboard-sidebar'
import DashboardHeader from 'react/components/dashboard-header'
import { fetchDashboardCaseActivity } from 'state/case-activity/actions'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ActivityDetail from 'react/components/case-activity'
import Header from './header'
import SectionSpinner from 'react/components/spinners/section-spinner'
import ChangeRequestAcknowledged from './change-request-acknowledged'
import { labFeatures, appMode } from '../../../api-config'
import { AnlyticsPageLog } from '../../../global';

JavascriptTimeAgo.locale(en)
let _pageSize = 10

function Dashboard({
    caseActivity,
    fetchDashboardCaseActivity,
    caseStage,
    fetchingActivity,
    caseActivityFilter,
    lastCaseId,
    isHasNewData
}) {
    
    let activityToShow
    if (appMode != "sandbox") {
        activityToShow = Object.values(caseActivity)
            .filter(activity => {
                if (!caseStage) {
                    return true
                }
                return activity.caseStageID === caseStage
            })
            .reverse();
    } else {
        activityToShow = Object.values(caseActivity)
            .filter(activity => {
                return activity;
            });

        activityToShow.sort(function (a, b) {
            var key1 = new Date(a.activityDate);
            var key2 = new Date(b.activityDate);

            if (key2 < key1) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
        });
    }
    
    activityToShow = activityToShow.filter(function (v) {
        return v.caseActivityID > 0;
    });

    
    //activityToShow = caseActivityFilter
    //    ? activityToShow.filter(
    //        activity => activity.caseActivityTypeTitle === caseActivityFilter
    //    )
    //    : activityToShow
    
    activityToShow =
        caseActivityFilter.length > 0
            ? activityToShow.filter(itemX =>
                caseActivityFilter.includes(itemX.caseActivityTypeTitle)
            )
            : activityToShow

    
    //let lastCaseId = 0
    //let casesCount = 0
    const [showPageLoader, setShowPageLoader] = useState(false)
    const firstName = localStorage.getItem(global.FirstName) || ''
    const [hour, setHour] = useState('')


    //lastCaseId =
    //    activityToShow && activityToShow.length > 0
    //        ? activityToShow[activityToShow.length - 1].caseActivityID
    //        : 0
    //casesCount =
    //    activityToShow && activityToShow.length > 0 ? activityToShow.length : 0


    //if (
    //    activityToShow &&
    //    activityToShow.length > 0 &&
    //    activityToShow.length % _pageSize == 1
    //) {
    //    activityToShow = activityToShow.slice(0, -1)
    //}


    const getHour = () => {
        const date = new Date()
        const hour = date.getHours()
        setHour(hour)
    }


    useEffect(() => {
        if (activityToShow.length == 0) {
            fetchDashboardCaseActivity({ lastCaseId, _pageSize })
        }
        getHour()
        AnlyticsPageLog("Dashboard page");
    }, [])


    const LoadMoreCases = async () => {
        setShowPageLoader(true)
        await fetchDashboardCaseActivity({ lastCaseId, _pageSize })
        setShowPageLoader(false)
    }


    return (
        <div>
            <DashboardHeader page="dashboard" />
            <div
                id="main"
                className="layout-row flex simplurisallcasesBodyBackgroudColour"
            >
                <CaseSideBar />

                <div id="content" className="flex ">
                    <div className="page-container" id="page-container">
                        <div className="page-title padding pb-0 pt-0">
                            <div className="text-md mb-2 SimplurisFontSize26 SimplurisMilestoneLables SimplurisFontWeight400">
                                {hour < 12
                                    ? `Good Morning, `
                                    : hour >= 12 && hour < 17
                                        ? `Good Afternoon, `
                                        : `Good Evening, `}
                                {firstName}!
              </div>
                            {/* <div className="text-md mb-0 SimplurisCasesPageTitle">12 new events since you were here last</div>*/}
                        </div>
                        <Header caseDetail={activityToShow} />
                        <div className="padding pt-3">
                            <div className="row">
                                <div className="col-md-8 col-xs-12 col-sm-12">
                                    {/* {
                                        localStorage.getItem(global.CaseRoleTitle) == "Project Manager" ? <ChangeRequestAcknowledged /> : null
                                    }*/}
                                    {/* <ChangeRequestAcknowledged /> */}
                                    {fetchingActivity && !Object.keys(caseActivity).length ? (
                                        <div className="mt-3 row pl-3 pr-3 pr-lg-5 pr-xl-5 pr-md-3 pr-sm-3">
                                            <div className="col-md-12 col-12">
                                                <div className="row">
                                                    <SectionSpinner />
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                        activityToShow.length > 0 && caseActivity != 'undefined' ? (
                                            activityToShow.map(post =>
                                                <React.Fragment key={post.caseActivityID}>
                                                    <ActivityDetail data={post} parentScreen="DashboardScreen"/>
                                                </React.Fragment>
                                            )
                                        ) : (
                                                <div>
                                                    Seems your dashboard is empty. Go to{' '}
                                                    <Link to="/cases">my cases</Link> to see your information.
                    </div>
                                            )
                                    }

                                    <div className={`col-md-12 mt-2 text-center ${appMode == "sandbox" ? 'd-none' : 'd-block'}`}>
                                        {isHasNewData ? (
                                            <button
                                                className="btn w-md mb-4 mb-md-1 btn-light SimplurisDashbordPostText p-3"
                                                onClick={LoadMoreCases}
                                                disabled={showPageLoader}>
                                                {
                                                    showPageLoader && (
                                                        <ButtonLoader />
                                                    )
                                                }
                                                {showPageLoader && <span></span>}
                                                {!showPageLoader && <span>Load More</span>}
                                            </button>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-3 col-xs-12 col-sm-12">
                                    {labFeatures && <div>
                                        <input
                                            type="button"
                                            name="btnNewBidRequest"
                                            value="New Bid Request"
                                            className="btn SimplurisAllCasesRightSideButton btn-lg btn-block remove-bootstrap-persistent-button-border"
                                        />
                                    </div>}
                                    <div className="pt-3">
                                        {
                                            appMode != "sandbox" ?
                                                <Link
                                                    to={`/request-call`}
                                                    className="btn SimplurisAllCasesRightSideButton btn-lg btn-block remove-bootstrap-persistent-button-border"
                                                >
                                                    Schedule Consult
                    </Link> : <></>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(
    (state, props) => ({
        caseActivity: state.caseActivity,
        lastCaseId: state.caseActivity.lastCaseId,
        isHasNewData: state.caseActivity.isHasNewData,
        caseStage: state.dashboardView.caseStage,
        fetchingActivity: state.dashboardView.fetchingActivity,
        caseActivityFilter:
            state.dashboardView.filter == undefined
                ? null
                : state.dashboardView.filter
    }),
    {
        fetchDashboardCaseActivity,
    }
)(Dashboard)
