import React, { useState, useEffect } from 'react'
import Header from '../../components/outer-header'
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png'
import { Link } from 'react-router-dom'
import PageLoader from '../../components/page-loader'
import ButtonLoader from '../../components/button-loader';
import { simplurisFetch } from 'helpers'
import { AnlyticsPageLog } from "../../../global";

function AccountActivationPage(activationdata) {
    const [activationCode, setActivationCode] = useState('')
    const [email, setEmail] = useState('')
    const [newpwd, setNewpwd] = useState('')
    const [confpwd, setConfpwd] = useState('')
    const [
        passwordChangedSuccessfully,
        setPasswordChangedSuccessfully,
    ] = useState(false)
    const [newpwdError, setNewpwdError] = useState(false)
    const [confpwdError, setConfpwdError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [strongPasswordError, setStrongPasswordError] = useState(false)
    const [confirmMatchPasswordError, setConfirmMatchPasswordError] = useState(
        false
    )
    const [showPageLoader, setShowPageLoader] = useState(true)
    const [isWrongCode, setIsWrongCode] = useState(false)
    const [showButtonLoader, setShowButtonLoader] = useState(false)

    useEffect(() => {
        AnlyticsPageLog("Admin Account Activation page");
        simplurisFetch({
            url: 'auth/getadminaccountdetailsbyactivationcode/' + activationdata.match.params.code,
        })
            .then(data => {     
                setShowPageLoader(false)
                setIsWrongCode(true)
                if (data.resultObject != null) {                    
                    setEmail(data.resultObject.email)
                    setActivationCode(data.resultObject.activationCode)
                }
                else {
                    setShowPageLoader(false)
                    setIsWrongCode(false)
                }
            })
            .catch(e => {
                console.error('Error in admin account activation', e, e.errorObject)
                setShowPageLoader(false)
                setIsWrongCode(false)
            })
    }, [activationdata.match.params.code])

    const SaveClick = () => {
        //either const or var is ok, but const doesn't let you reassign it in memory
        setNewpwd(newpwd)
        setConfpwd(confpwd)
        const isStrongPassword = global.StrongPasswordRegex.test(newpwd)
        //! covers all possible nulls/empty strings
        if (!newpwd || !confpwd || !isStrongPassword) {
            if (!newpwd) {
                setNewpwdError(true)
            }

            if (!confpwd) {
                setConfpwdError(true)
            }

            if (newpwd && !isStrongPassword) {
                setStrongPasswordError(true)
            }
            return
        }
        if (newpwd != confpwd) {
            setConfirmMatchPasswordError(true)
            return
        }
        var data = {}
        data.Email = email;
        data.AccountActivationCode = activationCode;
        data.NewPassword = confpwd;
        setShowButtonLoader(true)


        simplurisFetch({
            url: "auth/adminaccountactivationandchangepassword", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowButtonLoader(false);
            if (data.name != undefined && data.name == "FetchError") {                
                if (data.errorObject.resultCode == "ERROR") {                    
                    setErrorMessage(data.errorObject.resultMessage)
                }                
            }
            else {                
                setNewpwd('')
                setConfpwd('')
                setErrorMessage('')
                setConfirmMatchPasswordError(false)
                setPasswordChangedSuccessfully(true)
                setSuccessMessage('Password changed.')
            }
            }).catch(e => {
                console.error('Error in admin account activation and change password', e, e.errorObject);
                setShowPageLoader(false);
                setShowButtonLoader(false);
                if (e.errorObject.resultCode == "ERROR") {
                    setErrorMessage(e.errorObject.resultMessage)
                } 
            });
    }

    const KeyPressed = (event) => {
        if (event.key === "Enter") {
            SaveClick()
        }
    }

    return (
        <div className="layout-column bg-white">
            <Header page="admin-account-activation" />
            <div className="d-flex flex-column flex">
                <div>
                    <div className="p-3">
                        <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                            <div className="row no-gutters">
                                <div className="col-md-5 r-l">
                                    <div className="p-4 d-flex flex-column">
                                        <div className="d-block">
                                            <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right">
                                                <img src={BrandmarkLogo} className="w-50" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {showPageLoader ? (
                                    <div className="col-md-5 align-self-center" id="step2-body">
                                        <h4 className="p-5">Processing Activation...</h4>
                                    </div>
                                ) : (
                                        <div className="col-md-7" id="step2-body">
                                            {isWrongCode ? (
                                                <div
                                                    className={`p-4 ${
                                                        passwordChangedSuccessfully ? 'd-none' : 'd-block'
                                                        }`}
                                                >
                                                    <div className="d-flex flex row">
                                                        <div className="col-md-12 col-xs-12 col-sm-12">
                                                            <div className="card SimplurisCardTopBorderStatusDisbursement d-flex flex">
                                                                <div className="card-body">
                                                                    <h4 className="SimplurisTitle">
                                                                        Set your password
																</h4>
                                                                    <div className="">
                                                                        {successMessage != '' ? (
                                                                            <SuccessPlacement items={successMessage} />
                                                                        ) : (
                                                                                ''
                                                                            )}
                                                                        {errorMessage != '' ? (
                                                                            <ErrorPlacement items={errorMessage} />
                                                                        ) : (
                                                                                ''
                                                                            )}
                                                                        <form
                                                                            id="frmChangePassword"
                                                                            role="form"
                                                                            action="#"
                                                                        >
                                                                            <div
                                                                                className={`form-group mt-2 ${
                                                                                    newpwdError || strongPasswordError
                                                                                        ? 'has-error'
                                                                                        : ''
                                                                                    }`}
                                                                            >
                                                                                <label>New Password</label>
                                                                                <input
                                                                                    type="password"
                                                                                    value={newpwd}
                                                                                    onKeyPress={KeyPressed}
                                                                                    onChange={e => (
                                                                                        setNewpwd(e.target.value),
                                                                                        setNewpwdError(false),
                                                                                        setStrongPasswordError(false)
                                                                                    )}
                                                                                    id="txtNewPassword"
                                                                                    name="NewPassword"
                                                                                    className="form-control SimplurisNewCaseMemebersInputBox"
                                                                                />
                                                                                {newpwdError ? (
                                                                                    <span>Please enter new password</span>
                                                                                ) : null}
                                                                                {strongPasswordError ? (
                                                                                    <span>
                                                                                        {' '}
                                                                                        A minimum 8 characters password
                                                                                        contains a combination of uppercase
                                                                                        and lowercase letter and number and
																					special character are required{' '}
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-12 col-sm-6">
                                                                                    <p className="mb-0">
                                                                                        <small className="text-muted">
                                                                                            One lowercase character <br />
                                                                                            One uppercase character <br />
                                                                                            One number
																					</small>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-12 col-sm-6">
                                                                                    <p className="mb-0">
                                                                                        <small className="text-muted">
                                                                                            One special character <br /> 8
                                                                                            characters minimum
																					</small>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={`form-group mt-2 ${
                                                                                    confpwdError ||
                                                                                        confirmMatchPasswordError
                                                                                        ? 'has-error'
                                                                                        : ''
                                                                                    }`}
                                                                            >
                                                                                <label>Confirm Password</label>
                                                                                <input
                                                                                    type="password"
                                                                                    value={confpwd}
                                                                                    onKeyPress={KeyPressed}
                                                                                    onChange={e => (
                                                                                        setConfpwd(e.target.value),
                                                                                        setConfpwdError(false)
                                                                                    )}
                                                                                    id="txtConfirmPassword"
                                                                                    name="ConfirmPassword"
                                                                                    className="form-control SimplurisNewCaseMemebersInputBox"
                                                                                />
                                                                                {confpwdError ? (
                                                                                    <span>
                                                                                        Please enter confirm password
																				</span>
                                                                                ) : null}
                                                                                {confirmMatchPasswordError ? (
                                                                                    <span>
                                                                                        New password and confirm password does
                                                                                        not match
																				</span>
                                                                                ) : null}
                                                                            </div>

                                                                            <button
                                                                                type="button"
                                                                                disabled={showButtonLoader}
                                                                                className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3"
                                                                                onClick={SaveClick} >
                                                                                {
                                                                                    showButtonLoader && (
                                                                                        <ButtonLoader />
                                                                                    )
                                                                                }
                                                                                {showButtonLoader && <span></span>}
                                                                                {!showButtonLoader && <span>Save</span>}
                                                                            </button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    <h4
                                                        className={`py-5 ${
                                                            passwordChangedSuccessfully ? 'd-none' : 'd-block'
                                                            }`}
                                                    >
                                                        The link or activation code attempted is not valid.
                                                        Perhaps you've already activated your account? Please
                                                        check the email or request another activation link to
                                                        try again.
											</h4>
                                                )}
                                            {passwordChangedSuccessfully ? (
                                                <>
                                                    <h4 className="py-5 mb-0">
                                                        Your account has been activated. Please
													<Link to={'/sign-in'}> click here </Link>
                                                        to login.
												</h4>
                                                </>
                                            ) : null}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPageLoader ? <PageLoader /> : null}
        </div>
    )
}

export default AccountActivationPage

function ErrorPlacement(data) {
    if (data.items != '') {
        return (
            <div id="dvFailedMessage" className="alert alert-danger" role="alert">
                {data.items}
            </div>
        )
    }
}

function SuccessPlacement(data) {
    if (data.items != '') {
        return (
            <div id="dvsuccessMessage" className="alert alert-success" role="success">
                {data.items}
            </div>
        )
    }
}
