import * as React from 'react'
import styled from 'styled-components'

import CenteredSpinner from './centered-spinner'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.5);
  position: absolute;
`

const OverlaySpinner = ({ size = 30 }) => (
	<Wrapper>
		<CenteredSpinner size={size} />
	</Wrapper>
)

export default OverlaySpinner
