import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { simplurisFetch } from 'helpers'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import NewShareFileModalPopup from './new-share-case-file'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import ButtonLoader from '../../../../components/button-loader';
import CaseFileBlueImage from '../../../../../static/img/securefile_icon_small.png'
import { SectionSpinner } from 'react/components/spinners'

function SecureFileUploadModalPopup(props) {

    const [showPageLoader, setShowPageLoader] = useState(false);
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [showNewShareFile, setShowNewShareFile] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    //const [uploadedfile, setUploadedfile] = useState('');
    var uploadedfile = "";
    const [fileError, setFileError] = useState(false);
    const [isFetchCaseManager, setIsFetchCaseManager] = useState(false);
    const [fileReqError, setFileReqError] = useState(false);
    const [sharableFileData, setSharableFileData] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [fileDetail, setFileDetail] = useState(false);
    const [fileName, setFileName] = useState("");

    function CloseSecureFileUploadModalPopup() {
        setDisabled(true);
        setInvalidFileType(false);
        uploadedfile = "";
        setFileDetail(false);
        setFileError(false);
        setFileReqError(false);
        props.setShowSecureFileUploadModalPopup(false);
    }

    function OpenNewShareFileModalPopup() {
        AnlyticsPageLog("Click on new share file Modal Popup from File Upload");

        if (uploadedfile == null || uploadedfile == "") {
            setFileReqError(true);
            return false;
        }
        setFileReqError(false);
        /* Upload/Save file on liquid files */
        var formData = new FormData();
        formData.append('CaseID', props.caseID);
        formData.append('file', uploadedfile);
        formData.append('Title', uploadedfile.name);
        formData.append('FileName', uploadedfile.name);
        formData.append('FileType', uploadedfile.type);
        formData.append('FileSize', uploadedfile.size);

        setFileName(uploadedfile.name);

        setShowButtonLoader(true);
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/${props.caseID}/savefile`,
            method: 'POST',
            body: formData,
            json: false,
        }).then((result) => {
            setShowButtonLoader(false);
            if (result.resultCode == "SUCCESS") {
                setSharableFileData(result.resultObject);
                props.setShowSecureFileUploadModalPopup(false);
                setShowNewShareFile(true);
                setIsFetchCaseManager(true);
            } else {
                props.setShowSecureFileUploadModalPopup(true);
                setShowNewShareFile(false);
                setIsFetchCaseManager(false);
                setFileError(true);
            }
            setShowPageLoader(false);
        });
        setDisabled(true);
    }

    const getUploadParams = ({ meta }) => {
        return {
            url: `${global.API_URL}`
        }
    }

    const handleChangeStatus = ({ meta, file }, status) => {
        setShowPageLoader(true);
        setInvalidFileType(false);
        if (status == "done") {
            uploadedfile = file;
            console.log(status);
            setDisabled(false);
            setFileDetail(true);
            setShowPageLoader(false);
            OpenNewShareFileModalPopup();
        }
        else if (status == "rejected_file_type") {
            setInvalidFileType(true);
            setShowPageLoader(false);
        }
    }

    const RemoveUploadedFile = () => {
        setDisabled(true);
        uploadedfile = "";
        setFileDetail(false);
        setFileError(false);
        setFileReqError(false);
    }

    const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
        input.props.withFilesContent = 'Drag-and-drop the document here. Or, click here to upload.'
        return (
            <div>
                <div {...dropzoneProps}>
                    {files.length < maxFiles && input}
                </div>
            </div>
        )
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseSecureFileUploadModalPopup} backdrop="static" size="lg" data-keyboard="false" data-backdrop="static">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        {showPageLoader == true ? <h5 className="modal-title SimplurisBidRequestTitle pl-3">Uploading file: {fileName}</h5> : <h5 className="modal-title SimplurisBidRequestTitle pl-3">Secure File Transfer</h5>}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="SimplurisFileUploadModalPopupBody">

                    {showPageLoader ? <div className="row"><div className="col-md-11 ml-3"><SectionSpinner height={260} /></div></div> :

                        <div className="px-5 py-4 mb-2">

                            <div className={"FileDetails " + (fileDetail == true ? '' : 'd-none')}>
                                <div className={"row"}>
                                    <div className="col-md-1 col-sm-1 col-xs-1">
                                        <span className="r avatar float-left float-md-none">
                                            <img src={CaseFileBlueImage} className="SimplurisMailingsEnvelopeImage" />
                                        </span>
                                    </div>
                                    <div className="col-md-10 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0">
                                        <div className="SimplurisReportTitle">
                                            {uploadedfile.name}
                                        </div>
                                        <div className="SimplurisReportSubTitle">
                                            {uploadedfile.type}
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-sm-1 col-xs-1 align-self-center pt-3 pt-lg-0">
                                        <span onClick={() => RemoveUploadedFile()}
                                            className="w-28 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-success SimplurisCursorPointer">
                                            <i className="i-con i-con-close"></i>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className="row pb-3">
                                <div className="col-12">
                                    <p className="modal-title SimplurisFontSize19 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                        Upload your file:
                            </p>
                                </div>
                            </div>
                            <Dropzone
                                accept="image/*,audio/*,video/*,.pdf,.txt,.doc,.docx,.zip,.xls,.xlsx,.ppt,.pptx,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain"
                                //maxFiles={1}
                                multiple={true}
                                inputContent={(files, extra) => (extra.reject ? 'Sorry but you’ve attempted to upload a file type we aren’t allowing at this time. Valid file extensions are: jpg, jpeg, png, gif, mp4, mp3, doc, docx, xls, xlsx, zip, pdf, txt, ppt, pptx.' : 'Drag-and-drop the document here. Or, click here to upload.')}
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                LayoutComponent={Layout}
                                classNames="white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center SimplurisDropzone"
                            />
                            <div className="pt-2"> <i class="fa fa-info-circle" aria-hidden="true"></i> Valid File Types: jpg, jpeg, png, gif, mp4, mp3, doc, docx, xls, xlsx, zip, pdf, txt, ppt, pptx </div>
                            {fileReqError ? <span className="text-danger">Please select file.</span> : null}
                            {fileError ? <span className="text-danger">Error to upload file, please try again.</span> : null}
                            {invalidFileType ? <span className="text-danger">Sorry but you’ve attempted to upload a file type we aren’t allowing at this time. Valid file extensions are: jpg, jpeg, png, gif, mp4, mp3, doc, docx, xls, xlsx, zip, pdf, txt, ppt, pptx.</span> : null}
                            <div className="row pt-3">
                                <div className="col-md-10 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0">
                                    {/* <button disabled={disabled} type="button" onClick={() => OpenNewShareFileModalPopup()} className="btn text-white SimplurisEggBlueBackGroudColor SimplurisFontSize14 SimplurisFontWeight400 w-sm mb-1 btn-light py-3 rounded-0">
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Next</span>}
                            </button> */}
                                </div>
                                <div className="col-md-2 float-lg-right float-md-right mt-2 mt-lg-0 mt-md-0 float-right">
                                    <button type="button" onClick={() => CloseSecureFileUploadModalPopup()} className="btn bg-white Simpluris-btnCancel SimplurisFontSize14 SimplurisFontWeight400 w-sm mb-1 btn-light py-3 rounded-0 border-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <NewShareFileModalPopup
                OpenModalPopup={showNewShareFile}
                setShowNewShareFile={setShowNewShareFile}
                setShowSecureFileUploadModalPopup={props.setShowSecureFileUploadModalPopup}
                sharableFileData={sharableFileData}
                caseID={props.caseID}
                setFileDetail={setFileDetail}
                isFetchCaseManager={isFetchCaseManager}
                setIsFetchCaseManager={setIsFetchCaseManager}
                fetchCaseDataCounts={props.fetchCaseDataCounts}
                updateSidebarMenuCounts={props.updateSidebarMenuCounts}
            />
        </>
    )
}

export default SecureFileUploadModalPopup
