import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { fetchMailingsDocuments } from 'state/mailings/other/actions'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { SectionSpinner } from 'react/components/spinners';
import { getFetchingDocuments, getMailingsDocuments, getSelectedMailingID } from 'state/case-detail-view/mailings/other/selectors'
import { labFeatures } from '../../../../../api-config'

function MailingDocuments({ isFetchingMailingDocuments, fetchMailingsDocuments, mailingsDocuments, caseID, selectedMailingID }) {
    const noMailingsDocuments = !mailingsDocuments || !mailingsDocuments.length
    const showLoader = isFetchingMailingDocuments && noMailingsDocuments

    React.useEffect(() => {
        fetchMailingsDocuments(caseID, selectedMailingID)
    }, [])

    function columnClassNameFormat() {
        return 'td-column';
    }

    
    function setTableOption() {
        if (!mailingsDocuments || mailingsDocuments.length <= 0) {
            return 'No Data Found!'
        }
    }

    const defaultSorted = [{
        dataField: 'fileName',
        order: 'desc'
    }];


    const MailingDocumentsData = ({ data}) => (
        <div>
            <BootstrapTable                
                data={data}
                pagination={false}
                search={false}
                options={
                    {                        
                        sortIndicator: false,
                        noDataText: setTableOption(),                        
                    }
                }
                defaultSorted={defaultSorted}
                style={{ 'margin-bottom': '0px' }}
                tableContainerClass='table table-theme v-middle SimplurisCasesDetailsGrid table-hover' bodyContainerClass='text-grid-td'>
                <TableHeaderColumn dataField='mailingDocumentID' isKey width={'12%'}>Document ID</TableHeaderColumn>
                <TableHeaderColumn dataField='title' columnClassName={columnClassNameFormat} width={'27%'} >Title</TableHeaderColumn>             
                <TableHeaderColumn dataField='mailingDocumentTypeTitle' columnClassName={columnClassNameFormat} width={'19%'} >Type</TableHeaderColumn>
                <TableHeaderColumn dataField='fileName' columnClassName={columnClassNameFormat} width={'30%'} >Word Document</TableHeaderColumn>   
            </BootstrapTable>
        </div>
    );

    return <div>
        {
            showLoader && <div className="card SimplurisDashboardCard">
                <SectionSpinner /></div>
        }
        {
            <div className="card SimplurisDashboardCard">
                <div className="d-flex flex row">
                    <div className="col-md-12">
                        <div className="card d-flex flex p-3 mb-0">
                            <div className="col-md-12 mb-2 pl-0">
                                <span className="SimplurisSubTabTitle">All Documents</span>
                                {
                                    labFeatures &&
                                    <div className="d-inline-block SimplurisMailingDocumentsAddButton float-lg-right float-md-right mt-2 mt-lg-0 mt-md-0">
                                        <input type="button" className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 py-2 px-4" value="Add" id="btnAdd" name="btnAdd" />
                                    </div>
                                }
                                
                            </div>

                            <div className="SimplurisMailingRecipientsGrid">
                                <MailingDocumentsData data={mailingsDocuments}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
}

export default connect(
    (state, props) => ({
        isFetchingMailingDocuments: getFetchingDocuments(state, props),
        mailingsDocuments: getMailingsDocuments(state, props),
        selectedMailingID: getSelectedMailingID(state, props),
    }),
    { fetchMailingsDocuments }
)(MailingDocuments)
