import React, { useState } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { func } from 'prop-types';
import { setTimeout } from 'timers';
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import {
    selectMember,
    selectPermission,
    selectPaymentsDetail,
    selectResponsesDetail
} from 'state/case-detail-view/members/actions'


const CallStatistics = ({
    caseCallCenterData,
    viewPersonIdPermission,
    viewFirstNamePermission,
    viewLastNamePermission,
    viewMemberDrilDownToDetail,
    changeCaseSubtab,
    selectMember,
    setPreviousCaseSubtab,
    selectPermission,
    selectPaymentsDetail,
    selectResponsesDetail }) => {   

    const [totalCount, settotalCount] = useState(0);
    function renderShowsTotal(start, to, total) {
        settotalCount(total);
        return <></>;
    }

    const tableOptions = {
        toolBar: getToolbarWithTotal({ buttonText: `All ${totalCount} ` }),
        searchField: getSearchField({ defaultValue: '' }),        
        onRowClick: onRowClick,       
        onlyOneExpanding: true,
        expandBy: 'column',
        paginationShowsTotal: renderShowsTotal
    }

    function onRowClick(row, columnIndex, rowIndex, e) {        
        if (e.target.classList.value.indexOf("SimplurisCallCenterMemeberDrillDownLink") > -1) {
            return false; 
        }

        if (e.target.classList.value.indexOf("SimplurisCallCenterExpandLink") > -1) {
            const collepsedTag = document.getElementsByClassName("expandDiv");
            for (var i = 0; i < collepsedTag.length; i++) {
                collepsedTag[i].style.height = "0px";
            }

            const audioEl = document.getElementsByClassName("audio-" + row.callID)[0]
            if (audioEl.paused) {
                audioEl.play();

                setTimeout(function () {
                    document.getElementsByClassName("expandDiv-" + row.callID)[0].style.height = "50px";
                }, 100);

            } else {
                audioEl.pause();
                document.getElementsByClassName("expandDiv-" + row.callID)[0].style.height = "0px";
            }

            const audioTag = document.getElementsByTagName("audio");
            for (var i = 0; i < audioTag.length; i++) {
                if (audioTag[i].className != "audio-" + row.callID) {
                    audioTag[i].pause();
                }
            }
        }
        else
            return false;
    }

    function isExpandableRow(row) {          
        if (row.isReleased == true) {
            return true
        }
        else {
            return false;
        }
            
    }

    function expandColumnComponent({ isExpandableRow, isExpanded }) {                
        
        if (isExpandableRow) {
            
            if (!isExpanded) {
                
                return (
                    <i className="SimplurisCallCenterPlayPauseIcon fa fa-play-circle-o SimplurisCallCenterExpandLink"></i>)
            }
            else {
                
                return (
                    <i className="SimplurisCallCenterPlayPauseIcon fa fa-pause-circle-o SimplurisCallCenterExpandLink"></i>)
            }
        }
        else {
            return <div>&nbsp;</div>;
        }
    }


    function expandComponent(row) {
        return (
            <>
                <tr className="p-0">
                    <td colSpan="6" className="p-0 border-0 shadow-none">
                        <table className="table table-theme v-middle m-0 callCenterStatisticsGrid">
                            {(() => {
                                   return <tr>
                                        <td className="p-0">
                                            <div className={`SimplurisFadeInAnimation expandDiv ${"expandDiv-" + row.callID}`}>
                                                {(
                                                    () => {
                                                        if (row.audioURL != undefined) {
                                                          return<>  <audio controls loop controlsList="nodownload" width="100%" className={"audio-" + row.callID}>
                                                                <source src={row.audioURL} type="audio/wav" />
                                                            </audio></>
                                                        }
                                                        else {
                                                            return <div>&nbsp;</div>
                                                        }        
                                                    }
                                                )()}                                                                                               
                                            </div>
                                        </td>
                                    </tr>
                                
                            })()}
                        </table>
                    </td>
                </tr></>
        )
    }


    function linkCellFormatterForName(cell, row) {        
        if (viewMemberDrilDownToDetail && viewMemberDrilDownToDetail.length > 0) {
        return (
            <a
                className="SimplurisBlueColor SimplurisPointer SimplurisCallCenterMemeberDrillDownLink"
                href={null}
                onClick={() => redirectToMemberDetailScreen(row)}
            >
                {(viewFirstNamePermission && viewFirstNamePermission.length > 0 ? row.firstName + ' ' : '')}
                {(viewLastNamePermission && viewLastNamePermission.length > 0 ? row.lastName : '')}
            </a>
        );
        } else {
            return <>{(viewFirstNamePermission && viewFirstNamePermission.length > 0 ? row.firstName + ' ': '')}
                {(viewLastNamePermission && viewLastNamePermission.length > 0 ? row.lastName : '')}</>;
         }
    }   

    function redirectToMemberDetailScreen(row) {        
        selectMember(row.personID)
        selectPermission({ viewFirstNamePermission, viewLastNamePermission });
        var paymentsDetail = false
        selectPaymentsDetail(paymentsDetail)
        var responsesDetail = false
        selectResponsesDetail(responsesDetail);
        changeCaseSubtab('member-detail')
        setPreviousCaseSubtab('call center')
    }

    return (
        <div>
            <BootstrapTable
                data={caseCallCenterData}
                expandBy="column"
                options={tableOptions}
                expandableRow={isExpandableRow}
                expandComponent={expandComponent}
                expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: expandColumnComponent,
                    columnWidth: 60
                }}
                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover callCenterStatisticsMainGrid table1"
                bodyContainerClass="text-grid-td"
                hover
                pagination
                search
                trClassName="SimplurisStatisticsGridMainTr"
            >
                <TableHeaderColumn
                    dataField="callID"
                    isKey
                    hidden={true}
                    expandable={false}
                    columnClassName="SimplurisCallCenterStatisticsAudioGrid"
                >
                    CallID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="personID"    
                    width="16%"
                    expandable={false}
                    columnClassName="SimplurisCallCenterStatisticsAudioGrid"
                    hidden={
                        viewPersonIdPermission && viewPersonIdPermission.length > 0
                            ? false
                            : true
                    }
                >
                    PersonID
                </TableHeaderColumn>
                <TableHeaderColumn expandable={false} dataField="date"
                    columnClassName="SimplurisCallCenterStatisticsAudioGrid" width="16%"
                    dataFormat={date => new Date(date).toLocaleDateString()}
                >
                    Date
                    </TableHeaderColumn>
                <TableHeaderColumn expandable={false} columnClassName="SimplurisCallCenterStatisticsAudioGrid" dataField="date" width="16%" dataFormat={date => new Date(date).toLocaleTimeString()}>
                    Time
                    </TableHeaderColumn>
                <TableHeaderColumn expandable={false} columnClassName="SimplurisCallCenterStatisticsAudioGrid" width="11%"
                    dataField="durationSeconds"
                >
                    Length
            </TableHeaderColumn>
                <TableHeaderColumn expandable={false} columnClassName="SimplurisCallCenterStatisticsAudioGrid" dataField="fromCity" width="30%" dataFormat={(fromCity, { fromState }) =>
                    `${fromCity} ${fromState}`
                }>
                    Call Location
            </TableHeaderColumn>

                <TableHeaderColumn expandable={false} dataField="firstName" width="30%" dataFormat={linkCellFormatterForName}>
                    Member
                                </TableHeaderColumn>             
            </BootstrapTable>
        </div>
    )
}

//export default CallStatistics

export default connect(
    (state, props) => ({        
        previousCaseSubtabs: state.previousSubTab.subTabNames
    }),
    {        
        selectMember,
        selectPermission,
        changeCaseSubtab,
        setPreviousCaseSubtab, 
        selectPaymentsDetail,
        selectResponsesDetail
    }
)(CallStatistics)