import React, { useState, useEffect } from 'react'
import GlobalImage from '../../components/global-image-set'
import { Link } from 'react-router-dom'

function ManagerInfo(data) {
    const [profilePic, setProfilePic] = useState(
        data.items.caseManagerProfilePicURL
    )
    const [managerName, setCaseManagerName] = useState(data.items.caseManagerName)
    const [email, setCaseManagerEmail] = useState(data.items.caseManagerEmail)

    if (
        data.items.caseManagerName != null &&
        data.items.caseManagerName != 'null' &&
        data.items.caseManagerName != 'undefined'
    ) {
        return (
            <Link className="SimplurisMouseRollOver" to={{
                pathname: `/cases/detail/${data.items.caseID}`,
                state: [{ caseManagerPersonID: data.items.caseManagerPersonID, TabType: "TeamMemberDetail" }]
            }}>
                <div className="list list-row block animates animates-fadeIn shadow-none">
                    <div className="list-item">
                        <div className="flex SimplurisCaseManagerDetails">
                            <div className="text-right mb-2 SimplurisMutedText SimplurisCaseManagerlable">
                                Project Manager
            </div>
                            <h5 className="mb-2 SimplurisCaseManagerName text-lg-right">
                                {data.items.caseManagerName}
                            </h5>
                        </div>
                        <div className="SimplurisCaseManagerAvatar">
                            <GlobalImage
                                firstname={managerName}
                                image={profilePic}
                                email={email}
                                dynamicClass="w-56 avatar circle bg-info-lt"
                            />
                        </div>
                    </div>
                </div>
            </Link>
        )
    } else {
        return (
            <div className="list list-row block animates animates-fadeIn">
                <div className="list-item"></div>
            </div>
        )
    }
}

export default ManagerInfo
