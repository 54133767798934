import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import ReactTimeAgo from 'react-time-ago';
import { Link } from 'react-router-dom';
import GlobalImage from 'react/components/global-image-set';
import { addCaseActivityPost } from 'state/case-activity/actions'
import { addCaseActivityPostForHomeLoggedInScreen } from 'state/home-loggedin-case/actions'
import { addCaseActivityPostForTeamMemberDetailScreen } from 'state/team-member-detail/actions'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import { appMode } from '../../../api-config'

const Rotate90OnHover = styled.span`
    &:hover i {
        transform: rotate(90deg);
        transition: transform 250ms;
    }
`

function ActivityDetail({ data, addCaseActivityPost, addCaseActivityPostForHomeLoggedInScreen, addCaseActivityPostForTeamMemberDetailScreen, parentScreen, IscaseDetail, getCaseMemberDetailComponent, teamMemberDetailPersonID, getTeamMemberComponent }) {

    const [isRefreshPostCommentForTeamMembers, setIsRefreshPostCommentForTeamMembers] = useState(false);
    const expression = /\@(?<kind>\w)\_(?<id>\d+)\_(?<title>[\w|\_]+)/g;
    let authorResult = expression.exec(data.activity);
    const caseResult = expression.exec(data.activity);
    let caseName;
    if (appMode == "sandbox") {
        caseName = caseResult.groups.title.replace(/_/g, " ");
    }
    //const expressionAuthor = /\@(?<kind>\w)\_(?<id>\d+)\_(?<title>[\w|\_]+[\s][\w]+)/g;
    //authorResult = expressionAuthor.exec(data.activity);
    const [profilePic, setProfilePic] = useState(data.profilePicURL);
    const [email, setEmail] = useState(data.email);
    const [firstName, setFirstName] = useState(authorResult.groups.title);
    let firstNameLetter = authorResult.groups.title;
    let initialLetterFName = '';
    for (let i = 0; i < firstNameLetter.length; i++) {
        if (firstNameLetter.charAt(i) === firstNameLetter.charAt(i).toUpperCase()) {
            initialLetterFName += firstNameLetter.charAt(i);
        }
    }
    initialLetterFName = initialLetterFName.replace(/_/g, "");

    const [initialLetterFirstName, setInitialLetterFirstName] = useState(initialLetterFName);
    const [loginfirstName, setLoginFirstName] = useState(localStorage.getItem(global.FirstName) || '');
    const [loginlastName, setloginLastName] = useState(localStorage.getItem(global.LastName) || '');
    const [userprofilePicUrl, setUserProfilePicUrl] = useState(localStorage.getItem(global.ProfilePicURL) || '');
    const managerFirstLetter = loginfirstName + ' ' + loginlastName;
    const activityModified = data.activity
        .replace(authorResult[0], '')
        .replace(caseResult[0], '');

    const [postCommentCaseActivityID, setpostCommentCaseActivityID] = useState(0);
    const [postDetail, setPostDetail] = useState('');
    const [postTextboxEnable, setPostTextboxEnable] = useState(false);

    const CreateCaseActivityPost = () => {
        setIsRefreshPostCommentForTeamMembers(false);
        setPostDetail(postDetail);

        if (!postDetail) return;

        var objData = {};
        objData.postContent = postDetail;
        //objData.postedByPersonID = parseInt(localStorage.getItem(global.PersonID));
        objData.caseActivityID = postCommentCaseActivityID;

        simplurisFetch({
            url: 'createpost', method: 'POST', body: JSON.stringify(objData)
        }).then((responseData) => {
            if (responseData.name != undefined && responseData.name == "FetchError") {
                setPostTextboxEnable(false);
            }
            else {
                setPostDetail('');
                setpostCommentCaseActivityID(0);


                if (parentScreen == "HomeLoggedInScreen") {

                    addCaseActivityPostForHomeLoggedInScreen(responseData.resultObject);
                }
                else if (parentScreen == "CaseTeamMemberDetails") {
                    addCaseActivityPostForTeamMemberDetailScreen(responseData.resultObject, teamMemberDetailPersonID);
                    setIsRefreshPostCommentForTeamMembers(true);
                }
                else {
                    addCaseActivityPost(responseData.resultObject);
                }
                setPostTextboxEnable(false);
            }
        }).catch(e => {
            console.error('Error in create case activity post', e, e.errorObject);
            setPostTextboxEnable(false);
        });
    }

    function GetSelectedTime(caseActivityId) {
        setpostCommentCaseActivityID(caseActivityId);

    }

    useEffect(() => {
        GetSelectedTime(0);
    }, []);

    const keyPressed = (event) => {
        if (event.key === "Enter") {
            //setPostTextboxEnable(true);
            CreateCaseActivityPost();
        }
    }

    // Ref and function used to scroll and click
    const addCommentRef = useRef(data.caseActivityID)
    const onClickPlusButton = () => {

        addCommentRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        setTimeout(function () {
            addCommentRef.current.click()
        }, 500)
    }

    if (data != null) {        
        return (
            <div className="d-flex flex row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                    <div className={"card d-flex flex position-relative SimplurisDashboardCard " + (data.caseActivityTypeTitle == "Funds Disbursed" ? 'SimplurisDashbordDisbursementCard' : 'SimplurisDashbordSecondaryCard')}>
                        <div className="p-4">
                            <div className={"SimplurisFontSize10 SimplurisFontWeight700 " + (data.caseActivityTypeTitle == "Funds Disbursed" ? 'text-white SimplurisOpacity045' : 'SimplurisDashbordSecondaryHeaderText SimplurisOpacity03')}>
                                {data.activityDateFormatted}
                            </div>
                            <div className={"SimplurisFontSize16 SimplurisFontWeight400 " + (data.caseActivityTypeTitle == "Funds Disbursed" ? 'text-white' : 'SimplurisDashbordSecondaryHeaderText')}>
                                {data.caseActivityTypeTitle}
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="col-md-12 col-xs-12 col-sm-12 p-4 bg-white SimplurisBorderRadius9">
                                <div className="d-flex flex mb-4">
                                    <div className="">
                                        {(
                                            () => {
                                                if (IscaseDetail == true) {
                                                    return <a href={null} className="SimplurisMouseRollOver" onClick={() => getTeamMemberComponent(0, authorResult.groups.id)}>
                                                        {/*<span className="SimplurisActatarW-34 avatar circle bg-info-lt">
                                                            <GlobalImage firstname={firstName} image={profilePic} email={email} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" />
                                                        </span>*/}
                                                        <GlobalImage
                                                            isSandbox={appMode == "sandbox" ? "true" : "false"}
                                                            firstname={initialLetterFirstName} image={profilePic} email={email} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" isTeam="true" />
                                                    </a>
                                                }
                                                else {
                                                    return <Link className="SimplurisMouseRollOver" to={{
                                                        pathname: `/cases/detail/${data.caseID}`,
                                                        state: [{ caseManagerPersonID: authorResult.groups.id, TabType: "TeamMemberDetail" }]
                                                    }}>
                                                        {/*<span className="SimplurisActatarW-34 avatar circle bg-info-lt">
                                                            <GlobalImage firstname={firstName} image={profilePic} email={email} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" />
                                                        </span>*/}
                                                        <GlobalImage
                                                            isSandbox={appMode == "sandbox" ? "true" : "false"}
                                                            firstname={initialLetterFirstName} image={profilePic} email={email} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" isTeam="true" />
                                                    </Link>
                                                }
                                            }
                                        )()}

                                    </div>
                                    <div className="align-self-center ml-2 SimplurisActivitiesCaseTitle">
                                        {(
                                            () => {
                                                if (IscaseDetail == true) {
                                                    return (<a href={null} className="SimplurisMouseRollOver" onClick={() => getTeamMemberComponent(0, authorResult.groups.id)}>
                                                        {authorResult.groups.title.match(/[A-Z][a-z]+/g).join(" ")}
                                                    </a>)
                                                }
                                                else {
                                                    return (<Link className="SimplurisMouseRollOver" to={{
                                                        pathname: `/cases/detail/${data.caseID}`,
                                                        state: [{ caseManagerPersonID: authorResult.groups.id, TabType: "TeamMemberDetail" }]
                                                    }}>
                                                        {authorResult.groups.title.match(/[A-Z][a-z]+/g).join(" ")}
                                                    </Link>)
                                                }
                                            }
                                        )()}
                                        {activityModified}
                                        <Link className="SimplurisMouseRollOver" to={`/cases/detail/${caseResult.groups.id}`}>{caseName}</Link>
                                        {(
                                            () => {
                                                if (data.optionalClickHereID != undefined && data.optionalClickHereID > 0) {
                                                    return <span> <Link className="SimplurisMouseRollOver" to={`/cases/detail/${caseResult.groups.id}`}>Click here</Link> to review</span>
                                                }
                                            })()}
                                    </div>
                                </div>
                                <hr />
                                {
                                    data.posts && data.posts.map(post =>
                                        <React.Fragment key={post.caseActivityPostID}>
                                            <ActivityPost items={post} />
                                        </React.Fragment>
                                    )
                                }
                                <div className="d-flex flex mb-2 mt-4">
                                    <a onClick={() => GetSelectedTime(data.caseActivityID)} className="text-sm text-primary" ref={addCommentRef}>Add Comment</a>
                                </div>
                                {postCommentCaseActivityID == data.caseActivityID ?
                                    <div className="d-flex flex mb-2 mt-4" id="commentbox">
                                        <div className="mt-2">
                                            <a href="#">
                                                {/*<span className="">
                                                    <GlobalImage firstname={managerFirstLetter} image={userprofilePicUrl} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt"/>
                                                </span>*/}
                                                <GlobalImage
                                                    isSandbox={appMode == "sandbox" ? "true" : "false"}
                                                    firstname={managerFirstLetter} image={userprofilePicUrl} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" isTeam="true" />
                                            </a>
                                        </div>
                                        <div className="w-100">
                                            <div className="SimplurisFontSize13 SimplurisFontWeight400 pl-3 pr-3 p-2">
                                                <input autoFocus type="text" name="casepost" id="casepost" disabled={(postTextboxEnable) ? "disabled" : ""} onKeyPress={keyPressed} onChange={e => (setPostDetail(e.target.value))} className="form-control" />
                                            </div>
                                            <div className="SimplurisFontSize12 SimplurisFontWeight500 ml-2 mt-1 pl-2 SimplurisOpacity07">
                                                <span className="SimplurisTeamDetailsUserNameLable">Type your message and press ENTER to post</span>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {/*(
                                    () => {
                                        if (data.optionalPercentage > 0) {
                                            return (
                                                <div>
                                                    <hr />
                                                    <div className="SimplurisFontSize14 SimplurisFontWeight400 SimplurisTextLighPrimary mt-3">
                                                        Case is now {data.optionalPercentage}% disbursed
                                            </div>
                                                    <div className="progress mt-3">
                                                        <div className="progress-bar" style={{ width: `${data.optionalPercentage}%` }}></div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()*/}
                            </div>
                        </div>
                        <div className="SimplurisDashboardSecondarySection d-sm-block d-none">
                            <button className="btn btn-lg btn-icon btn-rounded btn-raised SimplurisDashboardCancleButton d-block" onClick={onClickPlusButton}>
                                <Rotate90OnHover>
                                    <i className="i-con w-24 i-con-plus b-2x SimplurisDashboardPlusIcon" />
                                </Rotate90OnHover>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    else {
        return null;
    }
}

export default connect(
    (state) => ({
        caseActivity: state.caseActivity
    }),
    {
        addCaseActivityPost,
        addCaseActivityPostForHomeLoggedInScreen,
        addCaseActivityPostForTeamMemberDetailScreen
    }
)(ActivityDetail)
//export default ActivityDetail

function ActivityPost(data) {
    const expression = /\@(?<kind>\w)\_(?<id>\d+)\_(?<title>[\w|\_]+)/g;
    const [firstName, setFirstName] = useState(expression.exec(data.items.postedByPersonName));

    if (data.items != null) {
        const expression = /\@(?<kind>\w)\_(?<id>\d+)\_(?<title>[\w|\_]+)/g;
        const postPersonResult = expression.exec(data.items.postedByPersonName);
        let firstNameLetter = postPersonResult.groups.title;
        let initialLetterFName = '';
        for (let i = 0; i < firstNameLetter.length; i++) {
            if (firstNameLetter.charAt(i) === firstNameLetter.charAt(i).toUpperCase()) {
                initialLetterFName += firstNameLetter.charAt(i);
            }
        }
        initialLetterFName = initialLetterFName.replace(/_/g, "");

        return (
            <div>
                <div className="d-flex flex mb-2 mt-4">
                    <div className="">
                        <a href="#">
                            {
                                appMode == "sandbox" ?
                                    <GlobalImage isSandbox="true" firstname={initialLetterFName} isTeam="true" image={data.items.profilePicURL} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" />
                                    :
                                    <GlobalImage isSandbox="false" firstname={firstName.groups.title} isTeam="true" image={localStorage.getItem(global.ProfilePicURL)} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" />
                            }
                        </a>
                    </div>
                    <div className="align-self-center ml-2">
                        <div className="SimplurisFontSize13 SimplurisFontWeight400 SimplurisDashbordPostText Simpluris-bg-gray-post pl-3 pr-3 p-2 rounded">
                            {data.items.postContent}
                        </div>
                        {(
                            () => {
                                if (postPersonResult != null) {
                                    return (
                                        <div className="SimplurisFontSize8 SimplurisFontWeight500 SimplurisDashbordPostText SimplurisDashboradActivitySpan mt-1 pl-2 SimplurisOpacity07">
                                            <span className="SimplurisTeamDetailsUserNameLable">
                                                <a href="#">{postPersonResult.groups.title.match(/[A-Z][a-z]+/g).join(" ")}</a>&nbsp;-&nbsp;
                                                {(
                                                    () => {
                                                        if (data.items.timestamp != null) {
                                                            var date = new Date(data.items.timestamp);
                                                            return (
                                                                <ReactTimeAgo date={date} />
                                                            )
                                                        }
                                                    }
                                                )()}
                                            </span>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className="SimplurisFontSize8 SimplurisFontWeight500 SimplurisDashbordPostText mt-4 pl-2 SimplurisOpacity07">
                                            <span className="SimplurisTeamDetailsUserNameLable">&nbsp;</span>
                                        </div>
                                    )
                                }
                            })()}
                    </div>
                </div>
                <hr />

            </div>
        )
    } else {
        return null;
    }
}

