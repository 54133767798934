import React, { Component, useState, useEffect } from 'react';
import Header from '../../../../components/outer-header';
import BrandmarkLogo from '../../../../../static/img/simpluris-logo-brandmark.png';
import { Link } from 'react-router-dom';


function CaseSecureFileNotAccessible() {

    useEffect(() => {

    }, [])

    return (
        <>
            <div className="layout-column bg-white">
                <Header page="not-allowed-to-case" />
                <div className="d-flex flex-column flex">
                    <div>
                        <div className="p-3">
                            <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                                <div className="row no-gutters">
                                    <div className="col-md-5 r-l">
                                        <div className="p-4 d-flex flex-column">
                                            <div className="d-block">
                                                <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right 27Mar2020 01:26 PM">
                                                    <img src={BrandmarkLogo} className="w-50" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6" id="content-body">
                                        <div className="p-4">

                                            <div className="login-header-section">
                                                <h4 className="SimplurisFontWeight400 13-01-2020_1">Case Secure File Inaccessible</h4>
                                                <p>
                                                    <small className="text-muted">
                                                        This case secure file is not accessible by your user account.
                                                    </small>
                                                </p>

                                                <Link to={"/cases"} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor mb-1 py-2 px-4">Take Me to My Cases</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CaseSecureFileNotAccessible