import React, { useRef, useState } from "react";
import _ from 'lodash'
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DashboardHeader from "../../components/dashboard-header";
import PermissionPage from "../../../permission";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import appSettings from 'appSettings.json'
import { AnlyticsPageLog } from "../../../global";
import { continueStatement } from "@babel/types";
import { IsNullOrEmptyString } from '../../../global';
import EdiText from 'react-editext';
import { appMode, labFeatures, fakeAPIData, allowPageLog } from '../../../api-config'

const caseID = 3569;
let teamMemberPersonID = 0;
const lastActivityID = 0;
const pageSize = 100;
let mailingID = 0;
const pageIndex = 0;
const sortColumn = null;
const sortColumnDir = null;
const searchValue = null;
const page = 0;
const sizePerPage = 100;
const sortOrder = null;
const searchText = null;
const modalitytypecode = 1;
const lastCaseID = 0;
const caseStatus = 0;
const caseStageId = 0;
const lastRecordID = 0;
const status = null;
let memberPersonID = 0;

//const defaultState = routes.reduce((obj, route) => {
//    obj[route] = null
//    return obj
//}, {})

const Wrapper = styled.div`
    padding: 100px;

    @media only screen and (max-width:768px){
        padding:20px;
    }
`;
const Route = styled.div`
  font-size: 20px;
  color: ${props => props.color};
`;

const APIStatusPage = ({ userPermissions }) => {

    const [caseID, setCaseID] = useState(localStorage.getItem(global.CaseID));
    const [mailingID, setMailingID] = useState(localStorage.getItem(global.TopMailingID));
    const [teamMemberID, setTeamMemberID] = useState(localStorage.getItem(global.TopTeamMemberPersonID));
    const [caseMemberPersonID, setCaseMemberPersonID] = useState(localStorage.getItem(global.TopCaseMemberPersonID));
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const [spinnerDisplayLive, setSpinnerDisplayLive] = useState(false);
    const [spinnerRetry, setSpinnerRetry] = React.useState(null);

    const [responses, setResponses] = React.useState([]);
    const [responsesLive, setResponsesLive] = React.useState([]);
    const [popoverAPICallID, setPopoverAPICallID] = React.useState(null);
    const [popoverAPICallIDForLive, setPopoverAPICallIDForLive] = React.useState(
        null
    );
    const [
        popoverAPICallIDForShowSqlQuery,
        setPopoverAPICallIDForShowSqlQuery
    ] = React.useState(null);
    const [copied, setCopied] = React.useState(false);
    const [copiedMessageID, setCopiedMessageID] = React.useState(null);

    const routes = [
        {
            url: `cases/${caseID}/detail`,
            isParentAPI: true,
            index: 0,
        },
        {
            url: `cases/${caseID}/milestones`,
            isParentAPI: true,
            index: 1,
        },
        {
            url: `cases/${caseID}/teammembers`,
            isParentAPI: true,
            index: 2,
        },
        {
            url: `cases/${caseID}/teammembers/${teamMemberID}/${lastActivityID}/${pageSize}`,
            isParentAPI: true,
            index: 3,
        },
        {
            url: `cases/${caseID}/classes`,
            isParentAPI: true,
            index: 4,
        },
        {
            url: `cases/${caseID}/recentactivity`,
            isParentAPI: true,
            index: 5,
        },
        {
            url: `cases/${caseID}/attorneys`,
            isParentAPI: true,
            index: 6,
        },
        {
            url: `cases/${caseID}/caseinfo`,
            isParentAPI: true,
            index: 7,
        },
        {
            url: `cases/${caseID}/statistics/mailings`,
            isParentAPI: true,
            index: 8,
        },
        {
            url: `cases/${caseID}/mailtrackingdata`,
            isParentAPI: true,
            index: 9,
        },
        {
            url: `cases/${caseID}/mailings/${mailingID}/general`,
            isParentAPI: true,
            index: 10,
        },
        {
            url: `cases/${caseID}/mailings/${mailingID}/documents`,
            isParentAPI: true,
            index: 11,
        },
        {
            url: `cases/${caseID}/mailings/${mailingID}/${lastRecordID}/${pageSize}/bulksendevents`,
            isParentAPI: true,
            index: 12,
        },
        {
            url: `cases/${caseID}/mailings/${mailingID}/${pageIndex}/${pageSize}/${sortColumn}/${sortColumnDir}/${searchValue}/recipients`,
            isParentAPI: true,
            index: 13,
        },
        {
            url: `cases/${caseID}/responses/${page}/${sizePerPage}/${sortColumn}/${sortOrder}/${searchText}`,
            isParentAPI: true,
            index: 14,
        },
        {
            url: `cases/${caseID}/${lastActivityID}/${pageSize}`,
            isParentAPI: true,
            index: 15,
        },
        {
            url: `cases/${caseID}/feed/${pageIndex}/${pageSize}`,
            isParentAPI: true,
            index: 16,
        },
        {
            url: `cases/${caseID}/${lastActivityID}/${pageSize}/activity`,
            isParentAPI: true,
            index: 17,
        },
        {
            url: `cases/${caseID}/activitycounts`,
            isParentAPI: true,
            index: 18,
        },
        {
            url: `cases/projectssimple`,
            isParentAPI: true,
            index: 19,
        },
        {
            url: `cases/welcomecardsmodality/${modalitytypecode}/${lastCaseID}/${pageSize}`,
            isParentAPI: true,
            index: 20,
        },
        {
            url: `cases/dashcardsmodality/${modalitytypecode}/${caseStatus}`,
            isParentAPI: true,
            index: 21,
        },
        {
            url: `getmycases/null/${caseStageId}/${lastCaseID}/${pageSize}`,
            isParentAPI: true,
            index: 22,
        },
        {
            url: `cases/countsbystage`,
            isParentAPI: true,
            index: 23,
        },
        {
            url: `cases/searchuniversal/${searchText}`,
            isParentAPI: true,
            index: 24,
        },
        {
            url: `cases/dashboard/${lastCaseID}/${pageSize}`,
            isParentAPI: true,
            index: 25,
        },
        {
            url: `cases/${caseID}/casemembers/${page}/${sizePerPage}/${sortColumn}/${sortOrder}/${searchText}/${status}`,
            isParentAPI: true,
            index: 26,
        },
        {
            url: `cases/${caseID}/casemembers/${caseMemberPersonID}/${page}/${sizePerPage}`,
            isParentAPI: true,
            index: 27,
        },
        {
            url: `cases/${caseID}/distributions`,
            isParentAPI: true,
            index: 28,
        },
        {
            url: `cases/${caseID}/caselivereport`,
            isParentAPI: true,
            index: 29,
        },
        {
            url: `cases/${caseID}/null/null/getcallsforcase`,
            isParentAPI: true,
            index: 30,
        },
        {
            url: `cases/${caseID}/casemembers/paymentdetail`,
            isParentAPI: true,
            index: 31,
        },
        {
            url: `cases/${caseID}/casemembers/responsesdetail`,
            isParentAPI: true,
            index: 32,
        },
        {
            url: `cases/${caseID}/casedocument/${page}/${sizePerPage}/${sortColumn}/${sortOrder}/${searchText}`,
            isParentAPI: true,
            index: 33,
        }
    ];

    const [apiControllers, setApiControllers] = React.useState([]);
    const [fakeAPIController, setFakeAPIController] = React.useState([]);

    const [healthAPIResponse, setHealthAPIResponse] = React.useState([]);

    React.useEffect(() => {
        var newRoutes = routes.filter(x => x.isParentAPI == true);

        ReRunFakeAPIClick(newRoutes);
        ReRunAPIClick(newRoutes);
        GetHealthResult();
        AnlyticsPageLog("Api Satus page");
    }, []);

    const RetryLiveCall = id => {
        var routeUrl = routes.filter(x => {
            return (x.index == id)
        });
        ReRunAPIClick(routeUrl);
        setSpinnerRetry(id);
    };

    const TogglePopoverForFakeError = id => {
        setPopoverAPICallID(id);
    };

    const TogglePopoverForLiveError = id => {
        setPopoverAPICallIDForLive(id);
    };

    const TogglePopoverForLiveSqlQuery = id => {
        setPopoverAPICallIDForShowSqlQuery(id);
    };

    const CopySqlQuery = e => {
        setCopiedMessageID(e);
        setCopied(true);
        setTimeout(function () {
            setCopied(false);
        }, 1000);
    };

    const HandleReRunAPICall = () => {
        setResponsesLive([]);

        apiControllers.map(async route => {
            route.abort();
        });

        setApiControllers([])

        var newRoutes = routes.filter(x => x.isParentAPI == true);
        ReRunAPIClick(newRoutes);
    }

    const GetHealthResult = async () => {

        const res = await fetch(`${global.API_URL}health`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(global.AuthTokenKey)
            }
        });

        const json = await res.json();
        setHealthAPIResponse(json);

        console.log(json);
    }

    const ReRunAPIClick = (newRoutes) => {
        setSpinnerDisplayLive(false);
        let controller;
        let signal;

        if (newRoutes.length > 0) {
            newRoutes.forEach(async route => {
                controller = new AbortController;
                signal = controller.signal;

                //apiControllers.push(controller);

                setApiControllers(prevResponses => [
                    ...prevResponses,
                    controller
                ]);


                try {
                    var startTimeForLiveAPI = new Date().getTime();
                    const res = await fetch(`${global.API_URL}${route.url}`, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem(global.AuthTokenKey)
                        },
                        signal
                    });

                    const json = await res.json();
                    if ((json.data ? json.data.resultCode : json.resultCode) === "SUCCESS") {
                        setSpinnerRetry(null);

                        setResponsesLive(prevResponses => ({
                            ...prevResponses,
                            [route.url]: {
                                Status: "success",
                                time: json.data ? json.data.resultResponseTime : json.resultResponseTime,
                                sqlQuery: json.data ? json.data.sqlQuery : json.sqlQuery
                            }
                        }));
                    } else {
                        setSpinnerRetry(null);
                        setResponsesLive(prevResponses => ({
                            ...prevResponses,
                            [route.url]: {
                                Status: "fail",
                                ErrorMessage: json.data
                                    ? json.data.resultMessage
                                    : json.resultMessage,
                                time: json.data ? json.data.resultResponseTime : json.resultResponseTime,
                                sqlQuery: json.data ? json.data.sqlQuery : json.sqlQuery
                            }
                        }));
                    }
                } catch (e) {
                    setSpinnerRetry(null);
                    var endTimeForLiveAPI = new Date().getTime();
                    var totalTimeForLiveAPI =
                        (endTimeForLiveAPI - startTimeForLiveAPI) / 1000;
                    console.error(e);
                    setResponsesLive(prevResponses => ({
                        ...prevResponses,
                        [route.url]: {
                            Status: "fail",
                            ErrorMessage: e.message,
                            time: totalTimeForLiveAPI
                        }
                    }));
                }
            });
        }
    }

    const HandleReRunFakeAPICall = () => {
        setResponses([]);

        fakeAPIController.map(async route => {
            route.abort();
        });

        setFakeAPIController([]);

        ReRunFakeAPIClick();
    }

    const ReRunFakeAPIClick = () => {
        setSpinnerDisplay(false);
        var newRoutes = routes;//.filter(x => x.isParentAPI == true);
        let controller;
        let signal;

        newRoutes.forEach(async (route, i) => {

            controller = new AbortController;
            signal = controller.signal;

            //fakeAPIController.push(controller);

            setFakeAPIController(prevResponses => [
                ...prevResponses,
                controller
            ]);

            try {
                var startTimeForFakeAPI = new Date().getTime();
                const res = await fetch(`${global.API_URL}${route.url}/fake`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem(global.AuthTokenKey)
                    },
                    signal
                });

                const json = await res.json();

                if ((json.data ? json.data.resultCode : json.resultCode) === "SUCCESS") {

                    setResponses(prevResponses => ({
                        ...prevResponses,
                        [route.url]: {
                            Status: "success",
                            time: json.data ? json.data.resultResponseTime : json.resultResponseTime,
                        }
                    }));
                } else {

                    setResponses(prevResponses => ({
                        ...prevResponses,
                        [route.url]: {
                            Status: "fail",
                            ErrorMessage: json.resultMessage,
                            time: json.data ? json.data.resultResponseTime : json.resultResponseTime,
                        }
                    }));
                }
            } catch (e) {
                var endTimeForFakeAPI = new Date().getTime();
                var totalTimeForFakeAPI =
                    (endTimeForFakeAPI - startTimeForFakeAPI) / 1000;
                console.error(e);
                setResponses(prevResponses => ({
                    ...prevResponses,
                    [route.url]: {
                        Status: "fail",
                        ErrorMessage: e.message,
                        time: totalTimeForFakeAPI
                    }
                }));
            }

        });

    }

    const ReRunHealthAPI = () => {
        setHealthAPIResponse([]);
        GetHealthResult();
    }

    return (
        <>
            {/*<DashboardHeader IsStatusPage={true} />*/}
            <>
                <div className="card SimplrisGlobalApiStatusPanel">
                    <div className="card-header SimplrisGlobalApiStatusPanelHeader">
                        <div className="row">
                            <div className="col-md-12">API Settings Values</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-2">appMode</div>
                            <div className="col-md-10">{String(appMode)}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">labFeatures</div>
                            <div className="col-md-10">{String(labFeatures)}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">fakeAPIData</div>
                            <div className="col-md-10">{String(fakeAPIData)}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">allowPageLog</div>
                            <div className="col-md-10">{String(allowPageLog)}</div>
                        </div>
                    </div>
                </div>


                <div className="card SimplrisGlobalApiStatusPanel">
                    <div className="card-header SimplrisGlobalApiStatusPanelHeader">
                        <div className="row">
                            <div className="col-md-12">Health Call Results
                                <a className="i-con-h-a" title="Retry" onClick={ReRunHealthAPI}>
                                    <i className="ml-2 i-con i-con-refresh"><i></i></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">

                            <div className="col-md-3">can Get To Basic Logic</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.canGetToBasicLogic ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.canGetToBasicLogic}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">can Get To Platform Database</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.canGetToPlatformDatabase ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.canGetToPlatformDatabase}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">can Read From Config Settings</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.canReadFromConfigSettings ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.canReadFromConfigSettings}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">can Save To Report Folder</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.canSaveToReportFolder ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.canSaveToReportFolder}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">can Send An Email</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.canSendAnEmail ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.canSendAnEmail}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">can Update Platform Database</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.canUpdatePlatformDatabase ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.canUpdatePlatformDatabase}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">can Upload Reports To Cloud</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.canUploadReportsToCloud ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : <span dangerouslySetInnerHTML={{
                                        __html: healthAPIResponse.canUploadReportsToCloud
                                }}></span>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">has Report Folder Ready To Use</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.hasReportFolderReadyToUse ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.hasReportFolderReadyToUse}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">last Known Case Title From Platform</div>
                            <div className="col-md-9">
                                {!healthAPIResponse.lastKnownCaseTitleFromPlatform ? <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                ></i> : healthAPIResponse.lastKnownCaseTitleFromPlatform}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card SimplrisGlobalApiStatusPanel">
                    <div className="card-header SimplrisGlobalApiStatusPanelHeader">
                        <div className="row">
                            <div className="col-md-12">API Variables In Use</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row py-1">

                            <div className="col-md-2">CaseID</div>
                            <div className="col-md-3" title="Click to edit CaseID">
                                <EdiText
                                    type="text"
                                    validationMessage="Please enter id."
                                    validation={val => val != ""}
                                    value={caseID}
                                    onSave={value => {
                                        setCaseID(value);
                                        setSpinnerDisplay(true);
                                        setSpinnerDisplayLive(true);
                                        setResponsesLive([]);
                                        setResponses([]);
                                        apiControllers.map(async route => {
                                            route.abort();
                                        });
                                        fakeAPIController.map(async route => {
                                            route.abort();
                                        });
                                    }}
                                    editButtonClassName="editlabel-editbutton styles_Editext__button__6H8n_ styles_Editext__edit_button__hthOZ"
                                />
                            </div>
                        </div>
                        <div className="row py-1">
                            <div className="col-md-2">MailingID</div>
                            <div className="col-md-3" title="Click to edit MailingID">

                                <EdiText
                                    type="text"
                                    validationMessage="Please enter value"
                                    validation={val => val != ""}
                                    value={mailingID}
                                    onSave={value => {
                                        setMailingID(!IsNullOrEmptyString(value) ? value : 0);
                                        setSpinnerDisplay(true);
                                        setSpinnerDisplayLive(true);
                                        setResponsesLive([]);
                                        setResponses([]);
                                        apiControllers.map(async route => {
                                            route.abort();
                                        });
                                        fakeAPIController.map(async route => {
                                            route.abort();
                                        });
                                    }}
                                    editButtonClassName="editlabel-editbutton styles_Editext__button__6H8n_ styles_Editext__edit_button__hthOZ"
                                />
                            </div>
                        </div>
                        <div className="row py-1">

                            <div className="col-md-2">TeamMemberID</div>
                            <div className="col-md-3" title="Click to edit TeamMemberID">

                                <EdiText
                                    type="text"
                                    validationMessage="Please enter value"
                                    validation={val => val != ""}
                                    value={teamMemberID}
                                    onSave={value => {
                                        setTeamMemberID(!IsNullOrEmptyString(value) ? value : 0);
                                        setSpinnerDisplay(true);
                                        setSpinnerDisplayLive(true);
                                        setResponsesLive([]);
                                        setResponses([]);
                                        apiControllers.map(async route => {
                                            route.abort();
                                        });
                                        fakeAPIController.map(async route => {
                                            route.abort();
                                        });
                                    }}
                                    editButtonClassName="editlabel-editbutton styles_Editext__button__6H8n_ styles_Editext__edit_button__hthOZ"

                                />
                            </div>
                        </div>
                        <div className="row py-1">

                            <div className="col-md-2">CaseMemberPersonID</div>
                            <div className="col-md-3" title="Click to edit CaseMemberPersonID">

                                <EdiText
                                    type="text"
                                    validationMessage="Please enter value"
                                    validation={val => val != ""}
                                    value={caseMemberPersonID}
                                    onSave={value => {
                                        setCaseMemberPersonID(!IsNullOrEmptyString(value) ? value : 0);
                                        setSpinnerDisplay(true);
                                        setSpinnerDisplayLive(true);
                                        setResponsesLive([]);
                                        setResponses([]);
                                        apiControllers.map(async route => {
                                            route.abort();
                                        });
                                        fakeAPIController.map(async route => {
                                            route.abort();
                                        });
                                    }}
                                    editButtonClassName="editlabel-editbutton styles_Editext__button__6H8n_ styles_Editext__edit_button__hthOZ"

                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card SimplrisGlobalApiStatusPanel">
                    <div className="card-header SimplrisGlobalApiStatusPanelHeader">
                        <div className="row">
                            <div className="col-md-8">Status</div>
                            <div className="col-md-2">API</div>
                            <div className="col-md-2">Fake API</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8"></div>
                            <div className="col-md-2 h-100 d-table pl-0 mb-2">
                                <button className="btn mb-1 bg-dark-lt" onClick={HandleReRunAPICall} >
                                    <i className="mr-2 i-con i-con-repeat"><i></i></i>ReRun
                                </button>
                            </div>
                            <div className="col-md-2 h-100 d-table pl-0 mb-2">
                                <button className="btn mb-1 bg-dark-lt" onClick={HandleReRunFakeAPICall} >
                                    <i className="mr-2 i-con i-con-repeat"><i></i></i>ReRun
                                </button>
                            </div>
                        </div>
                        {routes.map((route, index) => {
                            let routeColor = spinnerDisplay ? '' : 'spinner';
                            let errorForFake

                            if (responses[route.url]) {
                                routeColor =
                                    responses[route.url].Status === 'success' ? 'limegreen' : 'red'
                            }

                            if (responses[route.url]) {
                                errorForFake =
                                    responses[route.url].Status === 'fail'
                                        ? responses[route.url].ErrorMessage
                                        : ''
                            }

                            let routeColorForLive = spinnerDisplayLive ? '' : 'spinner';
                            let errorForLive

                            if (responsesLive[route.url]) {
                                routeColorForLive =
                                    responsesLive[route.url].Status === 'success'
                                        ? 'limegreen'
                                        : 'red'
                            }

                            if (responsesLive[route.url]) {
                                errorForLive =
                                    responsesLive[route.url].Status === 'fail'
                                        ? responsesLive[route.url].ErrorMessage
                                        : ''
                            }

                            return (
                                <div key={index} className="row">
                                    <div className="col-md-8">
                                        <Route>{route.url}</Route>
                                    </div>
                                    <div className="col-md-2 h-100 d-table pl-0">
                                        {(() => {
                                            if (routeColorForLive == 'limegreen') {
                                                return (
                                                    <>
                                                        {(
                                                            () => {
                                                                if (spinnerRetry == index) {
                                                                    return (
                                                                        <i
                                                                            className="fa fa-spinner fa-spin"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <>
                                                                            <i
                                                                                className="fa fa-check-circle SimplurisApiCallSucessStatus align-middle"
                                                                                id={'Live' + index}
                                                                                aria-hidden="true"
                                                                            ></i>

                                                                            {(() => {
                                                                                if (responsesLive[route.url].time.toFixed(2) < 5) {
                                                                                    return (
                                                                                        <span className="badge badge-success text-uppercase align-middle ml-1">
                                                                                            {responsesLive[route.url].time.toFixed(2)} Sec
                                </span>
                                                                                    )
                                                                                } else if (
                                                                                    responsesLive[route.url].time.toFixed(2) >= 5 &&
                                                                                    responsesLive[route.url].time.toFixed(2) <= 10
                                                                                ) {
                                                                                    return (
                                                                                        <span className="badge badge-warning text-uppercase ml-1 align-middle">
                                                                                            {responsesLive[route.url].time.toFixed(2)} Sec
                                </span>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <span className="badge badge-danger text-uppercase ml-1 align-middle">
                                                                                            {responsesLive[route.url].time.toFixed(2)} Sec
                                </span>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                            <CopyToClipboard
                                                                                text={responsesLive[route.url].sqlQuery}
                                                                                onCopy={() => CopySqlQuery(index)}
                                                                            >
                                                                                <i
                                                                                    className="align-middle ml-1 SimplurisMouseRollOver"
                                                                                    id={'LiveSqlQuery' + index}
                                                                                    onMouseLeave={() =>
                                                                                        TogglePopoverForLiveSqlQuery(null)
                                                                                    }
                                                                                    onMouseEnter={() =>
                                                                                        TogglePopoverForLiveSqlQuery(index)
                                                                                    }
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                                </i>
                                                                            </CopyToClipboard>
                                                                            {copied &&
                                                                                copiedMessageID != null &&
                                                                                copiedMessageID == index ? (
                                                                                    <span className="text-primary pl-1 align-middle">
                                                                                        Copied.
                                                                </span>
                                                                                ) : null}
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        )()}
                                                        <a className="i-con-h-a" title="Retry">
                                                            <i className="ml-2 i-con i-con-refresh"
                                                                id={'repeat' + index}
                                                                onClick={() =>
                                                                    RetryLiveCall(index)
                                                                }
                                                            ><i></i></i>
                                                        </a>
                                                    </>
                                                )
                                            } else if (routeColorForLive == 'red') {
                                                return (
                                                    <>
                                                        {(
                                                            () => {
                                                                if (spinnerRetry == index) {
                                                                    return (
                                                                        <i
                                                                            className="fa fa-spinner fa-spin"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <>
                                                                            <i
                                                                                className="SimplurisApiCallFailStatus fa fa-exclamation-circle align-middle"
                                                                                id={'Live' + index}
                                                                                data-id={index}
                                                                                onMouseLeave={() => TogglePopoverForLiveError(null)}
                                                                                onMouseEnter={() =>
                                                                                    TogglePopoverForLiveError(index)
                                                                                }
                                                                                aria-hidden="true"
                                                                            ></i>

                                                                            {(() => {
                                                                                if (responsesLive[route.url].time.toFixed(2) < 5) {
                                                                                    return (
                                                                                        <span className="badge badge-success text-uppercase align-middle ml-1">
                                                                                            {responsesLive[route.url].time.toFixed(2)} Sec
                                </span>
                                                                                    )
                                                                                } else if (
                                                                                    responsesLive[route.url].time.toFixed(2) >= 5 &&
                                                                                    responsesLive[route.url].time.toFixed(2) <= 10
                                                                                ) {
                                                                                    return (
                                                                                        <span className="badge badge-warning text-uppercase ml-1 align-middle">
                                                                                            {responsesLive[route.url].time.toFixed(2)} Sec
                                </span>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <span className="badge badge-danger text-uppercase ml-1 align-middle">
                                                                                            {responsesLive[route.url].time.toFixed(2)} Sec
                                </span>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                            <CopyToClipboard
                                                                                text={responsesLive[route.url].sqlQuery}
                                                                                onCopy={() => CopySqlQuery(index)}
                                                                            >
                                                                                <i
                                                                                    className="align-middle ml-1 SimplurisMouseRollOver"
                                                                                    id={'LiveSqlQuery' + index}
                                                                                    onMouseLeave={() =>
                                                                                        TogglePopoverForLiveSqlQuery(null)
                                                                                    }
                                                                                    onMouseEnter={() =>
                                                                                        TogglePopoverForLiveSqlQuery(index)
                                                                                    }
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                                </i>
                                                                            </CopyToClipboard>
                                                                            {copied &&
                                                                                copiedMessageID != null &&
                                                                                copiedMessageID == index ? (
                                                                                    <span className="text-primary pl-1 align-middle">
                                                                                        Copied.
                            </span>
                                                                                ) : null}
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        )()}
                                                        <a className="i-con-h-a" title="Retry">
                                                            <i className="ml-2 i-con i-con-refresh"
                                                                id={'repeat' + index}
                                                                onClick={() =>
                                                                    RetryLiveCall(index)
                                                                }
                                                            ><i></i></i>
                                                        </a>
                                                    </>
                                                )
                                            } else if (routeColorForLive == 'spinner') {
                                                return (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        aria-hidden="true"
                                                    ></i>
                                                )
                                            } else { return null }
                                        })()}
                                    </div>
                                    <div className="col-md-2 h-100 d-table pl-0">
                                        {(() => {
                                            if (routeColor == 'limegreen') {
                                                return (
                                                    <>
                                                        <i
                                                            className="fa fa-check-circle SimplurisApiCallSucessStatus align-middle"
                                                            id={'Fake' + index}
                                                            aria-hidden="true"
                                                        ></i>
                                                        {(() => {
                                                            if (responses[route.url].time.toFixed(2) < 5) {
                                                                return (
                                                                    <span className="badge badge-success text-uppercase ml-1 align-middle">
                                                                        {responses[route.url].time.toFixed(2)} Sec
                                </span>
                                                                )
                                                            } else if (
                                                                responses[route.url].time.toFixed(2) >= 5 &&
                                                                responses[route.url].time.toFixed(2) <= 10
                                                            ) {
                                                                return (
                                                                    <span className="badge badge-warning text-uppercase ml-1 align-middle">
                                                                        {responses[route.url].time.toFixed(2)} Sec
                                </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <span className="badge badge-danger text-uppercase ml-1 align-middle">
                                                                        {responses[route.url].time.toFixed(2)} Sec
                                </span>
                                                                )
                                                            }
                                                        })()}
                                                    </>
                                                )
                                            } else if (routeColor == 'red') {
                                                return (
                                                    <>
                                                        <i
                                                            className="SimplurisApiCallFailStatus fa fa-exclamation-circle align-middle"
                                                            id={'Fake' + index}
                                                            data-id={index}
                                                            onMouseLeave={() => TogglePopoverForFakeError(null)}
                                                            onMouseEnter={() =>
                                                                TogglePopoverForFakeError(index)
                                                            }
                                                            aria-hidden="true"
                                                        ></i>
                                                        {(() => {
                                                            if (responses[route.url].time.toFixed(2) < 5) {
                                                                return (
                                                                    <span className="badge badge-success text-uppercase ml-1 align-middle">
                                                                        {responses[route.url].time.toFixed(2)} Sec
                                </span>
                                                                )
                                                            } else if (
                                                                responses[route.url].time.toFixed(2) >= 5 &&
                                                                responses[route.url].time.toFixed(2) <= 10
                                                            ) {
                                                                return (
                                                                    <span className="badge badge-warning text-uppercase ml-1 align-middle">
                                                                        {responses[route.url].time.toFixed(2)} Sec
                                </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <span className="badge badge-danger text-uppercase ml-1 align-middle">
                                                                        {responses[route.url].time.toFixed(2)} Sec
                                </span>
                                                                )
                                                            }
                                                        })()}
                                                    </>
                                                )
                                            } else if (routeColor == 'spinner') {
                                                return (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        aria-hidden="true"
                                                    ></i>
                                                )
                                            } else { return null }
                                        })()}
                                    </div>

                                    {(() => {
                                        if (routeColor == 'limegreen' || routeColor == 'red') {
                                            return (
                                                <Popover
                                                    placement="top"
                                                    isOpen={
                                                        popoverAPICallID != null && popoverAPICallID == index
                                                            ? true
                                                            : false
                                                    }
                                                    target={'Fake' + index}
                                                    toggle={() => TogglePopoverForFakeError(index)}
                                                >
                                                    <PopoverHeader></PopoverHeader>
                                                    <PopoverBody>
                                                        <div>{errorForFake}</div>
                                                    </PopoverBody>
                                                </Popover>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (
                                            routeColorForLive == 'limegreen' ||
                                            routeColorForLive == 'red'
                                        ) {
                                            return (
                                                <>
                                                    <Popover
                                                        placement="top"
                                                        isOpen={
                                                            popoverAPICallIDForLive != null &&
                                                                popoverAPICallIDForLive == index
                                                                ? true
                                                                : false
                                                        }
                                                        target={'Live' + index}
                                                        toggle={() => TogglePopoverForLiveError(index)}
                                                    >
                                                        <PopoverHeader></PopoverHeader>
                                                        <PopoverBody>
                                                            <div>{errorForLive}</div>
                                                        </PopoverBody>
                                                    </Popover>

                                                    <Popover
                                                        placement="top"
                                                        isOpen={
                                                            popoverAPICallIDForShowSqlQuery != null &&
                                                                popoverAPICallIDForShowSqlQuery == index
                                                                ? true
                                                                : false
                                                        }
                                                        target={'LiveSqlQuery' + index}
                                                        toggle={() => TogglePopoverForLiveSqlQuery(index)}
                                                    >
                                                        <PopoverHeader></PopoverHeader>
                                                        <PopoverBody>
                                                            <div>Copy SQL for this API call.</div>
                                                        </PopoverBody>
                                                    </Popover>
                                                </>
                                            )
                                        }
                                    })()}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <PermissionPage /> */}
            </>
        </>
    )
};

export default connect((state, props) => ({}))(APIStatusPage);
