import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    BootstrapTable,
    TableHeaderColumn,
    SearchField
} from "react-bootstrap-table";
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { SectionSpinner } from 'react/components/spinners'
import { simplurisFetch } from 'helpers'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { selectPermission } from 'state/case-detail-view/members/actions'

function CaseCampaignDetail({ userPermissions, selectPermission, selectCampaignCaseID, caseID, hash, campaignName }) {    
    var memberPermission;
    var exportDataPermission;
    var gridSearchTextPermission;
    var sendEmailPermission;
    var sortGridByColumnPermission;
    var viewEmailAdrressPermission;
    var viewFirstNamePermission;
    var viewLastNamePermission;
    var viewPersonIdPermission;
    var viewSimIdPermission;
    var viewStatusPermission;
    var viewAwardStatusPermission;
    var drillDownDetailPermission;

    if (Object.keys(userPermissions).length > 0) {
        drillDownDetailPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_CAMPAIGN_DRILL_DOWN_TO_DETAIL'
        )
        memberPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEW_IN_MENU'
        )

        exportDataPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MEMBER_DOWNLOAD_EXPORT_DATA'
        )

        gridSearchTextPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_FILTER_GRID_BY_SEARCH_TEXT'
        )
        sendEmailPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_SEND_DATA_EMAIL'
        )

        sortGridByColumnPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_SORT_GRID_BY_COLUMN'
        )
        viewEmailAdrressPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_EMAIL_ADDRESS'
        )

        viewFirstNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_FIRST_NAME'
        )
        viewLastNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_LAST_NAME'
        )

        viewPersonIdPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_PERSONID'
        )
        viewSimIdPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_SIMID'
        )
        viewStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_STATUS'
        )
        viewAwardStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_SETTLEMENT_AWARD_STATUS'
        )
    }

    const [caseCampaignsDetail, setCaseCampaignsDetail] = useState([]);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [isDataFetch, setIsDataFetch] = useState(false);

    const fetchCaseCampaignDetail = async (
        caseID
    ) => {
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/${parseInt(caseID)}/emailcampaign/detail/${hash}`
        })
            .then(result => {
                if (result.name !== undefined && result.name === "FetchError") {
                    setShowPageLoader(false);
                    setIsDataFetch(false);
                } else {                    

                    if (result.resultObject.length > 0) {
                        result.resultObject.map(item => { item.caseId = selectCampaignCaseID; item.mailingName = campaignName; return item; })
                    }                    
                    setCaseCampaignsDetail(result.resultObject);
                    setTotalCount(result.resultObject.length);
                    setShowPageLoader(false);
                    setIsDataFetch(true);
                }
            })
            .catch(e => {
                console.error("Error in campaign", e, e.errorObject);
                setShowPageLoader(false);
                setIsDataFetch(false);
            });
    };

    useEffect(() => {
        fetchCaseCampaignDetail(
            caseID
        );
    }, []);

    function setTableOption() {
        if (showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        }
        else {
            return "no data found!";
        }
    }

    function renderShowsTotal(start, to, total) {
        setTotalCount(total);
        return <></>;
    }

    const RemotePagination = ({
        data
    }) => (
            <div>
                <BootstrapTable
                    data={data}
                    remote={false}
                    hover={true}
                    options={{
                        noDataText: setTableOption(),
                        paginationShowsTotal: renderShowsTotal 
                    }}
                    pagination
                    style={{ "margin-bottom": "0px" }}
                    tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover SimplurisCampaignDetailGrid"
                    bodyContainerClass="text-grid-td"
                >
                    <TableHeaderColumn
                        width="65px"
                        dataField="caseId">Case ID</TableHeaderColumn>
                    <TableHeaderColumn
                        width="125px"
                        columnTitle={true}
                        dataField="mailingName">Campaign Name</TableHeaderColumn>
                    <TableHeaderColumn
                        width="80px"
                        dataField="personId">Member ID</TableHeaderColumn>
                    <TableHeaderColumn
                        width="100px"
                        columnTitle={true}
                        hidden={
                            viewFirstNamePermission && viewFirstNamePermission.length > 0
                                ? false
                                : true
                        }
                        dataField="memberFirstName">First Name</TableHeaderColumn>
                    <TableHeaderColumn
                        width="100px"
                        columnTitle={true}
                        hidden={
                            viewLastNamePermission && viewLastNamePermission.length > 0
                                ? false
                                : true
                        }
                        dataField="memberLastName">Last Name</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="emailAddress"
                        width="200px"
                        columnTitle={true}
                        hidden={
                            viewEmailAdrressPermission && viewEmailAdrressPermission.length > 0
                                ? false
                                : true
                        }
                        isKey>Email</TableHeaderColumn>
                    <TableHeaderColumn
                        width="120px"
                        dataField="timeStampStart">Sent Date</TableHeaderColumn>
                    <TableHeaderColumn
                        width="80px"
                        hidden={
                            viewStatusPermission && viewStatusPermission.length > 0
                                ? false
                                : true
                        }
                        dataField="status">Status</TableHeaderColumn>
                    <TableHeaderColumn
                        width="100px"
                        columnTitle={true}
                        dataField="smtpResponse">SMTP Response</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );

    if (showPageLoader) {
        return <div className="row"> <SectionSpinner /></div>
    }

    return (
        <div className="card d-flex flex p-3">
            <div className="row">
                <div className="col-md-5 col-lg-5 col-xl-6 pt-4 mb-2">
                    <span className="SimplurisRecentDisbursementsChartTitle">
                        Campaign Detail
						</span>
                </div>
            </div>
            {(() => {
                if (isDataFetch) {
                    return (
                        <>
                            <RemotePagination
                                data={caseCampaignsDetail}
                            />
                        </>
                    )
                } else {
                    return (
                        <div className="row">
                            <SectionSpinner />
                        </div>
                    )
                }
            })()}
        </div>
    )
}

export default connect(
    (state, props) => ({
        previousCaseSubtabs: state.previousSubTab.subTabNames,
        userPermissions: getPermissionsForCase(state, props),
    }),
    {
        changeCaseSubtab,
        setPreviousCaseSubtab,
        selectPermission
        //selectCampaign,
    }
)(CaseCampaignDetail)
