import _ from 'lodash'

import {
	SET_FILTERED_IDS,
	SET_DISTRIBUTION_ID_FILTERS,
	SET_PAYMENT_STATUS_FILTERS,
} from './actions'
import { FETCH_CASE_LIVE_REPORT } from 'state/live-reports/actions'
import { FETCH_DISTRIBUTIONS_FOR_CASE } from 'state/distributions/actions'

const initialState = {
	filteredIDs: null,
	distributionIDFilters: {},
	paymentStatusFilters: {},
}

export default (
	state = initialState,
	{ type, filteredIDs, distributionIDs, caseLiveReport, distributions, paymentStatusFilters }
) => {
	switch (type) {
		case FETCH_CASE_LIVE_REPORT:
        case FETCH_DISTRIBUTIONS_FOR_CASE:                    
			const distributionData = (caseLiveReport && caseLiveReport.distributions) || distributions || []
            
			// Set options for the filters based on data returned to us
			const distributionIDFilterDefaults = distributionData.reduce(
				(defaults, { distributionID }) => {
					defaults[distributionID] = true
					return defaults
				},
				{}
			)
            
			const allPayments = _.flatMap(distributionData, ({ payments }) => payments)
			const uniquePaymentStatuses = [...new Set(allPayments.map(({ paymentStatus }) => paymentStatus))]
			const paymentStatusFilterDefaults = uniquePaymentStatuses.reduce(
				(filters, status) => {
					filters[status] = true
					return filters
				},
				{}
			)
            
			return {
				...state,
				distributionIDFilters: distributionIDFilterDefaults,
				paymentStatusFilters: paymentStatusFilterDefaults,
				filteredIDs: null,
			}
		case SET_FILTERED_IDS:
			return { ...state, filteredIDs }
		case SET_DISTRIBUTION_ID_FILTERS:
			return {
				...state,
				distributionIDFilters: {
					...state.distributionIDFilters,
					...distributionIDs,
				},
			}
		case SET_PAYMENT_STATUS_FILTERS:
			return {
				...state,
				paymentStatusFilters: {
					...state.paymentStatusFilters,
					...paymentStatusFilters,
				},
			}
		default:
			return state
	}
}
