import * as React from 'react'
import { connect } from 'react-redux'
import { DatePicker } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

// Antd css overwrites a lot of default css, as apparently they intend you to
// start a new project with it and conform to their styles. This issue was
// marked as won't resolve, so it won't change anytime soon. As such we just
// pull in the individual styles needed for the specific component here
import 'antd/es/date-picker/style/index.css'
import 'antd/es/input/style/index.css'
import 'antd/es/time-picker/style/index.css'
import 'antd/es/tag/style/index.css'

import { changeResponseDateRange } from 'state/responses/actions'
import { getDateRange } from 'state/responses/selectors'

const { RangePicker } = DatePicker
const Wrapper = styled.div`
	width: 300px;
`

const ResponsesWeeksPicker = ({ dateRange, changeResponseDateRange }) => (
	<Wrapper>
		<RangePicker
			onChange={(momentObjects, [start, end]) =>
                changeResponseDateRange({ start, end })
			}
			defaultValue={[
				dateRange.start ? moment(dateRange.start) : null,
				dateRange.end ? moment(dateRange.end) : null,
			]}
			ranges={{
				'This Week': [moment().startOf('week'), moment().endOf('week')],
				'Last Week': [
					moment()
						.subtract(1, 'weeks')
						.startOf('week'),
					moment()
						.subtract(1, 'weeks')
						.endOf('week'),
				],
				'2 Weeks Ago': [
					moment()
						.subtract(2, 'weeks')
						.startOf('week'),
					moment()
						.subtract(2, 'weeks')
						.endOf('week'),
				],
				'3 Weeks Ago': [
					moment()
						.subtract(3, 'weeks')
						.startOf('week'),
					moment()
						.subtract(3, 'weeks')
						.endOf('week'),
				],
				'All Time': [null, null],
			}}
		/>
	</Wrapper>
)

export default connect(
	state => ({
		dateRange: getDateRange(state),
	}),
    { changeResponseDateRange }
)(ResponsesWeeksPicker)
