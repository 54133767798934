import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../components/button-loader';
import { simplurisFetch } from 'helpers'

function SetDefaultPaymentMethodCardConfirmationModalPopup(props) {    
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    function CloseSetDefaultPaymentMethodCardConfirmationModalPopup() {        
        props.setShowSetDefaultPaymentMethodCardConfirmationModalPopup(false);        
    }

    function SetDefaultCardByCardID(id) {        
        MakeDefaultCardByCardID(id);
    }

    const MakeDefaultCardByCardID = (paymentMethodID) => {
        setShowButtonLoader(true);

        simplurisFetch({
            url: "auth/" + paymentMethodID + "/setdefaultcard", method: 'POST'
        }).then((data) => {
            setShowButtonLoader(false);
            if (data.name == undefined && data.name != "FetchError") {
                CloseSetDefaultPaymentMethodCardConfirmationModalPopup();
                props.fetchAllCards();
            }
            }).catch(e => {
                console.error('Error in set default card', e, e.errorObject);
                setShowButtonLoader(false);
            });
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseSetDefaultPaymentMethodCardConfirmationModalPopup}>
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">Make Default</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-3 py-4">
                    <div className="SimplurisFontSize16 SimplurisFontWeight400">
                        Are you sure want to make default this card?
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-0">
                    <button type="button" className="btn btn-secondary rounded-0 w-sm btn-light py-3 mb-4 mt-3" onClick={CloseSetDefaultPaymentMethodCardConfirmationModalPopup}>No</button>
                    <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm btn-light py-3 mb-4 mt-3" onClick={() => SetDefaultCardByCardID(props.paymentMethodID)}>
                        {
                            showButtonLoader && (
                                <ButtonLoader />
                            )
                        }
                        {showButtonLoader && <span></span>}
                        {!showButtonLoader && <span>Yes</span>}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SetDefaultPaymentMethodCardConfirmationModalPopup
