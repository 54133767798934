import { createSelector } from 'reselect'

const stateSelector = state => state.caseDetailView.admin

export const getAllReleaseCallsForAdmin = createSelector(
	[stateSelector],
	state => state.fetching
)

export const getAllAccountRequestForAdmin = createSelector(
    [stateSelector],
    state => state.isFetchingAccountRequests
)

export const getAllMyCasesForAdmin = createSelector(
    [stateSelector],
    state => state.isFetchingMyCases
)

export const getAllUsersForAdmin = createSelector(
    [stateSelector],
    state => state.isFetchingAllUsers
)

export const getAllExceptionLogForAdmin = createSelector(
    [stateSelector],
    state => state.isFetchinAllExceptionLog,

)

export const getAllAppLogForAdmin = createSelector(
    [stateSelector],
    state => state.isFetchinAllAppLog
)