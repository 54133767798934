import { combineReducers } from 'redux'

import caseData from './data/reducer'
import view from './view/reducer'
import favoriteCases from './favorite-cases/reducer'

export default combineReducers({
	caseData,
    view,
    favoriteCases
})
