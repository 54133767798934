import { simplurisFetch } from 'helpers'
import { start, resolved } from 'state/helpers'
export const FETCH_ACCOUNT_REQUESTS = 'FETCH_ACCOUNT_REQUESTS'
export const FETCH_ADMIN_PERMISSION = "FETCH_ADMIN_PERMISSION"

export const fetchAccountRequestsDataForAdmin = (
    pageIndex,
    pageSize,
    pendingStatus,
    approveStatus,
    denyStatus,
    liveCaseRequest,
    sandboxRequest,
    searchValue,
    startDate,
    endDate,
    enriched,
    allEnriched,
    sortColumn,
    sortColumnDir
) => async dispatch => {
    try {
        dispatch({ type: start(FETCH_ACCOUNT_REQUESTS) })
        searchValue = searchValue == undefined ? null : searchValue
        startDate = startDate == undefined || startDate == "" ? null : startDate
        endDate = endDate == undefined || endDate == "" ? null : endDate


        const url = `auth/getallaccountrequestsforadmin/${pageIndex}/${pageSize}/${searchValue}/${startDate}/${endDate}/${pendingStatus}/${approveStatus}/${denyStatus}/${liveCaseRequest}/${sandboxRequest}/${enriched}/${allEnriched}/${sortColumn}/${sortColumnDir}`
        const { resultObject, resultObjectRecordCount } = await simplurisFetch({ url })
        
        dispatch({
            type: resolved(FETCH_ACCOUNT_REQUESTS),
            accountRequests: {
                accountRequests: resultObject,
                pageIndex: pageIndex,
                pageSize: pageSize,
                pendingStatus: pendingStatus,
                approveStatus: approveStatus,
                denyStatus: denyStatus,
                liveCaseRequest: liveCaseRequest,
                sandboxRequest: sandboxRequest,
                searchValue: searchValue,
                startDate: startDate,
                endDate: endDate,
                enrichedStatus: enriched,
                allEnrichedStatus: allEnriched,
                sortColumn: sortColumn,
                sortColumndir:sortColumnDir,
                totalCount: resultObjectRecordCount
            }
        })


    } catch (e) {
        console.error('Error in get all account requests for admin', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_ACCOUNT_REQUESTS),
            accountRequests: []
        })
    }
}

export const fetchPermissionForAdmin = () => async dispatch => {
    try {
        
        const url = `auth/fetchPermissionForAdmin`
        const { resultObject } = await simplurisFetch({ url })
               
        dispatch({
            type: FETCH_ADMIN_PERMISSION,
            adminPermission: resultObject,
        })


    } catch (e) {
        console.error('Error in fetchPermissionForAdmin for admin', e, e.errorObject)

        dispatch({
            type: FETCH_ADMIN_PERMISSION,
            adminPermission: []
        })
    }
}
