import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn, SearchField, } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { feacthMyCasesForAdmin } from 'state/admin/user-management/actions'
import { getAllMyCasesForAdmin } from 'state/case-detail-view/admin/selectors'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import ReportSubscribeModalPopup from './add-report-subscribe-modal'

let currentCaseID = 0;

const ManageCases = ({
    isFetchingMyCases,
    allMyCases = {},
    feacthMyCasesForAdmin,
    caseID,
    userPermissions,
}) => {

    
    if (isFetchingMyCases) {
        allMyCases = [];
        allMyCases.adminCaseObject = [];
        allMyCases.adminCaseObject.adminCases = [];
        allMyCases.pageIndex = 0;
        allMyCases.pageSize = 10;
        allMyCases.sortColumn = 1;
        allMyCases.sortColumnDir = 'asc';
        allMyCases.totalCount = 0;
    }

    var {
        pageIndex = Object.keys(allMyCases).length > 0 ? allMyCases.adminCaseObject.pageIndex : 1,
        pageSize = Object.keys(allMyCases).length > 0 ? allMyCases.adminCaseObject.pageSize : 10,
        sortColumn = Object.keys(allMyCases).length > 0 ? allMyCases.sortColumn : 'caseID',
        sortColumnDir = Object.keys(allMyCases).length > 0 ? allMyCases.sortColumnDir : 'asc',
        totalCount = Object.keys(allMyCases).length > 0 ? allMyCases.adminCaseObject.totalCount : 0,
        searchValue,
        data = [],
    } = allMyCases

    const [sortName, setSortName] = useState(Object.keys(allMyCases).length > 0 ? allMyCases.sortColumn : 'caseID');
    const [sortOrder, setSortOrder] = useState(Object.keys(allMyCases).length > 0 ? allMyCases.sortColumn : 'asc');
    
    const [Text, setText] = useState('')
    const [isVisibale, setIsVisibale] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [currentExpandCaseID, setCurrentExpandCaseID] = useState(0)

    useEffect(() => {
        try {
            
            allMyCases = {};
            feacthMyCasesForAdmin(1, 10, null,sortName, sortOrder);
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [])

    
    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }

    
    const tableOptions = {
        toolBar: createCustomToolBar,
        onlyOneExpanding: true,
        expandBy: 'column',
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        onSortChange: onSortChange,
        sortName: sortName,
        sortOrder: sortOrder,
        sortIndicator: true,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader(),
        onRowClick: onParentRowClick,
    }


    function onParentRowClick(row) {
        currentCaseID = 0;
        setCurrentExpandCaseID(row.caseID);
    }

    function isExpandableRow(row) {
        return true;
    }

    function expandComponent(row) {
        
        if (currentExpandCaseID == row.caseID) {
            return <GridForAllUserByCaseID caseID={row.caseID} currentExpandCaseID={currentExpandCaseID} />;
        }
        else
            return <></>;
    }

    
    function onSearchChange(searchText) {
        
        allMyCases.adminCaseObject.adminCases = [];
        
        searchValue = searchText
        setText(searchText)
        
        if (searchText == '') setText('')
        
        searchText = searchText == '' ? '' : searchText
        
        feacthMyCasesForAdmin(1, pageSize, searchText,sortName, sortOrder);
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        allMyCases.adminCaseObject.adminCases = [];
        feacthMyCasesForAdmin(page, sizePerPage, searchValue, sortName, sortOrder);
    }

    function onSizePerPageList(sizePerPage) {
        allMyCases.adminCaseObject.adminCases = [];
        feacthMyCasesForAdmin(1, pageSize,searchValue, sortName, sortOrder);
    }


    function showTableLoader() {
        if (isFetchingMyCases || showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }

    function onSortChange(sortName, sortOrder) {
        
        allMyCases = [];
        allMyCases.appLogs = [];
        setSortName(sortName);
        setSortOrder(sortOrder);
        
        feacthMyCasesForAdmin(1, pageSize, searchValue, sortName, sortOrder);
    }

    function linkCellFormatter(cell, row) {        
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}                    
                >
                    {cell}
                </a>
            );      
    }

    return (
        <div>
            <div className="row mb-4">

            </div>
            <div>
                {
                    Object.keys(allMyCases).length > 0 &&
                    <BootstrapTable
                        data={allMyCases.adminCaseObject.adminCases}                        
                        expandableRow={isExpandableRow}
                        expandComponent={expandComponent}
                        options={tableOptions}
                        tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover SimplurisMyCasesMainGrid SimplurisUserManagementMyCasesGridWrraper"
                        bodyContainerClass="text-grid-td"
                        hover
                        remote={true}
                        pagination={true}
                        search={true}
                        fetchInfo={{ dataTotalSize: totalCount }}
                        trClassName=""
                    >
                        <TableHeaderColumn dataSort={true} isKey dataField='caseID' width={'90'} dataFormat={linkCellFormatter}>CaseID</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='name' expandable={false}>Case Name</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='officialName' expandable={false}>Official Name</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='caseStageTitle' expandable={false}>Stage Title</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='caseStatusTitle' expandable={false}>Status</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='caseManagerName' expandable={false}>Manager Name</TableHeaderColumn>
                    </BootstrapTable>
                }

            </div>
        </div>
    )
}


export default connect(
    (state, props) => ({
        isFetchingMyCases: getAllMyCasesForAdmin(state, props),
        allMyCases: state.adminData.AllUserManagement,
        userPermissions: getPermissionsForCase(state, props)
    }),
    {
        feacthMyCasesForAdmin,
    }
)(ManageCases)

function GridForAllUserByCaseID(data) {
        
    const [Text, setText] = useState('')
    const [userData, setUserData] = useState([]);    
    const [pageIndex, setPageIndex] = useState(1);    
    const [pageSize, setPageSize] = useState(10);    
    const [totalCount, setTotalCount] = useState(0);    
    const [searchValue, setSearchValue] = useState();     
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [currentExpandUserID, setCurrentExpandUserID] = useState(0)
    const [currentCaseID, setCurrentCaseID] = useState(0)

    
    function getAllUserByCaseID(caseID, pageIndex, pageSize, searchValue) {        
        setShowPageLoader(true);
        searchValue = searchValue == undefined || searchValue == "" ? null : searchValue
        simplurisFetch({ url: `cases/getallusersbycaseid/${parseInt(caseID)}/${pageIndex}/${pageSize}/${searchValue}` }).then((result) => {
                        
            setShowPageLoader(false);
            if (result.resultCode !== "ERROR") {
                
                setUserData(result.resultObject); 
                setPageIndex(pageIndex)
                setPageSize(pageSize)
                setTotalCount(result.resultObjectRecordCount)                
            }
        })
            .catch(e => {
                setShowPageLoader(false);
                console.error("Error in getEmailDetailsForReportviewer for admin", e, e.errorObject);
            });
    }

    function showTableLoader() {
        
        if (userData && showPageLoader) {
            
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }    

    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }
    
    const tableOptions = {
        onlyOneExpanding: true,
        expandBy: 'column',
        toolBar: createCustomToolBar,
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader(),
        onRowClick: onParentRowClick,
    }

    function onParentRowClick(row) {
        
        if (row.platformPersonID > 0) {
            setCurrentExpandUserID(row.platformPersonID);
        }
    }

    function isExpandableRow(row) {
        if (row.platformPersonID > 0) {
            return true;
        }
        else
        {
            return false;
        }
    }

    function expandComponent(row) {
        
        if (row.platformPersonID > 0 && currentExpandUserID == row.platformPersonID) {
            return <GridForAllSubscribedReportByUserID userID={row.platformPersonID} currentCaseID={currentCaseID} currentExpandUserID={currentExpandUserID} />;
        }
        else
            return false;
    }

    
    function onSearchChange(searchText) {
        setUserData([]);
        
        setSearchValue(searchText)
        setText(searchText)
        
        if (searchText == '') setText('')
        
        setText(searchText == '' ? '' : searchText);
        
        getAllUserByCaseID(data.currentExpandCaseID, pageIndex, pageSize, searchText);
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        setUserData([]);
        getAllUserByCaseID(data.currentExpandCaseID, page, sizePerPage, searchValue);
    }

    function onSizePerPageList(sizePerPage) {
        
        setUserData([]);
        getAllUserByCaseID(data.currentExpandCaseID,1,sizePerPage,searchValue);
    }

        
    useEffect(() => {
        try {
            
            if (data.currentExpandCaseID == data.caseID) {
                setCurrentCaseID(data.currentExpandCaseID);
                getAllUserByCaseID(data.currentExpandCaseID, pageIndex, pageSize, searchValue);
            }
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [1])


    function linkCellFormatter(cell, row) {
        if (row.platformPersonID > 0) {
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}
                >
                    {cell}
                </a>
            );
        }
        else {
            return <span>{cell}</span>
        }
    }
    
        
        return (
            <BootstrapTable
                data={userData}
                expandableRow={isExpandableRow}
                expandComponent={expandComponent}
                tableContainerClass="table table-theme v-middle table-hover SimplurisSecondLevelGrid"
                bodyContainerClass="text-grid-td"
                hover
                options={tableOptions}
                remote={true}
                pagination={true}
                search={true}
                fetchInfo={{ dataTotalSize: totalCount }}
                trClassName=""
            >
                <TableHeaderColumn isKey dataField='platformPersonID' hidden>PlatformPersonID</TableHeaderColumn>
                <TableHeaderColumn dataField='fullName' dataFormat={linkCellFormatter}>Name</TableHeaderColumn>
                <TableHeaderColumn dataField='personEmail'>Email</TableHeaderColumn>
                <TableHeaderColumn dataField='personPhone1'>Phone Number</TableHeaderColumn>
                <TableHeaderColumn dataField='roleTitle'>Role Title</TableHeaderColumn>
            </BootstrapTable>
        )   
}

function GridForAllSubscribedReportByUserID(data) {
    
    const [Text, setText] = useState('')
    const [subscribedReportData, SetSubscribedReportData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [searchValue, setSearchValue] = useState();
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [showLoaderForIsSubscribed, setShowLoaderForIsSubscribed] = useState(false);
    
    const [showReportSubscriptionModalPopup, setShowReportSubscriptionModalPopup] = useState(false);
    const [caseIDForReportSubscribe, setCaseIDForReportSubscribe] = useState(0);
    const [reportIDForReportSubscribe, setReportIDForReportSubscribe] = useState(0);
    const [platformPersonIDForReportSubscribe, setPlatformPersonIDForReportSubscribe] = useState(0);
    const [reportCodeForSaveReportSubscribe, setReportCodeForSaveReportSubscribe] = useState('');
    const [reportTitleForSaveReportSubscribe, setReportTitleForSaveReportSubscribe] = useState('');
    const [caseOfficialName, setCaseOfficialName] = useState('');
    const [isSubscribedReportByModalPopup, setIsSubscribedReportByModalPopup] = useState(false);

    
    function getAllSubscribedReportByUserID(platformPersonID, currentCaseID, pageIndex, pageSize, searchValue) {
        
        SetSubscribedReportData([]);
        setShowPageLoader(true);
        searchValue = searchValue == undefined || searchValue == "" ? null : searchValue
        
        simplurisFetch({ url: `cases/getallsubscribedreportbyuserid/${platformPersonID}/${currentCaseID}/${pageIndex}/${pageSize}/${searchValue}` }).then((result) => {
            
            setShowPageLoader(false);
            if (result.resultCode !== "ERROR") {
                
                SetSubscribedReportData(result.resultObject);
                setPageIndex(pageIndex)
                setPageSize(pageSize)
                setTotalCount(result.resultObjectRecordCount)
            }
        })
            .catch(e => {
                setShowPageLoader(false);
                console.error("Error in getEmailDetailsForReportviewer for admin", e, e.errorObject);
            });
    }

    function showTableLoader() {
        
        if (subscribedReportData && showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }

 
    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }

    
    const tableOptions = {
        toolBar: createCustomToolBar,
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader(),
    }

    
    function onSearchChange(searchText) {
        SetSubscribedReportData([]);
        
        setSearchValue(searchText)
        setText(searchText)
        
        if (searchText == '') setText('')
        
        setText(searchText == '' ? '' : searchText);
        
        getAllSubscribedReportByUserID(data.currentExpandUserID, currentCaseID, pageIndex, pageSize, searchText);
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        SetSubscribedReportData([]);
        getAllSubscribedReportByUserID(data.currentExpandUserID, currentCaseID,page, sizePerPage, searchValue);
    }

    function onSizePerPageList(sizePerPage) {
        SetSubscribedReportData([]);
        getAllSubscribedReportByUserID(data.currentExpandUserID, currentCaseID, 1, sizePerPage, searchValue);
    }

    function CellFormatForToggleSubscribed(cell, row) {        
        if (row.isSubscribed == true) {
            return (
                <label className="ui-switch mt-1 mr-2">
                    <input type="checkbox" checked="checked" onClick={() => reportSubscribed(row.platformPersonID,row.caseID,row.reportID, false)} />
                    <i></i>
                </label>
            );
        }
        else {
            return (
                <label className="ui-switch mt-1 mr-2">
                    <input type="checkbox" checked={isSubscribedReportByModalPopup == false ? '' : 'checked'} onClick={() => OpenReportSubscriptionModalPopup(row.platformPersonID, row.caseID, row.officialCaseName, row.reportID, row.reportCode, row.title)} />
                    <i></i>
                </label>);
        }
    }

    function OpenReportSubscriptionModalPopup(platformPersonID, caseID, officialName, reportID, reportCode, title) {
        
        setCaseIDForReportSubscribe(caseID);
        setReportIDForReportSubscribe(reportID);
        setPlatformPersonIDForReportSubscribe(platformPersonID);
        setCaseOfficialName(officialName);
        setReportCodeForSaveReportSubscribe(reportCode);
        setReportTitleForSaveReportSubscribe(title);
        setShowReportSubscriptionModalPopup(true);
    }

    const reportSubscribed = (platformPersonID, caseID, reportID, value) => {
        
        saveIsSubscribedReportByUser(platformPersonID, caseID,reportID, value);
    }

    const saveIsSubscribedReportByUser = (platformPersonID, caseID, reportID, value) => {
                
        setShowLoaderForIsSubscribed(true);
        var data = {};
        data.CaseID = parseInt(caseID);
        data.ReportID = parseInt(reportID);
        data.IsSubscribeReport = value;
        data.PlatformPersonID = parseInt(platformPersonID);
        data.IsAdminByUpdateReportSubcribe = true;

        simplurisFetch({
            url: "cases/reportissubscribed", method: 'POST', body: JSON.stringify(data)
        }).then((res) => {      
            setShowLoaderForIsSubscribed(false);
            if (res.name != undefined && res.name == "FetchError") {
            }
            else {
                  
                SetSubscribedReportData([]);        
                getAllSubscribedReportByUserID(platformPersonID, currentCaseID,pageIndex, pageSize, searchValue)
            }
        }).catch(e => {            
            setShowLoaderForIsSubscribed(false);
            console.error('Error in saveIsSubscribedReportByUser', e, e.errorObject);
        });
    }

    
    useEffect(() => {
        try {            
            
            if (data.currentExpandUserID == data.userID) {                
                
                currentCaseID = data.currentCaseID;
                getAllSubscribedReportByUserID(data.currentExpandUserID, currentCaseID,pageIndex, pageSize, searchValue);
            }
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [1])

    if (showLoaderForIsSubscribed) {
        return <div className="row"> <SectionSpinner /></div>
    }

    
    return (
        <>
        <BootstrapTable
            data={subscribedReportData}
            tableContainerClass="table table-theme v-middle table-hover SimplurisThirdLevelGrid"
            bodyContainerClass="text-grid-td"
            hover
            options={tableOptions}
            remote={true}
            pagination={true}
            search={true}
            fetchInfo={{ dataTotalSize: totalCount }}            
        >
            <TableHeaderColumn
                dataField="reportID"
                width="80"
                isKey
            >
                Report ID
                                </TableHeaderColumn>
            <TableHeaderColumn
                columnTitle={true}
                dataField="caseID"
                width="80"
            >
                CaseID
                                </TableHeaderColumn>
            <TableHeaderColumn
                columnTitle={true}
                dataField="title"
                width="160"
            >
                Title
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="reportType"
                width="120"
            >
                Type
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="isSubscribed"
                dataFormat={CellFormatForToggleSubscribed}
                width="100"
            >
                Subscribed
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="email"
                width="150"
            >
                Send To
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="scheduleText"
                width="150"
            >
                Schedule
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="lastGeneratedDateFormat"
                width="150"
            >
                Last Sent
                                </TableHeaderColumn>
        </BootstrapTable>
            <ReportSubscribeModalPopup 
                OpenModalPopup={showReportSubscriptionModalPopup}
                setShowReportSubscriptionModalPopup={setShowReportSubscriptionModalPopup}
                caseIDForReportSubscribe={caseIDForReportSubscribe}
                reportIDForReportSubscribe={reportIDForReportSubscribe}
                reportCodeForSaveReportSubscribe={reportCodeForSaveReportSubscribe}
                reportTitleForSaveReportSubscribe={reportTitleForSaveReportSubscribe}
                platformPersonIDForReportSubscribe={platformPersonIDForReportSubscribe}
                caseOfficialName={caseOfficialName}
                getAllSubscribedReportByUserID={getAllSubscribedReportByUserID}
                setIsSubscribedReportByModalPopup={setIsSubscribedReportByModalPopup}
                isTabFrom="Mycases"/>
            </>
    )
}
