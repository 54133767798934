import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import GlobalImage from '../../../../components/global-image-set';
import ButtonLoader from '../../../../components/button-loader';
import { fetchMailingsActivity } from 'state/mailings/other/actions'
import { SectionSpinner } from 'react/components/spinners';
import _ from 'lodash'
import { getFetchingMailingActivities, getMailingsActivities, getSelectedMailingID } from 'state/case-detail-view/mailings/other/selectors'
let _pageSize = 10

function MailingActivity({ data, isFetchingMailingActivities, fetchMailingsActivity, MailingsActivites, caseID, selectedMailingID, isFirstClickActivity }) {


    const noMailingsActivities = !MailingsActivites || !MailingsActivites.caseMailingsActivities.length
    const showLoader = isFetchingMailingActivities && noMailingsActivities
    const showNullState = !isFetchingMailingActivities && noMailingsActivities
    let lastRecordId = (!noMailingsActivities && MailingsActivites.lastRecordIds) ? MailingsActivites.lastRecordIds : 0
    let isHasNewData = (!noMailingsActivities && MailingsActivites.isHasNewData) ? MailingsActivites.isHasNewData : false
    const [showPageLoader, setShowPageLoader] = useState(false)

    if (!noMailingsActivities) {
        MailingsActivites.caseMailingsActivities = _.uniqBy(MailingsActivites.caseMailingsActivities, 'rowNo');
    }


    React.useEffect(() => {
        //setTimeout(function () {
            //if (isFirstClickActivity) {
                fetchMailingsActivity({ caseID, mailingID: selectedMailingID, lastRecordId, _pageSize })
            //}
        //}, 300)
    }, [])


    const LoadMoreActivity = async () => {

        setShowPageLoader(true)
        await fetchMailingsActivity({ caseID, mailingID: selectedMailingID, lastRecordId, _pageSize })
        setShowPageLoader(false)
    }

    return (
        <div>
            {showLoader && <div className="card SimplurisDashboardCard">
                <SectionSpinner /></div>}
            {showNullState && <div className="text-center">No mailings activities available</div>}
            {
                !noMailingsActivities && MailingsActivites.caseMailingsActivities.map(item => {
                    let profilePic = item.personURL;
                    let fullName = item.firstName + ' ' + item.lastName;
                    let email = item.email;
                    return (
                        <div className="d-flex flex row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="card d-flex flex SimplurisDashboardCard SimplurisDashbordSecondaryCard position-relative">
                                    <div className="p-4">
                                        <div className="SimplurisFontSize10 SimplurisFontWeight700 SimplurisDashbordSecondaryHeaderText SimplurisOpacity03">
                                            {item.dataSentFormattedForHeader}
                                        </div>
                                        <div className="SimplurisFontSize16 SimplurisFontWeight400 SimplurisDashbordSecondaryHeaderText">
                                            {item.title}
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="col-md-12 col-xs-12 col-sm-12 px-4 pt-4 pb-1 bg-white SimplurisBorderRadius9">
                                            <div className="d-flex flex mb-4">
                                                <div className="d-none">
                                                    <a href="#">
                                                        <GlobalImage firstname={fullName} image={profilePic} email={email} dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt" isTeam="true" />
                                                    </a>
                                                </div>
                                                <div className="align-self-center ml-2">
                                                    {item.numberSent} mailings sent on {item.dataSentFormatted} by {item.firstName} {item.lastName}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                )
            }
            <div className="col-md-12 mt-2 text-center">
                {isHasNewData ? (
                    <button
                        className="btn w-md mb-4 mb-md-1 btn-light SimplurisDashbordPostText p-3"
                        onClick={LoadMoreActivity}
                        disabled={showPageLoader}>
                        {
                            showPageLoader && (
                                <ButtonLoader />
                            )
                        }
                        {showPageLoader && <span></span>}
                        {!showPageLoader && <span>Load More</span>}
                    </button>
                ) : null}
            </div>
        </div>
    )

}

export default connect(
    (state, props) => ({
        isFetchingMailingActivities: getFetchingMailingActivities(state, props),
        MailingsActivites: getMailingsActivities(state, props),
        selectedMailingID: getSelectedMailingID(state, props)
    }),
    { fetchMailingsActivity }
)(MailingActivity)