import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import LiveReportDatePicker from 'react/features/case-detail/content/live-report/date-picker'
import exportImage from 'static/img/export.png'
import ShareReportModalPopup from 'react/features/case-detail/content/live-report/share-report-modal'

const Pointer = styled.div`
  cursor: pointer;
`

const LiveReportHeaderContent = ({ caseID }) => {
    
    var exportDataPermission
    var sendEmailPermission

    // if (Object.keys(userPermissions).length > 0) {
    //     exportDataPermission = userPermissions.find(
    //         (permissionCode) => permissionCode == 'LC_LIVEREPORT_DOWNLOAD_EXPORT_DATA'
    //     )
    //     sendEmailPermission = userPermissions.find(
    //         (permissionCode) => permissionCode == 'LC_LIVEREPORT_SEND_DATA_EMAIL'
    //     )
    // }

    const [showModal, setShowModal] = useState(false)

    return (
        <div className="row align-items-center">
            <div className="col-md-8 col-sm-8 col-lg-8 col-12 d-flex">
                <LiveReportDatePicker />
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4 col-4 text-right">
                {(exportDataPermission && exportDataPermission.length > 0 && sendEmailPermission && sendEmailPermission.length > 0) ?
                    <Pointer>
                        <img src={exportImage} onClick={() => setShowModal(true)} />
                    </Pointer> : null
                }
                {(() => {
                    if (exportDataPermission && sendEmailPermission) {
                        return (
                            <ShareReportModalPopup
                                OpenModalPopup={showModal}
                                caseID={caseID}
                                setShowShareReportModalPopup={setShowModal}
                                // userPermissions={userPermissions}
                            />
                        )
                    } else {
                        return <></>
                    }
                })()}
            </div>
        </div>
    )
}
export default LiveReportHeaderContent
