import { PREVIOUS_CASE_SUBTAB } from './actions'

const initialState = {
    subTabNames: ['activity']
};
export default (state = initialState, { type, subtab }) => {
    switch (type) {
        case PREVIOUS_CASE_SUBTAB: {
            let subTabNames;
            if (state.subTabNames.length > 20) state.subTabNames = state.subTabNames.slice(state.subTabNames.length - 10, state.subTabNames.length);
            if (state.subTabNames[state.subTabNames.length - 1] != subtab) {
                subTabNames = [
                    ...state.subTabNames, subtab
                ]
            }
            else {
                subTabNames = [
                    ...state.subTabNames
                ]
            }
            return {
                subTabNames: subTabNames,
            };
        }
        default:
            return state
    }
}