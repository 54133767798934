import { FETCH_ADMIN_PERMISSION } from 'state/admin/account-requests/actions'

const initialState = {}

export default (
  state = initialState,
    { type,  adminPermission }
) => {
  switch (type) {
      case FETCH_ADMIN_PERMISSION:             
          return adminPermission
    default:
      return state
  }
}
