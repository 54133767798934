import * as React from 'react'
import styled from 'styled-components'

import { numberToDollars } from 'react/helpers'

const ColorWrapper = styled.div`
	color: ${({ index }) => index > 3 ? 'black' : 'white'};
`
const Ellipsis = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`
const Subtext = styled(Ellipsis)`
	font-size: 12px;
	opacity: .6;
`

const DisbursementsTreemapLeafTitle = ({ title, number, total, index }) => (    
	<ColorWrapper index={index}>
		<Ellipsis>{title}</Ellipsis>
		<Subtext>{number} Payments</Subtext>
		<Subtext>Totalling {numberToDollars(total,false)}</Subtext>
	</ColorWrapper>
)

export default DisbursementsTreemapLeafTitle
