import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Treemap from './treemap'
import BarChart from './bar-chart'
import FilterButton from './filter-button'
import {
  getDistributionDataForCase,
  getFilteredPaymentsForCase,
} from 'state/distributions/selectors'
import { getPaymentStatusFilters } from 'state/case-detail-view/distributions/selectors'
import { setDistributionIDFilters } from 'state/case-detail-view/distributions/actions'
import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'
import SecureAccessToggle from 'react/components/secure-access-toggle'
import { simplurisFetch } from 'helpers'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`
const Button = styled(FilterButton)`
  background-color: ${props =>
    props.active ? '#EBF2F7' : '#F8FAFC'} !important;
  margin: 0 !important;
  padding: 0.5rem !important;
  width: 200px;
  border-radius: 0 !important;
`
const Spacer = styled.div`
  height: ${props => props.height}px;
`
const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DistributionsCharts = ({ hasPermission, DateRangeStartDate, DateRangeEndDate, ...props }) => {
    
  const [activeChart, setActiveChart] = React.useState('date')
    const allDistributionIDs = props.distributionData && props.distributionData.map(d => d.distributionID)
    const allDistributionsAsTrue = allDistributionIDs && allDistributionIDs.reduce((obj, id) => {
    obj[id] = true
    return obj
  }, {})
  const resetFilters = () => {
    props.setDistributionIDFilters(allDistributionsAsTrue)
    props.setFilteredIDs(null)
  }

  return (
    <div className="col-md-12">
      <div className="card p-4">
        <TitleBar>
          <span className="text-md text-dark">Recent Distributions</span>
          {/* <SecureAccessToggle
            checked={hasPermission}
            requestAccess={() =>
              simplurisFetch({
                url: `cases/${props.caseID}/newaccessrequest`,
                method: 'POST',
                json: true,
                body: JSON.stringify({
                  CaseID: props.caseID,
                  AccessRequestAreaTypeID: '1',
                }),
              })
            }
          /> */}
        </TitleBar>
        <Spacer height={20} />

        <Buttons>
          <Button
            label="By Date"
            active={activeChart === 'date'}
            onClick={() => {
              setActiveChart('date')
              resetFilters()
            }}
          />
          <Button
            label="By Payee Type"
            active={activeChart === 'payee'}
            onClick={() => {
              setActiveChart('payee')
              resetFilters()
            }}
          />
        </Buttons>

        <Spacer height={20} />

        {activeChart === 'date' ? (
                  <BarChart {...props} swapToTreemap={() => setActiveChart('payee')} DateRangeStartDate={DateRangeStartDate} DateRangeEndDate={DateRangeEndDate}  />
        ) : (
                      <Treemap {...props} DateRangeStartDate={DateRangeStartDate} DateRangeEndDate={DateRangeEndDate}/>
        )}
      </div>
    </div>
  )
}

export default connect(
  (state, props) => ({
    distributionData: getDistributionDataForCase(state, props),
    paymentStatusFilters: getPaymentStatusFilters(state, props),
    filteredPayments: getFilteredPaymentsForCase(state, props),
  }),
  { setDistributionIDFilters, setFilteredIDs }
)(DistributionsCharts)
