import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'
import ReportImage from '../../../../../static/img/report_inverse.png'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import ReportsTable from './reports-table'
import ReportsList from './reports-list'
import ReportsSubscribed from './reports-subscribed'
import ChooseReportTypeModalPopup from './choose-report-type'
import PdfReportViewer from './PdfReportViewer'
import { fetchCaseReportsData, resetCaseReportsData, fetchCaseDataCounts } from 'state/case-report/actions'

const ReportsScreen = ({
    fetchCaseReportsData,
    caseID,
    getReportsDetailComponent,
    userPermissions,
    resetCaseReportsData,
    fetchCaseDataCounts
}) => {    
    const [showChooseReportTypeModalPopup, setShowChooseReportTypeModalPopup] = useState(false);
    const [reportCount, setReportCount] = useState(0)
    const [searchValue, setSearchValue] = useState(null);
    const [dateSortOrder, setDateSortOrder] = useState(null);
    const [typeSortOrder, setTypeSortOrder] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('Date');
    const [reportFlag, setReportFlag] = useState('');
    const [dateSortActive, setDateSortActive] = useState(false);
    const [typeSortActive, setTypeSortActive] = useState(false);
    const [pdfURL, setPdfURL] = useState('');
    let localStoragePdfURL = localStorage.getItem(global.ReportPDFURL);


    var viewAllReportPermission
    var viewMyReportPermission
    var allowRunReportPermission
    let permissionViseRenderTab;
    
    if (Object.keys(userPermissions).length > 0) {
        
        viewAllReportPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_REPORTS_VIEW_ALL_REPORTS'            
        )

        viewMyReportPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_REPORTS_VIEW_MY_REPORTS'                
        )

        allowRunReportPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_REPORTS_RUN_REPORT'
        )
        
        if (!IsNullOrEmptyString(viewAllReportPermission)) {
            permissionViseRenderTab = 'All Reports';            
        }
        else if (!IsNullOrEmptyString(viewMyReportPermission)) {            
            permissionViseRenderTab = 'My Reports';            
        }
        else
        {            
            permissionViseRenderTab = 'Subscribed Reports';            
        }
    }

    
    const getReportsCount = function (Count) {
        setReportCount(Count)
    }

    function onSearchChange(searchValue) {
        resetCaseReportsData(caseID);
        if (searchValue == "") {
            searchValue = null;
        }
        setSearchValue(searchValue);
    }

    const onTabChange = (tab, pdfURL) => {
        setDateSortOrder('')
        setTypeSortOrder('')
        resetCaseReportsData(caseID);
        let reportFlag = tab;
        fetchCaseReportsData({
            caseID,
            sortColumn,
            sortOrder,
            reportFlag,
            searchValue
        });
        setReportFlag(tab);
    }

    const onSortChange = (sortColumn) => {
        resetCaseReportsData(caseID);
        let sortOrder;
        if (sortColumn == "Date") {
            if (dateSortOrder == "asc") {
                setDateSortOrder('desc');
                sortOrder = 'desc';
            } else {
                setDateSortOrder('asc');
                sortOrder = 'asc';
            }
            setDateSortActive(true);
            setTypeSortActive(false);
        }
        else {
            if (typeSortOrder == "asc") {
                setTypeSortOrder('desc');
                sortOrder = 'desc';
            } else {
                setTypeSortOrder('asc');
                sortOrder = 'asc';
            }
            setTypeSortActive(true);
            setDateSortActive(false);
        }
        setSortColumn(sortColumn);
        fetchCaseReportsData({
            caseID,
            sortColumn,
            sortOrder,
            reportFlag,
            searchValue
        });
    }

    useEffect(() => {
        try {            
            if (!IsNullOrEmptyString(localStoragePdfURL)) {             
                getReportsDetailComponent(caseID, 0, localStoragePdfURL, localStorage.getItem(global.ReportTitle), localStorage.getItem(global.ReportCreatedDate));
            }
            else {                
                setReportFlag('All Reports');
            }

            
            if (!IsNullOrEmptyString(permissionViseRenderTab)) {
                
                let reportFlag = permissionViseRenderTab;
                fetchCaseReportsData({
                    caseID,
                    sortColumn,
                    sortOrder,
                    reportFlag,
                    searchValue
                });
            }          
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [fetchCaseReportsData, caseID, searchValue, permissionViseRenderTab])

    function OpenChooseReportTypeModalPopup() {
        AnlyticsPageLog("Click on choose report type Modal Popup from report tab");
        setShowChooseReportTypeModalPopup(true);
    }

    return (
        <div>
            <div className="d-flex flex row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                    <div className="card d-flex flex mb-0">
                        <div className="card-body SimpulrisCardBody">
                            <table className="w-100 SimplurisCardHeaderTable">
                                <tr>
                                    <td>
                                        <span className="SimplurisMailingsEnvelopeSqure r avatar">
                                            <img src={ReportImage} className="SimplurisMailingsEnvelopeImage" />
                                        </span>
                                    </td>
                                    <td>
                                        <div className="SimplurisMailingTitle">
                                            Reports
                                                    </div>
                                        <div className="SimplurisMailingSubTitle">
                                            {reportCount} reports generated for this case
                                                    </div>
                                    </td>
                                    <td>

                                        {allowRunReportPermission && allowRunReportPermission.length > 0 &&
                                            <input type="button" onClick={() => OpenChooseReportTypeModalPopup()} className="btn btnRunReport" value="Run Report" />}
                                    </td>
                                </tr>
                            </table>

                            <div className="row d-none">
                                <div className="col-2 col-xl-1 pl-0 px-xl-0 px-lg-2">
                                    <span className="SimplurisMailingsEnvelopeSqure r avatar">
                                        <img src={ReportImage} className="SimplurisMailingsEnvelopeImage" />
                                    </span>

                                </div>
                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 align-self-center pl-xl-4 pl-lg-3 pl-md-3 SimplurisPaddingLeft23">
                                    <div className="SimplurisMailingTitle">
                                        Reports
                                                    </div>
                                    <div className="SimplurisMailingSubTitle">
                                        {reportCount} reports generated for this case
                                                    </div>

                                    {allowRunReportPermission && allowRunReportPermission.length > 0 &&
                                        <input type="button" onClick={() => OpenChooseReportTypeModalPopup()} className="btn btnRunReport" value="Run Report" />}
                                </div>
                            </div>
                        </div>
                        <div className="px-3 pt-3 pb-3 b-t">
                            <ul className="nav border-0 d-inline-flex SimplurisReportTabs" id="myTab" role="tablist">
                                {viewAllReportPermission && viewAllReportPermission.length > 0 &&
                                    <li className="nav-item">
                                    <a className={`nav-link SimplurisReportLink ${permissionViseRenderTab == 'All Reports' ? 'active show' : '' }`}
                                            id="allreport-tab" data-toggle="tab"
                                            onClick={() => onTabChange('All Reports')}
                                            href="#allreport" role="tab"
                                            aria-controls="allreport"
                                            aria-selected="true">All Reports</a>
                                    </li>
                                }
                                {viewMyReportPermission && viewMyReportPermission.length > 0 &&
                                    <li className="nav-item">
                                    <a className={`nav-link SimplurisReportLink ${permissionViseRenderTab == 'My Reports' ? 'active show' : '' }`}
                                            id="myreport-tab"
                                            data-toggle="tab"
                                            onClick={() => onTabChange('My Reports')}
                                            href="#myreport"
                                            role="tab"
                                            aria-controls="myreport"
                                            aria-selected="false">My Reports</a>
                                    </li>
                                }
                                <li className="nav-item">
                                    <a className={`nav-link SimplurisReportLink ${permissionViseRenderTab == 'Subscribed Reports' ? 'active show' : '' }`}
                                        id="subscribedreport-tab"
                                        data-toggle="tab"
                                        onClick={() => onTabChange('Subscribed Reports')}
                                        href="#subscribedreport"
                                        role="tab"
                                        aria-controls="subscribedreport"
                                        aria-selected="false">Subscribed Reports</a>
                                </li>
                            </ul>

                            <div className="d-inline-block position-relative float-right">
                                <input type="text" name="txtSearchCaseBank" id="txtSearchCaseBank"
                                    onChange={e => (setSearchValue(e.target.value), onSearchChange(e.target.value))}
                                    className="form-control SimplurisCaseBanktxtSearchCaseBank" />
                                <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content">
                {(
                    () => {
                        if (!IsNullOrEmptyString(reportFlag) || !IsNullOrEmptyString(permissionViseRenderTab)) {                            
                            switch (reportFlag || permissionViseRenderTab) {                                
                                case 'All Reports':
                                    return (
                                        <>
                                            <div className="d-flex flex row">
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <div className="row my-2 px-3 justify-content-center align-items-center">
                                                        <div className="col-md-6 col-8 col-sm-6">
                                                            <ul className="nav border-0 d-inline-flex" role="tablist">
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (dateSortActive == true ? 'active SimplurisTextLighPrimary' : '')}
                                                                        onClick={() => onSortChange('Date')}>
                                                                        <div className={"th-inner sortable both " + dateSortOrder}>
                                                                            Date
                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (typeSortActive == true ? 'active SimplurisTextLighPrimary' : '')}
                                                                        onClick={() => onSortChange('Type')}
                                                                    >
                                                                        <div className={"th-inner sortable both " + typeSortOrder}>
                                                                            Type
                                    </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 col-4 col-sm-6 text-right">
                                                            <ul className="nav border-0 d-inline-flex float-md-right ml-3 ml-md-0">
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink active show"
                                                                        id="reportList-tab" data-toggle="tab" href="#reportList" role="tab"
                                                                        aria-controls="reportList" aria-selected="true">
                                                                        <i class="mr-2 i-con i-con-list"><i></i></i>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink"
                                                                        id="reportGrid-tab" data-toggle="tab" href="#reportGrid" role="tab"
                                                                        aria-controls="reportGrid" aria-selected="false">
                                                                        <i class="i-con i-con-grid"><i></i></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id="reportList" role="tabpanel" aria-labelledby="reportList-tab">
                                                    <ReportsTable
                                                        caseID={caseID}
                                                        getReportsCount={getReportsCount}
                                                        getReportsDetailComponent={getReportsDetailComponent} />
                                                </div>
                                                <div className="tab-pane fade" id="reportGrid" role="tabpanel" aria-labelledby="reportGrid-tab">
                                                    <ReportsList
                                                        caseID={caseID}
                                                        getReportsCount={getReportsCount}
                                                        getReportsDetailComponent={getReportsDetailComponent} />
                                                </div>
                                            </div>

                                        </>
                                    )
                                    break;
                                case 'My Reports':
                                    return (
                                        <>
                                            <div className="d-flex flex row">
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <div className="row my-2 px-3 justify-content-center align-items-center">
                                                        <div className="col-md-6 col-8 col-sm-6">
                                                            <ul className="nav border-0 d-inline-flex" role="tablist">
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (dateSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Date')}>
                                                                        <div className={"th-inner sortable both " + dateSortOrder}>
                                                                            Date
                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (typeSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Type')}
                                                                    >
                                                                        <div className={"th-inner sortable both " + typeSortOrder}>
                                                                            Type
                                    </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 col-4 col-sm-6 text-right">
                                                            <ul className="nav border-0 d-inline-flex float-md-right ml-3 ml-md-0">
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink active show"
                                                                        id="reportList-tab" data-toggle="tab" href="#reportList" role="tab"
                                                                        aria-controls="reportList" aria-selected="true">
                                                                        <i class="mr-2 i-con i-con-list"><i></i></i>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink"
                                                                        id="reportGrid-tab" data-toggle="tab" href="#reportGrid" role="tab"
                                                                        aria-controls="reportGrid" aria-selected="false">
                                                                        <i class="i-con i-con-grid"><i></i></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id="reportList" role="tabpanel" aria-labelledby="reportList-tab">
                                                    <ReportsTable
                                                        caseID={caseID}
                                                        getReportsCount={getReportsCount}
                                                        getReportsDetailComponent={getReportsDetailComponent} />
                                                </div>
                                                <div className="tab-pane fade" id="reportGrid" role="tabpanel" aria-labelledby="reportGrid-tab">
                                                    <ReportsList
                                                        caseID={caseID}
                                                        getReportsCount={getReportsCount}
                                                        getReportsDetailComponent={getReportsDetailComponent} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                    break;
                                case 'Subscribed Reports':
                                    return (
                                        <>
                                            <div className="d-flex flex row hidden">
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <div className="row my-2 px-3 justify-content-center align-items-center">
                                                        <div className="col-md-6 col-8 col-sm-6">
                                                            <ul className="nav border-0 d-inline-flex" role="tablist">
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (dateSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Date')}>
                                                                        <div className={"th-inner sortable both " + dateSortOrder}>
                                                                            Date
                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (typeSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Type')}
                                                                    >
                                                                        <div className={"th-inner sortable both " + typeSortOrder}>
                                                                            Type
                                    </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 col-4 col-sm-6 text-right">
                                                            <ul className="nav border-0 d-inline-flex float-md-right ml-3 ml-md-0">
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink active show"
                                                                        id="reportList-tab" data-toggle="tab" href="#reportList" role="tab"
                                                                        aria-controls="reportList" aria-selected="true">
                                                                        <i class="mr-2 i-con i-con-list"><i></i></i>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink"
                                                                        id="reportGrid-tab" data-toggle="tab" href="#reportGrid" role="tab"
                                                                        aria-controls="reportGrid" aria-selected="false">
                                                                        <i class="i-con i-con-grid"><i></i></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ReportsSubscribed
                                                caseID={caseID}
                                                getReportsCount={getReportsCount}
                                                getReportsDetailComponent={getReportsDetailComponent} onTabChange={onTabChange} caseOfficialName="Test" />
                                        </>
                                    )
                                case 'PDFReportViewer':
                                    return (
                                        <>
                                            <div className="d-flex flex row">
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <div className="row my-2 px-3 justify-content-center align-items-center">
                                                        <div className="col-md-8 col-xs-8 col-sm-8">
                                                        </div>
                                                        <div className="col-md-4 col-xs-4 col-sm-4">
                                                            <a className="nav-link SimplurisDownloadPDFReportButton"
                                                                id="pdfreport"
                                                                href={pdfURL}
                                                                target="_blank"
                                                                download>Download PDF Report</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <PdfReportViewer PDFURL={pdfURL} />
                                        </>
                                    )
                                default:
                            }
                        }
                        else {
                            return <></>
                        }
                    }
                )()}

            </div>
            <ChooseReportTypeModalPopup
                OpenModalPopup={showChooseReportTypeModalPopup}
                setShowChooseReportTypeModalPopup={setShowChooseReportTypeModalPopup}
                caseID={caseID}
                onTabChange={onTabChange}
                getReportsDetailComponent={getReportsDetailComponent}
                fetchCaseDataCounts={fetchCaseDataCounts}/>
        </div>
    )
}

export default connect(
    (state, props) => ({
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames
    }),
    {
        changeCaseSubtab,
        setFilteredIDs,
        setPreviousCaseSubtab,
        fetchCaseReportsData,
        resetCaseReportsData,
        fetchCaseDataCounts
    }
)(ReportsScreen)
