import { combineReducers } from 'redux'

import subtab from './subtab/reducer'
import mailings from './mailings/reducer'
import caseActivity from './case-activity/reducer'
import members from './members/reducer'
import distributions from './distributions/reducer'
import admin from './admin/reducer'

export default combineReducers({
	subtab,
	mailings,
	caseActivity,
	members,
    distributions,
    admin
})
