import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn, SearchField, } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { feacthAllUsersForAdmin } from 'state/admin/user-management/actions'
import { getAllUsersForAdmin } from 'state/case-detail-view/admin/selectors'
import ReportSubscribeModalPopup from './add-report-subscribe-modal'

const ManageUsers = ({
    isFetchingAllUsers,
    allUsers = {},
    feacthAllUsersForAdmin,
    caseID,
    userPermissions,
}) => {

    


    if (isFetchingAllUsers) {
        allUsers = [];
        allUsers.adminUsers = [];
        allUsers.pageIndex = 0;
        allUsers.pageSize = 10;
        allUsers.sortColumn = 1;
        allUsers.sortColumnDir = 'asc';
        allUsers.totalCount = 0;
    }

    var {
        pageIndex = Object.keys(allUsers).length > 0 ? allUsers.pageIndex : 1,
        pageSize = Object.keys(allUsers).length > 0 ? allUsers.pageSize : 10,
        sortColumn = Object.keys(allUsers).length > 0 ? allUsers.sortColumn : 'fullName',
        sortColumnDir = Object.keys(allUsers).length > 0 ? allUsers.sortColumnDir : 'asc',
        totalCount = Object.keys(allUsers).length > 0 ? allUsers.totalCount : 0,
        searchValue,        
        data = [],
    } = allUsers

    const [sortName, setSortName] = useState(Object.keys(allUsers).length > 0 ? allUsers.sortColumn : 'fullName');
    const [sortOrder, setSortOrder] = useState(Object.keys(allUsers).length > 0 ? allUsers.sortColumn : 'asc');
    
    const [Text, setText] = useState('')
    const [isVisibale, setIsVisibale] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [liveCase, setLiveCase] = useState(true);
    const [forMyCases, setForMyCases] = useState(true);
    const [forAllCases, setForAllCases] = useState(false);
    const [filterType, setFilterType] = useState(1);
    const [currentExpandUserID, setCurrentExpandUserID] = useState(0)


    useEffect(() => {
        try {
            
            allUsers = [];
            feacthAllUsersForAdmin(1, 10, null,filterType,sortName, sortOrder);
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [])

    
    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }

    
    const tableOptions = {
        toolBar: createCustomToolBar,        
        onlyOneExpanding: true,
        expandBy: 'column',
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        onSortChange: onSortChange,
        sortName: sortName,
        sortOrder: sortOrder,
        sortIndicator: true,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader(),
        onRowClick: onParentRowClick,
    }

    function onParentRowClick(row) {
        
        if (row.platformPersonID > 0) {
            setCurrentExpandUserID(row.platformPersonID);
        }        
    }

    function isExpandableRow(row) {
        if (row.platformPersonID > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    function expandComponent(row) {
        
        if (row.platformPersonID > 0 && currentExpandUserID == row.platformPersonID) {
            return <GridForAllSubscribedReportByUserID userID={row.platformPersonID} currentExpandUserID={currentExpandUserID} />;
        }
        else
            return <></>;
    }


    
    function onSearchChange(searchText) {
        
        allUsers.adminUsers = [];
        
        searchValue = searchText
        setText(searchText)
        
        if (searchText == '') setText('')
        
        searchText = searchText == '' ? '' : searchText
        
        feacthAllUsersForAdmin(1, pageSize, searchText,filterType, sortName, sortOrder);
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        allUsers.adminUsers = [];
        feacthAllUsersForAdmin(page, sizePerPage, searchValue, filterType, sortName, sortOrder);
    }

    function onSizePerPageList(sizePerPage) {        
        allUsers.adminUsers = [];
        feacthAllUsersForAdmin(1, sizePerPage, searchValue,filterType, sortName, sortOrder);
    }

    function onSortChange(sortName, sortOrder) {
        
        allUsers = [];
        allUsers.appLogs = [];
        setSortName(sortName);
        setSortOrder(sortOrder);
        
        feacthAllUsersForAdmin(1, pageSize, searchValue,filterType, sortName, sortOrder);
    }

    function showTableLoader() {
        if (isFetchingAllUsers || showPageLoader) {            
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }



    const onFilterChange = status => {        
        allUsers = [];
        allUsers.adminUsers = [];
        if (status == 1) {
                                                
            setFilterType(status);
            feacthAllUsersForAdmin(1, pageSize, searchValue, status, sortName, sortOrder);
        } else if (status == 2) {
                                    
            setFilterType(status);
            feacthAllUsersForAdmin(1, pageSize, searchValue, status, sortName, sortOrder);
        } 
    };

    function linkCellFormatter(cell, row) {
        if (row.platformPersonID > 0) {
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}
                >
                    {cell}
                </a>
            );
        }
        else {
            return <span>{cell}</span>
        }
    }


    return (
        <div>
            <div className="row mb-4">
                <div className="SimplurisUserGridFilterButtonWrraperDiv SimplurisUserManagementWrapperGrid">
                    {
                        <>
                            <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-2 mt-xl-0 mt-lg-2 ml-4 mr-0 mr-md-2 mr-xl-2 mr-lg-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                        ${filterType == 1 ? 'active' : ''}`}
                                id="btnForMyCases"
                                onClick={e => {
                                    onFilterChange('1')
                                }}
                                aria-selected="false">for My Cases</button>
                            <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-2 mt-xl-0 mt-lg-2 ml-2 mr-0 mr-md-4 mr-xl-2 mr-lg-2 ml-3 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                        ${filterType == 2 ? 'active' : ''}`}
                                id="btnForAllCases"
                                onClick={e => {
                                    onFilterChange('2')
                                }}
                                aria-selected="false">for All Cases</button>
                        </>}                   

                </div>
            </div>
            <div>
                {
                    Object.keys(allUsers).length > 0 &&
                    <BootstrapTable
                        data={allUsers.adminUsers}
                        expandableRow={isExpandableRow}
                        expandComponent={expandComponent}
                        options={tableOptions}
                        tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover SimplurisMyUsersMainGrid SimplurisUserManagementAllUsersGridWrraper"
                        bodyContainerClass="text-grid-td"
                        hover
                        remote={true}
                        pagination={true}
                        search={true}
                        fetchInfo={{ dataTotalSize: totalCount }}
                        trClassName="SimplurisStatisticsGridMainTr SimplurisAdminCallReleaseChildMainTr"
                    >
                        <TableHeaderColumn isKey dataField='platformPersonID' hidden>Platform PersonID</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='fullName' dataFormat={linkCellFormatter}>Name</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='personEmail'>Email</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='personPhone1'>Phone Number</TableHeaderColumn>                        
                        <TableHeaderColumn dataSort={true} dataField='roleTitle'>Role</TableHeaderColumn>                        
                    </BootstrapTable>
                }
              
            </div>
        </div>
    )
}


export default connect(
    (state, props) => ({
        isFetchingAllUsers: getAllUsersForAdmin(state, props),
        allUsers: state.adminData.AllUserManagement.adminUserObject,
        userPermissions: getPermissionsForCase(state, props)
    }),
    {
        feacthAllUsersForAdmin,
    }
)(ManageUsers)


function GridForAllSubscribedReportByUserID(data) {
    
    const [Text, setText] = useState('')
    const [subscribedReportData, SetSubscribedReportData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [searchValue, setSearchValue] = useState();
    const [showPageLoader, setShowPageLoader] = useState(false);

    const [showLoaderForIsSubscribed, setShowLoaderForIsSubscribed] = useState(false);

    const [showReportSubscriptionModalPopup, setShowReportSubscriptionModalPopup] = useState(false);
    const [caseIDForReportSubscribe, setCaseIDForReportSubscribe] = useState(0);
    const [reportIDForReportSubscribe, setReportIDForReportSubscribe] = useState(0);
    const [platformPersonIDForReportSubscribe, setPlatformPersonIDForReportSubscribe] = useState(0);
    const [reportCodeForSaveReportSubscribe, setReportCodeForSaveReportSubscribe] = useState('');
    const [reportTitleForSaveReportSubscribe, setReportTitleForSaveReportSubscribe] = useState('');
    const [caseOfficialName, setCaseOfficialName] = useState('');
    const [isSubscribedReportByModalPopup, setIsSubscribedReportByModalPopup] = useState(false);


    
    function getAllSubscribedReportByUserID(platformPersonID, pageIndex, pageSize, searchValue) {
        
        SetSubscribedReportData([]);
        setShowPageLoader(true);
        searchValue = searchValue == undefined || searchValue == "" ? null : searchValue
        let caseID = 0;
        
        simplurisFetch({ url: `cases/getallsubscribedreportbyuserid/${platformPersonID}/${caseID}/${pageIndex}/${pageSize}/${searchValue}` }).then((result) => {            
            setShowPageLoader(false);
            if (result.resultCode !== "ERROR") {
                
                SetSubscribedReportData(result.resultObject);
                setPageIndex(pageIndex)
                setPageSize(pageSize)
                setTotalCount(result.resultObjectRecordCount)
            }
        })
            .catch(e => {
                setShowPageLoader(false);
                console.error("Error in getEmailDetailsForReportviewer for admin", e, e.errorObject);
            });
    }

    function showTableLoader() {
        
        if (subscribedReportData && showPageLoader) {
            
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }

    

    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }

    
    const tableOptions = {
        toolBar: createCustomToolBar,
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader(),
    }

    
    function onSearchChange(searchText) {
        SetSubscribedReportData([]);
        
        setSearchValue(searchText)
        setText(searchText)
        
        if (searchText == '') setText('')
        
        setText(searchText == '' ? '' : searchText);
        
        getAllSubscribedReportByUserID(data.currentExpandUserID, pageIndex, pageSize, searchText);
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        SetSubscribedReportData([]);
        getAllSubscribedReportByUserID(data.currentExpandUserID, page, sizePerPage, searchValue);
    }

    function onSizePerPageList(sizePerPage) {
        SetSubscribedReportData([]);
        getAllSubscribedReportByUserID(data.currentExpandUserID, 1, sizePerPage, searchValue);
    }

    function CellFormatForToggleSubscribed(cell, row) {

        if (row.isSubscribed == true) {
            return (
                <label className="ui-switch mt-1 mr-2">
                    <input type="checkbox" checked="checked" onClick={() => reportSubscribed(row.platformPersonID, row.caseID, row.reportID, false)} />
                    <i></i>
                </label>
            );
        }
        else {
            return (
                <label className="ui-switch mt-1 mr-2">
                    <input type="checkbox" checked={isSubscribedReportByModalPopup == false ? '' : 'checked'} onClick={() => OpenReportSubscriptionModalPopup(row.platformPersonID, row.caseID, row.officialCaseName, row.reportID, row.reportCode, row.title)} />
                    <i></i>
                </label>);
        }
    }

    function OpenReportSubscriptionModalPopup(platformPersonID, caseID, officialName, reportID,reportCode, title) {
        
        setCaseIDForReportSubscribe(caseID);
        setReportIDForReportSubscribe(reportID);
        setPlatformPersonIDForReportSubscribe(platformPersonID);
        setCaseOfficialName(officialName);
        setReportCodeForSaveReportSubscribe(reportCode);
        setReportTitleForSaveReportSubscribe(title);
        setShowReportSubscriptionModalPopup(true);
    }


    const reportSubscribed = (platformPersonID, caseID, reportID, value) => {
        
        saveIsSubscribedReportByUser(platformPersonID, caseID, reportID, value);
    }

    const saveIsSubscribedReportByUser = (platformPersonID, caseID, reportID, value) => {
        
        setShowLoaderForIsSubscribed(true);
        var data = {};
        data.CaseID = caseID;
        data.ReportID = reportID;
        data.IsSubscribeReport = value;
        data.PlatformPersonID = platformPersonID;
        data.IsAdminByUpdateReportSubcribe = true;

        simplurisFetch({
            url: "cases/reportissubscribed", method: 'POST', body: JSON.stringify(data)
        }).then((res) => {
            setShowLoaderForIsSubscribed(false);
            if (res.name != undefined && res.name == "FetchError") {
            }
            else {
                
                SetSubscribedReportData([]);
                getAllSubscribedReportByUserID(platformPersonID, pageIndex, pageSize, searchValue)
            }
        }).catch(e => {
            setShowLoaderForIsSubscribed(false);
            console.error('Error in saveIsSubscribedReportByUser', e, e.errorObject);
        });
    }


    useEffect(() => {
        try {
            
            if (data.currentExpandUserID == data.userID) {
                getAllSubscribedReportByUserID(data.currentExpandUserID, pageIndex, pageSize, searchValue);
            }
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [1])

    if (showLoaderForIsSubscribed) {
        return <div className="row"> <SectionSpinner /></div>
    }

    
    return (
        <>
        <BootstrapTable
            data={subscribedReportData}
            tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover callCenterStatisticsMainGrid"
            bodyContainerClass="text-grid-td"
            hover
            options={tableOptions}
            remote={true}
            pagination={true}
            search={true}
            fetchInfo={{ dataTotalSize: totalCount }}
            trClassName="SimplurisStatisticsGridMainTr SimplurisAdminCallReleaseChildMainTr"
        >
            <TableHeaderColumn
                dataField="reportID"
                width="80"
                isKey
            >
                Report ID
                                </TableHeaderColumn>
            <TableHeaderColumn
                columnTitle={true}
                dataField="caseID"
                width="80"
            >
                CaseID
                                </TableHeaderColumn>
            <TableHeaderColumn
                columnTitle={true}
                dataField="title"
                width="160"
            >
                Title
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="reportType"
                width="120"
            >
                Type
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="isSubscribed"
                dataFormat={CellFormatForToggleSubscribed}
                width="100"
            >
                Subscribed
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="email"
                width="150"
            >
                Send To
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="scheduleText"
                width="150"
            >
                Schedule
                                </TableHeaderColumn>
            <TableHeaderColumn
                dataField="lastGeneratedDateFormat"
                width="150"
            >
                Last Sent
                                </TableHeaderColumn>   
        </BootstrapTable>
        <ReportSubscribeModalPopup
                OpenModalPopup={showReportSubscriptionModalPopup}
                setShowReportSubscriptionModalPopup={setShowReportSubscriptionModalPopup}
                caseIDForReportSubscribe={caseIDForReportSubscribe}
                reportIDForReportSubscribe={reportIDForReportSubscribe}
                reportCodeForSaveReportSubscribe={reportCodeForSaveReportSubscribe}
                reportTitleForSaveReportSubscribe={reportTitleForSaveReportSubscribe}
                platformPersonIDForReportSubscribe={platformPersonIDForReportSubscribe}
                caseOfficialName={caseOfficialName}
                getAllSubscribedReportByUserID={getAllSubscribedReportByUserID}
                setIsSubscribedReportByModalPopup={setIsSubscribedReportByModalPopup}
                isTabFrom="MyUsers"/>
            </>
    )
}
