import React, { useState, useEffect } from 'react'
import Header from '../../components/outer-header'
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png'
import { Link } from 'react-router-dom'
import PageLoader from '../../components/page-loader'
import { simplurisFetch } from 'helpers'
import { AnlyticsPageLog } from "../../../global";

function AccountActivationPage(activationdata) {
    const [showPageLoader, setShowPageLoader] = useState(false)
    const [isWrongCode, setIsWrongCode] = useState(false)

    useEffect(() => {
        AnlyticsPageLog("Account Activation page");
        setShowPageLoader(true)

        simplurisFetch({
            url: "auth/accountactivation/" + activationdata.match.params.code
        }).then((data) => {
            setShowPageLoader(false);
            if (data.name != undefined && data.name == "FetchError") {
                if (data.errorObject.resultCode === 'ERROR') {
                    setIsWrongCode(false)
                }
            }
            else {
                setIsWrongCode(true);
            }
            }).catch(e => { console.error('Error in account activation', e, e.errorObject); setShowPageLoader(false); });       
    }, [activationdata.match.params.code])

    return (
        <div className="layout-column bg-white">
            <Header page="account-activation" />
            <div className="d-flex flex-column flex">
                <div>
                    <div className="p-3">
                        <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                            <div className="row no-gutters">
                                <div className="col-md-5 r-l">
                                    <div className="p-4 d-flex flex-column">
                                        <div className="d-block">
                                            <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right">
                                                <img src={BrandmarkLogo} className="w-50" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showPageLoader ? (
                                    <div className="col-md-5 align-self-center" id="step2-body">
                                        <h4 className="p-5">Processing Activation...</h4>
                                    </div>
                                ) : (
                                        <div className="col-md-5" id="step2-body">
                                            {isWrongCode ? (
                                                <div className="p-4">
                                                    <h4>Your account has been activated.</h4>
                                                    <Link to={'/sign-in'}>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3"
                                                        >
                                                            Continue
													</button>
                                                    </Link>
                                                </div>
                                            ) : (
                                                    <h4 className="p-5">
                                                        The link or activation code attempted is not valid.
                                                        Please check the email or request another activation
                                                        link to try again.
											</h4>
                                                )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPageLoader ? <PageLoader /> : null}
        </div>
    )
}

export default AccountActivationPage
