let backendHost;
const hostname = window && window.location && window.location.hostname;
let appModeValue;
let labFeaturesValue;
let fakeAPIDataValue;
let allowPageLogValue;
let useRequestAccessInsteadOfSignupValue;
let googleClientID;
let redirectMainURL;

if (hostname === 'livecase.simpluris.com' || hostname === 'www.livecase.simpluris.com') {
    backendHost = 'https://livecaseapi.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = false;
    fakeAPIDataValue = false;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='';
} else if (hostname === 'livecaseuat.simpluris.com' || hostname === 'www.livecaseuat.simpluris.com') {
    backendHost = 'https://livecaseapiuat.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = false;
    fakeAPIDataValue = false;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='';
} else if (hostname === 'livecaseqa.simpluris.com' || hostname === 'www.livecaseqa.simpluris.com') {
    backendHost = 'https://livecaseapiqa.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = false;
    fakeAPIDataValue = false;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='';    
} else if (hostname === 'livecasedev.simpluris.com' || hostname === 'www.livecasedev.simpluris.com') {
    backendHost = 'https://livecaseapidev.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = false;
    fakeAPIDataValue = false;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='';  
} else if (hostname === 'livecasedemo.simpluris.com' || hostname === 'www.livecasedemo.simpluris.com') {
    backendHost = 'https://livecasedemoapi.simpluris.com';
    appModeValue = "sandbox";
    labFeaturesValue = false;
    fakeAPIDataValue = true;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";  
    redirectMainURL ='';  
} else if (hostname === 'livecasedemouat.simpluris.com' || hostname === 'www.livecasedemouat.simpluris.com') {
    backendHost = 'https://livecasedemoapiuat.simpluris.com';
    appModeValue = "sandbox";
    labFeaturesValue = false;
    fakeAPIDataValue = true;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";  
    redirectMainURL ='';        
} else if (hostname === 'livecasedemoqa.simpluris.com' || hostname === 'www.livecasedemoqa.simpluris.com') {
    backendHost = 'https://livecasedemoapiqa.simpluris.com';
    appModeValue = "sandbox";
    labFeaturesValue = false;
    fakeAPIDataValue = true;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";  
    redirectMainURL ='';    
} else if (hostname === 'livecasedemodev.simpluris.com' || hostname === 'www.livecasedemodev.simpluris.com') {
    backendHost = 'https://livecasedemoapidev.simpluris.com';
    appModeValue = "sandbox";
    labFeaturesValue = false;
    fakeAPIDataValue = true;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";  
    redirectMainURL ='';             
} else if (hostname === 'platform.simpluris.com' || hostname === 'www.platform.simpluris.com') {
    backendHost = 'https://platformapi.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = false;
    fakeAPIDataValue = false;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='https://livecase.simpluris.com';
} else if (hostname === 'platformuat.simpluris.com' || hostname === 'www.platformuat.simpluris.com') {
    backendHost = 'https://platformapiuat.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = false;
    fakeAPIDataValue = false;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='https://livecaseuat.simpluris.com';
} else if (hostname === 'platformqa.simpluris.com' || hostname === 'www.platformqa.simpluris.com') {
    backendHost = 'https://platformapiqa.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = false;
    fakeAPIDataValue = false;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='https://livecaseqa.simpluris.com';
} else if (hostname === 'platformdev.simpluris.com' || hostname === 'www.platformdev.simpluris.com') {
    backendHost = 'https://platformapidev.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = true;
    fakeAPIDataValue = false;
    allowPageLogValue = true;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='https://livecasedev.simpluris.com';
} else if (hostname === 'testdrive.simpluris.com' || hostname === 'www.testdrive.simpluris.com') {
    backendHost = 'https://testdriveapi.simpluris.com';
    appModeValue = "sandbox";
    labFeaturesValue = false;
    fakeAPIDataValue = true;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='https://livecasedemo.simpluris.com';
} else if (hostname === 'testdriveuat.simpluris.com' || hostname === 'www.testdriveuat.simpluris.com') {
    backendHost = 'https://testdriveapiuat.simpluris.com';
    appModeValue = "sandbox";
    labFeaturesValue = false;
    fakeAPIDataValue = true;
    allowPageLogValue = false;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='https://livecasedemouat.simpluris.com';
} else if (hostname === 'livecaseteam1.simpluris.com' || hostname === 'www.livecaseteam1.simpluris.com') {
    backendHost = 'https://livecaseapiteam1.simpluris.com';
    appModeValue = "normal";
    labFeaturesValue = true;
    fakeAPIDataValue = false;
    allowPageLogValue = true;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='';
} else {
    backendHost = 'http://localhost:26591';
    appModeValue = "normal";
    labFeaturesValue = true;
    fakeAPIDataValue = false;
    allowPageLogValue = true;
    useRequestAccessInsteadOfSignupValue = true;
    googleClientID = "318396082890-3a5n6rmcdu5abq4atod8n7q09tas6gpf.apps.googleusercontent.com";
    redirectMainURL ='';
}

export const API_ROOT = `${backendHost}/`;
export const appMode = `${ appModeValue }`;
export const labFeatures = labFeaturesValue;
export const fakeAPIData = fakeAPIDataValue;
export const allowPageLog = allowPageLogValue;
export const useRequestAccessInsteadOfSignup = useRequestAccessInsteadOfSignupValue;
export const GoogleClientID = `${ googleClientID }`;
export const RedirectMainURL = redirectMainURL ;