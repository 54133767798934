import * as React from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import {
  getFilteredPaymentsForCase,
  getPaymentsForCaseLength,
  getUncashedPayments,
  getTotalDisbursed,
} from 'state/distributions/selectors'
import { SectionSpinner } from 'react/components/spinners'
import DistributionsCharts from '../shared/distributions-charts'
import {
  getToolbarWithTotal,
  getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { numberToDollars } from 'react/helpers'
import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'
import {
    selectMember,
    selectPermission,
    selectPaymentsDetail,
    selectResponsesDetail,
} from 'state/case-detail-view/members/actions'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { fetchDistributions } from 'state/distributions/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'
import NumberCard from './number-card'

const Distributions = ({
  caseID,
  filteredPayments,
  totalPayments,
  setFilteredIDs,
  selectMember,
  changeCaseSubtab,
  userPermissions,
  setPreviousCaseSubtab,
  previousCaseSubtabs,
  fetchDistributions,
  selectPermission,
  totalDisbursed,
    uncashedPayments,
    selectPaymentsDetail,
    selectResponsesDetail,
    data,
}) => {
    
    const [totalCount, setTotalCount] = React.useState('');
  React.useEffect(() => {
    fetchDistributions({ caseID })
  }, [caseID])

  var distributionsPermission
  var statChartPermission
  var dataEmailPermission
  var exportDataPermission
  var viewPersonIdPermission
  var viewSimIdPermission
  var viewFirstNamePermission
  var viewLastNamePermission
  var viewPayeeNamePermission
  var viewPaymentAmountPermission
  var viewPaymentDatePermission
  var viewSentAddressPermission
  var viewPaymentStatusPermission
  var viewBarChartPermission
  var paymentsTabPermission

if (Object.keys(userPermissions).length > 0) {
    paymentsTabPermission = userPermissions.find(
        (permissionCode) => permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_PAYMENT_VISUAL'
    )
    distributionsPermission = userPermissions.find(
      permissionCode => permissionCode == 'LC_BANKINGDISTRIBUTION_VIEW_IN_MENU'
    )
    statChartPermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEW_STATS_CHARTS'
    )

    dataEmailPermission = userPermissions.find(
      permissionCode =>
        permissionCode === 'LC_BANKINGDISTRIBUTION_SEND_DATA_EMAIL'
    )

    exportDataPermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_DOWNLOAD_EXPORT_DATA'
    )
    viewPersonIdPermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_PERSONID'
    )

    viewSimIdPermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_SIMID'
    )
    viewFirstNamePermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_FIRSTNAME'
    )

    viewLastNamePermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_LASTNAME'
    )
    viewPayeeNamePermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_PAYEE_NAME'
    )

    viewPaymentAmountPermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_PAYMENT_AMOUNT'
    )
    viewPaymentDatePermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_PAYMENT_DATE'
    )
    viewSentAddressPermission = userPermissions.find(
      permissionCode =>
        permissionCode ==
        'LC_BANKINGDISTRIBUTION_VIEWDATA_PAYMENT_SENT_TO_ADDRESS'
    )
    viewPaymentStatusPermission = userPermissions.find(
      permissionCode =>
        permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_PAYMENT_STATUS'
    )
    viewBarChartPermission = userPermissions.includes('LC_BANKINGDISTRIBUTION_VIEWDATA_BAR_CHART')
  }

  if (!filteredPayments) {
    return (
      <div className="row">
        {' '}
        <SectionSpinner />
      </div>
    )
    }
    
    function renderShowsTotal(start, to, total) {
        if (filteredPayments.length == total)
            setTotalCount(total + 1);
        else
            setTotalCount(total);
        return <></>;
    }

    const tableOptions = {        
        toolBar: getToolbarWithTotal({
            buttonText: `All ${totalCount} `,
        onButtonClick: () => {
        setFilteredIDs(null)
      },
    }),
      searchField: getSearchField({ defaultValue: '' }),
      paginationShowsTotal: renderShowsTotal 
    }

    
   


    const handleCellClick = ({ payeePersonID }) => {
        
        if (payeePersonID) {
            
            selectMember(payeePersonID)
            selectPermission({
                viewFirstNamePermission,
                viewLastNamePermission,
            })
            
            var paymentsDetail = true
            selectPaymentsDetail(paymentsDetail)
            var responsesDetail = false
            selectResponsesDetail(responsesDetail);
            
            changeCaseSubtab('member-detail')
            setPreviousCaseSubtab('distributions')
        }
    };

    function linkCellFormatter(cell, row) {
        
        if (paymentsTabPermission && paymentsTabPermission.length > 0) {
            
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}
                    onClick={() => handleCellClick(row)}
                >
                    {cell}
                </a>
            );
        } else {
            
            return <span>{cell}</span>;
        }
    }
    
    function numberToDollarsForDistributions(amount) {                
        return numberToDollars(amount, true);
    }

  if (distributionsPermission && distributionsPermission.length <= 0) {
    // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
    changeCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2])
    setPreviousCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2])
  }

  let isClosedStage = false;
    if (data && data.stage == "Closed") {
        isClosedStage = true;
    }

  return (
    <div className="row">
      <NumberCard
        title="Total Disbursed"
        number={isClosedStage == true ? 0 : totalDisbursed}
        popoverContent={
          <div>
            <b>Total Disbursed</b>
            <div>
                    {/*The gross total of all payments sent out to Members, Lead Plaintiffs, Administrator, Attorneys, and PAGA.*/}
                    The gross total of all payments sent out to Members.
            </div>
          </div>
        }
      />
      <NumberCard
        title="Uncashed Member Payments"
        number={isClosedStage == true ? 0 : uncashedPayments}
        // percentChange={55}
        popoverContent={
          <div>
            <b>Uncashed Member Payments</b>
            <div>
              The gross total of all payments sent out to class members through the check cashing deadline.
            </div>
          </div>
        }
      />
      {statChartPermission && statChartPermission.length > 0 ? (
        <DistributionsCharts
          caseID={caseID}
          payments={filteredPayments}
          onLeafClick={node => setFilteredIDs(node.data.ids)}
          hasPermission={viewBarChartPermission}
        />
      ) : (
        <></>
      )}

      <div className="col-md-12">
        {(() => {
                  if (
                      (viewFirstNamePermission && viewFirstNamePermission.length > 0) ||
                      (viewLastNamePermission && viewLastNamePermission.length > 0) ||
                      (viewPayeeNamePermission && viewPayeeNamePermission.length > 0) ||
                      (viewPaymentAmountPermission &&
                          viewLastNamePermission.length > 0) ||
                      (viewPaymentDatePermission &&
                          viewPaymentDatePermission.length > 0) ||
                      (viewPersonIdPermission && viewPersonIdPermission.length > 0)
                  ) {
                      if (isClosedStage == true) { return <></> } else {
                          return (
                              <>
                                  <BootstrapTable
                                      data={filteredPayments}
                                      options={tableOptions}
                                      tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                      bodyContainerClass="text-grid-td"
                                      hover
                                      pagination
                                      search
                                  >
                                      <TableHeaderColumn dataField="paymentID" isKey hidden />
                                      <TableHeaderColumn dataField="simid" width="90" dataSort>SIMID</TableHeaderColumn>

                                      {/* Hidden first and last names to allow them to be searchable */}
                                      {viewFirstNamePermission &&
                                          viewFirstNamePermission.length > 0 ? (
                                              <TableHeaderColumn dataField="firstName" dataSort>
                                                  First Name
                                              </TableHeaderColumn>
                                          ) : (
                                              null
                                          )}
                                      {viewLastNamePermission &&
                                          viewLastNamePermission.length > 0 ? (
                                              <TableHeaderColumn dataField="lastName" dataSort>
                                                  Last Name
                                              </TableHeaderColumn>
                                          ) : (
                                              null
                                          )}
                                      {viewPayeeNamePermission &&
                                          viewPayeeNamePermission.length > 0 ? (
                                              <TableHeaderColumn
                                                  dataField="payee"
                                                  dataSort
                                                  dataFormat={linkCellFormatter}
                                              >
                                                  Payee
                                              </TableHeaderColumn>
                                          ) : (
                                              null
                                          )}
                                      {viewPaymentAmountPermission &&
                                          viewPaymentAmountPermission.length > 0 ? (
                                              <TableHeaderColumn
                                                  dataField="amount"
                                                  dataSort
                                                  dataFormat={numberToDollarsForDistributions}
                                              >
                                                  Amount
                                              </TableHeaderColumn>
                                          ) : (
                                              null
                                          )}
                                      {viewPaymentDatePermission &&
                                          viewPaymentDatePermission.length > 0 ? (
                                              <TableHeaderColumn
                                                  dataField="paymentDate"
                                                  dataFormat={date => new Date(date).toLocaleDateString()}
                                                  dataSort
                                                  width="100"
                                              >
                                                  Date
                                              </TableHeaderColumn>
                                          ) : (
                                              null
                                          )}
                                  </BootstrapTable>
                              </>
                          )
                      }
                  }
        })()}
      </div>
    </div>
  )
}

export default connect(
  (state, props) => ({
    filteredPayments: getFilteredPaymentsForCase(state, props),
    totalPayments: getPaymentsForCaseLength(state, props),
    userPermissions: getPermissionsForCase(state, props),
    previousCaseSubtabs: state.previousSubTab.subTabNames,
    uncashedPayments: getUncashedPayments(state, props),
    totalDisbursed: getTotalDisbursed(state, props),
  }),
  {
    setFilteredIDs,
    selectMember,
    changeCaseSubtab,
    setPreviousCaseSubtab,
    fetchDistributions,
      selectPermission,
      selectPaymentsDetail,
      selectResponsesDetail,
  }
)(Distributions)
