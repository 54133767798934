import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PdfReportViewer from './PdfReportViewer'
import ReportImage from '../../../static/img/report_inverse.png'
import Brand from '../../components/header/brand'
import styled from 'styled-components'
import { IsNullOrEmptyString } from '../../../global';
import { Redirect } from 'react-router';

const Name = styled.div`
	display: flex;
	align-items: center;
`

const CircleIcon = styled.div`
	content: '';
	border-color: inherit;
	border-width: 2px;
	border-style: solid;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	transform: translateX(-50%);
	border-color: #137eff;
	box-shadow: 0 0 0px 4px rgba(19, 126, 255, 0.2);
	margin-right: 7px;
`

function ReportQuickView() {      
    return (
        <div>
            {
                IsNullOrEmptyString(localStorage.getItem(global.ReportPDFURL)) ? <Redirect to="/sign-in" /> : 
					<>
                        <header id="header" className="page-header scroll-up sticky">
                            <div className="navbar navbar-expand-lg header-nav SimplurisNavbar">
                                <Brand />
                            </div>
                        </header>
                        <div id="main" className="layout-row flex simplurisallcasesBodyBackgroudColour">
                            <div id="aside" class="page-sidenav no-shrink  nav-expand   SimplurisCasePagesSideBarDiv" aria-hidden="true"><div class="sidenav h-100 modal-dialog SimplurisDashboardBackgroudColour position-relative"></div></div>
                            <div id="content" className="flex ">
                                <div className="page-container" id="page-container">
                                    <div className="">
                                        <div className="col-12 col-md-8 col-sm-8 col-xl-8 col-lg-8">
                                            <div className="page-title padding pb-0 pt-0">
                                                <Name className="text-md mb-2 SimplurisFontSize26 SimplurisFontSize19 SimplurisFontSize14 SimplurisCaseBankTitle position-relative ml-1">
                                                    <CircleIcon />
                                                    {localStorage.getItem(global.OfficialCaseName)}                                                                                                                                                               
                                                    <Link to={`/sign-in`} className="btn SimplurisAllCasesRightSideButton btn-lg btn-block SimplurisReportQuickViewLiveCaseLogin"> Login to LiveCase </Link>
                                                </Name>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="padding pt-3">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-8 col-xl-8">
                                                <div>
                                                    <div className="d-flex flex row">
                                                        <div className="col-12 col-md-12 col-xs-12 col-sm-12">
                                                            <div className="card d-flex flex mb-0">
                                                                <div className="card-body SimpulrisMailingCardBody">
                                                                    <table className="w-100 SimplurisCardHeaderTable">
                                                                        <tr>
                                                                            <td>
                                                                                <span className="SimplurisMailingsEnvelopeSqure r avatar">
                                                                                    <img src={ReportImage} className="SimplurisMailingsEnvelopeImage" />
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <div className="SimplurisMailingTitle">
                                                                                    {localStorage.getItem(global.ReportTitle)}
                                                                                </div>
                                                                                <div className="SimplurisMailingSubTitle">
                                                                                    {localStorage.getItem(global.OfficialCaseName)}
                                                                                </div>
                                                                                <div className="SimplurisMailingSubTitle">
                                                                                    {new Intl.DateTimeFormat('en-GB', {
                                                                                        month: 'long'
                                                                                    }).format(new Date(localStorage.getItem(global.ReportCreatedDate)))} {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(localStorage.getItem(global.ReportCreatedDate)))}, {new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(new Date(localStorage.getItem(global.ReportCreatedDate)))}
                                                                                </div>
                                                                            </td>
                                                                            <td>

                                                                                <a className="nav-link btnRunReport"
                                                                                    id="pdfreport"
                                                                                    href={localStorage.getItem(global.ReportPDFURL)}
                                                                                    target="_blank"
                                                                                    download>Download</a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="my-2 px-3">
                                                    </div>
                                                    <PdfReportViewer PDFURL={localStorage.getItem(global.ReportPDFURL)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
             }

        </div>
    )
}

export default connect(
    (state, props) => ({}),{ }
)(ReportQuickView)
