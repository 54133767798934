import * as React from 'react'
import styled from 'styled-components'

import LogoSpinner from './logo-spinner'

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
  height: 100%;
  width: 100%;
`

const CenteredSpinner = ({ size = 30 }) => (
	<Wrapper>
		<LogoSpinner size={size} />
	</Wrapper>
)

export default CenteredSpinner
