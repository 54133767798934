import * as React from "react";

const MiddleCards = ({ mailingCount, totalCountOFMailingUndeliverable, percentageForMailingUndeliverable }) => (    
  <div className="row">
    <div className="col-md-6">
      <div className="card p-3">
        <div className="d-flex align-items-center">
          <div>
            <small className="SimplurisFontSize19 SimplurisFontWeight400 SimplurisMilestoneLables">
                            Items sent: {mailingCount}
            </small>
            <div className="text-primary SimplurisFontSize36">
                            <strong className="SimplurisFontWeight400"></strong>
            </div>
          </div>
          <div className="flex" />
          <div className="d-flex">
						<div className="i-con-h-a">
							<div className="mt-1">
								<div className="text-right">                                    
									<small className="SimplurisFontWeight400 SimplurisFontSize14">
                                        &nbsp;
									</small>
								</div>
								
							</div>
						</div>
					</div>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="card p-3">
        <div className="d-flex align-items-center">
          <div>
            <small className="SimplurisFontSize19 SimplurisFontWeight400 SimplurisMilestoneLables">
                            Items Undeliverable: {totalCountOFMailingUndeliverable}
            </small>
            <div className="text-primary SimplurisFontSize36">
              <strong className="SimplurisFontWeight400"></strong>
            </div>
          </div>
          <div className="flex" />
          <div className="d-flex">
            <div className="i-con-h-a">
              <div className="mt-1">
                <div className="text-right">                
                                    <small className="SimplurisFontWeight400 SimplurisFontSize14">{percentageForMailingUndeliverable}</small>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MiddleCards;
