import { start, resolved } from 'state/helpers'
import { simplurisFetch } from 'helpers'

export const FETCH_DASHBOARD_CASE_ACTIVITY = 'FETCH_DASHBOARD_CASE_ACTIVITY'
export const ADD_CASE_ACTIVITY_POST = 'ADD_CASE_ACTIVITY_POST'

export const fetchDashboardCaseActivity = ({
	lastCaseId = 0,
	_pageSize = 10,
}) => async dispatch => {
    try {     
        
		dispatch({ type: start(FETCH_DASHBOARD_CASE_ACTIVITY) })        
        const url = `cases/dashboard/${lastCaseId}/${_pageSize}`
        let { resultObject } = await simplurisFetch({ url })           
        
        let lastCaseIds = resultObject.length > 0 ? resultObject[resultObject.length - 1].caseActivityID : 0

        let isHasNewData = false;

        if (resultObject.length > _pageSize) {
            
            resultObject = resultObject.slice(0, -1)
            isHasNewData = true;
        }
        
		dispatch({
            type: resolved(FETCH_DASHBOARD_CASE_ACTIVITY),
            caseActivity: {
                caseActivity: resultObject,
                lastCaseId: lastCaseIds,
                isHasNewData: isHasNewData
            }
		})
    } catch (e) {
        console.error('Error in case activity', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_DASHBOARD_CASE_ACTIVITY),
            caseActivity: {
                caseActivity: [],
                lastCaseId: 0,
                isHasNewData: false
            },
        })
	}
}

export const addCaseActivityPost = post => ({
	type: ADD_CASE_ACTIVITY_POST,
	post,
})
