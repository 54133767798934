import { FETCH_CASE_CALL_RELEASE, CHANGE_CASE_CALL_RELEASE_DATE_RANGE } from './actions'
import { resolved } from 'state/helpers'

const initialState = {
    dateRange: {
        start: null,
        end: null,
    },
}

export default (state = initialState, { type, releaseCalls, dateRange }) => {
    switch (type) {        
        case resolved(FETCH_CASE_CALL_RELEASE):            
            state.dateRange.start = releaseCalls.startDate;
            state.dateRange.end = releaseCalls.endDate;
            return {
                ...state,                  
                releaseCalls,
            }
        case CHANGE_CASE_CALL_RELEASE_DATE_RANGE:             
            return {
                ...state, dateRange
            }
        default:
            return state
    }
}
