import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Headliners from './headliners'
import { SectionSpinner } from 'react/components/spinners'
import { fetchLiveReportResponseSummary, fetchLiveReportDistribution, fetchLiveReportMailingHistorySummary } from 'state/live-reports/actions'
import { fetchDistributions } from 'state/distributions/actions'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { getDateRange } from 'state/live-reports/selectors'
import ResponseSummary from '../shared/response-summary'
import MailingsCharts from './mailings-charts'
import {
    getPaymentsForCase,
    getDistributionDataForCase,
} from 'state/distributions/selectors'
import DistributionsCharts from '../shared/distributions-charts'
import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'

const CaseDetailLiveReport = ({
	changeCaseSubtab,
	caseLiveReport,
	caseID,
	data,
    fetchLiveReportResponseSummary,
    fetchLiveReportDistribution,
    fetchLiveReportMailingHistorySummary,
    fetchDistributions,
    dateRange,
    setFilteredIDs,
    paymentsForCase,
    distributionsForCase,
    caseLiveReportResponses,
    caseLiveReportDistribution,
    caseLiveReportMailingHistory,
    userPermissions,
    previousCaseSubtabs,
    setPreviousCaseSubtab,

}) => {
    var liveReportPermission
    var stateChartPermission
    var payeeAttorneyPermission


    if (Object.keys(userPermissions).length > 0) {
        liveReportPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_IN_MENU'
        )
        stateChartPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_STATS_CHARTS'
        )

        payeeAttorneyPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_DRILL_DOWN_PAYEE_ATTORNEY'
        )
    }

    let filteredPaymentsForCase = paymentsForCase
    let filteredDistributionsForCase = distributionsForCase
    let previousPeriodPaymentsForCase = []
    let previousPeriodResponses = []
    let previousPeriodMailings = []
    let previousPeriodDistributions = []

    let responses = caseLiveReportResponses && caseLiveReportResponses.responses.length > 0 ? caseLiveReportResponses.responses : [];
    let mailings = caseLiveReportMailingHistory && caseLiveReportMailingHistory.mailings.length > 0 ? caseLiveReportMailingHistory.mailings : [];

    useEffect(() => {
        fetchDistributions({ caseID });
        fetchLiveReportResponseSummary({ caseID });
        fetchLiveReportMailingHistorySummary({ caseID });
        fetchLiveReportDistribution({ caseID });
    }, [caseID])

    if (dateRange.start && dateRange.end) {
        
        const startDate = new Date(dateRange.start)
        const endDate = new Date(dateRange.end)

        filteredPaymentsForCase = paymentsForCase.filter(({ paymentDate }) => {
            const mailingDate = new Date(paymentDate)
            return mailingDate > startDate && mailingDate < endDate
        })
        responses = responses.filter(({ date }) => {
            const mailingDate = new Date(date)
            return mailingDate > startDate && mailingDate < endDate
        })
        mailings = mailings.filter(({ date }) => {
            const mailingDate = new Date(date)
            return mailingDate > startDate && mailingDate < endDate
        })
        
        filteredDistributionsForCase = distributionsForCase.filter(
            ({ createdDate }) => {
                const distributionDate = new Date(createdDate)
                return distributionDate > startDate && distributionDate < endDate
            }
        )
        
        // Also get the same length of time of values up until the start date to
        // calculate % change vs the previous same length time period
        // Add one to days in range to be inclusive of the start date
        const daysInRange = moment(endDate).diff(moment(startDate), 'days') + 1
        const previousPeriodStartDate = moment(startDate)
            .subtract(daysInRange, 'days')
            .toDate()
        const previousPeriodEndDate = moment(startDate).subtract(1, 'days')

        previousPeriodPaymentsForCase = paymentsForCase.filter(
            ({ paymentDate }) => {
                const mailingDate = new Date(paymentDate)
                return (
                    mailingDate > previousPeriodStartDate &&
                    mailingDate < previousPeriodEndDate
                )
            }
        )
        previousPeriodResponses = responses.filter(({ date }) => {
            const mailingDate = new Date(date)
            return (
                mailingDate > previousPeriodStartDate &&
                mailingDate < previousPeriodEndDate
            )
        })
        previousPeriodMailings = mailings.filter(({ date }) => {
            const mailingDate = new Date(date)
            return (
                mailingDate > previousPeriodStartDate &&
                mailingDate < previousPeriodEndDate
            )
        })
        previousPeriodDistributions = distributionsForCase.filter(
            ({ createdDate }) => {
                const distributionDate = new Date(createdDate)
                return (
                    distributionDate > previousPeriodStartDate &&
                    distributionDate < previousPeriodEndDate
                )
            }
        )
    }

    if (stateChartPermission && stateChartPermission.length <= 0) {

        // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
        changeCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2])
        setPreviousCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2])
    }

    let isClosedStage = false;
    if (data && data.stage == "Closed") {
        isClosedStage = true;
    }

    return (<>
        {(
            () => {
                if (filteredDistributionsForCase != null) {
                    if (filteredDistributionsForCase.length >= 0 && responses.length >= 0 && mailings.length >= 0) {
                        return <div className="row">
                            <Headliners
                                isClosedStage={isClosedStage}
                                distributions={filteredDistributionsForCase}
                                responses={responses}
                                mailings={mailings}
                                previousPeriodDistributions={previousPeriodDistributions}
                                previousPeriodResponses={previousPeriodResponses}
                                previousPeriodMailings={previousPeriodMailings}
                                caseID={caseID}
                            />
                        </div>
                    } else {
                        return <div className="row" style={{ 'minheight': '300px', 'marginbottom': '10px' }}> <SectionSpinner /></div>
                    }
                }
                else {
                    return <div className="row" style={{ 'minheight': '300px', 'marginbottom': '10px' }}> <SectionSpinner /></div>
                }
            })()}
        {(
            () => {
                if (!caseLiveReportResponses) {
                    return <div className="row" style={{ 'minheight': '300px', 'marginbottom': '10px' }}> <SectionSpinner /></div>
                } else {
                    return <div className="row"><ResponseSummary
                        responses={responses}
                        caseID={caseID}
                        permission={
                            Object.keys(userPermissions).length > 0 ? userPermissions : []
                        }
                    /></div>
                }
            }
        )()}
        {(
            () => {
                if (!caseLiveReportMailingHistory) {
                    return <div className="row" style={{ 'minheight': '300px', 'marginbottom': '10px' }}> <SectionSpinner /></div>
                } else {
                    return <div className="row"><MailingsCharts mailings={mailings} /></div>
                }
            }
        )()}
        {(
            () => {
                if (!caseLiveReportDistribution) {
                    
                    return <div className="row" style={{ 'minheight': '300px', 'marginbottom': '10px' }}> <SectionSpinner /></div>
                } else {
                    if (payeeAttorneyPermission && payeeAttorneyPermission.length > 0) {
                        
                        return <><div className="row">
                            <DistributionsCharts
                                payeeAttorneyPermission={
                                    payeeAttorneyPermission && payeeAttorneyPermission.length > 0
                                        ? payeeAttorneyPermission
                                        : []
                                }
                                caseID={caseID}
                                payments={filteredPaymentsForCase != null && filteredPaymentsForCase}
                                onLeafClick={node => {
                                    setFilteredIDs(node.data.ids)
                                }}
                                DateRangeStartDate={dateRange.start}
                                DateRangeEndDate={dateRange.end}
                            /></div></>
                    } else { return <></> }
                }
            }
        )()}
    </>
    )
}

export default connect(
    (state, props) => ({
        caseLiveReportResponses: state.liveReports.responses && state.liveReports.responses[props.caseID],
        caseLiveReportDistribution: (state.liveReports && state.liveReports[props.caseID] != null) && state.liveReports[props.caseID].distributions,
        caseLiveReportMailingHistory: state.liveReports.mailings && state.liveReports.mailings[props.caseID],
        dateRange: getDateRange(state),
        paymentsForCase: getPaymentsForCase(state, props),
        distributionsForCase: getDistributionDataForCase(state, props),
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
    }),
    {
        fetchDistributions,
        fetchLiveReportResponseSummary,
        fetchLiveReportDistribution,
        fetchLiveReportMailingHistorySummary,
        changeCaseSubtab,
        setFilteredIDs,
        setPreviousCaseSubtab,
    }
)(CaseDetailLiveReport)
