import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import { Modal } from 'react-bootstrap';
import LeftArrowPNG from '../../../../../static/img/left-arrow.png'
import CaseFileBlueImage from '../../../../../static/img/securefile_icon_small.png'
import Select from 'react-select';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ButtonLoader from '../../../../components/button-loader';
import { fetchCaseFilesData } from 'state/case-file/actions'
import ConfirmCheckIcon from '../../../../../static/img/confirm_check_icon.png'

function NewShareFileModalPopup(props) {
    const [isFileUploadedAndSave, setIsFileUploadedAndSave] = useState(false);
    let sharableFileData = props.sharableFileData;
    let secureFileID = props.sharableFileData.secureFileID;

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedTeamMapIds, setSelectedTeamMapIds] = useState('');
    const [copied, setCopied] = useState(false);
    const [copiedLink, setCopiedLink] = useState(null);
    const [caseManagers, setCaseManagers] = useState([]);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [isDataFetch, setIsDataFetch] = useState(false);
    const [caseManagerReqError, setCaseManagerReqError] = useState(false);
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [addNotes, setAddNotes] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function OpenSecureFileModalPopup() {
        simplurisFetch({
            url: `cases/${props.caseID}/deletesecurefile/${sharableFileData.vendorUniqueID}`,
            method: 'DELETE',
            json: false,
        }).then((result) => {
            if (result.resultCode == "SUCCESS") {
                props.setShowNewShareFile(false);
                props.setShowSecureFileUploadModalPopup(true);
                props.setFileDetail(false);
            } else {
                props.setShowNewShareFile(true);
            }
        });
    }

    function CloseNewShareFileModalPopup() {
        setIsFileUploadedAndSave(false);
        
        props.setShowNewShareFile(false);
        props.setFileDetail(false);
        props.setIsFetchCaseManager(false);
        setIsFileUploadedAndSave(false);
        setErrorMessage('');
    }


    function CloseConfirmationMessageShareFileModalPopup() {               
        setIsFileUploadedAndSave(false);
        GetCaseFilesData();        
    }

    function BackOpenSecureFileUploadModalPopup() {
        OpenSecureFileModalPopup();
    }

    const handleChange = selectedOption => {        
        var selectedIds = [];
        if (selectedOption != null) {
            var l = selectedOption.length;
            for (var i = 0; i < l; i++) {
                selectedIds.push(selectedOption[i].value);
            }
            setSelectedTeamMapIds(selectedIds.join(","));
        }
        setSelectedOption(selectedOption);
    };

    const CopySharableLink = e => {
        setCopiedLink(e);
        setCopied(true);
        setTimeout(function () {
            setCopied(false);
        }, 3000);
    };

    const fetchCaseManagers = async () => {
        simplurisFetch({
            url: `cases/${parseInt(props.caseID)}/getcasemanagers`
        })
            .then(result => {                
                if (result.resultCode == "SUCCESS") {
                    setCaseManagers(result.resultObject);
                    setShowPageLoader(false);
                    setIsDataFetch(true);
                } else {
                    setShowPageLoader(false);
                    setIsDataFetch(false);
                }
            })
            .catch(e => {
                console.error("Error in fetch project managers data ", e, e.errorObject);
                setShowPageLoader(false);
                setIsDataFetch(false);
            });
    }

    const GetCaseFilesData = () => {
        var CaseID = props.caseID;
        props.fetchCaseFilesData({ caseID: CaseID, sortColumn: 'Date', sortOrder: 'desc', fileFlag: true, serachValue: null });
    }

    const sendClick = () => {
        if (selectedOption == null || selectedOption.length <= 0) {
            setCaseManagerReqError(true);
        } else {
            setCaseManagerReqError(false);

            var formData = new FormData();
            formData.append('SecureFileID', secureFileID);
            formData.append('FileLink', sharableFileData.attachmentUrl);
            formData.append('TeamMemberPersonMapIDs', selectedTeamMapIds);
            formData.append('Description', addNotes);
            formData.append('Title', sharableFileData.title);
            formData.append('SenderFullName', localStorage.getItem(global.FirstName) + " " + localStorage.getItem(global.LastName));
            formData.append('VendorUniqueID', sharableFileData.vendorUniqueID);

            setShowButtonLoader(true);
            simplurisFetch({
                url: `cases/${props.caseID}/sendsecurefilelink`,
                method: 'POST',
                body: formData,
                json: false,
            }).then((result) => {
                setErrorMessage('');
                if (result.resultCode == "SUCCESS") {   
                    props.fetchCaseDataCounts(props.caseID);
                    setTimeout(function () {                        
                        setShowButtonLoader(false);
                        CloseNewShareFileModalPopup();
                        setIsFileUploadedAndSave(true);
                        setSelectedOption('');
                        setAddNotes('');                     
                        props.updateSidebarMenuCounts();
                    }, 3000);
                } else {
                    setShowButtonLoader(false);
                    setSelectedOption('');
                    setAddNotes('');
                    props.setShowNewShareFile(true);
                }
            }).catch(e => {                
                setShowButtonLoader(false);
                props.setShowNewShareFile(true);
                setErrorMessage("Something went wrong.");
            });
        }
    }
  
    useEffect(() => {
        try {
            if (props.isFetchCaseManager) {
                fetchCaseManagers();
            }
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [props.isFetchCaseManager])

    return (
        <>
            
                    <Modal show={isFileUploadedAndSave} onHide={CloseConfirmationMessageShareFileModalPopup} backdrop="static" size="lg" data-keyboard="false" data-backdrop="static">
                        <Modal.Header closeButton className="border-0">                            
                        </Modal.Header>
                        <Modal.Body className="px-5">
                            <div className="text-center p-5">
                                <div className="pb-2">
                                    <img src={ConfirmCheckIcon} />
                                </div>
                                <div className="SimplurisFontSize26 text-black pb-5 mb-3">
                                    File Transfer Submitted
                                </div>
                               </div>                            
                        </Modal.Body>
                    </Modal> 
                    <Modal show={props.OpenModalPopup} onHide={CloseNewShareFileModalPopup} backdrop="static" size="lg" data-keyboard="false" data-backdrop="static">
                        <Modal.Header closeButton className="request-modal-title-b-b">
                            <Modal.Title>
                                <h5 className="modal-title SimplurisBidRequestTitle">
                                    <a href={null} onClick={() => BackOpenSecureFileUploadModalPopup()}>
                                        <img src={LeftArrowPNG} width="25px" className="pr-2 pb-2" /></a>
                            Secure File Transfer
                        </h5>
                            </Modal.Title>
                        </Modal.Header>
                <Modal.Body className="px-5">
                    {errorMessage != "" ? <ErrorPlacement items={errorMessage} /> : ''}
                            <div className="row">
                                <div className="col-md-1 col-sm-1 col-xs-1">
                                    <span className="r avatar float-left float-md-none">
                                        <img src={CaseFileBlueImage} className="SimplurisMailingsEnvelopeImage" />
                                    </span>
                                </div>
                                <div className="col-md-10 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0">
                                    <div className="SimplurisReportTitle">
                                        {sharableFileData.title}
                                    </div>
                                    <div className="SimplurisReportSubTitle">
                                        {sharableFileData.fileType}
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-1 align-self-center pt-3 pt-lg-0">
                                    <span onClick={OpenSecureFileModalPopup}
                                        className="w-28 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-success SimplurisCursorPointer">
                                        <i className="i-con i-con-close"></i>
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mt-5">
                                        <div className="SimlurisReportTypeLabel mb-3">
                                            Get shareable link to this file:
                                </div>
                                        <div className="Simpluris-case-file-card">
                                            <div className="Simpluris-case-file-header">
                                                <div className="d-flex">
                                                    <div className="p-1 Simpluris-sharefile-title">
                                                        Only the parties designated below will be able to authenticate and access this file.
                                            </div>
                                                    <div className="ml-auto p-1 text-uppercase SimplurisDashboardTitle SimplurisOpacity08 SimplurisFontSize12 SimplurisCursorPointer">
                                                        <CopyToClipboard
                                                            onCopy={() => CopySharableLink(sharableFileData.url)}
                                                            text={sharableFileData.url} >
                                                            {copied &&
                                                                copiedLink != null &&
                                                                copiedLink == sharableFileData.url ? (
                                                                    <span>Copied!</span>
                                                                ) : <span>Copy</span>}
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Simpluris-case-file-body">
                                                <a href={sharableFileData.url} target="_blank">{sharableFileData.url}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mt-4">
                                        <div className="SimlurisReportTypeLabel mb-3">
                                            Send this file to:
                                </div>
                                        <div className="Simpluris-case-file-card1">
                                            <div className="Simpluris-case-file-header">
                                                <div className="d-flex">
                                                    <div className="p-1 Simpluris-sharefile-title">
                                                        Sharing this file is limited to only LiveCase users who can access this case. Start typing a name below to select someone from within the eligible parties.
                                            </div>
                                                </div>
                                            </div>
                                            <div className="Simpluris-case-file-body" style={{ position: 'relative', zIndex: 1, opacity: 1 }}>
                                                <Select
                                                    placeholder="Select"
                                                    closeMenuOnSelect={false}
                                                    classNamePrefix="simpluris-select"
                                                    hideSelectedOptions={false}
                                                    isMulti={true}
                                                    name="form-field-name"
                                                    value={selectedOption}
                                                    options={caseManagers}
                                                    onChange={handleChange}
                                                />
                                                {caseManagerReqError ? <span className="text-danger">Please select project manager</span> : null}
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <textarea className="form-control Simpluris-case-file-textarea"
                                                rows="5"
                                                placeholder="Add Note..."
                                                onChange={e => (setAddNotes(e.target.value))}>{addNotes}</textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10">
                                    <button type="button" disabled={showButtonLoader} onClick={() => sendClick()} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor SimplurisFontSize14 SimplurisFontWeight400 w-sm btn-light py-3 mt-3">
                                        {
                                            showButtonLoader && (
                                                <ButtonLoader />
                                            )
                                        }
                                        {showButtonLoader && <span></span>}
                                        {!showButtonLoader && <span>Send</span>}
                                    </button>
                                    {/* <button type="button" onClick={() => SaveAndCloseClick()} className="btn bg-white Simpluris-btnCancel SimplurisFontSize14 SimplurisFontWeight400 w-sm mb-1 btn-light py-3 rounded-0 border-0 mt-3" data-dismiss="modal">Save & Close</button> */}
                                </div>
                                <div className="col-md-2 float-lg-right float-md-right float-right">
                                    <button type="button" onClick={() => CloseNewShareFileModalPopup()} className="btn bg-white Simpluris-btnCancel SimplurisFontSize14 SimplurisFontWeight400 w-sm mb-1 btn-light py-3 rounded-0 border-0 mt-3" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>            
        </>
    )
}

export default connect(
    (state, props) => ({
    }),
    {
        fetchCaseFilesData,
    }
)(NewShareFileModalPopup)

function ErrorPlacement(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvFailedMessage" className="alert alert-danger" role="alert">
            <button type="button" className="close SimpluriAlertMessageCloseIcon" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}