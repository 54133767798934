import * as React from 'react'
import _ from 'lodash'
import { renderToString } from 'react-dom/server'

import { numberToDollars } from 'react/helpers'
import Title from './treemap/title'

export const getTotal = disbursements =>
  disbursements.reduce((total, { amount }) => total + amount, 0)
const getRangeText = ([start, end]) =>
    `${numberToDollars(start, false) || '$0'} - ${end ? numberToDollars(end, false) : 'and up'}`

const numberOfGroups = 10

// These are the brand blue as descending alpha values for rgba but converted to hex values so they are not transparent at all
const orderedGroupColors = [
  '#4a90e2',
  '#5c9be4',
  '#6ea6e7',
  '#80b1ea',
  '#92bced',
  '#a4c7f0',
  '#b6d2f3',
  '#c8ddf6',
  '#dae8f9',
  '#ecf3fc',
]

export const groupDistributionsByDollar = disbursements => {
  const disbursementAmounts = disbursements.map(({ amount }) => amount)

  // Two options for min amount, the dynamic floor being better for drilling
  // through the data as you click on different groups, but sometimes 0 looks
  // better to begin with. Check against actual data in the future to decide.
  // const minAmount = 0
  let minAmount = _.floor(_.min(disbursementAmounts), -2)
  const maxAmount = _.ceil(_.max(disbursementAmounts), -2)

  // Fallback for cases where amounts are all the exact same and we don't create a range for them to fall inside of
  if (minAmount === maxAmount) {
    minAmount -= 100
  }

  const stepSize = (maxAmount - minAmount) / numberOfGroups
  const amountGroups = new Array(numberOfGroups)
    .fill(null)
    .map((_, i) => minAmount + stepSize * i)

  const a = disbursements.filter(
    ({ amount }) => amount > amountGroups[0] && amount <= amountGroups[1]
  )
  const b = disbursements.filter(
    ({ amount }) => amount > amountGroups[1] && amount <= amountGroups[2]
  )
  const c = disbursements.filter(
    ({ amount }) => amount > amountGroups[2] && amount <= amountGroups[3]
  )
  const d = disbursements.filter(
    ({ amount }) => amount > amountGroups[3] && amount <= amountGroups[4]
  )
  const e = disbursements.filter(
    ({ amount }) => amount > amountGroups[4] && amount <= amountGroups[5]
  )
  const f = disbursements.filter(
    ({ amount }) => amount > amountGroups[5] && amount <= amountGroups[6]
  )
  const g = disbursements.filter(
    ({ amount }) => amount > amountGroups[6] && amount <= amountGroups[7]
  )
  const h = disbursements.filter(
    ({ amount }) => amount > amountGroups[7] && amount <= amountGroups[8]
  )
  const i = disbursements.filter(
    ({ amount }) => amount > amountGroups[8] && amount <= amountGroups[9]
  )
  const j = disbursements.filter(({ amount }) => amount > amountGroups[9])
  const groups = [a, b, c, d, e, f, g, h, i, j]

  return {
    title: 'Disbursements',
    children: groups
      .map((group, i) => ({
        size: Math.max(group.length, disbursements.length / 20),
        number: group.length,
        total: getTotal(group),
        title: getRangeText([amountGroups[i], amountGroups[i + 1]]),
        ids: group.map(({ paymentID }) => paymentID),
      }))
      .sort((a, b) => b.total - a.total)
      .filter(x => x.total > 0)
      .map(({ title, size, total, ids, number }, i) => ({
        size,
        color: orderedGroupColors[i],
        title: <Title title={title} number={number} total={total} index={i} />,
        titleWording: title,
        tooltipContent: renderToString(
          <Title title={title} number={number} total={total} />
        ),
        ids,
      })),
  }
}

export const groupDistributionsByPayeeType = disbursements => {
  const uniquePayeeTypes = [
    ...new Set(disbursements.map(({ payeeType }) => payeeType)),
  ]
  const groups = uniquePayeeTypes.map(payeeType =>
    disbursements.filter(disbursement => disbursement.payeeType === payeeType)
  )

  return {
    title: 'Disbursements',
    children: groups
      .map((group, i) => ({
        size: Math.max(group.length, disbursements.length / 20),
        number: group.length,
        total: getTotal(group),
        title: group[0].payeeType,
        ids: group.map(({ paymentID }) => paymentID),
      }))
      .sort((a, b) => b.total - a.total)
      .filter(x => x.total > 0)
      .map(({ title, size, total, ids, number }, i) => ({
        size,
        color: orderedGroupColors[i],
        title: <Title title={title} number={number} total={total} index={i} />,
        titleWording: title,
        tooltipContent: renderToString(
          <Title title={title} number={number} total={total} />
        ),
        ids,
      })),
  }
}
