import * as React from 'react'
import { connect } from 'react-redux'

import FilterButton from './filter-button'
import { setPaymentStatusFilters } from 'state/case-detail-view/distributions/actions'

const PaymentStatusFilters = ({ filters, setPaymentStatusFilters }) => (    
  <>
    {Object.keys(filters).map(paymentStatus => (
      <FilterButton
        key={paymentStatus}
        label={paymentStatus}
        filter={paymentStatus}
        active={!!filters[paymentStatus]}
        onClick={() =>
          setPaymentStatusFilters({
            [paymentStatus]: !filters[paymentStatus],
          })
        }
      />
    ))}
  </>
)

export default connect(null, { setPaymentStatusFilters })(PaymentStatusFilters)
