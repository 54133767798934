import * as React from 'react'
import { SearchField } from 'react-bootstrap-table'

export const getToolbarWithTotal = ({ buttonText, onButtonClick }) => props => {    
    return (
        <>
            <div className="col-md-12">
                <div className="dropdown mb-2 d-inline-block">
                    <button
                        className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2"
                        onClick={onButtonClick}
                    >
                        {buttonText}
                    </button>
                    <div
                        className="dropdown-menu bg-dark"
                        role="menu"
                        x-placement="bottom-start"
                    />
                </div>
                <div className="d-inline-block position-relative ml-1">
                    {props.components.searchField}
                    <i className={`mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank ${props.components.searchField != undefined ? '' : 'd-none'}`} />
                </div>
            </div>
        </>
    )
}

export const getSearchField = ({ ...additionalProps }) => props => (  
    <SearchField className="form-control SimplurisCaseBanktxtSearchCaseBank" {...additionalProps}/>
)
