import { simplurisFetch } from 'helpers'
import { start, resolved } from 'state/helpers'
export const FETCH_MYCASES = 'FETCH_MYCASES'
export const FETCH_USERS = 'FETCH_USERS'

//Fetach All My Cases for Admin
export const feacthMyCasesForAdmin = (
    pageIndex,
    pageSize,    
    searchValue, 
    sortColumn,
    sortColumnDir,   
) => async dispatch => {
    try {
        
        dispatch({ type: start(FETCH_MYCASES) })
        searchValue = searchValue == undefined || searchValue == "" ? null : searchValue
        
        const url = `cases/getallmycasesforadmin/${searchValue}/${0}/${pageIndex}/${pageSize}/${sortColumn}/${sortColumnDir}`
        const { resultObject, resultObjectRecordCount } = await simplurisFetch({ url })
        
        dispatch({
            type: resolved(FETCH_MYCASES),
            adminCaseObject: {
                adminCases: resultObject,
                pageIndex: pageIndex,
                pageSize: pageSize,
                searchValue: searchValue,
                sortColumn: sortColumn,
                sortColumndir:sortColumnDir,
                totalCount: resultObjectRecordCount
            }
        })
        
    } catch (e) {
        console.error('Error in get all cases for admin', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_MYCASES),
            adminCaseObject: []
        })
    }
    }

//Fetach All Users for Admin
export const feacthAllUsersForAdmin = (
    pageIndex,
    pageSize,
    searchValue,
    filterType,
    sortColumn,
    sortColumnDir,
) => async dispatch => {
    try {
        
        
        dispatch({ type: start(FETCH_USERS) })
        searchValue = searchValue == undefined || searchValue == "" ? null : searchValue
        
        const url = `cases/getallusersforadmin/${parseInt(filterType)}/${searchValue}/${pageIndex}/${pageSize}/${sortColumn}/${sortColumnDir}`
        const { resultObject, resultObjectRecordCount } = await simplurisFetch({ url })
        
        
        dispatch({
            type: resolved(FETCH_USERS),
            adminUserObject: {
                adminUsers: resultObject,
                pageIndex: pageIndex,
                pageSize: pageSize,
                searchValue: searchValue,
                sortColumn: sortColumn,
                sortColumndir:sortColumnDir,
                totalCount: resultObjectRecordCount
            }
        })
        
    } catch (e) {
        console.error('Error in get all users for admin', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_USERS),
            adminUserObject: []
        })
    }
}
