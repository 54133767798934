import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { numberToDollars } from 'react/helpers'
import { mobile } from 'shared/media-queries'

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  ${mobile} {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`
const PaymentSection = styled.div`
  flex: 0 1 250px;
  margin: 0 10px;
  cursor: pointer;
`
const Title = styled.div`
  color: #5ed0d3;
  font-size: 12px;
`
const Bar = styled.div`
  background-color: #5ed0d3;
  width: 100%;
  height: ${props => props.height}px;
`

const MAX_BAR_HEIGHT = 300


const DistributionsBarChart = ({
    filteredPayments,
    distributionData,
    setDistributionIDFilters,
    swapToTreemap,
    DateRangeStartDate,
    DateRangeEndDate,        
    filteredIDs
}) => {
    
    if (DateRangeStartDate && DateRangeEndDate) {
        
        const startDate = new Date(DateRangeStartDate)
        const endDate = new Date(DateRangeEndDate)

        distributionData = distributionData.filter(
            ({ dateSent }) => {                
                const distributionSendDate = dateSent != undefined ? new Date(dateSent) : new Date();
                return distributionSendDate > startDate && distributionSendDate < endDate
            }
        )
        
    }

    const highestDistributionAmount = distributionData && distributionData.reduce(
    (highest, { totalDistributionAmount }) =>
      Math.max(highest, totalDistributionAmount),
    0
    )
    
    const allDistributionsAsFalse = distributionData && distributionData.reduce(
    (obj, { distributionID }) => {
      obj[distributionID] = false
      return obj
    },
    {}
  )
    const [popoverBarID, setPopoverBarID] = useState(-1);
    const ToggleBarPopover = (id) => {
        setPopoverBarID(id)
    }
    return (
    <FlexContainer>
            {distributionData != null && distributionData.length > 0 ? distributionData.map(
        ({
          totalDistributionAmount,
          distributionID,
          createdDate,
            description,
            dateSent,
          }) => {
                    //debugger;
              var size = filteredPayments.filter(x => x.distributionID == distributionID).length;

          const barHeight =
            (totalDistributionAmount / highestDistributionAmount) *
            MAX_BAR_HEIGHT

          return (
            <PaymentSection
                  onClick={() => {                      
                setDistributionIDFilters({
                  ...allDistributionsAsFalse,
                  [distributionID]: true,
                })
                swapToTreemap()
              }}
            >
                  <div>{dateSent != undefined && moment(dateSent).format('L')}</div>
                  <Title>{description}</Title>
                  <Bar id={"bar" + distributionID} height={barHeight}
                      onMouseEnter={() => {
                          ToggleBarPopover(distributionID)
                      }}
                      onMouseLeave={() => {
                          ToggleBarPopover(-1)
                      }}
                  />
                  <Popover popperClassName="Simpluris-Popover" innerClassName="Simpluris-Popover-inner" placement="top-end" isOpen={popoverBarID == distributionID ? true : false} target={"bar" + distributionID} toggle={() => ToggleBarPopover(1)}>
                      <PopoverBody className="Simpluris-PopoverBody">
                          <div>Member</div>
                          <div>{size} Payments</div>
                          <div>Totalling {numberToDollars(totalDistributionAmount,true)}</div>
                      </PopoverBody>
                  </Popover>
            </PaymentSection>
          )
        }) : (
            <div style={{ paddingTop: '50px' }}>
                No distributions to display for selected filters
            </div>
          )
            }
                
      </FlexContainer>
  )
}

export default DistributionsBarChart
