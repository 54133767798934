import { createSelector } from 'reselect'

import { caseIDFromPropsSelector } from 'state/helpers'
import { getSelectedMailingID } from 'state/case-detail-view/mailings/other/selectors'

const stateSelector = state => state.mailings.other

export const getMailingsForCase = createSelector(            
	[stateSelector, caseIDFromPropsSelector],
    (state, caseID) => {
        const mailingsArray = Object.values(state)        
		const mailingsForCase = mailingsArray.filter(
			mailing => mailing.caseID === caseID
        )        
		return mailingsForCase.length ? mailingsForCase : []
	}
)

export const getSelectedMailing = createSelector(    
	[stateSelector, getSelectedMailingID],
    (state, selectedMailingID) => state[selectedMailingID]        
)
