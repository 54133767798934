import { combineReducers } from 'redux'

import AllReleaseCalls from './call-release/reducer'
import AllAccountRequests from './account-requests/reducer'
import AllUserManagement from './user-management/reducer'
import AllLogs from './log/reducer'

export default combineReducers({
    AllReleaseCalls,
    AllAccountRequests,
    AllUserManagement,
    AllLogs
})
