import * as React from 'react'
import { connect } from 'react-redux'
import { DatePicker } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

// Antd css overwrites a lot of default css, as apparently they intend you to
// start a new project with it and conform to their styles. This issue was
// marked as won't resolve, so it won't change anytime soon. As such we just
// pull in the individual styles needed for the specific component here
import 'antd/es/date-picker/style/index.css'
import 'antd/es/input/style/index.css'
import 'antd/es/time-picker/style/index.css'
import 'antd/es/tag/style/index.css'

const { RangePicker } = DatePicker
const Wrapper = styled.div`
	width: auto;
`

const RunReportsPerWeeksPicker = ({ dateRange, updateDateRangeFilter }) => (    
    <Wrapper className="mt-3 mt-sm-0 mt-md-3 mt-xl-0 mt-lg-3 mb-2 mb-sm-0 mb-md-2 mb-xl-0 mb-lg-3">
        <RangePicker            
            onChange={(momentObjects, [start, end]) =>
                updateDateRangeFilter({ start, end })
            }
            defaultValue={[
                 null,
                 null,
            ]}
            ranges={{
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [
                    moment()
                        .subtract(1, 'weeks')
                        .startOf('week'),
                    moment()
                        .subtract(1, 'weeks')
                        .endOf('week'),
                ],
                '2 Weeks Ago': [
                    moment()
                        .subtract(2, 'weeks')
                        .startOf('week'),
                    moment()
                        .subtract(2, 'weeks')
                        .endOf('week'),
                ],
                '3 Weeks Ago': [
                    moment()
                        .subtract(3, 'weeks')
                        .startOf('week'),
                    moment()
                        .subtract(3, 'weeks')
                        .endOf('week'),
                ],
                'All Time': [null, null],
            }}
        />
    </Wrapper>
)

export default RunReportsPerWeeksPicker
