import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn, SearchField, } from 'react-bootstrap-table'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { feacthAllAppLogForAdmin } from 'state/admin/log/actions'
import { getAllAppLogForAdmin } from 'state/case-detail-view/admin/selectors'
import ViewLogDetailModalPopup from './view-logdetail-modalpopup'

const ManageAppLog = ({
    isFetchinAllAppLog,
    allAppLog = {},
    feacthAllAppLogForAdmin,
    userPermissions,
}) => {

    if (isFetchinAllAppLog) {
        allAppLog = [];
        allAppLog.appLogs = [];
        allAppLog.pageIndex = 0;
        allAppLog.pageSize = 10;
        allAppLog.sortColumn = 1;
        allAppLog.sortColumnDir = 'desc';
        allAppLog.totalCount = 0;
    }

    var {
        pageIndex = Object.keys(allAppLog).length > 0 ? allAppLog.pageIndex : 1,
        pageSize = Object.keys(allAppLog).length > 0 ? allAppLog.pageSize : 10,
        sortColumn = Object.keys(allAppLog).length > 0 ? allAppLog.sortColumn : 1,
        sortColumnDir = Object.keys(allAppLog).length > 0 ? allAppLog.sortColumnDir : 'desc',
        totalCount = Object.keys(allAppLog).length > 0 ? allAppLog.totalCount : 0,
        searchValue,
        data = [],
    } = allAppLog

    const [sortName, setSortName] = useState(Object.keys(allAppLog).length > 0 ? allAppLog.sortColumn : 'logID');
    const [sortOrder, setSortOrder] = useState(Object.keys(allAppLog).length > 0 ? allAppLog.sortColumn : 'desc');

    const [Text, setText] = useState('')
    const [showPageLoader, setShowPageLoader] = useState(false);  
    const [showViewLogDetailModalPopup, setShowViewLogDetailModalPopup] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        try {
            allAppLog = [];
            feacthAllAppLogForAdmin(1, 10, sortName, sortOrder, null);
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [])


    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }


    const tableOptions = {
        toolBar: createCustomToolBar,
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        onSortChange: onSortChange,
        sortName: sortName,
        sortOrder: sortOrder,
        sortIndicator: true,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader()
    }

    function onSearchChange(searchText) {

        allAppLog = [];
        allAppLog.appLogs = [];

        searchValue = searchText
        setText(searchText)

        if (searchText == '') setText('')

        searchText = searchText == '' ? '' : searchText

        feacthAllAppLogForAdmin(1, pageSize, sortName, sortOrder, searchText);        
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        allAppLog = [];
        allAppLog.appLogs = [];
        feacthAllAppLogForAdmin(page, sizePerPage, sortName, sortOrder, searchValue);
    }

    function onSizePerPageList(sizePerPage) {
        allAppLog = [];
        allAppLog.appLogs = [];
        feacthAllAppLogForAdmin(1, sizePerPage, sortName, sortOrder, searchValue);
    }

    function showTableLoader() {
        if (feacthAllAppLogForAdmin || showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }


    function onSortChange(sortName, sortOrder) {
        
        allAppLog = [];
        allAppLog.appLogs = [];
        setSortName(sortName);
        setSortOrder(sortOrder);
        
        feacthAllAppLogForAdmin(1, pageSize, sortName, sortOrder, searchValue);
    }

    const defaultSorted = [
        {
            dataField: "logID",
            order: "desc"
        }
    ];


    function linkCellFormatter(cell, row) {
        if (row.platformPersonID > 0) {
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}
                >
                    {cell}
                </a>
            );
        }
        else {
            return <span>{cell}</span>
        }
    }


    function actionFormatter(cell, row) {
        return (
            <a
                className="SimplurisBlueColor SimplurisPointer"
                href={null}
                onClick={() => handleCellClick(row)}
                title="View Log Detail"
            >
                <i class="fa fa-eye" aria-hidden="true"></i>
            </a>
        );
    }

    const errorMessageColumnFormatter = (cell) => {        
        let messageLength = cell.length;
        if (messageLength >= 200) {
            cell = cell.toString().substring(0, 200)
            return cell + "..."
        }
        else {
            return cell; 
        }
    }

    const handleCellClick = (row) => {     
        
            OpenViewLogDetailModalPopUp(row.logText);        
    }

    function OpenViewLogDetailModalPopUp(message)
    {
        
        setMessage(message);
        setShowViewLogDetailModalPopup(true);        
    } 

    return (
        <div>
            <div className="row mb-4">               
            </div>
            <div>
                {
                    Object.keys(allAppLog).length > 0 &&
                    <BootstrapTable
                        data={allAppLog.appLogs}
                        options={tableOptions}
                        tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover SimplurisMyUsersMainGrid SimplurisUserManagementAllUsersGridWrraper SimplurisAppManageGridWraper"                        
                        bodyContainerClass="text-grid-td"
                        hover
                        remote={true}
                        pagination={true}
                        search={true}
                        fetchInfo={{ dataTotalSize: totalCount }}
                        trClassName="SimplurisStatisticsGridMainTr SimplurisAdminCallReleaseChildMainTr"
                        defaultSorted={defaultSorted}
                    >
                        <TableHeaderColumn dataSort={true} isKey dataField='logID' width={100}>Log ID</TableHeaderColumn>                        
                        <TableHeaderColumn dataSort={true} width={130} dataField='class'>Class Name</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='logText' dataFormat={errorMessageColumnFormatter}>Error Message</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} width={130}  dataField='method'>Method</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='logTypeCode' width={140}>Log Type Code</TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='createdDate' width={120} dataFormat={createdDate => new Date(createdDate).toLocaleDateString()}>Created Date</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField=""
                            width="70"
                            dataFormat={actionFormatter}
                            searchable={false}
                        >Action</TableHeaderColumn>
                    </BootstrapTable>
                }
                <ViewLogDetailModalPopup OpenModalPopup={showViewLogDetailModalPopup} setShowViewLogDetailModalPopup={setShowViewLogDetailModalPopup} message={message} />
            </div>
        </div>
    )
}


export default connect(
    (state, props) => ({
        isFetchinAllAppLog: getAllAppLogForAdmin(state, props),
        allAppLog: state.adminData.AllLogs.adminAppLogObject,
        userPermissions: getPermissionsForCase(state, props)
    }),
    {
        feacthAllAppLogForAdmin,
    }
)(ManageAppLog)
