import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

function SendReportModalPopup(props) {

    const [email, setEmail] = useState('');
    const [emailReqError, setEmailReqError] = useState(false);
    const [emailInvalidError, setEmailInvalidError] = useState(false);

    const SendReport = () => {
        setEmail(email);

        const isValidEmail = global.EmailRegex.test(email)

        if (!email || !isValidEmail) {
            if (!email) {
                setEmailReqError(true);
            }

            if (email && !isValidEmail) {
                setEmailInvalidError(true);
            }
            return;
        }
    }

    function CloseSendReportModalPopup() {
        props.setShowSendReportModalPopup(false);
    }

    function BackShareReportModalPopup() {        
        props.setShowSendReportModalPopup(false);
        props.setShowShareReportModalPopup(true);
    }    

    return (
        <>
        <Modal show={props.OpenModalPopup} onHide={CloseSendReportModalPopup} size="lg">
            <Modal.Header className="Simpluris-modal-header" closeButton>
                <Modal.Title>
                    <div className="d-flex flex p-0 p-xl-3">
                        <button type="button" onClick={() => BackShareReportModalPopup()} className="backarrow pointer ml-0 ml-md-1 ml-xl-2" data-dismiss="modal" data-toggle="modal" data-toggle-className="fade-down" data-toggle-class-target="#animate">
                            <i className="i-con i-con-arrow-left w-20 text-dark"><i></i></i>
                        </button>
                        <h5 className="modal-title SimplurisBidRequestTitle ml-2">Send report via email</h5>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row pl-0 pl-md-3 pl-xl-5 pt-0 pt-md-1 pt-xl-2">
                    <div className="col-md-12 col-xs-12 col-sm-12 mb-1">
                        <h3 className="modal-title SimplurisCaseCallCenterNoneTitle">
                            Send this report to following email addresses:
                            </h3>
                    </div>
                </div>
                <div className="d-flex flex row pl-0 pl-md-3 pl-xl-5">
                    <div className="col-xl-8 col-md-8 col-xs-12 col-sm-12">
                        <form className="" role="form">
                            <div className="form-group mt-2">
                                    <label>Separate email addresses with commas:</label>
                                    <div className={`form-group mt-2 ${emailReqError || emailInvalidError ? 'has-error' : ''}`}>
                                    <input type="email" value={email} onChange={e => (setEmail(e.target.value), setEmailReqError(false), setEmailInvalidError(false))} className="form-control SimplurisNewCaseMemebersInputBox" />
                                    {emailReqError ? <span>Please enter email</span> : null}
                                        {emailInvalidError ? <span>Please enter valid email</span> : null}
                                    </div>
                            </div>
                                <button type="button" onClick={SendReport} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w btn-light py-3 mb-5">Send</button>
                        </form>
                    </div>
                </div>
            </Modal.Body>
            </Modal>            
        </>
    )
}
export default SendReportModalPopup