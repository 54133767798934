import * as React from 'react'
import styled from 'styled-components'

import { teal, lightGray, primaryBlue, red } from 'shared/colors'

const Container = styled.div`
  padding: 20px;  
`

const DistincMailingLable = styled.div`
  color: ${lightGray};
  opacity: 0.6;
  color: #647787;
  font-weight: 400;
`

const Location = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
`
const LocationChild = styled.div`
  flex: 1 1 0%;
`

const InfoSection = ({ selectedMailing, mailTrackingHopsDetailData }) => {        
    return (
        <Container>
            <b className="SimplurisFontSize16 SimplurisFontWeight500">{Object.keys(selectedMailing).length > 0 && selectedMailing.lineSummary}</b>
            <DistincMailingLable className="SimplurisFontSize12">{mailTrackingHopsDetailData != undefined && mailTrackingHopsDetailData.length > 0 && mailTrackingHopsDetailData[0].distinctMailings} distinct Mailings</DistincMailingLable>
            <Location>
                <LocationChild>
                    <b className="SimplurisFontSize10 SimplurisFontWeight700">From</b>
                    <div className="SimplurisFontSize14 SimplurisFontWeight400">{Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.from[0].city},
                    {Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.from[0].state}
                        <i class="ml-4 i-con i-con-arrow-right"><i></i></i>
                    </div>
                </LocationChild>
                <LocationChild>
                    <b className="SimplurisFontSize10 SimplurisFontWeight700">To</b>
                    <div className="SimplurisFontSize14 SimplurisFontWeight400">{Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.to[0].city}, {Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.to[0].state}</div>                    
                </LocationChild>
            </Location>
        </Container>
    )
}

export default InfoSection
