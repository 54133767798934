import { simplurisFetch } from 'helpers'


export const FETCH_ALL_FAVORITE_CASES = 'FETCH_ALL_FAVORITE_CASES'
export const UPDATE_CASE_FAVORITE = 'UPDATE_CASE_FAVORITE'


//Fetch All Favorite Cases
export const FeatchAllFavoriteCases = () => async dispatch => {
    try {
    
    const url = `cases/getallfavoritecases`
    let { resultObject } = await simplurisFetch({ url })

    dispatch({
        type: FETCH_ALL_FAVORITE_CASES,      
        favoriteCases: resultObject       
    })
  } catch (e) {
    console.error('Error in fetch all favorite cases', e, e.errorObject)

    dispatch({
        type: FETCH_ALL_FAVORITE_CASES,
      cases: [],
    })
  }
}

//Favorite case by personmapID and caseID
export const casesFavortiteByPersonMpaID = ({ caseID, officialCaseName }) => async dispatch => {
    try {
        const url = `cases/casesfavorite/${parseInt(caseID)}`
        await simplurisFetch({ url, method: 'POST' }).then((data) => {

            if (data.name != undefined && data.name == "FetchError") {
                if (data.errorObject.resultCode == "ERROR") {
                }
            }
            else {
                dispatch({
                    type: UPDATE_CASE_FAVORITE,
                    isFavorite: data.resultObjectCode,
                    caseIDs: parseInt(caseID),
                    officialCaseName: officialCaseName
                })
            }
        }).catch(e => {
            console.error('Error in casesFavortiteByPersonMpaID', e, e.errorObject);
        });
    } catch (e) {
        console.error('Error in casesFavortiteByPersonMpaID', e, e.errorObject)
        dispatch({
            type: UPDATE_CASE_FAVORITE
        })
    }
}

