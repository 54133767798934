import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Motion, spring } from 'react-motion'
import _ from 'lodash'
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,    
    Markers,
    Marker,
} from 'react-simple-maps'
import ReactTooltip from 'react-tooltip'

import statesJSON from '../mail-tracking/map-section/states.json'
import stateList from './us-states.json'
import { setTimeout } from 'timers';

const centerOfUS = [-97, 40]

const CallMapPage = (data) => {        
    const [center, setCenter] = React.useState(centerOfUS)
    const [zoom, setZoom] = React.useState(1)
    const [content, setContent] = useState("");
    
    // Used to determine if the map was dragged or clicked on as the underlying geography fires onClick even when dragged
    const [mouseDownCoords, setMouseDownCoords] = React.useState({})
    const [mouseUpCoords, setMouseUpCoords] = React.useState({})

    const lastMoveWasAClick = _.isEqual(mouseDownCoords, mouseUpCoords)

    var states = stateList;
    var coord = data.callMapData.filter((x) =>
        x.fromState.toLowerCase() == states[x.abbrev[0]].name.toLowerCase()).map(function (e) {
            return {
                markerOffset: 25,
                name: states[e.abbrev[0]].name,
                coordinates: [parseFloat(states[e.abbrev[0]].long), parseFloat(states[e.abbrev[0]].lat)],
                isCallDoats: false
            };
        });    
    for (var i = 0; i < data.callMapData.length; i++) {
        
        var arrayOfData = data.callMapData[i].callMapData.data;        

        for (var j = 0; j < arrayOfData.length; j++) {   
            if (arrayOfData[j].stateName != undefined || arrayOfData[j].stateName != null) {
                coord.push({
                    markerOffset: 25,
                    name: arrayOfData[j].stateName,
                    coordinates: [parseFloat(arrayOfData[j].longitude), parseFloat(arrayOfData[j].latitude)],
                    isCallDoats:true
                })
            }            
        }
    }
  
    return (<>
        <Motion
            style={{
                x: lastMoveWasAClick ? spring(center[0]) : center[0],
                y: lastMoveWasAClick ? spring(center[1]) : center[1],
                zoom: spring(zoom),
            }}
        >
            {({ x, y, zoom }) => (
                <ComposableMap
                    data-tip=""
                    projection="albersUsa"
                    projectionConfig={{
                        scale: 1000,
                    }}
                    style={{
                        width: '100%',
                        minHeight: '100%',                        
                    }}
                >                    
                    <ZoomableGroup center={[x, y]} zoom={zoom} onMoveEnd={setCenter}>
                        <Geographies geography={statesJSON} disableOptimization>
                            {(geographies, projection) =>                                                 
                                geographies.map((geography, i) => (                                    
                                    <Geography
                                        cacheId={i}
                                        data-tip={geography.properties.NAME_1}
                                        onMouseEnter={() => {                                                                                              
                                            var noOfCalls = data.callMapData.filter((x) => x.fromState.toLowerCase() == geography.properties.NAME_1.toLowerCase()).length > 0 ?
                                                data.callMapData.filter((x) => x.fromState.toLowerCase() == geography.properties.NAME_1.toLowerCase())[0].noOfCalls : '';                                            
                                            var toolTipText = noOfCalls != '' ? noOfCalls.replace(/Total /g, '') : '';
                                            setTimeout(function () {
                                                setContent(`${geography.properties.NAME_1} ${toolTipText}`);
                                            },50)
                                            
                                        }}
                                        onMouseLeave={() => {
                                            setContent("");
                                        }}
                                        onMouseDown={(geo, e) =>
                                            setMouseDownCoords({ x: e.clientX, y: e.clientY })
                                        }
                                        onMouseUp={(geo, e) =>
                                            setMouseUpCoords({ x: e.clientX, y: e.clientY })
                                        }
                                        onClick={({ geometry: { coordinates } }) => {
                                            if (lastMoveWasAClick) {
                                                const allCoords = _.flattenDeep(coordinates)
                                                setCenter(allCoords.slice(0, 2))
                                                setZoom(2)
                                            }
                                        }}
                                        key={i}
                                        geography={geography}
                                        projection={projection}                                       
                                        style={{
                                            default: {
                                                fill: '#ccc',
                                                stroke: '#fff',
                                                strokeWidth: 0.75,
                                                outline: 'red',
                                                cursor: 'pointer'
                },
                                            hover: {
                                                fill: '#CFD8DC',
                                                stroke: '#fff',
                                                strokeWidth: 1,
                                                outline: 'none',
                                                cursor: 'pointer',
                                            },
                                            pressed: {
                                                fill: '#4a90e2',
                                                stroke: '#607D8B',
                                                strokeWidth: 1,
                                                outline: 'none',
                                                cursor: 'pointer',
                                            },
                                        }}
                                    />                                                                        
                                ))
                            }                            
                        </Geographies>  
                        <Markers>                          
                              {
                                coord.map((e, i) => (                                    
                                    <Marker marker={{ coordinates: e.coordinates }}>                                        
                                        <circle r={`${e.isCallDoats == true ? 10 : 15}px`} class="Simpluris-map-marker blue" />
                                    </Marker>
                                )
                                )                                
                            }
                        </Markers>
                    </ZoomableGroup>  
                </ComposableMap>    
                
            )}
            
        </Motion>
        <ReactTooltip>{content}</ReactTooltip>
    </>) 
}

export default CallMapPage