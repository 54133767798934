import * as React from 'react'
import styled from 'styled-components'

const TitleBar = styled.div`
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #348fe9;
	color: white;
	padding: 0 30px;
	font-size: 20px;
`
const Clickable = styled.div`
	cursor: pointer;
`

const FullScreenMapTitleBar = ({ title, exit }) => (
	<TitleBar>
		{title}
		<Clickable onClick={exit}>Exit</Clickable>
	</TitleBar>
)

export default FullScreenMapTitleBar
