import { simplurisFetch } from 'helpers'
export const FETCH_CASE_REPORTS_DATA = 'FETCH_CASE_REPORTS_DATA'
export const RESET_CASE_REPORTS_DATA = 'RESET_CASE_REPORTS_DATA'
export const UPDATE_CASE_DATA_COUNTS_FOR_REPORTS = 'UPDATE_CASE_DATA_COUNTS_FOR_REPORTS'

export const fetchCaseReportsData = ({
    caseID,
    sortColumn,
    sortOrder,
    reportFlag,
    searchValue = null,
}) => async dispatch => {
    try {
        
        const url = `cases/${parseInt(caseID)}/reports/${sortColumn}/${sortOrder}/${reportFlag}/${searchValue}`
        const { resultObject } = await simplurisFetch({ url })
        
        dispatch({
            type: FETCH_CASE_REPORTS_DATA,
            caseReportsData: resultObject,
            caseID,
        })

    } catch (e) {
        console.error('Error in case reports data ', e, e.errorObject)

        dispatch({
            type: FETCH_CASE_REPORTS_DATA,
            caseReportsData: [],
            caseID,
        })
    }
}




export const fetchCaseDataCounts = (caseID) => async dispatch => {
    try {        
        const url = `cases/fetchCaseDataCounts/${caseID}`
        let { resultObject } = await simplurisFetch({ url })        
        dispatch({
            type: UPDATE_CASE_DATA_COUNTS_FOR_REPORTS,
            updatedCaseCountsObject: resultObject,
            caseIDForUpdatedCaseCountsObject: caseID
        })
    } catch (e) {
        console.error('Error in fetchCaseDataCounts', e, e.errorObject)

        dispatch({
            type: UPDATE_CASE_DATA_COUNTS_FOR_REPORTS,
            updatedCaseCountsObject: [],
            caseIDForUpdatedCaseCountsObject: caseID
        })
    }
}

export const resetCaseReportsData = (caseID) => ({
    type: "RESET_CASE_REPORTS_DATA",
    ResetCaseReportDataCaseID: caseID
})