import * as React from 'react'
import styled from 'styled-components'

import TitleBar from './title-bar'
import InfoSection from './info-section'
import MailingRecipientsList from './mailingrecipientslist'
import ScrollArea from 'react-scrollbar'
import { SectionSpinner } from 'react/components/spinners'

const Container = styled.div`
  width: 400px;
  word-break: break-word;
  box-shadow: 0 1px 8px 0 #999999;
  border-radius: 10px;
  background-color: white;  
`
let MailingRecipientsData;
const MailingInfo = ({ selectedMailing, mailTrackingHopsDetailData, caseID, getMailTrackingDdataByMailingHistoryID, apiFlageFetchingMailingHopsDetails }) => {    
    let scrollbarStyles = { borderRadius: 4 };
    if (Object.keys(selectedMailing).length > 0) {
        if (mailTrackingHopsDetailData != undefined && mailTrackingHopsDetailData.length > 0) {
            MailingRecipientsData = mailTrackingHopsDetailData[0].recipients
        }                
        return <>           
            <Container className="ZoomedMailTrackingMailingInfoContainer position-relative">
                {apiFlageFetchingMailingHopsDetails ? <SectionSpinner /> :
                    <>
                        <InfoSection selectedMailing={selectedMailing} mailTrackingHopsDetailData={mailTrackingHopsDetailData} />
                        <MailingRecipientsList mailTrackingHopsDetailData={mailTrackingHopsDetailData} caseID={caseID} MailingRecipientsData={MailingRecipientsData} getMailTrackingDdataByMailingHistoryID={getMailTrackingDdataByMailingHistoryID} apiFlageFetchingMailingHopsDetails={apiFlageFetchingMailingHopsDetails} />
                        {/*<ScrollArea
                        className="area"
                        contentClassName="content"
                        vertical={true}
                        verticalScrollbarStyle={scrollbarStyles}
                        verticalContainerStyle={scrollbarStyles}
                        horizontal={false}
                        smoothScrolling={true}
                        style={{ height: '420px' }}>                        
                        <MailingRecipientsList mailTrackingHopsDetailData={mailTrackingHopsDetailData} caseID={caseID} MailingRecipientsData={MailingRecipientsData} getMailTrackingDdataByMailingHistoryID={getMailTrackingDdataByMailingHistoryID} apiFlageFetchingMailingHopsDetails={apiFlageFetchingMailingHopsDetails} />
                        </ScrollArea>*/}
                        </>
                }
                </Container> 
            
        </>
    }
    else
        return <></>
}


export default MailingInfo
