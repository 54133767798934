import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import a5png from '../../../static/img/a5.jpg'
import { Link } from 'react-router-dom'
import Milestone from './cases-milestones'
import ManagerInfo from './cases-managerinfo'
import { casesFavortiteByPersonMpaID } from '../../../state/cases/favorite-cases/actions'
import { SectionSpinner } from 'react/components/spinners'

const HighlightedText = styled.span`
  background-color: #F7FF9D;
  background-opacity: 65%;
`

const OverlayDiv = styled.div`
position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`



function CasesSection({ casesFavortiteByPersonMpaID, searchTerm, ...item }) {

    const [showPageLoader, setShowPageLoader] = useState(false)
    
    const CasesFavortiteByPersonMpaID = async ({ caseID, officialCaseName }) => {
        setShowPageLoader(true)
        await casesFavortiteByPersonMpaID({ caseID, officialCaseName })
        setShowPageLoader(false)
    }

  var accessCaseDetailPermission
  // if (Object.keys(userPermissions).length > 0) {
  //     accessCaseDetailPermission = userPermissions.find(
  //         (permissionCode) => permissionCode == 'LC_MYCASES_CLICK_CASE_TO_ACCESS_DETAIL'
  //     )
  // }

  const [lastMileStonePosition, setLastMileStonePosition] = useState(-1)

  let caseName = item.item.officialName
  if (searchTerm.length >=3 && caseName) {
    const searchTermIndex = caseName.toLowerCase().indexOf(searchTerm.toLowerCase())
    if (searchTermIndex > -1) {
      caseName = (
        <>
          {caseName.slice(0, searchTermIndex)}
          <HighlightedText>{caseName.slice(searchTermIndex, searchTermIndex + searchTerm.length)}</HighlightedText>
          {caseName.slice(searchTermIndex + searchTerm.length)}
        </>
      )
    }
  }

  if (item.item != null) {
      return (
          <>
              
      <div className="row">
        <div className="col-md-12 col-xs-12 col-sm-12">
          <div className="d-flex flex row">
            <div
              className="col-md-12 col-xs-12 col-sm-12 mb-5"
              data-id={lastMileStonePosition}
            >
              <div
                className={
                  'SimplurisCardRowSpecial card d-flex flex ' +
                  (item.item.caseStageTitle == 'Disbursement' ||
                  item.item.caseStageTitle == 'Pending Disbursement' ||
                  item.item.caseStageTitle ==
                    'Pending Confirmation of Final Approval'
                    ? 'SimplurisCardTopBorderStatusDisbursement'
                    : '') +
                  (item.item.caseStageTitle == 'Delayed'
                    ? 'SimplurisCardTopBorderStatusGray'
                    : '') +
                  (item.item.caseStageTitle == 'Notice & Response Period'
                    ? 'SimplurisCardTopBorderStatusBlue'
                    : '')
                }
              >
                <div
                  className={`${
                    lastMileStonePosition >= 0 ? 'SimplurisCardprogressBG' : ''
                  }`}
                  style={{ width: +(lastMileStonePosition + 28) + 'px' }}
                            ></div>
                                  <a onClick={() => CasesFavortiteByPersonMpaID({ caseID: item.item.caseID, officialCaseName: item.item.officialName })} data-toggle-class="" data-pjax-state="anchor-empty" className={`SimplurisCaseFavorite ${item.item.isFavorite == true ? 'active' : ''}`}>
                                <i className="i-con i-con-star active-solid text-muted active-warning"><i><i></i></i></i>
                            </a>
                <Link
                  to={`/cases/detail/${item.item.caseID}`}
                  className="SimplurisPointer"
                >
                  <div className="card-body">
                                          {
                                              showPageLoader ? <OverlayDiv> <div className="card SimplurisDashboardCard SimplurisCasesList"><SectionSpinner /></div></OverlayDiv> : null
                                          }
                    <div className="row">
                      <div className="col-xl-8 col-md-7 col-xs-12 col-sm-12 px-0">
                        <div className="list list-row block animates animates-fadeIn shadow-none">
                          <div className="list-item">
                            <div>
                              <span className="w-56 avatar circle bg-info-lt">
                                <img src={a5png} alt="." width="55" />
                              </span>
                            </div>
                            <div className="flex">
                              {accessCaseDetailPermission &&
                              accessCaseDetailPermission.length > 0 ? (
                                <h4 className="SimplurisStatusBoxesTitle">
                                  {caseName}
                                </h4>
                              ) : (
                                <h4 className="SimplurisStatusBoxesTitle">
                                  {caseName}
                                </h4>
                              )}
                              <label
                                className={
                                  'font-weight-500 SimplurisFontSize14 ' +
                                  (item.item.caseStageTitle == 'Disbursement' ||
                                  item.item.caseStageTitle ==
                                    'Pending Disbursement' ||
                                  item.item.caseStageTitle ==
                                    'Pending Confirmation of Final Approval'
                                    ? 'SimplurislblModalityPrimary'
                                    : '') +
                                  (item.item.caseStageTitle == 'Delayed'
                                    ? 'SimplurislblModalityGray'
                                    : '') +
                                  (item.item.caseStageTitle ==
                                  'Notice & Response Period'
                                    ? 'SimplurislblModalityBlue'
                                    : '')
                                }
                              >
                                <span
                                  className={
                                    'mr-1 ' +
                                    (item.item.caseStageTitle ==
                                      'Disbursement' ||
                                    item.item.caseStageTitle ==
                                      'Pending Disbursement' ||
                                    item.item.caseStageTitle ==
                                      'Pending Confirmation of Final Approval'
                                      ? 'SimplurisModalityPrimaryStatus'
                                      : '') +
                                    (item.item.caseStageTitle == 'Delayed'
                                      ? 'SimplurisModalityGrayStatus'
                                      : '') +
                                    (item.item.caseStageTitle ==
                                    'Notice & Response Period'
                                      ? 'SimplurisModalityBlueStatus'
                                      : '')
                                  }
                                ></span>
                                                            {item.item.caseStageTitle}
                                                         
                              </label>
                                                    </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-5 col-xs-12 col-sm-12 px-0 SimplurisCaseManagerSection">
                        <ManagerInfo items={item.item} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-xs-12 col-sm-12 pr-0">
                        <Milestone
                          items={item.item.milestones}
                          setLastMileStonePosition={setLastMileStonePosition}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        </div>
        
              </>
    )
  } else {
    return <div></div>
  }
}

//export default CasesSection


export default connect(
    (state, props) => ({        
    }),
    {
        casesFavortiteByPersonMpaID        
    }
)(CasesSection)
