import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PageLoader from 'react/components/page-loader';
import ButtonLoader from '../../components/button-loader';
import { IsNullOrEmptyString } from '../../../global'
import LeftArrowPNG from '../../../static/img/left-arrow.png'
import { simplurisFetch } from 'helpers'

function AddCardMethodModalPopup(props) {    
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [cardID, setCardID] = useState('');
    const [nameOfCard, setNameOfCard] = useState('');
    const [nameOfCardError, setNameOfCardError] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardNumberError, setCardNumberError] = useState(false);
    const [cardNumberInvalidError, setCardNumberInvalidError] = useState(false);
    const [expirationDate, setExpirationDate] = useState('');
    const [expirationDateError, setExpirationDateError] = useState(false);
    const [expirationDateInvalidFormatError, setExpirationDateInvalidFormatError] = useState(false);
    const [expirationDateInvalidError, setExpirationDateInvalidError] = useState(false);
    const [securityCode, setSecurityCode] = useState('');
    const [securityCodeError, setSecurityCodeError] = useState(false);
    const [securityCodeInvalidError, setSecurityCodeInvalidError] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');

    function CloseAddCardMethodModalPopup() {
        setErrorMessage('');
        setNameOfCard('');
        setNameOfCardError(false);
        setCardNumber('');
        setCardNumberError(false);
        setCardNumberInvalidError(false);
        setExpirationDate('');
        setExpirationDateError(false);
        setExpirationDateInvalidFormatError(false);
        setExpirationDateInvalidError(false);
        setSecurityCode('');
        setSecurityCodeError(false);
        setSecurityCodeInvalidError(false);
        props.setShowAddCardMethodModalPopup(false);
      
    }
    
    function BackOpenAddPaymentMethodModalPopup() {        
        props.setShowAddCardMethodModalPopup(false);
        props.setShowAddPaymentMethodModalPopup(true);
    }

    
    const AddCardMethod = () => {               
        let isvalidExporationDate = true;
        const isValidExpirationDateFormat = global.ExpirationDate.test(expirationDate)
        const isValidSecurityCode = global.IsPositiveNumber.test(securityCode)
        const isValidCardNumber = global.IsPositiveNumber.test(cardNumber)        
        var getCurrentDate = new Date();
        var currentYear =  getCurrentDate.getFullYear();
        var currentMonth = getCurrentDate.getMonth() + 1;        
        if (expirationDate.indexOf('/') > -1) {            
            var parts = expirationDate.split('/');
            var year = parseInt(parts[1], 10) + 2000;
            var month = parseInt(parts[0], 10);            
            // compare the dates
            if (year < currentYear || (year == currentYear && month < currentMonth)) {
                isvalidExporationDate = false;
            }
        }
        else
        {
            isvalidExporationDate = false;            
        }
                    
        if (!nameOfCard || !cardNumber || !expirationDate || !securityCode || !isValidExpirationDateFormat || !isvalidExporationDate || !isValidSecurityCode || !isValidCardNumber)
        {                        
            if (!nameOfCard) {
                setNameOfCardError(true);
            }
            if (!cardNumber) {
                setCardNumberError(true);
            }

            if (cardNumber && !isValidCardNumber) {
                setCardNumberInvalidError(true);
            }
            
            if (!expirationDate) {
                setExpirationDateError(true);
            }

            if (expirationDate && !isValidExpirationDateFormat) {                
                setExpirationDateInvalidFormatError(true);
                return;
            }

            if (expirationDate && !isvalidExporationDate) {                
                setExpirationDateInvalidError(true);
                return;
            }

            if (!securityCode) {
                setSecurityCodeError(true);
            }

            if (securityCode && !isValidSecurityCode) {
                setSecurityCodeInvalidError(true);
            }
           
            return;
        }        

        var data = {};
        data.PersonID = parseInt(localStorage.getItem(global.PersonID));
        data.Email = localStorage.getItem(global.Email)
        data.NameOfCard = nameOfCard;
        data.CardNumber = cardNumber;
        data.ExpirationDate = expirationDate;
        data.SecurityCode = securityCode;
        data.FullName = localStorage.getItem(global.FirstName) + " " + localStorage.getItem(global.LastName);
        setShowButtonLoader(true);        

        simplurisFetch({
            url: "auth/savecard", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowButtonLoader(false);
            if (data.name != undefined && data.name == "FetchError") {                                
                if (data.errorObject.errorMessage == "Your card number is incorrect.") {
                    setErrorMessage(data.errorObject.errorMessage);
                }
                else {
                    setErrorMessage("Something went wrong.");
                }
            }
            else {                
                setErrorMessage('');
                CloseAddCardMethodModalPopup();
                props.fetchAllCards();
            }
            }).catch(e => {
                console.error('Error in save card', e, e.errorObject);
                setShowButtonLoader(false);
                if (e.errorObject.errorMessage == "Your card number is incorrect.") {
                    setErrorMessage(e.errorObject.errorMessage);
                }
                else {
                    setErrorMessage("Something went wrong.");
                }
            });       
    }
    
    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseAddCardMethodModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">
                            <a href={null} onClick={() => BackOpenAddPaymentMethodModalPopup()}>
                                <img src={LeftArrowPNG} width="25px" className="pr-2 pb-2" /></a>
                            Add Credit or Debit Card
                    </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5">
                    <div className="row mt-4">                        
                        <div className="col-md-12 mb-1">
                            {ErrorMessage != "" ? <ErrorPlacement items={ErrorMessage} /> : ''}
                            <p className="modal-title SimplurisFontSize19 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                Enter your card information.
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex row mt-2">
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className={`form-group ${nameOfCardError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Name of card</label>
                                <div className="position-relative">
                                    <input type="text" tabIndex="1" value={nameOfCard} onChange={e => (setNameOfCard(e.target.value), setNameOfCardError(false))} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="Name Of Card" />
                                    {nameOfCardError ? <span>Please enter name of card</span> : null}
                                </div>
                            </div>

                            <div className={`form-group ${expirationDateError || expirationDateInvalidFormatError || expirationDateInvalidError? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Expiration Date</label>
                                <div className="position-relative">
                                    <input type="text" tabIndex="3" value={expirationDate} maxLength="5" onChange={e => (setExpirationDate(e.target.value), setExpirationDateInvalidFormatError(false), setExpirationDateError(false), setExpirationDateInvalidError(false))} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="MM/YY" />
                                    {expirationDateError ? <span>Please enter expiration date</span> : null}
                                    {expirationDateInvalidFormatError ? <span>Please enter a 2-digit month and a 2-digit year.</span> : null}
                                    {expirationDateInvalidError ? <span>Please enter a valid expiration date.</span> : null}
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className={`form-group ${cardNumberError || cardNumberInvalidError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Card Number</label>
                                <div className="position-relative">
                                    <input type="text" tabIndex="2" maxLength="16" value={cardNumber} onChange={e => (setCardNumber(e.target.value), setCardNumberError(false), setCardNumberInvalidError(false))} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="Card Number" />
                                    {cardNumberError ? <span>Please enter card number</span> : null}
                                    {cardNumberInvalidError ? <span>Please enter valid card number</span> : null}
                                </div>
                            </div>

                            <div className={`form-group ${securityCodeError || securityCodeInvalidError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Security Code</label>
                                <div className="position-relative">
                                    <input type="text" tabIndex="4" maxLength="4" value={securityCode} onChange={e => (setSecurityCode(e.target.value), setSecurityCodeError(false), setSecurityCodeInvalidError(false))} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="Security Code" />
                                    {securityCodeError ? <span>Please enter security code</span> : null}
                                    {securityCodeInvalidError ? <span>Please enter valid security code</span> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w btn-light py-3 mb-4 mt-3" onClick={AddCardMethod}>
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Save</span>}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCardMethodModalPopup

function ErrorPlacement (data) {    
        if (data.items != "") {
            return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
                {data.items}
            </div>
        }    
}
