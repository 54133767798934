import React, { Component } from 'react';

const Wording = () =>
    <div>
        <br />
        <p>LICENSE AGREEMENT FOR USE OF LIVECASE PLATFORM</p>
        <p>This License Agreement for use of LiveCase Program (the "<strong>Agreement</strong>"), is by and between Simpluris, Inc., a &nbsp;corporation formed under the laws of the State of California with its primary place of business at 3194-C Airport Loop Drive, Costa Mesa, CA 92626 ("<strong>Licensor</strong>") and you (“<strong>Licensee</strong>” or &nbsp;“<strong>Authorized User</strong>”). &nbsp;This Agreement governs the Authorized User’s use of LiveCase, described more fully below. &nbsp; LiveCase is licensed, not sold, to the Authorized User pursuant to the terms of this agreement. &nbsp;Licensor and Authorized User may be referred to herein collectively as the "<strong>Parties</strong>" or individually as a "<strong>Party</strong>."</p>
        <p><strong><u>BY CLICKING THE "AGREE" BUTTON, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE LIVECASE.</u></strong></p>
        <p><u>RECITALS</u></p>
        <p>Licensor Company is the creator of LiveCase platform (“<strong>LiveCase</strong>”). &nbsp;LiveCase is a comprehensive technology platform that provides users in the legal industry with a centralized dashboard to track, manage, and collaborate on settlement administration, including but not limited to, settlement administration for labor and employment class action settlements has developed an application. &nbsp;</p>
        <p>Licensor desires to provide a non-exclusive license to Licensee for the use of LiveCase; and &nbsp;</p>
        <p>Licensee desires to obtain a license to use LiveCase for his/her own internal business purposes, subject to the terms and conditions of this Agreement.</p>
        <p>In consideration of the mutual covenants, terms, and conditions set forth herein, and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the Parties agree as follows:</p>
        <ol>
            <li>Definitions.</li>
        </ol>
        <p>"<strong>Action</strong>" means any claim, action, cause of action, demand, lawsuit, arbitration, inquiry, audit, notice of violation, proceeding, litigation, citation, summons, subpoena, or investigation of any nature, civil, criminal, administrative, regulatory, or other, whether at law, in equity, or otherwise.</p>
        <p>"<strong>Affiliate</strong>" of a Person means any other Person that directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with, such Person. The term "control" (including the terms "controlled by" and "under common control with") means the direct or indirect power to direct or cause the direction of the management and policies of a Person, whether through the ownership of voting securities, by contract, or otherwise/ownership of more than 50 % of the voting securities of a Person.</p>
        <p>"<strong>Agreement</strong>" has the meaning set forth in the preamble.</p>
        <p>"<strong>Authorized User</strong>" means individual user or Licensee authorized to use LiveCase..</p>
        <p>"<strong>Business Day</strong>" means a day other than a Saturday, Sunday, or other day on which commercial banks in Los Angeles, California are authorized or required by Law to be closed for business.</p>
        <p>"<strong>Confidential Information</strong>" has the meaning set forth in Section 6.1.&nbsp;</p>
        <p>"<strong>Disclosing Party</strong>" has the meaning set forth in Section 6.1.</p>
        <p>"<strong>Effective Date</strong>" has the meaning set forth in the preamble.</p>
        <p>"<strong>Force Majeure Event</strong>" has the meaning set forth in Section 12.9(a).</p>
        <p>"<strong>Indemnitee</strong>" has the meaning set forth in Section 9.3.</p>
        <p>"<strong>Indemnitor</strong>" has the meaning set forth in Section 9.3.</p>
        <p>"<strong>Intellectual Property Rights</strong>" means any and all registered and unregistered rights granted, applied for, or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection, or other intellectual property rights Laws, and all similar or equivalent rights or forms of protection, in any part of the world.</p>
        <p>"<strong>Law</strong>" means any statute, law, ordinance, regulation, rule, code, order, constitution, treaty, common law, judgment, decree, or other requirement of any federal, state, local, or foreign government or political subdivision thereof, or any arbitrator, court, or tribunal of competent jurisdiction.</p>
        <p>"<strong>Licensee Indemnitee</strong>" has the meaning set forth in Section 9.1.</p>
        <p>"<strong>Licensor Indemnitee</strong>" has the meaning set forth in Section 9.2.</p>
        <p>"<strong>LiveCase</strong>" means the executable, object code version of the Software-as-a-Service (SaaS), web-based application, provided to Licensee pursuant to this Agreement.&nbsp;</p>
        <p>"<strong>Losses</strong>" means all losses, damages, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.&nbsp;</p>
        <p>"<strong>Permitted Use</strong>" means use of LiveCase by an Authorized User for the benefit of Licensee in the ordinary course of its internal business operations and for the purpose of viewing and managing case information in a limited form. Licensee acknowledges and agrees that the Permitted Use of LiveCase is only for general, overview information and is not to be used as a specific or complete representation of the summary level data, charts, figures, reports, or detail level data, or other matters contained therein. &nbsp;</p>
        <p>"<strong>Person</strong>" means an individual, corporation, partnership, joint venture, limited liability entity, governmental authority, unincorporated organization, trust, association, or other entity.</p>
        <p>"<strong>Receiving Party</strong>" has the meaning set forth in Section 6.1.</p>
        <p>"<strong>Representatives</strong>" means, with respect to a Party, that Party's and its Affiliates' employees, officers, directors, consultants, agents, independent contractors, service providers, sublicensees, subcontractors, and legal advisors.</p>
        <p>"<strong>Term</strong>" has the meaning set forth in Section 12.1.</p>
        <ol className="decimal_type">
            <li>License.
                <ol className="decimal_type">
                    <li>License Grant. Subject to and conditioned on Licensee's compliance with all other terms and conditions of this Agreement, Licensor hereby grants to Licensee a non-exclusive, non-sublicensable, and non-transferable (except in compliance with Section 12.8) license to use LiveCase solely for the Permitted Use during the Term. &nbsp;</li>
                    <li>Scope of Licensed Access and Use. Licensor will provide Licensee with password. &nbsp;The passwords will allow access LiveCase for the Permitted Use from any device. &nbsp;Upon receiving written notice, Licensor will immediately disable the password and remove access privileges for these individuals. &nbsp;&nbsp;</li>
                    <li>Security Measures. LiveCase may contain technological measures designed to prevent unauthorized or illegal use of LiveCase. Licensee acknowledges and agrees that: (a) Licensor may use these and other lawful measures to verify Licensee's compliance with the terms of this Agreement and enforce Licensor's rights, including all Intellectual Property Rights, in and to LiveCase; (b) Licensor may deny any individual access to and/or use of LiveCase if Licensor, in its discretion, believes that person's use of LiveCase would violate any provision of this Agreement, regardless of whether Licensee designated that person as an Authorized User; and (c) Licensor and its Representatives may collect, maintain, process and use diagnostic, technical, usage and related information, including information about Licensee's computers, systems and LiveCase, that Licensor may gather periodically to improve the performance of LiveCase. This information will be treated in accordance with Licensor's privacy policy, as amended from time to time, which can be viewed at:
                        <a href="https://livecase.simpluris.com">https://livecase.simpluris.com</a>,&nbsp;
                        <a href="https://simpluris.com">https://simpluris.com</a>, or a successor website address.
                    </li>
                </ol>
            </li>
            <li>License Restrictions. Except as this Agreement expressly permits, Licensee shall not, and shall not permit any other Person to: &nbsp;
                <ol>
                    <li>copy LiveCase, in whole or in part; &nbsp;</li>
                    <li>modify, correct, adapt, translate, enhance, or otherwise prepare derivative works or improvements of any LiveCase;</li>
                    <li>offer, rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available LiveCase to any third party;</li>
                    <li>reverse engineer, disassemble, decompile, decode, or adapt LiveCase, or otherwise attempt to derive or gain access to the source code, compiled code or any form of web-based or browser-based software development or logic of LiveCase, in whole or in part;</li>
                    <li>bypass or breach any security device or protection used for or contained in LiveCase;</li>
                    <li>remove, delete, efface, alter, obscure, translate, combine, supplement, or otherwise change any trademarks, warranties, disclaimers, or Intellectual Property Rights, proprietary rights or other symbols, notices, marks, or serial numbers relating to LiveCase;</li>
                    <li>use LiveCase in any manner or for any purpose that infringes, misappropriates, or otherwise violates any Intellectual Property Right or other right of any Person, or that violates any applicable Law;</li>
                    <li>use LiveCase for purposes of: (i) benchmarking or competitive analysis of LiveCase; (ii) developing, using, or providing a competing LiveCase product or service; or (iii) any other purpose that is to Licensor's detriment or commercial disadvantage; or</li>
                    <li>use LiveCase other than for the Permitted Use or in any manner or for any purpose or application not expressly permitted by this Agreement.</li>
                </ol>
            </li>
            <li>Delivery. Licensor shall provide passwords to the Licensee that permit instant access to LiveCase via any web browser. &nbsp;</li>
            <li>Confidentiality.
                <ol className="decimal_type">
                    <li>Confidential Information. In connection with this Agreement, each Party (the "<strong>Disclosing Party</strong>") may disclose or make available Confidential Information to the other Party (the "<strong>Receiving Party</strong>"). Subject to Section 5.2, "<strong>Confidential Information</strong>" means information in any form or medium (whether oral, written, electronic, or other) that the Disclosing Party considers confidential or proprietary, including information consisting of or relating to the Disclosing Party's technology, trade secrets, know-how, business operations, plans, strategies, customers, and pricing, and information with respect to which the Disclosing Party has contractual or other confidentiality obligations, whether or not marked, designated, or otherwise identified as "confidential." Without limiting the foregoing: (a) LiveCase and its associated source code, compiled code or any form of web-based or browser-based software development or logic is the Confidential Information of Licensor; and (b) the financial terms and existence of this Agreement are the Confidential Information of Licensor.&nbsp;</li>
                    <li>Exclusions. Confidential Information does not include information that the Receiving Party can demonstrate by written or other documentary records: (a) was rightfully known to the Receiving Party without restriction on use or disclosure prior to such information being disclosed or made available to the Receiving Party in connection with this Agreement; (b) was or becomes generally known by the public other than by the Receiving Party's or any of its Representatives' noncompliance with this Agreement; (c) was or is received by the Receiving Party on a non-confidential basis from a third party that was not or is not, at the time of such receipt, under any obligation to maintain its confidentiality; or (d) was or is independently developed by the Receiving Party without reference to or use of any Confidential Information.</li>
                    <li>Protection of Confidential Information. As a condition to being provided with any disclosure of or access to Confidential Information, the Receiving Party shall:
                        <ol className="decimal_type">
                            <li>not access or use Confidential Information other than as necessary to exercise its rights or perform its obligations under and in accordance with this Agreement;</li>
                            <li>except as may be permitted under the terms and conditions of Section 5.4, not disclose or permit access to Confidential Information other than to its Representatives who: (i) need to know such Confidential Information for purposes of the Receiving Party's exercise of its rights or performance of its obligations under and in accordance with this Agreement; (ii) have been informed of the confidential nature of the Confidential Information and the Receiving Party's obligations under this Section 9; and (iii) are bound by written confidentiality and restricted use obligations at least as protective of the Confidential Information as the terms set forth in this Section 9;</li>
                            <li>safeguard the Confidential Information from unauthorized use, access or disclosure using at least the degree of care it uses to protect its most sensitive information and in no event less than a reasonable degree of care;</li>
                            <li>promptly notify the Disclosing Party of any unauthorized use or disclosure of Confidential Information and take all reasonable steps to prevent further unauthorized use or disclosure; and</li>
                            <li>ensure its Representatives' compliance with, and be responsible and liable for any of its Representatives' non-compliance with, the terms of this Section 8.</li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
        <p>The obligations set forth in this section shall last for a period of for three (3) years after termination of this Agreement or for any period set by a court of competent jurisdiction for which information is maintained on LiveCase, whichever is longer. &nbsp;Notwithstanding any other provisions of this Agreement, the Receiving Party's obligations under this Section 6 with respect to any Confidential Information that constitutes a trade secret under any applicable Law will continue until such time, if ever, as such Confidential Information ceases to qualify for trade secret protection under one or more such applicable Laws other than as a result of any act or omission of the Receiving Party or any of its Representatives.</p>
        <ol>
            <li>Compelled Disclosures. If the Receiving Party or any of its Representatives is compelled by applicable Law to disclose any Confidential Information then, to the extent permitted by applicable Law, the Receiving Party will: (a) promptly, and prior to such disclosure, notify the Disclosing Party in writing of such requirement so that the Disclosing Party can seek a protective order or other remedy or waive its rights under Section 5.3; and (b) provide reasonable assistance to the Disclosing Party in opposing such disclosure or seeking a protective order or other limitations on disclosure. If the Disclosing Party waives compliance or, after providing the notice and assistance required under this Section&nbsp;5.4, the Receiving Party remains required by Law to disclose any Confidential Information, the Receiving Party will disclose only that portion of the Confidential Information that the Receiving Party is legally required to disclose.</li>
        </ol>
        <ol className="decimal_type">
            <li>Intellectual Property Rights.
                <ol className="decimal_type">
                    <li>Intellectual Property Ownership. Licensee acknowledges and agrees that:
                        <ol className="decimal_type">
                            <li>LiveCase is licensed, not sold, to Licensee by Licensor and Licensee does not have under or in connection with this Agreement any ownership interest in LiveCase, or in any related Intellectual Property Rights;</li>
                            <li>Licensor is the sole and exclusive owner of all right, title, and interest in and to LiveCase, including all Intellectual Property Rights relating thereto, subject only to the limited license granted to Licensee under this Agreement; and</li>
                            <li>Licensee hereby unconditionally and irrevocably assigns to Licensor its entire right, title, and interest in and to any Intellectual Property Rights that Licensee may now or hereafter have in or relating to LiveCase (including any rights in derivative works or patent improvements relating to either of them), whether held or acquired by operation of law, contract, assignment or otherwise.</li>
                        </ol>
                    </li>
                    <li>Licensee Cooperation and Notice of Infringement. Licensee shall, during the Term:
                        <ol className="decimal_type">
                            <li>safeguard LiveCase from infringement, misappropriation, theft, misuse, or unauthorized access;</li>
                            <li>take all such steps as Licensor may reasonably require to assist Licensor in maintaining the validity, enforceability and Licensor's ownership of the Intellectual Property Rights in LiveCase;</li>
                            <li>promptly notify Licensor in writing if Licensee becomes aware of: (i) any actual or suspected infringement, misappropriation or other violation of Licensor's Intellectual Property Rights in or relating to LiveCase; or (ii) any claim that LiveCase, including any production, use, marketing, sale or other disposition of LiveCase, in whole or in part, infringes, misappropriates or otherwise violates the Intellectual Property Rights or other rights of any Person; and</li>
                            <li>fully cooperate with and assist Licensor in all reasonable ways in the conduct of any Action by Licensor to prevent or abate any actual or threatened infringement, misappropriation or violation of Licensor's rights in, and to attempt to resolve any Actions relating to, LiveCase, including having Licensee's employees testify when requested and making available for discovery or trial relevant records, papers, information, samples, specimens and the like.</li>
                        </ol>
                    </li>
                    <li>No Implied Rights. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication, waiver, estoppel or otherwise, to Licensee or any third party any Intellectual Property Rights or other right, title, or interest in or to any of LiveCase.</li>
                </ol>
            </li>
            <li>DISCLAIMER OF WARRANTIES. &nbsp;ALL LICENSED LIVECASE INFORMATION, MATERIALS, AND SERVICES PROVIDED BY LICENSOR ARE PROVIDED "AS IS." LICENSOR SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, LICENSOR MAKES NO WARRANTY OF ANY KIND THAT THE LICENSED LIVECASE OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET LICENSEE'S OR OTHER PERSONS' REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY OTHER PROGRAM, SYSTEMS, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE OR ERROR FREE.</li>
        </ol>
        <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU</p>
        <ol className="decimal_type">
            <li>Indemnification.
                <ol className="decimal_type">
                    <li>Licensor Indemnification. Licensor shall indemnify, defend, and hold harmless Licensee and Licensee's officers, directors, employees, agents, permitted successors and permitted assigns (each, a "<strong>Licensee Indemnitee</strong>") from and against any and all Losses incurred by Licensee/the Licensee Indemnitee resulting from any Action by a third party that LiveCase, or any use of LiveCase in accordance with this Agreement, infringes or misappropriates such third party's US Intellectual Property Rights/US patents, copyrights, or trade secrets. &nbsp;This Section 8.1 does not apply to the extent that the alleged infringement arises from:
                        <ol className="decimal_type">
                            <li>operation, or use of LiveCase in or with, any technology (including any program, hardware, firmware, system, or network) or service not provided by Licensor;</li>
                            <li>modification of LiveCase other than: (i) by Licensor in connection with this Agreement; or (ii) with Licensor's express written authorization and in strict accordance with Licensor's written directions and specifications;</li>
                            <li>use of LiveCase after Licensor's notice to Licensee of such activity's alleged or actual infringement, misappropriation, or other violation of a third party's rights;</li>
                            <li>negligence, abuse, misapplication, or misuse of LiveCase by or on behalf of Licensee, Licensee's Representatives, or a third party;</li>
                            <li>use of LiveCase by or on behalf of Licensee that is outside the purpose, scope, or manner of use authorized by this Agreement or in any manner contrary to Licensor's instructions;</li>
                            <li>events or circumstances outside of Licensor's commercially reasonable control, including any third-party hardware, LiveCase, or system bugs, defects, or malfunctions; or</li>
                            <li>Third-Party Claims or Losses for which Licensee is obligated to indemnify Licensor pursuant to Section 8.2.</li>
                        </ol>
                    </li>
                    <li>Licensee Indemnification. Licensee shall indemnify, defend, and hold harmless Licensor and its Affiliates, and each of its and their respective officers, directors, employees, agents, subcontractors, successors and permitted assigns (each, a "<strong>Licensor Indemnitee</strong>") from and against any and all Losses incurred by Licensor or the Licensor Indemnitee] resulting from any Action by a third party:
                        <ol className="decimal_type">
                            <li>that any Intellectual Property Rights or other right of any Person, or any Law, is or will be infringed, misappropriated, or otherwise violated by any:
                                <ol>
                                    <li>use of LiveCase by or on behalf of Licensee or any of its Representatives with any hardware, program, system, network, service, or other matter whatsoever that is neither provided by Licensor nor authorized by Licensor in this Agreement; and</li>
                                    <li>information, materials, or technology directly or indirectly provided by Licensee or directed by Licensee to be installed, combined, integrated, or used with, as part of, or in connection with LiveCase;</li>
                                </ol>
                            </li>
                            <li>relating to facts that, if true, would constitute a breach by Licensee of any representation, warranty, covenant, or obligation under this Agreement;</li>
                            <li>relating to negligence, abuse, misapplication, misuse or more culpable act or omission (including recklessness or willful misconduct) by or on behalf of Licensee or any of its Representatives with respect to LiveCase or otherwise in connection with this Agreement; or</li>
                            <li>relating to use of LiveCase by or on behalf of Licensee or any of its Representatives that is outside the purpose, scope or manner of use authorized by this Agreement, or in any manner contrary to Licensor's instructions.</li>
                        </ol>
                    </li>
                    <li>Indemnification Procedure. Each Party shall promptly notify the other Party in writing of any Action for which such Party believes it is entitled to be indemnified pursuant to Section 8.1&nbsp;or Section&nbsp;8.2. The Party seeking indemnification (the "<strong>Indemnitee</strong>") shall cooperate with the other Party (the "<strong>Indemnitor</strong>") at the Indemnitor's sole cost and expense. The Indemnitor shall promptly assume control of the defense and investigation of such Action and shall employ counsel to handle and defend the same, at the Indemnitor's sole cost and expense. The Indemnitee may participate in and observe the proceedings at its own cost and expense with counsel of its own choosing. The Indemnitor shall not settle any Action on any terms or in any manner that adversely affects the rights of any Indemnitee without the Indemnitee's prior written consent, which shall not be unreasonably withheld or delayed. If the Indemnitor fails or refuses to assume control of the defense of such Action, the Indemnitee shall have the right, but no obligation, to defend against such Action, including settling such Action after giving notice to the Indemnitor, in each case in such manner and on such terms as the Indemnitee may deem appropriate. The Indemnitee's failure to perform any obligations under this Section 9.3 will not relieve the Indemnitor of its obligations under this Section 9, except to the extent that the Indemnitor can demonstrate that it has been prejudiced as a result of such failure.</li>
                    <li>Mitigation. If LiveCase, or any part of LiveCase, is, or in Licensor's opinion is likely to be, claimed to infringe, misappropriate, or otherwise violate any third-party Intellectual Property Right, or if Licensee's use of LiveCase is enjoined or threatened to be enjoined, Licensor may, at its option and sole cost and expense:
                        <ol className="decimal_type">
                            <li>obtain the right for Licensee to continue to use LiveCase as contemplated by this Agreement;</li>
                            <li>modify or replace LiveCase, in whole or in part, to seek to make LiveCase non-infringing, while providing materially equivalent features and functionality, and such modified or replacement LiveCase will constitute LiveCase under this Agreement; or</li>
                            <li>if none of the remedies set forth in the above Section 8.4(a)&nbsp;or Section&nbsp;8.4(b)&nbsp;is reasonably available to Licensor, terminate this Agreement, in its entirety or with respect to the affected part or feature of LiveCase, effective immediately on written notice to Licensee, in which event Licensee shall cease all use of LiveCase immediately on receipt of Licensee's notice; and</li>
                        </ol>
                    </li>
                    <li>Sole Remedy. THIS SECTION 8 SETS FORTH LICENSEE'S SOLE REMEDIES AND LICENSOR'S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT LIVECASE OR ANY SUBJECT MATTER OF THIS AGREEMENT INFRINGES, MISAPPROPRIATES, OR OTHERWISE VIOLATES ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY.</li>
                </ol>
            </li>
            <li>Limitations of Liability.
                <ol className="decimal_type">
                    <li>EXCLUSION OF DAMAGES. IN NO EVENT WILL LICENSOR &nbsp;BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY (a) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES OR PROFITS, (b) LOSS OF GOODWILL OR REPUTATION, (c) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR RECOVERY OF ANY LICENSED LIVECASE, (d) LOSS, DAMAGE, CORRUPTION, OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY, (e) COST OF REPLACEMENT GOODS OR SERVICES, OR (f) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES, IN EACH CASE REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</li>
                    <li>CAP ON MONETARY LIABILITY. IN NO EVENT WILL THE AGGREGATE LIABILITY OF LICENSOR ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED ONE HUNDRED DOLLARS ($100).</li>
                    <li>BASIS OF THE BARGAIN. &nbsp;THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN&nbsp;LICENSOR AND LICENSEE.</li>
                </ol>
            </li>
            <li>Term and Termination.
                <ol className="decimal_type">
                    <li>Term. The term of this Agreement commences when the Licensee acknowledges his/her acceptance of the terms and will continue in effect until terminated by Licensor at any time (the "Term"). &nbsp;</li>
                    <li>Effect of Termination or Expiration.
                        <ol className="decimal_type">
                            <li>On the expiration or earlier termination of this Agreement:
                                <ol className="decimal_type">
                                    <li>all rights, licenses and authorizations granted to licensee hereunder will immediately terminate and Licensee will (A) immediately cease all use of and other activities with respect to LiveCase; (B) within five (5) days deliver to Licensor, or at Licensor's written request destroy, and permanently erase from all devices and systems Licensee directly or indirectly controls, LiveCase and the Licensor's Confidential Information, including all documents, files, and tangible materials (and any partial and complete copies) containing, reflecting, incorporating, or based on any of the foregoing, whether or not modified or merged into other materials; and (C) certify to Licensor in a signed written instrument that it has complied with the requirements of this Section 10.2; and</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>Surviving Terms. The provisions set forth in the following sections, and any other right, obligation or provision under this Agreement that, by its nature, should survive termination or expiration of this Agreement, will survive any expiration or termination of this Agreement: this Section 10.3, Section 1, Section 5, Section&nbsp;6,&nbsp;
                        <a href="#a600580">Section 7</a>, Section&nbsp;8, &nbsp;Section 9, and Section&nbsp;11.
                    </li>
                </ol>
            </li>
            <li>Miscellaneous.
                <ol className="decimal_type">
                    <li>Further Assurances. On a Party's reasonable request, the other Party shall, at the requesting Party's sole cost and expense, execute and deliver all such documents and instruments, and take all such further actions, as may be necessary to give full effect to this Agreement.</li>
                    <li>Relationship of the Parties. The relationship between the Parties is that of independent contractors. Nothing contained in this Agreement will be construed as creating any agency, partnership, joint venture, or other form of joint enterprise, employment, or fiduciary relationship between the Parties, and neither Party shall have authority to contract for or bind the other Party in any manner whatsoever.</li>
                    <li>Interpretation. For purposes of this Agreement: (a) the words "include," "includes" and "including" are deemed to be followed by the words "without limitation"; (b) the word "or" is not exclusive; (c) the words "herein," "hereof," "hereby," "hereto" and "hereunder" refer to this Agreement as a whole; (d) words denoting the singular have a comparable meaning when used in the plural, and vice versa; and (e) words denoting any gender include all genders. Unless the context otherwise requires, references in this Agreement: (x) to sections, exhibits, schedules, attachments, and appendices mean the sections of, and exhibits, schedules, attachments, and appendices to, this Agreement; (y) to an agreement, instrument or other document means such agreement, instrument or other document as amended, supplemented and modified from time to time to the extent permitted by the provisions thereof; and (z) to a statute means such statute as amended from time to time and includes any successor legislation thereto and any regulations promulgated thereunder. The Parties intend this Agreement to be construed without regard to any presumption or rule requiring construction or interpretation against the Party drafting an instrument or causing any instrument to be drafted. The exhibits, schedules, attachments, and appendices referred to herein are an integral part of this Agreement to the same extent as if they were set forth verbatim herein.</li>
                    <li>Headings. The headings in this Agreement are for reference only and do not affect the interpretation of this Agreement.</li>
                    <li>Entire Agreement. This Agreement constitutes the sole and entire agreement of the Parties with respect to the subject matter of this Agreement and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter.</li>
                    <li>Assignment. Licensee shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance under this Agreement, in each case whether voluntarily, involuntarily, by operation of law, or otherwise, without Licensor's prior written consent. No assignment, delegation, or transfer will relieve Customer of any of its obligations or performance under this Agreement. Any purported assignment, delegation, or transfer in violation of this Section 14.8 is void. This Agreement is binding on and inures to the benefit of the Parties hereto and their respective successors and permitted assigns.</li>
                    <li>Force Majeure.
                        <ol className="decimal_type">
                            <li>No Breach or Default. In no event will Licensor be liable or responsible to Licensee or be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement when and to the extent such failure or delay is caused by any circumstances beyond Licensor's reasonable control (a "<strong>Force Majeure Event</strong>"), including acts of God, flood, fire, earthquake or explosion, war, terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect on or after the Effective Date, national or regional emergency, strikes, labor stoppages or slowdowns or other industrial disturbances, passage of Law or any action taken by a governmental or public authority, including imposing an export or import restriction, quota, or other restriction or prohibition or any complete or partial government shutdown, or national or regional shortage of adequate power or telecommunications or transportation. Either Party may terminate this Agreement if a Force Majeure Event continues substantially uninterrupted for a period of thirty (30) days or more.</li>
                            <li>Affected Party Obligations. In the event of any failure or delay caused by a Force Majeure Event, Licensor will give prompt written notice to Licensee stating the period of time the occurrence is expected to continue and use commercially reasonable efforts to end the failure or delay and minimize the effects of such Force Majeure Event.</li>
                        </ol>
                    </li>
                    <li>No Third-Party Beneficiaries. This Agreement is for the sole benefit of the Parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or will confer on any other Person any legal or equitable right, benefit, or remedy of any nature whatsoever under or by reason of this Agreement.</li>
                    <li>Amendment and Modification; Waiver. No amendment to or modification of this Agreement is effective unless it is in writing and signed by an authorized representative of each Party. No waiver by any Party of any of the provisions hereof is effective unless explicitly set forth in writing and signed by the Party so waiving. Except as otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any rights, remedy, power, or privilege arising from this Agreement will operate or be construed as a waiver thereof; nor will any single or partial exercise of any right, remedy, power, or privilege hereunder preclude any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</li>
                    <li>Severability. If any provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. On such determination that any term or other provision is invalid, illegal, or unenforceable, the Parties hereto shall negotiate in good faith to modify this Agreement so as to effect the original intent of the Parties as closely as possible in a mutually acceptable manner in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.</li>
                    <li>Governing Law; Submission to Jurisdiction. This Agreement is governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of California. Any legal suit, action, or proceeding arising out of or related to this Agreement or the licenses granted hereunder will be instituted in the federal courts of the United States or the courts of the State of California in each case located in the County of Orange and each Party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding. Service of process, summons, notice, or other document by mail to such Party's address set forth herein will be effective service of process for any suit, action, or other proceeding brought in any such court.</li>
                    <li>Equitable Relief. Licensee acknowledges and agrees that a breach or threatened breach by Licensee of any of its obligations under
                        <a href="#a961471"><u>Section 3</u></a><u>,&nbsp;</u>
                        <a href="#a600580"><u>Section 5&nbsp;</u></a>, Section 6, or Section 8 of this Agreement would cause Licensor irreparable harm for which monetary damages would not be an adequate remedy and that, in the event of such breach or threatened breach, Licensor will be entitled to equitable relief, wincluding in a restraining order, an injunction, specific performance, and any other relief that may be available from any court of competent jurisdiction, without any requirement to post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy. Such remedies are not exclusive and are in addition to all other remedies that may be available at law, in equity, or otherwise.
                    </li>
                    <li>Attorneys' Fees. In the event that any action, suit, or other legal or administrative proceeding is instituted or commenced by either Party against the other Party arising out of or related to this Agreement, the prevailing Party is entitled to recover its reasonable attorneys' fees and court costs from the non-prevailing Party.</li>
                </ol>
            </li>
        </ol>
    </div>


function AgreeTermsConditionsContent() {
    return (
        <Wording />
    );
}

export default AgreeTermsConditionsContent;