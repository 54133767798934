import {
    FETCH_MAILINGS_HISTORY,
    FETCH_MAILINGS_TOP_LEVEL_HOPS,
    FETCH_MAILINGS_HOP_DETAILS,
    FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID
} from './actions'

import { resolved } from 'state/helpers'

const initialState = {}

export default (state = initialState, { type, history, MailTrackingTopLevelHops, MailTrackingHopsDetail, MailingHistoryDetail }) => {
	switch (type) {
		case resolved(FETCH_MAILINGS_HISTORY):
			return {
				...state,
				...history.reduce((historyByID, historyItem) => {
					historyByID[historyItem.mailingHistoryID] = historyItem
					return historyByID
				}, {}),
            }
        case resolved(FETCH_MAILINGS_TOP_LEVEL_HOPS):            
            return {
                ...state,
                MailTrackingTopLevelHops: MailTrackingTopLevelHops.lines
            }            
        case resolved(FETCH_MAILINGS_HOP_DETAILS):            
            return {
                ...state,
                MailTrackingHopsDetail: MailTrackingHopsDetail.lines
            }
        case resolved(FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID):            
            return {
                ...state,
                MailingHistoryDetail: MailingHistoryDetail
            }
		default:
			return state
	}
}
