import React, { useState, useEffect, useRef } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { SectionSpinner } from 'react/components/spinners'
import { simplurisFetch } from 'helpers'
import Exportpng from 'static/img/export.png'
import { AnlyticsPageLog } from '../../../global'

function PaymentPage(props) {
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [paymentsData, setPaymentsData] = useState([]);
    const [isDataFetch, setIsDataFetch] = useState(false)

    const fetchPaymentDetail = async () => {
        simplurisFetch({
            url: "auth/getpersonpaymentdetail"
        }).then((data) => {
            setShowPageLoader(false);
            if (data.name == undefined && data.name != "FetchError") {
                if (data.resultCode == "SUCCESS") {
                    setPaymentsData(data.resultObject);
                    setIsDataFetch(true)
                }
            }
            }).catch(e => { console.error('Error in get person payment detail', e, e.errorObject); setShowPageLoader(false); });       
    }

    useEffect(() => {
        setShowPageLoader(true);
        fetchPaymentDetail();
        AnlyticsPageLog("Payment Tab");
    }, []);

    const [totalCount, settotalCount] = useState(0);
    function renderShowsTotal(start, to, total) {
        settotalCount(total);
        return <></>;
    }

    const tableOptions = {
        toolBar: getToolbarWithTotal({ buttonText: `All ${totalCount} ` }),
        searchField: getSearchField({ defaultValue: '' }),
        paginationShowsTotal: renderShowsTotal 
    }

    const handleCellClick = (row) => {
        for (const prop in row) {
            if (prop == "id") {
                props.setPaymentDetailAttributeValues({ ledgerBaseID: row.ledgerBaseID, base: row.base });
                props.setCurrentSelectedTab('paymentdetail');
            }
        }
        return false;
    }

    function linkCellFormatter(cell, row) {
        return (
            <a className='SimplurisBlueColor SimplurisPointer' href={null} onClick={() => handleCellClick(row)}>{cell}</a>
        );
    }

    return (
        <>
            {(
                () => {
                    if (isDataFetch) {
                        return (
                            <div className="d-flex flex row">
                                <div className="col-md-12">
                                    <div className="card d-flex flex">
                                        <div className="col-md-12 my-3">
                                            <span className="text-grid-sub-td SimplurisFontWeight700 SimplurisFontSize12">Payment History</span>
                                        </div>
                                        <div className="col-md-12">

                                        </div>
                                        <div className="p-2">
                                            <BootstrapTable
                                                data={paymentsData}
                                                options={tableOptions}
                                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                                bodyContainerClass="text-grid-td"
                                                hover search>
                                                <TableHeaderColumn dataField="ledgerID" isKey dataSort dataFormat={linkCellFormatter} >Transaction ID</TableHeaderColumn>
                                                <TableHeaderColumn dataField="description" dataSort >Description</TableHeaderColumn>
                                                <TableHeaderColumn dataField="method" dataSort>Method</TableHeaderColumn>
                                                <TableHeaderColumn dataField="type" dataSort>Type</TableHeaderColumn>
                                                <TableHeaderColumn dataField="amount" dataSort>Amount</TableHeaderColumn>
                                                <TableHeaderColumn dataField="base" hidden>BASE</TableHeaderColumn>
                                                <TableHeaderColumn dataField="ledgerBaseID" hidden>LID</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        return <div className="row"> <SectionSpinner /></div>
                    }
                }
            )()}

        </>
    )
}

export default PaymentPage