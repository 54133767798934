import * as React from 'react'
import CountUp from 'react-countup'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styled from 'styled-components'

const StyledPopover = styled(Popover)`
	width: 300px;
`

const NumberCard = ({
	title,
	number,
	numberPrefix,
	percentChange,
	buttonText,
    buttonOnClick,
    buttonDisabled,
}) => {
	const hasChange = !!percentChange
	const isNegativeChange = hasChange && percentChange < 0

	const overlay = (
		<StyledPopover id="percentage-change-tooltip">
			<Popover.Content>
				{numberPrefix}
				{number.toLocaleString()} {title} is {Math.abs(percentChange)}%{' '}
				{isNegativeChange ? 'lower' : 'higher'} than the previous time period
			</Popover.Content>
		</StyledPopover>
	)

	const percentChangeArea = (
		<div className="d-flex mb-3">
			<div className="i-con-h-a">
				<div className="mt-1">
					<span
						className={`text-lg ${
							isNegativeChange ? 'text-deep-orange' : 'text-primary'
						}`}
					>
						{numberPrefix}
						<CountUp end={number} duration={0.75} separator="," />
					</span>
					{hasChange && (
						<>
							<i
								className={`i-con ${
									isNegativeChange ? 'i-con-arrow-down' : 'i-con-arrow-up'
								}`}
							>
								<i />
							</i>
							<small className="text-muted">{Math.abs(percentChange)}%</small>
						</>
					)}
				</div>
			</div>
		</div>
	)

	return (
		<div className="col-md-6">
			<div className="card p-4">
				<div className="d-flex mb-3">
					<span className="text-md text-dark">{title}</span>
					<span className="flex" />
				</div>
				{!!hasChange ? (
					<OverlayTrigger overlay={overlay}>{percentChangeArea}</OverlayTrigger>
				) : (
					percentChangeArea
				)}
				<div className="d-flex">
					<input
                        type="button"
                        className="btn bg-primary-lt btn-block SimplurisCaseManagerDetails SimplurisFontSize14"
                        value={buttonText}
                        onClick={buttonOnClick}
                        disabled={buttonDisabled}
					/>
				</div>
			</div>
		</div>
	)
}

export default NumberCard
