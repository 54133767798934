import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

function ChangeRequestMessageModalPopup(props) {

    function CloseChangeRequestMessageModalPopup() {
        props.setShowChangeRequestMessageModalPopup(false);        
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseChangeRequestMessageModalPopup} size="md">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title> <h5 className="modal-title SimplurisBidRequestTitle SimplurisFontWeight400">Change Request</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-10 col-xs-10 col-sm-12 ml-3 mt-2">
                            <p className="modal-title">
                                {props.message}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-10 col-md-6 col-xs-12 col-sm-12 ml-lg-3">
                            <button onClick={CloseChangeRequestMessageModalPopup} type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w btn-light py-3 mb-3 mt-3">
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChangeRequestMessageModalPopup