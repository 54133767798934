import React, { useState, useEffect, useRef } from 'react'
import DashboardHeader from '../../components/dashboard-header'
import ProfilePitcureUploadIcon from '../../../static/img/profilepitcureuploadicon.png'
import ChangeProfilePicture from './change-profile-picture-modal'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../global'
import GlobalImage from 'react/components/global-image-set';
import { SectionSpinner } from 'react/components/spinners'
import ProfileTab from './profile'
import BillingTab from './billing'
import PaymentTab from './payment'
import PaymentDetailTab from './payment-detail'
import { labFeatures } from '../../../api-config'

function AccountProfileAndSettingPage(props) {    
    const [personID, setPersonID] = useState(localStorage.getItem(global.PersonID) || '');
    const [email, setEmail] = useState(localStorage.getItem(global.Email) || '');
    let [fullName, setFullName] = useState('');
    let [firstName, setFirstName] = useState('');
    let [lastName, setLastName] = useState('');
    let [title, setTitle] = useState('');
    let [profilePicURL, setProfilePicURL] = useState(!IsNullOrEmptyString(localStorage.getItem(global.ProfilePicURL)) ? localStorage.getItem(global.ProfilePicURL) :'');
    const [selectedTab, setSelectedTab] = useState('profile');
    const [showChangeProfilePicutureRequestModalPopup, setShowChangeProfilePicutureRequestModalPopup] = useState(false);
    const [profilePictureValue, setProfilePictureValue] = useState('');
    const [paymentDetailAttributeValues, setPaymentDetailAttributeValues] = useState([]);
    const [updateProfilePicRandomState, setUpdateProfilePicRandomState] = useState(-1);

    const setCurrentSelectedTab = (tab) => {        
        setSelectedTab(tab);
    }

    const ChangeProfilePicRandomState = () => {
        setUpdateProfilePicRandomState(Math.random());
    }

    function OpenChangeProfilePicutreRequestModalPopup(data) {
        AnlyticsPageLog("Click on Change Profile Picture Modal Popup");
        setProfilePictureValue(data);
        setShowChangeProfilePicutureRequestModalPopup(true);        
    }

    return (
        <div>
            <DashboardHeader updateProfilePicRandomState={updateProfilePicRandomState} page="Profile"/>
            <div id="main" className="layout-row flex simplurisallcasesBodyBackgroudColour">
                <div id="aside" className="page-sidenav no-shrink  nav-expand   SimplurisCasePagesSideBarDiv" aria-hidden="true"></div>
                <div id="content" className="flex ">
                    <div className="page-container" id="page-container">
                        <div className="page-title padding pb-0 pt-0">
                            <div className="text-md mb-2 mt-2 SimplurisFontSize26 SimplurisMilestoneLables SimplurisFontWeight400">
                                Account Profile and Settings
                            </div>
                        </div>
                        <div className="padding pt-2">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                    <div className="card">
                                        <div className="b-t" id="feed-1">                                            
                                            <div className="row">
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <div className="d-flex p-4">
                                                        <div className="position-relative">                                                            
                                                                <a onClick={() => OpenChangeProfilePicutreRequestModalPopup(localStorage.getItem(global.ProfilePicURL))}>
                                                                    {(
                                                                        () => {
                                                                            //if (!IsNullOrEmptyString(profilePicURL)) {
                                                                            return <GlobalImage firstname={fullName} image={localStorage.getItem(global.ProfilePicURL)} email={email} dynamicClass="SimplurisAvtatarWH-95 avatar circle bg-info-lt" />
                                                                            // }
                                                                        }
                                                                    )()}

                                                                    <img src={ProfilePitcureUploadIcon} className="position-absolute SimplurisTeamMemberProfileChangedIcon" />
                                                                </a>                                                            
                                                        </div>
                                                        <div className="my-auto mx-4">
                                                            <div className="SimplurisFontSize19 font-weight-500 SimplurisSearchUniversalResult">{firstName} {lastName}</div>
                                                            <div className="text-muted SimplurisFontSize14 SimplurisFontWeight400">{!IsNullOrEmptyString(title) ? title : 'N/A'}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="m-0 mt-2 mt-md-0 mt-lg-0 mt-xl-0" />
                                            <div className="p-3">
                                                <ul className="nav" id="myTab" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active SimplurisFontSize12 SimplurisFontWeight400" onClick={() => setCurrentSelectedTab('profile')} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                                                    </li>
                                                    {labFeatures &&
                                                        <>
                                                        <li className="nav-item">
                                                            <a className="nav-link  SimplurisFontSize12 SimplurisFontWeight400" onClick={() => setCurrentSelectedTab('billing')} id="billing-tab" data-toggle="tab" href="#billing" role="tab" aria-controls="billing" aria-selected="false">Billing</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link  SimplurisFontSize12 SimplurisFontWeight400" onClick={() => setCurrentSelectedTab('payment')} id="payment-tab" data-toggle="tab" href="#payment" role="tab" aria-controls="payment" aria-selected="false">Payments</a>
                                                        </li>
                                                        </>
                                                        }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        {(
                                            () => {
                                                switch (selectedTab) {
                                                    case 'profile':
                                                        return <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                            <ProfileTab setFullName={setFullName} setFirstName={setFirstName} setLastName={setLastName} setTitle={setTitle} setProfilePicURL={setProfilePicURL}/>
                                                        </div>
                                                        break;
                                                    case 'billing':
                                                        return <div className="tab-pane fade show active" id="billing" role="tabpanel" aria-labelledby="billing-tab">
                                                            <BillingTab />
                                                        </div>
                                                    case 'payment':
                                                        return <div className="tab-pane fade show active" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                                                            <PaymentTab setCurrentSelectedTab={setCurrentSelectedTab} setPaymentDetailAttributeValues={setPaymentDetailAttributeValues}/>
                                                        </div>      
                                                    case 'paymentdetail':
                                                        return <div className="tab-pane fade show active" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                                                            <PaymentDetailTab paymentDetailAttributeValues={paymentDetailAttributeValues}/>
                                                        </div>
                                                    default:
                                                }
                                            }
                                        )()}                                    
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>                
                <ChangeProfilePicture OpenModalPopup={showChangeProfilePicutureRequestModalPopup} setShowChangeProfilePicutureRequestModalPopup={setShowChangeProfilePicutureRequestModalPopup} profilePictureValue={profilePictureValue} personID={personID} setProfilePicURL={setProfilePicURL} ChangeProfilePicRandomState={ChangeProfilePicRandomState}/>
            </div>
        </div>
    )
}

export default AccountProfileAndSettingPage