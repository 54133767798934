import * as React from 'react'
import styled from 'styled-components'

import HeaderContent from './content'

const Name = styled.div`
	display: flex;
	align-items: center;
`
const CircleIcon = styled.div`
	content: '';
	border-color: inherit;
	border-width: 2px;
	border-style: solid;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	transform: translateX(-50%);
	border-color: #137eff;
	box-shadow: 0 0 0px 4px rgba(19, 126, 255, 0.2);
	margin-right: 7px;
`

const CaseDetailHeader = ({ caseObject, caseID }) => (
	<>
		<div className="page-title padding pb-0 pt-0">
			<Name className="text-md mb-2 SimplurisFontSize26 SimplurisFontSize19 SimplurisFontSize14 SimplurisCaseBankTitle position-relative ml-1">
				{caseObject && (
					<>
						<CircleIcon />
						{caseObject.officialName}
					</>
				)}
			</Name>
		</div>

		<div className="page-title padding pb-0 pt-3">
			<div className="col-md-8 col-xs-12 col-sm-12 pl-0">
				<HeaderContent caseObject={caseObject} caseID={caseID} />
			</div>
		</div>
	</>
)

export default CaseDetailHeader
