import React, { useEffect, useState } from 'react'
import MyCasesView from './manage-cases'
import UsersView from './manage-users'
const UserManagement = ({
    userPermissions,
}) => {

    const [flagForUserManagement, setFlagForUserManagement] = useState('MyCases');

    const onTabChange = (tab) => {
        setFlagForUserManagement(tab);        
    }
    
    return (
        <div className="position-relative">
            <div className="row mb-4">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12 SimplurisFontSize16 SimplurisFontWeight500">
                    LiveCase Users
                    </div>
                <div className="SimplurisUserManagementScreenFilterButtonWrraperDiv SimplurisUserManagementWrapperDiv">                    
                    {
                        <>
                            
                            <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-0 mt-lg-0 mt-xl-0 ml-2 ml-md-2 ml-sm-0 ml-lg-0 ml-xl-0 mr-3 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                            ${flagForUserManagement == "MyCases" ? 'active' : ''}`}
                                id="btnByCase" onClick={() => onTabChange('MyCases')} 
                                aria-selected="false">By Case</button>
                            <button className={`float-left float-md-left float-sm-left  float-xl-right float-lg-right mt-2 mt-md-0 mt-lg-0 mt-xl-0 mr-2 mr-md-2 mr-sm-0 mr-lg-2 mr-xl-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                            ${flagForUserManagement == "Users" ? 'active' : ''}`}
                                id="btnByUser" onClick={() => onTabChange('Users')}
                                aria-selected="false">By User</button>
                        </>
                    }
                  
                </div>
                <div className="tab-content col-md-12">
                    {(
                        () => {
                            switch (flagForUserManagement) {
                                case 'Users':
                                            return <UsersView />        
                                    break;
                                case 'MyCases':
                                    return <MyCasesView/>
                                    break;                          
                                default:
                            }
                        }
                    )()}

                </div>
            </div>
            <div>                
            </div>
        </div>
    )
}


export default UserManagement
