import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    BootstrapTable,
    TableHeaderColumn,
    SearchField
} from "react-bootstrap-table";
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { SectionSpinner } from 'react/components/spinners'
import { simplurisFetch } from 'helpers'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { selectPermission } from 'state/case-detail-view/members/actions'
import GreyEmailImage from '../../../../../static/img/email_icon_grey.png'
import NumberCard from './campaign-number-card'

function CaseCampaigns({ userPermissions, selectPermission, selectCampaign, changeCaseSubtab, getCampaignDetailComponent, ...data }) {
    var viewCampaignGridPermission;
    var exportDataPermission;
    var gridSearchTextPermission;
    var sendEmailPermission;
    var sortGridByColumnPermission;
    var viewEmailAdrressPermission;
    var viewFirstNamePermission;
    var viewLastNamePermission;
    var viewPersonIdPermission;
    var viewSimIdPermission;
    var viewStatusPermission;
    var viewAwardStatusPermission;
    var drillDownDetailPermission;

    if (Object.keys(userPermissions).length > 0) {
        drillDownDetailPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_CAMPAIGN_DRILL_DOWN_TO_DETAIL'
        )
        viewCampaignGridPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_CAMPAIGN_GRID'
        )

        exportDataPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MEMBER_DOWNLOAD_EXPORT_DATA'
        )

        gridSearchTextPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_FILTER_GRID_BY_SEARCH_TEXT'
        )
        sendEmailPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_SEND_DATA_EMAIL'
        )

        sortGridByColumnPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_SORT_GRID_BY_COLUMN'
        )
        viewEmailAdrressPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_EMAIL_ADDRESS'
        )

        viewFirstNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_FIRST_NAME'
        )
        viewLastNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_LAST_NAME'
        )

        viewPersonIdPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_PERSONID'
        )
        viewSimIdPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_SIMID'
        )
        viewStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_STATUS'
        )
        viewAwardStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_SETTLEMENT_AWARD_STATUS'
        )
    }

    const [caseCampaignsData, setCaseCampaignsData] = useState([]);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const caseID = data.caseID;
    const getCaseMemberDetailComponent = data.getCaseMemberDetailComponent;

    const [isDataFetch, setIsDataFetch] = useState(false);
    const [campaignsCount, setCampaignsCount] = useState(0);
    const [deliveredCount, setDeliveredCount] = useState(0);
    const [bounceCount, setBounceCount] = useState(0);
    const [processedCount, setProcessedCount] = useState(0);
    const [unverifiedCount, setUnverifiedCount] = useState(0);
    const [totalDeliveryCount, setTotalDeliveryCount] = useState(0);
    const [totalBounceCount, setTotalBounceCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const fetchCaseCampaignsData = async (
        caseID
    ) => {
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/${parseInt(caseID)}/emailcampaign`
        })
            .then(result => {
                if (result.name !== undefined && result.name === "FetchError") {
                    setShowPageLoader(false);
                    setIsDataFetch(false);
                } else {
                    setCaseCampaignsData(result.resultObject.data);
                    setTotalCount(result.resultObject.data.length);
                    setShowPageLoader(false);
                    setIsDataFetch(true);
                    setCampaignsCount(result.resultObject != undefined && result.resultObject != null ? result.resultObject.data.length : 0);
                    setDeliveredCount(result.resultObject != undefined && result.resultObject != null ? result.resultObject.totalDeliveryCount : 0);
                    setBounceCount(result.resultObject != undefined && result.resultObject != null ? result.resultObject.totalBounceCount : 0);
                    setProcessedCount(result.resultObject != undefined && result.resultObject != null ? result.resultObject.totalProcessedCount : 0);
                    setUnverifiedCount(result.resultObject != undefined && result.resultObject != null ? result.resultObject.totalUnverifiedCount : 0);

                    setTotalDeliveryCount(result.resultObject.totalDeliveryCount + result.resultObject.totalProcessedCount);
                    setTotalBounceCount(result.resultObject.totalBounceCount + result.resultObject.totalUnverifiedCount);
                }
            })
            .catch(e => {
                console.error("Error in campaign", e, e.errorObject);
                setShowPageLoader(false);
                setIsDataFetch(false);
            });
    };

    useEffect(() => {
        fetchCaseCampaignsData(
            data.caseID
        );

    }, []);

    function setTableOption() {
        if (showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        }
        else {
            return "no data found!";
        }
    }

    const handleCellClick = (row) => {
        getCampaignDetailComponent(row.caseId, row.hash, row.mailingName);
    };

    function linkCellFormatter(cell, row) {
        if (drillDownDetailPermission && drillDownDetailPermission.length > 0) {
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}
                    onClick={() => handleCellClick(row)}
                >
                    {cell}
                </a>
            );
        } else {
            return <span>{cell}</span>;
        }
    }

    function renderShowsTotal(start, to, total) {
        setTotalCount(total);
        return <></>;
    }

    const RemotePagination = ({
        data
    }) => (
            <div>
                <BootstrapTable
                    data={data}
                    remote={false}
                    hover={true}
                    options={{
                        noDataText: setTableOption(),
                        paginationShowsTotal: renderShowsTotal 
                    }}
                    pagination
                    style={{ "margin-bottom": "0px" }}
                    tableContainerClass="table table-theme v-middle SimplurisCampaignsGrid table-hover SimplurisMembersGrid"
                    bodyContainerClass="text-grid-td"
                >
                    <TableHeaderColumn
                        dataField="hash"
                        hidden={true}
                        isKey
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                        width="70px"
                        dataField="caseId"
                        dataFormat={linkCellFormatter}>Case ID</TableHeaderColumn>
                    <TableHeaderColumn
                        width="100%"
                        columnTitle={true}
                        dataField="mailingName"
                        columnClassName=" simpluris-text-overflow">Campaign Name</TableHeaderColumn>
                    {/*<TableHeaderColumn
                        width="100px"
                        dataField="emailList">Email List</TableHeaderColumn>*/}
                    <TableHeaderColumn
                        width="100%"
                        dataField="subject"
                        columnTitle={true}
                        columnClassName="simpluris-text-overflow">Subject</TableHeaderColumn>
                    <TableHeaderColumn
                        width="120px"
                        dataField="template">Template</TableHeaderColumn>
                    <TableHeaderColumn
                        width="190px"
                        dataField="timeStampStart"
                        columnClassName="simplris-whitespace-column"
                    >Sent Date</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );

    if (showPageLoader) {
        return <div className="row"> <SectionSpinner /></div>
    }

    return (
        <>
            <div className="d-flex flex row">
                <div className="col-md-12 col-xs-12 col-sm-12 pb-3">
                    <div className="card d-flex flex mb-0">
                        <div className="card-body SimpulrisCardBody">
                            <table className="w-100 SimplurisCardHeaderTable">
                                <tr>
                                    <td>
                                        <span className="SimplurisMailingsEnvelopeSqure r avatar">
                                            <img src={GreyEmailImage} className="SimplurisMailingsEnvelopeImage" />
                                        </span>
                                    </td>
                                    <td>
                                        <div className="SimplurisMailingTitle">
                                            Email, Text & Phone Campaigns
										</div>
                                        <div className="SimplurisMailingSubTitle">
                                            {campaignsCount} campaigns is used for this case.
										</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <NumberCard
                    title="Total Emails Sent"
                    number={totalDeliveryCount}
                    popoverContent={
                        <div>
                            <b>Total Emails Sent</b>
                            <div>The total of delivered emails is {deliveredCount} <br />
                                The total of processed emails is {processedCount}.</div>
                        </div>
                    }
                />
                <NumberCard
                    title="Bounced Emails"
                    number={totalBounceCount}
                    popoverContent={
                        <div>
                            <b>Bounced Emails</b>
                            <div>The total of bounced emails is {bounceCount} <br />
                                The total of unverified emails is {unverifiedCount}.</div>
                        </div>
                    }
                />
            </div>
            <div className="card d-flex flex p-3">
                {/* <div className="row">
                    <div className="col-md-5 col-lg-5 col-xl-6 pt-4 mb-2">
                        <span className="SimplurisRecentDisbursementsChartTitle">
                            Campaigns
						</span>
                    </div>
                </div> */}
                {(() => {
                    if (isDataFetch) {
                        return (
                            <>
                                {
                                    viewCampaignGridPermission && viewCampaignGridPermission.length > 0 ?
                                    <RemotePagination
                                        data={caseCampaignsData} />
                                    : <></>
                                }
                            </>
                        )
                    } else {
                        return (
                            <div className="row">
                                <SectionSpinner />
                            </div>
                        )
                    }
                })()}
            </div>
        </>
    )
}

export default connect(
    (state, props) => ({
        previousCaseSubtabs: state.previousSubTab.subTabNames,
        userPermissions: getPermissionsForCase(state, props),
    }),
    {
        changeCaseSubtab,
        setPreviousCaseSubtab,
        selectPermission
        //selectCampaign,
    }
)(CaseCampaigns)
