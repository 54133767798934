export const numberToDollars = (number, withCents) =>    
    number
        ? number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: withCents ? 2 : 0,
            maximumFractionDigits: withCents ? 2 : 0,
        })
        : ''

