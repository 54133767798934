import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import { fetchCaseFilesData } from 'state/case-file/actions'
import { SectionSpinner } from 'react/components/spinners'
import UnlinkImage from '../../../../../static/img/unlink-img.png'
import LinkImage from '../../../../../static/img/link-img.png'
import DownloadImage from '../../../../../static/img/download-img.png'
import CaseFileBlueImage from '../../../../../static/img/securefile_icon_small.png'
import { CopyToClipboard } from "react-copy-to-clipboard"
import ExistingShareFileModalPopup from './existing-share-case-file'
import ShareableFileLinkModalPopup from './shareable-link-modal'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { simplurisFetch } from 'helpers'

function FilesList({ caseID, caseFilesData, getSecureFileDetailComponent, getSecureFileCount }) {
    
    getSecureFileCount(caseFilesData != undefined && caseFilesData != null ? caseFilesData.length : 0);
    useEffect(() => {

    }, []);


    if (!caseFilesData) {
        return <div className="row"> <SectionSpinner /></div>
    }

    if (caseFilesData.length <= 0) {
        return (
            <div className="d-flex flex row">
                <div className="col-md-12 mb-2 SimplurisCursorPointer">
                    <div className="card d-flex flex py-3">
                        <div className="col-md-12 text-center">
                            no data found!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex flex row">
            {
                caseFilesData.map((item, i) =>
                    <React.Fragment key={i}>
                        <CaseFileBox
                            items={item}
                            caseID={caseID}
                            getSecureFileDetailComponent={getSecureFileDetailComponent}
                        />
                    </React.Fragment>
                )
            }
        </div>
    )
}

export default connect(
    (state, props) => ({
        caseFilesData: state.caseFiles[props.caseID],
    }),
    {
        fetchCaseFilesData,
    }
)(FilesList)

function CaseFileBox({ items, caseID, getSecureFileDetailComponent }) {
    var fileSize;
    if (items.fileSize == 0) fileSize = '0 Bytes';
    var k = 1024,
        dm = 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(items.fileSize) / Math.log(k));
    fileSize = parseFloat((items.fileSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    const [showShareableLinkFileModel, setshowShareableLinkFileModel] = useState(false);
    const [showExistingShareFile, setShowExistingShareFile] = useState(false);
    const [isFetchCaseManager, setIsFetchCaseManager] = useState(false);
    const [popoverBarID, setPopoverBarID] = useState(-1);
    const ToggleBarPopover = (id) => {
        setPopoverBarID(id)
    }

    function OpenExistingShareFileModalPopup() {
        AnlyticsPageLog("Click on existing share file Modal Popup from File Upload");
        setIsFetchCaseManager(true);
        setShowExistingShareFile(true);
    }

    function OpenShareableLinkModalPopup() {        
        setIsFetchCaseManager(true);
        setshowShareableLinkFileModel(true);
    }

    const SaveSecureFileHistoryClick = () => {
        
        var formData = new FormData();
        formData.append('SecureFileID', items.secureFileID);
        formData.append('CaseID', caseID);
        formData.append('PersonMapID', localStorage.getItem(global.PersonMapID));
        formData.append('SentByPersonMapID', items.sentByPersonMapID);
        formData.append('AttachmentID', items.attachmentID);
        formData.append('VendorUniqueID', items.vendorUniqueID);

        simplurisFetch({
            url: `cases/${caseID}/savesecurefilehistory`,
            method: 'POST',
            body: formData,
            json: false,
        }).then((result) => {
            if (result.resultCode == "SUCCESS") {
            } else {
            }
        }).catch(e => {
        });

    }

    const GetFileDetails = (caseID, secureFileID, items) => {
        if (items.sentByPersonMapID == localStorage.getItem(global.PersonMapID)) {
            getSecureFileDetailComponent(caseID, secureFileID, items);
        }
    }

    if (items != null) {
        return (
            <>
                <div className="col-md-4 mb-2 SimplurisCursorPointer">
                    <div className="card d-flex flex py-3">
                        <div className="col-md-12">
                            <img src={CaseFileBlueImage} className="SimplurisReportTypeIcon" />
                            <a href="javascript:void(0)" className="text-secondary">
                                <div className="float-right">
                                    <div className="item-action dropdown simplris-detail-dropdown">
                                        <a href="#" data-toggle="dropdown" className="text-muted">
                                            <i className="i-con i-con-more-h w-32 "><i></i></i></a>
                                        <div className="dropdown-menu dropdown-menu-right bg-dark" role="menu">
                                            {(items.sentByPersonMapID == localStorage.getItem(global.PersonMapID)) &&
                                                <>
                                                    <a className="dropdown-item" onClick={() => OpenExistingShareFileModalPopup()}>
                                                        Re-share File
                                                    </a>
                                                    <a className="dropdown-item" onClick={() => getSecureFileDetailComponent(caseID, items.secureFileID, items)}>
                                                        File Details
                                                    </a>
                                                </>
                                            }
                                            <a onClick={() => OpenShareableLinkModalPopup()} className="dropdown-item">Get Shareable Link</a>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-12 mt-1" onClick={() => GetFileDetails(caseID, items.secureFileID, items)}>
                            <div className="SimplurisReportBody">
                                <div className="SimplurisReportTitle">{items.title}</div>
                                <div className="SimplurisReportSubTitle">{items.fileType}</div>
                                <p id={"file" + items.secureFileID}
                                    className="SimplurisReportContent SimplurisFontSize12"
                                    onMouseEnter={() => {
                                        ToggleBarPopover(items.secureFileID)
                                    }}
                                    onMouseLeave={() => {
                                        ToggleBarPopover(-1)
                                    }}>
                                    {items.description}
                                </p>
                                <Popover popperClassName="Simpluris-Popover Simpluris-Popover-file" innerClassName="Simpluris-Popover-inner" placement="top-end" isOpen={popoverBarID == items.secureFileID ? true : false} target={"file" + items.secureFileID} toggle={() => ToggleBarPopover(1)}>
                                    <PopoverBody className="Simpluris-PopoverBody">
                                        <div>{items.description}</div>
                                    </PopoverBody>
                                </Popover>
                            </div>
                        </div>
                        <div className="px-3" onClick={() => GetFileDetails(caseID, items.secureFileID, items)}>
                            <div className="row">
                                <div className="col">
                                    <div className="text-muted m-0 SimplurisReportDate">
                                        <a href={items.attachmentUrl} onClick={() => SaveSecureFileHistoryClick()} target="_blank" download>
                                            <img src={DownloadImage} />
                                        </a> {fileSize}
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="text-muted m-0 SimplurisReportDate float-right">
                                        {(items.sentByPersonMapID == localStorage.getItem(global.PersonMapID)) &&
                                            <a onClick={() => OpenExistingShareFileModalPopup()}>
                                                <img src={LinkImage} />
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ExistingShareFileModalPopup
                    OpenModalPopup={showExistingShareFile}
                    setShowExistingShareFile={setShowExistingShareFile}
                    caseID={caseID}
                    secureFileID={items.secureFileID}
                    random={Math.random()}
                    isFetchCaseManager={isFetchCaseManager}
                    setIsFetchCaseManager={setIsFetchCaseManager}
                />

                <ShareableFileLinkModalPopup
                    OpenModalPopup={showShareableLinkFileModel}
                    setShowExistingShareFile={setshowShareableLinkFileModel}
                    caseID={caseID}
                    secureFileID={items.secureFileID}
                    random={Math.random()}
                    isFetchCaseManager={isFetchCaseManager}
                    setIsFetchCaseManager={setIsFetchCaseManager}
                    />
            </>
        )
    }
}