export const SELECT_MEMBER = 'SELECT_MEMBER'
export const SELECT_MEMBER_DETAILS_PERMISSION = 'SELECT_MEMBER_DETAILS_PERMISSION'
export const SELECT_RESPONSES_DETAIL = 'SELECT_RESPONSES_DETAIL'
export const SELECT_PAYMENTS_DETAIL = 'SELECT_PAYMENTS_DETAIL'
export const SELECT_MAILING_HISTORY_DETAILS_FROM_FULLMAPSCREEN = 'SELECT_MAILING_HISTORY_DETAILS_FROM_FULLMAPSCREEN'

export const selectMember = selectedMemberID => ({
    type: SELECT_MEMBER,
    selectedMemberID,
})

export const selectPermission = ({ viewFirstNamePermission, viewLastNamePermission }) => async dispatch => {
    dispatch({
        type: SELECT_MEMBER_DETAILS_PERMISSION,
        membersPermission: {
            firstNamePermission: viewFirstNamePermission,
            lastNamePermission: viewLastNamePermission
        }
    })
}

export const selectResponsesDetail = responsesDetail => ({
    type: SELECT_RESPONSES_DETAIL,
    responsesDetail,
})

export const selectPaymentsDetail = paymentsDetail => ({
    type: SELECT_PAYMENTS_DETAIL,
    paymentsDetail,
})

export const selectMailingHistoryDetailFromFullScreenMap = mailingHistoryDetail => ({
    type: SELECT_MAILING_HISTORY_DETAILS_FROM_FULLMAPSCREEN,
    mailingHistoryDetail,
})