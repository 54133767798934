import React, { useState, useEffect } from 'react'
import { simplurisFetch } from 'helpers'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import DashboardHeader from 'react/components/dashboard-header'
import SectionSpinner from 'react/components/spinners/section-spinner'
import { labFeatures } from '../../../api-config'
import { AnlyticsPageLog, IsNullOrEmptyString } from '../../../global';
import SystemStausPage from './system-status'
import CallRelease from './call-release'
import UserManagement from './user-management'
import LogManagement from './log-management'
import AccountRequestsPage from './account-requests'
import { getPermissionsForAdmin } from '../../../state/admin-permission/selectors'
import { fetchPermissionForAdmin } from '../../../state/admin/account-requests/actions'


function AdminSectionPage({ adminPermissions, fetchPermissionForAdmin }) {
    var viewAPIStatusTab;
    var viewAccountRequestsTab;
    var viewAccessRequestsTab;
    var viewChangeRequestsTab;
    var viewCallReleaseTab;
    var viewUserTab;
    var viewLogTab;

    const [selectedTab, setSelectedTab] = useState('');
    const [isExpand, setIsExpand] = useState(false);
    const [isContract, setIsContract] = useState(true);
    const [pendingTotalCount, setPendingTotalCount] = useState(0);

    let permissionViseRenderTab;

    const getCountForPendingRequests = async () => {
        simplurisFetch({ url: "auth/fetchcountforpendingrequests" }).then((result) => {
            if (result.resultCode !== "ERROR") {
                setPendingTotalCount(result.resultObject);
            }
        })
            .catch(e => {
                console.error("Error in getCountForPendingRequests for admin", e, e.errorObject);
            });
    };

    useEffect(() => {
        getCountForPendingRequests();
        fetchPermissionForAdmin();
    }, []);


    if (adminPermissions.length > 0) {
        
        viewAPIStatusTab = adminPermissions.find(
            (permissionCode) => permissionCode === 'LC_ADMIN_API_STATUS'
        )

        viewAccountRequestsTab = adminPermissions.find(
            (permissionCode) => permissionCode === 'LC_ADMIN_ACCOUNT_REQUESTS'
        )

        viewAccessRequestsTab = adminPermissions.find(
            (permissionCode) => permissionCode === 'LC_ADMIN_ACCESS_REQUESTS'
        )

        viewChangeRequestsTab = adminPermissions.find(
            (permissionCode) => permissionCode === 'LC_ADMIN_CHANGE_REQUESTS'
        )

        viewCallReleaseTab = adminPermissions.find(
            (permissionCode) => permissionCode === 'LC_ADMIN_CALL_RELEASE'
        )

        viewUserTab = adminPermissions.find(
            (permissionCode) => permissionCode === 'LC_ADMIN_USERS'
        )

        viewLogTab = adminPermissions.find(
            (permissionCode) => permissionCode === 'LC_ADMIN_APP_LOG'
        )

        
        if (!IsNullOrEmptyString(viewAccessRequestsTab)) {
            permissionViseRenderTab = 'accessrequest';
        }
        if (!IsNullOrEmptyString(viewChangeRequestsTab)) {
            permissionViseRenderTab = 'changerequest';
        }
        if (!IsNullOrEmptyString(viewCallReleaseTab)) {
            permissionViseRenderTab = 'callrelease';
        }
        if (!IsNullOrEmptyString(viewAPIStatusTab)) {
            permissionViseRenderTab = 'systemstatus';
        }
        if (!IsNullOrEmptyString(viewAccountRequestsTab)) {
            permissionViseRenderTab = 'accountrequest';
        }
        if (!IsNullOrEmptyString(viewUserTab)) {
            permissionViseRenderTab = 'usermanagement';
        }
        if (!IsNullOrEmptyString(viewLogTab)) {
            permissionViseRenderTab = 'logmanagement';
        }
        
    }

    const setCurrentSelectedTab = tab => {
        setSelectedTab(tab)
    }

    const clickToContractScreen = () => {
        setIsContract(true);
        setIsExpand(false);
    }

    const clickToExpandScreen = () => {
        setIsContract(false);
        setIsExpand(true);
    }


    return (
        <div>
            <DashboardHeader page="dashboard" />
            <div
                id="main"
                className="layout-row flex simplurisallcasesBodyBackgroudColour"
            >
                <div id="content" className="flex ">
                    <div className={`${isExpand == true ? '' : 'page-container'}`} id="page-container">
                        <div className="padding pt-3 position-relative">
                            <div className="d-inline-block">
                                <ul className="nav" id="adminTab" role="tablist">
                                    {
                                        viewAPIStatusTab &&
                                        <li className="nav-item p-1 p-md-1 p-sm-0 p-xs-0 p-lg-0">
                                            <a className={`btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border ${permissionViseRenderTab == "systemstatus" ? 'active show' : ''}`}
                                                id="systemstatus-tab"
                                                data-toggle="tab"
                                                href="#systemstatus"
                                                onClick={() => setCurrentSelectedTab('systemstatus')}
                                                role="tab"
                                                aria-controls="systemstatus"
                                                aria-selected="true">System Status</a>
                                        </li>
                                    }
                                    {
                                        viewAccountRequestsTab &&
                                        <li className="nav-item ml-0 ml-sm-1 ml-md-0 ml-xl-1 ml-lg-1 p-1 p-md-1 p-sm-0 p-xs-0 p-lg-0">
                                            <a className={`btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border ${permissionViseRenderTab == "accountrequest" ? 'active show' : ''}`}
                                                id="accountrequest-tab"
                                                data-toggle="tab"
                                                href="#accountrequest"
                                                onClick={() => setCurrentSelectedTab('accountrequest')}
                                                role="tab"
                                                aria-controls="accountrequest"
                                                aria-selected="false">Account Requests   <span className="badge badge-danger">{pendingTotalCount}</span></a>
                                        </li>
                                    }
                                    {
                                        viewAccessRequestsTab &&
                                        <li className="nav-item ml-0 ml-sm-1 ml-md-0 ml-xl-1 ml-lg-1 p-1 p-md-1 p-sm-0 p-xs-0 p-lg-0">
                                            <a className={`btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border ${permissionViseRenderTab == "accessrequest" ? 'active show' : ''}`}
                                                id="accessrequest-tab"
                                                data-toggle="tab"
                                                href="#accessrequest"
                                                onClick={() => setCurrentSelectedTab('accessrequest')}
                                                role="tab"
                                                aria-controls="accessrequest"
                                                aria-selected="false">Access Requests</a>
                                        </li>
                                    }
                                    {
                                        viewChangeRequestsTab &&
                                        <li className="nav-item ml-0 ml-sm-1 ml-md-0 ml-xl-1 ml-lg-1 p-1 p-md-1 p-sm-0 p-xs-0 p-lg-0">
                                            <a className={`btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border ${permissionViseRenderTab == "changerequest" ? 'active show' : ''}`}
                                                id="changerequest-tab"
                                                data-toggle="tab"
                                                href="#changerequest"
                                                onClick={() => setCurrentSelectedTab('changerequest')}
                                                role="tab"
                                                aria-controls="changerequest"
                                                aria-selected="false">Change Requests</a>
                                        </li>
                                    }
                                    {
                                        viewCallReleaseTab &&
                                        <li className="nav-item ml-0 ml-sm-1 ml-md-0 ml-xl-1 ml-lg-1 p-1 p-md-1 p-sm-0 p-xs-0 p-lg-0">
                                            <a className={`btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border ${permissionViseRenderTab == "callrelease" ? 'active show' : ''}`}
                                                id="callrelease-tab"
                                                data-toggle="tab"
                                                href="#callrelease"
                                                onClick={() => setCurrentSelectedTab('callrelease')}
                                                role="tab"
                                                aria-controls="callrelease"
                                                aria-selected="false">Call Release</a>
                                        </li>
                                    }
                                    {
                                        viewUserTab &&
                                        <li className="nav-item ml-0 ml-sm-1 ml-md-0 ml-xl-1 ml-lg-1 p-1 p-md-1 p-sm-0 p-xs-0 p-lg-0">
                                            <a className={`btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border ${permissionViseRenderTab == "usermanagement" ? 'active show' : ''}`}
                                                id="usermanagement-tab"
                                                data-toggle="tab"
                                                href="#usermanagement"
                                                onClick={() => setCurrentSelectedTab('usermanagement')}
                                                role="tab"
                                                aria-controls="usermanagement"
                                                aria-selected="false">Users</a>
                                        </li>
                                    }
                                    {
                                        viewLogTab &&
                                        <li className="nav-item ml-0 ml-sm-1 ml-md-0 ml-xl-1 ml-lg-1 p-1 p-md-1 p-sm-0 p-xs-0 p-lg-0">
                                            <a className={`btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border ${permissionViseRenderTab == "logmanagement" ? 'active show' : ''}`}
                                                id="logmanagement-tab"
                                                data-toggle="tab"
                                                href="#logmanagement"
                                                onClick={() => setCurrentSelectedTab('logmanagement')}
                                                role="tab"
                                                aria-controls="logmanagement"
                                                aria-selected="false">Log</a>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="SimplurisContractExpandIconForAdminPanel">
                                <div className={`d-inline-block position-absolute SimplurisAdminPanelContractIcon ${isContract != true ? '' : 'hide'}`}>
                                    <i className="fa fa-expand" onClick={e => { clickToContractScreen() }} title="Contract" />
                                </div>
                                <div className={`d-inline-block position-absolute SimplurisAdminPanelContractIcon ${isExpand != true ? '' : 'hide'}`}>
                                    <a hrf={null} onClick={e => { clickToExpandScreen() }}><i className="fa fa-arrows-h" title="Expand" /></a>
                                </div>
                            </div>
                            <div className="tab-content mt-3">
                                <div className="card p-4">
                                    {(() => {
                                        if (!IsNullOrEmptyString(selectedTab) || !IsNullOrEmptyString(permissionViseRenderTab)) {
                                            switch (selectedTab || permissionViseRenderTab) {
                                                case 'systemstatus':
                                                    return (
                                                        <div className="tab-pane fade show active" id="systemstatus" role="tabpanel" aria-labelledby="systemstatus-tab">
                                                            <SystemStausPage />
                                                        </div>
                                                    )
                                                    break
                                                case 'accountrequest':
                                                    return (
                                                        <div className="tab-pane fade show active" id="accountrequest" role="tabpanel" aria-labelledby="accountrequest-tab">
                                                            <div className="d-flex flex row">
                                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                                    <AccountRequestsPage />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                case 'accessrequest':
                                                    return (
                                                        <div className="tab-pane fade show active" id="accessrequest" role="tabpanel" aria-labelledby="accessrequest-tab">
                                                            <div className="d-flex flex row">
                                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                case 'changerequest':
                                                    return (
                                                        <div className="tab-pane fade show active" id="changerequest" role="tabpanel" aria-labelledby="changerequest-tab">
                                                            <div className="d-flex flex row">
                                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                case 'callrelease':
                                                    return (
                                                        <div className="tab-pane fade show active" id="callrelease" role="tabpanel" aria-labelledby="callrelease-tab">
                                                            <div className="d-flex flex row">
                                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                                    <CallRelease />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                case 'usermanagement':
                                                    return (
                                                        <div className="tab-pane fade show active" id="usermanagement" role="tabpanel" aria-labelledby="usermanagement-tab">
                                                            <div className="d-flex flex row">
                                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                                    <UserManagement />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                case 'logmanagement':
                                                    return (
                                                        <div className="tab-pane fade show active" id="usermanagement" role="tabpanel" aria-labelledby="usermanagement-tab">
                                                            <div className="d-flex flex row">
                                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                                    <LogManagement />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                default:
                                            }
                                        }
                                        else {
                                            return <></>
                                        }
                                    })()}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default connect(
    (state, props) => ({
        adminPermissions: getPermissionsForAdmin(state, props),
    }),
    {
        fetchPermissionForAdmin
    }
)(AdminSectionPage)
