import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    BootstrapTable,
    TableHeaderColumn
} from "react-bootstrap-table";
import { SectionSpinner } from 'react/components/spinners'
import SecureFileImage from '../../../../../static/img/securefile_icon.png'
import DownloadImage from '../../../../../static/img/download-img.png'
import LinkBlueImage from '../../../../../static/img/link-blue-img.png'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import { simplurisFetch } from 'helpers'
import ExistingShareFileModalPopup from './existing-share-case-file'

const CaseFileDetails = ({ caseID, secureFileID, caseSecureFileData }) => {

    const [showExistingShareFile, setShowExistingShareFile] = useState(false);
    const [isFetchCaseManager, setIsFetchCaseManager] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [caseFileDetail, setCaseFileDetail] = useState([]);
    const [caseFileDetailForShareMember, setCaseFileDetailForShareMember] = useState([]);
    const [caseFileDescription, setCaseFileDescription] = useState(null);
    const [sharableNames, setSharableNames] = useState('');

    var fileSize;
    if (caseSecureFileData != undefined) {
        if (caseSecureFileData.fileSize == 0) fileSize = '0 Bytes';
        var k = 1024,
            dm = 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(caseSecureFileData.fileSize) / Math.log(k));
        fileSize = parseFloat((caseSecureFileData.fileSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

        localStorage.removeItem(global.SecureFileURL);
        localStorage.removeItem(global.CaseIDForSecureFile);
        localStorage.removeItem(global.SecureFileIDForSecureFile);
        localStorage.removeItem(global.SecureFileName);
        localStorage.removeItem(global.SecureFileForCaseOfficialName);
        localStorage.removeItem(global.SecureFileSentDate);
    }

    function OpenExistingShareFileModalPopup() {
        AnlyticsPageLog("Click on existing share file Modal Popup from File Upload");
        setShowExistingShareFile(true);
        if (caseFileDetailForShareMember.length > 0) {
            setIsFetchCaseManager(true);
        }
    }

    const fetchSecureFileDetail = async (caseID, secureFileID) => {
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/${parseInt(caseID)}/securefiledetail/${secureFileID}`
        })
            .then(result => {
                if (result.resultCode == "SUCCESS") {

                    setCaseFileDetail(result.resultObject.caseSecureFileHistory);
                    setCaseFileDetailForShareMember(result.resultObject.caseSecureFileShareMember);
                    setCaseFileDescription(result.resultObject.description)

                    setSharableNames(Array.prototype.map.call(result.resultObject.caseSecureFileShareMember, function (item) { return item.fullName; }).join(", "));

                    setShowPageLoader(false);
                }
            })
            .catch(e => {
                console.error("Error in case files ", e, e.errorObject);
                setShowPageLoader(false);
            });
    };

    var options = {
        noDataText: "No Data Found !"
    };

    const SaveSecureFileHistoryClick = () => {

        var formData = new FormData();
        formData.append('SecureFileID', secureFileID);
        formData.append('CaseID', caseID);
        formData.append('PersonMapID', localStorage.getItem(global.PersonMapID));
        formData.append('SentByPersonMapID', caseSecureFileData.sentByPersonMapID);
        formData.append('AttachmentID', caseSecureFileData.attachmentID);
        formData.append('VendorUniqueID', caseSecureFileData.vendorUniqueID);

        simplurisFetch({
            url: `cases/${caseID}/savesecurefilehistory`,
            method: 'POST',
            body: formData,
            json: false,
        }).then((result) => {
            if (result.resultCode == "SUCCESS") {
                fetchSecureFileDetail(caseID, secureFileID);
            } else {
            }
        }).catch(e => {
        });

    }

    useEffect(() => {
        fetchSecureFileDetail(caseID, secureFileID);
    }, [])


    return (
        <div>
            {showPageLoader ? <SectionSpinner /> :

                <>
                    <div className="d-flex flex row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="card d-flex flex mb-0">
                                <div className="card-body SimpulrisMailingCardBody">
                                    <div className="row">
                                        <div className="col-2 col-xl-1">
                                            <span className="SimplurisCaseFilesSquareWhite r avatar">
                                                <img src={SecureFileImage} className="SimplurisMailingsEnvelopeImage" />
                                            </span>
                                        </div>
                                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 align-self-center pl-xl-5 pl-lg-3 pl-md-3 SimplurisPaddingLeft23">
                                            <div className="SimplurisMailingTitle">
                                                {caseSecureFileData && caseSecureFileData.title}
                                            </div>
                                            <div className="SimplurisMailingSubTitle">
                                                {/*Uploaded by {caseSecureFileData && caseSecureFileData.fullName} on {new Date(caseSecureFileData && caseSecureFileData.sentDate).toLocaleDateString()}*/}
                                                {localStorage.getItem(global.caseOfficialName)}
                                            </div>

                                            {
                                                typeof (caseFileDetail) == "object" && Array.isArray(caseSecureFileData) == false ? <div className="SimplurisMailingSubTitle">
                                                    {
                                                        new Intl.DateTimeFormat('en-GB', {
                                                            month: 'long'
                                                        }).format(new Date(caseSecureFileData && caseSecureFileData.sentDate))} {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(caseSecureFileData && caseSecureFileData.sentDate))}, {new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(new Date(caseSecureFileData && caseSecureFileData.sentDate))}
                                                </div>
                                                    : <></>
                                            }

                                            {
                                                caseSecureFileData.sentByPersonMapID == localStorage.getItem(global.PersonMapID) &&
                                                <a className="nav-link SimplurisDownloadPDFReportButton btnRunReport btnDownloadReport btnDownloadFiles"
                                                    href={caseSecureFileData && caseSecureFileData.attachmentUrl}
                                                    onClick={() => SaveSecureFileHistoryClick()}
                                                    target="_blank"
                                                    download
                                                >Download</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {
                                caseFileDescription != null ? <div className="card d-flex flex mb-0 mt-4">
                                    <div className="card-body SimpulrisMailingCardBody">
                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-12 col-md-12">
                                                <div className="SimplurisMailingTitle">
                                                    Description
                                    </div>
                                                <div className="SimplurisMailingSubTitle">
                                                    {caseFileDescription}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    : <></>
                            }

                            

                            <div className="card d-flex flex mb-0 mt-4">
                                <div className="card-body SimpulrisMailingCardBody">
                                    <div className="row">
                                        <div className="col-12 col-lg-8 col-xl-9 col-md-8">
                                            <div className="SimplurisMailingTitle">
                                                Sharing to {caseFileDetail && caseFileDetailForShareMember.length} People
                                    </div>
                                            <div className="SimplurisMailingSubTitle">
                                                {sharableNames}
                                            </div>
                                            {
                                                typeof (caseFileDetail) == "object" && Array.isArray(caseSecureFileData) == false ?
                                                    <div className="SimplurisMailingSubTitle">
                                                        {new Intl.DateTimeFormat('en-GB', {
                                                            month: 'long'
                                                        }).format(new Date(caseSecureFileData && caseSecureFileData.sentDate))} {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(caseSecureFileData && caseSecureFileData.sentDate))}, {new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(new Date(caseSecureFileData && caseSecureFileData.sentDate))}
                                                    </div>
                                                    : <></>
                                            }
                                        </div>
                                        <div className="col-12 col-lg-4 col-xl-3 col-md-4 justify-content-center align-self-center text-left text-md-center">
                                            {
                                                caseSecureFileData.sentByPersonMapID == localStorage.getItem(global.PersonMapID) && <a className="nav-link  d-inline-block btnRunReport SimplurisTextLighPrimary"
                                                    onClick={() => OpenExistingShareFileModalPopup()} href={null}>Edit</a>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card d-flex flex mb-0 mt-4">
                                <div className="card-body SimpulrisMailingCardBody p-3">
                                    <div className="SimplurisMailingTitle">
                                        Share History
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="shareHistory" role="tabpanel" aria-labelledby="shareHistory-tab">
                            <div className="card SimplurisDashboardCard">
                                <div className="d-flex flex row">
                                    <div className="col-md-12">
                                        <div className="card d-flex flex p-3 mb-0">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    data={caseFileDetail}
                                                    options={options}
                                                    style={{ "margin-bottom": "0px" }}
                                                    tableContainerClass="table table-theme v-middle SimplurisCaseReportsGrid"
                                                    bodyContainerClass="text-grid-td"
                                                >
                                                    <TableHeaderColumn
                                                        isKey
                                                        hidden
                                                        dataField="fileHistoryID" />
                                                    <TableHeaderColumn
                                                        width="100"
                                                        dataField="actionName"
                                                    >
                                                        Action
                                            </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        width="200"
                                                        dataField="fullName"
                                                    >
                                                        User
                                            </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        width="100"
                                                        dataField="ipAddress"
                                                    >
                                                        IPAddress
                                            </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="sentDate"
                                                        dataFormat={date => new Date(date).toLocaleString('en-US')}
                                                        width="110"
                                                    >
                                                        Date
                                            </TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ExistingShareFileModalPopup
                        OpenModalPopup={showExistingShareFile}
                        setShowExistingShareFile={setShowExistingShareFile}
                        caseID={caseID}
                        secureFileID={secureFileID}
                        random={Math.random()}
                        isFetchCaseManager={isFetchCaseManager}
                        setIsFetchCaseManager={setIsFetchCaseManager}
                        isFromFileDetail={true}
                        fetchSecureFileDetail={fetchSecureFileDetail}
                    />
                </>}

        </div>
    )
}

export default CaseFileDetails
