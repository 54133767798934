import { CHANGE_DASHBOARD_CASE_FILTER } from './actions'
import { FETCH_DASHBOARD_CASE_ACTIVITY } from 'state/case-activity/actions'
import { FETCH_HOME_LOGGEDIN_CASE_ACTIVITY } from 'state/home-loggedin-case/actions'
import { FETCH_TEAM_MEMBER_DETAIL } from 'state/team-member-detail/actions'
import { CHANGE_CASE_ACTIVITY_FILTER } from 'state/dashboard-view/actions'
import { start, resolved } from 'state/helpers'

const initialState = {
    caseFilter: 0,
    fetchingActivity: false,
    fetchingHomeLoggedinCaseActivity: false,
    fetchingTeamMemberDetailFalge:false,
    filter: [],
}

export default (state = initialState, { type, caseFilter, filter }) => {
    switch (type) {
        case CHANGE_DASHBOARD_CASE_FILTER:
            return { ...state, caseFilter }
        case start(FETCH_DASHBOARD_CASE_ACTIVITY):            
            return { ...state, fetchingActivity: true }
        case resolved(FETCH_DASHBOARD_CASE_ACTIVITY):            
            return { ...state, fetchingActivity: false }
        case start(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY):
            
            return { ...state, fetchingHomeLoggedinCaseActivity: true }
        case resolved(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY):
            
            return { ...state, fetchingHomeLoggedinCaseActivity: false }
        case start(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY):
            
            return { ...state, fetchingTeamMemberDetailFalge: true }
        case resolved(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY):
            
            return { ...state, fetchingTeamMemberDetailFalge: false }
        case CHANGE_CASE_ACTIVITY_FILTER:
            let FinalResult;            
            if (filter == 'All Activity') {               
                FinalResult = [];
            }
            else {
                if (state.filter.length > 0 && state.filter.indexOf(filter) > -1) {                    
                    FinalResult = state.filter.filter(id => id != filter)
                }
                else {                    
                    FinalResult = [...state.filter, filter]
                }
            }
            return {
                ...state,
                filter: FinalResult
            }
        default:
            return state
    }
}
