import { simplurisFetch } from 'helpers'

export const FETCH_DISTRIBUTIONS_FOR_CASE = 'FETCH_DISTRIBUTIONS_FOR_CASE'

export const fetchDistributions = ({ caseID }) => async dispatch => {
  try {
      const url = `cases/${caseID}/distributions`
      
    const {
      resultObject,
      distributions,
      uncashedPayments,
      totalDisbursed,
    } = await simplurisFetch({ url })

      
    dispatch({
      type: FETCH_DISTRIBUTIONS_FOR_CASE,

      // When route is updated to return the normal object, uncomment the line
      // below and remove the other distributions value from this object as well
      // as remove it from the destructuring of the simplurisFetch response

      // distributions: resultObject,
      distributions,
      caseID,
      uncashedPayments,
      totalDisbursed,
    })
  } catch (e) {
    console.error('Error getting distributions', e, e.errorObject)

    dispatch({
      type: FETCH_DISTRIBUTIONS_FOR_CASE,
      distributions: [],
      caseID,
    })
  }
}
