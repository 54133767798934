import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import { fetchResponses } from 'state/responses/actions'
import {
    getResponsesForCase,
    getTotalRecordsForCase,
} from 'state/responses/selectors'
import ResponseSummary from '../shared/response-summary'
import { getDateRange } from 'state/responses/selectors'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import {
    selectMember,
    selectPermission,
    selectResponsesDetail,
    selectPaymentsDetail
} from 'state/case-detail-view/members/actions'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { SectionSpinner } from 'react/components/spinners'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'

const ResponseData = ({
    caseID,
    fetchResponses,
    responses,
    totalRecords,
    selectMember,
    changeCaseSubtab,
    userPermissions,
    previousCaseSubtabs,
    setPreviousCaseSubtab,
    selectPermission,
    selectResponsesDetail,
    selectPaymentsDetail,
    dateRange
}) => {        
    var responsesPermission
    var exportDataPermission
    var viewStatChartPermission
    var viewFirstNamePermission
    var viewLastNamePermission
    var viewResponseDatePermission
    var viewResponseStatusPermission
    var viewResponseTypePermission
    var viewResponseIdPermission
    var responsesTabPermission
    
    let filteredResponses = responses;
    
    if (Object.keys(userPermissions).length > 0) {  
        responsesTabPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_RESPONSE_FIELDS'
        )
        responsesPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEW_IN_MENU'
        )
        exportDataPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_DOWNLOAD_EXPORT_DATA'
        )

        viewStatChartPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_RESPONSES_VIEW_STATS_CHARTS'
        )

        viewFirstNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_FIRSTNAME'
        )        
        viewLastNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_LASTNAME'
        )

        viewResponseDatePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_RESPONSE_DATE'
        )
        viewResponseStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_RESPONSE_STATUS'
        )

        viewResponseTypePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_RESPONSE_TYPE'
        )
        viewResponseIdPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_RESPONSEID'
        )
    }    
    
    if (dateRange.start && dateRange.end) {        
        const startDate = new Date(dateRange.start)
        const endDate = new Date(dateRange.end)
        
        filteredResponses = responses ? responses.filter(({ date }) => {            
            const mailingDate = new Date(date)
            return mailingDate > startDate && mailingDate < endDate
        }) : [];

        console.log(filteredResponses);

    }
    
    useEffect(() => {
        try {
            
            fetchResponses({ caseID })
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [fetchResponses, caseID])

    const handleCellClick = ({ submitterPersonID }) => {                 
       if (submitterPersonID) {                                    
            selectMember(submitterPersonID)
            selectPermission({ viewFirstNamePermission, viewLastNamePermission });
           var responsesDetail = true;
           selectResponsesDetail(responsesDetail);
           var paymentsDetail = false;
           selectPaymentsDetail(paymentsDetail)
            changeCaseSubtab('member-detail')
            setPreviousCaseSubtab('responses')
        }
    };

    

    function linkCellFormatter(cell, row) {        
        if (responsesTabPermission && responsesTabPermission.length > 0) {
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}
                    onClick={() => handleCellClick(row)}>{cell}</a>
            )
        } else {
            return <span>{cell}</span>;
        }
    }

    const [totalCount, settotalCount] = useState(0);
    const [sortName, setSortName] = useState('lastName');
    const [sortOrder, setSortOrder] = useState('asc');
    function renderShowsTotal(start, to, total) {
        if (filteredResponses.length == total)
            settotalCount(total + 1);
        else
            settotalCount(total);
        return <></>;
    }

    const defaultSorted = [
        {
            dataField: "lastName",
            order: "asc"
        }
    ];

    const tableOptions = {        
        toolBar: getToolbarWithTotal({ buttonText: `All ${totalCount} ` }),
        searchField: getSearchField({ defaultValue: '' }),
        paginationShowsTotal: renderShowsTotal,
        sortName: sortName,
        sortOrder: sortOrder
    }

    if (!responses) {
        return <div className="row"> <SectionSpinner /></div>
    }

    if (responsesPermission && responsesPermission.length <= 0) {
        // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
        changeCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2])
        setPreviousCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2])
    }
    return (
        <div className="row">
            {viewStatChartPermission && viewStatChartPermission.length > 0 ? (
                <ResponseSummary
                    responses={filteredResponses}
                    caseID={caseID}
                    permission={
                        exportDataPermission && exportDataPermission.length > 0
                            ? userPermissions
                            : []
                    }
                />
            ) : (
                    <></>
                )}
            <div className="col-md-12">
                {(viewFirstNamePermission && viewFirstNamePermission.length > 0 || 
                  viewLastNamePermission && viewLastNamePermission.length > 0 || 
                  viewResponseDatePermission && viewResponseDatePermission.length > 0 || 
                  viewResponseStatusPermission && viewResponseStatusPermission.length > 0 || 
                  viewResponseTypePermission && viewResponseTypePermission.length > 0 || 
                  viewResponseIdPermission && viewResponseIdPermission.length > 0) ?
                    <BootstrapTable
                        data={filteredResponses}
                        options={tableOptions}
                        tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                        bodyContainerClass="text-grid-td"
                        hover
                        pagination
                        search
                        defaultSorted={defaultSorted}
                    >
                        <TableHeaderColumn
                            width="120px"
                            dataField="responseID" isKey
                            dataFormat={linkCellFormatter}
                            dataSort
                            hidden = {
                                        viewResponseIdPermission && viewResponseIdPermission.length > 0
                                        ? false
                                        : true
                                     }
                        >
                            ResponseID
                        </TableHeaderColumn>

                        <TableHeaderColumn 
                            width="90"
                            dataField="simid"  
                            dataSort
                        >
                            SIMID
                        </TableHeaderColumn>
                        {/* Hidden first and last names to allow them to be searchable */}
                        <TableHeaderColumn
                            width="120px"
                            dataField="firstName"
                            dataSort
                            hidden = {
                                        viewFirstNamePermission && viewFirstNamePermission.length > 0
                                        ? false
                                        : true
                                     }
                        >
                            First Name
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn
                            width="120px"
                            dataField="lastName"
                            dataSort 
                            hidden = {
                                        viewLastNamePermission && viewLastNamePermission.length > 0
                                        ? false
                                        : true
                                     }
                        >
                            Last Name
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            width="88"
                            dataField="date"
                            dataFormat={date => new Date(date).toLocaleDateString()}
                            dataSort
                            hidden = {
                                        viewResponseDatePermission && viewResponseDatePermission.length > 0
                                        ? false
                                        : true
                                     }
                        >
                            Date
                        </TableHeaderColumn>

                        <TableHeaderColumn 
                            width="100px"
                            dataField="responseType" 
                            dataSort 
                            hidden = {
                                        viewResponseTypePermission && viewResponseTypePermission.length > 0
                                        ? false
                                        : true
                                     }                                    
                        >
                        Type
                        </TableHeaderColumn>

                        {(viewFirstNamePermission && viewFirstNamePermission.length > 0) &&
                         (viewLastNamePermission && viewLastNamePermission.length > 0) ? (
                            <TableHeaderColumn
                                width="150"
                                dataField="firstName"
                                dataFormat={(firstName, { lastName }) => `${firstName} ${lastName}`}
                                dataSort 
                            >
                                Class Member
                            </TableHeaderColumn>
                            ) : (null)
                        } 
                        
                        <TableHeaderColumn 
                            width="90"                                   
                            dataField="statusTypeCode"
                            dataFormat={type => {
                                switch (type) {
                                    case 'V':
                                        return 'Valid'
                                    case 'I':
                                        return 'Invalid'
                                    case 'P':
                                        return 'Pending'
                                    default:
                                        return type
                                }
                            }}
                            dataSort
                            hidden = {
                                        viewResponseStatusPermission && viewResponseStatusPermission.length > 0
                                        ? false
                                        : true
                                     }                                      
                        >
                            Status
                        </TableHeaderColumn>
                    </BootstrapTable>
                    : viewStatChartPermission && viewStatChartPermission.length > 0 ? ( null) : 
                      ( <div>You do not have Permissions for this Page, Please Contact Administrator!!</div>)}
            </div>
        </div>
    )
}

export default connect(
    (state, props) => ({
        responses: getResponsesForCase(state, props),
        totalRecords: getTotalRecordsForCase(state, props),
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
        dateRange: getDateRange(state),
    }),
    {
        fetchResponses,
        selectMember,
        changeCaseSubtab,
        setPreviousCaseSubtab,
        selectPermission,
        selectResponsesDetail,
        selectPaymentsDetail,
    }
)(ResponseData)
