import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { getRecentlyViewedCases } from 'state/my-cases-view/selectors'

const Wrapper = styled.div`
  padding: 30px 20px;
`
const Title = styled.div`
  margin: 15px 0;
`
const LinkWrapper = styled.div`
  margin: 10px 0;
`
const StyledLink = styled(Link)`
  color: #98a5b0;
  font-size: 14px;
  font-weight: 400;
`

const RecentlyViewedCases = ({ recentlyViewedCases }) =>
  recentlyViewedCases.length ? (
    <Wrapper>
      <Title>Recently Viewed</Title>
      {recentlyViewedCases.map(({ name, ID }) => (
        <LinkWrapper key={ID}>
          <StyledLink to={`/cases/detail/${ID}`}>{_.truncate(name)}</StyledLink>
        </LinkWrapper>
      ))}
    </Wrapper>
  ) : null

export default connect((state, props) => ({
  recentlyViewedCases: getRecentlyViewedCases(state, props),
}))(RecentlyViewedCases)
