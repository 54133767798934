import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	background-color: red;
	padding: 0 20px;
	height: 30px;
	border-radius: 10px 10px 0 0;
	color: white;
	display: flex;
	align-items: center;
	font-weight: bold;
	justify-content: space-between;
`
const MailingStatus = styled.div`
	text-transform: uppercase;
font-size: 12px;
  font-weight: 700;
letter-spacing: 0.06666667px;
`

const MailingBackButton = styled.div`
	cursor: pointer;
font-size: 12px;
  font-weight: 700;
letter-spacing: 0.06666667px;
`

const TitleBar = ({ setShowMailingHistoryRightSideSection, status }) => {            
    function ShowRecipientsSection() {
        setShowMailingHistoryRightSideSection(false);
    }

    return <Container className="ZoomedMailTrackingMailingTitleContainer">
        <MailingBackButton onClick={() => ShowRecipientsSection()}><i class="fa fa-angle-left" aria-hidden="true"></i><i class="fa fa-angle-left" aria-hidden="true"></i> Back</MailingBackButton>
        {/*<MailingStatus>{status != undefined && status === 'Mailed'
            ? 'Delivered'
            : status}</MailingStatus>*/}
    </Container>
}

export default TitleBar
