import * as React from 'react'
import styled from 'styled-components'
import exportImage from '../../../../../../../../static/img/export.png'

const Container = styled.div`
  padding: 20px;
  background-color: rgba(255, 7, 0, 0.02);
  border-bottom: 1px solid rgba(255, 7, 0, 0.12);
`
const Location = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
`
const LocationChild = styled.div`
  flex: 1 1 0%;
`

const MailingRecipientNameSection = styled.div`
  font-size: 12px;
  font-weight: 500;
color: #647787;
`

const MailingTitle = styled.div`
font-size: 12px;
  font-weight: 400;
color: #647787;
`

const CityNameInfoLable = styled.div`
color: #647787;
font-size: 12px;
  font-weight: 700;
`

const CityStateName = styled.div`
color: #647787;  
  font-size: 14px;
  font-weight: 400;
`

const InfoSection = ({ selectedMailing, mailHistoryDetail, caseID }) => {    
    return (
        <Container>
            <div className="row">
                <div className="col-10">
                    <MailingRecipientNameSection>{mailHistoryDetail.recipientName}</MailingRecipientNameSection>
                    <MailingTitle>{mailHistoryDetail.mailingTitle}</MailingTitle>
                    <MailingTitle>Case #{caseID} - {localStorage.getItem(global.caseOfficialName)}</MailingTitle>
                </div>
                <div className="col-2 text-right d-none">
                    <img src={exportImage} />
                </div>
            </div>
            <Location>
                <LocationChild>
                    <CityNameInfoLable>From</CityNameInfoLable>
                    <CityStateName>{Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.from[0].city}, {Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.from[0].state}
                    </CityStateName>
                </LocationChild>
                <LocationChild>
                    <CityNameInfoLable>To</CityNameInfoLable>
                    <CityStateName>{Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.to[0].city}, {Object.keys(selectedMailing).length > 0 && selectedMailing.fromandtocitys.to[0].state}</CityStateName>
                </LocationChild>
            </Location>
        </Container>
    )
}

export default InfoSection
