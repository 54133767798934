import React from 'react'
import Brand from '../header/brand'
import Nav from './nav'
import StatusIconBar from '../header/status-icon-bar'

export default function DashboardHeader(items) {    
    return <header id="header" className="page-header simplurisallcasesHeaderBackgroudColour bg-white sticky">
        <div className="navbar navbar-expand-lg SimplurisNavbar pb-3">
            <a className={`d-lg-none i-con-h-a px-1  ${(items.page == "Profile" || items.page == "request-call" || items.page == "dashboard") ? 'd-none' : ''}`} data-toggle="modal" data-target="#aside">
                <i className="i-con i-con-menu text-muted"></i>
            </a>
            <Brand />

            <Nav IsStatusPage={items.IsStatusPage} />

            <StatusIconBar updateProfilePicRandomState={items.updateProfilePicRandomState}/>
        </div>
    </header>
}
