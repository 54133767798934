import { combineReducers } from 'redux'

import caseActivityReducer from 'state/case-activity/reducer'
import caseDetailViewReducer from 'state/case-detail-view/reducer'
import casesReducer from 'state/cases/reducer'
import permissionsReducer from 'state/permissions/reducer'
import dashboardViewReducer from 'state/dashboard-view/reducer'
import myCasesViewReducer from 'state/my-cases-view/reducer'
import liveReportsReducer from 'state/live-reports/reducer'
import mailingsReducer from 'state/mailings/reducer'
import responsesReducer from 'state/responses/reducer'
import distributionsReducer from 'state/distributions/reducer'
import signInReducer from 'state/sign-in/reducer'
import previousSubTabDetailsReducer from 'state/previous-tab/reducer'
import casesStageFilterCountReducer from 'state/case-stage-filter-count/reducer'
import { USER_LOGOUT } from 'state/sign-in/actions'
import messageThreadsReducer from 'state/message-threads/reducer'
import callCenterReducer from 'state/call-center/reducer'
import adminDataReducer from 'state/admin/reducer'
import adminPermissionsReducer from 'state/admin-permission/reducer'
import caseFileReducer from 'state/case-file/reducer'
import caseReportReducer from 'state/case-report/reducer'
import homeLoggedinCase from 'state/home-loggedin-case/reducer'
import teamMemberDetailScreen from 'state/team-member-detail/reducer'

const appReducer = combineReducers({
    caseActivity: caseActivityReducer,
    caseDetailView: caseDetailViewReducer,
    cases: casesReducer,
    casesStageFilterCount: casesStageFilterCountReducer,
    dashboardView: dashboardViewReducer,
    distributions: distributionsReducer,
    liveReports: liveReportsReducer,
    mailings: mailingsReducer,
    myCasesView: myCasesViewReducer,
    permissions: permissionsReducer,
    previousSubTab: previousSubTabDetailsReducer,
    responses: responsesReducer,
    signIn: signInReducer,
    threads: messageThreadsReducer,
    callCenter: callCenterReducer,
    adminData: adminDataReducer,
    adminPermissions: adminPermissionsReducer,
    caseFiles: caseFileReducer,
    caseReports: caseReportReducer,
    homeLoggedinCase: homeLoggedinCase,
    teamMemberDetailScreen: teamMemberDetailScreen
})

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        return appReducer(undefined, action) // Reset all of redux state on user logout
    }
    return appReducer(state, action)
}

export default rootReducer