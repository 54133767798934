import * as React from 'react'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  height: ${props => props.size || 60}px;
  width: ${props => props.size || 60}px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const InnerInfo = styled.div`
	position: absolute;
	text-align: center;
`
const Percentage = styled.div`
	font-size: 16px;
`
const Wording = styled.div`
	font-size: 12px;
`

const PercentageCircleChart = ({ percentage, color, size = 60, wording, ...other }) => {
	const data = {
		datasets: [
			{
				data: [percentage, 100 - percentage],
				backgroundColor: [color],
			},
		],
	}
	const options = {
    cutoutPercentage: 80,
    legend: { display: false },
		tooltips: { enabled: false },
    hover: { mode: null },
    responsive: true,
    maintainAspectRatio: false,
	}

	return (
		<Wrapper size={size}>
			<Doughnut data={data} options={options} {...other} />
			<InnerInfo>
				<Percentage>{percentage}%</Percentage>
				<Wording>{wording}</Wording>
			</InnerInfo>
		</Wrapper>
	)
}

export default PercentageCircleChart
