import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import ReactTimeAgo from "react-time-ago";

import {
    getSelectedMemberID,
    getMembersPermission,
    getResponsesDetail,
    getPaymentsDetail,
    getMailingHistoryDetailFromFullScreenMap
} from 'state/case-detail-view/members/selectors'
import {
    selectMailingHistoryDetailFromFullScreenMap
} from 'state/case-detail-view/members/actions'
import GlobalImage from 'react/components/global-image-set';
import { SectionSpinner } from 'react/components/spinners'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { simplurisFetch } from 'helpers'
import { getPermissionsForCase } from 'state/permissions/selectors'
import ResponsesDetailView from './responses-detail-view'
import PaymentDetailView from './payment-detail-view'
import CallsDetailView from './calls-detail-view'
import { appMode, labFeatures } from '../../../../../api-config'
import { IsNullOrEmptyString, FormatPhoneNumberForUS, FormatFaxNumberForUS } from '../../../../../global'
//import ResponsesTable from './responses-table'


function CaseMemberDetail({
    caseID,
    personID,
    selectedMemberID,
    userPermissions,
    changeCaseSubtab,
    previousCaseSubtabs,
    setPreviousCaseSubtab,
    membersPermission,
    responsesDetail,
    paymentsDetail,
    mailingHistoryDetail,
    selectMailingHistoryDetailFromFullScreenMap
}) {

    const [caseMemberDetail, setCaseMemberDetail] = useState({});
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [tablePage, setTablePage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(0);
    const [isBackFromResponseScreen, setIsBackFromResponseScreen] = useState(false);

    var memberPermission;
    var activePhysicAddressPermission;
    var viewAwardStatusPermission;
    var cellPhonePermission;
    var homePhonePermission;
    var faxPermission;
    var viewEmailAdrressPermission;
    var paymentsTabPermission;
    var responsesTabPermission;
    if (Object.keys(userPermissions).length > 0) {
        paymentsTabPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_BANKINGDISTRIBUTION_VIEWDATA_PAYMENT_VISUAL'
        )
        responsesTabPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_VIEWDATA_RESPONSE_FIELDS'
        )
        memberPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEW_IN_MENU'
        )
        activePhysicAddressPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_DEFAULT_ACTIVE_PHYSICAL_ADDRESS'
        )
        viewAwardStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_SETTLEMENT_AWARD_STATUS'
        )
        cellPhonePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_CELL_PHONE'
        )
        homePhonePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_HOME_PHONE'
        )
        faxPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_FAX'
        )
        viewEmailAdrressPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_EMAIL_ADDRESS'
        )

    }

    const fetchcaseMemberDetail = async (caseID, selectedMemberID, page, sizePerPage) => {
        simplurisFetch({
            url: `cases/${parseInt(caseID)}/casemembers/${selectedMemberID}/${parseInt(
                page
            )}/${parseInt(sizePerPage)}`
        })
            .then(result => {
                setShowPageLoader(false);
                if (result.name != undefined && result.name == "FetchError") {
                } else {

                    setCaseMemberDetail(result.resultObject);
                    setTablePage(page);
                    if (result.resultObject.personMailings.length > 0)
                        setTotalSize(result.resultObject.personMailings[0].totalRecord);
                }
            })
            .catch(e => {
                console.error("Error in case members", e, e.errorObject);
                setShowPageLoader(false);
            });
    };

    useEffect(() => {
        setShowPageLoader(true);

        fetchcaseMemberDetail(caseID, selectedMemberID, tablePage, sizePerPage);

    }, [selectedMemberID]);

    function trClassFormat() {
        return "tr-cursor";
    }

    function columnClassNameFormat() {
        return "td-column";
    }

    var options = {
        noDataText: showPageLoader == true ? <div className="row"> <SectionSpinner /></div> : "No Data Found !"
    };

    function onPageChange(page, sizePerPage) {
        setTablePage(page);
        setCaseMemberDetail([]);
        setShowPageLoader(true);
        fetchcaseMemberDetail(caseID, selectedMemberID, page, sizePerPage);
    }

    function onSizePerPageList(sizePerPage) {
        setSizePerPage(sizePerPage);
        setCaseMemberDetail([]);
        setShowPageLoader(true);
        fetchcaseMemberDetail(caseID, selectedMemberID, 1, sizePerPage);
    }

    function isExpandableRow(row) {
        return true;
    }

    function expandComponent(row) {
        return <MemberMailTrackingTable data={row.documentTrackingItems} />;
    }

    const RemotePagination = ({
        data,
        page,
        sizePerPage,
        totalSize,
        onPageChange,
        onSizePerPageList
    }) => (
            <div>
                <BootstrapTable
                    version="4"
                    expandableRow={isExpandableRow}
                    expandComponent={expandComponent}
                    data={data}
                    remote={true}
                    hover={true}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: totalSize }}
                    options={{
                        sizePerPage: sizePerPage,
                        onPageChange: onPageChange,
                        sizePerPageList: [10, 20, 35],
                        page: page,
                        onSizePerPageList: onSizePerPageList,
                        expandRowBgColor: "rgb(255, 255, 255)"
                    }}
                    trClassName={trClassFormat}
                    tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover expand-table"
                    bodyContainerClass="text-grid-td"
                >
                    <TableHeaderColumn dataField="mailingID" isKey hidden>
                        MailingID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="mailingTitle"
                        columnClassName={columnClassNameFormat}
                    >   
                        Mail Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="mailingDescription"
                        columnClassName={columnClassNameFormat}
                    >
                        Description
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="mailStatus"
                        columnClassName={columnClassNameFormat}
                    >
                        Mail Status
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="mailType"
                        columnClassName={columnClassNameFormat}
                    >
                        Mail Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="dateSent"
                        dataFormat={dateSent => new Date(dateSent).toLocaleDateString()}
                        columnClassName={columnClassNameFormat}
                    >
                        Date Sent
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );

    const [selectedTab, setSelectedTab] = useState(mailingHistoryDetail == true ? 'mailings' : 'contactinfo')
    const setCurrentSelectedTab = tab => {
        setSelectedTab(tab)
    }

    if (memberPermission && memberPermission.length <= 0) {
        // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
        changeCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
        setPreviousCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
    }


    if ((!responsesDetail && !paymentsDetail) || isBackFromResponseScreen) {

        if (selectedMemberID) {

            let fullName;
            if (appMode == "sandbox") {
                fullName = caseMemberDetail.firstName + " " + caseMemberDetail.lastName;
            }
            else {
                fullName = (membersPermission && membersPermission.firstNamePermission && membersPermission.firstNamePermission.length > 0 ? caseMemberDetail.firstName : '') + " " + (membersPermission && membersPermission.lastNamePermission && membersPermission.lastNamePermission.length > 0 ?
                    caseMemberDetail.lastName
                    : '');
            }

            return (
                <div>
                    <div className="card">
                        <div className="b-t" id="feed-1">
                            {showPageLoader ? <SectionSpinner height={490} /> : null}

                            <div className="row align-items-center">
                                <div className="col-md-7 col-xs-12 col-sm-12">
                                    <div className="d-flex p-4">
                                        <a href="#">
                                            {/* <img
                      src={caseMemberDetail.profilePicURL}
                      className="avatar SimplurisCaseTeamDetailAvatar"
                      width="95px"
                                    />*/}
                                            <GlobalImage
                                                isSandbox={appMode == "sandbox" ? "true" : "false"}
                                                firstname={fullName}
                                                image={caseMemberDetail.profilePicURL}
                                                email={caseMemberDetail.personEmail}
                                                isTeam="true"
                                                dynamicClass="w-90 avatar SimplurisCaseTeamDetailAvatar circle Simpluris-bg-gray-lt"
                                            />
                                        </a>
                                        <div className="my-auto mx-4">
                                            <div className="SimplurisFontSize19 font-weight-500 SimplurisSearchUniversalResult">
                                                {
                                                    appMode == "sandbox" ? caseMemberDetail.firstName + " " :
                                                        (membersPermission && membersPermission.firstNamePermission && membersPermission.firstNamePermission.length > 0 ?
                                                            caseMemberDetail.firstName + " "
                                                            : null)
                                                }
                                                {
                                                    appMode == "sandbox" ? caseMemberDetail.lastName :
                                                        (membersPermission && membersPermission.lastNamePermission && membersPermission.lastNamePermission.length > 0 ?
                                                            caseMemberDetail.lastName
                                                            : null)
                                                }
                                            </div>
                                            <div className="text-muted SimplurisFontSize14 SimplurisFontWeight400">
                                                {caseMemberDetail.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-xs-12 col-sm-12">
                                    {viewAwardStatusPermission &&
                                        viewAwardStatusPermission.length > 0 ? (
                                            <div className="d-flex h-100">
                                                <div className="w-100 text-xl-right">
                                                    <button className="btn w-sm Simluris-bg-orange-lt mr-0 mr-xl-5 ml-4 ml-xl-0 mb-2 mb-sm-0 mb-xl-0 mb-lg-0 mb-md-0">
                                                        Opted Out
                      </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                </div>
                            </div>
                            <hr className="m-0" />
                            <div className="p-3">
                                <ul className="nav" id="myTab" role="tablist">
                                    {labFeatures && <li className="nav-item">
                                        <a
                                            className={`nav-link SimplurisFontSize14 SimplurisFontWeight400 ${selectedTab == "activity" ? "active" : ""}`}
                                            id="activity-tab"
                                            data-toggle="tab"
                                            href="#activity"
                                            onClick={() => setCurrentSelectedTab('activity')}
                                            role="tab"
                                            aria-controls="activity"
                                            aria-selected="true"
                                        >
                                            Activity
                  </a>
                                    </li>}
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link SimplurisFontSize14 SimplurisFontWeight400 ${selectedTab == "contactinfo" ? "active" : ""}`}
                                            id="contactinfo-tab"
                                            data-toggle="tab"
                                            onClick={() => setCurrentSelectedTab('contactinfo')}
                                            href="#contactinfo"
                                            role="tab"
                                            aria-controls="contactinfo"
                                            aria-selected="false"
                                        >
                                            Contact Info
                  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link SimplurisFontSize14 SimplurisFontWeight400 ${selectedTab == "casespecific" ? "active" : ""}`}
                                            id="casespecific-tab"
                                            onClick={() => setCurrentSelectedTab('casespecific')}
                                            data-toggle="tab"
                                            href="#casespecific"
                                            role="tab"
                                            aria-controls="casespecific"
                                            aria-selected="false"
                                        >
                                            Case Specific
                  </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link SimplurisFontSize14 SimplurisFontWeight400 ${selectedTab == "mailings" ? "active" : ""}`}
                                            id="mailings-tab"
                                            data-toggle="tab"
                                            onClick={() => setCurrentSelectedTab('mailings')}
                                            href="#mailings"
                                            role="tab"
                                            aria-controls="mailings"
                                            aria-selected="false"
                                        >
                                            Mailings
                  </a>
                                    </li>
                                    {responsesTabPermission && responsesTabPermission.length > 0 ?
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link SimplurisFontSize14 SimplurisFontWeight400 ${selectedTab == "responses" ? "active" : ""}`}
                                                id="responses-tab"
                                                data-toggle="tab"
                                                href="#responses"
                                                onClick={() => setCurrentSelectedTab('responses')}
                                                role="tab"
                                                aria-controls="responses"
                                                aria-selected="false"
                                            >
                                                Responses
                                            </a>
                                        </li> : <></>
                                    }
                                    {paymentsTabPermission && paymentsTabPermission.length > 0 ?
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link SimplurisFontSize14 SimplurisFontWeight400 ${selectedTab == "payments" ? "active" : ""}`}
                                                id="payments-tab"
                                                data-toggle="tab"
                                                onClick={() => setCurrentSelectedTab('payments')}
                                                href="#payments"
                                                role="tab"
                                                aria-controls="payments"
                                                aria-selected="false"
                                            >
                                                Payments
                                            </a>
                                        </li> : <></>
                                    }
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link SimplurisFontSize14 SimplurisFontWeight400 ${selectedTab == "calls" ? "active" : ""}`}
                                            id="calls-tab"
                                            data-toggle="tab"
                                            onClick={() => setCurrentSelectedTab('calls')}
                                            href="#calls"
                                            role="tab"
                                            aria-controls="calls"
                                            aria-selected="false"
                                        >
                                            Calls
                  </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="tab-content">
                        {(() => {
                            switch (selectedTab) {
                                case 'activity':
                                    return (
                                        <div
                                            className="tab-pane fade show active"
                                            id="activity"
                                            role="tabpanel"
                                            aria-labelledby="activity-tab"
                                        >
                                            <div className="card SimplurisDashboardCard SimplurisDashbordSecondaryCard">
                                                <div className="p-4">
                                                    <div className="SimplurisFontSize10 SimplurisFontWeight700 SimplurisDashbordSecondaryHeaderText SimplurisOpacity03">
                                                        4:34PM PST - June 20 2019
                </div>
                                                    <div className="SimplurisFontSize16 SimplurisFontWeight400 SimplurisDashbordSecondaryHeaderText">
                                                        Opt-Out
                </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    <div className="col-md-12 col-xs-12 col-sm-12 p-4 bg-white SimplurisBorderRadius9">
                                                        <div className="d-flex mt-1">
                                                            <span className="SimplurisActatarW-34 avatar circle Simpluris-bg-gray-lt btn btn-icon btn-rounded d-none">
                                                                FL
                    </span>
                                                            <div className="mx-3 flex">
                                                                <span className="SimplurisDashboradActivitySpan">
                                                                    Opted out of the class on Friday June 3rd at 12:30PM
                      </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card SimplurisDashboardCard SimplurisDashbordSecondaryCard">
                                                <div className="p-4">
                                                    <div className="SimplurisFontSize10 SimplurisFontWeight700 SimplurisDashbordSecondaryHeaderText SimplurisOpacity03">
                                                        4:34PM PST - June 20 2019
                </div>
                                                    <div className="SimplurisFontSize16 SimplurisFontWeight400 SimplurisDashbordSecondaryHeaderText">
                                                        Mail dispatched
                </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    <div className="col-md-12 col-xs-12 col-sm-12 p-4 bg-white SimplurisBorderRadius9">
                                                        <div className="d-flex mt-1">
                                                            <span className="SimplurisActatarW-34 avatar circle Simpluris-bg-gray-lt btn btn-icon btn-rounded  d-none">
                                                                FL
                    </span>
                                                            <div className="mx-3 flex">
                                                                <span className="SimplurisDashboradActivitySpan">
                                                                    Sent notice via mail on June 1st at 1:23am.
                      </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card SimplurisDashboardCard SimplurisDashbordSecondaryCard">
                                                <div className="p-4">
                                                    <div className="SimplurisFontSize10 SimplurisFontWeight700 SimplurisDashbordSecondaryHeaderText SimplurisOpacity03">
                                                        4:34PM PST - June 20 2019
                </div>
                                                    <div className="SimplurisFontSize16 SimplurisFontWeight400 SimplurisDashbordSecondaryHeaderText">
                                                        Class Member
                </div>
                                                </div>
                                                <div className="card-body p-0">
                                                    <div className="col-md-12 col-xs-12 col-sm-12 p-4 bg-white SimplurisBorderRadius9">
                                                        <div className="d-flex mt-1">
                                                            <span className="SimplurisActatarW-34 avatar circle Simpluris-bg-gray-lt btn btn-icon btn-rounded d-none">
                                                                FL
                    </span>
                                                            <div className="mx-3 flex">
                                                                <span className="SimplurisDashboradActivitySpan">
                                                                    Added the class member list on May 20th at 12:12pm.
                      </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    break
                                case 'contactinfo':
                                    return (
                                        <div
                                            className="tab-pane fade active show"
                                            id="contactinfo"
                                            role="tabpanel"
                                            aria-labelledby="contactinfo-tab"
                                        >
                                            <div className="card SimplurisDashboardCard">
                                                <div>
                                                    <div className="p-1">
                                                        <div className="px-4 py-4">
                                                            <div className="row mb-2">
                                                                {cellPhonePermission && cellPhonePermission.length > 0 ? (
                                                                    <div className="col-6">
                                                                        <small className="text-muted SimplurisFontWeight500">
                                                                            Cell Phone
                          </small>
                                                                        <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                                            {caseMemberDetail.phone != null &&
                                                                                caseMemberDetail.phone != "" && caseMemberDetail.phone != undefined
                                                                                ? FormatPhoneNumberForUS(caseMemberDetail.phone)
                                                                                : "N/A"}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                        <></>
                                                                    )}
                                                                {faxPermission && faxPermission.length > 0 ? (
                                                                    <div className="col-6">
                                                                        <small className="text-muted SimplurisFontWeight500">
                                                                            Person Fax
                          </small>
                                                                        <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                                            {caseMemberDetail.personFax != null &&
                                                                                caseMemberDetail.personFax != "" && caseMemberDetail.personFax != undefined
                                                                                ? FormatFaxNumberForUS(caseMemberDetail.personFax)
                                                                                : "N/A"}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                        <></>
                                                                    )}
                                                            </div>
                                                            <div className="row mb-2">
                                                                {viewEmailAdrressPermission && viewEmailAdrressPermission.length > 0 ? (
                                                                    <div className="col-6">
                                                                        <small className="text-muted SimplurisFontWeight500">
                                                                            Email
                        </small>
                                                                        <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                                            {caseMemberDetail.email != null &&
                                                                                caseMemberDetail.email != "" && caseMemberDetail.email != undefined
                                                                                ? caseMemberDetail.email
                                                                                : "N/A"}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                        <></>
                                                                    )}
                                                                {/*<div className="col-6">
                                                                    <small className="text-muted SimplurisFontWeight500">
                                                                        Second Email
                        </small>
                                                                    <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                                        {caseMemberDetail.personEmail2 != null &&
                                                                            caseMemberDetail.personEmail2 != "" && caseMemberDetail.personEmail2 != undefined
                                                                            ? caseMemberDetail.personEmail2
                                                                            : "N/A"}
                                                                    </div>
                                                                </div>*/}
                                                            </div>
                                                            <div className="row mb-2">
                                                                {(() => {
                                                                    if (
                                                                        activePhysicAddressPermission &&
                                                                        activePhysicAddressPermission.length > 0
                                                                    ) {
                                                                        return (
                                                                            <>
                                                                                <div className="col-12 mt-2 mb-2">
                                                                                    <small className="text-muted SimplurisFontWeight500">
                                                                                        Address Detail
                                </small>
                                                                                </div>
                                                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                                                    <BootstrapTable
                                                                                        version="4"
                                                                                        data={caseMemberDetail.addresses}
                                                                                        trClassName={trClassFormat}
                                                                                        hover
                                                                                        options={options}
                                                                                        tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                                                                        bodyContainerClass="text-grid-td"
                                                                                    >
                                                                                        <TableHeaderColumn
                                                                                            dataField="personID"
                                                                                            isKey
                                                                                            hidden
                                                                                        >
                                                                                            Person ID
                                        </TableHeaderColumn>
                                                                                        <TableHeaderColumn
                                                                                            dataField="address1"
                                                                                            columnClassName={columnClassNameFormat}
                                                                                        >
                                                                                            Address 1
                                        </TableHeaderColumn>
                                                                                        <TableHeaderColumn
                                                                                            dataField="address2"
                                                                                            columnClassName={columnClassNameFormat}
                                                                                            hidden
                                                                                        >
                                                                                            Address 2
                                        </TableHeaderColumn>
                                                                                        <TableHeaderColumn
                                                                                            dataField="address3"
                                                                                            columnClassName={columnClassNameFormat}
                                                                                            hidden
                                                                                        >
                                                                                            Address 3
                                        </TableHeaderColumn>
                                                                                        <TableHeaderColumn
                                                                                            dataField="city"
                                                                                            columnClassName={columnClassNameFormat}
                                                                                        >
                                                                                            City{" "}
                                                                                        </TableHeaderColumn>
                                                                                        <TableHeaderColumn
                                                                                            dataField="state"
                                                                                            columnClassName={columnClassNameFormat}
                                                                                        >
                                                                                            state{" "}
                                                                                        </TableHeaderColumn>
                                                                                        <TableHeaderColumn
                                                                                            dataField="country"
                                                                                            columnClassName={columnClassNameFormat}
                                                                                        >
                                                                                            {" "}
                                                                                            Country
                                        </TableHeaderColumn>
                                                                                        <TableHeaderColumn
                                                                                            dataField="zip"
                                                                                            columnClassName={columnClassNameFormat}
                                                                                        >
                                                                                            {" "}
                                                                                            zip
                                        </TableHeaderColumn>
                                                                                    </BootstrapTable>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        return <></>;
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                case 'casespecific':
                                    return (
                                        <div
                                            className="tab-pane fade active show"
                                            id="casespecific"
                                            role="tabpanel"
                                            aria-labelledby="casespecific-tab"
                                        >
                                            <div className="card SimplurisDashboardCard">
                                                <div className="p-1">
                                                    <div className="px-4 py-4">
                                                        {(() => {
                                                            if (appMode == "sandbox") {
                                                                return <>
                                                                    <div class="row mb-2">
                                                                        <div class="text-muted SimplurisFontWeight500">Job Title: </div>
                                                                        <div class="ml-2 SimplurisFontWeight500 SimplurisFontSize15" style={{ "lineHeight": "1.30" }}>
                                                                            General Manager
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <div class="text-muted SimplurisFontWeight500">Location Worked: </div>
                                                                        <div class="ml-2 SimplurisFontWeight500 SimplurisFontSize15" style={{ "lineHeight": "1.30" }}>
                                                                            California
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <div class="text-muted SimplurisFontWeight500">Dates of Employment: </div>
                                                                        <div class="ml-2 SimplurisFontWeight500 SimplurisFontSize15" style={{ "lineHeight": "1.30" }}>
                                                                            3/1/2019 - 4/1/2020
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <div class="text-muted SimplurisFontWeight500">Work Weeks: </div>
                                                                        <div class="ml-2 SimplurisFontWeight500 SimplurisFontSize15" style={{ "lineHeight": "1.30" }}>
                                                                            56
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            } else {
                                                                return <>
                                                                    <div className="row mb-2">
                                                                        <div className="col-6">
                                                                            <small className="text-muted SimplurisFontWeight500">
                                                                                Is DCM
                      </small>
                                                                            <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                                                {caseMemberDetail.isDCM != false ? "Yes" : "No"}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <small className="text-muted SimplurisFontWeight500">
                                                                                Has Death Certificate
                      </small>
                                                                            <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                                                {caseMemberDetail.hasDeathCertificate != false
                                                                                    ? "Yes"
                                                                                    : "No"}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                case 'mailings':
                                    var mailingHistoryDetail = false;
                                    selectMailingHistoryDetailFromFullScreenMap(mailingHistoryDetail);
                                    return (
                                        <div
                                            className="tab-pane fade active show"
                                            id="mailings"
                                            role="tabpanel"
                                            aria-labelledby="mailings-tab"
                                        >
                                            <div className="card SimplurisDashboardCard">
                                                <div className="p-3">
                                                    {(() => {
                                                        return (
                                                            <RemotePagination
                                                                data={caseMemberDetail.personMailings}
                                                                page={tablePage}
                                                                sizePerPage={sizePerPage}
                                                                totalSize={totalSize}
                                                                onPageChange={onPageChange}
                                                                onSizePerPageList={onSizePerPageList}
                                                            />
                                                        );
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                case 'responses':
                                    return (
                                        <div
                                            className="tab-pane fade show active"
                                            id="responses"
                                            role="tabpanel"
                                            aria-labelledby="responses-tab"
                                        >
                                            <ResponsesDetailView isFromMembers={true} caseID={caseID} data={caseMemberDetail} setCurrentSelectedTab={setCurrentSelectedTab} setIsBackFromResponseScreen={setIsBackFromResponseScreen} />
                                        </div>
                                    )
                                case 'payments':
                                    return (
                                        <div className="tab-pane fade active show" id="payments" role="tabpanel" aria-labelledby="payments-tab">
                                            <PaymentDetailView caseID={caseID} />
                                        </div>
                                    )
                                case 'calls':
                                    return (
                                        <div
                                            className="tab-pane fade show active"
                                            id="calls"
                                            role="tabpanel"
                                            aria-labelledby="calls-tab"
                                        >
                                            <CallsDetailView caseID={caseID} />
                                        </div>
                                    )
                                default:
                            }
                        })()}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="SimplurisCaseDetailsLoaderSection">
                    {showPageLoader ? <SectionSpinner /> : null}
                </div>
            );
        }
    }
    else {
        if (responsesDetail) {
            return (
                <>
                    <ResponsesDetailView isFromMembers={false} caseID={caseID} data={caseMemberDetail} setCurrentSelectedTab={setCurrentSelectedTab} setIsBackFromResponseScreen={setIsBackFromResponseScreen} />
                </>
            )
        }
        if (paymentsDetail) {
            return (
                <>
                    <PaymentDetailView caseID={caseID} />
                </>
            )
        }
    }
}

export default connect(
    (state, props) => ({
        selectedMemberID: getSelectedMemberID(state),
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
        membersPermission: getMembersPermission(state),
        responsesDetail: getResponsesDetail(state),
        paymentsDetail: getPaymentsDetail(state),
        mailingHistoryDetail: getMailingHistoryDetailFromFullScreenMap(state)
    }),
    { changeCaseSubtab, setPreviousCaseSubtab, selectMailingHistoryDetailFromFullScreenMap }
)(CaseMemberDetail)

function MemberMailTrackingTable({ data }) {
    return (
        <table>
            <tbody>
                <tr>
                    <td colSpan="6" className="p-0 border-0 shadow-none">
                        <table className="table table-theme v-middle m-0 mail-tracking-timeline">
                            <tbody>
                                {(() => {
                                    return data.map(item => (
                                        <tr
                                            key={item.id}
                                            className="collapse row2 SimplurisMailingRecipientsUnderGridRaw show"
                                        >
                                            <td>
                                                <div className="tl-item  active">
                                                    <div className="tl-dot b-primary">
                                                        <div className="SimplurisTimeLine"></div>
                                                    </div>
                                                    <div className="tl-content">
                                                        <div className="SimplurisFontSize12 SimplurisFontWeight400 mb-0 SimplurisMailingRecipientsGridTimeLine">
                                                            {item.documentDescription}
                                                        </div>
                                                        <div className="tl-date text-grid-sub-td SimplurisFontSize14 SimplurisFontWeight400">
                                                            {(() => {
                                                                if (item.timestamp != null) {
                                                                    var date = new Date(item.timestamp);
                                                                    return <ReactTimeAgo date={date} />;
                                                                }
                                                            })()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ));
                                })()}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
