import * as React from 'react'
import { connect } from "react-redux";
import styled from 'styled-components'

import TitleBar from './title-bar'
import InfoSection from './info-section'
import History from './history'
import ScrollArea from 'react-scrollbar'
import { teal, lightGray, primaryBlue, red } from 'shared/colors'
import {
    selectMailingHistoryDetailFromFullScreenMap,
} from 'state/case-detail-view/members/actions'
import { SectionSpinner } from 'react/components/spinners'
import { appMode } from '../../../../../../../../api-config'

const Container = styled.div`
  width: 400px;
  word-break: break-word;
  box-shadow: 0 1px 8px 0 #999999;
  border-radius: 10px;
  background-color: white;
`

const BottomSection = styled.div`
padding: 20px 26px;
color:#3490e9 !important;
cursor: pointer;
font-size: 12px;
`

const BottomSectionSecondLink = styled.div`
@media (max-width: 320px) {
    padding-left: 0px !important;
  }
`

const MailingInfo = ({ setShowMailingHistoryRightSideSection, selectedMailing, mailTrackingHopsDetailData, mailHistoryDetail, caseID, getCaseMemberDetailComponent, selectMailingHistoryDetailFromFullScreenMap, apiFlageFetchingMailingHistoryDetails }) => {

    const redirectToMemeberDetailForViewMialingHistory = (caseID, submitterPersonID) => {
        if (submitterPersonID) {
            var mailingHistoryDetail = true;
            selectMailingHistoryDetailFromFullScreenMap(mailingHistoryDetail);
            getCaseMemberDetailComponent(caseID, submitterPersonID)
        }
    };


    let scrollbarStyles = { borderRadius: 4 };
    return <>
        <Container className="ZoomedMailTrackingMailingInfoContainer">
            {(
                () => {
                    if (apiFlageFetchingMailingHistoryDetails) {
                        return <SectionSpinner />
                    }
                    else {
                        if (mailHistoryDetail != undefined) {                            
                            return <>
                                <ScrollArea
                                    className="area"
                                    contentClassName="content"
                                    vertical={true}
                                    verticalScrollbarStyle={scrollbarStyles}
                                    verticalContainerStyle={scrollbarStyles}
                                    horizontal={false}
                                    smoothScrolling={true}
                                    style={{ height: 'calc(100vh - 287px)' }}>
                                    <TitleBar setShowMailingHistoryRightSideSection={setShowMailingHistoryRightSideSection} status={mailHistoryDetail.mailingHistoryTypeTitle} />
                                    <InfoSection mailHistoryDetail={mailHistoryDetail} selectedMailing={selectedMailing} caseID={caseID} />
                                    <History mailHistoryDetail={mailHistoryDetail} />
                                </ScrollArea>
                                {
                                    appMode != "sandbox" && <BottomSection className="b-t">
                                        <div className="d-inline-flex SimplurisFontSize12 SimplurisFontWeight400" onClick={() => getCaseMemberDetailComponent(caseID, mailHistoryDetail.recipientPersonID)}>
                                            View Member Profile
                    </div>
                                        <BottomSectionSecondLink className="d-inline-flex pl-3 pl-sm-5 pl-md-5 pl-xl-5 pl-lg-5 SimplurisFontSize12 SimplurisFontWeight400" onClick={() => redirectToMemeberDetailForViewMialingHistory(caseID, mailHistoryDetail.recipientPersonID)}>
                                            View Mailing History for Member
                    </BottomSectionSecondLink>
                                    </BottomSection>
                                } 
                            </>
                        }
                        else
                            return <>
                                <ScrollArea
                                    className="area"
                                    contentClassName="content"
                                    vertical={true}
                                    verticalScrollbarStyle={scrollbarStyles}
                                    verticalContainerStyle={scrollbarStyles}
                                    horizontal={false}
                                    smoothScrolling={true}
                                    style={{ height: '400px' }}>
                                    <TitleBar setShowMailingHistoryRightSideSection={setShowMailingHistoryRightSideSection} status="" />
                                    <div className="text-center pt-3">Mailing Histroy not available</div>
                                </ScrollArea>

                            </>
                    }
                }
            )()}
        </Container>
    </>

}

//export default MailingInfo

export default connect((state, props) => ({
}), { selectMailingHistoryDetailFromFullScreenMap })(MailingInfo)