import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PageLoader from 'react/components/page-loader';
import { IsNullOrEmptyString } from '../../../global'
import LeftArrowPNG from '../../../static/img/left-arrow.png'
import Switch from "react-switch";
import ButtonLoader from '../../components/button-loader';
import { simplurisFetch } from 'helpers'

const EditBankModalPopup = (props) => {

    const [showButtonLoader, setShowButtonLoader] = useState(false);

    let [isChecking, setIsChecking] = useState(true);
    let [isSaving, setIsSaving] = useState(false);

    let [paymentMethodID, setPaymentMethodID] = useState(props.bankDetails.paymentMethodID);
    const [accountNumber, setAccountNumber] = useState('');
    const [confAccountNumber, setConfAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [confRoutingNumber, setConfRoutingNumber] = useState('');

    useEffect(() => {        
        setAccountNumber(props.bankDetails.bankAccountNumber);
        setConfAccountNumber(props.bankDetails.bankAccountNumber);
        setRoutingNumber(props.bankDetails.bankRoutingNumber);
        setConfRoutingNumber(props.bankDetails.bankRoutingNumber);
    }, [props.bankDetails.paymentMethodID]);

    let [accountNumberError, setAccountNumberError] = useState(false);
    let [accountNumberInvalidError, setAccountNumberInvalidError] = useState(false);
    let [confAccountNumberError, setConfAccountNumberError] = useState(false);
    let [confirmMatchAccountNumberError, setConfirmMatchAccountNumberError] = useState(false);
    let [routingNumberError, setRoutingNumberError] = useState(false);
    let [routingNumberInvalidError, setRoutingNumberInvalidError] = useState(false);
    let [confRoutingNumberError, setConfRoutingNumberError] = useState(false);
    let [confirmMatchRoutingNumberError, setConfirmMatchRoutingNumberError] = useState(false);

    const [ErrorMessage, setErrorMessage] = useState('');

    function CloseEditBankModalPopup() {
        setErrorMessage('');
        setAccountNumber('');
        setConfAccountNumber('');
        setRoutingNumber('');
        setConfRoutingNumber('');
        setAccountNumberError(false);
        setAccountNumberInvalidError(false);
        setConfAccountNumberError(false);
        setConfirmMatchAccountNumberError(false);
        setRoutingNumberError(false);
        setConfRoutingNumberError(false);
        setConfirmMatchRoutingNumberError(false);
        setRoutingNumberInvalidError(false);
        setIsChecking(true);
        setIsSaving(false);
        props.bankDetails.paymentMethodID = Math.random();
        props.setShowEditBankModalPopup(false);        
    }

    const ChangeIsChecking = (checked) => {
        setIsChecking(checked);
    }

    const changedAccountNumber = (event) => {
        setAccountNumber(event.target.value);
        setAccountNumberError(false);
        setAccountNumberInvalidError(false);
    }

    const changedConfAccountNumber = (event) => {
        setConfAccountNumber(event.target.value);
        setConfAccountNumberError(false);
        setConfirmMatchAccountNumberError(false);
    }

    const changedRoutingNumber = (event) => {
        setRoutingNumber(event.target.value);
        setRoutingNumberError(false);
        setRoutingNumberInvalidError(false);
    }

    const changedConfRoutingNumber = (event) => {
        setConfRoutingNumber(event.target.value);
        setConfRoutingNumberError(false);
        setConfirmMatchRoutingNumberError(false);
    }

    const EditBank = (e) => {        
        const isValidAccountNumber = global.IsPositiveNumber.test(accountNumber)
        const isValidRoutingNumber = global.IsPositiveNumber.test(routingNumber)

        if (!accountNumber || !routingNumber || !isValidAccountNumber || !isValidRoutingNumber || !confAccountNumber || !confRoutingNumber) {
            if (!accountNumber) {
                setAccountNumberError(true);
            }

            if (!routingNumber) {
                setRoutingNumberError(true);
            }

            if (!confAccountNumber) {
                setConfAccountNumberError(true);
            }

            if (!confRoutingNumber) {
                setConfRoutingNumberError(true);
            }

            if (accountNumber && !isValidAccountNumber) {
                setAccountNumberInvalidError(true);
            }

            if (routingNumber && !isValidRoutingNumber) {
                setRoutingNumberInvalidError(true);
            }

            return;
        }
        
        if (accountNumber != confAccountNumber) {
            setConfirmMatchAccountNumberError(true);
            return;
        }

        if (routingNumber != confRoutingNumber) {
            setConfirmMatchRoutingNumberError(true);
            return;
        }

        var data = {};
        data.PaymentMethodID = props.bankDetails.paymentMethodID;
        data.PersonID = parseInt(localStorage.getItem(global.PersonID));
        data.BankAccountNumber = accountNumber;
        data.BankRoutingNumber = routingNumber;
        data.AccountType = isChecking ? 1 : 2;
        setShowButtonLoader(true);



        simplurisFetch({
            url: "auth/savebank", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowButtonLoader(false);
            if (data.name != undefined && data.name == "FetchError") {                
                if (data.errorObject.resultCode == "ERROR") {                    
                    setErrorMessage("Something went wrong.");
                }
            }
            else {                
                props.bankDetails.paymentMethodID = Math.random();
                setErrorMessage('');
                CloseEditBankModalPopup();
                props.fetchAllCards();
            }
            }).catch(e => {
                console.error('Error in save bank', e, e.errorObject);
                setShowButtonLoader(false);
                if (e.errorObject.resultCode == "ERROR") {
                    setErrorMessage("Something went wrong.");
                }
            });       
        e.preventDefault();
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseEditBankModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">
                            Edit Bank
                    </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5">
                    <div className="row">
                        <div className="col-md-12 mb-1">
                            {ErrorMessage != "" ? <ErrorPlacement items={ErrorMessage} /> : ''}
                            <p className="modal-title SimplurisFontSize19 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                Edit your bank information.
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex row mt-2">
                        <div className="col-12">
                            <label className="SimplurisMutableTitle d-block">Account Type</label>
                            <label className="d-flex float-left">
                                <span className="align-self-center">checking</span>
                                <Switch onChange={ChangeIsChecking} checked={isChecking} offColor="#008000" onColor="#137eff" checkedIcon={false} uncheckedIcon={false} className="mt-1 mr-2 ml-2" />
                                <span className="align-self-center">Saving</span>
                            </label>
                        </div>
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className={`form-group ${accountNumberError || accountNumberInvalidError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Account Number</label>
                                <div className="position-relative">
                                    <input type="text" tabIndex="1" maxLength="20" defaultValue={accountNumber} onChange={changedAccountNumber} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="Account Number" />
                                    {accountNumberError ? <span>Please enter account number</span> : null}
                                    {accountNumberInvalidError ? <span>Please enter valid account number</span> : null}
                                </div>
                            </div>

                            <div className={`form-group mt-2 ${confAccountNumberError || confirmMatchAccountNumberError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Confirm Account Number</label>
                                <input type="text" tabIndex="2" maxLength="20" defaultValue={confAccountNumber} onChange={changedConfAccountNumber} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="Enter Confirm Account Number" />
                                {confAccountNumberError ? <span>Please enter confirm account number</span> : null}
                                {confirmMatchAccountNumberError ? <span>account number and confirm account number does not match</span> : null}
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12">
                            <div className={`form-group ${routingNumberError || routingNumberInvalidError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Routing Number</label>
                                <div className="position-relative">
                                    <input type="text" tabIndex="3" defaultValue={routingNumber} maxLength="15" onChange={changedRoutingNumber} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="Routing Number" />
                                    {routingNumberError ? <span>Please enter routing number</span> : null}
                                    {routingNumberInvalidError ? <span>Please enter valid routing number</span> : null}
                                </div>
                            </div>

                            <div className={`form-group mt-2 ${confRoutingNumberError || confirmMatchRoutingNumberError ? 'has-error' : ''}`}>
                                <label className="SimplurisMutableTitle">Confirm Routing Number</label>
                                <input type="text" tabIndex="4" maxLength="15" defaultValue={confRoutingNumber} onChange={changedConfRoutingNumber} className="form-control SimplurisNewCaseMemebersInputBox" placeholder="Enter Confirm Routing Number" />
                                {confRoutingNumberError ? <span>Please enter confirm routing number</span> : null}
                                {confirmMatchRoutingNumberError ? <span>routing number and confirm routing number does not match</span> : null}
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w btn-light py-3 mb-4 mt-3" onClick={EditBank}>
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Save</span>}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditBankModalPopup

function ErrorPlacement(data) {
    if (data.items != "") {
        return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
            {data.items}
        </div>
    }
}
