import { CHANGE_CASE_ACTIVITY_FILTER } from './actions'

const initialState = {
    filter: [],
}

export default (state = initialState, { type, filter }) => {
    switch (type) {
        case CHANGE_CASE_ACTIVITY_FILTER:           
            let FinalResult;
            if (filter == 'All Activity') {                
                FinalResult = [];
            }
            else {
                if (state.filter.length > 0 && state.filter.indexOf(filter) > -1) {                    
                    FinalResult = state.filter.filter(id => id != filter)
                }
                else {                    
                    FinalResult = [...state.filter, filter]
                }
            }
            return {
                ...state,
                filter: FinalResult
            }
        default:
            return state
    }
}