import { createSelector } from 'reselect'

const stateSelector = state => state.caseDetailView.members

export const getSelectedMemberID = createSelector(
  [stateSelector],
  ({ selectedMemberID }) => selectedMemberID
)

export const getMembersPermission = createSelector(
    [stateSelector],
    ({ membersPermission }) => membersPermission
)

export const getResponsesDetail = createSelector(
    [stateSelector],
    ({ responsesDetail }) => responsesDetail
)

export const getPaymentsDetail = createSelector(
    [stateSelector],
    ({ paymentsDetail }) => paymentsDetail
)

export const getMailingHistoryDetailFromFullScreenMap = createSelector(
    [stateSelector],
    ({ mailingHistoryDetail }) => mailingHistoryDetail
)
