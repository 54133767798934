import React from 'react'
import { connect } from 'react-redux'

import NumberCard from './number-card'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'

const getDistributionsTotal = distributions =>
    distributions && distributions.length
        ? distributions.reduce(
            (total, { totalDistributionAmount }) => total + totalDistributionAmount,
            0
        )
        : 0

const getPercentChange = (num1, num2) =>
    num1 && num2 && Number((num1 / num2) * 100 - 100).toFixed()

const getMailingsReturnedTotal = mailings => {
    var MailingEventTypeCount = 0;
    mailings != undefined ?? mailings.filter(x => {
        x.mailingEvents.filter(({ mailingEventType }) => {
            if (mailingEventType == 'Returned') {
                MailingEventTypeCount++;
            }
        })
    })
    return MailingEventTypeCount;
}

const getChangeofAddressFormTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Change Of Address Form').length : 0
const getClaimTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Claim Form').length : 0
const getConsentToJoinTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Consent To Join Form').length : 0
const getDisputeTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Dispute Form').length : 0
const getIntakeTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Intake Form').length : 0
const getObjectionTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Objection Form').length : 0
const getOptInPostcardTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Opt In Postcard').length : 0
const getOptOutsTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Opt Out Form').length : 0
const getOptOutPostcardTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Opt Out Postcard').length : 0
const getQuestionnaireTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Questionnaire').length : 0
const getReleaseTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Release Form').length : 0
const getRescissionTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Rescission Form').length : 0
const getVerificationTotal = responses => responses != undefined ? responses.filter(({ responseType }) => responseType === 'Verification Form').length : 0

const Headliners = ({
    changeCaseSubtab,
    distributions,
    responses,
    mailings,
    previousPeriodDistributions,
    previousPeriodResponses,
    previousPeriodMailings,
    setPreviousCaseSubtab,
    userPermissions,
    caseID,
    isClosedStage,
}) => {
    var distributionsPermission
    var responsesPermission
    var mailTrackingPermission
    var viewDistributionsPermission
    var viewResponsePermission
    var viewChangeofAddressPermission
    var viewClaimFormPermission
    var viewConsentToJoinPermission
    var viewDisputePermission
    var viewIntakePermission
    var viewObjectionPermission
    var viewOptInPostcardPermission
    var viewOptOutPermission
    var viewOptOutPostcardPermission
    var viewQuestionnairePermission
    var viewReleasePermission
    var viewRescissionPermission
    var viewVerificationPermission
    var viewMailingsReturnedPermission
    if (Object.keys(userPermissions).length > 0) {
        distributionsPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_BANKINGDISTRIBUTION_VIEW_IN_MENU'
        )
        responsesPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_RESPONSES_VIEW_IN_MENU'
        )
        mailTrackingPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MAILTRACKING_VIEW_IN_MENU'
        )
        viewDistributionsPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_DISTRIBUTIONS'
        )
        viewResponsePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_RESPONSES'
        )      
        viewChangeofAddressPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_CHANGE_OF_ADDRESS_FORM'
        )   
        viewClaimFormPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_CLAIM_FORM'
        )   
        viewConsentToJoinPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_CONSENT_TO_JOIN_FORM'
        )       
        viewDisputePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_DISPUTE_FORM'
        )     
        viewIntakePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_INTAKE_FORM'
        ) 
        viewObjectionPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_OBJECTION_FORM'
        )
        viewOptInPostcardPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_OPT_IN_POSTCARD'
        )    
        viewOptOutPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_OPT_OUT_FORM'
        )  
        viewOptOutPostcardPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_OPT_OUT_POSTCARD'
        )    
        viewQuestionnairePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_QUESTIONNAIRE'
        )        
        viewReleasePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_RELEASE_FORM'
        )         
        viewRescissionPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_RESCISSION_FORM'
        ) 
        viewVerificationPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_VERIFICATION_FORM'
        )  
        viewMailingsReturnedPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_MAILINGS_RETURNED'
        )                                                                 
    }

    const distributionsTotal = getDistributionsTotal(distributions)
    const previousPeriodDistributionsTotal = getDistributionsTotal(previousPeriodDistributions)
    const distributionsPercentChange = getPercentChange(distributionsTotal, previousPeriodDistributionsTotal)

    const numberOfResponses = responses != undefined ? responses.length : 0
    const previousPeriodNumberOfResponses = previousPeriodResponses.length
    const responsesPercentChange = getPercentChange(numberOfResponses, previousPeriodNumberOfResponses )

    const numberofChangeofAddress = getChangeofAddressFormTotal(responses)
    const previousNumberOfChangeofAddress = getChangeofAddressFormTotal(previousPeriodResponses)
    const changeofAddressPercentChange = getPercentChange(numberofChangeofAddress, previousNumberOfChangeofAddress)

    const numberofClaims = getClaimTotal(responses)
    const previousNumberOfClaims = getClaimTotal(previousPeriodResponses)
    const ClaimsPercentChange = getPercentChange(numberofClaims, previousNumberOfClaims)

    const numberofConsentToJoin = getConsentToJoinTotal(responses)
    const previousNumberofConsentToJoin = getConsentToJoinTotal(previousPeriodResponses)
    const ConsentToJoinPercentChange = getPercentChange(numberofConsentToJoin, previousNumberofConsentToJoin)

    const numberofDispute = getDisputeTotal(responses)
    const previousNumberOfDispute = getDisputeTotal(previousPeriodResponses)
    const DisputePercentChange = getPercentChange(numberofDispute, previousNumberOfDispute)  
    
    const numberofIntake = getIntakeTotal(responses)
    const previousNumberOfIntake = getIntakeTotal(previousPeriodResponses)
    const IntakePercentChange = getPercentChange(numberofIntake, previousNumberOfIntake)  

    const numberofObjection = getObjectionTotal(responses)
    const previousNumberOfObjection = getObjectionTotal(previousPeriodResponses)
    const ObjectionPercentChange = getPercentChange(numberofObjection, previousNumberOfObjection)  

    const numberofOptInPostcard = getOptInPostcardTotal(responses)
    const previousNumberOfOptInPostcard = getOptInPostcardTotal(previousPeriodResponses)
    const OptInPostcardPercentChange = getPercentChange(numberofOptInPostcard, previousNumberOfOptInPostcard)  

    const numberOfOptOuts = getOptOutsTotal(responses)
    const previousNumberOfOptOuts = getOptOutsTotal(previousPeriodResponses)
    const optOutsPercentChange = getPercentChange(numberOfOptOuts, previousNumberOfOptOuts)

    const numberOfOptOutPostcard = getOptOutPostcardTotal(responses)
    const previousNumberOfOptOutPostcard = getOptOutPostcardTotal(previousPeriodResponses)
    const OptOutPostcardPercentChange = getPercentChange(numberOfOptOutPostcard, previousNumberOfOptOutPostcard)

    const numberOfQuestionnaire = getQuestionnaireTotal(responses)
    const previousNumberOfQuestionnaire = getQuestionnaireTotal(previousPeriodResponses)
    const QuestionnairePercentChange = getPercentChange(numberOfQuestionnaire, previousNumberOfQuestionnaire)    

    const numberOfRelease = getReleaseTotal(responses)
    const previousNumberOfRelease = getReleaseTotal(previousPeriodResponses)
    const ReleasePercentChange = getPercentChange(numberOfRelease, previousNumberOfRelease)        

    const numberOfRescission = getRescissionTotal(responses)
    const previousNumberOfRescission = getRescissionTotal(previousPeriodResponses)
    const RescissionPercentChange = getPercentChange(numberOfRescission, previousNumberOfRescission)  
    
    const numberOfVerification = getVerificationTotal(responses)
    const previousNumberOfVerification = getVerificationTotal(previousPeriodResponses)
    const VerificationPercentChange = getPercentChange(numberOfVerification, previousNumberOfVerification)      

    const mailingsReturnedTotal = getMailingsReturnedTotal(mailings)
    const previousPeriodMailingsReturnedTotal = getMailingsReturnedTotal(previousPeriodMailings)
    const mailingsPercentChange = getPercentChange(mailingsReturnedTotal, previousPeriodMailingsReturnedTotal)

    return (
        <>
            {(viewDistributionsPermission && viewDistributionsPermission.length > 0) ?
            <NumberCard
                title="Distributions"
                numberPrefix="$"
                number={isClosedStage == true ? 0 : distributionsTotal}
                percentChange={distributionsPercentChange}
                buttonText="See distributions"
                buttonOnClick={() => {
                    changeCaseSubtab('distributions')
                    setPreviousCaseSubtab('distributions')
                }}
                buttonDisabled={(distributionsPermission && distributionsPermission.length > 0) ? false : true}
            /> :null} 
            {(viewResponsePermission && viewResponsePermission.length > 0) ?
            <NumberCard
                title="Responses" 
                number={numberOfResponses}
                //percentChange={responsesPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null} 
            {(viewChangeofAddressPermission && viewChangeofAddressPermission.length > 0) ?
            <NumberCard
                title="Change Of Address Forms"
                number={numberofChangeofAddress}
                percentChange={changeofAddressPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}  
            {(viewClaimFormPermission && viewClaimFormPermission.length > 0) ?
            <NumberCard
                title="Claim Forms"
                number={numberofClaims}
                percentChange={ClaimsPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}      
            {(viewConsentToJoinPermission && viewConsentToJoinPermission.length > 0) ?
            <NumberCard
                title="Consent To Join Forms"
                number={numberofConsentToJoin}
                percentChange={ConsentToJoinPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}    
            {(viewDisputePermission && viewDisputePermission.length > 0) ?
            <NumberCard
                title="Dispute Forms"
                number={numberofDispute}
                percentChange={DisputePercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}  
            {(viewIntakePermission && viewIntakePermission.length > 0) ?
            <NumberCard
                title="Intake Forms"
                number={numberofIntake}
                percentChange={IntakePercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}  
            {(viewObjectionPermission && viewObjectionPermission.length > 0) ?
            <NumberCard
                title="Objection Forms"
                number={numberofObjection}
                percentChange={ObjectionPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}  
            {(viewOptInPostcardPermission && viewOptInPostcardPermission.length > 0) ?
            <NumberCard
                title="Opt In Postcards"
                number={numberofOptInPostcard}
                percentChange={OptInPostcardPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null} 
            {(viewOptOutPermission && viewOptOutPermission.length > 0) ?                                                                              
            <NumberCard
                title="Opt Out Forms"
                number={numberOfOptOuts}
                percentChange={optOutsPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}
            {(viewOptOutPostcardPermission && viewOptOutPostcardPermission.length > 0) ?                                                                              
            <NumberCard
                title="Opt Out Postcard"
                number={numberOfOptOutPostcard}
                percentChange={OptOutPostcardPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}   
            {(viewQuestionnairePermission && viewQuestionnairePermission.length > 0) ?                                                                              
            <NumberCard
                title="Questionnaire"
                number={numberOfQuestionnaire}
                percentChange={QuestionnairePercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}      
            {(viewReleasePermission && viewReleasePermission.length > 0) ?                                                                              
            <NumberCard
                title="Release Form"
                number={numberOfRelease}
                percentChange={ReleasePercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}   
            {(viewRescissionPermission && viewRescissionPermission.length > 0) ?                                                                              
            <NumberCard
                title="Rescission Form"
                number={numberOfRescission}
                percentChange={RescissionPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}   
            {(viewVerificationPermission && viewVerificationPermission.length > 0) ?                                                                              
            <NumberCard
                title="Verification Form"
                number={numberOfVerification}
                percentChange={VerificationPercentChange}
                buttonText="See all responses"
                buttonOnClick={() => {
                    changeCaseSubtab('responses')
                    setPreviousCaseSubtab('responses')
                }}
                buttonDisabled= {(responsesPermission && responsesPermission.length > 0) ? false : true }
            /> :null}   
            {(viewMailingsReturnedPermission && viewMailingsReturnedPermission.length > 0) ?                                                  
            <NumberCard
                title="Mailings Returned"
                number={mailingsReturnedTotal}
                percentChange={mailingsPercentChange}
                buttonText="See mail tracking"
                buttonOnClick={() => {
                    changeCaseSubtab('mail-tracking')
                    setPreviousCaseSubtab('mail-tracking')
                }}
                buttonDisabled={(mailTrackingPermission && mailTrackingPermission.length > 0) ? false : true }
            />:null}  
        </>
    )
}

export default connect(
    (state, props) => ({
        userPermissions: getPermissionsForCase(state, props),
    }),
    { changeCaseSubtab, setPreviousCaseSubtab }
)(Headliners)
