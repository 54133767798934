import React from 'react'

export default function Nav() {
    return <div className="collapse navbar-collapse order-2 mt-3 mt-lg-0 order-lg-1" id="navbarToggler">
        <ul className="navbar-nav SimplurismarginLeft170" data-nav>
            <li className="nav-item active">
                <a href={null} className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700">
                    <span className="nav-text">My Cases</span>
                </a>
            </li>
            {/*<li className="nav-item">
                <a href={null} className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700">
                    <span className="nav-text">Services</span>
                </a>
            </li>
            <li className="nav-item">
                <a href={null} className="nav-link SimplurisAllCasesNavLink SimplurisFontWeight700">
                    <span className="nav-text">Research</span>
                </a>
            </li>*/}
        </ul>
    </div>
}
