import React, { Component, useState, useEffect } from 'react';
import SecureSideBar from '../../components/secure-sidebar'
import SecureHeader from '../../components/secure-header'
import PageLoader from '../../components/page-loader';

function ChangePasswordPage() {

    const [curpwd, setCurpwd] = useState('');
    const [newpwd, setNewpwd] = useState('');
    const [confpwd, setConfpwd] = useState('');
    const [curpwdError, setCurpwdError] = useState(false);
    const [newpwdError, setNewpwdError] = useState(false);
    const [confpwdError, setConfpwdError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [strongPasswordError, setStrongPasswordError] = useState(false);
    const [confirmMatchPasswordError, setConfirmMatchPasswordError] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);

    const SaveClick = () => {

        //either const or var is ok, but const doesn't let you reassign it in memory
        setCurpwd(curpwd);
        setNewpwd(newpwd);
        setConfpwd(confpwd);

        const isStrongPassword = global.StrongPasswordRegex.test(newpwd)

        //! covers all possible nulls/empty strings
        if (!curpwd || !newpwd || !confpwd || !isStrongPassword) {

            if (!curpwd) {
                setCurpwdError(true);
            }

            if (!newpwd) {
                setNewpwdError(true);
            }

            if (!confpwd) {
                setConfpwdError(true);
            }

            if (newpwd && !isStrongPassword) {
                setStrongPasswordError(true);
            }

            return;
        }

        if (newpwd != confpwd) {
            setConfirmMatchPasswordError(true);
            return;
        }

        var data = {};
        data.OldPassword = curpwd;
        data.NewPassword = confpwd;
        
        setShowPageLoader(true);
        fetch(global.API_URL + "auth/changepassword", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((data) => {
            setShowPageLoader(false);
            if (data.resultCode == "ERROR") {
                setErrorMessage(data.resultMessage)

            } else {
                setCurpwd('');
                setNewpwd('');
                setConfpwd('');
                setErrorMessage('');
                setConfirmMatchPasswordError(false);
                setSuccessMessage("Password changed.");
            }
        });
    };

    return (

        <div>
            <SecureHeader />

            {showPageLoader ? <PageLoader /> : null}
            <div id="main" className="layout-row flex simplurisallcasesBodyBackgroudColour">
                <SecureSideBar />

                <div id="content" className="flex ">
                    <div className="page-container" id="page-container">
                        <div className="page-title padding pt-2 pb-0 pl-2 pl-md-4 pl-sm-4">
                            <i className="mr-2 ml-2 i-con i-con-arrow-left"><i></i></i> <span className="SimplurisCasesPageTitle">Change Password</span>
                        </div>
                        <div className="padding">
                            <div className="row">
                                <div className="col-md-9 col-xs-12 col-sm-12">
                                    <div className="d-flex flex row">
                                        <div className="col-md-12 col-xs-12 col-sm-12">
                                            <div className="card SimplurisCardTopBorderStatusDisbursement d-flex flex">
                                                <div className="card-body">
                                                    <h4 className="SimplurisTitle">Change Password</h4>
                                                    <div className="mt-5 mb-3">
                                                        {successMessage != "" ? <SuccessPlacement items={successMessage} /> : ''}
                                                        {errorMessage != "" ? <ErrorPlacement items={errorMessage} /> : ''}
                                                        <form id="frmChangePassword" role="form" action="#">
                                                            <div className={`form-group mt-2 ${curpwdError ? 'has-error' : ''}`}>
                                                                <label>Current Password</label>
                                                                <input type="password" value={curpwd} onChange={e => (setCurpwd(e.target.value), setCurpwdError(false))} id="txtCurrentPassword" name="CurrentPassword" className="form-control SimplurisNewCaseMemebersInputBox" />
                                                                {curpwdError ? <span>Please enter current password</span> : null}
                                                            </div>
                                                            <div className={`form-group mt-2 ${newpwdError || strongPasswordError ? 'has-error' : ''}`}>
                                                                <label>New Password</label>
                                                                <input type="password" value={newpwd} onChange={e => (setNewpwd(e.target.value), setNewpwdError(false), setStrongPasswordError(false))} id="txtNewPassword" name="NewPassword" className="form-control SimplurisNewCaseMemebersInputBox" />
                                                                {newpwdError ? <span>Please enter new password</span> : null}
                                                                {strongPasswordError ? <span> Password does not meet security requirements </span> : null}
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-12 col-sm-6">
                                                                    <p className="mb-0">
                                                                        <small className="text-muted">
                                                                            One lowercase character <br />One uppercase character <br />One number
                                                                            </small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <p className="mb-0">
                                                                        <small className="text-muted">
                                                                            One special character <br /> 8 characters minimum
                                                                            </small>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className={`form-group mt-2 ${confpwdError || confirmMatchPasswordError ? 'has-error' : ''}`}>
                                                                <label>Confirm Password</label>
                                                                <input type="password" value={confpwd} onChange={e => (setConfpwd(e.target.value), setConfpwdError(false))} id="txtConfirmPassword" name="ConfirmPassword" className="form-control SimplurisNewCaseMemebersInputBox" />
                                                                {confpwdError ? <span>Please enter confirm password</span> : null}
                                                                {confirmMatchPasswordError ? <span>New password and confirm password does not match</span> : null}
                                                            </div>

                                                            <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3" onClick={SaveClick}>Save</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default ChangePasswordPage;


function ErrorPlacement(data) {
    if (data.items != "") {
        return <div id="dvFailedMessage" className="alert alert-danger" role="alert">
            {data.items}
        </div>
    }
}

function SuccessPlacement(data) {
    if (data.items != "") {
        return <div id="dvsuccessMessage" className="alert alert-success" role="success">
            {data.items}
        </div>
    }
}