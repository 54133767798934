import { start, resolved } from 'state/helpers'
import { simplurisFetch } from 'helpers'
export const FETCH_CASE_MAILINGS_STATISTICS = 'FETCH_CASE_MAILINGS_STATISTICS'
export const FETCH_CASE_MAILINGS_ACTIVITIES = 'FETCH_CASE_MAILINGS_ACTIVITIES'
export const FETCH_CASE_MAILINGS_RECIPIENTS = 'FETCH_CASE_MAILINGS_RECIPIENTS'
export const FETCH_CASE_MAILINGS_GENERAL = 'FETCH_CASE_MAILINGS_GENERAL'
export const FETCH_CASE_MAILINGS_DOCUMENTS = 'FETCH_CASE_MAILINGS_DOCUMENTS'
export const BIND_DROPDOWN_MAILINGS = 'BIND_DROPDOWN_MAILINGS'

export const BindDropdownsForMailings = (bindDropdowns, Type) => {
	return {
		type: BIND_DROPDOWN_MAILINGS,
		bindDropdowns,
		Type,
	}
}

export const fetchMailings = ({ caseID }) => async dispatch => {
	try {
		dispatch({ type: start(FETCH_CASE_MAILINGS_STATISTICS) })

		const { resultObject } = await simplurisFetch({
            url: `cases/${caseID}/statistics/mailings`,
		})

		dispatch({
			type: resolved(FETCH_CASE_MAILINGS_STATISTICS),
			caseMailingsStatistics: resultObject,
		})
	} catch (e) {
        console.error('Error in mainings', e, e.errorObject);
        dispatch({
            type: resolved(FETCH_CASE_MAILINGS_STATISTICS),
            caseMailingsStatistics: [],
        })
	}
}

export const fetchMailingsActivity = ({
	caseID,
    mailingID,
    lastRecordId = 0,
    _pageSize = 10
}) => async dispatch => {
    try {
        
		dispatch({ type: start(FETCH_CASE_MAILINGS_ACTIVITIES) })

        
        let { resultObject } = await simplurisFetch({
            url: `cases/${parseInt(caseID)}/mailings/${parseInt(mailingID)}/${parseInt(lastRecordId)}/${parseInt(_pageSize)}/bulksendevents`,
        })		

        
        let lastRecordIds = resultObject.length > 0 ? resultObject[resultObject.length - 1].rowNo : 0

        let isHasNewData = false;
        
        if (resultObject.length > _pageSize) {
            
            resultObject = resultObject.slice(0, -1)
            isHasNewData = true;
        }
        
		dispatch({
            type: resolved(FETCH_CASE_MAILINGS_ACTIVITIES),
            caseMailingsActivities: {
                caseMailingsActivities: resultObject,
                lastRecordIds: lastRecordIds,
                isHasNewData: isHasNewData
            }
			
		})
	} catch (e) {
        console.error('Error in mailing activity', e, e.errorObject);

        dispatch({
            type: resolved(FETCH_CASE_MAILINGS_ACTIVITIES),
            caseMailingsActivities: [],
        })
	}
}

export const fetchMailingsRecipients = (
	caseID,
	mailingID,
	pageIndex,
	pageSize,
	sortColumn,
	sortColumnDir,
	searchValue
) => async dispatch => {
	try {
		dispatch({ type: start(FETCH_CASE_MAILINGS_RECIPIENTS) })
        searchValue = searchValue == undefined ? null : searchValue
        
        const { resultObject, resultObjectRecordCount } = await simplurisFetch({            
            url: `cases/${parseInt(caseID)}/mailings/${parseInt(mailingID)}/${parseInt(pageIndex)}/${parseInt(pageSize)}/${sortColumn}/${sortColumnDir}/${searchValue}/recipients`,
        })
		  
		dispatch({
			type: resolved(FETCH_CASE_MAILINGS_RECIPIENTS),
			caseMailingsRecipients: {
				data: resultObject,
				pageIndex: pageIndex,
				pageSize: pageSize,
				searchValue: searchValue,
				sortColumn: sortColumn,
				sortColumnDir: sortColumnDir,
				totalCount: resultObjectRecordCount,
			},
		})
	} catch (e) {
        console.error('Error in mailing recipients', e, e.errorObject);
	}
}

export const fetchMailingsGeneral = ({
	caseID,
	mailingID,
}) => async dispatch => {
	try {
		dispatch({ type: start(FETCH_CASE_MAILINGS_GENERAL) })

        const { resultObject } = await simplurisFetch({
            url: `cases/${parseInt(caseID)}/mailings/${parseInt(mailingID)}/general`,
        })

		dispatch({
			type: resolved(FETCH_CASE_MAILINGS_GENERAL),
			caseMailingsGeneral: resultObject,
		})
	} catch (e) {
        console.error('Error in mailing general', e, e.errorObject);
	}
}

export const fetchMailingsDocuments = (caseID, mailingID) => async dispatch => {
	try {
        dispatch({ type: start(FETCH_CASE_MAILINGS_DOCUMENTS) })

        const { resultObject } = await simplurisFetch({
            url: `cases/${parseInt(caseID)}/mailings/${parseInt(mailingID)}/documents`,
        })

		dispatch({
			type: resolved(FETCH_CASE_MAILINGS_DOCUMENTS),
			caseMailingsDocuments: resultObject == null ? [] : resultObject,
		})
	} catch (e) {
        console.error('Error in mailing documents', e, e.errorObject);
	}
}
