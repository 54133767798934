import * as React from 'react'
import styled from 'styled-components'
import invariant from 'invariant'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const StyledPopover = styled(Popover)`
  width: 300px;
  max-width: 100%;
`
const Wrapper = styled.span`
  padding-left: 5px;
  cursor: pointer;
`

const MoreInfoPopover = ({ popoverContent }) => {
  invariant(popoverContent, 'You must provide the MoreInfoPopover component with a popoverContent prop')

  return (
    <Wrapper>
      <OverlayTrigger
        overlay={
          <StyledPopover id="more-info-popover">
            <Popover.Content>{popoverContent}</Popover.Content>
          </StyledPopover>
        }
      >
        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
      </OverlayTrigger>
    </Wrapper>
  )
}

export default MoreInfoPopover
