import React, { Component, useState, useEffect } from "react";
import _ from 'lodash'
import { Redirect } from 'react-router';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import a5png from "../../../../static/img/a5.jpg";
import GlobalImage from "../../../components/global-image-set";
import { Link } from "react-router-dom";
import { IsNullOrEmptyString } from "../../../../global";
import { labFeatures, appMode } from '../../../../api-config';
import { signOut } from 'state/sign-in/actions';
import { getIsUserSysAdmin } from 'state/sign-in/selectors';
import { simplurisFetch, toCamel } from 'helpers';
import { fetchThreads, updateMessages, updateThreads, getThreadMessgaes } from 'state/message-threads/actions';

function StatuIcons({ signOut, userIsSysAdmin, ...props }) {

    const [redirectToLoginPage, setRedirectToLoginPage] = useState(false);
    const [redirectToLoginPageForSandBoxMode, setRedirectToLoginPageSandBoxMode] = useState(false);
    var MessageChannel = global.Pusher.subscribe('platform-messaging-' + localStorage.getItem(global.PersonMapID));
    MessageChannel.unbind(global.Messaging).bind(global.Messaging, function (data) {

        var parsedJson = toCamel(data);

        // Check in thread that available in state or not in receiver side, then create and update that thread state.
        var messageThreadsId = Object.keys(props.threads).filter(x => {
            return x == parsedJson.messageThreadID;
        });
        if (messageThreadsId.length == 0) {
            props.updateThreads(parsedJson);
            simplurisFetch({
                url: `thread/${parsedJson.messageThreadID}/${parsedJson.recipientPersonMapID}/detail`
            }).then((result) => {
                if (result.error == null) {
                    props.getThreadMessgaes(result.resultObject);
                }
            });
        } else {
            props.updateMessages(parsedJson.messages[0]);
        }
    });

    //let [firstName, setFirstName] = useState(localStorage.getItem(global.FirstName) || '');
    //let [lastName, setLastName] = useState(localStorage.getItem(global.LastName) || '');
    let firstName = !IsNullOrEmptyString(localStorage.getItem(global.FirstName))
        ? localStorage.getItem(global.FirstName)
        : '';
    let lastName = !IsNullOrEmptyString(localStorage.getItem(global.LastName))
        ? localStorage.getItem(global.LastName)
        : '';
    let email = !IsNullOrEmptyString(localStorage.getItem(global.Email))
        ? localStorage.getItem(global.Email)
        : '';
    let title = !IsNullOrEmptyString(localStorage.getItem(global.Title))
        ? localStorage.getItem(global.Title)
        : '';        
    //const [profilePicUrl, setProfilePicUrl] = useState(localStorage.getItem(global.ProfilePicURL) || '');
    let profilePicUrl = !IsNullOrEmptyString(localStorage.getItem(global.ProfilePicURL))
        ? localStorage.getItem(global.ProfilePicURL)
        : '';
    const managerFirstLetter = firstName + ' ' + lastName;

    const SignoutClick = () => {
        global.Pusher.unsubscribe('platform-messaging-' + localStorage.getItem(global.PersonMapID));
        localStorage.removeItem(global.AuthTokenKey);
        localStorage.removeItem(global.AccessTokenKey);
        localStorage.removeItem(global.FirstName);
        localStorage.removeItem(global.LastName);
        localStorage.removeItem(global.ProfilePicURL);
        localStorage.removeItem(global.Email);
        localStorage.removeItem(global.PhoneNumber);
        localStorage.removeItem(global.PhoneNumber2);
        localStorage.removeItem(global.PersonFax);
        localStorage.removeItem(global.Title);
        localStorage.removeItem(global.PersonID);
        localStorage.removeItem(global.SchemaName);
        localStorage.removeItem(global.PersonMapID);
        localStorage.removeItem(global.DboPersonID);
        localStorage.removeItem(global.CaseID);
        localStorage.removeItem(global.LastAgreedToTerms);
        localStorage.removeItem(global.IsSysAdmin);
        localStorage.removeItem(global.ReportPDFURL);
        localStorage.removeItem(global.CaseIDForReportPDF);
        localStorage.removeItem(global.ReportCreatedDate);
        localStorage.removeItem(global.ReportTitle);
        localStorage.removeItem(global.ReportType);
        localStorage.removeItem(global.SecureFileURL);
        localStorage.removeItem(global.CaseIDForSecureFile);
        localStorage.removeItem(global.SecureFileIDForSecureFile);
        localStorage.removeItem(global.caseOfficialName);
        localStorage.removeItem(global.OfficialCaseName);
        localStorage.removeItem(global.SecureFileName);
        localStorage.removeItem(global.SecureFileForCaseOfficialName);
        localStorage.removeItem(global.SecureFileSentDate);
        signOut() // Redux clearing action
        setRedirectToLoginPage(true);
    };

    const SignoutClickForSandBox = () => {
        setRedirectToLoginPageSandBoxMode(true);
    }

    useEffect(() => {
        //const hostname = window && window.location && window.location.hostname;
        //window.onbeforeunload = function () {
        //    if (hostname != "localhost") {
        //        SignoutClick();
        //    }
        //}.bind(this);

        //window.onpopstate = function (data) {
        //    if (hostname != "localhost") {
        //        if (data.currentTarget.location.pathname == "/" || data.currentTarget.location.pathname == "/sign-in") {
        //            SignoutClick();
        //        }
        //    }
        //}.bind(this);
    }, []);

    return (
        <>
            {redirectToLoginPage ? <Redirect to="/sign-in" /> : null}
            {redirectToLoginPageForSandBoxMode ?
                !IsNullOrEmptyString(localStorage.getItem(global.TESTDRIVEURLCODE)) ? <Redirect to={`/login/${localStorage.getItem(global.TESTDRIVEURLCODE)}`} /> : <Redirect to={`/`} />
                : null}
            <ul className="nav navbar-menu order-1 order-lg-2">
                <li className="nav-item dropdown">
                    <a
                        href={null}
                        data-toggle="dropdown"
                        className="nav-link d-flex align-items-center py-0 px-lg-0 px-0 text-color"
                    >
                        <span className=" mr-lg-3 d-none l-h-1x d-xl-block text-right">
                            <span className="text-dark font-weight-500 SimplurisDarkHeaderMenuUserName">
                                {firstName}&nbsp;{lastName}
                            </span>
                            <small className="text-fade d-block mb-1 SimplurisHeaderAvailableLabel">
                                {title}
              </small>
                        </span>
                        <span className="">
                            <GlobalImage
                                firstname={managerFirstLetter}
                                image={profilePicUrl}
                                email={email}
                                dynamicClass="SimplurisHeaderProfileImage w-36 avatar circle bg-secondary-lt"
                            />
                        </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right w pt-0 mt-3 animate fadeIn">
                        <div className="row no-gutters mb-2"></div>
                        <Link to={"/profile"} className="dropdown-item">
                            Profile
            </Link>
                        <div className="row no-gutters mb-2"></div>
                        {/*
                            localStorage.getItem(global.IsSysAdmin) == "true" &&
                            <Link to={"/api-status"} className="dropdown-item">
                                System Status
                             </Link>
                       */ }
                        <div className="dropdown-divider"></div>
                        {
                            appMode == "sandbox" ? <a className="dropdown-item" href={null} onClick={SignoutClickForSandBox}>
                                Sign out
                         </a> : <a className="dropdown-item" href={null} onClick={SignoutClick}>
                                    Sign out
                         </a>
                        }

                    </div>
                </li>

                {labFeatures &&
                    <>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link px-2 i-con-h-a ml-2 SimplurisBackgroundLighGray rounded-circle"
                                data-toggle="dropdown"
                            >
                                <i className="i-con i-con-mail">
                                    <i></i>
                                </i>
                                <span className="badge badge-pill badge-up bg-danger SimplurisHeaderBadge">
                                    &nbsp;
            </span>
                            </a>

                            <div className="dropdown-menu dropdown-menu-right mt-3 w-md animate fadeIn p-0">
                                <div className="scrollable hover">
                                    <div className="list list-row animates animates-fadeIn">
                                        <div className="list-item " data-id="10">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-warning-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    New cloudy version updated, click to{" "}
                                                    <a href="#">upgrade</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="6">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-danger-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">Homepage mockup design</div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="5">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-warning-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    @Eddel Competitive gaming <a href="#">ladders</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="3">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-info-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    <a href="#">@James</a> submit a support ticket
                    </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="11">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-info-lt">K</span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    Prepare the documentation for the{" "}
                                                    <a href="#">Fitness app</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="2">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-primary-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    What's the project progress now
                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex px-3 py-2 b-t">
                                    <div className="flex">
                                        <span>6 Notifications</span>
                                    </div>
                                    <a href="setting.html">
                                        See all <i className="fa fa-angle-right text-muted"></i>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link px-2 i-con-h-a ml-2 SimplurisBackgroundLighGray rounded-circle"
                                data-toggle="dropdown"
                            >
                                <i className="i-con i-con-bell">
                                    <i></i>
                                </i>
                                <span className="badge badge-pill badge-up bg-danger SimplurisHeaderBadge">
                                    &nbsp;
            </span>
                            </a>

                            <div className="dropdown-menu dropdown-menu-right mt-3 w-md animate fadeIn p-0">
                                <div className="scrollable hover">
                                    <div className="list list-row animates animates-fadeIn">
                                        <div className="list-item " data-id="10">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-warning-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    New cloudy version updated, click to{" "}
                                                    <a href="#">upgrade</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="6">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-danger-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">Homepage mockup design</div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="5">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-warning-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    @Eddel Competitive gaming <a href="#">ladders</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="3">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-info-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    <a href="#">@James</a> submit a support ticket
                    </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="11">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-info-lt">K</span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    Prepare the documentation for the{" "}
                                                    <a href="#">Fitness app</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-item " data-id="2">
                                            <div>
                                                <a href="#">
                                                    <span className="w-32 avatar circle bg-primary-lt">
                                                        <img src={a5png} alt="." />
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="flex">
                                                <div className="item-feed h-2x">
                                                    What's the project progress now
                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex px-3 py-2 b-t">
                                    <div className="flex">
                                        <span>6 Notifications</span>
                                    </div>
                                    <a href="setting.html">
                                        See all <i className="fa fa-angle-right text-muted"></i>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </>
                }
                <li className="nav-item d-lg-none">
                    <a
                        href="#"
                        className="nav-link i-con-h-a ml-2 px-1"
                        data-toggle="collapse"
                        data-toggle-class
                        data-target="#navbarToggler"
                    >
                        <i className="i-con i-con-nav text-muted">
                            <i></i>
                        </i>
                    </a>
                </li>
            </ul>

            {/* <PermissionPage /> */}

        </>
    )
}

export default _.flow(
    withRouter,
    connect((state, props) => ({
        //userIsSysAdmin: getIsUserSysAdmin(state, props),
        threads: state.threads,
    }),
        {
            signOut,
            fetchThreads,
            updateMessages,
            updateThreads,
            getThreadMessgaes,
        }),
)(StatuIcons)
