import { FETCH_DISTRIBUTIONS_FOR_CASE } from './actions'
import { FETCH_CASE_LIVE_REPORT } from 'state/live-reports/actions'

const initialState = {}

export default (
    state = initialState,
    {
        type,
        distributions,
        caseLiveReport,
        caseID,
        uncashedPayments,
        totalDisbursed,
    }
) => {
    switch (type) {
        case FETCH_DISTRIBUTIONS_FOR_CASE:
        case FETCH_CASE_LIVE_REPORT:
            const distributionData = (caseLiveReport && caseLiveReport.distributions) || distributions || []
         

            const distributionsByID = distributionData.reduce(
                (distributionsMap, distribution) => {
                    distributionsMap[distribution.distributionID] = {
                        ...distribution,
                        payments: undefined,
                    }
                    return distributionsMap
                },
                {}
            )

            const paymentsByID = distributionData.reduce(
                (paymentsMap, { payments, distributionID }) => {
                    payments.forEach(payment => {
                        paymentsMap[payment.paymentID] = {
                            ...payment,
                            distributionID,
                        }
                    })
                    return paymentsMap
                },
                {}
            )

            return {
                ...state,
                [caseID]: {
                    ...state[caseID],
                    distributionData: distributionsByID,
                    payments: paymentsByID,
                    uncashedPayments,
                    totalDisbursed,
                },
            }
        default:
            return state
    }
}
