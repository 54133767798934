import * as React from 'react'
import styled from 'styled-components'

const defaultClasses = `btn btn-simpluris-response-summary remove-bootstrap-persistent-button-border SimplurisFontSize12 font-weight-500 m-1`

const ButtonContent = styled.div`
	display: flex;
	align-items: center;
`
const ColorDot = styled.div`
	background-color: ${props => props.color};
	width: 11px;
	height: 11px;
	border-radius: 50%;
	margin-top: -2px;
	margin-right: 5px;
`

const FilterButton = ({ active, onClick, label, legendColor }) => (
	<button
		className={`${defaultClasses} ${active ? 'active' : ''}`}
		onClick={onClick}
	>
		<ButtonContent>
			{legendColor ? <ColorDot color={legendColor} /> : null}{label}
		</ButtonContent>
	</button>
)

export default FilterButton
