import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    BootstrapTable,
    TableHeaderColumn,
    SearchField
} from "react-bootstrap-table";

import ShieldCheckpng from "static/img/shield-check.png";
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import exportImage from 'static/img/export.png'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { SectionSpinner } from 'react/components/spinners'
import ShareReportModalPopup from 'react/features/case-detail/content/live-report/share-report-modal'
import { simplurisFetch } from 'helpers'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { getPermissionsForCase } from 'state/permissions/selectors'
import {
    selectMember,
    selectPermission,
    selectResponsesDetail,
    selectPaymentsDetail
} from 'state/case-detail-view/members/actions'
import { setTimeout } from "timers";
import { labFeatures } from '../../../../api-config'

function CaseMembers({ userPermissions, selectMember, changeCaseSubtab, selectPermission, selectResponsesDetail, selectPaymentsDetail, ...data }) {    
    var memberPermission;
    var exportDataPermission;
    var gridSearchTextPermission;
    var sendEmailPermission;
    var sortGridByColumnPermission;
    var viewEmailAdrressPermission;
    var viewFirstNamePermission;
    var viewLastNamePermission;
    var viewPersonIdPermission;
    var viewSimIdPermission;
    var viewStatusPermission;
    var viewAwardStatusPermission;
    var drillDownDetailPermission;
    
    if (Object.keys(userPermissions).length > 0) {
        drillDownDetailPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_DRILL_DOWN_TO_DETAIL'
        )
        memberPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEW_IN_MENU'
        )

        exportDataPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MEMBER_DOWNLOAD_EXPORT_DATA'
        )

        gridSearchTextPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_FILTER_GRID_BY_SEARCH_TEXT'
        )
        sendEmailPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_SEND_DATA_EMAIL'
        )

        sortGridByColumnPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_SORT_GRID_BY_COLUMN'
        )
        viewEmailAdrressPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_EMAIL_ADDRESS'
        )

        viewFirstNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_FIRST_NAME'
        )
        viewLastNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_LAST_NAME'
        )

        viewPersonIdPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_PERSONID'
        )
        viewSimIdPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_SIMID'
        )
        viewStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_STATUS'
        )
        viewAwardStatusPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MEMBER_VIEWDATA_SETTLEMENT_AWARD_STATUS'
        )
    }

    const [showModal, setShowModal] = useState(false);
    const [caseMemberData, setCaseMemberData] = useState([]);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const caseID = data.caseID;
    const getCaseMemberDetailComponent = data.getCaseMemberDetailComponent;
    const [tablePage, setTablePage] = useState(data.page);
    const [sizePerPage, setSizePerPage] = useState(data.sizePerPage);
    const [totalSize, setTotalSize] = useState(data.totalMembers);
    const [sortName, setSortName] = useState('lastName');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchText, setSearchText] = useState("");

    const [activeMembers, setActiveMembers] = useState(true);
    const [inactiveMembers, setInactiveMembers] = useState(true);

    const [isDataFetch, setIsDataFetch] = useState(false);
    let hasAllPermission = (
        (viewEmailAdrressPermission && viewEmailAdrressPermission.length > 0) ||
        (viewAwardStatusPermission && viewAwardStatusPermission.length > 0) ||
        (viewFirstNamePermission && viewFirstNamePermission.length > 0) ||
        (viewLastNamePermission && viewLastNamePermission.length > 0) ||
        (viewPersonIdPermission && viewPersonIdPermission.length > 0) ||
        (viewSimIdPermission && viewSimIdPermission.length > 0) ||
        (viewStatusPermission && viewStatusPermission.length > 0)
    );

    const fetchCaseMemberDetail = async (
        caseID,
        page,
        sizePerPage,
        sortName,
        sortOrder,
        searchText
    ) => {   
        if (searchText === "") searchText = null;
        let memberStatus = null;        
        if (activeMembers && !inactiveMembers) {
            memberStatus = "Active";
        } else if (!activeMembers && inactiveMembers) {
            memberStatus = "Inactive";
        } else if (!activeMembers && !inactiveMembers) {
            setCaseMemberData([]);
            setIsDataFetch(true);
            setTotalSize(0);
            return;
        }        
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/${parseInt(caseID)}/casemembers/${parseInt(page)}/${parseInt(
                sizePerPage
            )}/${sortName}/${sortOrder}/${searchText}/${memberStatus}`
        })
            .then(result => {                
                if (result.name !== undefined && result.name === "FetchError") {
                    setShowPageLoader(false);
                    setIsDataFetch(false);
                } else {    
                    setTablePage(page);
                    setSortName(sortName);
                    setSortOrder(sortOrder);
                    setTotalSize(result.resultObjectRecordCount);
                    setCaseMemberData(result.resultObject);
                    setShowPageLoader(false);
                    setIsDataFetch(true);
                }
            })
            .catch(e => {
                console.error("Error in case members detail", e, e.errorObject);
                setShowPageLoader(false);
                setIsDataFetch(false);
            });
    };

    useEffect(() => {
        fetchCaseMemberDetail(
            data.caseID,
            tablePage,
            sizePerPage,
            sortName,
            sortOrder,
            searchText
        );
    }, [activeMembers, inactiveMembers]);

    const onFilterChange = status => {        
        status === "Active"
            ? setActiveMembers(!activeMembers)
            : setInactiveMembers(!inactiveMembers);

        setIsDataFetch(false);
    };

    function setTableOption() {
        if (showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        }
        else {
            return "no data found!";
        }
        //if (!showPageLoader) {        
        //    return <div className="row"> <SectionSpinner /></div>
        //}
        //else {        
        //    return "no data found!";            
        //}
    }

    // const options = {
    //     searchField: createCustomSearchField,
    //     toolBar: createCustomToolBar,
    //     sortIndicator: true,
    //     noDataText: setTableOption(),
    // };

    // function onRowSelect(row, isSelected, e) {
    //     if (isSelected) {
    //         for (const prop in row) {
    //             if (prop == "personID") {
    //                 getCaseMemberDetailComponent(caseID, row[prop]);
    //             }
    //         }
    //     }
    //     return false;
    // }

    // const selectRowProp = {
    //     mode: 'checkbox',
    //     hideSelectColumn: true,
    //     clickToSelect: true,
    //     onSelect: onRowSelect,
    // };

    const handleCellClick = ({ personID }) => {
        
        if (personID) {
        
            //getCaseMemberDetailComponent(caseID, personID);            
            selectMember(personID)
            selectPermission({ viewFirstNamePermission, viewLastNamePermission });
            changeCaseSubtab('member-detail')
            setPreviousCaseSubtab('member')
            var responsesDetail = false;
            selectResponsesDetail(responsesDetail);
            var paymentsDetail = false;
            selectPaymentsDetail(paymentsDetail)
            
        }
    };

    function linkCellFormatter(cell, row) {
        if (drillDownDetailPermission && drillDownDetailPermission.length > 0) {
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={null}
                    onClick={() => handleCellClick(row)}
                >
                    {cell}
                </a>
            );
        } else {
            return <span>{cell}</span>;
        }
    }

    // function trClassFormat() {
    //     return 'tr-cursor';
    // }

    function imageFormatter(cell, row, _, i) {
        if (row.status === "Active") {
            return (
                <OverlayTrigger
                    placement={i > 0 ? "top" : "right"}
                    overlay={<Tooltip id="tooltip-active">Member is Active</Tooltip>}
                >
                    <img src={ShieldCheckpng} />
                </OverlayTrigger>
            );
        } else {
            return null;
        }
    }

    function buttonFormatter(cell, row) {
        return (
            <>
                <div className="item-action dropdown">
                    <a href="#" data-toggle="dropdown" className="text-muted">
                        <i className="mr-2 i-con i-con-more-h">
                            <i></i>
                        </i>
                    </a>
                    <div
                        className="dropdown-menu dropdown-menu-right bg-dark d-menu"
                        role="menu"
                        x-placement="top-end"
                    >
                        <a className="dropdown-item" href="#">
                            See detail
            </a>
                        <a className="dropdown-item">Download</a>
                        <a className="dropdown-item">Edit</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item">Delete item</a>
                    </div>
                </div>
            </>
        );
    }

    function onPageChange(page, sizePerPage) {
        setTablePage(page);
        setCaseMemberData([]);
        fetchCaseMemberDetail(
            data.caseID,
            page,
            sizePerPage,
            sortName,
            sortOrder,
            searchText
        );
    }

    function onSizePerPageList(sizePerPage) {
        setSizePerPage(sizePerPage);
        setCaseMemberData([]);
        fetchCaseMemberDetail(
            data.caseID,
            1,
            sizePerPage,
            sortName,
            sortOrder,
            searchText
        );
    }

    function onSortChange(sortName, sortOrder) {
        setSortName(sortName);
        setSortOrder(sortOrder);
        fetchCaseMemberDetail(
            data.caseID,
            1,
            sizePerPage,
            sortName,
            sortOrder,
            searchText
        );
    }

    const defaultSorted = [
        {
            dataField: "lastName",
            order: "asc"
        }
    ];

    function onSearchChange(searchText) {
        setSearchText(searchText);
        if (searchText === "") setSearchText("");

        searchText = searchText === "" ? null : searchText;

        setCaseMemberData([]);
        fetchCaseMemberDetail(
            data.caseID,
            1,
            sizePerPage,
            sortName,
            sortOrder,
            searchText
        );
        setTimeout(function () {
            setSearchText("");
        }, 100000);
    }

    const RemotePagination = ({
        data,
        page,
        sizePerPage,
        totalSize,
        onPageChange,
        onSizePerPageList,
        onSortChange,
        onSearchChange
    }) => (
            <div>
                <BootstrapTable
                    data={data}
                    remote={true}
                    hover={true}
                    search={
                        gridSearchTextPermission && gridSearchTextPermission.length > 0
                            ? true
                            : false
                    }
                    pagination={true}
                    fetchInfo={{ dataTotalSize: totalSize }}
                    options={{
                        searchField: getSearchField({ defaultValue: searchText }),
                        toolBar: getToolbarWithTotal({ buttonText: `All ${totalSize}` }),
                        sortIndicator: true,
                        noDataText: setTableOption(),
                        sizePerPage: sizePerPage,
                        onPageChange: onPageChange,
                        sizePerPageList: [10, 20, 35],
                        page: page,
                        onSizePerPageList: onSizePerPageList,
                        onSortChange: onSortChange,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSearchChange: onSearchChange,
                        searchDelayTime: 1000
                    }}
                    style={{ "margin-bottom": "0px" }}
                    tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover SimplurisMembersGrid"
                    bodyContainerClass="text-grid-td"
                    defaultSorted={defaultSorted}
                >
                    <TableHeaderColumn
                        dataField=""
                        width="50px"
                        dataFormat={imageFormatter}
                        searchable={false}
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                        width="110px"
                        dataField="firstName"
                        hidden={
                            viewFirstNamePermission && viewFirstNamePermission.length > 0
                                ? false
                                : true
                        }
                        dataFormat={linkCellFormatter}
                        dataSort={
                            sortGridByColumnPermission && sortGridByColumnPermission.length > 0
                                ? true
                                : false
                        }
                    >
                        First Name
        </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        width="110px"
                        dataField="lastName"
                        hidden={
                            viewLastNamePermission && viewLastNamePermission.length > 0
                                ? false
                                : true
                        }
                        dataSort={
                            sortGridByColumnPermission && sortGridByColumnPermission.length > 0
                                ? true
                                : false
                        }
                    >
                        Last Name
        </TableHeaderColumn>
                    <TableHeaderColumn
                        width="110"
                        dataField="personID"
                        isKey
                        hidden={
                            viewPersonIdPermission && viewPersonIdPermission.length > 0
                                ? false
                                : true
                        }
                        searchable={false}
                        dataSort={
                            sortGridByColumnPermission && sortGridByColumnPermission.length > 0
                                ? true
                                : false
                        }
                    >
                        Person ID
        </TableHeaderColumn>
                    <TableHeaderColumn
                        width="90"
                        dataField="simid"
                        hidden={
                            viewSimIdPermission && viewSimIdPermission.length > 0 ? false : true
                        }
                        searchable={false}
                        dataSort={
                            sortGridByColumnPermission && sortGridByColumnPermission.length > 0
                                ? true
                                : false
                        }
                    >
                        SIM ID
        </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        width="150px"
                        dataField="email"
                        hidden={
                            viewEmailAdrressPermission && viewEmailAdrressPermission.length > 0
                                ? false
                                : true
                        }
                        dataSort={
                            sortGridByColumnPermission && sortGridByColumnPermission.length > 0
                                ? true
                                : false
                        }
                    >
                        Email Address
        </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        width="120"
                        dataField="settlementAwardStatusTitle"
                        hidden={
                            viewAwardStatusPermission && viewAwardStatusPermission.length > 0
                                ? false
                                : true
                        }
                        dataSort={
                            sortGridByColumnPermission && sortGridByColumnPermission.length > 0
                                ? true
                                : false
                        }
                    >
                        Settlement Award Status
        </TableHeaderColumn>
                    <TableHeaderColumn
                        columnTitle={true}
                        width="100"
                        dataField="status"
                        hidden={
                            viewStatusPermission && viewStatusPermission.length > 0
                                ? false
                                : true
                        }
                        searchable={false}
                        dataSort={
                            sortGridByColumnPermission && sortGridByColumnPermission.length > 0
                                ? true
                                : false
                        }
                    >
                        Status
        </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField=""
                        dataFormat={buttonFormatter}
                        searchable={false}
                        hidden
                    ></TableHeaderColumn>
                </BootstrapTable>
            </div>
        );

    if (memberPermission && memberPermission.length <= 0) {
        // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
        data.changeCaseSubtab(
            data.previousCaseSubtabs[data.previousCaseSubtabs.length - 2]
        );
        data.setPreviousCaseSubtab(
            data.previousCaseSubtabs[data.previousCaseSubtabs.length - 2]
        );
    }

    if (showPageLoader) {
        return <div className="row"> <SectionSpinner /></div>
    }

    return (
        <div className="card d-flex flex p-3">
            <div className="row">
                <div className="col-md-5 col-lg-5 col-xl-6 pt-4 mb-2">
                    <span className="SimplurisRecentDisbursementsChartTitle">
                        Members
						</span>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 pt-md-3 pt-0 mb-2 col-12">
                    <div className="float-left float-md-right">
                        {hasAllPermission && (
                            <>
                                <button
                                    className={`btn btn-simpluris-response-summary remove-bootstrap-persistent-button-border SimplurisFontSize12 font-weight-500 m-1 ${
                                        activeMembers ? 'active' : ''
                                        }`}
                                    onClick={e => {
                                        onFilterChange('Active')
                                    }}
                                >
                                    Active
									</button>

                                <button
                                    className={`btn btn-simpluris-response-summary remove-bootstrap-persistent-button-border SimplurisFontSize12 font-weight-500 m-1 ${
                                        inactiveMembers ? 'active' : ''
                                        }`}
                                    onClick={e => {
                                        onFilterChange('Inactive')
                                    }}
                                >
                                    Inactive
									</button>
                            </>
                        )}

                        {labFeatures &&
                            <>
                                <span className="pl-3">
                                    {exportDataPermission && exportDataPermission.length > 0 ? (
                                        <img src={exportImage} onClick={() => setShowModal(true)} />
                                    ) : null}
                                    <ShareReportModalPopup
                                        OpenModalPopup={showModal}
                                        setShowShareReportModalPopup={setShowModal}
                                        userPermissions={userPermissions}
                                        caseID={caseID}
                                    />
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>
            {(() => {
                if (isDataFetch) {
                    if (hasAllPermission) {
                        return (
                            <>
                                <RemotePagination
                                    data={caseMemberData}
                                    page={tablePage}
                                    sizePerPage={sizePerPage}
                                    totalSize={totalSize}
                                    onPageChange={onPageChange}
                                    onSizePerPageList={onSizePerPageList}
                                    onSortChange={onSortChange}
                                    onSearchChange={onSearchChange}
                                />
                            </>
                        )
                    }
                } else {
                    return (
                        <div className="row">
                            <SectionSpinner />
                        </div>
                    )
                }
            })()}
        </div>
    )
}

export default connect(
    (state, props) => ({
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
    }),
    {
        changeCaseSubtab,
        setPreviousCaseSubtab,
        selectMember,
        selectPermission,
        selectResponsesDetail,
        selectPaymentsDetail,
    }
)(CaseMembers)
