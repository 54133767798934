import { createSelector } from 'reselect'


const stateSelector = state => state.caseDetailView.mailings.other

export const getSelectedMailingID = createSelector(
	[stateSelector],
	state => state.selectedMailingID
)

export const getFetchingMailingActivities = createSelector(
	[stateSelector],
	state => state.fetchingMailingActivities
)

export const getFetchingDocuments = createSelector(
	[stateSelector],
	state => state.fetchingMailingDocuments
)

export const getFetchingGeneral = createSelector(
	[stateSelector],
	state => state.fetchingMailingGeneral
)

export const getFetchingRecipients = createSelector(
	[stateSelector],
	state => state.fetchingMailingRecipients
)

// Everything below this comment should not live inside the view reducer and
// needs to move to mailings state, ideally in separate reducers for each, with
// their own actions and selectors
export const getMailingsActivities = createSelector(        
        [stateSelector],
        state => state.caseMailingsActivities
)

export const getMailingsDocuments = createSelector(
	[stateSelector],
	state => state.caseMailingsDocuments
)

export const getMailingsGeneral = createSelector(
	[stateSelector],
	state => state.caseMailingsGeneral
)

export const getMailingsRecipients = createSelector(
	[stateSelector],
	state => state.caseMailingsRecipients
)