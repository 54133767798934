import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import ResponseDatePicker from 'react/features/case-detail/content/responses/date-picker'

const ResponseHeaderContent = ({ caseID }) => {   
    return (
        <div className="row align-items-center">
            <div className="col-md-8 col-sm-8 col-lg-8 col-12 d-flex">
                <ResponseDatePicker />
            </div>            
        </div>
    )
}
export default ResponseHeaderContent
