import React, { Component, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../../components/outer-header';
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png';
import { Link } from 'react-router-dom';
import PageLoader from '../../components/page-loader';
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../global';
import { simplurisFetch } from 'helpers'
import { appMode, useRequestAccessInsteadOfSignup } from '../../../api-config'
import { setTimeout } from 'timers';
import ButtonLoader from '../../components/button-loader';

function ReportUnSubscribePage({ match }) {
    const [isViewReportDetailScreen, setIsViewReportDetailScreen] = useState(true);
    const [showPageLoaderForViewReportDetailScreen, setShowPageLoaderForViewReportDetailScreen] = useState(false);


    const [reportTitle, setreportTitle] = useState('');
    const [reportOfficialCaseName, setreportOfficialCaseName] = useState('');
    const [reportGeneratedDate, setreportGeneratedDate] = useState('');
    const [fileName, setfileName] = useState('');
    const [secureFileForCaseOfficialName, setSecureFileForCaseOfficialName] = useState('');
    const [secureFileSentDate, setSecureFileSentDate] = useState('');

    const [isShowConfirmationMewssage, setIsShowConfirmationMewssage] = useState(false);

    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [redirectToLoginPage, setRedirectToLoginPage] = useState(false);


    useEffect(() => {
        AnlyticsPageLog("Report Unsubscribe page");

        getEmailDetailsForReportUnsubscribe(match.params.reportCode);

    }, [])

    const [ErrorMessage, setErrorMessage] = useState('');

    const getEmailDetailsForReportUnsubscribe = (reportCode) => {

        setRedirectToLoginPage(false);
        setShowPageLoaderForViewReportDetailScreen(true);
        simplurisFetch({ url: `auth/getreportdetailsforreportunsubscribeusingreportcode/${reportCode}` }).then((result) => {

            setShowPageLoaderForViewReportDetailScreen(false);
            if (result.resultCode !== "ERROR") {

                if (result.resultObject != undefined) {
                    localStorage.setItem(global.PlatformPersonIDForReportUnsubscribe, result.resultObject.platformPersonID);
                    localStorage.setItem(global.ReportIDForReportUnsubscribe, result.resultObject.reportID);
                    localStorage.setItem(global.CaseIDForReportPDF, result.resultObject.caseID);
                    localStorage.setItem(global.OfficialCaseName, result.resultObject.officialCaseName);
                    localStorage.setItem(global.ReportTitle, result.resultObject.title);
                    localStorage.setItem(global.ReportCreatedDate, result.resultObject.createdDate);

                    setreportTitle(result.resultObject.title);
                    setreportOfficialCaseName(result.resultObject.officialCaseName);
                    setreportGeneratedDate(result.resultObject.createdDate);
                }
                else {
                    setRedirectToLoginPage(true);
                }
            }
        }).catch(e => {
            setShowPageLoaderForViewReportDetailScreen(false);
            console.error("Error in getemaildetailsforreportviewerusingreportcode for admin", e, e.errorObject);
        });
    }


    const reportUnsubscribe = () => {

        setShowButtonLoader(true);
        var data = {};
        data.ReportSubscriptionID = 0;
        data.CaseID = parseInt(localStorage.getItem(global.CaseIDForReportPDF));
        data.ReportID = parseInt(localStorage.getItem(global.ReportIDForReportUnsubscribe));
        data.PlatformPersonID = parseInt(localStorage.getItem(global.PlatformPersonIDForReportUnsubscribe));
        data.IsSubscribeReport = false;
        data.IsReportUnsubscribeFromEmailLink = true;

        simplurisFetch({
            url: "cases/reportissubscribed", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {

            setShowButtonLoader(false);
            if (data.resultCode !== "ERROR") {

                localStorage.removeItem(global.PlatformPersonIDForReportUnsubscribe)
                localStorage.removeItem(global.ReportIDForReportUnsubscribe)
                localStorage.removeItem(global.CaseIDForReportPDF)
                localStorage.removeItem(global.OfficialCaseName)
                localStorage.removeItem(global.ReportTitle)
                localStorage.removeItem(global.ReportCreatedDate)

                setIsShowConfirmationMewssage(true);
            }
        }).catch(e => {
            setShowButtonLoader(false);
            console.error('Error in saveIsSubscribedReportByUser', e, e.errorObject);
        });
    }

    return (
        <>
            {
                showPageLoaderForViewReportDetailScreen ? <PageLoader /> :
                    <div className="layout-column bg-white">
                        <>
                            <Header page="sign-in"
                                isViewReportDetailScreen={isViewReportDetailScreen}
                                showPageLoaderForViewReportDetailScreen={showPageLoaderForViewReportDetailScreen}
                                reportTitle={reportTitle}
                                reportOfficialCaseName={reportOfficialCaseName}
                                reportGeneratedDate={reportGeneratedDate}
                            />
                            <div className="d-flex flex-column flex">
                                <div>
                                    <div className="p-3">
                                        <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                                            <div className="row no-gutters">
                                                <div className="col-md-5 r-l">
                                                    <div className={`${isViewReportDetailScreen == true ? 'p-md-4 p-xl-4 p-lg-4' : 'p-4'} d-flex flex-column`}>
                                                        <div className="d-block">
                                                            <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right 27Mar2020 01:26 PM">
                                                                <img src={BrandmarkLogo} className="w-50" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    redirectToLoginPage ?
                                                        <>
                                                            <div className="col-md-6 d-flex justify-content-center align-self-center" id="content-body">
                                                                <div className="p-4">

                                                                    <h4>
                                                                        You are not subscribe for this report.
                                </h4>

                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-md-6 p-4" id="content-body">
                                                                {
                                                                    isShowConfirmationMewssage ? <>
                                                                        <div className="py-4 px-1">
                                                                            <h4>
                                                                                Your report subscription has been cancelled for this case.
                                                                                You will no longer receive this report via email. Thank you.
                                </h4>
                                                                        </div>
                                                                    </> :
                                                                        <>
                                                                            <div className="login-header-section mb-2 SimplurisFontSize16 SimplurisFontWeight500">
                                                                                Click confirm below to remove the subscription to this report for this specific case,
                                                ({reportOfficialCaseName}). This will not affect other report subscriptions you may have in place.
                                            </div>

                                                                            <button type="button" disabled={showButtonLoader} onClick={() => reportUnsubscribe()} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor mb-1 btn-light py-3 px-4 SimplurisFontSize14 SimplurisFontWeight400">
                                                                                {
                                                                                    showButtonLoader && (
                                                                                        <ButtonLoader />
                                                                                    )
                                                                                }
                                                                                {showButtonLoader && <span></span>}
                                                                                {!showButtonLoader && <span>Confirm Subscription Cancellation</span>}
                                                                            </button>
                                                                            <Link to={"/sign-in"} className="SimplurisCancelButtonRequestCall btn rounded-0 SimplurisWhiteBackgroundButton mb-1 ml-2 ml-md-0 ml-lg-0 ml-xl-2 btn-light py-3 px-4 SimplurisFontSize14 SimplurisFontWeight400">Nevermind</Link>
                                                                        </>
                                                                }

                                                            </div>
                                                        </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
            }
        </>
    )

}

export default connect(null)(ReportUnSubscribePage)

class ErrorPlacement extends Component {
    render() {
        if (this.props.items != "") {
            return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
                <strong>Login failed. </strong>   {this.props.items}
            </div>
        }
        else {
            return <></>
        }
    }
}
