import React, { Component, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../../components/outer-header';
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png';
import { Link } from 'react-router-dom';
import PageLoader from '../../components/page-loader';
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../global';
import { simplurisFetch } from 'helpers'
import { signIn } from 'state/sign-in/actions'
import { appMode, useRequestAccessInsteadOfSignup,RedirectMainURL,GoogleClientID } from '../../../api-config'
import { setTimeout } from 'timers';
//import GoogleLogin from 'react-google-login';


const LoginError = () => <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert"> <strong>Login failed. </strong> Email or password was not correct.</div>

function SignInPage({ match, signIn }) {
    const [isSendBoxModeInvalidCodeError, setIsSendBoxModeInvalidCodeError] = useState(false);
    const [isSendBoxModeURLBlankCodeError, setIsSendBoxModeURLBlankCodeError] = useState(false);
    const [isViewReportDetailScreen, setIsViewReportDetailScreen] = useState(false);
    const [isViewFileDetailScreen, setIsViewFileDetailScreen] = useState(false);
    const [showPageLoaderForViewReportDetailScreen, setShowPageLoaderForViewReportDetailScreen] = useState(false);
    const [showPageLoaderForViewFileDetailScreen, setShowPageLoaderForViewFileDetailScreen] = useState(false);


    const [reportTitle, setreportTitle] = useState('');
    const [reportOfficialCaseName, setreportOfficialCaseName] = useState('');
    const [reportGeneratedDate, setreportGeneratedDate] = useState('');
    const [fileName, setfileName] = useState('');
    const [secureFileForCaseOfficialName, setSecureFileForCaseOfficialName] = useState('');
    const [secureFileSentDate, setSecureFileSentDate] = useState('');
    const [isAccessReportQuickView, setIsAccessReportQuickView] = useState(false);
    const [isUserAlreadyLoggedInOrNot, setIsUserAlreadyLoggedInOrNot] = useState(false);

    if(RedirectMainURL !== ''){
        window.open(RedirectMainURL,"_self");
    }

    useEffect(() => {
        AnlyticsPageLog("Login page");

        setIsAccessReportQuickView(false);

        if (match.path.indexOf("report") > -1) {
            setIsViewReportDetailScreen(true);
            getEmailDetailsForReportviewer(match.params.reportCode);
        }

        if (match.path.indexOf("secure-file-transfer") > -1) {
            setIsViewFileDetailScreen(true);
            getFileDetails(match.params.fileCode);
        }

        if (appMode == "sandbox") {

            var urlCode = match.params.code;

            if (urlCode != undefined) {
                setEmail("testdrive@simpluris.com");
                setPwd(urlCode);

                localStorage.setItem(global.TESTDRIVEURLCODE, urlCode);
            }
            else {
                localStorage.removeItem(global.TESTDRIVEURLCODE);
                //it is ok if there's no code in the querystring... just let them try to login to the sandbox with it manually in that case.
                //setIsSendBoxModeURLBlankCodeError(true);
            }
        }
        else {
            setEmail('');
            setPwd('');
        }
    }, [])


    const isSandbox = appMode === "sandbox";
    let requestAccessOrSignUpLink
    if (useRequestAccessInsteadOfSignup) {
        if (isSandbox) {
            requestAccessOrSignUpLink = <a href={"https://livecase.simpluris.com/request-access"}>Request Access to LiveCase</a>
        } else {
            requestAccessOrSignUpLink = <Link to={"/request-access"}>Request Access</Link>
        }
    } else {
        if (isSandbox) {
            requestAccessOrSignUpLink = <Link to={"/sign-up"}>Sign Up for a Sandbox Account</Link>
        } else {
            requestAccessOrSignUpLink = <Link to={"/sign-up"}>Sign Up</Link>
        }
    }
    let forgotPasswordLink
    if (isSandbox) {
        forgotPasswordLink = <Link to={""} >Forgot password?</Link>
    } else {
        forgotPasswordLink = <Link to={"/forgot-password"} >Forgot password?</Link>
    }


    const [name, setName] = useState("");
    const [email1, setEmail1] = useState("");
    const [url, setURL] = useState("");

  const responseSuccessGoogle  = (response) =>{

    console.log(response);
    var ssoData = {};
    ssoData.Email = response.profileObj.email;
    ssoData.FirstName = response.profileObj.givenName;
    ssoData.LastName = response.profileObj.familyName;
    ssoData.ProfilePicURL = response.profileObj.imageUrl;
    ssoData.SSOTokenID = response.profileObj.googleId;
    ssoData.isRequestAccess = true;
    ssoData.isRequestAccessTestDrive = false;
    ssoData.AccountRequiresApproval = true;
    ssoData.SourceLink = window.location.href;

    setShowPageLoader(true);

    simplurisFetch({
        url: "ssoauth", method: 'POST', body: JSON.stringify(ssoData)
    }).then((data) => {

        setShowPageLoader(false);
        
        if (!IsNullOrEmptyString(data.idToken) && data.idToken != undefined) {
            localStorage.setItem(global.CaseID, (data.cases.length > 0 ? data.cases[0].caseID : ''));
            localStorage.setItem(global.TopTeamMemberPersonID, data.topTeamMemberPersonID);
            localStorage.setItem(global.TopMailingID, data.topMailingID);
            localStorage.setItem(global.TopCaseMemberPersonID, data.topCaseMemberPersonID);

            localStorage.setItem(global.AccessTokenKey, (!IsNullOrEmptyString(data.accessToken) ? data.accessToken : ''));
            localStorage.setItem(global.AuthTokenKey, (!IsNullOrEmptyString(data.idToken) ? data.idToken : ''));
            localStorage.setItem(global.FirstName, (!IsNullOrEmptyString(data.firstName) ? data.firstName : ''));
            localStorage.setItem(global.LastName, (!IsNullOrEmptyString(data.lastName) ? data.lastName : ''));
            localStorage.setItem(global.ProfilePicURL, (!IsNullOrEmptyString(data.profilePicURL) ? data.profilePicURL : ''));
            localStorage.setItem(global.CaseRoleTitle, (!IsNullOrEmptyString(data.caseRoleTitle) ? data.caseRoleTitle : ''));
            localStorage.setItem(global.PersonID, (!IsNullOrEmptyString(data.platformPersonID) ? data.platformPersonID : ''));

            localStorage.setItem(global.DboPersonID, (!IsNullOrEmptyString(data.dboPersonID) ? data.dboPersonID : ''));
            localStorage.setItem(global.PersonMapID, (!IsNullOrEmptyString(data.personMapID) ? data.personMapID : ''));
            localStorage.setItem(global.Email, (!IsNullOrEmptyString(data.email) ? data.email : ''));
            localStorage.setItem(global.PhoneNumber, (!IsNullOrEmptyString(data.phoneNumber) ? data.phoneNumber : ''));
            localStorage.setItem(global.PhoneNumber2, (!IsNullOrEmptyString(data.phoneNumber2) ? data.phoneNumber2 : ''));
            localStorage.setItem(global.PersonFax, (!IsNullOrEmptyString(data.personFax) ? data.personFax : ''));
            localStorage.setItem(global.Title, (!IsNullOrEmptyString(data.title) ? data.title : ''));
            localStorage.setItem(global.SchemaName, (!IsNullOrEmptyString(data.schemaName) ? data.schemaName : ''));
            localStorage.setItem(global.LastAgreedToTerms, (!IsNullOrEmptyString(data.lastAgreedToTerms) ? data.lastAgreedToTerms : ''));
            localStorage.setItem(global.IsSysAdmin, data.isSysAdmin);

            if (appMode == "sandbox") {
                setRedirectToHomePage(true);
            }
            else {
                setRedirectToTermsConditionsPage(true);
            }

            window.analytics.identify(localStorage.getItem(global.PersonMapID), {
                email: localStorage.getItem(global.Email)
            });
        }
        else {
            setLoginError(true)
            setErrorMessage("Email or password was not correct.");
        }

    }).catch(e => {
        console.error('Error in sign in', e, e.errorObject)
        setShowPageLoader(false)
        setLoginError(true)
        setErrorMessage("Exception Google Login");
    });
    
  }

  const responseErrorGoogle = (response) =>{
    setLoginError(true)
    setErrorMessage("Invalid Google Account");
  }

    // let googlelogin = <GoogleLogin
    //                         clientId = {GoogleClientID}
    //                         buttonText ="Sign In with Google"
    //                         theme = 'dark'
    //                         onSuccess ={responseSuccessGoogle}
    //                         onFailure ={responseErrorGoogle}
    //                         cookiePolicy ={'single_host_origin'}
    //                         />

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [emailReqError, setEmailReqError] = useState(false);
    const [emailInvalidError, setEmailInvalidError] = useState(false);
    const [pwdError, setPwdError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [redirectToHomePage, setRedirectToHomePage] = useState(false);
    const [redirectToTermsConditionsPage, setRedirectToTermsConditionsPage] = useState(false);
    const [redirectToDashBoard, setRedirectToDashBoard] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');


    const [emailForTestMode, setEmailForTestMode] = useState('');
    const [passwordForTestMode, setPasswordForTestMode] = useState('');
    const [emailReqErrorForTestMode, setEmailReqErrorForTestMode] = useState(false);
    const [emailInvalidErrorForTestMode, setEmailInvalidErrorForTestMode] = useState(false);
    const [passwordErrorForTestMode, setPasswordErrorForTestMode] = useState(false);
    const [passwordInvalidErrorForTestMode, setPasswordInvalidErrorForTestMode] = useState(false);

    //arrow functions allow us to use 'this' contextually properly ... SignInClick = () => {
    const SignInClick = async () => {
        const isValidEmail = global.EmailRegex.test(email);
        //const isValidEmail = "valid";//for some reason, this email validation is not working for me...
        //! covers all possible nulls/empty strings        
        if (!email || !pwd || !isValidEmail) {
            if (!email) {
                setEmailReqError(true);
            }

            if (email && !isValidEmail) {
                setEmailInvalidError(true);
            }

            if (!pwd) {
                setPwdError(true);
            }
            return;
        }


        var loginData = {};
        loginData.Username = email;
        loginData.Password = pwd;

        setShowPageLoader(true);

        try {
            const data = await signIn({ loginData })

            setShowPageLoader(false);

            if (!IsNullOrEmptyString(data.idToken) && data.idToken != undefined) {
                // We need to get at least on caseID for api status page.

                localStorage.setItem(global.CaseID, (data.cases.length > 0 ? data.cases[0].caseID : ''));
                localStorage.setItem(global.TopTeamMemberPersonID, data.topTeamMemberPersonID);
                localStorage.setItem(global.TopMailingID, data.topMailingID);
                localStorage.setItem(global.TopCaseMemberPersonID, data.topCaseMemberPersonID);

                localStorage.setItem(global.AccessTokenKey, (!IsNullOrEmptyString(data.accessToken) ? data.accessToken : ''));
                localStorage.setItem(global.AuthTokenKey, (!IsNullOrEmptyString(data.idToken) ? data.idToken : ''));
                localStorage.setItem(global.FirstName, (!IsNullOrEmptyString(data.firstName) ? data.firstName : ''));
                localStorage.setItem(global.LastName, (!IsNullOrEmptyString(data.lastName) ? data.lastName : ''));
                localStorage.setItem(global.ProfilePicURL, (!IsNullOrEmptyString(data.profilePicURL) ? data.profilePicURL : ''));
                localStorage.setItem(global.CaseRoleTitle, (!IsNullOrEmptyString(data.caseRoleTitle) ? data.caseRoleTitle : ''));

                // This was updated from personID to platformPersonID in the response. When these get moved to redux state
                // we should also update the variable name to match
                localStorage.setItem(global.PersonID, (!IsNullOrEmptyString(data.platformPersonID) ? data.platformPersonID : ''));

                localStorage.setItem(global.DboPersonID, (!IsNullOrEmptyString(data.dboPersonID) ? data.dboPersonID : ''));
                localStorage.setItem(global.PersonMapID, (!IsNullOrEmptyString(data.personMapID) ? data.personMapID : ''));
                localStorage.setItem(global.Email, (!IsNullOrEmptyString(data.email) ? data.email : ''));
                localStorage.setItem(global.PhoneNumber, (!IsNullOrEmptyString(data.phoneNumber) ? data.phoneNumber : ''));
                localStorage.setItem(global.PhoneNumber2, (!IsNullOrEmptyString(data.phoneNumber2) ? data.phoneNumber2 : ''));
                localStorage.setItem(global.PersonFax, (!IsNullOrEmptyString(data.personFax) ? data.personFax : ''));
                localStorage.setItem(global.Title, (!IsNullOrEmptyString(data.title) ? data.title : ''));
                localStorage.setItem(global.SchemaName, (!IsNullOrEmptyString(data.schemaName) ? data.schemaName : ''));
                localStorage.setItem(global.LastAgreedToTerms, (!IsNullOrEmptyString(data.lastAgreedToTerms) ? data.lastAgreedToTerms : ''));
                localStorage.setItem(global.IsSysAdmin, data.isSysAdmin);
                //props.history.push('/landing', { caseActivitiesDetail: data.caseActivities, caseDashboardDetail: data.caseProjectsSimples });
                //props.history.push('/home-logged-in-case');
                //Permissions
                var viewDashBoardpermission;
                var permissions = data.permissionCodes;
                if(permissions.length > 0 ){
                    viewDashBoardpermission =permissions.find(
                        (permissionCode) => permissionCode === 'LC_CASEDASHBOARD_VIEW_CASE_ACTIVITY'
                    )
                } 
                localStorage.setItem(global.viewDashBoardpermission,(!IsNullOrEmptyString(viewDashBoardpermission) ? viewDashBoardpermission : ''));

                if (appMode == "sandbox") {
                    if (!IsNullOrEmptyString(localStorage.getItem(global.viewDashBoardpermission))){
                        setRedirectToHomePage(true); 
                    }
                    else{
                        setRedirectToDashBoard(true); 
                    }
                }
                else {
                    setRedirectToTermsConditionsPage(true);
                }

                window.analytics.identify(localStorage.getItem(global.PersonMapID), {
                    email: localStorage.getItem(global.Email)
                });
            }
            else if (data.resultCode == "LOGINLOCKDOWN") {
                setLoginError(true)
                setErrorMessage(data.resultMessage);
            }
            else {
                setLoginError(true)
                setErrorMessage("Email or password was not correct.");
            }
        } catch (e) {
            console.error('Error in sign in', e, e.errorObject)
            setShowPageLoader(false)
            setLoginError(true)
            setErrorMessage("Email or password was not correct.");
        }
    }

    const getEmailDetailsForReportviewer = (reportCode) => {

        setShowPageLoaderForViewReportDetailScreen(true);
        simplurisFetch({ url: `auth/getemaildetailsforreportviewerusingreportcode/${reportCode}` }).then((result) => {

            setShowPageLoaderForViewReportDetailScreen(false);

            if (result.resultCode !== "ERROR") {

                localStorage.setItem(global.ReportPDFURL, result.resultObject.url);
                localStorage.setItem(global.CaseIDForReportPDF, result.resultObject.caseID);
                localStorage.setItem(global.OfficialCaseName, result.resultObject.officialCaseName);
                localStorage.setItem(global.ReportTitle, result.resultObject.title);
                localStorage.setItem(global.CreatedReportPersonID, result.resultObject.platformPersonID);
                localStorage.setItem(global.ReportCreatedDate, result.resultObject.createdDate);

                setreportTitle(result.resultObject.title);
                setreportOfficialCaseName(result.resultObject.officialCaseName);
                setreportGeneratedDate(result.resultObject.createdDate);


                if (parseInt(result.resultObject.platformPersonID) == parseInt(localStorage.getItem(global.PersonID))) {
                    setIsUserAlreadyLoggedInOrNot(true);
                }
                else {
                    if (result.resultObject.isReportSubscriptionLinkExpire) {
                        setEmail(result.resultObject.email);
                    }
                    else {

                        setIsAccessReportQuickView(true);
                    }
                }
            }
        }).catch(e => {
            setShowPageLoaderForViewReportDetailScreen(false);
            console.error("Error in getemaildetailsforreportviewerusingreportcode for admin", e, e.errorObject);
        });
    }

    const getFileDetails = (fileCode) => {
        setShowPageLoaderForViewFileDetailScreen(true);
        simplurisFetch({ url: `auth/getsecurefiledetail/${fileCode}` }).then((result) => {
            setShowPageLoaderForViewFileDetailScreen(false);
            if (result.resultCode !== "ERROR") {
                //setEmail(result.resultObject.email);
                localStorage.setItem(global.SecureFileURL, result.resultObject.url);
                localStorage.setItem(global.CaseIDForSecureFile, result.resultObject.caseID);
                localStorage.setItem(global.SecureFileIDForSecureFile, result.resultObject.secureFileID);
                localStorage.setItem(global.SecureFileForCaseOfficialName, result.resultObject.officialCaseName);
                localStorage.setItem(global.SecureFileName, result.resultObject.fileName);
                localStorage.setItem(global.SecureFileSentDate, result.resultObject.sentDate);

                setfileName(result.resultObject.fileName);
                setSecureFileForCaseOfficialName(result.resultObject.officialCaseName);
                setSecureFileSentDate(result.resultObject.sentDate);
            }
        }).catch(e => {
            setShowPageLoaderForViewFileDetailScreen(false);
            console.error("Error in file details for admin", e, e.errorObject);
        });
    }

    const keyPressed = (event) => {
        if (event.key === "Enter") {
            SignInClick()
        }
    }

    return (
        <>
            {isUserAlreadyLoggedInOrNot ? <Redirect to={{ pathname: `/cases/detail/${localStorage.getItem(global.CaseIDForReportPDF)}`, state: [{ reportID: 0, TabType: "ReportViewerDetail" }] }} /> : null}
            {isAccessReportQuickView && <Redirect to="/viewreport" />}
            {redirectToHomePage ? <Redirect to="/home-logged-in-case" /> : null}
            {redirectToDashBoard ? <Redirect to="/cases" /> : null}
            {redirectToTermsConditionsPage ? <Redirect to="/terms-conditions" /> : null}
            <div className="layout-column bg-white">

                <Header page="sign-in"
                    isViewReportDetailScreen={isViewReportDetailScreen}
                    showPageLoaderForViewReportDetailScreen={showPageLoaderForViewReportDetailScreen}
                    showPageLoaderForViewFileDetailScreen={showPageLoaderForViewFileDetailScreen}
                    isViewFileDetailScreen={isViewFileDetailScreen}
                    reportTitle={reportTitle}
                    reportOfficialCaseName={reportOfficialCaseName}
                    reportGeneratedDate={reportGeneratedDate}
                    fileName={fileName}
                    secureFileForCaseOfficialName={secureFileForCaseOfficialName}
                    secureFileSentDate={secureFileSentDate}
                />

                <div className="d-flex flex-column flex">
                    <div>
                        <div className="p-3">
                            <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                                <div className="row no-gutters">
                                    <div className="col-md-5 r-l">
                                        <div className={`${isViewReportDetailScreen == true || isViewFileDetailScreen == true ? 'p-md-4 p-xl-4 p-lg-4' : 'p-4'} d-flex flex-column`}>
                                            <div className="d-block">
                                                <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right 27Mar2020 01:26 PM">
                                                    <img src={BrandmarkLogo} className={`w-50 ${isViewReportDetailScreen == true || isViewFileDetailScreen == true ? 'SimplurisHideLogoForMobileScreen' : ''}`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6" id="content-body">
                                        <div className="p-4">

                                            <div className="login-header-section">
                                                {(
                                                    () => {
                                                        if (isSandbox) {
                                                            return <h4 className="SimplurisFontWeight400 13-01-2020_1">Login to the LiveCase Demo</h4>
                                                        }
                                                        else if (isViewReportDetailScreen) {
                                                            return <h4 className="SimplurisFontWeight400 13-01-2020_1">Login to the LiveCase to View the Report</h4>
                                                        }
                                                        else if (isViewFileDetailScreen) {
                                                            return <h4 className="SimplurisFontWeight400 13-01-2020_1">Login to the LiveCase to Download the File</h4>
                                                        }
                                                        else {
                                                            return <h4 className="SimplurisFontWeight400 24Apr2020_7:29">Login to LiveCase</h4>
                                                        }
                                                    }
                                                )()}

                                                <p>
                                                    <small className="text-muted">
                                                        Need to create a new account? <br />
                                                        {requestAccessOrSignUpLink}
                                                    </small>
                                                    <br />
                                                    <big>
                                                        {/* {googlelogin} */}
                                                    </big>                                                    
                                                </p>
                                            </div>

                                            {loginError ? <ErrorPlacement items={ErrorMessage} /> : null}

                                            <form id="frmLogin" role="form" action="#">

                                                <div className={`form-group mt-2 ${emailReqError || emailInvalidError ? 'has-error' : ''}`}>
                                                    <label>Email Address</label>
                                                    <input value={email} onChange={e => (setEmail(e.target.value), setEmailReqError(false), setEmailInvalidError(false))} type="email" id="txtEmail" name="Email" className={`form-control SimplurisNewCaseMemebersInputBox ${isViewReportDetailScreen == true || isViewFileDetailScreen == true ? 'SimplurisLoginScreenTextBoxForReportAndFileWidth' : ''}`} />
                                                    {emailReqError ? <span>Please enter email</span> : null}
                                                    {emailInvalidError ? <span>Please enter valid email</span> : null}
                                                </div>
                                                <div className={`form-group mt-2 ${pwdError ? 'has-error' : ''}`}>
                                                    <label>Password</label>
                                                    <input type="password" value={pwd} onChange={e => (setPwd(e.target.value), setPwdError(false))} onKeyPress={keyPressed} id="txtPassword" name="Password" className={`form-control SimplurisNewCaseMemebersInputBox ${isViewReportDetailScreen == true || isViewFileDetailScreen == true ? 'SimplurisLoginScreenTextBoxForReportAndFileWidth' : ''}`} />
                                                    {pwdError ? <span>Please enter password</span> : null}
                                                </div>


                                                <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 py-3" onClick={SignInClick}>Continue</button>

                                                <p className="mt-3">
                                                    <small deployed="1-7-2020 at 9:57am pacific" className="text-muted">
                                                       {forgotPasswordLink}
                                                    </small>
                                                </p>
                                            </form>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showPageLoader ? <PageLoader /> : null}
                {showPageLoaderForViewReportDetailScreen ? <PageLoader /> : null}
                {showPageLoaderForViewFileDetailScreen ? <PageLoader /> : null}

            </div>
        </>
    )

}

export default connect(null, { signIn })(SignInPage)

class ErrorPlacement extends Component {
    render() {
        if (this.props.items != "") {
            return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
                <strong>Login failed. </strong>   {this.props.items}
            </div>
        }
        else {
            return <></>
        }
    }
}
