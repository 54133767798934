import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { ReactComponent as Circle } from 'icons/circle.svg'
import { teal, lightGray, primaryBlue, red } from 'shared/colors'

const Container = styled.div`
  padding: 20px;
`
const Spacer = styled.div`
  height: 10px;
`
const SectionContainer = styled.div`
  flex: 1 1 0%;
padding-bottom: 10px;
`
const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Flex = styled.div`
  display: flex;
position: relative;

`
const StyledCircle = styled(Circle)`
  height: 20px;
  width: 20px;
  ellipse {
    stroke: ${props => (props.status.toLowerCase() == 'sent' || props.status.toLowerCase() == 'forwarded' ? primaryBlue : props.status.toLowerCase() == 'returned' ? red : teal)};
  }
`
const Date = styled.div`
  color: ${lightGray};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
`
const Status = styled.div`
  color:${props => (props.mstatus.toLowerCase() == 'sent' || props.mstatus.toLowerCase() == 'forwarded' ? primaryBlue : props.mstatus.toLowerCase() == 'returned' ? red : teal)};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
`

const Line = styled.div`
  border: 1px solid #4a90e2;
  opacity: 0.96;
    height: 29px;
    position: absolute;
    left: 9px;
    top: 18px;
`

const LableHistory = styled.div`
color: #647787;
 font-size: 12px;
  font-weight: 700;
`

const ScanTimeSectionAndCityName = styled.div`
color: #647787;
  font-size: 14px;
  font-weight: 400;
`

const MailingHistory = ({ mailHistoryDetail }) => {    
    // Pulling "history" for just the selected mailing. When the API starts
    // grouping all jumps, this needs to be updated to grab all jumps for the
    // particular piece of mail
    //const { scanDate, scanTime, mailingHistoryTypeTitle, city, state } = mailHistoryDetail.trackingItems

    //const history = [
    //    {
    //        scanDate,
    //        scanTime,
    //        mailingHistoryTypeTitle,
    //        city,
    //        state,
    //    },
    //]

    return (
        <Container>            
            <LableHistory>History</LableHistory>
            <Spacer />
            <div>
                {mailHistoryDetail.trackingItems != undefined && mailHistoryDetail.trackingItems.map((item, i) => {                   
                    const momentObj = moment(item.scanDate)
                    return (
                        <Flex key={i}>
                            <StyledCircle status={item.mailingHistoryTypeTitle} />
                            {mailHistoryDetail.trackingItems.length == (i + 1) ? <></> : <Line></Line>}
                            <SectionContainer>
                                <Section>
                                    <Flex>
                                        <Date>{momentObj.format('ddd, l')}</Date>
                                    </Flex>

                                    {(
                                        () => {
                                            if (mailHistoryDetail.trackingItems.length == (i + 1)) {
                                                return <Status mstatus={item.mailingHistoryTypeTitle}>
                                                    {item.mailingHistoryTypeTitle === 'Mailed'
                                                        ? 'FINAL'
                                                        : item.mailingHistoryTypeTitle}
                                                </Status>                                                
                                            }
                                            else
                                            {
                                                return <Status mstatus="IN TRANSIT">IN TRANSIT</Status>
                                            }
                                        }
                                    )()}

                                    
                                </Section>
                                <Section>
                                    <ScanTimeSectionAndCityName>{item.scanTime}</ScanTimeSectionAndCityName>
                                    <ScanTimeSectionAndCityName>
                                        {item.city}, {item.state}
                                    </ScanTimeSectionAndCityName>
                                </Section>
                            </SectionContainer>
                        </Flex>
                    )
                }
                )}
            </div>
        </Container>
    )
}

export default MailingHistory
