import React, { useEffect, useState} from 'react'
import logo from './logo-blue.png'
import { Link } from 'react-router-dom';
import { appMode } from '../../../api-config'
import { useRequestAccessInsteadOfSignup } from '../../../api-config'
import ReportImage from '../../../static/img/report_inverse.png'
import SecureFileImage from '../../../static/img/securefile_icon.png'
import { IsNullOrEmptyString} from '../../../global'

export default function Header(items) {    
    return <header id="header" className="page-header simplurisallcasesHeaderBackgroudColour bg-white" data-classname="bg-white">
        <div className="navbar navbar-expand-lg SimplurisNavbar pb-1">
            <a className={`d-lg-none i-con-h-a px-1  ${(items.page == "sign-up" || items.page == "sign-in" || items.page == "forgot-password" || items.page == "admin-account-activation" || items.page == "account-activation") ? 'd-none' : ''}`} data-toggle="modal" data-target="#aside" data-pjax-state="">
                <i className="i-con i-con-menu text-muted"></i>
            </a>
            <a href={"http://simpluris.com/"} className="navbar-brand" data-pjax-state="" target="_blank">
                <img src={logo} />
            </a>

            {
                (appMode != "sandbox") && (items.page != "not-allowed-to-case") &&
                <ul className="nav navbar-menu order-1 order-lg-2">

                    <li className="nav-item dropdown">
                        <Link to={"/sign-in"} className="btn btn-link SimplurisFontWeight400">LOGIN</Link>

                        {
                            appMode !== "sandbox" && !useRequestAccessInsteadOfSignup ?
                                <>
                                    <Link to={"/sign-up"} className="btn btn-link SimplurisFontWeight400">SIGN UP</Link>
                                </>
                                : <></>
                        }
                        {
                            useRequestAccessInsteadOfSignup &&
                            <Link to={"/request-access"} className="btn btn-link SimplurisFontWeight400">REQUEST ACCESS</Link>
                        }
                    </li>
                </ul>
            }
          
            
        </div>
        {
            (items.isViewReportDetailScreen == true && items.showPageLoaderForViewReportDetailScreen == false) &&
            (!IsNullOrEmptyString(items.reportTitle) && !IsNullOrEmptyString(items.reportOfficialCaseName) && !IsNullOrEmptyString(items.reportGeneratedDate))&&            
            <div class="SimplurisWidth45 m-xl-auto m-lg-auto m-md-auto pb-4 px-4 px-md-0 px-xl-0 px-lg-0">
                    <div class="card d-flex flex mb-0">
                        <div class="card-body SimpulrisMailingCardBody">
                            <div class="row">
                            <div class="col-2 pl-0 px-xl-2 px-lg-2">
                                <span class="SimplurisImageSquare r avatar">
                                    <img src={ReportImage} className="SimplurisAutoWidth" />
                                    </span>

                                </div>
                                <div class="col-md-10 col-sm-10 col-10 align-self-center pl-xl-0 pl-lg-3 pl-md-3 SimplurisPaddingLeft23">
								<div class="SimplurisMailingTitle text-danger">
                                    Link has been expired, Please login below to view the Report.
                                </div>
                                    <div class="SimplurisMailingTitle">
                                    {items.reportTitle}
                                    </div>
                                    <div class="SimplurisMailingSubTitle">
                                    {items.reportOfficialCaseName}
                                    </div>
                                    <div class="SimplurisMailingSubTitle">
                                        {new Intl.DateTimeFormat('en-GB', {
                                            month: 'long'
                                        }).format(new Date(items.reportGeneratedDate))} {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(items.reportGeneratedDate))}, {new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(new Date(items.reportGeneratedDate))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>                            
        }
        {
            items.isViewFileDetailScreen == true && items.showPageLoaderForViewFileDetailScreen == false &&
            (!IsNullOrEmptyString(items.fileName) && !IsNullOrEmptyString(items.secureFileForCaseOfficialName) && !IsNullOrEmptyString(items.secureFileSentDate)) &&            
            
            <div class="SimplurisWidth45 m-xl-auto m-lg-auto m-md-auto pb-4 px-4 px-md-0 px-xl-0 px-lg-0">
                    <div class="card d-flex flex mb-0">
                        <div class="card-body SimpulrisMailingCardBody">
                            <div class="row">
                            <div class="col-2 pl-0 px-xl-2 px-lg-2">
                                <span class="SimplurisImageSquare r avatar">
                                    <img src={ReportImage} className="SimplurisAutoWidth" />
                                </span>

                            </div>
                            <div class="col-md-10 col-sm-10 col-10 align-self-center pl-xl-0 pl-lg-3 pl-md-3 SimplurisPaddingLeft23">
                                    <div class="SimplurisMailingTitle">
                                        {items.fileName}
                                    </div>
                                    <div class="SimplurisMailingSubTitle">
                                        {items.secureFileForCaseOfficialName}
                                    </div>
                                    <div class="SimplurisMailingSubTitle">
                                        {new Intl.DateTimeFormat('en-GB', {
                                            month: 'long'
                                        }).format(new Date(items.secureFileSentDate))} {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(items.secureFileSentDate))}, {new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(new Date(items.secureFileSentDate))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>            
        }
    </header>
}
