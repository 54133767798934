import React, { useState, useEffect } from 'react';
import { simplurisFetch } from 'helpers'
import { Modal } from 'react-bootstrap';
import PageLoader from 'react/components/page-loader';
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import CreditCardPNG from '../../../../../static/img/creditcard.png'
import BankPNG from '../../../../../static/img/bank.png'
import RunReportTypeModalPopup from './run-report-type'
import ReportImage from '../../../../../static/img/report_inverse.png'

function ChooseReportTypeModalPopup(props) {        
    function CloseReportTypeModalPopup() {
        props.setShowChooseReportTypeModalPopup(false);
    }
    const [reportID, setReportID] = useState();
    const [reportType, setReportType] = useState();
    const [reportTitle, setReportTitle] = useState();
    const [reportCode, setReportCode] = useState();
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [showRunReportType, setShowRunReportType] = useState(false);
    const [flagForGetParamData, setFlagForGetParamData] = useState(false);

    const [allReportsData, setAllReportsData] = useState([]);

    function OpenRunReportTypeModalPopup(reportID, title, reportTitle, reportCode) {        
        AnlyticsPageLog("Click on Run Report Type Modal Popup from Choose Report Type Modal Popup");
        setReportID(reportID)
        setReportType(reportType)
        setReportTitle(title)
        setReportCode(reportCode)
        props.setShowChooseReportTypeModalPopup(false);
        setShowRunReportType(true);
        setFlagForGetParamData(true);
    }

    useEffect(() => {
        
        try {
            fetchAllReportsForGenerateReports();
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [])

    const fetchAllReportsForGenerateReports = async () => {
        simplurisFetch({
            url: `cases/getallreportsforgeneratereports`
        })
            .then(result => {
                if (result.resultCode == "SUCCESS") {                     
                    setAllReportsData(result.resultObject);
                }
            })
            .catch(e => {
                console.error("Error in fetch all reports for generate reports data ", e, e.errorObject);
            });
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseReportTypeModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle pl-3">Run a Report</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4 mb-2">
                    <div className="row pb-3">
                        <div className="col-12">
                            <p className="modal-title SimplurisFontSize19 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                Choose a Report to Run:
                            </p>
                        </div>
                    </div>
                    <hr className="m-0" />                    
                    {
                        
                        allReportsData.length > 0 ?
                            allReportsData.map((item, i) =>
                                <>
                                    <div className="row py-4 justify-content-center align-items-center">
                                        <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12 pr-0">
                                            <img src={ReportImage} />
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-9 col-xs-12 pl-3 pl-md-0 pl-xl-0 pl-lg-0">
                                            <label className="d-flex mb-0 SimplurisFontSize18 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                                {item.title}
                            </label>
                                            <label className="d-flex SimplurisFontSize14 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                                {item.description}
                            </label>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 text-left text-lg-right text-xl-right mt-md-2">
                                            <input type="button" onClick={() => OpenRunReportTypeModalPopup(item.reportID, item.title, item.reportType,item.reportCode)} name="Next" value="Next" className="btn text-white SimplurisEggBlueBackGroudColor SimplurisFontSize14 SimplurisFontWeight400 w-sm mb-1 btn-light py-3 rounded-0" />
                                        </div>
                                    </div>
                                    <hr className="m-0" />
                                </>
                            )
                            :
                            <div>No reports found</div>
                    }
                </Modal.Body>
            </Modal>
            <RunReportTypeModalPopup
                OpenModalPopup={showRunReportType}
                setShowRunReportType={setShowRunReportType}
                setShowChooseReportTypeModalPopup={props.setShowChooseReportTypeModalPopup}
                reportID={reportID}
                reportType={reportType}
                reportCode={reportCode}
                reportTitle={reportTitle}
                caseID={props.caseID}
                onTabChange={props.onTabChange}
                getReportsDetailComponent={props.getReportsDetailComponent}
                flagForGetParamData={flagForGetParamData}
                setFlagForGetParamData={setFlagForGetParamData}
                fetchCaseDataCounts={props.fetchCaseDataCounts} />
        </>
    )
}

export default ChooseReportTypeModalPopup
