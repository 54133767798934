import React from 'react'
import Brand from '../header/brand'
import Nav from './nav'
import StatusIconBar from '../header/status-icon-bar'

export default function Header() {

    return <header id="header" className="page-header box-shadow scroll-up bg-white sticky">
        <div className="navbar navbar-expand-lg header-nav SimplurisNavbar">
            <Brand />

            <Nav />

            <StatusIconBar />
        </div>
    </header>
}
