import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import GlobalImage from '../../../components/global-image-set';
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { labFeatures, appMode } from "../../../../api-config";
import { fetchThreads } from 'state/message-threads/actions';

function TeamMembers({
    data,
    getTeamMemberComponent,
    userPermissions,
    changeCaseSubtab,
    previousCaseSubtabs,
    setPreviousCaseSubtab,
    caseID,
    fetchThreads
}) {
    var viewTeamMemberPermission;

    if (Object.keys(userPermissions).length > 0) {
        viewTeamMemberPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_TEAM_VIEW_TEAM_MEMBERS'
        )
    }

    if (viewTeamMemberPermission && viewTeamMemberPermission.length <= 0) {
        // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
        changeCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
        setPreviousCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
    }

    return (
        <div>
            {
                <div className="d-flex flex row row-sm">
                    <TeamMemberComponent
                        items={data}
                        getTeamMemberComponent={getTeamMemberComponent}
                        userPermissions={userPermissions}
                        caseID={caseID}
                        fetchThreads={fetchThreads}
                    />
                </div>
            }{" "}
        </div>
    );
}

export default connect(
    (state, props) => ({
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
        threads: state.threads,
    }), { changeCaseSubtab, setPreviousCaseSubtab, fetchThreads, }
)(TeamMembers)

function TeamMemberComponent(data) {
    var addTeamMemberPermission;
    var callTeamMemberPermission;
    var chatWithTeamMemberPermission;
    var messageTeamMemberPermission;

    if (Object.keys(data.userPermissions).length > 0) {
        addTeamMemberPermission = data.userPermissions.find(
            (permissionCode) => permissionCode == 'LC_TEAM_ADD_TEAM_MEMBER'
        )
        callTeamMemberPermission = data.userPermissions.find(
            (permissionCode) => permissionCode == 'LC_TEAM_CALL_TEAM_MEMBER'
        )

        chatWithTeamMemberPermission = data.userPermissions.find(
            (permissionCode) => permissionCode === 'LC_TEAM_CHAT_WITH_TEAM_MEMBER'
        )

        messageTeamMemberPermission = data.userPermissions.find(
            (permissionCode) => permissionCode == 'LC_TEAM_MESSAGE_TEAM_MEMBER'
        )
    }

    const [openNewMessage, setOpenNewMessage] = useState(false);
    const [firstName, setFirstName] = useState();
    const [profileImage, setProfileImage] = useState();
    const [personMapID, setPersonMapID] = useState();
    const OpenNewMessage = (check, firstName, profileImage, personMapID) => {
        setOpenNewMessage(check);
        setFirstName(firstName);
        setProfileImage(profileImage);
        setPersonMapID(personMapID);

        var senderPlatformMapId = localStorage.getItem(global.PersonMapID);
        var recipientPlatformMapId = personMapID;
        var caseID = data.caseID;
        data.fetchThreads({ senderPlatformMapId, recipientPlatformMapId, caseID })
    };

    if (data.items != null && data.items.length > 0) {        
        return data.items.map(item => (
            <div className="col-md-4" key={item.dboPersonID}>
                <div className="card">
                    <div className="p-3 SimplurisTeamMemberColorShift">
                        <div className="d-flex align-items-center justify-content-around mt-2">
                            <a href={null} className="avatar mb-2 w-96 pointer" onClick={() => data.getTeamMemberComponent(item.caseID, item.dboPersonID)}>
                                <GlobalImage
                                    isSandbox={appMode == "sandbox" ? "true" : "false"}
                                    firstname={item.fullName}
                                    image={item.profilePicURL}
                                    email={item.personEmail}
                                    isTeam="true"
                                    dynamicClass="w-90 avatar rounded-circle bg-secondary-lt SimplurisFontSize26"
                                />
                            </a>
                        </div>
                        <div className="text-center mt-3 mb-4 pointer" onClick={() => data.getTeamMemberComponent(item.caseID, item.dboPersonID)}>
                            <span className="font-weight-500 SimplurisMouseRollOver">
                                {item.fullName}
                            </span>
                            <small className="d-block SimplurisMilestoneGridDescription SimplurisMouseRollOver">
                                {item.roleTitle}
                            </small>
                        </div>
                    </div>
                    <div className="row no-gutters text-center b-t">
                        <a href={null} onClick={() => data.getTeamMemberComponent(item.caseID, item.dboPersonID)}
                            className={`col py-3 b-l ${
                                chatWithTeamMemberPermission &&
                                    chatWithTeamMemberPermission.length > 0
                                    ? ""
                                    : "d-none"
                                }`}
                        >
                            <span className="SimplurisChatLable d-block SimplurisMouseRollOver">
                                Profile
              </span>
                        </a>

                        {
                            labFeatures && item.personMapID != localStorage.getItem(global.PersonMapID) ?
                                <a
                                    href={null}
                                    onClick={() =>
                                        OpenNewMessage(
                                            true,
                                            item.fullName,
                                            item.profilePicURL,
                                            item.personMapID
                                        )
                                    }
                                    className={`col py-3 ${
                                        messageTeamMemberPermission &&
                                            messageTeamMemberPermission.length > 0
                                            ? ""
                                            : "d-none"
                                        }`}
                                >
                                    <span className="SimplurisChatLable d-block SimplurisMouseRollOver">
                                        Message
              </span>
                                </a>
                            :null}
                    </div>
                </div>
            </div>
        ));
    } else {
        return (
            <div className="col-md-12">
                <div className="card d-flex flex">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center mb-2">
                                    <h4>No team members found.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
