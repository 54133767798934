import { simplurisFetch } from 'helpers'
export const FETCH_CASE_LIVE_REPORT = 'FETCH_CASE_LIVE_REPORT'
export const CHANGE_CASE_LIVE_REPORT_DATE_RANGE = 'CHANGE_CASE_LIVE_REPORT_DATE_RANGE'
export const FETCH_CASE_LIVE_REPORT_RESPONSE_SUMMARY = 'FETCH_CASE_LIVE_REPORT_RESPONSE_SUMMARY'
export const FETCH_CASE_LIVE_REPORT_DISTRIBUTION = 'FETCH_CASE_LIVE_REPORT_DISTRIBUTION'
export const FETCH_CASE_LIVE_REPORT_MAILINGHISTORY_SUMMARY = 'FETCH_CASE_LIVE_REPORT_MAILINGHISTORY_SUMMARY'

export const fetchLiveReport = ({ caseID }) => async dispatch => {
    try {
        const url = `cases/${caseID}/caselivereport`
        const { resultObject } = await simplurisFetch({ url })
        dispatch({
            type: FETCH_CASE_LIVE_REPORT,
            caseLiveReport: resultObject,
            caseID,
        })
    } catch (e) {
        console.error('Error in live report', e, e.errorObject)

        dispatch({
            type: FETCH_CASE_LIVE_REPORT,
            caseLiveReport: [],
            caseID,
        })
    }
}


export const changeLiveReportDateRange = dateRange => ({
    type: CHANGE_CASE_LIVE_REPORT_DATE_RANGE,
    dateRange,
})

export const fetchLiveReportResponseSummary = ({ caseID }) => async dispatch => {
    try {
        const url = `cases/${caseID}/livereportresponsesummary`
        const { resultObject } = await simplurisFetch({ url })
        dispatch({
            type: FETCH_CASE_LIVE_REPORT_RESPONSE_SUMMARY,
            caseLiveReportResponses: resultObject,
            caseID,
        })
    } catch (e) {
        console.error('Error in live report response summary ', e, e.errorObject)

        dispatch({
            type: FETCH_CASE_LIVE_REPORT_RESPONSE_SUMMARY,
            caseLiveReportResponses: [],
            caseID,
        })
    }
}

export const fetchLiveReportDistribution = ({ caseID }) => async dispatch => {
    try {
        const url = `cases/${caseID}/livereportdistribution`
        const { resultObject } = await simplurisFetch({ url })
        dispatch({
            type: FETCH_CASE_LIVE_REPORT_DISTRIBUTION,
            caseLiveReportDistribution: resultObject
        })
    } catch (e) {
        console.error('Error in live report distribution ', e, e.errorObject)

        dispatch({
            type: FETCH_CASE_LIVE_REPORT_DISTRIBUTION,
            caseLiveReportDistribution: []
        })
    }
}

export const fetchLiveReportMailingHistorySummary = ({ caseID }) => async dispatch => {
    try {
        const url = `cases/${caseID}/livereportmailinghistorysummary`
        const { resultObject } = await simplurisFetch({ url })
        dispatch({
            type: FETCH_CASE_LIVE_REPORT_MAILINGHISTORY_SUMMARY,
            caseLiveReportMailingHistory: resultObject,
            caseID,
        })
    } catch (e) {
        console.error('Error in live report mailing history summary ', e, e.errorObject)

        dispatch({
            type: FETCH_CASE_LIVE_REPORT_MAILINGHISTORY_SUMMARY,
            caseLiveReportMailingHistory: [],
            caseID,
        })
    }
}
