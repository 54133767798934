import * as React from 'react'
import { Doughnut } from 'react-chartjs-2'

const getDataForChart = (reachPresumedInitialReceived, reachRemailed, reachUndeliverable) => ({
    labels: [
        'Initial mailed, Presumed Delivery: ' + reachPresumedInitialReceived,
        'Remailed: ' + reachRemailed,
        'Undeliverable: ' + reachUndeliverable
    ],

    datasets: [
        {
            data: [reachPresumedInitialReceived, reachRemailed, reachUndeliverable],
            backgroundColor: ['#117eff', '#70b2ff', '#d0e5ff'],
        },
    ],
})

const options = {
    cutoutPercentage: 70,
    legend: { position: 'bottom' },
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                return data.labels[tooltipItem.index];
            }
        }
    }
}

const MailingsCharts = ({ mailings }) => {
    if (mailings != undefined && !mailings.length) {
        return (

            <div className="col-md-12">
                <div className="card p-4">
                    No mailings for selected time period
                </div>
            </div>
        )
    }

    return mailings != undefined && mailings.map(({ mailingID, mailingTitle, reachPresumedInitialReceived, reachRemailed, reachTotalSent, reachUndeliverable }) => {

        return (
            <div className="col-12 col-md-6 col-xl-6" key={mailingID}>
                <div className="card p-4">
                    <div className="pb-4">
                        <div className="mb-3">
                            <span className="text-md text-dark">{mailingTitle}</span>
                            <div>{reachTotalSent} Total Mailed Out</div>
                        </div>
                        <Doughnut
                            data={getDataForChart(reachPresumedInitialReceived, reachRemailed, reachUndeliverable)}
                            options={options}
                            height={300}
                            width={300}
                        />
                    </div>
                </div>
            </div>
        )
    })
}

export default MailingsCharts
