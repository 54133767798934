import { FETCH_ALL_FAVORITE_CASES, UPDATE_CASE_FAVORITE } from './actions'

const initialState = {}
export default (
    state = initialState,
    {
        type,
        favoriteCases,        
        caseIDs,
        isFavorite,
        officialCaseName
    }
) => {
    switch (type) {
        case FETCH_ALL_FAVORITE_CASES:            
            return favoriteCases
        case UPDATE_CASE_FAVORITE:            
            if (isFavorite == "1") {                
                return [
                    ...state,
                    {
                        caseID: caseIDs,
                        officialName: officialCaseName
                    }
                ]
            }
            else {                
              state = state.filter(el => el.caseID !== caseIDs);
            }                 
        default:
            return state
    }
}
