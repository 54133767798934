import {
	FETCH_TEAM_MEMBER_DETAIL,
	ADD_FETCH_TEAM_MEMBER_DETAIL_ACTIVITY_POST,
} from './actions'
import { resolved } from 'state/helpers'

const initialState = {}

export default (state = initialState, { type, memberDetails, lastCaseId, personID, isHasNewData, post, teamMemberDetailPersonID }) => {
	switch (type) {
		case resolved(FETCH_TEAM_MEMBER_DETAIL):                        
			
			return {
				...state,
				[personID]: {
					memberDetails,
					lastCaseId,
					isHasNewData
				}
			}			
		case ADD_FETCH_TEAM_MEMBER_DETAIL_ACTIVITY_POST:            
			
			const activityToUpdate = state[teamMemberDetailPersonID].memberDetails.activityEntries.filter(function (x) { return x.caseActivityID == post.caseActivityID });			
			
			if (!activityToUpdate) {
				return state
			}

			activityToUpdate[0].posts.push(post);

			return {
				...state
				//finalActivoti: {
				//	...activityToUpdate[0],
				//	posts: [...activityToUpdate[0].posts, post],
				//},
			}
		default:
			return state
	}
}
