import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn, SearchField, } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { getDateRange } from 'state/call-center/selectors'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'
import CallReleaseDatePicker from './datepicker/call-release-date-picker'
import { fetchCallCenterDataForAdmin } from 'state/admin/call-release/actions'
import { func } from 'prop-types';
import { simplurisFetch } from 'helpers'
import {
    getAllReleaseCallsForAdmin
} from 'state/case-detail-view/admin/selectors'
import { setTimeout } from 'timers';

var callIDsForSelectReleaseCalls = [];

const CallReleasePage = ({
    isFetching,
    fetchCallCenterDataForAdmin,
    allReleaseCalls = {},
    dateRange,
    caseID,
    userPermissions,
}) => {


    var {
        pageIndex = 1,
        pageSize = 10,
        totalCount = 0,
        searchValue,
        startDate = startDate,
        endDate = endDate,
        data = [],
        sortColumn = Object.keys(allReleaseCalls).length > 0 ? allReleaseCalls.sortColumn : 'caseID',
        sortColumnDir = Object.keys(allReleaseCalls).length > 0 ? allReleaseCalls.sortColumnDir : 'asc',
    } = allReleaseCalls

    const [sortName, setSortName] = useState(Object.keys(allReleaseCalls).length > 0 ? allReleaseCalls.sortColumn : 'caseID');
    const [sortOrder, setSortOrder] = useState(Object.keys(allReleaseCalls).length > 0 ? allReleaseCalls.sortColumn : 'asc');

    const [Text, setText] = useState('')
    const [isVisibale, setIsVisibale] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);



    useEffect(() => {
        try {
            allReleaseCalls = [];
            fetchCallCenterDataForAdmin(1, 10, null, null, null,sortName, sortOrder)
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [])

    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }

    const tableOptions = {
        toolBar: createCustomToolBar,
        onRowClick: onRowClick,
        onlyOneExpanding: true,
        expandBy: 'column',
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        onSortChange: onSortChange,
        sortName: sortName,
        sortOrder: sortOrder,
        sortIndicator: true,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader()
    }

    function onSearchChange(searchText) {
        allReleaseCalls.releaseCalls = [];
        searchValue = searchText
        setText(searchText)

        if (searchText == '') setText('')

        searchText = searchText == '' ? null : searchText
        fetchCallCenterDataForAdmin(1, pageSize, searchText, startDate, endDate,sortName, sortOrder)
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        allReleaseCalls.releaseCalls = [];
        fetchCallCenterDataForAdmin(page, sizePerPage,searchValue,startDate,endDate,sortName, sortOrder)
    }

    function onSizePerPageList(sizePerPage) {
        allReleaseCalls.releaseCalls = [];
        fetchCallCenterDataForAdmin(1, sizePerPage, searchValue, startDate, endDate,sortName, sortOrder)
    }

    function updateDateRangeFilter(dateRange) {
        allReleaseCalls.releaseCalls = [];
        setShowPageLoader(true);
        fetchCallCenterDataForAdmin(1, pageSize, searchValue, dateRange["start"], dateRange["end"],sortName, sortOrder)
        if (!isFetching) {
            setIsVisibale(false);
            callIDsForSelectReleaseCalls = [];
        }
        setShowPageLoader(false);
    }

    function onSortChange(sortName, sortOrder) {
        allReleaseCalls = [];
        setSortName(sortName);
        setSortOrder(sortOrder);
        
        fetchCallCenterDataForAdmin(1, pageSize, searchValue, startDate, endDate, sortName, sortOrder);
    }

    function onRowClick(row, columnIndex, rowIndex, e) {        
        if (e.target.classList.value.indexOf("SimplurisCallCenterMemeberDrillDownLink") > -1) {
            return false;
        }

        if (e.target.classList.value.indexOf("SimplurisCallCenterExpandLink") > -1) {
            const collepsedTag = document.getElementsByClassName("expandDiv");
            for (var i = 0; i < collepsedTag.length; i++) {
                collepsedTag[i].style.height = "0px";
            }

            const audioEl = document.getElementsByClassName("audio-" + row.callID)[0]
            if (audioEl.paused) {
                audioEl.play();
                setTimeout(function () {
                    document.getElementsByClassName("expandDiv-" + row.callID)[0].style.height = "50px";
                }, 100);

            } else {
                audioEl.pause();
                document.getElementsByClassName("expandDiv-" + row.callID)[0].style.height = "0px";
            }

            const audioTag = document.getElementsByTagName("audio");
            for (var i = 0; i < audioTag.length; i++) {
                if (audioTag[i].className != "audio-" + row.callID) {
                    audioTag[i].pause();
                }
            }
        }
        else
            return false;
    }

    function isExpandableRow(row) {
        return true;
    }

    function expandColumnComponent({ isExpanded }) {        
        if (!isExpanded) {
            return (
                <i className="SimplurisCallCenterPlayPauseIcon fa fa-play-circle-o SimplurisCallCenterExpandLink"></i>)
        }
        else {
            return (
                <i className="SimplurisCallCenterPlayPauseIcon fa fa-pause-circle-o SimplurisCallCenterExpandLink"></i>)
        }
    }


    function expandComponent(row) {         
        return (
            <table>
                <tr className="p-0">
                    <td colSpan="6" className="p-0 border-0 shadow-none">
                        <table className="table table-theme v-middle m-0 callCenterStatisticsGrid">
                            {(() => {
                                return <tr>
                                    <td className="p-0">
                                        <div className={`SimplurisFadeInAnimation expandDiv ${"expandDiv-" + row.callID}`}>
                                            {(
                                                () => {
                                                    return <>  <audio controls loop controlsList="nodownload" width="100%" className={"audio-" + row.callID}>
                                                        <source src={row.audioURL} type="audio/wav" />
                                                    </audio></>

                                                }
                                            )()}
                                        </div>
                                    </td>
                                </tr>

                            })()}
                        </table>
                    </td>
                </tr>
            </table>
        )
    }


    //if (isFetching) {
    //    return <div className="row"> <SectionSpinner /></div>
    //}

    //if (showPageLoader) {
    //    return <div className="row"> <SectionSpinner /></div>
    //}


    function showTableLoader() {
        if (isFetching || showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return "No data found";
        }
    }

    const customMultiSelect = props => {
        const { type, checked, disabled, onChange, rowIndex } = props;
        /*
        * If rowIndex is 'Header', means this rendering is for header selection column.
        */
        if (rowIndex === 'Header') {            
            if (props.checked == true) {
                return (
                    <label className="ui-check">
                        <input className='react-bs-select-all'
                            type='checkbox'
                            name={'checkbox' + props.rowIndex}
                            id={'checkbox' + props.rowIndex}
                            checked={props.checked}
                            onChange={props.onChange} />
                        <i></i>
                    </label>);
            }
            else {
                return (
                    <label className="ui-check">
                        <input className='react-bs-select-all'
                            type='checkbox'
                            name={'checkbox' + props.rowIndex}
                            id={'checkbox' + props.rowIndex}                            
                            onChange={props.onChange} />
                        <i></i>
                    </label>);
            }
            
        } else {
            return (
                <label className="ui-check">
                    <input
                        type={type}
                        name={'checkbox' + rowIndex}
                        id={'checkbox' + rowIndex}
                        checked={checked}
                        disabled={disabled}
                        onChange={e => onChange(e, rowIndex)}
                        ref={input => {
                            if (input) {
                                input.indeterminate = props.indeterminate;
                            }
                        }} /> <i></i>
                </label>);
        }
    }

    const selectRowProp = {
        mode: 'checkbox',
        onSelect: onRowSelect,
        onSelectAll: onSelectAll,
        columnWidth: '60px',
        customComponent: customMultiSelect,
        clickToExpand: true,
    };

    function onRowSelect(row, isSelected, e) {

        if (isSelected) {
            callIDsForSelectReleaseCalls.push(row.callID);
        }
        else {
            callIDsForSelectReleaseCalls = arrayRemove(callIDsForSelectReleaseCalls, row.callID);
        }

        if (callIDsForSelectReleaseCalls.length > 0) {
            setIsVisibale(true);
        }
        else {
            setIsVisibale(false);
        }
    }

    function arrayRemove(arr, value) {

        return arr.filter(function (ele) {
            return ele != value;
        });

    }

    var i;
    function onSelectAll(isSelected, rows) {

        for (i = 0; i < rows.length; ++i) {

            if (isSelected) {
                callIDsForSelectReleaseCalls.push(rows[i].callID);
            }
            else {
                callIDsForSelectReleaseCalls = arrayRemove(callIDsForSelectReleaseCalls, rows[i].callID);
            }
        }
        if (callIDsForSelectReleaseCalls.length > 0) {
            setIsVisibale(true);
        }
        else {
            setIsVisibale(false);
        }
    }

    function UpdateReleaseCall() {        
        allReleaseCalls.releaseCalls = [];
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/updatereleasecallsforadmin/${callIDsForSelectReleaseCalls.toString()}`, method: 'POST'
        })
            .then(result => {

                setShowPageLoader(false);
                if (result.name != undefined && result.name == "FetchError") {

                } else {

                    setIsVisibale(false);
                    callIDsForSelectReleaseCalls = [];
                    fetchCallCenterDataForAdmin(1, pageSize, searchValue, startDate, endDate, sortName, sortOrder);
                }
            })
            .catch(e => {
                console.error("Error in Update Release Call", e, e.errorObject);
            });
    }

    return (
        <div>            
                <div className="row mb-4">
                <div className="col-12 col-md-12 col-lg-12 col-xl-8 SimplurisFontSize16 SimplurisFontWeight500">
                        LiveCase Call Release
                    </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                        <CallReleaseDatePicker updateDateRangeFilter={updateDateRangeFilter} />
                    </div>
                <div className="col-12 col-md-6 col-lg-8 col-xl-1 mt-0 mt-sm-0 mt-md-3 mt-xl-1 mt-lg-3">
                        {
                            isVisibale &&
                            <button className="btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border active py-1 px-2"
                                id="btnreleasecall"                                
                                onClick={() => UpdateReleaseCall()}
                                aria-selected="false">Release Calls</button>
                        }
                    </div>
                </div>                           
            <div>
                <BootstrapTable
                    selectRow={selectRowProp}
                    data={allReleaseCalls.releaseCalls}
                    expandBy="column"
                    options={tableOptions}
                    expandableRow={isExpandableRow}
                    expandComponent={expandComponent}
                    expandColumnOptions={{
                        expandColumnVisible: true,
                        expandColumnComponent: expandColumnComponent,
                        columnWidth: 60
                    }}
                    tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover callCenterStatisticsMainGrid table1"
                    bodyContainerClass="text-grid-td"
                    hover
                    remote={true}
                    pagination={true}
                    search={true}
                    fetchInfo={{ dataTotalSize: totalCount }}
                    trClassName="SimplurisStatisticsGridMainTr SimplurisAdminCallReleaseChildMainTr"
                >
                    <TableHeaderColumn
                        dataField="callID"
                        isKey
                        hidden={true}
                        expandable={false}
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid"
                    >
                        CallID
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="caseID"
                        width="16%"
                        expandable={false}
                        dataSort={true}
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid">
                        CaseID
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="personID"
                        width="16%"
                        expandable={false}
                        dataSort={true}
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid">
                        PersonID
                </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false} 
                        dataField="date"
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid" 
                        width="16%"
                        dataSort={true}
                        dataFormat={date => new Date(date).toLocaleDateString()}
                    >
                        Date
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false} 
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid" 
                        dataField="date" 
                        width="16%" 
                        dataSort={true}
                        dataFormat={date => new Date(date).toLocaleTimeString()}>
                        Time
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false} 
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid" 
                        width="11%"
                        dataSort={true}
                        dataField="durationSeconds"
                    >
                        Length
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false} 
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid" 
                        dataField="fromCity" 
                        width="30%" 
                        dataSort={true}
                        dataFormat={(fromCity, { fromState }) =>`${fromCity} ${fromState}`
                    }>
                        Call Location
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false} 
                        columnClassName="SimplurisCallCenterStatisticsAudioGrid" 
                        dataField="firstName" 
                        dataSort={true}
                        width="30%" dataFormat={(firstName, { lastName }) =>`${firstName} ${lastName}`
                    }>
                        Member
                                </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    )
}


export default connect(
    (state, props) => ({
        isFetching: getAllReleaseCallsForAdmin(state, props),
        allReleaseCalls: state.adminData.AllReleaseCalls.releaseCalls,
        dateRange: getDateRange(state),
        userPermissions: getPermissionsForCase(state, props)
    }),
    {
        fetchCallCenterDataForAdmin,
        setFilteredIDs
    }
)(CallReleasePage)
