import React, { useEffect, useState } from 'react'
import ExceptionLogView from './manage-exceptionlog'
import AppLogView from './manage-applog'
const LogManagement = ({
    userPermissions,
}) => {

    const [flagForLogManagement, setFlagForLogManagement] = useState('ExceptionLog');

    const onTabChange = (tab) => {        
        setFlagForLogManagement(tab);
    }

    return (
        <div className="position-relative">
            <div className="row mb-4">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12 SimplurisFontSize16 SimplurisFontWeight500">
                    Log
                    </div>
                <div className="SimplurisLogManagementScreenFilterButtonWrraperDiv SimplurisLogManagementWrapperDiv">
                    {
                        <>

                            <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-0 mt-lg-0 mt-xl-0 ml-2 ml-md-2 ml-sm-0 ml-lg-0 ml-xl-0 mr-0 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                            ${flagForLogManagement == "ExceptionLog" ? 'active' : ''}`}
                                id="btnExceptionLog" onClick={() => onTabChange('ExceptionLog')}
                                aria-selected="false">Exception Log</button>
                            <button className={`float-left float-md-left float-sm-left  float-xl-right float-lg-right mt-2 mt-md-0 mt-lg-0 mt-xl-0 mr-2 mr-md-2 mr-sm-0 mr-lg-2 mr-xl-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                            ${flagForLogManagement == "AppLog" ? 'active' : ''}`}
                                id="btnAppLog" onClick={() => onTabChange('AppLog')}
                                aria-selected="false">App Log</button>
                        </>
                    }

                </div>
                <div className="tab-content col-md-12">
                    {(
                        () => {
                            switch (flagForLogManagement) {
                                case 'ExceptionLog':
                                    return <ExceptionLogView />
                                    break;
                                case 'AppLog':
                                    return <AppLogView />
                                    break;
                                default:
                            }
                        }
                    )()}

                </div>
            </div>
            <div>
            </div>
        </div>
    )
}


export default LogManagement
