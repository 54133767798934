import React, { Component, useState, useEffect } from 'react';
import Header from '../../components/outer-header'
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png'
import 'jquery-validation';
import { Link } from 'react-router-dom';
import PageLoader from '../../components/page-loader';
import { simplurisFetch } from 'helpers'
import { AnlyticsPageLog } from '../../../global';
import { appMode } from '../../../api-config'

function ForgotPasswordPage() {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailInvalidError, setEmailInvalidError] = useState(false);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [strongPasswordError, setStrongPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmMatchPasswordError, setConfirmMatchPasswordError] = useState(false);
    const [isVisibleforgotPasswordStep1Success, setIsVisibleforgotPasswordStep1Success] = useState(false);
    const [isVisibleforgotPasswordStep2Success, setIsVisibleforgotPasswordStep2Success] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [showPageLoader, setShowPageLoader] = useState(false);

    const ForgotPassowrdClick = () => {
        AnlyticsPageLog("Click on Forgot-password button");
        setErrorMessage('');
        setEmail(email);

        const isValidEmailRegex = global.EmailRegex.test(email)
        if (!email || !isValidEmailRegex) {
            if (!email) {
                setEmailError(true);
            }

            if (email && !isValidEmailRegex) {
                setEmailInvalidError(true);
            }

            return;
        }

        var data = {};
        data.Username = email;

        setShowPageLoader(true);

        simplurisFetch({
            url: "reset", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowPageLoader(false);
            if (data.name != undefined && data.name == "FetchError") {                
                if (data.errorObject.resultCode == "ERROR") {                    
                    setErrorMessage(data.errorObject.resultMessage);
                    setIsVisibleforgotPasswordStep1Success(false);
                }
            }
            else {                
                setIsVisibleforgotPasswordStep1Success(true)
            }
            }).catch(e => {
                console.error('Error in forgot password link', e, e.errorObject);
                setShowPageLoader(false);
                if (e.errorObject.resultCode == "ERROR") {
                    setErrorMessage(e.errorObject.resultMessage);
                    setIsVisibleforgotPasswordStep1Success(false);
                }
            });
    }

    const ForgotPassowrdEmailAddressKeyPressed = (event) => {
        if (event.key === "Enter") {
            ForgotPassowrdClick()
        }
    }

    const ResetPassowrdClick = () => {
        AnlyticsPageLog("Click on Reset-password button");
        setPassword(password);
        setCode(code);
        setConfirmPassword(confirmPassword);

        const isStrongPassword = global.StrongPasswordRegex.test(password)

        if (!password || !code || !confirmPassword || !isStrongPassword) {
           
            if (!password) {
                setPasswordError(true);
            }

            if (password && !isStrongPassword) {
                setStrongPasswordError(true);
            }

            if (!code) {
                setCodeError(true);
            }
            if (!confirmPassword) {
                setConfirmPasswordError(true);
            }
            return;
        }

        if (password != confirmPassword) {
            setConfirmMatchPasswordError(true);
            return;
        }

        var data = {};
        data.ConfirmationCode = code;
        data.Password = password;

        setShowPageLoader(true);
        simplurisFetch({
            url: "confirm-forgot", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowPageLoader(false);
            if (data.name != undefined && data.name == "FetchError") {                
                if (data.errorObject.resultCode == "ERROR") {                    
                    setErrorMessage(data.errorObject.resultMessage);
                    setIsVisibleforgotPasswordStep2Success(false);
                }
            }
            else {
                setIsVisibleforgotPasswordStep2Success(true);
                setIsVisibleforgotPasswordStep1Success(true);
            }
            }).catch(e => {
                console.error('Error in forgot password', e, e.errorObject);
                setShowPageLoader(false);
                if (e.errorObject.resultCode == "ERROR") {
                    setErrorMessage(e.errorObject.resultMessage);
                    setIsVisibleforgotPasswordStep2Success(false);
                }
            });       
    }

    const ResetPassowrdKeyPressed = (event) => {
        if (event.key === "Enter") {
            ResetPassowrdClick()
        }
    }

    useEffect(() => {
        AnlyticsPageLog("Forgot-password page");

        if (appMode == "sandbox") {
            setEmail("demo@simpluris.com");    
            setCode("A45D8041-146F-44A8-8E60-FF0D7BF465DD");
            setPassword("Password1!");
            setConfirmPassword("Password1!");
        }
        else {
            setEmail('');            
        }
    }, []);


    return (
        <div className="layout-column bg-white">
            <Header page="forgot-password"/>

            <div className="d-flex flex-column flex">
                <div>
                    <div className="p-3">
                        <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                            <div className="row no-gutters">
                                <div className="col-md-5 r-l">
                                    <div className="p-4 d-flex flex-column">
                                        <div className="d-block">
                                            <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right">
                                                <img src={BrandmarkLogo} className="w-50" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`col-md-6 ${isVisibleforgotPasswordStep1Success ? 'hide' : ''}`} id="step1-body">
                                    <div className="p-4">
                                        <h4 className="SimplurisFontWeight400"> LiveCase Password Reset</h4>
                                        <p>
                                            <small className="text-dark">
                                                We will email you instructions to reset your password. If you do not have access to your registered email, 
                                                please contact our administrator at LiveCaseAdmin@simpluris.com
                                            </small>
                                        </p>
                                        {ErrorMessage != "" ? <ErrorPlacement items={ErrorMessage} /> : ''}
                                        {
                                            appMode == "sandbox" ?
                                                <>
                                                    <div className="form-group mt-2">
                                                        <label>Email Address</label>
                                                        <input disabled="disabled" value="demo@simpluris.com" onKeyPress={ForgotPassowrdEmailAddressKeyPressed} type="email" id="txtEmail" name="Email" className="form-control SimplurisNewCaseMemebersInputBox" />                                                        
                                                    </div>
                                                </> :
                                                <>
                                                    <div className={`form-group mt-2 ${emailError || emailInvalidError ? 'has-error' : ''}`}>
                                                        <label>Email Address</label>
                                                        <input value={email} onChange={e => (setEmail(e.target.value), setEmailError(false), setEmailInvalidError(false))} onKeyPress={ForgotPassowrdEmailAddressKeyPressed} type="email" id="txtEmail" name="Email" className="form-control SimplurisNewCaseMemebersInputBox" />
                                                        {emailError ? <span>Please enter email</span> : null}
                                                        {emailInvalidError ? <span>Please enter valid email</span> : null}
                                                    </div>
                                                </>

                                        }
                                            
                                            <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3" onClick={ForgotPassowrdClick}>Continue</button>
                                            <p className="mt-3">
                                            {appMode == "sandbox" ? <Link to={"/login"} >Return to login</Link> : <Link to={"/sign-in"} >Return to login</Link>} 
                                            </p>
                                        
                                    </div>
                                </div>

                                <div className={`col-md-6 ${isVisibleforgotPasswordStep1Success && !isVisibleforgotPasswordStep2Success ? '' : 'hide'}`} id="step2-body">
                                    <div className="p-4">
                                        <h4 className="SimplurisFontWeight400">
                                            Check your mail. <br />
                                            We’ve sent a message to {email} with a verification code to reset your password.
                                              </h4>

                                        {ErrorMessage != "" ? <ErrorPlacement items={ErrorMessage} /> : ''}
                                        {
                                            appMode == "sandbox" ?
                                                <>
                                                    <div className="form-group mt-2">
                                                        <label>Code</label>
                                                        <input disabled="disabled" value="A45D8041-146F-44A8-8E60-FF0D7BF465DD" type="text" onKeyPress={ResetPassowrdKeyPressed} id="txtCode" name="txtCode" className="form-control SimplurisNewCaseMemebersInputBox" />                                                        
                                                    </div>
                                                    <div className="form-group mt-2">
                                                        <label>New password</label>
                                                        <input value="Password1!"  onKeyPress={ResetPassowrdKeyPressed} type="password" id="txtPwd" name="txtPwd" className="form-control SimplurisNewCaseMemebersInputBox" />                                                        
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-12 col-sm-6">
                                                            <p className="mb-0">
                                                                <small className="text-muted">
                                                                    One lowercase character <br />One uppercase character <br />One number
                                                </small>
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <p className="mb-0">
                                                                <small className="text-muted">
                                                                    One special character <br /> 8 characters minimum
                                                </small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mt-2">
                                                        <label>Confirm new password</label>
                                                        <input value="Password1!" type="password" onKeyPress={ResetPassowrdKeyPressed} id="txtCPwd" name="txtCPwd" className="form-control SimplurisNewCaseMemebersInputBox" />                                                        
                                                    </div></> :
                                                <>
                                                    <div className={`form-group mt-2 ${codeError ? 'has-error' : ''}`}>
                                                        <label>Code</label>
                                                        <input value={code} onChange={e => (setCode(e.target.value), setCodeError(false))} type="text" onKeyPress={ResetPassowrdKeyPressed} id="txtCode" name="txtCode" className="form-control SimplurisNewCaseMemebersInputBox" />
                                                        {codeError ? <span>Please enter code</span> : null}
                                                    </div>
                                                    <div className={`form-group mt-2 ${passwordError || strongPasswordError ? 'has-error' : ''}`}>
                                                        <label>New password</label>
                                                        <input value={password} onChange={e => (setPassword(e.target.value), setPasswordError(false), setStrongPasswordError(false))} onKeyPress={ResetPassowrdKeyPressed} type="password" id="txtPwd" name="txtPwd" className="form-control SimplurisNewCaseMemebersInputBox" />
                                                        {passwordError ? <span>Please enter password</span> : null}
                                                        {strongPasswordError ? <span> Password does not meet security requirements </span> : null}
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-12 col-sm-6">
                                                            <p className="mb-0">
                                                                <small className="text-muted">
                                                                    One lowercase character <br />One uppercase character <br />One number
                                                </small>
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <p className="mb-0">
                                                                <small className="text-muted">
                                                                    One special character <br /> 8 characters minimum
                                                </small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className={`form-group mt-2 ${confirmPasswordError || confirmMatchPasswordError ? 'has-error' : ''}`}>
                                                        <label>Confirm new password</label>
                                                        <input value={confirmPassword} onChange={e => (setConfirmPassword(e.target.value), setConfirmPasswordError(false), setConfirmMatchPasswordError(false))} type="password" onKeyPress={ResetPassowrdKeyPressed} id="txtCPwd" name="txtCPwd" className="form-control SimplurisNewCaseMemebersInputBox" />
                                                        {confirmPasswordError ? <span>Please enter confirm password</span> : null}
                                                        {confirmMatchPasswordError ? <span>Password and confirm password does not match</span> : null}
                                                    </div></>
                                        }
                                            
                                            <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3" onClick={ResetPassowrdClick}>Continue</button>
                                            <p className="mt-3">                                            
                                            {appMode == "sandbox" ? <Link to={"/login"}>Return to login</Link> : <Link to={"/sign-in"}>Return to login</Link>} 
                                            </p>
                                        
                                    </div>
                                </div>

                                <div className={`col-md-6 ${isVisibleforgotPasswordStep2Success ? '' : 'hide'}`} id="step2-body">
                                    <div className="p-4">
                                        <h4 className="SimplurisFontWeight400">
                                            Success. <br />
                                            Your password has been changed.
                                             </h4>
                                        {appMode == "sandbox" ? <Link to={"/login"}>
                                            <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3">Continue</button>
                                        </Link> : <Link to={"/sign-in"}>
                                                <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3">Continue</button>
                                            </Link>} 
                                                                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPageLoader ? <PageLoader /> : null}
        </div>
    )

}

export default ForgotPasswordPage;

class ErrorPlacement extends Component {
    render() {
        if (this.props.items != "") {
            return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
                {this.props.items}
            </div>
        }
    }
}