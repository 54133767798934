import * as React from 'react'
import { connect } from 'react-redux'

import SubtabButton from '../subtab-button'
import CaseActivityContent from './case-activity'
import LiveReportContent from './live-report'
import ResponseHeaderContent from './responses'
import { getPermissionsForCase } from 'state/permissions/selectors'

const HeaderContent = ({ caseObject, caseSubtab, caseID, userPermissions }) => {    
    console.log(caseSubtab);
    
    var viewLiveReportScreenPermission = userPermissions.find(
        (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_IN_MENU'
    )

    if (caseSubtab == null) {
        if (userPermissions && userPermissions.length > 0) {            
            if (viewLiveReportScreenPermission && viewLiveReportScreenPermission.length > 0) {                
                caseSubtab = 'live-report'                
            }
            else {
                caseSubtab = 'activity'                
            }

            switch (caseSubtab) {
                case 'activity':
                    return <CaseActivityContent caseID={caseID} />
                case 'live-report':
                    return <LiveReportContent caseID={caseID} />
                case 'responses':
                    return <ResponseHeaderContent caseID={caseID} />
                default:
                    return <SubtabButton caseSubtab={caseSubtab} caseObject={caseObject} />
            }
        }
        else {
            return <></>
        }
    }
    else if (caseSubtab != null) {
        switch (caseSubtab) {
            case 'activity':
                return <CaseActivityContent caseID={caseID} />
            case 'live-report':
                return <LiveReportContent caseID={caseID} />
            case 'responses':
                return <ResponseHeaderContent caseID={caseID} />
            default:
                return <SubtabButton caseSubtab={caseSubtab} caseObject={caseObject} />
        }
    }
    else
    {
        return <></>
    }    
}

//export default connect(state => ({
//    caseSubtab: state.caseDetailView.subtab == "" ? null : state.caseDetailView.subtab,
//}))(HeaderContent)

export default connect(
    (state, props) => ({
        caseSubtab: state.caseDetailView.subtab == "" ? null : state.caseDetailView.subtab,
        userPermissions: getPermissionsForCase(state, props),
    }))(HeaderContent)
