import * as React from 'react'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'
import { getPermissionsForCase } from 'state/permissions/selectors'

const chartColors = ['#5ed0d3']

const getShortDate = dateString => new Date(dateString).toLocaleDateString()

const barChartOptions = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    precision: 0,
                    min: 0                    
                },
            },
        ],
    },
    legend: {
        display: false
    },
    cornerRadius: 8,
    title: {
        display: false,
        text: '# of Calls',
        position: 'top'
    },
    tooltips: {  
        callbacks: {
            title: function () {
                return null;
            },
            label: function (tooltipItem, data) {                                                
                var dataset = data['datasets'][0];
                var percent = Math.round((dataset['data'][tooltipItem['index']].y - (dataset['data'][tooltipItem['index'] - 1] == undefined ? 0 : dataset['data'][tooltipItem['index'] - 1].y)) * 100)
                var minusSign = "-";
                var finalString = '+' + percent + '%';
                return (finalString.indexOf(minusSign) != -1 ? '' : '+') + percent + '%';
            }            
        },
        filter: function (tooltipItem) {            
            return tooltipItem.index != 0;
        },
        backgroundColor: '#FFF',
        titleFontSize: 16,
        titleFontColor: '#000',
        bodyFontColor: '#000',
        bodyFontSize: 14,
        displayColors: false
    },
}

const CallDataSummarySection = ({ callDataResponse, userPermissions }) => {    
    const sortedResponses = callDataResponse.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
    )
    
    const callDataWithShortDates = sortedResponses.map(({ date, ...other }) => ({
        ...other,
        date: getShortDate(date),
    }))

    const totalsByDate = callDataWithShortDates
        .reduce((totalsByDate, { date }) => {

            totalsByDate[date] = (totalsByDate[date] || 0) + 1
            return totalsByDate
        }, {})
    
    const chartData = Object.keys(totalsByDate).map(date => ({
        x: date,
        y: totalsByDate[date],
    }))

    if (chartData.length > 0) {
        chartData.reduce((max, p) => p.y > max ? p.y : max, chartData[0].y)
    }
    

    const barChartData = {        
        labels: [...new Set(callDataWithShortDates.map(({ date }) => date))], // Only unique date strings      
        datasets: [
             {       
                label: "dataset",
                data: chartData,
                width:'100%',                
                backgroundColor: chartColors[0],                
                borderColor: chartColors[0],
                borderWidth: 2                
            }         
        ]
    }
    return (
        <div>
            <Bar data={barChartData} options={barChartOptions}/>
        </div>
    )
}


export default connect((state, props) => ({
    userPermissions: getPermissionsForCase(state, props),
}))(CallDataSummarySection)