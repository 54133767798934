import * as React from 'react'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'

import exportImage from 'static/img/export.png'
import FilterButton from 'react/components/filter-button'
import PercentageCircleChart from './percentage-circle-chart'
import ShareReportModalPopup from 'react/features/case-detail/content/live-report/share-report-modal'
import { labFeatures } from '../../../../../../api-config'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { changeResponseFilters } from 'state/responses/actions'
import { getFilters } from 'state/responses/selectors'

const chartColors = ['#157eff', '#7357ff', '#fe4d62', '#F57C00']
const percentageChartOrder = ['Valid', 'Pending']

const lineChartOptions = {    
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    precision: 0,
                },
            },
        ],
    },
    legend: {
        display: false,
    }
}
const Pointer = styled.div`
  cursor: pointer;
`

const getShortDate = dateString => new Date(dateString).toLocaleDateString()
const getUniqueResponseTypes = responses => responses != undefined ? [...new Set(responses.map(({ responseType }) => responseType)),] : []

const ResponseSummarySection = ({ responses, caseID, userPermissions, changeResponseFilters, filters }) => {
    
    var exportDataPermission;
    var viewResponseSummaryPermission
    if (Object.keys(userPermissions).length > 0) {
        exportDataPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_RESPONSES_DOWNLOAD_EXPORT_DATA'
        )
        viewResponseSummaryPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_LIVEREPORT_VIEW_RESPONSE_SUMMARY'
        )
    }
    
    const uniqueResponseTypes = getUniqueResponseTypes(responses)
    
    useEffect(() => {
        const initialFilters = uniqueResponseTypes.reduce((state, filter) => {
            state[filter] = true
            return state
        }, {})
        changeResponseFilters(initialFilters)
    }, [])

    
    const filteredResponses = responses != undefined ? responses.filter(({ statusTypeCode }) => statusTypeCode !== 'Invalid').filter(({ responseType }) => filters[responseType]) : []

    const percentageChartCounts = filteredResponses.reduce(
        (reducedData, { statusTypeCode }) => {
            reducedData[statusTypeCode] = (reducedData[statusTypeCode] || 0) + 1
            return reducedData
        },
        {}
    )
   const percentageChartValues = percentageChartOrder.map(key => {
        if (key == 'Pending') {
            return percentageChartCounts['Unresolved']
                ? Number(
                    (percentageChartCounts['Unresolved'] / filteredResponses.length) * 100
                ).toFixed()
                : 0;
        } else if (key == 'Valid') {
            return percentageChartCounts[key]
                ? Number(
                    (percentageChartCounts[key] / filteredResponses.length) * 100
                ).toFixed()
                : 0;
        }
    })
    
    const sortedResponses = filteredResponses.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
    )
    const responsesWithShortDates = sortedResponses.map(({ date, ...other }) => ({
        ...other,
        date: getShortDate(date),
    }))

    const lineChartData = {        
        labels: [...new Set(responsesWithShortDates.map(({ date }) => date))], // Only unique date strings
        datasets: uniqueResponseTypes.map((type, i) => {               
            
            const totalsByDate = responsesWithShortDates
                .filter(({ responseType }) => responseType === type)
                .filter(({ statusTypeCode }) => statusTypeCode !== 'Invalid')
                .reduce((totalsByDate, { date }) => {
                    totalsByDate[date] = (totalsByDate[date] || 0) + 1
                    return totalsByDate
                }, {})
            const chartData = Object.keys(totalsByDate).map(date => ({
                x: date,
                y: totalsByDate[date],
            }))
            
            return {
                fill: false,
                label: type,
                data: chartData,
                pointBackgroundColor: 'white',
                pointBorderColor: chartColors[i],
                backgroundColor: chartColors[i],
                pointBorderWidth: 2,
                pointRadius: 4,
                pointHitRadius: 10,
                borderColor: chartColors[i],
                borderWidth: 1,
            }
        }),
    }

    const [showModal, setShowModal] = useState(false)

    return (
        
        <div className="col-md-12">
            {(viewResponseSummaryPermission && viewResponseSummaryPermission.length > 0) ?
            <div className="card p-4">
                <div className="row">
                    <div className="col-md-11 col-10 mb-3">
                        <span className="text-md text-dark">Response Summary </span>
                    </div>
                    {labFeatures &&
                        <div className="col-md-1 col-2">
                            <Pointer>
                                {exportDataPermission && exportDataPermission.length > 0 ? <img src={exportImage} onClick={() => setShowModal(true)} /> : <></>}
                            </Pointer>
                            <ShareReportModalPopup
                            OpenModalPopup={showModal}
                            caseID={caseID}
                                setShowShareReportModalPopup={setShowModal}
                                userPermissions={userPermissions}
                            />
                        </div>
                    }
                    <div className="col-md-5">
                        {Object.keys(filters).map((type, i) => (
                            <FilterButton
                                key={type}
                                active={filters[type]}
                                onClick={() => changeResponseFilters({
                                    ...filters,
                                    [type]: !filters[type],
                                })}
                                label={type}
                                legendColor={chartColors[i]} // To match with datasets colors above
                            />
                        ))}
                    </div>
                    <div className="col-md-7">
                        <div className="row float-md-right">
                            {percentageChartOrder.map((type, i) => (
                                <div className="col-md-6 col-6" key={type}>
                                    <PercentageCircleChart
                                        size={90}
                                        percentage={percentageChartValues[i]}
                                        wording={type}
                                        color={chartColors[i]}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-12 mt-4">
                        <Line data={lineChartData} options={lineChartOptions} />
                    </div>
                </div>
            </div> :null}
        </div> 
    )
}

export default connect(
  (state, props) => ({
    userPermissions: getPermissionsForCase(state, props),
    filters: getFilters(state, props),
  }),
  { changeResponseFilters }
)(ResponseSummarySection)
