import React, { Component, useState, useEffect } from 'react';
import Header from '../../components/outer-header'
import DashboardHeader from '../../components/dashboard-header'
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png'
import Cleave from 'cleave.js/react';
import PageLoader from '../../components/page-loader';
import { Link } from 'react-router-dom';
import { simplurisFetch } from 'helpers'
import { AnlyticsPageLog, TrackAnalyticEvent } from '../../../global';
import { appMode } from '../../../api-config'

function RequestCallPage(props) {
    var dateFormat = require('dateformat');
    var now = new Date();
    var tomorrowDate = new Date(now.getFullYear(), parseInt(now.getMonth()), parseInt(now.getDate()) + 1);
    var dayAfterDate = new Date(now.getFullYear(), parseInt(now.getMonth()), parseInt(now.getDate()) + 2);
    var dayFourDate = new Date(now.getFullYear(), parseInt(now.getMonth()), parseInt(now.getDate()) + 3);
    var dayFiveDate = new Date(now.getFullYear(), parseInt(now.getMonth()), parseInt(now.getDate()) + 4);
    var daySixDate = new Date(now.getFullYear(), parseInt(now.getMonth()), parseInt(now.getDate()) + 5);

    const [userFirstName, setUserFirstName] = useState(localStorage.getItem(global.FirstName) || '');
    const [userLastName, setUserLastName] = useState(localStorage.getItem(global.LastName) || '');
    const [userEmail, setUserEmail] = useState(localStorage.getItem(global.Email) || '');
    const [userPhoneNo, setUserPhoneNo] = useState(localStorage.getItem(global.PhoneNumber) || '');
    const [userExist, setUserExist] = useState('');
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showStep1, setShowStep1] = useState(true);
    const [showStep2, setShowStep2] = useState(false);
    const [showStep3, setShowStep3] = useState(false);
    const [email, setEmail] = useState('');
    const [emailReqError, setEmailReqError] = useState(false);
    const [emailInvalidError, setEmailInvalidError] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [firstNameReqError, setFirstNameReqError] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameReqError, setLastNameReqError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberReqError, setPhoneNumberReqError] = useState(false);
    const [timeZone, setTimeZone] = useState('0');
    const [timeZoneReqError, setTimeZoneReqError] = useState(false);
    const [timeSlotReqError, setTimeSlotReqError] = useState(false);
    const [timeZones, setTimeZones] = useState([
        { value: '0', gmt: '0', timezonecode: "", display: "Select Time Zone" },
        { value: '1', gmt: '-10:00', timezonecode: "Pacific/Honolulu", display: "(GMT-10:00) Hawaii Time" },
        { value: '2', gmt: '-09:00', timezonecode: "America/Anchorage", display: "(GMT-9:00) Alaska Time" },
        { value: '3', gmt: '-08:00', timezonecode: "America/Los_Angeles", display: "(GMT-8:00) Pacific Time" },
        { value: '4', gmt: '-07:00', timezonecode: "America/Denver", display: "(GMT-7:00) Mountain Time" },
        { value: '5', gmt: '-07:00', timezonecode: "America/Phoenix", display: "(GMT-7:00) Arizona Time" },
        { value: '6', gmt: '-06:00', timezonecode: "America/Chicago", display: "(GMT-6:00) Central Time" },
        { value: '7', gmt: '-05:00', timezonecode: "America/New_York", display: "(GMT-5:00) Eastern Time" }
    ]);
    const [slotId, setSlotId] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedDayType, setSelectedDayType] = useState('');
    const [today, setToday] = useState([]);
    const [tomorrow, setTomorrow] = useState([]);
    const [dayaftertomorrow, setDayAfterTomorrow] = useState([]);
    const [dayFourTomorrow, setDayFourTomorrow] = useState([]);
    const [dayFiveTomorrow, setDayFiveTomorrow] = useState([]);
    const [daySixTomorrow, setDaySixTomorrow] = useState([]);
    const [okToCallSooner, setOkToCallSooner] = useState(true);
    const [okToResearchArticle, setOkToResearchArticle] = useState(true);
    const [okToResearchArticleCheckBoxHideShow, setOkToResearchArticleCheckBoxHideShow] = useState(false);

    const [slotDetail, setSlotDetail] = useState([]);
    const [scheduledTime, setScheduledTime] = useState('');
    const [scheduledEmail, setScheduledEmail] = useState('');

    function getSelectedTime(selectedTime, selectedDayType, slotId) {
        setTimeSlotReqError(false);
        setSelectedTime(selectedTime);
        setSelectedDayType(selectedDayType);
        setSlotId(slotId);
        if (selectedDayType == "today") {
            setSlotDetail(today.filter((x, y) => {
                return (y == slotId)
            }));
        } else if (selectedDayType == "tomorrow") {
            setSlotDetail(tomorrow.filter((x, y) => {
                return (y == slotId)
            }));
        } else if (selectedDayType == "dayaftertomorrow") {
            setSlotDetail(dayaftertomorrow.filter((x, y) => {
                return (y == slotId)
            }));
        }
        else if (selectedDayType == "dayfourtomorrow") {
            setSlotDetail(dayFourTomorrow.filter((x, y) => {
                return (y == slotId)
            }));
        }
        else if (selectedDayType == "dayfivetomorrow") {
            setSlotDetail(dayFiveTomorrow.filter((x, y) => {
                return (y == slotId)
            }));
        }
        else if (selectedDayType == "daysixtomorrow") {
            setSlotDetail(daySixTomorrow.filter((x, y) => {
                return (y == slotId)
            }));
        }
    }

    function formatDate(date, timezoneGMT) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        //hours = hours % 12;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ":00";// + '' + ampm;
        //return date.getFullYear() + " " + parseInt(date.getMonth() + 1) + " " + date.getDate() + "," + strTime + " " + timezoneGMT;

        var month = parseInt(date.getMonth() + 1);
        var dateOfMonth = date.getDate();
        month = month < 10 ? "0" + month : month;
        dateOfMonth = dateOfMonth < 10 ? "0" + dateOfMonth : dateOfMonth;

        return date.getFullYear() + "-" + month + "-" + dateOfMonth + "T" + strTime + "" + timezoneGMT;
    }

    //function formatDate(date, timezoneGMT) {
    //    var hours = date.getHours();
    //    var minutes = date.getMinutes();
    //    var ampm = hours >= 12 ? 'pm' : 'am';
    //    hours = hours % 12;
    //    hours = hours ? hours : 12;
    //    minutes = minutes < 10 ? '0' + minutes : minutes;
    //    var strTime = hours + ':' + minutes + ' ' + ampm;
    //    return date.getFullYear() + " " + parseInt(date.getMonth() + 1) + " " + date.getDate() + "," + strTime + " " + timezoneGMT;
    //}

    var timeZoneDetail = timeZones.filter(x => {
        return x.value == timeZone;
    });

    const RequestCallStep1Click = () => {
        AnlyticsPageLog("Click on Schedule consultant step - 1");
        
        const isValidEmail = global.EmailRegex.test(email)
        //! covers all possible nulls/empty strings
        if (!email || !isValidEmail || !firstName || !lastName || !phoneNumber || timeZoneDetail.length <= 0 || timeZoneDetail[0].value == "0") {

            if (!email) {
                setEmailReqError(true);
            }

            if (email && !isValidEmail) {
                setEmailInvalidError(true);
            }

            if (!firstName) {
                setFirstNameReqError(true);
            }

            if (!lastName) {
                setLastNameReqError(true);
            }

            if (!phoneNumber) {
                setPhoneNumberReqError(true);
            }

            if (timeZoneDetail.length <= 0 || timeZoneDetail[0].value == "0") {
                setTimeZoneReqError(true);
            }

            return;
        }

        let timezonecode = timeZoneDetail[0].timezonecode;
        let gmtTime = timeZoneDetail[0].gmt;
        let userSpecificTime = new Date().toLocaleString("en-US", { timeZone: timezonecode });
        userSpecificTime = new Date(userSpecificTime);
        let currentDateTime = formatDate(userSpecificTime, gmtTime);
        setShowPageLoader(true);
        var data = {};
        data.Email = email;
        data.FirstName = firstName;
        data.LastName = lastName;
        data.Mobile = phoneNumber;
        data.TimeZoneCode = timezonecode;
        data.CurrentDateTime = currentDateTime;
        data.SourceLink = window.location.href;

        simplurisFetch({
            url: "gettimeslots", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {
            setShowPageLoader(false);
            if (data.name != undefined && data.name == "FetchError") {
                if (data.errorObject.resultCode == "ERROR") {
                    setErrorMessage("Something went wrong.")
                }
                else if (data.errorObject.resultCode == "EXISTS") {
                    setErrorMessage(data.errorObject.resultMessage)
                }
            }
            else if (data.resultCode == 'SUCCESS') {
                TrackAnalyticEvent("ScheduleConsultStep1", { Title: "ScheduleConsultStep1", FirstName: firstName, LastName: lastName, Email: email, PhoneNumber: phoneNumber, Timezone: timezonecode });
                setToday(data.resultObject.todaysTimeSlots ? data.resultObject.todaysTimeSlots : []);
                setTomorrow(data.resultObject.tomorrowsTimeSlots ? data.resultObject.tomorrowsTimeSlots : []);
                setDayAfterTomorrow(data.resultObject.dayAfterTomorrowsTimeSlots ? data.resultObject.dayAfterTomorrowsTimeSlots : []);
                setDayFourTomorrow(data.resultObject.dayFourTimeSlots ? data.resultObject.dayFourTimeSlots : []);
                setDayFiveTomorrow(data.resultObject.dayFiveTimeSlots ? data.resultObject.dayFiveTimeSlots : []);
                setDaySixTomorrow(data.resultObject.daySixTimeSlots ? data.resultObject.daySixTimeSlots : []);
                setErrorMessage("");
                setShowStep1(false);
                setShowStep3(false);
                setShowStep2(true);
                if (data.resultObject.isSubscribed) {
                    setOkToResearchArticleCheckBoxHideShow(true);
                }
            } else {
                setErrorMessage("Something went wrong.")
            }
        }).catch(e => {
            console.error('Error in request step1', e, e.errorObject);
            setShowPageLoader(false);
            if (e.errorObject == undefined) {
                setErrorMessage("Something went wrong.")
            }
            else if (e.errorObject.resultCode == "ERROR") {
                setErrorMessage("Something went wrong.")
            }
            else if (e.errorObject.resultCode == "EXISTS") {
                setErrorMessage(e.errorObject.resultMessage);
            }
        });
    }

    const handleChecked = () => {
        setOkToCallSooner(!okToCallSooner);
    }

    const SendResearchArticleChange = () => {
        setOkToResearchArticle(!okToResearchArticle);
    }

    const RequestCallStep2Click = () => {
        AnlyticsPageLog("Click on Schedule consultant step - 2");
        setShowPageLoader(true);
        setTimeSlotReqError(false);
        let timezonecode = timeZoneDetail[0].timezonecode;
        let timezoneId = timeZoneDetail[0].value;
        var totalLength = timezonecode.length;
        var trimlength = timezonecode.indexOf(')') + 1;
        var shortUserTimezone = timezonecode.substring(trimlength, totalLength);
        shortUserTimezone = shortUserTimezone.toLowerCase();

        var personData = {};
        personData.Email = email;
        personData.FirstName = firstName;
        personData.LastName = lastName;
        personData.Mobile = phoneNumber;
        personData.SourceLink = window.location.href;

        var scheduleData = {};
        scheduleData.Person = personData;
        scheduleData.TimezoneID = parseInt(timezoneId);
        scheduleData.UserTimezone = timezonecode;
        if (slotDetail.length <= 0) {
            setTimeSlotReqError(true);
            setShowPageLoader(false);
            return false;
        }        
        scheduleData.ScheduledDateStartGMT = slotDetail[0].timeSlotDateString;
        scheduleData.ScheduledDateStartGMTMinus7 = slotDetail[0].timeSlotDateString;
        scheduleData.DateTimeOffset = slotDetail[0].timeSlotDateString;
        scheduleData.BookedToCalenderIdentifier = slotDetail[0].calenderID.toString();
        scheduleData.OkToCallSooner = okToCallSooner;
        scheduleData.OkToSendLegalArticle = okToResearchArticle;
        scheduleData.ShortUserTimezone = shortUserTimezone;
        
        TrackAnalyticEvent("ScheduleConsultStep2", {
            Title: "ScheduleConsultStep2",
            ScheduleDate: slotDetail[0].timeSlotDateString,
            CalenderID: slotDetail[0].calenderID.toString(),
            OkToCallSooner: okToCallSooner,
        });

        simplurisFetch({
            url: "savetimeslots", method: 'POST', body: JSON.stringify(scheduleData)
        }).then((data) => {
            setShowPageLoader(false);
            if (data.name != undefined && data.name == "FetchError") {
                if (data.errorObject.resultCode == "ERROR") {
                    setErrorMessage("Something went wrong.")
                }
                else if (data.errorObject.resultCode == "EXISTS") {
                    setErrorMessage(data.errorObject.resultMessage)
                }
            }
            else {
                setErrorMessage("");
                var dayOfWeek = '';
                switch (selectedDayType) {
                    case "today":
                        dayOfWeek = "Today at";
                        break;
                    case "tomorrow":
                        dayOfWeek = "Tomorrow at";
                        break;
                    case "dayaftertomorrow":
                        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                        var d = new Date(slotDetail[0].timeSlotDateString);
                        var dayName = days[d.getDay()];
                        dayOfWeek = "on " + dayName + " at";
                        break;
                    case "dayfourtomorrow":
                        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                        var d = new Date(slotDetail[0].timeSlotDateString);
                        var dayName = days[d.getDay()];
                        dayOfWeek = "on " + dayName + " at";
                        break;
                    case "dayfivetomorrow":
                        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                        var d = new Date(slotDetail[0].timeSlotDateString);
                        var dayName = days[d.getDay()];
                        dayOfWeek = "on " + dayName + " at";
                        break;
                    case "daysixtomorrow":
                        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                        var d = new Date(slotDetail[0].timeSlotDateString);
                        var dayName = days[d.getDay()];
                        dayOfWeek = "on " + dayName + " at";
                        break;
                }

                if (appMode == "sandbox") {
                    setScheduledTime("Tomorrow at " + slotDetail[0].starttime);
                    setScheduledEmail(localStorage.getItem(global.Email));
                }
                else
                {
                    setScheduledTime(dayOfWeek + " " + slotDetail[0].starttime);
                    setScheduledEmail(slotDetail[0].email);
                }
                
                setShowStep1(false);
                setShowStep2(false);
                setShowStep3(true);
                AnlyticsPageLog("Show Schedule consultant step - 3");

                TrackAnalyticEvent("ScheduleConsultStep3", {
                    Title: "ScheduleConsultStep3",
                    ScheduleDate: slotDetail[0].timeSlotDateString,
                    CalenderID: slotDetail[0].calenderID.toString(),
                    OkToCallSooner: okToCallSooner
                })
            }
        }).catch(e => {
            console.error('Error in request step2', e, e.errorObject);
            setShowPageLoader(false);
            setErrorMessage("Something went wrong.")
            if (e.errorObject == undefined) {
                setErrorMessage("Something went wrong.")
            }
            else if (e.errorObject.resultCode == "ERROR") {
                setErrorMessage("Something went wrong.")
            }
            else if (e.errorObject.resultCode == "EXISTS") {
                setErrorMessage(e.errorObject.resultMessage)
            }
            else {
                setErrorMessage("Something went wrong.")
            }
        });
    }

    const setRequestCallValues = () => {
        setTimeZone('3');
        if (userFirstName != "" || userLastName != "" || userEmail != "" || userPhoneNo != "") {
            setUserExist('disabled');
            if (userFirstName != "")
                setFirstName(userFirstName);
            if (userLastName != "")
                setLastName(userLastName);
            if (userEmail != "")
                setEmail(userEmail);
            if (userPhoneNo != "")
                setPhoneNumber(userPhoneNo);
        } else
            setUserExist('');
    }

    const CancelClick = () => {
        //props.history.goBack();
        props.history.push('/dashboard');
    }

    useEffect(() => {
        AnlyticsPageLog("Schedule Consult page");
        setRequestCallValues();
    }, []);

    return (
        <div>
            <div className="layout-column bg-white">
                {userFirstName != "" ? <DashboardHeader page="request-call" /> : <Header />}
                <div className="d-flex flex-column flex">
                    <div>
                        <div className="p-3">
                            <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                                <div className="row no-gutters">
                                    <div className="col-md-5 r-l" >
                                        <div className="p-4 d-flex flex-column">
                                            <div className="d-block">
                                                <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right">
                                                    <img src={BrandmarkLogo} className="w-50" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showStep1 ?
                                        <div className="col-md-6" id="step1-body">
                                            <div className="p-4">
                                                <h4 className="SimplurisNewCaseCreatelabel test1"> Schedule Consult</h4>
                                                <p className="mt-3">
                                                    <label className="SimplurisNewCaseTextColor SimplurisFontSize16 SimplurisFontWeight400">
                                                        Please confirm your info and timezone.
                                                    </label>
                                                </p>
                                                {errorMessage != "" ? <ErrorPlacement items={errorMessage} /> : ''}
                                                <form className="" role="form">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className={`form-group mt-2 ${firstNameReqError ? 'has-error' : ''}`}>
                                                                <label>First Name</label>
                                                                <div className="position-relative">
                                                                    <input type="text" disabled={userExist} name="txtFirstName" id="txtFirstName" className="form-control SimplurisNewCaseMemebersInputBox" value={firstName} onChange={e => (setFirstName(e.target.value), setFirstNameReqError(false))} />
                                                                    {firstNameReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                                    {firstNameReqError ? <span>Please enter firstname</span> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className={`form-group mt-2 ${lastNameReqError ? 'has-error' : ''}`}>
                                                                <label>Last Name</label>
                                                                <div className="position-relative">
                                                                    <input type="text" disabled={userExist} name="txtLastName" id="txtLastName" className="form-control SimplurisNewCaseMemebersInputBox" value={lastName} onChange={e => (setLastName(e.target.value), setLastNameReqError(false))} />
                                                                    {lastNameReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                                    {lastNameReqError ? <span>Please enter lastname</span> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`form-group mt-2 ${emailReqError || emailInvalidError ? 'has-error' : ''}`}>
                                                        <label>Email Address</label>
                                                        <div className="position-relative">
                                                            <input type="email" disabled={userExist} className="form-control SimplurisNewCaseMemebersInputBox" value={email} onChange={e => (setEmail(e.target.value), setEmailReqError(false), setEmailInvalidError(false))} />
                                                            {emailReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                            {emailReqError ? <span>Please enter email</span> : null}
                                                            {emailInvalidError ? <span>Please enter valid email</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className={`form-group mt-2 ${phoneNumberReqError ? 'has-error' : ''}`}>
                                                        <label>Phone Number</label>
                                                        <div className="position-relative">
                                                            <Cleave className="form-control SimplurisNewCaseMemebersInputBox" options={{ delimiter: '-', phone: true, phoneRegionCode: 'US', blocks: [3, 3, 4], uppercase: true }}
                                                                onChange={e => (setPhoneNumber(e.target.value), setPhoneNumberReqError(false))} value={phoneNumber} name="txtPhoneNumber" maxLength="15" id="txtPhoneNumber" />
                                                            {phoneNumberReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                            {phoneNumberReqError ? <span>Please enter phonenumber</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className={`form-group mt-2 ${timeZoneReqError ? 'has-error' : ''}`}>
                                                        <label>Time Zone</label>
                                                        <div className="position-relative">
                                                            <select value={timeZone} className="form-control SimplurisNewCaseMemebersInputBox"
                                                                onChange={e => (setTimeZone(e.target.value), setTimeZoneReqError(false))}>
                                                                {timeZones.map(timeZone => (
                                                                    <option
                                                                        key={timeZone.timezonecode}
                                                                        value={timeZone.value}
                                                                        data-gmt={timeZone.gmt}
                                                                        data-timezonecode={timeZone.timezonecode}
                                                                    >
                                                                        {timeZone.display}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {timeZoneReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                            {timeZoneReqError ? <span>Please select timezone</span> : null}
                                                        </div>
                                                    </div>
                                                    <p className="mt-3">
                                                        <small className="SimplurisFontSize14 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                                            Are you a class member or potential class member? <a href="http://www.simpluris.com/case-information/" target="_blank" className="SimplurisNewCaseTextColor">Click Here</a>
                                                        </small>
                                                    </p>

                                                    <button type="button" onClick={RequestCallStep1Click} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor mb-1 btn-light py-3 px-4 SimplurisFontSize14 SimplurisFontWeight400">
                                                        Continue to Scheduling
                                                </button>

                                                    <button type="button" onClick={CancelClick} className="SimplurisCancelButtonRequestCall btn rounded-0 SimplurisWhiteBackgroundButton mb-1 ml-3 btn-light py-3 px-4 SimplurisFontSize14 SimplurisFontWeight400">
                                                        Cancel
                                                </button>

                                                </form>
                                            </div>
                                        </div>
                                        : null}
                                    {showStep2 ?
                                        <div className="col-md-7" id="step2-body">
                                            <div className="p-4">
                                                <h4 className="SimplurisNewCaseCreatelabel w-auto-md w-80 SimplurisSignupStartedLableW-100">
                                                    {firstName}, do any of the following times work for you?
                                                </h4>
                                                <div className="row mt-25">
                                                    <div className="col-4 pr-0">
                                                        <p className="mb-3 Simpluris-request-call-timebox-title">Today {dateFormat(now, "mm/dd")}
                                                        </p>
                                                        {today.map((today, i) => (
                                                            <div onClick={() => getSelectedTime(today.time, 'today', i)} key={i}
                                                                data-calenderid={today.calenderID} data-timeslot-day="today" data-slot-id={i}
                                                                className={"col-12 px-3 mt-2 text-center Simpluris-request-call-timebox " + (selectedTime == today.time && selectedDayType == 'today' ? 'active' : '')}>
                                                                {today.starttime}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-4 pr-0">
                                                        <p className="mb-3 Simpluris-request-call-timebox-title">Tomorrow  {dateFormat(tomorrowDate, "mm/dd")}</p>
                                                        {tomorrow.map((tomorrow, i) => (
                                                            <div onClick={() => getSelectedTime(tomorrow.time, 'tomorrow', i)} key={i}
                                                                data-calenderid={tomorrow.calenderID} data-timeslot-day="tomorrow" data-slot-id={i}
                                                                className={"col-12 px-3 mt-2 text-center Simpluris-request-call-timebox " + (selectedTime == tomorrow.time && selectedDayType == 'tomorrow' ? 'active' : '')}>
                                                                {tomorrow.starttime}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-4 pr-0">
                                                        <p className="mb-3 Simpluris-request-call-timebox-title">{dateFormat(dayAfterDate, "dddd mm/dd")}</p>
                                                        {dayaftertomorrow.map((dayaftertomorrow, i) => (
                                                            <div onClick={() => getSelectedTime(dayaftertomorrow.time, 'dayaftertomorrow', i)} key={i}
                                                                data-calenderid={dayaftertomorrow.calenderID} data-timeslot-day="dayaftertomorrow" data-slot-id={i}
                                                                className={"col-12 px-3 mt-2 text-center Simpluris-request-call-timebox " + (selectedTime == dayaftertomorrow.time && selectedDayType == 'dayaftertomorrow' ? 'active' : '')}>
                                                                {dayaftertomorrow.starttime}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-4 pr-0">
                                                        <p className="mb-3 Simpluris-request-call-timebox-title">{dateFormat(dayFourDate, "dddd mm/dd")}</p>
                                                        {dayFourTomorrow.map((dayFourTomorrow, i) => (
                                                            <div onClick={() => getSelectedTime(dayFourTomorrow.time, 'dayfourtomorrow', i)} key={i}
                                                                data-calenderid={dayFourTomorrow.calenderID} data-timeslot-day="dayfourtomorrow" data-slot-id={i}
                                                                className={"col-12 px-3 mt-2 text-center Simpluris-request-call-timebox " + (selectedTime == dayFourTomorrow.time && selectedDayType == 'dayfourtomorrow' ? 'active' : '')}>
                                                                {dayFourTomorrow.starttime}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-4 pr-0">
                                                        <p className="mb-3 Simpluris-request-call-timebox-title">{dateFormat(dayFiveDate, "dddd mm/dd")}</p>
                                                        {dayFiveTomorrow.map((dayFiveTomorrow, i) => (
                                                            <div onClick={() => getSelectedTime(dayFiveTomorrow.time, 'dayfivetomorrow', i)} key={i}
                                                                data-calenderid={dayFiveTomorrow.calenderID} data-timeslot-day="dayfivetomorrow" data-slot-id={i}
                                                                className={"col-12 px-3 mt-2 text-center Simpluris-request-call-timebox " + (selectedTime == dayFiveTomorrow.time && selectedDayType == 'dayfivetomorrow' ? 'active' : '')}>
                                                                {dayFiveTomorrow.starttime}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-4 pr-0">
                                                        <p className="mb-3 Simpluris-request-call-timebox-title">{dateFormat(daySixDate, "dddd mm/dd")}</p>
                                                        {daySixTomorrow.map((daySixTomorrow, i) => (
                                                            <div onClick={() => getSelectedTime(daySixTomorrow.time, 'daysixtomorrow', i)} key={i}
                                                                data-calenderid={daySixTomorrow.calenderID} data-timeslot-day="daysixtomorrow" data-slot-id={i}
                                                                className={"col-12 px-3 mt-2 text-center Simpluris-request-call-timebox " + (selectedTime == daySixTomorrow.time && selectedDayType == 'daysixtomorrow' ? 'active' : '')}>
                                                                {daySixTomorrow.starttime}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                {timeSlotReqError ? <h6 className="my-3 text-danger">Please select time slot</h6> : null}
                                                <div className="form-group mt-2 mb-3">
                                                    <div className="col-12 pl-0">
                                                        <div className="mb-2">
                                                            <div className="checkbox">
                                                                <label className="md-check SimplurisNewCaseTextColor SimplurisFontSize16 SimplurisFontWeight400">
                                                                    <input type="checkbox" checked={okToCallSooner} onChange={handleChecked} />
                                                                    <i className="Simpluris-request-call-checkbox"></i>
                                                                    Ok to call you earlier?
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-12 pl-0 ${okToResearchArticleCheckBoxHideShow == true ? 'd-none' : 'd-block'}`}>
                                                        <div className="mb-2">
                                                            <div className="checkbox">
                                                                <label className="md-check SimplurisNewCaseTextColor SimplurisFontSize16 SimplurisFontWeight400">
                                                                    <input type="checkbox" checked={okToResearchArticle} onChange={SendResearchArticleChange} />
                                                                    <i className="Simpluris-request-call-checkbox"></i>
                                                                    OK to send me legal research articles.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={RequestCallStep2Click} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3 px-4 SimplurisFontSize14 SimplurisFontWeight400">
                                                    Submit
                                                </button>
                                                <button type="button" onClick={CancelClick} className="btn rounded-0 SimplurisWhiteBackgroundButton ml-3 mb-1 btn-light py-3 px-4 SimplurisFontSize14 SimplurisFontWeight400">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        : null}
                                    {showStep3 ?
                                        <div className="col-md-6" id="step3-body">
                                            <div className="p-4">
                                                <h4 className="SimplurisNewCaseCreatelabel">All Set!</h4>
                                                <p className="mb-1 mt-4 SimplurisRatedTitle">
                                                    All Set! We will speak with you
                                                </p>
                                                <span className="Simpluris-request-call-setall"> {scheduledTime}</span>
                                                <p className="mb-1 mt-5 SimplurisRatedTitle">
                                                    We also sent a calendar invite to:
                                                </p>
                                                <span className="Simpluris-request-call-setall"> {scheduledEmail}</span>
                                                <div className="mt-5">
                                                    <Link to={"/home-logged-in-case"} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor mb-1 btn-light py-3 px-4 SimplurisFontSize14 SimplurisFontWeight400">Return home</Link>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPageLoader ? <PageLoader /> : null}
            </div>
        </div>
    )
}

export default RequestCallPage;

class ErrorPlacement extends Component {
    render() {
        if (this.props.items != "") {
            return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
                {this.props.items}
            </div>
        }
    }
}