import { simplurisFetch } from 'helpers'

export const FETCH_LOGIN_DETAILS = 'FETCH_LOGIN_DETAILS'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'

export const updateLoginDetail = userCases => {
  return {
    type: FETCH_LOGIN_DETAILS,
    userCases,
  }
}

export const signIn = ({ loginData }) => async dispatch => {
  const authInfo = await simplurisFetch({
    url: 'auth',
    method: 'POST',
    json: true,
    body: JSON.stringify(loginData),
  })

  dispatch({
    type: USER_LOGIN,
    payload: authInfo,
  })

  return authInfo
}
export const signOut = () => ({ type: USER_LOGOUT })
