import { FETCH_CASE_FILES_DATA, RESET_CASE_FILES_DATA } from './actions'

const initialState = {
    
}

export default (state = initialState, { type, caseFilesData, caseID, ResetCaseFilesDataCaseID }) => {
    switch (type) {        
        case FETCH_CASE_FILES_DATA:
            return {
                ...state,                    
                [caseID]: caseFilesData,                   
            }   
        case RESET_CASE_FILES_DATA:
            return state[ResetCaseFilesDataCaseID] = {}
        default:
            return state
    }
}
