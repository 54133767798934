import { simplurisFetch } from 'helpers'
import { ReactComponent as Circle } from 'icons/circle.svg'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BootstrapTable, SearchField, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import { SectionSpinner } from 'react/components/spinners'
import { lightGray, primaryBlue, red, teal } from 'shared/colors'
import { getFetchingRecipients, getMailingsRecipients, getSelectedMailingID } from 'state/case-detail-view/mailings/other/selectors'
import { fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID } from 'state/mailings/history/actions'
import { fetchMailingsRecipients } from 'state/mailings/other/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'
import styled from 'styled-components'

function MailingRecipientsPage({
    isFetchingMailingRecipients,
    fetchMailingsRecipients,
    mailingsRecipients = {},
    userPermissions = {},
    CaseID,
    selectedMailingID,
    mailHistoryDetail,
    fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID,
}) {

    var {
        pageIndex = 1,
        pageSize = 10,
        totalCount = 0,
        searchValue,
        sortColumn = 'recipientPersonID',
        sortColumnDir = 'desc',
        data = [],
    } = mailingsRecipients


    var viewFirstNamePermission
    var viewLastNamePermission
    if (Object.keys(userPermissions).length > 0) {
        viewFirstNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_FIRSTNAME'
        )
        viewLastNamePermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_LASTNAME'
        )
    }

    const [Text, setText] = useState('')


    const noMailingsRecipients = !data || !data.length

    const showLoader = isFetchingMailingRecipients && noMailingsRecipients

    React.useEffect(() => {
        fetchMailingsRecipients(
            CaseID,
            selectedMailingID,
            pageIndex,
            pageSize,
            sortColumn,
            sortColumnDir,
            searchValue
        )
    }, [])

    function columnClassNameFormat() {
        return 'td-column'
    }

    function isExpandableRow(row) {
        if (row.mailingHistoryID > 0)
            return true;
        else
            return false;
    }

    function expandComponent(row) {
        return <MailingRecipientsTrackingTable
            MailingHistoryID={row.mailingHistoryID}
            CaseID={CaseID}
            fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID={fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID}
            mailHistoryDetail={mailHistoryDetail} />
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }

    function onSearchChange(searchText) {
        searchValue = searchText
        setText(searchText)

        if (searchText == '') setText('')

        searchText = searchText == '' ? null : searchText
        fetchMailingsRecipients(
            CaseID,
            selectedMailingID,
            1,
            pageSize,
            sortColumn,
            sortColumnDir,
            searchText
        )
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    function onPageChange(page, sizePerPage) {
        fetchMailingsRecipients(
            CaseID,
            selectedMailingID,
            page,
            sizePerPage,
            sortColumn,
            sortColumnDir,
            searchValue
        )
    }

    function onSizePerPageList(sizePerPage) {
        fetchMailingsRecipients(
            CaseID,
            selectedMailingID,
            1,
            sizePerPage,
            sortColumn,
            sortColumnDir,
            searchValue
        )
    }

    function onSortChange(sortColumn, sortColumnDir) {
        fetchMailingsRecipients(
            CaseID,
            selectedMailingID,
            1,
            pageSize,
            sortColumn,
            sortColumnDir,
            searchValue
        )
    }

    function setTableOption() {
        //if (data.length <= 0) {
        //  return 'No Data Found!'
        //  }        
        if (isFetchingMailingRecipients) {
            return <SectionSpinner />
        }
        else {
            return "no data found!";
        }
    }

    const defaultSorted = [
        {
            dataField: 'recipientPersonID',
            order: 'desc',
        },
    ]

    const MailingRecipientsData = ({
        data,
        pageIndex,
        pageSize,
        totalSize,
        onPageChange,
        onSizePerPageList,
        onSearchChange,
        onSortChange,
    }) => (
            <div data-id={pageSize}>
                <BootstrapTable
                    remote={true}
                    data={data}
                    expandableRow={isExpandableRow}
                    expandComponent={expandComponent}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: totalSize }}
                    search={true}
                    options={{
                        searchField: CreateCustomSearchField,
                        toolBar: createCustomToolBar,
                        sortIndicator: true,
                        noDataText: setTableOption(),
                        sizePerPage: pageSize,
                        onPageChange: onPageChange,
                        sizePerPageList: [10, 20, 35],
                        page: pageIndex,
                        onSizePerPageList: onSizePerPageList,
                        onSearchChange: onSearchChange,
                        searchDelayTime: 1000,
                        onSortChange: onSortChange,
                        sortName: sortColumn,
                        sortOrder: sortColumnDir,
                        expandRowBgColor: 'rgb(255, 255, 255)',
                        onlyOneExpanding: true,
                    }}
                    tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover expand-table"
                    bodyContainerClass="text-grid-td"
                    defaultSorted={defaultSorted}
                    style={{ 'margin-bottom': '0px' }}
                >
                    <TableHeaderColumn
                        dataField="recipientPersonID"
                        searchable={false}
                        width={'14%'}
                        dataSort={true}
                    >
                        Person ID
        </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="mailingHistoryID"
                        isKey
                        hidden
                        searchable={false}
                    >
                        Mailing HistoryID
        </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="recipientName"
                        hidden={
                            viewFirstNamePermission && viewFirstNamePermission.length > 0
                                ? false
                                : true
                        }
                        columnClassName={columnClassNameFormat}
                        width={'20%'}
                        dataSort={true}
                    >
                        Mailing Recipient
        </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="recipientAddressString"
                        columnClassName={columnClassNameFormat}
                        width={'18%'}
                    >
                        Mailing Address
        </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="mailingHistoryTypeTitle"
                        columnClassName={columnClassNameFormat}
                        width={'18%'}
                    >
                        Mail Type
        </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="mailingHistoryStatus"
                        columnClassName={columnClassNameFormat}
                        width={'18%'}
                    >
                        Status
        </TableHeaderColumn>
                </BootstrapTable>
            </div>
        )

    return (
        <div>
            <div className="card SimplurisDashboardCard">
                {(() => {
                    if (showLoader) {
                        return <SectionSpinner />
                    }
                })()}
                <div className="d-flex flex row">
                    <div className="col-md-12">
                        {isFetchingMailingRecipients ? <div className="row"><SectionSpinner /></div> : null}
                        <div className="card d-flex flex p-3 mb-0">
                            <div className="col-md-12 mb-2 pl-0">
                                <span className="SimplurisSubTabTitle">Activity</span>
                            </div>

                            <div className="SimplurisMailingRecipientsGrid">
                                {(() => {
                                    if (!isFetchingMailingRecipients) {
                                        return (
                                            <MailingRecipientsData
                                                data={data}
                                                pageIndex={pageIndex}
                                                pageSize={pageSize}
                                                totalSize={totalCount}
                                                onPageChange={onPageChange}
                                                onSizePerPageList={onSizePerPageList}
                                                onSearchChange={onSearchChange}
                                                onSortChange={onSortChange.bind(this)}
                                            />
                                        )
                                    }

                                })()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(
    (state, props) => ({
        isFetchingMailingRecipients: getFetchingRecipients(state, props),
        mailingsRecipients: getMailingsRecipients(state, props),
        userPermissions: getPermissionsForCase(state, props),
        selectedMailingID: getSelectedMailingID(state, props),
        mailHistoryDetail: state.mailings.history.MailingHistoryDetail,
    }),
    {
        fetchMailingsRecipients,
        fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID
    }
)(MailingRecipientsPage)

function MailingRecipientsTrackingTable({ CaseID, MailingHistoryID }) {

    const SectionContainer = styled.div`
  flex: 1 1 0%;
padding-bottom: 10px;
`
    const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
    const Flex = styled.div`
  display: flex;
position: relative;

`
    const StyledCircle = styled(Circle)`
  height: 20px;
  width: 20px;
  ellipse {
    stroke: ${props => (props.status.toLowerCase() == 'sent' || props.status.toLowerCase() == 'forwarded' ? primaryBlue : props.status.toLowerCase() == 'returned' ? red : teal)};
  }
`
    const Date = styled.div`
  color: ${lightGray};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
`
    const Status = styled.div`
  color:${props => (props.mstatus.toLowerCase() == 'sent' || props.mstatus.toLowerCase() == 'forwarded' ? primaryBlue : props.mstatus.toLowerCase() == 'returned' ? red : teal)};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
`

    const Line = styled.div`
  border: 1px solid #4a90e2;
  opacity: 0.96;
    height: 29px;
    position: absolute;
    left: 9px;
    top: 18px;
`

    const ScanTimeSectionAndCityName = styled.div`
color: #647787;
  font-size: 14px;
  font-weight: 400;
`

    const [recipientData, SetRecipientData] = useState([]);
    const [showPageLoader, setShowPageLoader] = useState(false);

    function fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID(CaseID, MailingHistoryID) {
        SetRecipientData([]);
        setShowPageLoader(true);
        simplurisFetch({ url: `cases/${CaseID}/mailtrackingdatabydrilldowngetdetailsbymailinghistoryid/${MailingHistoryID}` }).then((result) => {
            setShowPageLoader(false);
            if (result.resultCode !== "ERROR") {
                SetRecipientData(result.resultObject.trackingItems);
            }
        })
            .catch(e => {
                setShowPageLoader(false);
                console.error("Error in mailtrackingdatabydrilldowngetdetailsbymailinghistoryid ", e, e.errorObject);
            });
    }

    useEffect(() => {
        try {
            fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID(CaseID, MailingHistoryID);
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [1])


    if (recipientData && showPageLoader) {
        return <div className="row"><SectionSpinner /></div>
    }

    if (recipientData && recipientData.length > 0) {
        return (
            <>
                {recipientData != undefined && recipientData.map((item, i) => {
                    const momentObj = moment(item.scanDate)
                    return (
                        <div className="w-50">
                            <Flex key={i}>
                                <StyledCircle status={item.mailingHistoryTypeTitle} />
                                {recipientData.length == (i + 1) ? <></> : <Line></Line>}
                                <SectionContainer>
                                    <Section>
                                        <Flex>
                                            <Date>{momentObj.format('ddd, l')}</Date>
                                        </Flex>

                                        {(
                                            () => {
                                                if (recipientData.length == (i + 1)) {
                                                    return <Status mstatus={item.mailingHistoryTypeTitle}>
                                                        {item.mailingHistoryTypeTitle === 'Mailed'
                                                            ? 'FINAL'
                                                            : item.mailingHistoryTypeTitle}
                                                    </Status>
                                                }
                                                else {
                                                    return <Status mstatus="IN TRANSIT">IN TRANSIT</Status>
                                                }
                                            }
                                        )()}


                                    </Section>
                                    <Section>
                                        <ScanTimeSectionAndCityName>{item.scanTime}</ScanTimeSectionAndCityName>
                                        <ScanTimeSectionAndCityName>
                                            {item.city}, {item.state}
                                        </ScanTimeSectionAndCityName>
                                    </Section>
                                </SectionContainer>
                            </Flex>
                        </div>)
                })
                }
            </>
        )
    } else {
        return null;
    }
}