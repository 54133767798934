import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import ShareReportModalPopup from '../content/live-report/share-report-modal'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { setTimeout } from 'timers';

const SubtabButton = ({ caseObject, caseSubtab, changeCaseSubtab, setPreviousCaseSubtab, previousCaseSubtabs, userPermissions }) => {

    const [showShareReportModalPopup, setShowShareReportModalPopup] = useState(false);

    function OpenShareReportModalPopup() {
        setShowShareReportModalPopup(true);
    }

    switch (caseSubtab) {
        case 'live-report':
            return (
                <div>
                    <input
                        type="button"
                        className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 "
                        value="Live Report"
                        id="btnLiveReport"
                        name="btnLiveReport"
                        onClick={() => OpenShareReportModalPopup()}
                    />
                    <ShareReportModalPopup OpenModalPopup={showShareReportModalPopup} setShowShareReportModalPopup={setShowShareReportModalPopup} />
                </div>
            )
        case 'milestone':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="All Milestones"
                    id="btnAllMilestones"
                    name="btnAllMilestones"
                />
            )
        case 'team':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="All Team Members"
                    id="btnDisbursementOverview"
                    name="btnDisbursementOverview"
                />
            )
        case 'case-info':
            return (
                <>
                    <ul className="nav" id="myCaseTab" role="tablist">
                        <li className="nav-item">
                            <a className="active show btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border" id="caseinfo-tab" data-toggle="tab" href="#caseinfo" role="tab" aria-controls="caseinfo" aria-selected="true">Case Info</a>
                        </li>
                        <li className="nav-item ml-1">
                            <a className="btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border" id="attorney-tab" data-toggle="tab" href="#attorney" role="tab" aria-controls="attorney" aria-selected="false">Attorney</a>
                        </li>
                        <li className="nav-item ml-1">
                            <a className="btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border" id="casedocument-tab" data-toggle="tab" href="#casedocument" role="tab" aria-controls="casedocument" aria-selected="false">Case Documents</a>
                        </li>
                    </ul>
                </>
            )
        case 'member':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="Case Members"
                    id="btnCaseMembers"
                    name="btnCaseMembers"
                />
            )
        case 'member-detail':
            var previousTab = previousCaseSubtabs.length > 0 ? previousCaseSubtabs[previousCaseSubtabs.length - 1] : '';
            if (previousTab == 'responses') {
                return (
                    <a
                        href={null}
                        onClick={() => { changeCaseSubtab('responses'); setPreviousCaseSubtab('responses') }}
                    >
                        <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                            <i />
                        </i>
                        <span className="SimplurisCasesPageTitle">Back</span>
                    </a>
                )
            }
            else if (previousTab == 'distributions') {
                return (
                    <a
                        href={null}
                        onClick={() => { changeCaseSubtab('distributions'); setPreviousCaseSubtab('distributions') }}
                    >
                        <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                            <i />
                        </i>
                        <span className="SimplurisCasesPageTitle">Back</span>
                    </a>
                )
            }
            else if (previousTab == 'call center') {
                return (
                    <a
                        href={null}
                        onClick={() => { changeCaseSubtab('call center'); setPreviousCaseSubtab('call center') }}
                    >
                        <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                            <i />
                        </i>
                        <span className="SimplurisCasesPageTitle">Back</span>
                    </a>
                )
            }
            else if (previousTab == 'activity') {                
                return (
                    <a
                        href={null}
                        onClick={() => { changeCaseSubtab('activity'); setPreviousCaseSubtab('activity') }}
                    >
                        <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                            <i />
                        </i>
                        <span className="SimplurisCasesPageTitle">Back</span>
                    </a>
                )
            }
            else {
                return (
                    <a
                        href={null}
                        onClick={() => { changeCaseSubtab('member'); setPreviousCaseSubtab('member') }}
                    >
                        <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                            <i />
                        </i>
                        <span className="SimplurisCasesPageTitle">Back</span>
                    </a>
                )
            }
        case 'team-member-detail':
            return (
                <a
                    href={null}
                    onClick={() => { changeCaseSubtab('team'); setPreviousCaseSubtab('team') }}
                    className="SimplurisBackbuttonBorder"
                >
                    <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                        <i />
                    </i>
                    <span className="SimplurisCasesPageTitle">Back</span>
                </a>
            )
        case 'mailing':
            return (
                <>
                    <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                        <i />
                    </i>
                    <span className="SimplurisCasesPageTitle">Back</span>
                </>
            )
        case 'responses':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="Responses"
                    id="btnResponses"
                    name="btnResponses"
                />
            )
        case 'mail-tracking':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="Manage Mailing"
                    id="btnManageMailing"
                    name="btnManageMailing"
                />
            )
        case 'disbursements':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="Disbursements"
                    id="btnDisbursements"
                    name="btnDisbursements"
                />
            )
        case 'reports':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="All Reports"
                    id="btnDisbursementOverview"
                    name="btnDisbursementOverview"
                />
            )
        case 'files':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="All Files"
                    id="btnDisbursementOverview"
                    name="btnDisbursementOverview"
                />
            )
        case 'case-file-detail':
            return (
                <a
                    href={null}
                    onClick={() => { changeCaseSubtab('files'); setPreviousCaseSubtab('files') }}
                    className="SimplurisBackbuttonBorder"
                >
                    <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                        <i />
                    </i>
                    <span className="SimplurisCasesPageTitle">Back</span>
                </a>
            )
        case 'case-report-detail':
            return (
                <a
                    href={null}
                    onClick={() => { changeCaseSubtab('reports'); setPreviousCaseSubtab('reports') }}
                    className="SimplurisBackbuttonBorder"
                >
                    <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                        <i />
                    </i>
                    <span className="SimplurisCasesPageTitle">Back</span>
                </a>
            )
        case 'call center':
            return (
                <>
                    <ul className="nav" id="myCallCenterTab" role="tablist">
                        <li className="nav-item">
                            <a className="btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border active show" id="callsupport-tab" data-toggle="tab" href="#callsupport" role="tab" aria-controls="callsupport" aria-selected="true">Call support Overview</a>
                        </li>
                        <li className="nav-item ml-1">
                            <a className="btn btnDisbursementOverview SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border" id="callmap-tab" data-toggle="tab" href="#callmap" role="tab" aria-controls="callmap" aria-selected="false">Call Map</a>
                        </li>
                    </ul>
                </>
            )
        case 'campaigns':
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 remove-bootstrap-persistent-button-border"
                    value="All Campaigns"
                    id="btnDisbursementOverview"
                    name="btnDisbursementOverview"
                />
            )
        case 'campaign-detail':
            return (
                <a
                    href={null}
                    onClick={() => { changeCaseSubtab('campaigns'); setPreviousCaseSubtab('campaigns') }}
                    className="SimplurisBackbuttonBorder"
                >
                    <i className="mr-2 ml-2 i-con i-con-arrow-left text-dark">
                        <i />
                    </i>
                    <span className="SimplurisCasesPageTitle">Back</span>
                </a>
            )
        default:
            return (
                <input
                    type="button"
                    className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500"
                    value={
                        caseObject && caseObject.stage ? caseObject.stage : 'Disbursement'
                    }
                    id="btnDisbursementOverview"
                    name="btnDisbursementOverview"
                />
            )
    }

}

export default connect(
    state => ({
        caseSubtab: state.caseDetailView.subtab,
        previousCaseSubtabs: state.previousSubTab.subTabNames,
    }),
    { changeCaseSubtab, setPreviousCaseSubtab, }
)(SubtabButton)
