import {
	FETCH_HOME_LOGGEDIN_CASE_ACTIVITY,
	ADD_HOME_LOGGEDIN_CASE_ACTIVITY_POST,
} from './actions'
import { resolved } from 'state/helpers'

const initialState = {}

export default (state = initialState, { type, HomeLoggedinCaseActivity, post }) => {
	switch (type) {
		case resolved(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY):                        
			
			return {
				...state,
				...HomeLoggedinCaseActivity.caseActivity.reduce((obj, activity) => {
					obj[activity.caseActivityID] = activity
					return obj
				}, {}),				
				lastCaseId: HomeLoggedinCaseActivity.lastCaseId,
				isHasNewData: HomeLoggedinCaseActivity.isHasNewData				
			}			
		case ADD_HOME_LOGGEDIN_CASE_ACTIVITY_POST:            
			
			const activityToUpdate = state[post.caseActivityID]
			
			if (!activityToUpdate) {
				return state
			}
			
			return {
				...state,
				[post.caseActivityID]: {
					...activityToUpdate,
					posts: [...activityToUpdate.posts, post],
				},
			}
		default:
			return state
	}
}
