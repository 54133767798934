import React, { useState, useEffect } from 'react'
import Messagepng from '../../../static/img/ico-message.png'
import GlobalImage from '../../components/global-image-set';
import ChatBox from '../../components/chatbox'
import { labFeatures, appMode } from '../../../api-config'

function CaseManagerDetails({ data, getTeamMemberComponent, userPermissions }) {    
    var chatWithTeamMemberPermission;
    if (Object.keys(userPermissions).length > 0) {
        chatWithTeamMemberPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_TEAM_CHAT_WITH_TEAM_MEMBER'
        )

    }

    const [openNewMessage, setOpenNewMessage] = useState(false);

    const OpenNewMessage = (check) => {
        setOpenNewMessage(check);
    }

    //caseId
    if (data == null) {
        return <div className="col-md-12">
            <div className="text-center mt-3">Not available project manager</div>
        </div>
    }
    else {
        return (
            <>
                <div className="col-md-12">
                    <div className="list list-row cases-b-b">
                        <div className="list-item pl-0 position-relative" data-id="14">
                            <div>
                                <a href={null} className="SimplurisMouseRollOver" onClick={() => getTeamMemberComponent(0, data.caseManagerPersonID)}>
                                    <span className="w-40 avatar circle bg-warning-lt">
                                        <GlobalImage firstname={data.caseManagerName} image={data.profilePicURL} email={data.personEmail} dynamicClass="avatar-status circle off b-white avatar-right" isTeamMember="true" />
                                    </span>
                                </a>
                            </div>
                            <div className="flex">
                                <a href={null} onClick={() => getTeamMemberComponent(0, data.caseManagerPersonID)} className="item-author SimplurisMouseRollOver">{data.caseManagerName}</a>
                                <a href="#" className="item-company text-muted h-1x">
                                    {/*appMode == "sandbox" ? "Project Manager" : ""*/}
                                    {data.roleTitle}
                                </a>
                            </div>
                            {labFeatures &&
                                <span className="nav-badge position-absolute d-none d-xl-block SimplurisPersonBadge">
                                    <a href={null} onClick={() => OpenNewMessage(true)} className={`${chatWithTeamMemberPermission && chatWithTeamMemberPermission.length > 0 ? 'd-none' : 'd-none'}`}>
                                        <img src={Messagepng} className="img-chat mr-2" />
                                    </a>
                                </span>
                            }
                            {/*<span className="nav-badge position-absolute d-none d-xl-block SimplurisPersonBadge">
                            <a href={null} onClick={() => OpenNewMessage(true)}>
                                <b className="badge badge-sm badge-pill badge-primary SimplurisbadgeChat pl-3 pr-3">1</b>
                            </a>
                            </span> */}
                        </div>
                    </div>
                </div>
                {(
                    () => {
                        if (openNewMessage) {
                            return (
                                <div className={"aside-deck " + (openNewMessage != true ? 'hide' : '')} id="message-deck">
                                    <ChatBox setOpenNewMessage={setOpenNewMessage} openNewMessage={openNewMessage} firstname={data.caseManagerName} profileimage={data.profilePicURL} isCaseManager={true} caseManager={data.caseManagerName} />
                                </div>
                            )
                        }
                    }
                )()}
            </>
        )
    }
}

export default CaseManagerDetails
