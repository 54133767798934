import React, { useState, useEffect, useRef } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'

import Exportpng from 'static/img/export.png'
import { simplurisFetch } from 'helpers'

function PaymentDetailPage(props) {
    var dateFormat = require('dateformat');
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [paymentDetailsData, setPaymentDetailsData] = useState([]);

    const fetchPersonPaymentDetails = async (ledgerBaseID, baseID) => {




        simplurisFetch({
            url: "auth/getpaymentdetail/" + ledgerBaseID + '/' + baseID
        }).then((data) => {
            setShowPageLoader(false);            
            if (data.name == undefined && data.name != "FetchError") {                
                if (data.resultCode == "SUCCESS") {                    
                    setPaymentDetailsData(data.resultObject);
                }
            }
            }).catch(e => { console.error('Error in get payment detail', e, e.errorObject); setShowPageLoader(false); });       
    };

    useEffect(() => {
        setShowPageLoader(true);
        fetchPersonPaymentDetails(props.paymentDetailAttributeValues.ledgerBaseID, props.paymentDetailAttributeValues.base);
    }, []);


    return (
        <>
            {(
                () => {
                    return (
                        <div className="card SimplurisDashboardCard p-0">
                            <div className="SimplurisPaymentBackgroundColor">
                                <div className="p-2">
                                    <div className="px-4 py-4">
                                        <div className="row">
                                            <div className="col-lg-8 col-12">
                                                <div className="row mb-2">
                                                    <div className="col-lg-6 col-12">
                                                        <small className="SimplurisMutableTitle">Invoice ID:</small>
                                                        <div className="SimplurisMutableTitle">{paymentDetailsData.invoiceID}</div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-lg-6 col-12">
                                                        <small className="SimplurisDataTitle">{paymentDetailsData.invoiceTitle}</small>
                                                        <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">
                                                            {paymentDetailsData.caseProjectName}</div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <small className="SimplurisDataTitle">Date of Billing</small>
                                                        <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">
                                                            {dateFormat(paymentDetailsData.createdDateOnly, "mmmm dS, yyyy")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-lg-6 col-12">
                                                        <small className="SimplurisDataTitle">{paymentDetailsData.invoiceTitle == 'Case' ? 'Project Manager' : 'Project Manager'}</small>
                                                        <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">{paymentDetailsData.managerName ? paymentDetailsData.managerName : 'N/A'}</div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <small className="SimplurisDataTitle">Due Date</small>
                                                        <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">
                                                            {dateFormat(paymentDetailsData.dueDateOnly, "mmmm dS, yyyy")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-6 col-12 offset-xl-2 offset-0">
                                                        <input type="button" className="btn btn-secondary w-sm py-2 SimplurisFontSize11 SimplurisFontWeight400 text-white text-uppercase" value="Paid In Full" name="btnPaid" id="btnPaid" />
                                                        <img src={Exportpng} className="ml-0 ml-xl-3 mt-2 mt-xl-0" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-0 SimplurisHRPaymentBorderColor" />
                            {
                                Object.keys(paymentDetailsData).length > 0 ?
                                    paymentDetailsData.personPayment.map((item) => {
                                        return (
                                            <>
                                                <div className="list list-row mx-3">
                                                    <div className="list-item pl-0 position-relative">
                                                        <div className="flex">
                                                            <div className="SimplurisMutableTitle SimplurisFontSize14">{item.lineItemTitle}</div>
                                                            <div className="SimplurisMutableTitle">
                                                                {item.lineItemSubtext}
                                                            </div>
                                                        </div>
                                                        <span className="mx-3 position-absolute d-xl-block SimplurisPersonBadge SimplurisMutableTitle SimplurisFontSize14">
                                                            ${item.amount}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                            </>
                                        )
                                    }) : <></>
                            }

                            <hr className="my-0 SimplurisHRPaymentBorderColor" />
                            <div className="SimplurisDashboardBody">
                                <div className="list list-row my-2">
                                    <div className="list-item pl-0 position-relative">
                                        <div className="flex px-2">
                                            <div className="text-muted text-right SimplurisFontSize10 text-uppercase SimplurisOpacity09">Total</div>
                                            <div className="text-grid-sub-td text-right SimplurisFontSize21 SimplurisFontWeight400">
                                                ${
                                                    Object.keys(paymentDetailsData).length > 0 ?
                                                        paymentDetailsData.personPayment.reduce((totalAmount, items) => totalAmount + items.amount, 0)
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                }
            )()}
        </>
    )
}

export default PaymentDetailPage