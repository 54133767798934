import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import exportImage from 'static/img/export.png'
import SignImage from 'static/img/sign.png'
import RightBlueImage from 'static/img/right-blue.png'
import GlobalImage from 'react/components/global-image-set'
import { getAllCases } from 'state/cases/data/selectors'
import { SectionSpinner } from 'react/components/spinners'
import { getSelectedMemberID } from 'state/case-detail-view/members/selectors'
import { labFeatures, appMode } from '../../../../../api-config'

function ResponsesDetailView({ caseID, cases, data, setCurrentSelectedTab, selectedMemberID, setIsBackFromResponseScreen }) {    
    var caseDetail = cases[caseID].details;


    const [responsesViewDetail, setResponsesViewDetail] = useState([]);
    const [isShowLoader, setIsShowLoader] = useState(false);
    
    const fetchresponsesDetail = () => {
        setIsShowLoader(true);
        simplurisFetch({
            url: `cases/${caseID}/casemembers/responsesdetail/${selectedMemberID}`
        })
            .then(result => {                
                setIsShowLoader(false);
                if (result.resultCode == "SUCCESS") {                    
                    //setResponsesViewDetail(result.resultObject);
                    setResponsesViewDetail(result.resultObject.length > 0 ? result.resultObject : []);
                }
            })
            .catch(e => {
                setIsShowLoader(false);
                console.error("Error in view response details ", e, e.errorObject);
            });
    };

    useEffect(() => {
        fetchresponsesDetail();
    }, []);

    if (!isShowLoader) {
        return (
            <>
                {                    
                    responsesViewDetail.length > 0
                        ? responsesViewDetail.map((item, i) => (
                            <React.Fragment key={i}>
                                <ResponseView
                                    item={item}
                                    data={data}
                                    caseDetail={caseDetail}
                                    setCurrentSelectedTab={setCurrentSelectedTab}
                                    setIsBackFromResponseScreen={setIsBackFromResponseScreen}
                                />
                            </React.Fragment>
                        ))
                        :
                        <div className="card SimplurisDashboardCard p-4 mb-2 text-center">
                            No data found
	                </div>
                }

            </>
        )
    }
    else
    {
        return (
            <div className="card SimplurisDashboardCard "><SectionSpinner /></div>
            )
    }   
}

export default connect(
    (state, props) => ({
        cases: getAllCases(state, props),
        selectedMemberID: getSelectedMemberID(state, props),
    }), {}
)(ResponsesDetailView)

function ResponseView({ item, data, caseDetail, setCurrentSelectedTab, setIsBackFromResponseScreen }) {
    
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    var Rdate = new Date(item.dateReceived);
    var Sdate = new Date(item.dateSubmitted);
    var Signdate = new Date(item.dateSigned);
    const day = Rdate.getDate();
    const ReceivedDate = (('' + day).length < 2 ? '0' : '') + day;
    var dateReceived = monthNames[Rdate.getMonth()] + ' ' + ReceivedDate + ', ' + Rdate.getFullYear();

    const Sday = Sdate.getDate();
    const SubmittedDate = (('' + Sday).length < 2 ? '0' : '') + Sday;
    var dateSubmitted = monthNames[Sdate.getMonth()] + ' ' + SubmittedDate + ', ' + Sdate.getFullYear();

    const Signday = Signdate.getDate();
    const SignedDate = (('' + Signday).length < 2 ? '0' : '') + Signday;
    var dateSigned = monthNames[Signdate.getMonth()] + ' ' + SignedDate + ', ' + Signdate.getFullYear();

    function RedirectToActivityTab() {
        setCurrentSelectedTab('activity');
        setIsBackFromResponseScreen(true);
    }

    if (item.claimStatusTitle != 'Invalid') {
        return (
            <div className="card SimplurisDashboardCard p-0 mb-2">
                <div className="SimplurisResponseViewBackgroundColor">
                    <div className="p-2">
                        <div className="px-4 py-4">
                            <div className="row">
                                <div className="col-lg-8 col-xs-12 col-sm-12 col-12">
                                    <div className="row mb-2">
                                        <div className="col-lg-12 col-12">
                                            <small className="SimplurisMutableTitle">
                                                {item.claimID}
                                            </small>
                                            <div className="SimplurisMutableTitle SimplurisFontSize15">
                                                {item.title}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-lg-12 col-12">
                                            <div className="d-flex mt-1 align-items-center">
                                                <a onClick={() => RedirectToActivityTab()}>
                                                    <GlobalImage
                                                        isSandbox={appMode == "sandbox" ? "true" : "false"}
                                                        firstname={data.name}
                                                        image={data.profilePicURL}
                                                        email={data.personEmail}
                                                        isTeam="true"
                                                        dynamicClass="SimplurisActatarW-34 avatar circle bg-info-lt"
                                                    />
                                                </a>
                                                <div className="mx-3 flex">
                                                    <span className="SimplurisDashboradActivitySpan">
                                                        <a className="SimplurisMouseRollOver" onClick={() => RedirectToActivityTab()}>{data.firstName} {data.lastName}</a> submitted a <a className="SimplurisMouseRollOver">Claim Form.</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xs-12 col-sm-12 col-12">
                                    <div className="row">
                                        <div className={`col-lg-12 col-md-6 col-12 offset-xl-${labFeatures == true ? 2 : 4} offset-0`}>
                                            <button
                                                className={
                                                    'btn w-sm py-2 SimplurisFontSize11 SimplurisFontWeight400 text-white text-uppercase ' +
                                                    (item.claimStatusTitle == 'Valid'
                                                        ? 'SimplurisEggBlueBackGroudColor'
                                                        : '') +
                                                    (item.claimStatusTitle == 'Unresolved'
                                                        ? 'btn-secondary'
                                                        : '') +
                                                    (item.claimStatusTitle == 'Invalid'
                                                        ? 'red'
                                                        : '')
                                                }

                                            >
                                                {item.claimStatusTitle}
                                            </button>
                                            {labFeatures && <img src={exportImage} className="ml-md-0 ml-2 ml-xl-3 ml-md-3 mt-2 mt-xl-0" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-xs-12 col-sm-12 col-12">
                                    <small className="SimplurisDataTitle text-uppercase">Case</small>
                                    <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">
                                        {caseDetail.officialName}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xs-12 col-sm-12 col-12">
                                    <small className="SimplurisDataTitle text-uppercase">Claim Submission Date</small>
                                    <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">{dateSubmitted}</div>
                                </div>
                                <div className="col-lg-3 col-xs-12 col-sm-12 col-12">
                                    <small className="SimplurisDataTitle text-uppercase">Project Manager</small>
                                    <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">
                                        {caseDetail.caseManagerName}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <small className="SimplurisDataTitle text-uppercase">Postmark Date</small>
                                    <div className="text-grid-sub-td SimplurisFontSize11 SimplurisFontWeight400">
                                        {dateReceived}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-0 SimplurisHRPaymentBorderColor" />
                <div className="list list-row mx-3">
                    <div className="list-item pl-0 position-relative pb-1">
                        <div className="flex">
                            <small className="SimplurisDataTitle text-uppercase">Claim Questions</small>
                        </div>
                    </div>
                </div>
                {
                    item.responsesClaimQuestions &&
                    item.responsesClaimQuestions.map((items, j) =>
                        <React.Fragment key={j}>
                            <ClaimQuestion details={items} questionNo={(j + 1)} />
                        </React.Fragment>
                    )
                }
                <hr className="my-0 SimplurisHRPaymentBorderColor" />
                <div className="SimplurisDashboardBody">
                    <div className="row">
                        <div className="col-md-7 col-xs-12 col-sm-12">
                            <div className="d-flex p-4">
                                <label className="md-check SimplurisFontSize12 SimplurisFontWeight400">
                                    <input type="radio" name="radio" checked />
                                    <i className="primary-check"></i>
                                    By checking this box, under the penalty of perjury, I certify that all information i have provided is true and correct.
                                                        </label>
                            </div>
                        </div>
                        <div className="col-md-5 col-xs-12 col-sm-12">
                            <div className="d-flex h-100 align-items-center">
                                <div className="w-100 text-xl-left mt-3 text-uppercase pr-3 SimplurisFontSize15 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                    {(dateSigned != null && dateSigned != undefined) ?
                                        <img src={SignImage} className="Simpluris-sign-img" />
                                        :
                                        <>
                                            Signed and Agreed
                                            <hr className="Simpluris-hr" />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

function ClaimQuestion({ details, questionNo }) {
    return (
        <>
            <div className="list list-row mx-3">
                <div className="list-item pl-0 position-relative">
                    <div className="text-grid-sub-td SimplurisFontSize14 SimplurisFontWeight400 align-self-start px-0">
                        {questionNo}.
                    </div>
                    <div className="flex">
                        <div className="text-grid-sub-td SimplurisFontSize14 SimplurisFontWeight400">
                            {details.claimTemplateFieldTitle}
                        </div>
                        <div className="SimplurisMailingSubTitle SimplurisFontSize14 SimplurisFontWeight400">
                            {details.claimFieldValue}
                        </div>
                    </div>
                    <span className="mx-3 position-absolute d-xl-block SimplurisPersonBadge">
                        <img src={RightBlueImage} class="mr-2" />
                    </span>
                </div>
            </div>
            <hr className="my-0" />
        </>
    )
}