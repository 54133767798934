import React, { useState } from 'react'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import {
    BootstrapTable,
    TableHeaderColumn,
    SearchField
} from "react-bootstrap-table";
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { fetchCaseReportsData } from 'state/case-report/actions'
import { SectionSpinner } from 'react/components/spinners'
import ReportSubscribeModalPopup from './add-report-subscribe-modal'

function ReportsSubscribed({ caseID, caseReportsData, getReportsDetailComponent, getReportsCount, onTabChange, caseOfficialName }) {    
    getReportsCount(caseReportsData != undefined && caseReportsData != null ? caseReportsData.length : 0);

    const [caseIDs, setCaseIDs] = useState(caseID);

    const [reportIdForSaveReportSubscribe, setReportIdForSaveReportSubscribe] = useState(0);
    const [reportCodeForSaveReportSubscribe, setReportCodeForSaveReportSubscribe] = useState(0);
    const [reportTitleForSaveReportSubscribe, setReportTitleForSaveReportSubscribe] = useState('');

    const [showReportSubscriptionModalPopup, setShowReportSubscriptionModalPopup] = useState(false);

    const [showLoaderForIsSubscribed, setShowLoaderForIsSubscribed] = useState(false);

    const [isSubscribedReportByModalPopup, setIsSubscribedReportByModalPopup] = useState(false);


    function setTableOption() {       
            return "No data found";        
    }
    
    function CellFormatForToggleSubscribed(cell, row) {        
        if (row.isSubscribed == true) {
            return (
                <label className="ui-switch mt-1 mr-2">
                    <input type="checkbox" checked="checked" onClick={() => reportSubscribed(row.reportSubscriptionID,row.reportID,false)} />
                    <i></i>
                </label>
            );
        }
        else {
            return (
                <label className="ui-switch mt-1 mr-2">
                    <input type="checkbox" checked={isSubscribedReportByModalPopup == false ? '' : 'checked'} onClick={() => OpenReportSubscriptionModalPopup(row.reportID,row.reportCode,row.title)}/>
                    <i></i>
                </label>);
        }
    }


    const reportSubscribed = (reportSubscriptionID,reportID,value) => {                
        saveIsSubscribedReportByUser(reportSubscriptionID,reportID,value);
    }



    const saveIsSubscribedReportByUser = (reportSubscriptionID, reportID, value) => {        

        setShowLoaderForIsSubscribed(true);
        var data = {};
        data.ReportSubscriptionID = parseInt(reportSubscriptionID);
        data.CaseID = parseInt(caseIDs);
        data.ReportID = parseInt(reportID);
        data.IsSubscribeReport = value;
        
        simplurisFetch({
            url: "cases/reportissubscribed", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowLoaderForIsSubscribed(false);
            if (data.name != undefined && data.name == "FetchError") {
            }
            else {
                onTabChange("Subscribed Reports");
            }
        }).catch(e => {
            setShowLoaderForIsSubscribed(false);
            console.error('Error in saveIsSubscribedReportByUser', e, e.errorObject);
        });
    }

    function OpenReportSubscriptionModalPopup(reportID,reportCode,title) {         
        setReportIdForSaveReportSubscribe(reportID);
        setReportCodeForSaveReportSubscribe(reportCode);
        setReportTitleForSaveReportSubscribe(title);
        setShowReportSubscriptionModalPopup(true);
    }
   
    if (!caseReportsData) {
        return <div className="row"> <SectionSpinner /></div>
    }

    if (showLoaderForIsSubscribed) {
        return <div className="row"> <SectionSpinner /></div>
    }
    return (
        <>
            <div className="card SimplurisDashboardCard">
                <div className="d-flex flex row">
                    <div className="col-md-12">
                        <div className="card d-flex flex p-3 mb-0">
                            <div className="table-responsive">
                                <BootstrapTable
                                    data={caseReportsData}
                                    options={{
                                        noDataText: setTableOption(),
                                    }}
                                    style={{ "margin-bottom": "0px" }}
                                    tableContainerClass="table table-theme v-middle SimplurisCaseReportsGrid SimplurisSubscribedMainGird"
                                    bodyContainerClass="text-grid-td"
                                >
                                    <TableHeaderColumn
                                        dataField="reportID"
                                        width="80"
                                        isKey
                                    >
                                        Report ID
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="title"
                                        width="160"
                                    >
                                        Title
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="reportType"
                                        width="120"
                                    >
                                        Type
                                </TableHeaderColumn>                                 
                                    <TableHeaderColumn
                                        dataField="isSubscribed"
                                        dataFormat={CellFormatForToggleSubscribed}
                                        width="100"
                                    >
                                        Subscribed
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="scheduleText"
                                        width="150"
                                    >
                                        Schedule
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="lastGeneratedDateFormat"
                                        width="150"
                                    >
                                        Last Sent
                                </TableHeaderColumn>                                    
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReportSubscribeModalPopup
                OpenModalPopup={showReportSubscriptionModalPopup}
                setShowReportSubscriptionModalPopup={setShowReportSubscriptionModalPopup}
                caseID={caseID}
                onTabChange={onTabChange}
                reportIdForSaveReportSubscribe={reportIdForSaveReportSubscribe}
                reportCodeForSaveReportSubscribe={reportCodeForSaveReportSubscribe}
                reportTitleForSaveReportSubscribe={reportTitleForSaveReportSubscribe}
                setIsSubscribedReportByModalPopup={setIsSubscribedReportByModalPopup} />
        </>
    )
}

export default connect(
    (state, props) => ({
        caseReportsData: state.caseReports[props.caseID],
    }),
    {
        fetchCaseReportsData,
    }
)(ReportsSubscribed)