import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import {
    BootstrapTable,
    TableHeaderColumn,
    SearchField
} from "react-bootstrap-table";
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { fetchCaseReportsData } from 'state/case-report/actions'
import { SectionSpinner } from 'react/components/spinners'

function ReportsTables({ caseID, caseReportsData, getReportsDetailComponent, getReportsCount }) {
    
    getReportsCount(caseReportsData != undefined && caseReportsData != null ? caseReportsData.length : 0);
    const [reportID, setReportID] = useState('');


    function setTableOption() {
        return "no data found!";
    }

    const handleCellClick = (row) => {        
        if (row) {
            getReportsDetailComponent(row.caseID, row.reportID, row.url, row.title,row.createdDate);
        }
    };

    function linkCellFormatter(cell, row) {        
        return (
            <a
                className="SimplurisBlueColor SimplurisPointer"
                href={null}
                onClick={() => handleCellClick(row)}
                title="View PDF Report"
            >
                {cell}
            </a>
        );
    }


    function actionFormatter(cell, row) {        
        return (            
            <a
                className="SimplurisBlueColor SimplurisPointer"
                href={null}
                onClick={() => handleCellClick(row)}
                title="View PDF Report"
            >
                <i class="fa fa-eye" aria-hidden="true"></i>
            </a>
            );        
    }
    
    if (!caseReportsData) {                
        return <div className="row"> <SectionSpinner /></div>
    }
    return (
        <>
            <div className="card SimplurisDashboardCard">
                <div className="d-flex flex row">
                    <div className="col-md-12">
                        <div className="card d-flex flex p-3 mb-0">
                            <div className="table-responsive">
                                <BootstrapTable
                                    data={caseReportsData}
                                    options={{
                                        noDataText: setTableOption(),
                                    }}
                                    style={{ "margin-bottom": "0px" }}
                                    tableContainerClass="table table-theme v-middle SimplurisCaseReportsGrid"
                                    bodyContainerClass="text-grid-td"
                                >
                                    <TableHeaderColumn                                        
                                        dataField="reportHistoryID"
                                        isKey     
                                        width="70"
                                    >
                                        ID
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="title"                                        
                                        width="180"
                                    >
                                        Title
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="reportType"                                        
                                    >
                                        Type
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fullName"
                                        width="150"
                                    >
                                        User
                                </TableHeaderColumn>                                 
                                    <TableHeaderColumn
                                        dataField="createdDate"
                                        className="SimplurisCursorPointer"
                                        dataFormat={createdDate => new Date(createdDate).toLocaleDateString()}                                        
                                    >
                                        Date
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="CreatedBy" hidden
                                    >
                                        Created By
                                </TableHeaderColumn>   
                                    <TableHeaderColumn
                                        dataField=""
                                        width="70"
                                        dataFormat={actionFormatter}
                                        searchable={false}
                                    >Action</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    )
}

export default connect(
    (state, props) => ({
        caseReportsData: state.caseReports[props.caseID],
    }),
    {
        fetchCaseReportsData,
    }
)(ReportsTables)