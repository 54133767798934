import React from 'react';
import { Modal } from 'react-bootstrap';


function LogDetailModalPopup(props) {

    function CloseViewLogDetailModalPopup() {
        props.setShowViewLogDetailModalPopup(false);
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseViewLogDetailModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">
                            Log Detail
                    </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="SimplurisAddReportSubscribeModalPopUpBody">
                    <div className="d-flex flex mt-2">
                        <div className="SimplurisLogDetailsWarrperDiv">
                            {props.message}
                        </div>
                    </div>
                    <div className="text-right">
                        <button type="button"
                            className="btn rounded-0 text-white btn btn-primary w-sm py-3 mb-4 mt-3"
                            onClick={() => CloseViewLogDetailModalPopup()}> Close
                            </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LogDetailModalPopup
