import React, { Component, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import Header from '../../components/outer-header'
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png'
import PageLoader from '../../components/page-loader';
import { simplurisFetch } from 'helpers'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../global';
import TermsConditionsHTML from './terms-conditions'
import { Link } from 'react-router-dom';

function AgreeTermsConditionsPage() {
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [redirectToHomePage, setRedirectToHomePage] = useState(false);
    const [redirectToCaseDetailPage, setRedirectToCaseDetailPage] = useState(false);
    const [redirectToCaseDetailFilePage, setRedirectToCaseDetailFilePage] = useState(false);
    const [redirectToDashBoard, setRedirectToDashBoard] = useState(false);

    useEffect(() => {
        AnlyticsPageLog("Agree Terms Conditions page");
    }, [])


    const UpdatePersonLastAgreedToTermsByPersonID = () => {
        setShowPageLoader(true);
        simplurisFetch({
            url: "auth/updatepersonlastagreedtoterms",
            method: 'POST',
        }).then((data) => {
            setShowPageLoader(false);
            if (data.name != undefined && data.name == "FetchError") {
                if (data.errorObject.resultCode == "ERROR") {
                }
            }
            else
            {
                localStorage.setItem(global.LastAgreedToTerms, (!IsNullOrEmptyString(data.resultObjectCode) ? data.resultObjectCode : ''));
                if (!IsNullOrEmptyString(localStorage.getItem(global.ReportPDFURL))) {
                    setRedirectToCaseDetailPage(true);
                } else if (!IsNullOrEmptyString(localStorage.getItem(global.SecureFileURL))) {
                    setRedirectToCaseDetailFilePage(true);
                }
                else {
                    if (!IsNullOrEmptyString(localStorage.getItem(global.viewDashBoardpermission))){
                        setRedirectToHomePage(true); 
                    }
                    else{
                        setRedirectToDashBoard(true); 
                    }
                }                
            }                
            }).catch(e => {                
            console.error('Error in UpdatePersonLastAgreedToTermsByPersonID', e, e.errorObject);
            setShowPageLoader(false);
            if (e.errorObject.resultCode == "ERROR") {
            }
        });
    }

    return (

        <div>

            {redirectToHomePage ? <Redirect to="/home-logged-in-case" /> : null}
            {redirectToDashBoard ? <Redirect to="/cases" /> : null}
            {redirectToCaseDetailPage ? <Redirect to={{ pathname: `/cases/detail/${localStorage.getItem(global.CaseIDForReportPDF)}`, state: [{ reportID: 0, TabType: "ReportViewerDetail" }] }} /> : null}
            {redirectToCaseDetailFilePage ? <Redirect to={{ pathname: `/cases/detail/${localStorage.getItem(global.CaseIDForSecureFile)}`, state: [{ securefileID: 0, TabType: "SecureFileDetail" }] }} /> : null}
            <div className="layout-column bg-white">
                <Header />
                <div className="d-flex flex-column flex">
                    <div>
                        <div className="p-3">
                            <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                                <div className="row no-gutters">
                                    <div className="col-md-5 r-l" >
                                        <div className="p-4 d-flex flex-column">
                                            <div className="d-block">
                                                <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right">
                                                    <img src={BrandmarkLogo} className="w-50" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mt-3">
                                            <h4 className="SimplurisFontWeight400 SimplurisSignupStartedLableW-100">Agree to Terms & Conditions</h4>
                                            <div className="SimplurisTermsConditionsWrapper">
                                                <TermsConditionsHTML />
                                            </div>
                                        </div>
                                        <button type="button" onClick={UpdatePersonLastAgreedToTermsByPersonID} className="mt-4 btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3">I Agree</button>
                                        <p className="mt-3">
                                            <small className="text-muted">
                                                By clicking the "I Agree" button, you are confirming that you are {localStorage.getItem(global.FirstName)} {localStorage.getItem(global.LastName)}, and that you are agreeing to these Terms & Conditions.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPageLoader ? <PageLoader /> : null}
            </div>
        </div>
    )
}

export default AgreeTermsConditionsPage;
