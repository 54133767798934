import * as React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import styled from 'styled-components'

import { changeCaseActivityFilter } from 'state/case-detail-view/case-activity/actions'
import { getSelectedCase } from 'state/cases/data/selectors'

const Wrapper = styled.div`
	min-width: 300px;
`

const getOptions = caseDetail => {
    
    // Wait for API response to generate only available filter options
    if (!caseDetail || !caseDetail.caseActivity) {
        return []
    }

    
    const types = caseDetail.caseActivity.map(
        ({ caseActivityTypeTitle }) => caseActivityTypeTitle
    )

    
    const uniqueTypes = [...new Set(types)]
    const options = uniqueTypes.map(type => ({
        value: type,
        label: type,
    }))

    
    return [{ value: 'All Activity', label: 'All Activity' }].concat(options)
}

const HeaderContentForCaseActivity = ({
    caseActivityFilter,
    changeCaseActivityFilter,
    caseDetail,
}) => {        
    const filterOptions = getOptions(caseDetail)    
    return (
        <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-12">
                {
                    filterOptions.map((item) =>
                        item.label != undefined ? <input type="button" name={item.label} value={item.label} className={`SimplurisActivityFilterButton SimplurisMarginCaseFilterButtons ${(caseActivityFilter.includes(item.value)) || (caseActivityFilter.length <= 0 && item.value == "All Activity") ? 'active' : ''}`} onClick={() => changeCaseActivityFilter(item.value)} /> : null
                    )
                }

                {/*<input type="button" name="Mail Sent" value="Mail Sent" className="button SimplurisActivityFilterButton" onClick={() => changeCaseActivityFilter('Mailing Sent')} />
                <input type="button" name="Responses Received" value="Responses Received" className="button SimplurisActivityFilterButton" onClick={() => changeCaseActivityFilter('Audit Log Entry')} />
                <input type="button" name="Incoming Calls" value="Incoming Calls" className="button SimplurisActivityFilterButton" onClick={() => changeCaseActivityFilter('Funds Disbursed')} />
                <input type="button" name="Messages" value="Messages" className="button SimplurisActivityFilterButton" onClick={() => changeCaseActivityFilter('Funds Disbursed')} />
                <input type="button" name="Disbursements" value="Disbursements" className="button SimplurisActivityFilterButton" onClick={() => changeCaseActivityFilter('Funds Disbursed')} />
				<Wrapper>
					<Select
						value={filterOptions.find(
							({ value }) => value === caseActivityFilter
						)}
						onChange={({ value }) => changeCaseActivityFilter(value)}
						options={filterOptions}
						placeholder="Filter by Case Activity Type"
					/>
				</Wrapper>*/}
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4 col-4 text-right" />
        </div>
    )
}

export default connect(
    (state, props) => ({
        caseActivityFilter: state.caseDetailView.caseActivity.filter,
        caseDetail: getSelectedCase(state, props)
    }),
    { changeCaseActivityFilter }
)(HeaderContentForCaseActivity)