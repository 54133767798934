import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';
import DashboardHeader from '../../components/dashboard-header'
import CircleFakeLoader from '../../components/circle-fake-loader'
import SquareFakeLoader from '../../components/square-fake-loader'
import { fetchCase, updateCase } from 'state/cases/data/actions'
import MilestoneNext from '../../components/milestone-next'
import CaseDetailSidebar from './sidebar'
import CaseManagerDetails from './case-manager'
import CaseTeamMembers from './case-teammembers'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import Header from './header'
import Content from './content'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { AnlyticsPageLog } from "../../../global";
import { labFeatures } from '../../../api-config'
import ChatSection from '../../components/chatbox/chat-section'
import { appMode } from '../../../api-config'
import { setTimeout } from 'timers';
import { getSelectedCase } from 'state/cases/data/selectors'
import { selectMember, selectPaymentsDetail, selectResponsesDetail } from 'state/case-detail-view/members/actions'

const _pageSize = 10;

function CaseFeedCasePage({
    match,
    history,
    caseDetail,
    fetchCase,
    updateCase,
    caseSubtab,
    changeCaseSubtab,
    userPermissions,
    setPreviousCaseSubtab,
    previousCaseSubtabs,
    selectMember,
    selectPaymentsDetail,
    selectResponsesDetail
}) {
    const addTeamMemberPermission = userPermissions.find(
        (permissionCode) => permissionCode === 'LC_TEAM_ADD_TEAM_MEMBER'
    )

    var viewLiveReportScreenPermission = userPermissions.find(
        (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_IN_MENU'
    )

    var caseActivityPermission = userPermissions.find(
        (permissionCode) => permissionCode === 'LC_ACTIVITY_VIEW_IN_MENU'
    )

    var caseTeamPermission = userPermissions.find(
        (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_CASE_TEAM_CHAT'
    )

    //match from react router, allows us to pass in URL values like /cases/1027, etc...
    let lastCaseActivityID = 0
    let casesActivityCount = 0
    let hasNewPage = false
    const [showPageLoader, setShowPageLoader] = useState(false)
    const [showButtonLoader, setShowButtonLoader] = useState(false)
    const [hideLoaderForTabType, setHideLoaderForTabType] = useState(false)
    const [randomNumberForUpdateSidebarMenuCounts, setRandomNumberForUpdateSidebarMenuCounts] = useState(0)

    //if (history.location.state[0].TabType == "TeamMemberDetail" && history.location.state[0].caseManagerPersonID > 0) {
    //    setHideLoaderForTabType(true);
    //}    
    const caseObject = caseDetail ? caseDetail.details : null
    const caseID = match.params.caseID
    const teamMembers = caseDetail ? caseDetail.teamMembers : null
    const mileStones = caseDetail ? caseDetail.milestones : []
    const caseDetailCounts = caseDetail ? caseDetail.caseDetailCounts : []
    const [teamMemberID, setTeamMemberID] = useState(0)
    const [secureFileID, setSecureFileID] = useState(0)
    const [caseSecureFileData, setCaseSecureFileData] = useState([])
    const [reportID, setReportID] = useState(0)
    const [caseReportData, setCaseReportData] = useState('')
    const [reportGeneratedDate, setReportGeneratedDate] = useState('')
    const [reportTitle, setReportTitle] = useState('')
    const [hash, setHash] = useState('')
    const [campaignName, setCampaignName] = useState('')
    const [selectCampaignCaseID, setSelectCampaignCaseID] = useState('')

    let caseActivities =
        caseDetail && caseDetail.caseActivity ? caseDetail.caseActivity : []
    let selectedMilestones = null
    let nextRemainingMilestones = null
    let isCaseActivityLastRecordCovered = false

    if (caseObject) {
        localStorage.setItem(global.caseOfficialName, caseObject.officialName);
    }

    lastCaseActivityID =
        caseActivities.length > 0
            ? caseActivities[caseActivities.length - 1].caseActivityID
            : 0
    casesActivityCount = caseActivities.length > 0 ? caseActivities.length : 0
    isCaseActivityLastRecordCovered =
        caseActivities.length > 0
            ? caseActivities[caseActivities.length - 1].IsLastRecord
            : 0

    isCaseActivityLastRecordCovered =
        isCaseActivityLastRecordCovered == undefined
            ? false
            : isCaseActivityLastRecordCovered


    if (appMode == "sandbox") {
        isCaseActivityLastRecordCovered = true;
    }

    if (
        caseObject &&
        caseActivities.length % _pageSize == 1 &&
        isCaseActivityLastRecordCovered == false
    ) {
        caseActivities = caseActivities.slice(0, -1)
        hasNewPage = true
    }

    if (caseObject) {
        const curr = new Date() // get current date
        //const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
        //const last = first + 6 // last day is the first day + 6

        const first = curr.getDate() // First day is the day of the month - the day of the week
        const last = first + 30 // last day is the first day + 6

        const start = new Date(curr.setDate(first))
        const end = new Date(curr.setDate(last))

        var startMonth = '' + (start.getMonth() + 1),
            startDay = '' + start.getDate(),
            endMonth = '' + (end.getMonth() + 1),
            endDay = '' + end.getDate()
        if (startMonth.length < 2) startMonth = '0' + startMonth
        if (startDay.length < 2) startDay = '0' + startDay
        if (endMonth.length < 2) endMonth = '0' + endMonth
        if (endDay.length < 2) endDay = '0' + endDay

        const startDateOfNext30Days =
            start.getFullYear() + '/' + startMonth + '/' + startDay
        const lastDateOfNext30Days =
            end.getFullYear() + '/' + endMonth + '/' + endDay

        if (caseDetail.milestones != null) {
            caseDetail.milestones.map(function (e) {
                e.deadlineDateOnly = e.deadlineDateOnly.replace(/-/g, '/')
                return e
            })
            selectedMilestones = caseDetail.milestones.filter(m => {
                if (
                    new Date(m.deadlineDateOnly) >= new Date(startDateOfNext30Days) &&
                    new Date(m.deadlineDateOnly) <= new Date(lastDateOfNext30Days)
                ) {
                    return m
                }
            })

            nextRemainingMilestones = caseDetail.milestones.filter(m => {
                if (new Date(m.deadlineDateOnly) >= new Date(startDateOfNext30Days)) {
                    return m
                }
            })
        }
    }

    // TODO: this should be moved to the case-activity action creators and should update case-activity state. This component
    // should then be refactored to use case-activity state for case-activity rather than nesting activity within the case.
    const fetchCaseActivity = async lastArticleId => {
        const resultRaw = await fetch(
            global.API_URL +
            'cases/' +
            parseInt(caseID) +
            '/' +
            lastArticleId +
            '/' +
            _pageSize +
            '/activity',
            {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem(global.AuthTokenKey),
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        )

        const result = await resultRaw.json()
        if (result.data.resultCode == 'SUCCESS') {
            caseDetail.caseActivity = caseActivities.concat(result.data.resultObject)

            if (result.data.resultObject.length == 1) {
                caseDetail.caseActivity[caseDetail.caseActivity.length - 1][
                    'IsLastRecord'
                ] = true
            }

            updateCase(caseDetail)
            setShowButtonLoader(false)
        }
    }

    const getTeamMemberComponent = function (caseID, personID) {
        changeCaseSubtab('team-member-detail')
        setPreviousCaseSubtab('team')
        setTeamMemberID(personID)
    }

    const getCaseMemberDetailComponent = function (caseID, personID) {
        //changeCaseSubtab('member-detail')
        //setPreviousCaseSubtab('member')
        //setTeamMemberID(personID)

        //02Mar2020 Made this changes when activity available and click on username from activity tab redirect to member-detail screen.
        setTeamMemberID(personID)
        selectMember(personID)
        var responsesDetail = false;
        selectResponsesDetail(responsesDetail);
        var paymentsDetail = false;
        selectPaymentsDetail(paymentsDetail)
        changeCaseSubtab('member-detail')
        setPreviousCaseSubtab('activity')
    }

    const getCampaignDetailComponent = function (caseID, hash, campaignName) {
        setHash(hash);
        setCampaignName(campaignName);
        setSelectCampaignCaseID(caseID);
        changeCaseSubtab('campaign-detail')
        setPreviousCaseSubtab('campaign')
    }

    const getSecureFileDetailComponent = function (caseID, secureFileID, secureFileData) {
        setSecureFileID(secureFileID)
        setCaseSecureFileData(secureFileData)
        changeCaseSubtab('case-file-detail')
        setPreviousCaseSubtab('files')
    }

    const getReportsDetailComponent = function (caseID, reportID, reportData, reportTitle, createdDate) {
        setReportID(reportID)
        setCaseReportData(reportData)
        setReportTitle(reportTitle)
        setReportGeneratedDate(createdDate)
        changeCaseSubtab('case-report-detail')
        setPreviousCaseSubtab('reports')
    }

    const updateSidebarMenuCounts = function () {
        setRandomNumberForUpdateSidebarMenuCounts(Math.random());
    }


    useEffect(() => {
        AnlyticsPageLog("Cases Detail " + caseID + " page");
        try {
            if (
                history.location.state != undefined &&
                history.location.state[0].TabType == 'TeamMemberDetail' &&
                history.location.state[0].caseManagerPersonID > 0
            ) {
                changeCaseSubtab('team-member-detail')
                setPreviousCaseSubtab('team')
                setTeamMemberID(history.location.state[0].caseManagerPersonID)
                setHideLoaderForTabType(true)
            } else if (
                history.location.state != undefined &&
                history.location.state[0].TabType == 'MemberDetail' &&
                history.location.state[0].caseMemberID > 0
            ) {
                changeCaseSubtab('member-detail')
                setPreviousCaseSubtab('member')
                //setTeamMemberID(history.location.state[0].caseMemberID)
                selectMember(history.location.state[0].caseMemberID)
                setHideLoaderForTabType(true)
            }
            else if (
                history.location.state != undefined &&
                history.location.state[0].TabType == 'ReportViewerDetail'
            ) {
                changeCaseSubtab('reports')
                setPreviousCaseSubtab('reports')
                setHideLoaderForTabType(true)
            }
            else if (
                history.location.state != undefined &&
                history.location.state[0].TabType == 'SecureFileDetail') {
                changeCaseSubtab('files')
                setPreviousCaseSubtab('files')
                setHideLoaderForTabType(true)
            }
            else if (
                history.location.state != undefined &&
                history.location.state[0].TabType == 'MilestoneDetail'
            ) {
                changeCaseSubtab('milestone')
                setPreviousCaseSubtab('milestone')
                setHideLoaderForTabType(true)
            }
            else {

                if (userPermissions && userPermissions.length > 0) {
                    if (viewLiveReportScreenPermission && viewLiveReportScreenPermission.length > 0) {
                        changeCaseSubtab('live-report')
                        setPreviousCaseSubtab('live-report')
                    }
                    else {
                        changeCaseSubtab('activity')
                        setPreviousCaseSubtab('activity')
                    }
                }
            }

            fetchCase({ caseID })
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [fetchCase, caseID])

    const LoadMoreActivity = () => {
        setShowButtonLoader(true)
        fetchCaseActivity(lastCaseActivityID)
    }

    return (
        <div>
            {(typeof caseDetail) == "object" && Object.keys(caseDetail).length === 0 ? <Redirect to="/not-allowed-to-case" /> : null}
            <DashboardHeader />
            <div
                id="main"
                className="layout-row flex simplurisallcasesBodyBackgroudColour"
            >
                <CaseDetailSidebar
                    caseDetailCounts={caseDetailCounts}
                    caseID={caseID}
                    randomNumberForUpdateSidebarMenuCounts={randomNumberForUpdateSidebarMenuCounts}
                    data={caseObject}
                />
                <div id="content" className="flex">
                    <div className="page-container" id="page-container">
                        <Header caseObject={caseObject} caseID={caseID} />
                        <div className="padding pt-3">
                            <div className="row">
                                <div className="col-md-12 col-xl-8 col-lg-8 col-xs-12 col-sm-12">
                                    <Content
                                        selectedMilestones={selectedMilestones}
                                        nextRemainingMilestones={nextRemainingMilestones}
                                        mileStones={mileStones}
                                        teamMembers={teamMembers}
                                        caseID={caseID}
                                        getTeamMemberComponent={getTeamMemberComponent}
                                        teamMemberID={teamMemberID}
                                        caseObject={caseObject}
                                        caseActivities={caseActivities}
                                        hasNewPage={hasNewPage}
                                        LoadMoreActivity={LoadMoreActivity}
                                        showButtonLoader={showButtonLoader}
                                        getCaseMemberDetailComponent={getCaseMemberDetailComponent}
                                        getSecureFileDetailComponent={getSecureFileDetailComponent}
                                        getReportsDetailComponent={getReportsDetailComponent}
                                        secureFileID={secureFileID}
                                        caseSecureFileData={caseSecureFileData}
                                        reportID={reportID}
                                        caseReportData={caseReportData}
                                        reportTitle={reportTitle}
                                        reportGeneratedDate={reportGeneratedDate}
                                        tabType={
                                            history.location.state != undefined
                                                ? history.location.state[0].TabType
                                                : null
                                        }
                                        IscaseDetail={true}
                                        updateSidebarMenuCounts={updateSidebarMenuCounts}
                                        hash={hash}
                                        campaignName={campaignName}
                                        selectCampaignCaseID={selectCampaignCaseID}
                                        getCampaignDetailComponent={getCampaignDetailComponent}
                                    />
                                </div>

                                <div className="col-md-12 col-xl-4 col-lg-4 col-xs-12 col-sm-12 pl-lg-5 pl-md-5 pl-sm-5 pl-xs-0 d-none d-md-none d-xl-block d-lg-block">
                                    {(() => {
                                        switch (caseSubtab) {
                                            case 'team':
                                                return (
                                                    labFeatures &&
                                                    <div>
                                                        {addTeamMemberPermission ? (
                                                            <input
                                                                type="button"
                                                                name="btnNewTeamMember"
                                                                value="Add New Team Member"
                                                                className="btn btn-block SimplurisAllCasesRightSideButton btnNewTeamMember py-3"
                                                            />
                                                        ) : null}
                                                    </div>
                                                )
                                            default:
                                                return (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="SimplurisCaseRightSideSectionTitle mb-0">
                                                                    Next Milestones
                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {(() => {
                                                                if (
                                                                    selectedMilestones != null ||
                                                                    nextRemainingMilestones != null
                                                                ) {
                                                                    return (
                                                                        <MilestoneNext
                                                                            next30DaysMilestones={selectedMilestones}
                                                                            nextRemainingMilestones={
                                                                                nextRemainingMilestones
                                                                            }
                                                                        />
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div className="col-md-12">
                                                                            <SquareFakeLoader />
                                                                        </div>
                                                                    )
                                                                }
                                                            })()}
                                                        </div>
                                                    </>
                                                )
                                        }
                                    })()}
                                    <div className="row mt-4">
                                        <div className="col-md-12 pl-4">
                                            <label className="SimplurisCaseRightSideSectionTitle mb-0">
                                                Your Project Manager
                      </label>
                                        </div>

                                        {(() => {
                                            if (caseObject != null) {
                                                return (
                                                    <CaseManagerDetails
                                                        data={caseObject}
                                                        getTeamMemberComponent={getTeamMemberComponent}
                                                        userPermissions={userPermissions}
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <div className="col-md-12">
                                                        <CircleFakeLoader />
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                    {(caseTeamPermission && caseTeamPermission.length > 0) ?
                                    <div className="row mt-4">
                                        <div className="col-md-6 pl-4">
                                            <label className="SimplurisCaseRightSideSectionTitle mb-0">
                                                Case Team </label>
                                        </div>
                                        {/* <div className="col-md-6 text-right">
                                            <label className="SimplurisSeeAllLink mb-0"><a href="#">See All</a></label>
                                        </div>*/}
                                        <div className="col-md-12">
                                            <div className="list list-row">
                                                {(() => {
                                                    return teamMembers  != null ? (
                                                        <CaseTeamMembers
                                                            data={teamMembers}
                                                            getTeamMemberComponent={getTeamMemberComponent}
                                                            userPermissions={userPermissions}
                                                            caseID={match.params.caseID}
                                                        />
                                                    ) : (
                                                            <React.Fragment>
                                                                {Array(4)
                                                                    .fill('')
                                                                    .map((e, i) => (
                                                                        <CircleFakeLoader key={i} />
                                                                    ))}
                                                            </React.Fragment>
                                                        )
                                                })()}
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                    hideLoaderForTabType ? null : (caseObject ? null : <div className="SimplurisMainLoaderSection">
                    {showPageLoader ? <SectionSpinner /> : null}
                </div>)
                */}
            </div>
            {
                caseObject ? <ChatSection caseManagerName={caseObject.caseManagerName} caseManagerPersonMapId={28528} /> :
                    <ChatSection caseManagerName={''} caseManagerPersonMapId={28528} />
                //caseObject.caseManagerPersonMapID
            }
        </div>
    )
}

export default connect(
    (state, props) => ({
        caseDetail: getSelectedCase(state, props),
        caseSubtab: state.caseDetailView.subtab,
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
    }),
    {
        fetchCase,
        updateCase,
        changeCaseSubtab,
        setPreviousCaseSubtab,
        selectMember,
        selectResponsesDetail,
        selectPaymentsDetail
    }
)(CaseFeedCasePage)
