import { simplurisFetch } from 'helpers'
export const FETCH_CASE_FILES_DATA = 'FETCH_CASE_FILES_DATA'
export const RESET_CASE_FILES_DATA = 'RESET_CASE_FILES_DATA'
export const UPDATE_CASE_DATA_COUNTS_FOR_FILES = 'UPDATE_CASE_DATA_COUNTS_FOR_FILES'

export const fetchCaseFilesData = ({
    caseID,
    sortColumn,
    sortOrder,
    fileFlag,
    searchValue = null,
}) => async dispatch => {
    try {

        const url = `cases/${parseInt(caseID)}/files/${sortColumn}/${sortOrder}/${fileFlag}/${searchValue}`
        const { resultObject } = await simplurisFetch({ url })

        dispatch({
            type: FETCH_CASE_FILES_DATA,
            caseFilesData: resultObject,
            caseID,
        })

    } catch (e) {
        console.error('Error in case files data ', e, e.errorObject)

        dispatch({
            type: FETCH_CASE_FILES_DATA,
            caseFilesData: [],
            caseID,
        })
    }
}

export const fetchCaseDataCounts = (caseID) => async dispatch => {
    try {
        const url = `cases/fetchCaseDataCounts/${caseID}`
        let { resultObject } = await simplurisFetch({ url })
        dispatch({
            type: UPDATE_CASE_DATA_COUNTS_FOR_FILES,
            updatedCaseCountsObject: resultObject,
            caseIDForUpdatedCaseCountsObject: caseID
        })
    } catch (e) {
        console.error('Error in fetchCaseDataCounts', e, e.errorObject)

        dispatch({
            type: UPDATE_CASE_DATA_COUNTS_FOR_FILES,
            updatedCaseCountsObject: [],
            caseIDForUpdatedCaseCountsObject: caseID
        })
    }
}

export const resetCaseFilesData = (CaseID) => ({
    type: "RESET_CASE_FILES_DATA",
    ResetCaseFilesDataCaseID: CaseID
})