import * as React from 'react'
import Switch from 'react-switch'
import styled from 'styled-components'
import _ from 'lodash'

import { primaryBlue, lightGray } from 'shared/colors'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const Wording = styled.div`
  margin-right: 10px;
  text-transform: uppercase;
  color: ${lightGray};
  font-size: 12px;
`

const SecureAccessToggle = ({ checked, requestAccess, ...other }) => (
  <Wrapper>
    <Wording>{checked ? 'SECURE ACCESS ON' : 'REQUEST ACCESS'}</Wording>
    <Switch
      onChange={checked ? _.noop : requestAccess}
      onColor={primaryBlue}
      checkedIcon={false}
      uncheckedIcon={false}
      height={24}
      width={40}
      checked={checked || false}
      {...other}
    />
  </Wrapper>
)

export default SecureAccessToggle
