import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import SendReportModalPopup from './send-report-modal'
import atgray from '../../../../../static/img/at-gray.png'
import copyatgray from '../../../../../static/img/copy-alt-gray.png'
import { getPermissionsForCase } from 'state/permissions/selectors'

function ShareReportModalPopup({ userPermissions, ...props }) {
  
    var exportDataPermission
    var sendEmailPermission

    if (Object.keys(userPermissions).length > 0) {
        exportDataPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_LIVEREPORT_DOWNLOAD_EXPORT_DATA' || permissionCode == "LC_RESPONSES_DOWNLOAD_EXPORT_DATA" || permissionCode == "LC_MEMBER_DOWNLOAD_EXPORT_DATA"
        )
        sendEmailPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_LIVEREPORT_SEND_DATA_EMAIL' || permissionCode == "LC_MEMBER_SEND_DATA_EMAIL"
        )
    }

    const [showSendReportModalPopup, setShowSendReportModalPopup] = useState(
        false
    )

    function CloseShareReportModalPopup() {
        props.setShowShareReportModalPopup(false)
    }

    function OpenSendReportModalPopup() {
        props.setShowShareReportModalPopup(false)
        setShowSendReportModalPopup(true)
    }

    return (
        <>
            <Modal
                show={props.OpenModalPopup}
                onHide={CloseShareReportModalPopup}
                size="lg"
            >
                <Modal.Header className="Simpluris-modal-header" closeButton>
                    <Modal.Title>
                        {' '}
                        <h5 className="modal-title SimplurisBidRequestTitle">
                            Share Report
            </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center p-lg p-5">
                    <div className="row">
                        {(() => {
                            if (sendEmailPermission && sendEmailPermission.length > 0) {
                                return (
                                    <div className="col-md-6 col-xl-6">
                                        <div className="SimplurisBidRequestGridSqure active r-3x avatar mx-auto">
                                            <div className="text-center">
                                                <img src={atgray} />
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-4 col-xl-10 col-md-12 mx-auto SimplurisNewCaseTextColor SimplurisFontSize17 SimplurisFontWeight700">
                                            Email this report to yourself or others
                    </div>
                                        <div className="py-2">
                                            <a
                                                href="#"
                                                className="btn rounded-0 SimplurisBidRequestButton active"
                                                data-dismiss="modal"
                                                data-toggle="modal"
                                                onClick={() => OpenSendReportModalPopup()}
                                                data-toggle-className="fade-down"
                                                data-toggle-class-target="#animate"
                                            >
                                                Send Report
                      </a>
                                        </div>
                                    </div>
                                )
                            } else {
                                return <></>
                            }
                        })()}
                        {(() => {
                            if (exportDataPermission && exportDataPermission.length > 0) {
                                return (
                                    <div className="col-md-6 col-xl-6 mt-md-0 mt-4">
                                        <div className="SimplurisBidRequestGridSqure active r-3x avatar mx-auto">
                                            <div className="text-center">
                                                <img src={copyatgray} />
                                            </div>
                                        </div>
                                        <div className="px-0 mb-3 mt-4 col-xl-10 col-md-12 mx-auto SimplurisNewCaseTextColor SimplurisFontSize17 SimplurisFontWeight700">
                                            Download a PDF version for this report
                    </div>
                                        <div className="py-2">
                                            <a
                                                href="#"
                                                className="btn rounded-0 SimplurisBidRequestButton active"
                                            >
                                                Download Report
                      </a>
                                        </div>
                                    </div>
                                )
                            } else {
                                return <></>
                            }
                        })()}
                    </div>
                </Modal.Body>
            </Modal>
            <SendReportModalPopup
                OpenModalPopup={showSendReportModalPopup}
                setShowSendReportModalPopup={setShowSendReportModalPopup}
                setShowShareReportModalPopup={props.setShowShareReportModalPopup}
            />
        </>
    )
}

export default connect((state, props) => ({
  userPermissions: getPermissionsForCase(state, props),
}))(ShareReportModalPopup)
