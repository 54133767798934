export const getCasesForStage = ({ cases, stage }) => {
    
    var data = [];
    if (Object.keys(cases).length > 0) {                
        var casesJSON = JSON.parse(JSON.stringify(cases));
        delete casesJSON.lastCaseId;
        delete casesJSON.isHasNewData;        
        var splitArray = stage.toString().split(',');                
        data = Object.values(casesJSON).filter(({ caseStageID }) => {            
            if (!stage) {
                return true
            }                        
            return splitArray.indexOf(caseStageID != undefined ? caseStageID.toString() : '') != -1;           
        });
    }    
    return data;
}