import { FETCH_CASE_REPORTS_DATA, RESET_CASE_REPORTS_DATA } from './actions'

const initialState = {
    
}

export default (state = initialState, { type, caseReportsData, caseID, ResetCaseReportDataCaseID }) => {
    switch (type) {        
        case FETCH_CASE_REPORTS_DATA:            
            return {
                ...state,                    
                [caseID]:caseReportsData,                   
            }   
        case RESET_CASE_REPORTS_DATA:            
            return state[ResetCaseReportDataCaseID] = {}        
        default:
            return state
    }
}
