import * as React from 'react'
import { connect } from 'react-redux'
import { SectionSpinner } from 'react/components/spinners'
import ButtonLoader from '../../../components/button-loader';

import ActivityDetail from 'react/components/case-activity'

const CaseDetailActivity = ({
    caseActivityFilter,
    caseObject,
    caseActivities,
    hasNewPage,
    LoadMoreActivity,
    showButtonLoader,
    tabType,
    IscaseDetail,
    getCaseMemberDetailComponent,
    getTeamMemberComponent,
}) => {
    const filteredActivities =
        caseActivityFilter.length > 0
            ? caseActivities.filter(itemX =>
                caseActivityFilter.includes(itemX.caseActivityTypeTitle)
            )
            : caseActivities

    return (
        <div>
            {caseObject ? (
                filteredActivities.length > 0 ? (
                    filteredActivities.map(post => (
                        <React.Fragment key={post.caseActivityID}>
                            <ActivityDetail
                                data={post}
                                IscaseDetail={IscaseDetail}
                                getCaseMemberDetailComponent={getCaseMemberDetailComponent}
                                getTeamMemberComponent={getTeamMemberComponent}
                            />
                        </React.Fragment>
                    ))
                ) : (
                        <div className="text-center mt-5">
                            Activities not found for this case member
					</div>
                    )
            ) : (
                    <div className="row"> <SectionSpinner /></div>
                )}
            <div className="col-md-12 mt-2 text-center">
                {hasNewPage ? (
                    <button
                        className="btn w-md mb-4 mb-md-1 btn-light SimplurisDashbordPostText p-3"
                        onClick={LoadMoreActivity}
                        disabled={showButtonLoader} >
                        {
                            showButtonLoader && (
                                <ButtonLoader />
                            )
                        }
                        {showButtonLoader && <span></span>}
                        {!showButtonLoader && <span>Load More</span>}
                    </button>
                ) : null}
            </div>
        </div>
    )
}

export default connect(state => ({
    caseActivityFilter: state.caseDetailView.caseActivity.filter,
}))(CaseDetailActivity)
