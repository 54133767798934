import React, { useState, useEffect } from 'react';
import { simplurisFetch } from 'helpers'
import { IsNullOrEmptyString } from 'global'
import { Modal } from 'react-bootstrap';
import LeftArrowPNG from '../../../../../static/img/left-arrow.png'
import DatePicker1 from './run-report-date-picker'
import ButtonLoader from '../../../../components/button-loader'
import { SectionSpinner } from '../../../../components/spinners'

let startDate = null, endDate = null;
function RunReportTypeModalPopup(props) {
    
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [isElementHidden, setIsElementHidden] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);

    const [paramResponseObj, setParamResponseObj] = useState([]);


    function CloseRunReportTypeModalPopup() {
        
        setErrorMessage('');
        setSuccessMessage('');
        startDate = '';
        endDate = '';
        props.setShowRunReportType(false);
        props.setFlagForGetParamData(false);
        setParamResponseObj([]);
        setdynamicTextBoxValues('');
    }

    function BackOpenChooseReportTypeModalPopup() {
        
        setErrorMessage('');
        setSuccessMessage('');
        startDate = '';
        endDate = '';
        props.setShowRunReportType(false);
        props.setShowChooseReportTypeModalPopup(true);
        props.setShowRunReportType(false);
        props.setFlagForGetParamData(false);
        setParamResponseObj([]);
        setdynamicTextBoxValues('');
    }

    useEffect(() => {
        try {
            
            if (props.flagForGetParamData) {
                fetchParamsForReportByReportCode(props.reportCode);
            }
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [props.flagForGetParamData])

    const fetchParamsForReportByReportCode = async (reportCode) => {
        setShowPageLoader(true);
        simplurisFetch({
            url: `report/getparamsforreportbyreportcode/${reportCode}`
        }).then(result => {
            setShowPageLoader(false);
            
            if (result.resultCode == "SUCCESS") {
                
                setParamResponseObj(result.resultObject);
            }
        }).catch(e => {
            
            setShowPageLoader(false);
            console.error("Error in getparamsforreportbyreportcode ", e, e.errorObject);
        });
    }


    function updateDateRangeFilter(dateRange) {
        
        startDate = dateRange.start;
        endDate = dateRange.end;
    }

    const [dynamicTextBoxValues, setdynamicTextBoxValues] = useState('');

    function handleChange(reportParamData, i, e) {       
        const { name, value } = e.target;
        let data = [...dynamicTextBoxValues];
        data[i] = { ...data[i], 'Value': value, 'Title': reportParamData.title, 'ReportParamID': reportParamData.reportParamID };
        setdynamicTextBoxValues(data);
    }

    const GenerateReportsByCaseID = async (caseID, reportID, reportTitle, reportCode) => {
        
        var array = [];
        
        for (var i = 0; i < paramResponseObj.length; i++) {            
            
            var value = '';
            if (paramResponseObj[i].title == 'CaseID') {
                
                value = caseID;
            }
            else if (paramResponseObj[i].title == 'StartDate') {
                
                value = startDate;
            }
            else if (paramResponseObj[i].title == 'EndDate') {
                
                value = endDate;
            }
            else {       
                
                for (var j = 0; j < dynamicTextBoxValues.length; j++) {                    
                    
                    if (dynamicTextBoxValues[j] != undefined && paramResponseObj[i].reportParamID == dynamicTextBoxValues[j].ReportParamID
                        && paramResponseObj[i].title == dynamicTextBoxValues[j].Title) {                        
                        
                        value = dynamicTextBoxValues[j].Value
                    }
                }
            }

            
            array.push({
                'ReportParamID': paramResponseObj[i].reportParamID,
                'ReportID': paramResponseObj[i].reportID,
                'ReportParamDataTypeID': paramResponseObj[i].reportParamDataTypeID,
                'ReportParamDataTypeTitle': paramResponseObj[i].reportParamDataTypeTitle,
                'Title': paramResponseObj[i].title,
                'Subtext': paramResponseObj[i].subtext,
                'AllowNull': paramResponseObj[i].allowNull,
                'Value': value,
            })
        }
        
        var data = {};
        data.ReportCode = reportCode;
        data.ReportParameters = JSON.stringify(array);
        data.CaseID = caseID;
        
        setShowButtonLoader(true);
        simplurisFetch({
            url: `report/generatereportbycaseID`,
            method: 'POST', body: JSON.stringify(data)
        }).then(data => {            
            setErrorMessage('');
            setSuccessMessage('');
            if (data.name == undefined && data.name != "FetchError") {
                if (data.resultCode == "SUCCESS") {
                    props.fetchCaseDataCounts(caseID);
                    startDate = '';
                    endDate = '';
                    //setSuccessMessage("Report generated successfully.");                                        
                    setTimeout(function () {
                        setShowButtonLoader(false);
                        CloseRunReportTypeModalPopup();                    
                        props.getReportsDetailComponent(caseID, reportID, data.resultObject.url, reportTitle, data.resultObject.createdDate);
                    }, 2000);                                        
                }
                else {
                    setShowButtonLoader(false);
                    setSuccessMessage(data.resultMessage);
                }
            }
        }).catch(e => {
            setShowButtonLoader(false)
            console.error("Error in fetch all reports for generate reports data ", e, e.errorObject);
            if (e.errorObject.resultMessage == "Object reference not set to an instance of an object.")
                setErrorMessage("Something went wrong.");
            else
                setErrorMessage(e.errorObject.resultMessage);
        });
    }


    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseRunReportTypeModalPopup} size="lg" className="SimpluriRunReportTypeModalPopup" data-keyboard="false" data-backdrop="static">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">
                            <a href={null} onClick={() => BackOpenChooseReportTypeModalPopup()}>
                                <img src={LeftArrowPNG} width="25px" className="pr-2 pb-2" /></a>
                            Run {props.reportTitle}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                {showPageLoader && <div className="row"><div className="col-md-11 ml-3"><SectionSpinner height='auto' /></div></div>}
                <Modal.Body className="px-5 SimplurisRunReportModalPopUpBody">
                    {successMessage != "" ? <SuccessPlacement items={successMessage} /> : ''}
                    {errorMessage != "" ? <ErrorPlacement items={errorMessage} /> : ''}
                    <div className="d-flex flex row mt-2">
                        <div className="col-12 col-md-6 col-xl-6 col-lg-6">
                            <div className="form-group">
                                <div className="SimlurisReportTypeLabel SimplurisFontSize17">Report Parameters
                                </div>
                                <div className="SimlurisReportTypeLabel mb-1">Case ID: #{props.caseID}
                                </div>                                
                            </div>
                            {/* <div className="form-group mt-3">
                                <div className="SimlurisReportTypeLabel mb-2">Date Range:</div>                                
                                <div>
                                    <DatePicker1 updateDateRangeFilter={updateDateRangeFilter} />
                                </div>
                            </div>*/}

                            {
                                
                                paramResponseObj.length > 0 && paramResponseObj.map((item, i) =>
                                    <>
                                        {(
                                            () => {                                                               
                                                if (item.title != 'CaseID' && item.title != 'EndDate') {
                                                    if (item.reportParamDataTypeTitle == 'Integer' || item.reportParamDataTypeTitle == 'Decimal') {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">{item.title}:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <input type="number" className="form-control SimplurisNewCaseMemebersInputBox" onChange={e => handleChange(item, i, e)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }      
                                                    else if (item.reportParamDataTypeTitle == 'DateRange' && item.title == 'StartDate') {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">Date Range:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <DatePicker1 updateDateRangeFilter={updateDateRangeFilter} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }  
                                                    else if (item.reportParamDataTypeTitle == 'Boolean') {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">{item.title}:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <label className="ui-switch ui-switch-md mt-1 mr-2">
                                                                        <input type="checkbox" onChange={e => handleChange(item, i, e)} className="SimplurisNewCaseMemebersInputBox" />
                                                                        <i></i>
                                                                    </label>                                                                   
                                                                </div>
                                                            </div>
                                                        </>
                                                    }  
                                                    else {
                                                        return <>
                                                            <div className="form-group mt-3">
                                                                <div className="SimlurisReportTypeLabel">{item.title}:</div>
                                                                <div><small>{item.subtext}</small></div>
                                                                <div>
                                                                    <input type="text" className="form-control SimplurisNewCaseMemebersInputBox" onChange={e => handleChange(item, i, e)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                }
                                            }
                                        )()}

                                    </>
                                )
                            }

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="button" disabled={showButtonLoader} onClick={() => GenerateReportsByCaseID(props.caseID, props.reportID, props.reportTitle, props.reportCode)} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor SimplurisFontSize14 SimplurisFontWeight400 w btn-light py-3 mb-4 mt-2">
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Run Report Now</span>}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RunReportTypeModalPopup

function ErrorPlacement(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvFailedMessage" className="alert alert-danger" role="alert">
            <button type="button" className="close SimpluriAlertMessageCloseIcon" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}

function SuccessPlacement(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvsuccessMessage" className="alert alert-success" role="success">
            <button type="button" className="close SimpluriAlertMessageCloseIcon" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}