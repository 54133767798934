export const SET_FILTERED_IDS = 'SET_FILTERED_IDS'
export const SET_DISTRIBUTION_ID_FILTERS = 'SET_DISTRIBUTION_ID_FILTERS'
export const SET_PAYMENT_STATUS_FILTERS = 'SET_PAYMENT_STATUS_FILTERS'

export const setFilteredIDs = filteredIDs => ({    
    type: SET_FILTERED_IDS,
    filteredIDs,
})

export const setDistributionIDFilters = distributionIDs => ({    
    type: SET_DISTRIBUTION_ID_FILTERS,
    distributionIDs,
})

export const setPaymentStatusFilters = paymentStatusFilters => ({    
    type: SET_PAYMENT_STATUS_FILTERS,
    paymentStatusFilters,
})