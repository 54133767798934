import { FETCH_CASE_MAILINGS_STATISTICS, FETCH_CASE_MAILINGS_ACTIVITIES, FETCH_CASE_MAILINGS_RECIPIENTS, FETCH_CASE_MAILINGS_GENERAL, FETCH_CASE_MAILINGS_DOCUMENTS, BIND_DROPDOWN_MAILINGS } from './actions'
import { resolved } from 'state/helpers'

const initialState = {}

export default (state = initialState, { type, caseMailingsStatistics, caseMailingsActivities, caseMailingsRecipients, caseMailingsGeneral, caseMailingsDocuments, bindDropdowns, Type }) => {
    switch (type) {
        case resolved(FETCH_CASE_MAILINGS_STATISTICS):
            return {
                ...state,
                ...caseMailingsStatistics.reduce((mailingsByID, mailing) => {
                    return {
                        ...mailingsByID,
                        [mailing.mailingID]: mailing,
                    }
                }, {})
            }       
        case resolved(FETCH_CASE_MAILINGS_ACTIVITIES):            
            return {
                ...state,
                ...caseMailingsActivities.caseMailingsActivities.reduce((mailingsByID, mailing) => {                    
                    return {
                        ...mailingsByID,
                        [mailing.mailingID]: mailing,
                    }
                }, {}),
                lastRecordIds: caseMailingsActivities.lastRecordIds,
                isHasNewData: caseMailingsActivities.isHasNewData
            }
        case resolved(FETCH_CASE_MAILINGS_RECIPIENTS):
            return {
                ...state,
                ...caseMailingsRecipients.data.reduce((mailingsByID, mailing) => {
                    return {
                        ...mailingsByID,
                        [mailing.mailingID]: mailing
                    }
                }, {})
            }
        case resolved(FETCH_CASE_MAILINGS_GENERAL):
            return {
                ...state,
                ...caseMailingsGeneral.reduce((mailingsByID, mailing) => {
                    return {
                        ...mailingsByID,
                        [mailing.mailingID]: mailing,
                    }
                }, {})
            }
        case resolved(FETCH_CASE_MAILINGS_DOCUMENTS):
            return {
                ...state,
                ...caseMailingsDocuments.reduce((mailingsByID, mailing) => {
                    return {
                        ...mailingsByID,
                        [mailing.mailingID]: mailing,
                    }
                }, {})
            }
        case BIND_DROPDOWN_MAILINGS:            
            return {
                ...state,
                [Type]: bindDropdowns,
            }
        default:
            return state
    }
}
