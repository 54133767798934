import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Messagepng from "../../../static/img/ico-message.png";
import GlobalImage from "../../components/global-image-set";
import { labFeatures, appMode } from "../../../api-config";
import { fetchThreads } from 'state/message-threads/actions';
import { getPermissionsForCase } from 'state/permissions/selectors';

function CaseTeamMembersDetails(data) {    
    const chatWithTeamMemberPermission = data.userPermissions.find(
        (permissionCode) => permissionCode === 'LC_TEAM_CHAT_WITH_TEAM_MEMBER'
    )
    const [openNewMessage, setOpenNewMessage] = useState(false);
    const [firstName, setFirstName] = useState();
    const [profileImage, setProfileImage] = useState();
    const [personMapID, setPersonMapID] = useState();
    const OpenNewMessage = (check, firstName, profileImage, personMapID) => {
        setOpenNewMessage(check);
        setFirstName(firstName);
        setProfileImage(profileImage);
        setPersonMapID(personMapID);

        var senderPlatformMapId = localStorage.getItem(global.PersonMapID);
        var recipientPlatformMapId = personMapID;
        var caseID = data.caseID;
        data.fetchThreads({ senderPlatformMapId, recipientPlatformMapId, caseID })
    };

    if (data.data == null || data.data.length <= 0) {        
        return (
            <div className="col-md-12">
                <div className="text-center mt-3">Not available team members</div>
            </div>
        );
    } else {
        return (
            <>
                <div>
                    {data.data.map((item, i) => {
                        return item.personMapID != localStorage.getItem(global.PersonMapID) ?

                            // Key is the index here as one case example had the same person as several members of the team
                            <div className="list list-row" key={i}>
                                <div
                                    className="list-item pl-0 position-relative border-top-0 cases-b-b"
                                    data-id="14"
                                >
                                    <div className="avatar-container">
                                        <a
                                            href={null}
                                            onClick={() =>
                                                data.getTeamMemberComponent(0, item.dboPersonID)
                                            }
                                        >
                                            <span className="w-40 avatar circle bg-warning-lt">
                                                <GlobalImage
                                                    isSandbox={appMode == "sandbox" ? "true" : "false"}
                                                    firstname={item.fullName}
                                                    image={item.profilePicURL}
                                                    email={item.personEmail}
                                                    dynamicClass="avatar-status circle off b-white avatar-right"
                                                    isTeamMember="true"
                                                />
                                            </span>
                                        </a>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href={null}
                                            onClick={() =>
                                                data.getTeamMemberComponent(0, item.dboPersonID)
                                            }
                                            className="item-author SimplurisMouseRollOver"
                                        >
                                            {item.fullName}
                                        </a>
                                        <a href="#" className="item-company text-muted h-1x">
                                            {item.roleTitle}
                                        </a>
                                    </div>
                                    {item.unreadMessageCount != 0 ?
                                        <span className="nav-badge position-absolute d-none d-xl-block SimplurisPersonBadgeCount">
                                            <b className="badge badge-sm badge-pill badge-primary SimplurisbadgeChat pl-3 pr-3">
                                                {item.unreadMessageCount}</b>
                                        </span>
                                        : null}
                                    {labFeatures && (
                                        <span className="nav-badge position-absolute d-none d-xl-block SimplurisPersonBadge">
                                            <a
                                                href={null}
                                                onClick={() =>
                                                    OpenNewMessage(
                                                        true,
                                                        item.name,
                                                        item.profilePicURL,
                                                        item.personMapID
                                                    )
                                                }
                                                className={`${
                                                    chatWithTeamMemberPermission ? ""
                                                        : "d-none"
                                                    }`}
                                            >
                                                <img src={Messagepng} className="img-chat mr-2" />
                                            </a>
                                        </span>
                                    )}
                                </div>
                            </div>
                            : null
                        }
                    )}
                </div>
            </>
        );
    }
}

export default connect(
    (state, props) => ({
        userPermissions: getPermissionsForCase(state, props),
        threads: state.threads,
    }),
    {
        fetchThreads,
    }
)(CaseTeamMembersDetails)
