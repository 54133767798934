import { FETCH_MYCASES,FETCH_USERS} from './actions'
import { resolved } from 'state/helpers'

const initialState = {  
}

export default (state = initialState, { type, adminCaseObject, adminUserObject }) => {
    switch (type) {        
        case resolved(FETCH_MYCASES):                         
            return {
                ...state,                  
                adminCaseObject,
            }        
        case resolved(FETCH_USERS):
            return {
                ...state,
                adminUserObject,
            }        
        default:
            return state
    }
}
