import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import StyledTreemap from './styled-treemap'
import { numberToDollars } from 'react/helpers'
import {
  getTotal,
  groupDistributionsByDollar,
  groupDistributionsByPayeeType,
} from '../helpers'
import FilterButton from '../filter-button'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import {
  setDistributionIDFilters,
  setPaymentStatusFilters,
} from 'state/case-detail-view/distributions/actions'
import {
  getDistributionIDFilters,
  getActiveDistributionIDFilters,
  getFilteredIDs,
} from 'state/case-detail-view/distributions/selectors'
import BreadCrumbs from './breadcrumbs'
import PaymentStatusFilters from '../payment-status-filters'

const ButtonsWrapper = styled.div`
  margin-left: -4px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`

const DistributionsTreemap = ({
  filteredPayments,
  distributionData,
  onLeafClick,
  distributionIDFilters,
  activeDistributionIDFilters,
  setDistributionIDFilters,
  paymentStatusFilters,
  setPaymentStatusFilters,
  filteredIDs,
  setPreviousCaseSubtab,
    payeeAttorneyPermission,
    DateRangeStartDate,
    DateRangeEndDate
}) => {        
  const [breadCrumbs, setBreadCrumbs] = React.useState([
    { title: 'Payee Type', ids: null },
    ])
        
  if (!filteredPayments || !distributionData) {
    return null
    }

    if (DateRangeStartDate && DateRangeEndDate) {
        
        const startDate = new Date(DateRangeStartDate)
        const endDate = new Date(DateRangeEndDate)

        distributionData = distributionData.filter(
            ({ dateSent }) => {
                const distributionSendDate = dateSent != undefined ? new Date(dateSent) : new Date();
                return distributionSendDate > startDate && distributionSendDate < endDate
            }
        )

        filteredPayments = filteredPayments.filter(
            ({ paymentDate }) => {
                const distributionSendDate = new Date(paymentDate)
                return distributionSendDate > startDate && distributionSendDate < endDate
            }
        )
        
    }

    
    const distributionNames = distributionData.length > 0 ? distributionData.map(data => data.description) : '';
    
  const treemapData = filteredIDs
    ? groupDistributionsByDollar(filteredPayments)
    : groupDistributionsByPayeeType(filteredPayments)
    
  return (
      <>
          {
              treemapData.children.length > 0 && <>
                  <BreadCrumbs breadCrumbs={breadCrumbs} setBreadCrumbs={setBreadCrumbs} />
                  <div className="row">
                      <ButtonsWrapper className="col-md-12">
                          <div>
                              {Object.keys(distributionIDFilters).map((id, i) => {
                                  const {
                                      description,
                                      totalDistributionAmount,
                                  } = distributionData.length > 0 ? distributionData.find(
                                      ({ distributionID }) => String(distributionID) === id
                                  ) : '';

                                  return (
                                      <FilterButton
                                          key={id}
                                          label={`${description ||
                                              `Distribution #${(++i)}`} - ${numberToDollars(
                                                  totalDistributionAmount, false
                                              )}`}
                                          filter={id}
                                          active={!!distributionIDFilters[id]}
                                          onClick={() =>
                                              setDistributionIDFilters({
                                                  [id]: !distributionIDFilters[id],
                                              })
                                          }
                                      />
                                  )
                              })}
                          </div>
                          <div>
                              <PaymentStatusFilters filters={paymentStatusFilters} />
                          </div>
                      </ButtonsWrapper>
                  </div>
              </>
          }
      
      {activeDistributionIDFilters.length ? (
              <div className="mt-3">
                  {
                      filteredPayments.length > 0 && <>
                          <b>{distributionNames != '' ? distributionNames.join(', ') : ''}</b> - total payments made:{' '}
                          {numberToDollars(getTotal(filteredPayments), false)}
                      </>
                  }
                  
          {treemapData.children && treemapData.children.length ? (
            <StyledTreemap
              animation
              height={500}
              data={treemapData}
              colorType="literal"
              margin={0}
              padding={0}
              onLeafMouseOver={(node, event) => {
                const elementToTooltip =
                  event.target && event.target.closest('.rv-treemap__leaf')
                if (elementToTooltip) {
                  elementToTooltip.setAttribute('data-html', true)
                  elementToTooltip.setAttribute(
                    'data-tip',
                    node.data.tooltipContent
                  )
                }
                ReactTooltip.rebuild()
              }}
              onLeafClick={node => {
                ReactTooltip.hide()
                onLeafClick(node)
                const nextBreadCrumb = {
                  title: node.data.titleWording,
                  ids: node.data.ids,
                }

                if (node.data.titleWording == 'Attorney') {
                  if (
                    payeeAttorneyPermission &&
                    payeeAttorneyPermission.length > 0
                  ) {
                    // TODO
                  } else {
                    console.log('Permission Denied')
                  }
                }

                setBreadCrumbs(breadCrumbs.concat(nextBreadCrumb))
              }}
              // sortFunction={(a, b) => b.height - a.height || b.value - a.value}
            />
          ) : (
            <div style={{ paddingTop: '34px' }} className="text-center">
              No payments to display for selected filters
            </div>
          )}
        </div>
      ) : (
        <div>No filters selected</div>
      )}
      <ReactTooltip />
    </>
  )
}


export default connect(
  (state, props) => ({
    distributionIDFilters: getDistributionIDFilters(state, props),
    activeDistributionIDFilters: getActiveDistributionIDFilters(state, props),
    filteredIDs: getFilteredIDs(state, props),
  }),
  {
    changeCaseSubtab,
    setDistributionIDFilters,
    setPaymentStatusFilters,
    setPreviousCaseSubtab,
  }
)(DistributionsTreemap)
