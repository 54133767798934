import { FETCH_THREADS, UPDATE_MESSAGES, UPDATE_THREADS, GET_THREADS, DELETE_THREAD } from './actions'

const initialState = {}

export default (
    state = initialState,
    { type, threads, singlethread, messages }
) => {
    switch (type) {
        case FETCH_THREADS:
            return {
                ...state,
                ...threads.threads.reduce((messageThreadByID, nextThread) => {
                    messageThreadByID[nextThread.messageThreadID] = {
                        ...state[nextThread.messageThreadID],
                        ...nextThread,
                    }
                    return messageThreadByID
                }, {})
            }
        case UPDATE_MESSAGES:
            return {
                ...state,
                [singlethread.messageThreadID]: {
                    ...state[singlethread.messageThreadID],
                    'messages': [...state[singlethread.messageThreadID].messages, singlethread]
                }
            }
        case UPDATE_THREADS:
            return {
                ...state,
                [singlethread.messageThreadID]: singlethread
            }
        case GET_THREADS:
            if (messages != null) {
                return {
                    ...state,
                    [messages[0].messageThreadID]: {
                        ...state[messages[0].messageThreadID],
                        'messages': messages
                    }
                }
            }
            else
                return state
        case DELETE_THREAD:
            delete state[threads.messageThreadID];
            return state;
        default:
            return state
    }
}
