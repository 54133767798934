import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";

import MapSection from './map-section'
import FullScreenMap from './map-section/full-screen'
import AllMailings from './all-mailings'
import MiddleCards from './middle-cards'
import MailingDetail from '../mailings'
import { getSelectedMailingID } from 'state/case-detail-view/mailings/other/selectors'
import { fetchMailings } from 'state/mailings/other/actions'
import { ReactComponent as FullScreenIcon } from 'icons/expand-arrows.svg'
import { getMailingsForCase } from 'state/mailings/other/selectors'
import FilterButton from 'react/components/filter-button'
import { getMailingsHistoryByCaseID } from 'state/mailings/history/selectors'
import {
    fetchMailingsHistory,
    fetchMailTrackingDataByDrillDownTopLevelHops,
    fetchMailTrackingDataByDrillDownGetHopDetail,
    fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID
} from 'state/mailings/history/actions'
import exportImage from 'static/img/export.png'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { getHistoryFetching, getMailingHopsDetailsFetching, getMailingHistoryDetailsFetching } from 'state/case-detail-view/mailings/history/selectors'
import { labFeatures } from '../../../../../api-config'
import { getPermissionsForCase } from 'state/permissions/selectors'

const Controls = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;
const FullScreenWrapper = styled.div`
  cursor: pointer;
  margin-right: 16px;
`;

const MailTracking = ({
  caseID,
  selectedMailingID,
  data,
  fetchMailings,
  fetchMailingsHistory,
  mailingsForCase,
  mailingsHistory,  
  userPermissions,
  changeCaseSubtab,
  setPreviousCaseSubtab,
  previousCaseSubtabs,
    fetchingMailingsHistory,
    apiFlageFetchingMailingHopsDetails,
    apiFlageFetchingMailingHistoryDetails,
    fetchMailTrackingDataByDrillDownTopLevelHops,
    mailTrackingTopLevelHopsData,
    fetchMailTrackingDataByDrillDownGetHopDetail,
    mailTrackingHopsDetail,
    fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID,
    mailHistoryDetail,
    getCaseMemberDetailComponent,
    caseLiveReportMailingHistory,
}) => {       
  // Effects and state
    React.useEffect(() => {
        
    fetchMailings({ caseID });
      //fetchMailingsHistory({ caseID });
      
      fetchMailTrackingDataByDrillDownTopLevelHops({ caseID });
    }, [fetchMailings, caseID, fetchMailTrackingDataByDrillDownTopLevelHops, selectedMailingID]);

  const [fullScreenMap, setFullScreenMap] = React.useState(false);
  const [
    fullScreenDefaultMailing,
    setFullScreenDefaultMailing
    ] = React.useState(false);
    
    const [filters, setFilters] = React.useState({});
    
    const [fullScreenMapInfo, setFullScreenMapInfo] = React.useState([]);
    const [showMailingHistoryRightSideSection, setShowMailingHistoryRightSideSection] = React.useState(false); 
    
    function GetMailingHopeDetails(line)
    {        
        setShowMailingHistoryRightSideSection(false);
        var fromlocalkey = line.localkeys.from[0];
        var tolocalkey = line.localkeys.to[0];
        fetchMailTrackingDataByDrillDownGetHopDetail({ caseID, fromlocalkey, tolocalkey });     
    }

    function getMailTrackingDdataByMailingHistoryID(mailingHistoryID)
    {                
        setShowMailingHistoryRightSideSection(true);
        fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID(caseID, mailingHistoryID);        
    }
    
    React.useEffect(() => {
        
    const defaultFilters = mailingsForCase.reduce((defaults, { mailingID }) => {
      defaults[mailingID] = true;
      return defaults;
    }, {});
    setFilters(defaultFilters);
  }, [mailingsForCase]);
    
  // Drop out early for mailing detail view - this should live elsewhere in the future
  if (selectedMailingID) {
    return (
      <MailingDetail
        caseID={caseID}
        selectedMailingID={selectedMailingID}
        data={data}
      />
    );
    }
    
   
    
  var mailTrackingPermission;
  var editExistingMailPermission;
  var exportDataPermission;
  var sendDataEmailPermission;
  var viewStatChartPermission;
  var viewDeliverableStatusPermission;
  var viewFirstNamePermission;
  var viewLastNamePermission;
  var viewMailDatePermission;
  var viewMailTitlePermission;
  var viewMailingIdPermission;
  var viewTransitDataPermission;

  if (Object.keys(userPermissions).length > 0) {
      mailTrackingPermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEW_IN_MENU'
      )
      editExistingMailPermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_EDITMAILING_EDIT_EXISTING_MAILING'
      )

      exportDataPermission = userPermissions.find(
          (permissionCode) => permissionCode === 'LC_MAILTRACKING_DOWNLOAD_EXPORT_DATA'
      )

      sendDataEmailPermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_SEND_DATA_EMAIL'
      )
      viewStatChartPermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEW_STATS_CHARTS'
      )

      viewDeliverableStatusPermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_DELIVERABLE_STATUS'
      )
      viewFirstNamePermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_FIRSTNAME'
      )

      viewLastNamePermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_LASTNAME'
      )
      viewMailDatePermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_MAILING_DATE'
      )

      viewMailTitlePermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_MAILING_TITLE'
      )
      viewMailingIdPermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_MAILINGID'
      )
      viewTransitDataPermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MAILTRACKING_VIEWDATA_TRANSIT_DETAIL_DATA'
      )
  }

  if (mailTrackingPermission && mailTrackingPermission.length <= 0) {
    // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
    changeCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
    setPreviousCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
  }

    
  const filteredMailings = mailingsForCase.filter(
    ({ mailingID }) => filters[mailingID]
    );

    
    var totalCountOFMailingItemSent = 0;
    var totalCountOFMailingUndeliverable = 0;
    var percentageForMailingUndeliverable = 0;
    
    let mailings = caseLiveReportMailingHistory && caseLiveReportMailingHistory.mailings.length > 0 ? caseLiveReportMailingHistory.mailings : [];
    
    if (mailings != undefined) {
        

        if (Object.keys(filters).length > 0) {
            
            mailings = mailings.filter(
                ({ mailingID }) => filters[mailingID]
            );
            
            for (var i = 0; i < mailings.length; i++) {
                
                totalCountOFMailingItemSent += parseInt(mailings[i].distinctTotalSent);
                totalCountOFMailingUndeliverable += parseInt(mailings[i].distinctUndeliverable);
                
            }
        }
        else {
            for (var i = 0; i < mailings.length; i++) {
                
                totalCountOFMailingItemSent += parseInt(mailings[i].distinctTotalSent);
                totalCountOFMailingUndeliverable += parseInt(mailings[i].distinctUndeliverable);
                
            }
        }        
        
        percentageForMailingUndeliverable = !isNaN(parseFloat((parseInt(totalCountOFMailingUndeliverable) * 100) / parseInt(totalCountOFMailingItemSent)).toFixed(2)) ? parseFloat((parseInt(totalCountOFMailingUndeliverable) * 100) / parseInt(totalCountOFMailingItemSent)).toFixed(2) + "%" : "";
        
    }

    
  //const filteredMailingsHistory = mailingsHistory.filter(
  //  ({ mailingID }) => filters[mailingID]
  //  );    

    
  const filterButtonLabels = mailingsForCase.reduce(
    (labels, { mailingDescription, mailingID }, i, collection) => {
      const sameDescriptions = collection.filter(
        x => x.mailingDescription === mailingDescription
      );

      let label = mailingDescription;
      if (sameDescriptions.length > 1) {
        const index = _.findIndex(
          sameDescriptions,
          x => x.mailingID === mailingID
        );
        label = `${mailingDescription} - ${index + 1}`;
      }

      labels[mailingID] = label;
      return labels;
    },
    {}
    );

    

	return (
		<div className="flex">
			<div className="page-container" id="page-container">
				<div className="row">
					<div className="col-md-12 col-xs-12 col-sm-12">
						{viewStatChartPermission && viewStatChartPermission.length > 0 ? (
							<div className="card d-flex flex">
								<div className="row align-items-center p-3">
                                    <div className="d-inline-block col-md-10 col-xl-10 col-lg-10 col-10">
										<div className="text-md SimplurisFontSize19 SimplurisFontWeight400 SimplurisMilestoneLables">
											Mailing Tracking
										</div>
									</div>
									{labFeatures &&
                                        <div className="col-md-2 col-xl-2 col-lg-2 col-1 text-right">
											{exportDataPermission && exportDataPermission.length > 0 ? (
												<img src={exportImage} />
											) : null}
										</div>
									}
								</div>
								<hr className="m-0" />
								<Controls>
									<div>
										{_.map(filters, (filterStatus, mailingID) => (
											<FilterButton
												key={mailingID}
												label={filterButtonLabels[mailingID]}
												active={filterStatus}
												onClick={() =>
													setFilters({ ...filters, [mailingID]: !filterStatus })
												}
											/>
										))}
									</div>
									<FullScreenWrapper onClick={() => setFullScreenMap(true)}>
										<FullScreenIcon />
									</FullScreenWrapper>
								</Controls>
								<MapSection
									caseID={caseID}
									mailings={filteredMailings}
                                    mailTrackingTopLevelHopsData={mailTrackingTopLevelHopsData}
                                    mailTrackingHopsDetailData={mailTrackingHopsDetail}
									onLineClick={line => {
										setFullScreenMap(false)
                                        setFullScreenDefaultMailing(line)
                                        GetMailingHopeDetails(line);
									}}
                                    setFullScreenMap={setFullScreenMap}                                    
								/>
                                {fullScreenMap && (
                                    <FullScreenMap
                                        caseID={caseID}
                                        exitFullScreen={() => setFullScreenMap(false)}
                                        mailings={filteredMailings}
                                        mailTrackingTopLevelHopsData={mailTrackingTopLevelHopsData}
                                        mailTrackingHopsDetailData={mailTrackingHopsDetail}
                                        apiFlageFetchingMailingHopsDetails={apiFlageFetchingMailingHopsDetails}
                                        apiFlageFetchingMailingHistoryDetails={apiFlageFetchingMailingHistoryDetails}
                                        defaultMailing={fullScreenDefaultMailing}
                                        fullScreenMapInfo={fullScreenMapInfo}
                                        onLineClick={line => {                                            
                                            setFullScreenMapInfo(line);
                                            GetMailingHopeDetails(line);
                                        }}
                                        getMailTrackingDdataByMailingHistoryID={getMailTrackingDdataByMailingHistoryID}
                                        mailHistoryDetail={mailHistoryDetail}
                                        showMailingHistoryRightSideSection={showMailingHistoryRightSideSection}
                                        setShowMailingHistoryRightSideSection={setShowMailingHistoryRightSideSection}
                                        getCaseMemberDetailComponent={getCaseMemberDetailComponent}
									/>
								)}
							</div>
						) : null}
                        <MiddleCards mailingCount={totalCountOFMailingItemSent} totalCountOFMailingUndeliverable={totalCountOFMailingUndeliverable} percentageForMailingUndeliverable={percentageForMailingUndeliverable}/>
						<AllMailings
                            caseID={caseID}
							viewDeliverableStatusPermission={viewDeliverableStatusPermission}
							editExistingMailPermission={
								editExistingMailPermission &&
								editExistingMailPermission.length > 0
									? editExistingMailPermission
									: []
							}
							viewMailingIdPermission={
								viewMailingIdPermission && viewMailingIdPermission.length > 0
									? viewMailingIdPermission
									: []
							}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default connect(
  (state, props) => ({
    selectedMailingID: getSelectedMailingID(state, props),
    mailingsForCase: getMailingsForCase(state, props),
    //mailingsHistory: getMailingsHistoryByCaseID(state, props),
    userPermissions: getPermissionsForCase(state, props),
    previousCaseSubtabs: state.previousSubTab.subTabNames,
    fetchingMailingsHistory: getHistoryFetching(state, props),
    mailTrackingTopLevelHopsData: state.mailings.history.MailTrackingTopLevelHops,
    mailTrackingHopsDetail: state.mailings.history.MailTrackingHopsDetail,
    mailHistoryDetail: state.mailings.history.MailingHistoryDetail,
    apiFlageFetchingMailingHopsDetails: getMailingHopsDetailsFetching(state, props),
    apiFlageFetchingMailingHistoryDetails: getMailingHistoryDetailsFetching(state, props),
    caseLiveReportMailingHistory: state.liveReports.mailings && state.liveReports.mailings[props.caseID],
  }),
  {
    fetchMailings,
      //fetchMailingsHistory,
      fetchMailTrackingDataByDrillDownTopLevelHops,
      fetchMailTrackingDataByDrillDownGetHopDetail,
      fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID,
    changeCaseSubtab,
    setPreviousCaseSubtab,
  }
)(MailTracking)
