import { FETCH_CASE_PERMISSIONS } from 'state/cases/data/actions'

const initialState = {}

export default (
  state = initialState,
  { type, caseId, singleCasePermissions }
) => {
  switch (type) {
    case FETCH_CASE_PERMISSIONS:
      return { 
        ...state,
        [caseId]: singleCasePermissions,
      }
    default:
      return state
  }
}
