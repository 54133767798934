import React, { useState } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { func } from 'prop-types';
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import {
    selectMember,
    selectPermission,
    selectPaymentsDetail,
    selectResponsesDetail
} from 'state/case-detail-view/members/actions'
import { parse } from '@babel/parser';


const CallMapData = ({
    callMapGridData,
    viewPersonIdPermission,
    viewFirstNamePermission,
    viewLastNamePermission,
    viewMemberDrilDownToDetail,
    changeCaseSubtab,
    selectMember,
    setPreviousCaseSubtab,
    selectPermission,
    selectPaymentsDetail,
    selectResponsesDetail }) => {

    const [totalCount, settotalCount] = useState(0);
    function renderShowsTotal(start, to, total) {
        settotalCount(total);
        return <></>;
    }

    const tableOptions = {
        toolBar: getToolbarWithTotal({ buttonText: `All ${totalCount} ` }),
        searchField: getSearchField({ defaultValue: '' }),
        onlyOneExpanding: true,
        onRowClick: onParentRowClick,
        paginationShowsTotal: renderShowsTotal
    }

    function onParentRowClick(row) {
        var openedTrClass = '';
        const collepsedTag = document.getElementsByClassName("expandStateGrid");
        for (var i = 0; i < collepsedTag.length; i++) {
            if (collepsedTag[i].style.height != '0px') {
                collepsedTag[i].style.height = "0px";
                openedTrClass = collepsedTag[i].classList[2];
            }
        }

        if (openedTrClass != ("expandStateGrid-" + row.callMapData.counter)) {

            var lengthOfAudioURL = row.callMapData.data.length;

            document.getElementsByClassName("expandStateGrid-" + row.callMapData.counter)[0].style.height = ((lengthOfAudioURL + 2) * 50) + 'px';
        }

    }

    function isExpandableRow(row) {
        return true
    }

    function expandComponent(row) {

        return <CallMapAudioTable
            data={row.callMapData}
            viewPersonIdPermission={viewPersonIdPermission}
            viewFirstNamePermission={viewFirstNamePermission}
            viewLastNamePermission={viewLastNamePermission}
            viewMemberDrilDownToDetail={viewMemberDrilDownToDetail}
            changeCaseSubtab={changeCaseSubtab}
            selectMember={selectMember}
            setPreviousCaseSubtab={setPreviousCaseSubtab}
            selectPermission={selectPermission}
            selectPaymentsDetail={selectPaymentsDetail}
            selectResponsesDetail={selectResponsesDetail}/>
    }

    function expandColumnComponent({ isExpandableRow, isExpanded }) {
        if (isExpandableRow) {
            if (!isExpanded) {
                return (
                    <i class="SimplurisCallCenterPlayPauseIcon fa fa-plus-circle" aria-hidden="true"></i>)
            }
            else {
                return (
                    <i class="SimplurisCallCenterPlayPauseIcon fa fa-minus-circle" aria-hidden="true"></i>)
            }
        }
    }
  
    let maxRourceValue = callMapGridData.length > 0 ? callMapGridData.reduce((max, p) => p.resources > max ? p.resources : max, callMapGridData[0].resources) : 0;
    let maxCallValue = callMapGridData.length > 0 ? callMapGridData.reduce((max, p) => parseInt(p.noOfCalls.replace('Total Calls ', '')) > max ? parseInt(p.noOfCalls.replace('Total Calls ', '')) : max, parseInt(callMapGridData[0].noOfCalls.replace('Total Calls ', ''))) : 0;

    return (
        <div className="pt-3">
            <BootstrapTable
                data={callMapGridData}
                expandBy="column"
                options={tableOptions}
                expandableRow={isExpandableRow}
                expandComponent={expandComponent}
                expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: expandColumnComponent,
                    columnWidth: 60
                }}
                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover callCenterStatisticsMainGrid callCenterMapMainGrid table2"
                bodyContainerClass="text-grid-td"
                hover
                pagination
                search
            >
                <TableHeaderColumn expandable={false}                    
                    dataFormat={(noOfCalls) =>
                        parseInt(noOfCalls.replace('Total Calls ', '')) == maxCallValue ? `<span class='badge bg-danger-lt p-1'>${noOfCalls.replace('Total ', '')}</span>` : `<span class='badge bg-primary-lt p-1'>${noOfCalls.replace('Total ', '')}</span>`
                    }
                    dataField="noOfCalls">
                    Total Calls
                </TableHeaderColumn>
                <TableHeaderColumn isKey expandable={false} dataField="fromState"
                >
                    State
                    </TableHeaderColumn>
                <TableHeaderColumn expandable={false}
                    dataField="avgCallTime"
                >
                    Avg. Call Time
            </TableHeaderColumn>
                <TableHeaderColumn expandable={false}                    
                    dataFormat={(resources) =>
                        resources == maxRourceValue ? `<span class='text-danger'>${resources}</span>` : `<span>${resources}</span>`
                    }
                    dataField="resources"
                >
                    % of Resources
            </TableHeaderColumn>

            </BootstrapTable>
        </div>
    )
}


function CallMapAudioTable({
    data,
    viewPersonIdPermission,
    viewFirstNamePermission,
    viewLastNamePermission,
    viewMemberDrilDownToDetail,
    changeCaseSubtab,
    selectMember,
    setPreviousCaseSubtab,
    selectPermission,
    selectPaymentsDetail,
    selectResponsesDetail}) {

    const tableOptions = {
        onRowClick: onRowClickForExpandebleAudioTableRaw,
        onlyOneExpanding: true,
        expandBy: 'column',        
    }

    function isExpandableRow(row) {
        if (row.isReleased == true) {
            return true
        }
        else {
            return false;
        }
    }

    function onRowClickForExpandebleAudioTableRaw(row, columnIndex, rowIndex, e) {        
        if (e.target.classList.value.indexOf("SimplurisCallCenterMapMemeberDrillDownLink") > -1) {
            return false;
        }
        
        if (e.target.classList.value.indexOf("SimplurisCallCenterMapAudioExpandLink") > -1) {
            const collepsedTag = document.getElementsByClassName("subexpandDiv");
            for (var i = 0; i < collepsedTag.length; i++) {
                collepsedTag[i].style.height = "0px";
            }

            const audioEl = document.getElementsByClassName("subaudio-" + row.callID)[0]

            if (audioEl.paused) {
                audioEl.play();
                document.getElementsByClassName("subexpandDiv-" + row.callID)[0].style.height = "50px";
            } else {
                audioEl.pause();
                document.getElementsByClassName("subexpandDiv-" + row.callID)[0].style.height = "0px";
            }
            const audioTag = document.getElementsByTagName("audio");
            for (var i = 0; i < audioTag.length; i++) {
                if (audioTag[i].className != "subaudio-" + row.callID) {
                    audioTag[i].pause();
                }
            }
        }
             else
                return false;

        }

        function expandColumnComponent({ isExpandableRow, isExpanded }) {
            if (isExpandableRow) {
                if (!isExpanded) {
                    return (
                        <i className="SimplurisCallCenterPlayPauseIcon fa fa-play-circle-o SimplurisCallCenterMapAudioExpandLink"></i>)
                }
                else {
                    return (
                        <i className="SimplurisCallCenterPlayPauseIcon fa fa-pause-circle-o SimplurisCallCenterMapAudioExpandLink"></i>)
                }
            }
            else {
                return <div>&nbsp;</div>;
            }
        }


        function expandComponent(row) {
            return (
                <>
                    <tr className="p-0">
                        <td colSpan="6" className="border-0 shadow-none p-0 callCenterMapAudioGridTD">
                            <table className="table table-theme v-middle m-0 callCenterMapAudioGrid">
                                {(() => {

                                    return <tr>
                                        <td className="">
                                            <div className={`SimplurisFadeInAnimation subexpandDiv ${"subexpandDiv-" + row.callID}`}>
                                                {(
                                                    () => {
                                                        if (row.audioURL != undefined) {
                                                            return <> <audio controls loop controlsList="nodownload" width="100%" className={"subaudio-" + row.callID}>
                                                                <source src={row.audioURL} type="audio/wav" />
                                                            </audio></>
                                                        }
                                                        else {
                                                            return <div>&nbsp;</div>
                                                        }
                                                    }
                                                )()}
                                            </div>
                                        </td>
                                    </tr>
                                })()}
                            </table>
                        </td>
                    </tr></>
            )
        }

        function linkCellFormatterForNameInMapGrid(cell, row) {            
            if (viewMemberDrilDownToDetail && viewMemberDrilDownToDetail.length > 0) {
                return (
                    <a
                        className="SimplurisBlueColor SimplurisPointer SimplurisCallCenterMapMemeberDrillDownLink"
                        href={null}
                        onClick={() => redirectToMemberDetailScreenFromCallCenterMapTab(row)}
                    >
                        {(viewFirstNamePermission && viewFirstNamePermission.length > 0 ? row.firstName + ' ' : '')}
                        {(viewLastNamePermission && viewLastNamePermission.length > 0 ? row.lastName : '')}
                    </a>
                );
            } else {
                return <>{(viewFirstNamePermission && viewFirstNamePermission.length > 0 ? row.firstName + ' ' : '')}
                    {(viewLastNamePermission && viewLastNamePermission.length > 0 ? row.lastName : '')}</>;
            }
    }

    function redirectToMemberDetailScreenFromCallCenterMapTab(row) {        
        selectMember(row.personID)
        selectPermission({ viewFirstNamePermission, viewLastNamePermission });
        var paymentsDetail = false
        selectPaymentsDetail(paymentsDetail)
        var responsesDetail = false
        selectResponsesDetail(responsesDetail);
        changeCaseSubtab('member-detail')
        setPreviousCaseSubtab('call center')
    }

        return (
            <tr>
                <td colSpan="4" className="p-0 border-0 shadow-none">
                    <div className={`SimplurisFadeInAnimation expandStateGrid ${"expandStateGrid-" + data.counter}`}>
                        <table className="table table-theme v-middle m-0 callCenterStatisticsGrid callCenterMapTabInnerGrid">
                            <BootstrapTable
                                data={data.data}
                                expandBy="column"
                                options={tableOptions}
                                expandableRow={isExpandableRow}
                                expandComponent={expandComponent}
                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent: expandColumnComponent,
                                    columnWidth: 60
                                }}
                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover callCenterStatisticsMainGrid callCenterMapInnerGrid table3"
                                bodyContainerClass="text-grid-td"
                                hover
                            >
                                <TableHeaderColumn
                                    dataField="callID"
                                    isKey
                                    hidden={true}
                                >
                                    CallID
                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="personID"
                                    width="16%"
                                    expandable={false}
                                    hidden={
                                        viewPersonIdPermission && viewPersonIdPermission.length > 0
                                            ? false
                                            : true
                                    }
                                >
                                    PersonID
                </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} dataField="date" width="16%"
                                    dataFormat={date => new Date(date).toLocaleDateString()}
                                >
                                    Date
                    </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} dataField="date" width="16%" dataFormat={date => new Date(date).toLocaleTimeString()}>
                                    Time
                    </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} width="11%"
                                    dataField="durationSeconds"
                                >
                                    Length
            </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} dataField="fromCity" width="30%" dataFormat={(fromCity, { fromState }) =>
                                    `${fromCity}, ${fromState}`
                                }>
                                    Call Location
            </TableHeaderColumn>

                                <TableHeaderColumn expandable={false} dataField="firstName" width="30%" dataFormat={linkCellFormatterForNameInMapGrid}>
                                    Member
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </table>
                    </div>
                </td>
            </tr>
        )
}

export default connect(
    (state, props) => ({
        previousCaseSubtabs: state.previousSubTab.subTabNames
    }),
    {
        selectMember,
        selectPermission,
        changeCaseSubtab,
        setPreviousCaseSubtab,
        selectPaymentsDetail,
        selectResponsesDetail
    }
)(CallMapData)
