import React from 'react'
import styled from 'styled-components'

import { LogoSpinner } from 'react/components/spinners'

const CenteredContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const PageLoader = () => (
	<div id="dvPageLoader">
        <CenteredContent>
            <LogoSpinner size={30} />
        </CenteredContent>
	</div>
)

export default PageLoader
