import {
    UPDATE_CASES,
    UPDATE_CASE,
    FETCH_CASE,
    FETCH_CASES,
    UPDATE_TEAMMEMBER_UNREAD_MESSAGE,
    UPDATE_CASE_FAVORITE,
    RESET_CASES
} from './actions'
import { ADD_CASE_ACTIVITY_POST } from 'state/case-activity/actions'
import { UPDATE_CASE_DATA_COUNTS_FOR_REPORTS } from 'state/case-report/actions'
import { UPDATE_CASE_DATA_COUNTS_FOR_FILES } from 'state/case-file/actions'
import { appMode} from '../../../api-config'
import { parse } from 'querystring';

const initialState = {}
export default (
    state = initialState,
    {
        type,
        singleCase,
        cases,
        post,
        unreadMessages,
        caseIDs,
        isFavorite,
        caseID,
        updatedCaseCountsObject,
        caseIDForUpdatedCaseCountsObject
    }
) => {

    var tempCaseID = caseID;
    switch (type) {
        case RESET_CASES:
            return {}
        case FETCH_CASES:
            return {
                ...state,
                ...cases.cases.reduce((casesById, nextCase) => {
                    casesById[nextCase.caseID] = {
                        ...state[nextCase.caseID],
                        ...nextCase,
                    }
                    return casesById
                }, {}),
            }
        case FETCH_CASE:
        case UPDATE_CASE:                           
            if (singleCase.length == 0) {    

                
                return {
                    ...state,
                    [tempCaseID]: {
                        ...state[tempCaseID],
                        ...null,
                    },
                }
            }            
            if (singleCase != undefined) {                
                return {
                    ...state,
                    [singleCase.details.caseId]: {
                        ...state[singleCase.details.caseId],
                        ...singleCase,
                    },
                }
            }
            else
            {
                return  state
            }
        case UPDATE_CASES:
            return {
                ...state,
                ...cases.reduce((casesById, nextCase) => {
                    casesById[nextCase.caseID] = {
                        ...state[nextCase.caseID],
                        ...nextCase,
                    }
                    return casesById
                }, {}),
            }
        case ADD_CASE_ACTIVITY_POST:            
            // This part of the reducer is a bit large and complex due to the current need to store case activity in two parts of redux state.
            // Eventually we should refactor so we don't store case activity nested within state and can remove this
            const caseToAddPostTo = Object.values(state).find(({ caseActivity }) => {
                const caseHasActivityID =
                    caseActivity &&
                    caseActivity.find(
                        activity => activity.caseActivityID === post.caseActivityID
                    )
                return !!caseHasActivityID
            })

            if (!caseToAddPostTo) {
                return state
            }
            
            const caseID = caseToAddPostTo.details.caseId
            const updatedCaseActivity = caseToAddPostTo.caseActivity.map(activity => {
                if (activity.caseActivityID === post.caseActivityID) {
                    return {
                        ...activity,
                        posts: activity.posts.concat(post),
                    }
                }
                return activity
            })

            return {
                ...state,
                [caseID]: {
                    ...state[caseID],
                    caseActivity: updatedCaseActivity,
                },
            }
        case UPDATE_TEAMMEMBER_UNREAD_MESSAGE:
            var temaMemberID = unreadMessages.personMapID
            var teams = state[unreadMessages.messageCaseID]
            if (teams) {
                for (var i = 0; i < teams.teamMembers.length; i++) {
                    if (teams.teamMembers[i].personMapID == temaMemberID) {
                        teams.teamMembers[i].unreadMessageCount = 0
                    }
                }
            }
            return {
                ...state,
                [unreadMessages.messageCaseID]: {
                    ...state[unreadMessages.messageCaseID],
                    teams,
                },
            }
        case UPDATE_CASE_FAVORITE:                             
            return {
                ...state,
                ...state[caseIDs].isFavorite = (isFavorite == "1" ? true : false)
            }    
        case UPDATE_CASE_DATA_COUNTS_FOR_REPORTS:                        
            if (appMode == "sandbox") {                
                var updatedReportCount = parseInt(state[caseIDForUpdatedCaseCountsObject].caseDetailCounts.reportCount) + 1
                state[caseIDForUpdatedCaseCountsObject].caseDetailCounts.reportCount = updatedReportCount;
            }
            else
            {
                state[caseIDForUpdatedCaseCountsObject].caseDetailCounts = [];
                state[caseIDForUpdatedCaseCountsObject].caseDetailCounts = updatedCaseCountsObject[0];            
            }                        
            return state;
        case UPDATE_CASE_DATA_COUNTS_FOR_FILES:            
            if (appMode == "sandbox") {                
                var updatedFilesCount = parseInt(state[caseIDForUpdatedCaseCountsObject].caseDetailCounts.secureFileCount) + 1
                state[caseIDForUpdatedCaseCountsObject].caseDetailCounts.secureFileCount = updatedFilesCount;
            }
            else {
                state[caseIDForUpdatedCaseCountsObject].caseDetailCounts = [];
                state[caseIDForUpdatedCaseCountsObject].caseDetailCounts = updatedCaseCountsObject[0];
            }            
            return state;
        default:
            return state
    }
}
