import * as React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import {
    getDoughnutChart,
    getTotalsForMailings,
    chartColors,
    chartTitles,
} from '../helpers'
import { getPercentage } from 'helpers'

const MailingInfo = styled.div`	
	display: flex;
	align-items: center;
	padding: 0 30px;
	font-size: 20px;
	box-shadow: 0 10px 10px -10px #999999;
	background-color: white;
`
const Chart = styled.div`
	position: relative;
	flex: 0 0 200px;
`

const FullScreenMapTopInfo = ({ mailings }) => {
    const { allMailingsTypesTotal, ...mailingsTotals } = getTotalsForMailings(
        mailings
    )

    return (
        <MailingInfo>
            <div className="row w-100">
                {_.map(mailingsTotals, (total, type) => (
                    <div className="col-12 col-sm-4">
                        <Chart>
                            {getDoughnutChart({
                                number: getPercentage({
                                    total: allMailingsTypesTotal,
                                    part: total,
                                }),
                                color: chartColors[type],
                                title: chartTitles[type],
                            })}
                        </Chart>
                    </div>
                ))}
            </div>
        </MailingInfo>
    )
}

export default FullScreenMapTopInfo
