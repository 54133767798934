import React, { useState } from 'react';
import { labFeatures } from '../../../api-config';

function DashboardSideBar() {
    
    return (
        <div id="aside" className="page-sidenav no-shrink  nav-expand" aria-hidden="true">
            <div className="sidenav h-100 modal-dialog SimplurisDashboardBackgroudColour SimplurisAllCasePagesSideBarDiv position-relative">
                <a href="#" className="SimplurisCloseMenu"> <i className="i-con i-con-close position-absolute d-block d-lg-none d-xl-none SimplurisCloseIconSideBar"></i> </a>
                <div className="flex scrollable hover SimplurisPaddingTop83">
                    <div className="nav-border b-primary" data-nav>
                        {labFeatures &&
                            <>
                            <ul className="nav bg SimplurisDashboardBackgroudColour">
                                <li className="nav-header SimplurisMouseRollOver hidden-folded active">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                        <span>All Activity</span>
                                        <span className="nav-badge SimplurisSidebarInfo">
                                            <b className="badge badge-sm badge-pill SimplurisSidebarBadge">7</b>
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-header SimplurisMouseRollOver hidden-folded pt-0">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                        <span>Tasks</span>
                                        <span className="nav-badge SimplurisSidebarInfo">
                                            <b className="badge badge-sm badge-pill SimplurisSidebarBadge">0</b>
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-header SimplurisMouseRollOver hidden-folded pt-0">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                        <span>Activity</span>
                                        <span className="nav-badge SimplurisSidebarInfo">
                                            <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">2</b>
                                        </span>
                                    </a>
                                </li>
                                {/*<li className="nav-header SimplurisMouseRollOver hidden-folded pt-0">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                        <span>Research</span>
                                        <span className="nav-badge SimplurisSidebarInfo">
                                            <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">2</b>
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-header SimplurisMouseRollOver hidden-folded pt-0">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                        <span>Chatter</span>
                                        <span className="nav-badge SimplurisSidebarInfo">
                                            <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">3</b>
                                        </span>
                                    </a>
                                </li>*/}
                                <li className="nav-header SimplurisMouseRollOver hidden-folded pt-0">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                        <span>Announcements</span>
                                        <span className="nav-badge SimplurisSidebarInfo">
                                            <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">2</b>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default DashboardSideBar;