import { simplurisFetch } from 'helpers'
import { start, resolved } from 'state/helpers'
export const FETCH_EXCEPTIONLOG = 'FETCH_EXCEPTIONLOG'
export const FETCH_APPLOG = 'FETCH_APPLOG'

//Fetach All Exception Log for Admin
export const feacthAllExceptionLogForAdmin = (
    pageIndex,
    pageSize,   
    sortColumn,
    sortColumnDir,
    searchValue,   
    filterType
) => async dispatch => {
    try {
        
        dispatch({ type: start(FETCH_EXCEPTIONLOG) })
        searchValue = searchValue == undefined || searchValue == "" ? null : searchValue
        
        const url = `log/getallexceptionlogs/${pageIndex}/${pageSize}/${sortColumn}/${sortColumnDir}/${searchValue}/${filterType}`
        const { resultObject, resultObjectRecordCount } = await simplurisFetch({ url })
        
        dispatch({
            type: resolved(FETCH_EXCEPTIONLOG),
            adminExceptionLogObject: {
                excepionLogs: resultObject,
                pageIndex: pageIndex,
                pageSize: pageSize,
                sortColumn: sortColumn,
                sortColumndir:sortColumnDir,
                searchValue: searchValue,
                totalCount: resultObjectRecordCount
            }
        })
        
    } catch (e) {
        console.error('Error in get all exception log for admin', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_EXCEPTIONLOG),
            adminExceptionLogObject: []
        })
    }
    }

//Fetach All App Log for Admin
export const feacthAllAppLogForAdmin = (
    pageIndex,
    pageSize,
    sortColumn,
    sortColumnDir,
    searchValue    
) => async dispatch => {
    try {

        dispatch({ type: start(FETCH_APPLOG) })
        searchValue = searchValue == undefined || searchValue == "" ? null : searchValue

        const url = `log/getallapplogs/${pageIndex}/${pageSize}/${sortColumn}/${sortColumnDir}/${searchValue}`
        const { resultObject, resultObjectRecordCount } = await simplurisFetch({ url })

        dispatch({
            type: resolved(FETCH_APPLOG),
            adminAppLogObject: {
                appLogs: resultObject,
                pageIndex: pageIndex,
                pageSize: pageSize,
                sortColumn: sortColumn,
                sortColumndir: sortColumnDir,
                searchValue: searchValue,
                totalCount: resultObjectRecordCount
            }
        })

    } catch (e) {
        console.error('Error in get all app log for admin', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_APPLOG),
            adminAppLogObject: []
        })
    }
}
