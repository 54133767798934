import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import { Modal } from 'react-bootstrap';
import { fetchCaseFilesData } from 'state/case-file/actions'
import LeftArrowPNG from '../../../../../static/img/left-arrow.png'
import CaseFileBlueImage from '../../../../../static/img/securefile_icon_small.png'
import Select from 'react-select';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ButtonLoader from '../../../../components/button-loader';
import ConfirmCheckIcon from '../../../../../static/img/confirm_check_icon.png'

function ExistingShareFileModalPopup
    ({
        caseID,
        OpenModalPopup,
        setShowExistingShareFile,
        secureFileID,
        fetchCaseFilesData,
        caseFilesData,
        random,
        setIsFetchCaseManager,
        isFetchCaseManager,
        isFromFileDetail,
        fetchSecureFileDetail
    }) {


    const [isFileUploadedAndSave, setIsFileUploadedAndSave] = useState(false);
    const [copied, setCopied] = useState(false);
    const [copiedLink, setCopiedLink] = useState(null);
    const [caseManagers, setCaseManagers] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedTeamMapIds, setSelectedTeamMapIds] = useState('');
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [isDataFetch, setIsDataFetch] = useState(false);
    const [caseManagerReqError, setCaseManagerReqError] = useState(false);
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [addNotes, setAddNotes] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    var caseFileDetails = caseFilesData && caseFilesData.filter((x, y) => {
        return (x.secureFileID == secureFileID)
    });
    var isFetchCaseManagerForDetail = caseFileDetails && caseFileDetails.length > 0 ? (caseFileDetails[0].teamPersonMapIds == undefined ? false : true) : false;

    var addDescription = caseFileDetails && caseFileDetails.length > 0 ? (caseFileDetails[0].description != undefined ? caseFileDetails[0].description : "") : "";

    const CopySharableLink = e => {
        setCopiedLink(e);
        setCopied(true);
        setTimeout(function () {
            setCopied(false);
        }, 3000);
    };

    const handleChange = selectedOption => {

        var selectedIds = [];
        if (selectedOption != null && selectedOption.length > 0) {
            var l = selectedOption.length;
            for (var i = 0; i < l; i++) {
                selectedIds.push(selectedOption[i].value);
            }
            setSelectedTeamMapIds(selectedIds.join(","));
        }

        setSelectedOption(selectedOption);
    };

    const fetchCaseManagers = async () => {
        simplurisFetch({
            url: `cases/${parseInt(caseID)}/getcasemanagers`
        }).then(result => {

            if (result.resultCode == "SUCCESS") {
                setCaseManagers(result.resultObject);

                var selectedTeamsIds = [];
                result.resultObject.filter(op => {
                    var separatedIds = [];
                    var str = caseFileDetails && caseFileDetails.length > 0 ? (caseFileDetails[0].teamPersonMapIds == undefined ? "0" : caseFileDetails[0].teamPersonMapIds) : "0";
                    separatedIds = str.split(',');
                    for (var i = 0; i < separatedIds.length; i++) {
                        if (op.value == separatedIds[i]) {
                            selectedTeamsIds.push(op);
                        }
                    }

                });

                var selectedIds = [];
                if (selectedTeamsIds.length > 0) {
                    var l = selectedTeamsIds.length;
                    for (var i = 0; i < l; i++) {
                        selectedIds.push(selectedTeamsIds[i].value);
                    }
                    setSelectedTeamMapIds(selectedIds.join(","));
                    setSelectedOption(selectedTeamsIds);
                }

                setShowPageLoader(false);
                setIsDataFetch(true);
            } else {
                setShowPageLoader(false);
                setIsDataFetch(false);
            }
        })
            .catch(e => {
                console.error("Error in fetch project managers data ", e, e.errorObject);
                setShowPageLoader(false);
                setIsDataFetch(false);
            });
    }

    function CloseExistingShareFileModalPopup() {
        setIsFetchCaseManager(false);
        setShowExistingShareFile(false);
        setIsFileUploadedAndSave(false);
        setErrorMessage('');
    }

    function CloseConfirmationMessageExistingShareFileModalPopup() {
        setIsFileUploadedAndSave(false);

        if (isFromFileDetail) {

            GetCaseFilesData();
            fetchSecureFileDetail(caseID, secureFileID);
        }
        else {

            GetCaseFilesData();
        }
    }

    const sendClick = () => {
        if (selectedOption == null || selectedOption.length <= 0) {
            setCaseManagerReqError(true);
        } else {
            setCaseManagerReqError(false);

            var formData = new FormData();
            formData.append('SecureFileID', secureFileID);
            formData.append('FileLink', caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].attachmentUrl : "");
            formData.append('TeamMemberPersonMapIDs', selectedTeamMapIds);
            formData.append('Description', addNotes);
            formData.append('Title', caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].title : "");
            formData.append('SenderFullName', localStorage.getItem(global.FirstName) + " " + localStorage.getItem(global.LastName));
            formData.append('AttachmentID', caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].attachmentID : "");
            formData.append('VendorUniqueID', caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].vendorUniqueID : "");
            formData.append('SenderFullName', localStorage.getItem(global.FirstName) + " " + localStorage.getItem(global.LastName));

            setShowButtonLoader(true);

            simplurisFetch({
                url: `cases/${caseID}/resendsecurefilelink`,
                method: 'POST',
                body: formData,
                json: false,
            }).then((result) => {

                setShowButtonLoader(false);
                setErrorMessage('');
                if (result.resultCode == "SUCCESS") {

                    CloseExistingShareFileModalPopup()
                    setIsFileUploadedAndSave(true);
                    setSelectedOption('');
                    setAddNotes('');


                } else {
                    setShowExistingShareFile(true);
                }
            }).catch(e => {

                setShowButtonLoader(false);
                setShowExistingShareFile(true);
                setErrorMessage("Something went wrong.");
            });
        }
    }

    const GetCaseFilesData = () => {
        fetchCaseFilesData({ caseID: caseID, sortColumn: 'Date', sortOrder: 'desc', fileFlag: true, serachValue: null });
    }

    const SaveAndCloseClick = () => {
        if (selectedOption == null || selectedOption.length <= 0) {
            setCaseManagerReqError(true);
        } else {
            setCaseManagerReqError(false);

            var formData = new FormData();
            formData.append('SecureFileID', secureFileID);
            formData.append('TeamMemberPersonMapIDs', selectedTeamMapIds);
            formData.append('Description', addNotes);
            formData.append('VendorUniqueID', caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].vendorUniqueID : "");

            setShowButtonLoader(true);
            simplurisFetch({
                url: `cases/${caseID}/savesecurefilelink`,
                method: 'POST',
                body: formData,
                json: false,
            }).then((result) => {
                setShowButtonLoader(false);
                if (result.resultCode == "SUCCESS") {
                    GetCaseFilesData();
                    setSelectedOption('');
                    setAddNotes('');
                    setShowExistingShareFile(false);
                } else {
                    setSelectedOption('');
                    setAddNotes('');
                    setShowExistingShareFile(true);
                }
            });
        }
    }

    useEffect(() => {
        try {
            setSelectedTeamMapIds(null);
            setSelectedOption(null);
            setAddNotes(addDescription);
            if (isFetchCaseManager && isFetchCaseManagerForDetail) {
                fetchCaseManagers();
            }
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [isFetchCaseManager])

    return (
        <>

            <Modal show={isFileUploadedAndSave} onHide={CloseConfirmationMessageExistingShareFileModalPopup} backdrop="static" size="lg" data-keyboard="false" data-backdrop="static">
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body className="px-5">
                    <div className="text-center p-5">
                        <div className="pb-2">
                            <img src={ConfirmCheckIcon} />
                        </div>
                        <div className="SimplurisFontSize26 text-black pb-5 mb-3">
                            Files shared successfully
                                </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={OpenModalPopup} onHide={CloseExistingShareFileModalPopup} backdrop="static" size="lg" data-keyboard="false" data-backdrop="static">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">
                            Secure File Transfer
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5">
                    {errorMessage != "" ? <ErrorPlacement items={errorMessage} /> : ''}
                    <div className="row">
                        <div className="col-md-1 col-sm-1 col-xs-1">
                            <span className="r avatar float-left float-md-none">
                                <img src={CaseFileBlueImage} className="SimplurisMailingsEnvelopeImage" />
                            </span>
                        </div>
                        <div className="col-md-10 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0">
                            <div className="SimplurisReportTitle">
                                {caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].title : ""}
                            </div>
                            <div className="SimplurisReportSubTitle">
                                {caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].fileType : ""}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group mt-5">
                                <div className="SimlurisReportTypeLabel mb-3">
                                    Get shareable link to this file:
                                </div>
                                <div className="Simpluris-case-file-card">
                                    <div className="Simpluris-case-file-header">
                                        <div className="d-flex">
                                            <div className="p-1 Simpluris-sharefile-title">
                                                Only the parties designated below will be able to authenticate and access this file.
                                            </div>
                                            <div className="ml-auto p-1 text-uppercase SimplurisDashboardTitle SimplurisOpacity08 SimplurisFontSize12 SimplurisCursorPointer">
                                                <CopyToClipboard
                                                    onCopy={() => CopySharableLink(caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].url : "")}
                                                    text={caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].url : ""} >
                                                    {copied &&
                                                        copiedLink != null &&
                                                        copiedLink == (caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].url : "") ? (
                                                            <span>Copied!</span>
                                                        ) : <span>Copy</span>}
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Simpluris-case-file-body">
                                        <a href={caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].url : "#"} target="_blank">{caseFileDetails && caseFileDetails.length > 0 ? caseFileDetails[0].url : ""}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-4">
                                <div className="SimlurisReportTypeLabel mb-3">
                                    Send this file to:
                                </div>
                                <div className="Simpluris-case-file-card1">
                                    <div className="Simpluris-case-file-header">
                                        <div className="d-flex">
                                            <div className="p-1 Simpluris-sharefile-title">
                                                Sharing this file is limited to only LiveCase users who can access this case. Start typing a name below to select someone from within the eligible parties.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Simpluris-case-file-body" style={{ position: 'relative', zIndex: 1, opacity: 1 }}>
                                        <Select
                                            //defaultValue={{ label: "Shawn Livermore", value: 28691 }}
                                            placeholder="Select"
                                            closeMenuOnSelect={false}
                                            classNamePrefix="simpluris-select"
                                            hideSelectedOptions={false}
                                            isMulti={true}
                                            name="form-field-name"
                                            value={selectedOption}
                                            options={caseManagers}
                                            onChange={handleChange}
                                        />
                                        {caseManagerReqError ? <span className="text-danger">Please select project manager</span> : null}
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <textarea className="form-control Simpluris-case-file-textarea"
                                        rows="5"
                                        placeholder="Add Note..."
                                        onChange={e => (setAddNotes(e.target.value))}>
                                        {addDescription != null ? addDescription : addNotes}
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10">
                            <button type="button" disabled={showButtonLoader} onClick={() => sendClick()} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor SimplurisFontSize14 SimplurisFontWeight400 w-sm btn-light py-3 mt-3">
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Save & Send</span>}
                            </button>
                            {/*  <button type="button" onClick={() => SaveAndCloseClick()} className="btn bg-white Simpluris-btnCancel SimplurisFontSize14 SimplurisFontWeight400 w-sm mb-1 btn-light py-3 rounded-0 border-0 mt-3" data-dismiss="modal">Save & Close</button> */}
                        </div>
                        <div className="col-md-2 float-lg-right float-md-right float-right">
                            <button type="button" onClick={() => CloseExistingShareFileModalPopup()} className="btn bg-white Simpluris-btnCancel SimplurisFontSize14 SimplurisFontWeight400 w-sm mb-1 btn-light py-3 rounded-0 border-0 mt-3" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default connect(
    (state, props) => ({
        caseFilesData: state.caseFiles[props.caseID],
    }),
    {
        fetchCaseFilesData,
    }
)(ExistingShareFileModalPopup)

function ErrorPlacement(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvFailedMessage" className="alert alert-danger" role="alert">
            <button type="button" className="close SimpluriAlertMessageCloseIcon" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}