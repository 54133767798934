import { simplurisFetch } from 'helpers'

export const UPDATE_CASE = 'UPDATE_CASE'
export const UPDATE_CASES = 'UPDATE_CASES'
export const FETCH_CASE = 'FETCH_CASE'
export const FETCH_CASES = 'FETCH_CASES'
export const FETCH_CASES_STAGECOUNT = 'FETCH_CASES_STAGECOUNT'
export const FETCH_CASE_PERMISSIONS = 'FETCH_CASE_PERMISSIONS'
export const UPDATE_TEAMMEMBER_UNREAD_MESSAGE =
    'UPDATE_TEAMMEMBER_UNREAD_MESSAGE'
export const UPDATE_CASE_FAVORITE = 'UPDATE_CASE_FAVORITE'
export const RESET_CASES = 'RESET_CASES'

export const updateCase = singleCase => ({
  type: UPDATE_CASE,
  singleCase,
})

export const updateCases = cases => ({
  type: UPDATE_CASES,
  cases,
})

export const resetCases = cases => ({
    type: RESET_CASES,
    cases,
})

//Fetch Case Detail By CaseID
export const fetchCase = ({
  caseID,
  lastActivityID = 0,
  _pageSize = 10,
}) => async dispatch => {
  try {
    const url = `cases/${caseID}/${lastActivityID}/${_pageSize}`
    const { resultObject } = await simplurisFetch({ url })
    dispatch({
      type: FETCH_CASE,
      singleCase: resultObject.case,
      caseID,
    })

    dispatch({
      type: FETCH_CASE_PERMISSIONS,
      singleCasePermissions: resultObject.permissions,
      caseId: caseID,
    })
  } catch (e) {
    console.error('Error in fetch case', e, e.errorObject)
    dispatch({
      type: FETCH_CASE,
        singleCase: [],
        caseID,
    })
  }
}

//Fetch Cases
export const fetchCases = ({
  searchTerm,
  caseStageFilter = 0,
  lastCaseId,
  _pageSize,
}) => async dispatch => {
  try {
    const url = `getmycases/${
      searchTerm ? searchTerm : null
    }/${caseStageFilter}/${lastCaseId}/${_pageSize}`
    let { resultObject } = await simplurisFetch({ url })

    let lastCaseIds =
      resultObject.length > 0 ? resultObject[resultObject.length - 1].caseID : 0

    let isHasNewData = false

    if (resultObject.length > _pageSize) {
      resultObject = resultObject.slice(0, -1)
      isHasNewData = true
    }

    dispatch({
      type: FETCH_CASES,
      cases: {
        cases: resultObject,
        lastCaseId: lastCaseIds,
        isHasNewData: isHasNewData,
        removeFromRedux: lastCaseId == 0 ? true : false,
      },
    })
  } catch (e) {
    console.error('Error in fetch cases', e, e.errorObject)

    dispatch({
      type: FETCH_CASES,
      cases: [],
    })
  }
}

export const updateTeamMemberUnreadMessage = ({
  messageCaseID,
  personMapID,
}) => async dispatch => {
  dispatch({
    type: UPDATE_TEAMMEMBER_UNREAD_MESSAGE,
    unreadMessages: {
      messageCaseID: messageCaseID,
      personMapID: personMapID,
    },
  })
}
