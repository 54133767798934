import { CHANGE_CASE_SUBTAB } from './actions'

const initialState = ''

export default (state = initialState, { type, subtab }) => {
    switch (type) {
        case CHANGE_CASE_SUBTAB:            
            return subtab;      
        default:            
            return state;
    }
}