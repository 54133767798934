import React, { useState, useEffect } from "react";
import { IsNullOrEmptyString } from "../../../global";
import { getGravatarUrl, GravatarOptions } from "react-awesome-gravatar";
var imageExists = require("image-exists");

const options = {
    size: 95
};

function GlobalImageSet(items) {
    let isSandbox = items.isSandbox != null &&
        items.isSandbox != "null" &&
        items.isSandbox != "undefined"
        ? items.isSandbox
        : "";
    let firstLetter = "";
    let managerFirstLetter = "";
    let gravatarURLProfileUrl = "";
    let profilePic =
        items.image != null && items.image != "null" && items.image != "undefined"
            ? items.image
            : "";
    let firstName =
        items.firstname != null &&
            items.firstname != "null" &&
            items.firstname != "undefined"
            ? items.firstname
            : "";
    let classValue =
        items.dynamicClass != null &&
            items.dynamicClass != "null" &&
            items.dynamicClass != "undefined"
            ? items.dynamicClass
            : "";
    let isChatImageIcon =
        items.isChatImageIcon != null &&
            items.isChatImageIcon != "null" &&
            items.isChatImageIcon != "undefined"
            ? items.isChatImageIcon
            : "";
    let isTeamMember =
        items.isTeamMember != null &&
            items.isTeamMember != "null" &&
            items.isTeamMember != "undefined"
            ? items.isTeamMember
            : "";
    let isTeam =
        items.isTeam != null &&
            items.isTeam != "null" &&
            items.isTeam != "undefined"
            ? items.isTeam
            : "";
    let email =
        items.email != null && items.email != "null" && items.email != "undefined"
            ? items.email
            : "";
    let [isURLExists, setIsURLExists] = useState(false);
    if (firstName.length == 2) {
        firstLetter = firstName;
        managerFirstLetter = firstName;
    }
    else {
        firstLetter = firstName.match(/\b(\w)/g);
        managerFirstLetter = firstLetter != null ? firstLetter.join("") : "";
    }

    useEffect(() => {
        if (!IsNullOrEmptyString(profilePic)) {
            imageExists(profilePic, function (exists) {
                if (exists) {
                    setIsURLExists(true);
                }
            });
        }
    }, [Math.random()]);

    const onImageError = (element) => {        
        element.currentTarget.classList.add('d-none')
        element.currentTarget.nextSibling.removeAttribute('class')
    }

    //const gravatarURLProfileUrl = getGravatarUrl('shawnlivermore@gmail.com', options); //items.image
    if (!IsNullOrEmptyString(email)) {
        if (isChatImageIcon == "true") {
            gravatarURLProfileUrl = getGravatarUrl(email);
        } else {
            gravatarURLProfileUrl = getGravatarUrl(email, options);
        }
    }

    return (
        <div>
            {(() => {
                if (!IsNullOrEmptyString(profilePic)) {
                    if (isURLExists) {
                        if (isTeamMember == "true") {
                            return (
                                <>
                                    {" "}
                                    <span className={classValue}></span>{" "}
                                    <img src={profilePic} className="rounded-circle" onError={(e) => { onImageError(e) }} />
                                    <div className="d-none">{managerFirstLetter}</div>
                                </>
                            );
                        } else if (isTeam == "true") {
                            return <img src={profilePic} className={classValue} />;
                        } else {
                            return (
                                <>
                                    <span className={classValue}>
                                        <img src={profilePic} alt="..." />
                                    </span>
                                </>
                            );
                        }
                    } else {
                        if (isTeamMember == "true") {
                            return (
                                <>
                                    <span className={classValue}></span>
                                    <div>{managerFirstLetter}</div>
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <span className={classValue}>{managerFirstLetter}</span>
                                </>
                            );
                        }
                    }
                } else {
                    if (
                        IsNullOrEmptyString(profilePic)
                        //&& IsNullOrEmptyString(gravatarURLProfileUrl)
                    ) {
                        if (isTeam == "true") {
                            return (
                                <>
                                    <span className={classValue}>{managerFirstLetter}</span>
                                </>
                            );
                        } else if (isTeamMember == "true") {
                            return (
                                <>
                                    <span className={classValue}></span>
                                    <div> {managerFirstLetter}</div>
                                </>
                            );
                        }
                        else {
                            return <span className={classValue}>{managerFirstLetter}</span>
                        }
                    } else {
                        if (isTeamMember == "true") {
                            return (
                                <>
                                    {" "}
                                    <div>
                                        {" "}
                                        <span className={classValue}></span>{" "}
                                        <img
                                            src={gravatarURLProfileUrl}
                                            className="rounded-circle"
                                        />
                                    </div>
                                </>
                            );
                        }
                        if (isTeam == "true") {
                            return (
                                <span className={classValue}>
                                    {" "}
                                    <img src={gravatarURLProfileUrl} />
                                </span>
                            );
                        } else {
                            return <img src={gravatarURLProfileUrl} className={classValue} />;
                        }
                    }
                }

                //if (isSandbox == "true") {
                //    if (isTeamMember == "true") {
                //        return (
                //            <>
                //                <span className={classValue}></span>
                //                <div>{managerFirstLetter}</div>
                //            </>
                //        );
                //    } else {
                //        return (
                //            <>
                //                <span className={classValue}>{managerFirstLetter}</span>
                //            </>
                //        );
                //    }
                //}
                //else {
                //    if (!IsNullOrEmptyString(profilePic)) {
                //        if (isURLExists) {
                //            if (isTeamMember == "true") {
                //                return (
                //                    <>
                //                        {" "}
                //                        <span className={classValue}></span>{" "}
                //                        <img src={profilePic} className="rounded-circle" />
                //                    </>
                //                );
                //            } else if (isTeam == "true") {
                //                return <img src={profilePic} className={classValue} />;
                //            } else {
                //                return (
                //                    <>
                //                        <span className={classValue}>
                //                            <img src={profilePic} alt="..." />
                //                        </span>
                //                    </>
                //                );
                //            }
                //        } else {
                //            if (isTeamMember == "true") {
                //                return (
                //                    <>
                //                        <span className={classValue}></span>
                //                        <div>{managerFirstLetter}</div>
                //                    </>
                //                );
                //            } else {
                //                return (
                //                    <>
                //                        <span className={classValue}>{managerFirstLetter}</span>
                //                    </>
                //                );
                //            }
                //        }
                //    } else {
                //        if (
                //            IsNullOrEmptyString(profilePic) &&
                //            IsNullOrEmptyString(gravatarURLProfileUrl)
                //        ) {
                //            if (isTeam == "true") {
                //                return (
                //                    <>
                //                        <span className={classValue}>{managerFirstLetter}</span>
                //                    </>
                //                );
                //            } else {
                //                return (
                //                    <>
                //                        <span className={classValue}></span>
                //                        <div> {managerFirstLetter}</div>
                //                    </>
                //                );
                //            }
                //        } else {
                //            if (isTeamMember == "true") {
                //                return (
                //                    <>
                //                        {" "}
                //                        <div>
                //                            {" "}
                //                            <span className={classValue}></span>{" "}
                //                            <img
                //                                src={gravatarURLProfileUrl}
                //                                className="rounded-circle"
                //                            />
                //                        </div>
                //                    </>
                //                );
                //            }
                //            if (isTeam == "true") {
                //                return (
                //                    <span className={classValue}>
                //                        {" "}
                //                        <img src={gravatarURLProfileUrl} />
                //                    </span>
                //                );
                //            } else {
                //                return <img src={gravatarURLProfileUrl} className={classValue} />;
                //            }
                //        }
                //    }
                //}
            })()}
        </div>
    );
}

export { GlobalImageSet as default };
