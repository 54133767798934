import React from 'react'
function TopMilestonesData({ next30DaysMilestones, nextRemainingMilestones }) {    
    if ((next30DaysMilestones == null || next30DaysMilestones.length > 0)) {
        next30DaysMilestones.sort(function (a, b) {

            var key1 = new Date(a.deadlineDateOnly);
            var key2 = new Date(b.deadlineDateOnly);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
        });
    }
    if ((nextRemainingMilestones == null || nextRemainingMilestones.length > 0)) {
        nextRemainingMilestones.sort(function (a, b) {

            var key1 = new Date(a.deadlineDateOnly);
            var key2 = new Date(b.deadlineDateOnly);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
        });
    }
    if ((nextRemainingMilestones == null || nextRemainingMilestones.length <= 0) && (next30DaysMilestones == null || next30DaysMilestones.length <= 0)) {
        return <div className="col-md-12">
            <div className="text-center mt-3">None</div>
        </div>
    }
    else {
        return <>
            {            
                (next30DaysMilestones == null || next30DaysMilestones.length > 0) ?
                    <div className="col-md-12 col-sm-12 col-xs-12 mt-3 pb-4">
                        <div className="row">
                            <div className="pl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="SimplurisCaseMilstonesDateGridSqure r avatar bg-primary-lt m-auto">
                                    <div className="text-center">
                                        <label className="d-block SimplurisCaseMilstonesDateLableGridSqure mb-0">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                day: '2-digit'
                                            }).format(new Date(next30DaysMilestones[0].deadlineDateOnly))}
                                        </label>
                                        <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                month: 'long'
                                            }).format(new Date(next30DaysMilestones[0].deadlineDateOnly))}
                                        </label>
                                        <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                year: 'numeric'
                                            }).format(new Date(next30DaysMilestones[0].deadlineDateOnly))}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-2 pl-xl-0">
                                <div className="SimplurisMilestoneLables SimplurisFontSize14">
                                    {next30DaysMilestones[0].milestoneTitle}
                                </div>
                                <div className="SimplurisMilestoneGridDescription">
                                    {(() => {
                                        if (next30DaysMilestones[0].description != null) {
                                            return <p>{next30DaysMilestones[0].description}</p>
                                        }
                                        else {
                                            return <p></p>
                                        }
                                    }
                                    )()}
                                </div>
                            </div>
                        </div>
                    </div> : <></>
            }
            {
                (next30DaysMilestones == null || next30DaysMilestones.length <= 0) && (nextRemainingMilestones == null || nextRemainingMilestones.length > 0) ?
                    <div className="col-md-12 col-sm-12 col-xs-12 mt-3 pb-4">
                        <div className="row">
                            <div className="pl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="SimplurisCaseMilstonesDateGridSqure r avatar bg-primary-lt m-auto">
                                    <div className="text-center">
                                        <label className="d-block SimplurisCaseMilstonesDateLableGridSqure mb-0">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                day: '2-digit'
                                            }).format(new Date(nextRemainingMilestones[0].deadlineDateOnly))}
                                        </label>
                                        <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                month: 'long'
                                            }).format(new Date(nextRemainingMilestones[0].deadlineDateOnly))}
                                        </label>
                                        <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                year: 'numeric'
                                            }).format(new Date(nextRemainingMilestones[0].deadlineDateOnly))}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-2 pl-xl-0">
                                <div className="SimplurisMilestoneLables SimplurisFontSize14">
                                    {nextRemainingMilestones[0].milestoneTitle}
                                </div>
                                  <div className="SimplurisMilestoneGridDescription">
                                            {(() => {
                                        if (nextRemainingMilestones[0].description != null) {
                                                    return <p>{nextRemainingMilestones[0].description}</p>
                                                }
                                                else {
                                                    return <p></p>
                                                }
                                            }
                                            )()}
                                        </div>
                            </div>
                        </div>
                    </div> : <></>
            }

            {/*  data.items.map((item) =>
                    <div className="col-md-12 col-sm-12 col-xs-12 mt-3 pb-4">
                        <div className="row">
                            <div className="pl-3 col-md-4 col-sm-12 col-xs-12">
                                <div className="SimplurisCaseMilstonesDateGridSqure r avatar bg-primary-lt">
                                    <div className="text-center">
                                        <label className="d-block SimplurisCaseMilstonesDateLableGridSqure mb-0">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                day: '2-digit'
                                            }).format(new Date(item.deadlineDateOnly))}
                                        </label>
                                        <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                month: 'long'
                                            }).format(new Date(item.deadlineDateOnly))}
                                        </label>
                                        <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                year: 'numeric'
                                            }).format(new Date(item.deadlineDateOnly))}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0">
                                <div className="SimplurisMilestoneLables SimplurisFontSize14">
                                    {item.milestoneTitle}
                                </div>
                                <div className="SimplurisMilestoneGridDescription">
                                    {(() => {
                                        if (item.description != null) {
                                            return <p>{item.description}</p>
                                        }
                                        else {
                                            return <p></p>
                                        }
                                    }
                                    )()}
                                </div>
                            </div>
                        </div>
                    </div>
                )*/}
        </>
    }
}

export default TopMilestonesData