import invariant from 'invariant'
import { fakeAPIData } from 'api-config'

class FetchError extends Error {
    constructor(errorObject, ...params) {
        super(...params)
        this.name = 'FetchError'
        this.errorObject = errorObject
        this.date = new Date()
    }
}

export const simplurisFetch = async ({
    url,
    method = 'GET',
    headers,
    json,
    ...other
}) => {        
    invariant(url, 'You must pass a url to fetch from')
    const modifiedUrl = `${url}${fakeAPIData ? '/fake' : ''}`
    
    const defaultHeaders = {
        Authorization: `Bearer ${localStorage.getItem(global.AuthTokenKey)}`,
    }
    if (method.toUpperCase() === 'POST' && json !== false) {
        defaultHeaders['Content-Type'] = 'application/json'
    }

    const res = await fetch(`${global.API_URL}${modifiedUrl}`, {
        ...other,
        method,
        headers: {
            ...defaultHeaders,
            ...headers,
        },
    })

    if (res.status === 401) {
        window.location.href = '/sign-in'
        return
    }
    
    const resJson = await res.json()
    
    if (
        resJson.resultCode === 'ERROR' ||
        resJson.resultCode === 'EXISTS' ||
        resJson.resultCode === 'USERNOTACCESSCASE' ||
        resJson.resultCode === 'NOT_EXISTS' ||
        resJson.resultCode === 'NOT_FOUND' ||
        resJson.resultCode === 'NOT_MATCH'
    ) {
        throw new FetchError(resJson)
    }

    return resJson
}

export const getPercentage = ({ part, total, decimalPlaces = 0 }) => {
    const divided = part / total
    if (!divided) {
        return 0
    }
    return Number(divided * 100).toFixed(decimalPlaces)
}

export const toCamel = (o) => {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}
