import React, { useState, useEffect } from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { SectionSpinner } from "react/components/spinners";
import { simplurisFetch } from "helpers";
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components';
import { FormatPhoneNumberForUS } from '../../../../global'

function CaseInfo({ caseID }) {
    const [caseInfoData, setCaseInfoData] = useState({});
    const [plaintiffs, setPlaintiffs] = useState([]);
    const [plaintiffAttorneys, setPlaintiffAttorneys] = useState([]);
    const [defendants, setDefendants] = useState([]);
    const [defendantAttorneys, setDefendantAttorneys] = useState([]);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [isDataFetch, setIsDataFetch] = useState(false);
    const [caseDocument, setCaseDocument] = useState([]);
    const [tablePage, setTablePage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(0);
    const [sortName, setSortName] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const fetchCaseAttorneys = async ({ caseID }) => {
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/${caseID}/caseinfo`
        })
            .then(result => {
                setShowPageLoader(false);
                if (result.name == undefined && result.name != "FetchError") {                    
                    setCaseInfoData(result.resultObject);
                    setPlaintiffs(result.resultObject.plaintiffs);
                    setPlaintiffAttorneys(result.resultObject.plaintiffAttorneys);
                    setDefendants(result.resultObject.defendants);
                    setDefendantAttorneys(result.resultObject.defendantAttorneys);
                }
            })
            .catch(e => {
                console.error("Error in case info", e, e.errorObject);
                setShowPageLoader(false);
            });
    };

    const fetchCaseDocuments = async (
        caseID,
        page = 1,
        pageSize = 9999999,
        sortName = 1,
        sortOrder = 'desc',
        searchText = null,
    ) => {
        if (searchText === "") searchText = null;
        setShowPageLoader(true);
        simplurisFetch({
            url: `cases/${caseID}/casedocument/${page}/${pageSize}/${sortName}/${sortOrder}/${searchText}`
        }).then(result => {
            setShowPageLoader(false);
            if (result.resultCode != "SUCCESS") {
                setIsDataFetch(false);
            }
            else {
                setTablePage(page);
                setSortName(sortName);
                setSortOrder(sortOrder);
                setTotalSize(result.resultObjectRecordCount);
                setCaseDocument(result.resultObject);
                setIsDataFetch(true);
            }
        })
            .catch(e => {
                console.error("Error in case info document", e, e.errorObject);
                setShowPageLoader(false);
            });
    };

    useEffect(() => {
        fetchCaseAttorneys({ caseID });
        fetchCaseDocuments(caseID, 1, 100, 'documentID', 'asc', null);
    }, []);

    function columnClassNameFormat() {
        return "td-column";
    }

    function linkCellFormatter(cell,row) {
            return (
                <a
                    className="SimplurisBlueColor SimplurisPointer"
                    href={cell}
                    target="_blank"
                >
                    {cell}
                </a>
            );
    }

    function setTableOption() {
        if (!showPageLoader) {
            return "no data found!";
        }
    }

    function onPageChange(page, sizePerPage) {
        setTablePage(page);
        setCaseDocument([]);
        fetchCaseDocuments(caseID, page, sizePerPage, sortName, sortOrder, searchText);
    }

    function onSizePerPageList(sizePerPage) {
        setSizePerPage(sizePerPage);
        setCaseDocument([]);
        fetchCaseDocuments(caseID, 1, sizePerPage, sortName, sortOrder, searchText);
    }

    function onSortChange(sortName, sortOrder) {
        setSortName(sortName);
        setSortOrder(sortOrder);
        fetchCaseDocuments(caseID, 1, sizePerPage, sortName, sortOrder, searchText);
    }

    const defaultSorted = [
        {
            dataField: "documentID",
            order: "asc"
        }
    ];

    function onSearchChange(searchText) {
        setSearchText(searchText);
        if (searchText === "") setSearchText("");
        searchText = searchText === "" ? null : searchText;
        setCaseDocument([]);
        fetchCaseDocuments(caseID, 1, sizePerPage, sortName, sortOrder, searchText);
        setTimeout(function () {
            setSearchText("");
        }, 100000);
    }

    const RemotePagination = ({
        data,
        page,
        sizePerPage,
        totalSize,
        onPageChange,
        onSizePerPageList,
        onSortChange,
        onSearchChange
    }) => (
            <div>
                <BootstrapTable
                    data={data}
                    remote={true}
                    hover={true}
                    search={true}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: totalSize }}
                    options={{
                        searchField: getSearchField({ defaultValue: searchText }),
                        toolBar: getToolbarWithTotal({ buttonText: `All ${totalSize}` }),
                        sortIndicator: true,
                        noDataText: setTableOption(),
                        sizePerPage: sizePerPage,
                        onPageChange: onPageChange,
                        sizePerPageList: [10, 20, 35],
                        page: page,
                        onSizePerPageList: onSizePerPageList,
                        onSortChange: onSortChange,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSearchChange: onSearchChange,
                        searchDelayTime: 1000
                    }}
                    style={{ "margin-bottom": "0px" }}
                    tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                    bodyContainerClass="text-grid-td"
                    defaultSorted={defaultSorted}
                >
                    <TableHeaderColumn isKey dataField="documentID" width={"16%"} dataSort={true} searchable={false}>Document ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="documentTitle" width={"22%"} dataSort={true}>Document Title</TableHeaderColumn>
                    <TableHeaderColumn dataField="documentURL" width={"30%"} dataSort={true} dataFormat={linkCellFormatter}>Download Link</TableHeaderColumn>
                    <TableHeaderColumn dataField="createdDate" width={"15%"} searchable={false}
                        dataFormat={date => new Date(date).toLocaleDateString()}
                    >Date Created</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );

    function PhoneNumnerFormatForpalintiffAttorneys(cell, row) {
        return <span>{FormatPhoneNumberForUS(cell)}</span>;
    }

    function PhoneNumnerFormatForLeadPlaintiff(cell, row)
    {
        return <span>{FormatPhoneNumberForUS(cell)}</span>;
    }

    function PhoneNumnerFormatForDefendant(cell, row) {
        return <span>{FormatPhoneNumberForUS(cell)}</span>;
    }

    function PhoneNumnerFormatForDefendantAttorney(cell, row) {
        return <span>{FormatPhoneNumberForUS(cell)}</span>;
    }    

    if (Object.keys(caseInfoData).length) {
        return (
            <div>
                <div className="tab-content">
                    <div
                        className="tab-pane fade show active"
                        id="caseinfo"
                        role="tabpanel"
                        aria-labelledby="caseinfo-tab"
                    >
                        <CaseInfoDetail items={caseInfoData} />
                    </div>
                    <div
                        className="tab-pane fade"
                        id="attorney"
                        role="tabpanel"
                        aria-labelledby="attorney-tab"
                    >
                        {(() => {
                            return (
                                <>
                                    <div className="card SimplurisDashboardCard">
                                        <div className="px-4 py-3">
                                            <div className="pl-0 mb-3">
                                                <span className="SimplurisSubTabTitle">
                                                    Lead Plaintiff(s)
                          </span>
                                            </div>
                                            <BootstrapTable
                                                data={plaintiffs}
                                                hover
                                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                                bodyContainerClass="text-grid-td"
                                            >
                                                <TableHeaderColumn dataField="personID" isKey hidden>
                                                    ID
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="name"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Name
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personphone1"
                                                    columnClassName={columnClassNameFormat}
                                                    dataFormat={PhoneNumnerFormatForLeadPlaintiff}
                                                >
                                                    Phone
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="businessName"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Bussiness Name
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personEmail"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Email
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="stateTitle"
                                                    width={'15%'}
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    State
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="city"
                                                    width={'15%'}
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    City
                          </TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                        <div className="px-4 py-3">
                                            <div className="pl-0 mb-3">
                                                <span className="SimplurisSubTabTitle">
                                                    Defendant(s)
                          </span>
                                            </div>
                                            <BootstrapTable
                                                data={defendants}
                                                hover
                                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                                bodyContainerClass="text-grid-td"
                                            >
                                                <TableHeaderColumn dataField="personID" isKey hidden>
                                                    ID
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="name"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Name
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personphone1"
                                                    width={'20%'}
                                                    columnClassName={columnClassNameFormat}
                                                    dataFormat={PhoneNumnerFormatForDefendant}
                                                >
                                                    Phone
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="businessName"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Bussiness Name
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personEmail"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Email
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="stateTitle"
                                                    width={'15%'}
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    State
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="city"
                                                    width={'15%'}
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    City
                          </TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                        <div className="px-4 py-3">
                                            <div className="pl-0 mb-3">
                                                <span className="SimplurisSubTabTitle">
                                                    Plaintiff Attorney(s)
                          </span>
                                            </div>
                                            <BootstrapTable
                                                data={plaintiffAttorneys}
                                                hover
                                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                                bodyContainerClass="text-grid-td"
                                            >
                                                <TableHeaderColumn dataField="personID" isKey hidden>
                                                    ID
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="name"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Name
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personphone1"
                                                    columnClassName={columnClassNameFormat}
                                                    dataFormat={PhoneNumnerFormatForpalintiffAttorneys}
                                                >
                                                    Phone
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personEmail"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Email
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="address1"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Address
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="stateTitle"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    State
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="city"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    City
                          </TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                        <div className="px-4 py-3">
                                            <div className="pl-0 mb-3">
                                                <span className="SimplurisSubTabTitle">
                                                    Defendant Attorney(s)
                          </span>
                                            </div>
                                            <BootstrapTable
                                                data={defendantAttorneys}
                                                hover
                                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                                bodyContainerClass="text-grid-td"
                                            >
                                                <TableHeaderColumn dataField="personID" isKey hidden>
                                                    ID
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="name"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Name
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personphone1"
                                                    columnClassName={columnClassNameFormat}
                                                    dataFormat={PhoneNumnerFormatForDefendantAttorney}
                                                >
                                                    Phone
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="personEmail"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Email
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="address1"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    Address
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="stateTitle"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    State
                          </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="city"
                                                    columnClassName={columnClassNameFormat}
                                                >
                                                    City
                          </TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </>
                            );
                        })()}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="casedocument"
                        role="tabpanel"
                        aria-labelledby="casedocument-tab"
                    >
                        {(() => {
                            if (showPageLoader) {
                                return <div className="row"> <SectionSpinner /></div>
                            }
                            if (isDataFetch) {
                                return (
                                    <>
                                        <div className="card SimplurisDashboardCard">
                                            <div className="px-4 py-3">
                                                <div className="pl-0 mb-3">
                                                    <RemotePagination
                                                        data={caseDocument}
                                                        page={tablePage}
                                                        sizePerPage={sizePerPage}
                                                        totalSize={totalSize}
                                                        onPageChange={onPageChange}
                                                        onSizePerPageList={onSizePerPageList}
                                                        onSortChange={onSortChange}
                                                        onSearchChange={onSearchChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            };
                        })()}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="SimplurisCaseDetailsLoaderSection">
                {showPageLoader ? <div className="row"> <SectionSpinner /></div> : null}
            </div>
        );
    }
}

export default CaseInfo;

function CaseInfoDetail(data) {
    if (data.items != null) {
        return (
            //data.items.map(item =>
            <div className="card SimplurisDashboardCard">
                <div className="p-1">
                    <div className="px-4 py-4">
                        <div className="col-md-12 pl-0 mb-3">
                            <span className="SimplurisSubTabTitle">Case info</span>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-6 col-12">
                                <small className="SimplurisMutableTitle">
                                    Project Manager Name
                </small>
                                <div className="position-relative">
                                    <label className="text-dark">
                                        {data.items.caseManagerName != null &&
                                            data.items.caseManagerName != ""
                                            ? data.items.caseManagerName
                                            : "N/A"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <small className="SimplurisMutableTitle">
                                    Case Phone Number
                </small>
                                <div className="position-relative">
                                    <label className="text-dark">
                                        {data.items.casePhoneNumber != null &&
                                            data.items.casePhoneNumber != ""
                                            ? FormatPhoneNumberForUS(data.items.casePhoneNumber)
                                            : "N/A"}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            {/*<div className="col-md-6 col-12">
                <small className="SimplurisMutableTitle">Case Name</small>
                <div className="position-relative">
                  <label className="text-dark">
                    {data.items.caseTitle != null && data.items.caseTitle != ""
                      ? data.items.caseTitle
                      : "N/A"}
                  </label>
                </div>
              </div>
                            <div className="col-md-6 col-12">
                                <small className="SimplurisMutableTitle">Case Status</small>
                                <div className="position-relative">
                                    <label className="text-dark">
                                        {data.items.caseStatusTitle != null &&
                                            data.items.caseStatusTitle != ""
                                            ? data.items.caseStatusTitle
                                            : "N/A"}
                                    </label>
                                </div>
                            </div>*/}
                            <div className="col-md-6 col-12">
                                <small className="SimplurisMutableTitle">
                                    Case Name
                </small>
                                <div className="position-relative">
                                    <label className="text-dark">
                                        {data.items.officialCaseName != null &&
                                            data.items.officialCaseName != ""
                                            ? data.items.officialCaseName
                                            : "N/A"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <small className="SimplurisMutableTitle">
                                    Amount Of Issue ($)
                </small>
                                <div className="position-relative">
                                    <label className="text-dark">
                                        {data.items.amountAtIssue != null &&
                                            data.items.amountAtIssue != ""
                                            ? data.items.amountAtIssue.toLocaleString(
                                                navigator.language,
                                                { minimumFractionDigits: 2 }
                                            )
                                            : "N/A"}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row mb-2">
                            <div className="col-md-6 col-12">
                                <small className="SimplurisMutableTitle">Case Type</small>
                                <div className="position-relative">
                                    <label className="text-dark">
                                        {data.items.caseTypeTitle != null &&
                                            data.items.caseTypeTitle != ""
                                            ? data.items.caseTypeTitle
                                            : "N/A"}
                                    </label>
                                </div>
                            </div> 
                        </div>*/}
                        {/*<div className="row mb-2">

                            <div className="col-md-6 col-12">
                <small className="SimplurisMutableTitle">
                  Lead Plaintiffs Required to Submit Claims?
                </small>
                <div className="position-relative">
                  <label className="text-dark">
                    {data.items.leadPlantiffClaim != false ? "Yes" : "No"}
                  </label>
                </div>
              </div>
                        </div>
                        <div className="row mb-2">
              <div className="col-md-6 col-12">
                <small className="SimplurisMutableTitle">
                  Auto Update Backup Withholding Status
                </small>
                <div className="position-relative">
                  <label className="text-dark">
                    {data.items.isAutoUpdatedBackupWithholdingStatus != false
                      ? "Yes"
                      : "No"}
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <small className="SimplurisMutableTitle">
                  PCM Requires Approval?
                </small>
                <div className="position-relative">
                  <label className="text-dark">
                    {data.items.isPCMRequiresApproval != false ? "Yes" : "No"}
                  </label>
                </div>
              </div>
            </div>*/}
                    </div>
                </div>
            </div>
            //)
        );
    } else {
        return <></>;
    }
}
