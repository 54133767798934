import { createSelector } from 'reselect'
import _ from 'lodash'

import { caseIDFromPropsSelector } from 'state/helpers'

const stateSelector = state => state.mailings.history

export const getMailingsHistoryByCaseID = createSelector(
	[stateSelector, caseIDFromPropsSelector],
	(state, caseID) =>
		Object.values(state).filter(history => history.caseID === caseID)
)
