import * as React from 'react'
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Lines,
    Line,
    Markers,
    Marker,
} from 'react-simple-maps'
import { connect } from 'react-redux'
import { Motion, spring } from 'react-motion'
import _ from 'lodash'
import statesJSON from './states.json'
import { buildCurves } from './helpers'
import { getHistoryFetching } from 'state/case-detail-view/mailings/history/selectors'
import { OverlaySpinner } from 'react/components/spinners'
import { appMode } from '../../../../../../api-config'
import  PlayIcon  from '../../../../../../static/img/play-btn.png'

const centerOfUS = [-97, 40]
const USMap = ({
    lines = [],
    linesdetails = [],
    isFetching,
    onLineClick = _.noop,
    startingZoom = 1,
    isZoom = false,
}) => {
    const [center, setCenter] = React.useState(centerOfUS)
    const [zoom, setZoom] = React.useState(startingZoom)	// Used to determine if the map was dragged or clicked on as the underlying geography fires onClick even when dragged
    const [mouseDownCoords, setMouseDownCoords] = React.useState({})
    const [mouseUpCoords, setMouseUpCoords] = React.useState({})
    const lastMoveWasAClick = _.isEqual(mouseDownCoords, mouseUpCoords)

    var markers1 = [];
    if (appMode == "sandbox") {
        for (var i = 0; i < lines.length; i++) {
            if (i == 0) {
                markers1.push({
                    markerOffset: 25,
                    coordinates: [parseFloat(lines[i].coordinates.start[0]), parseFloat(lines[i].coordinates.start[1])]
                })
                markers1.push({
                    markerOffset: 25,
                    coordinates: [parseFloat(lines[i].coordinates.end[0]), parseFloat(lines[i].coordinates.end[1])]
                })
            }
            else {
                markers1.push({
                    markerOffset: 25,
                    coordinates: [parseFloat(lines[i].coordinates.end[0]), parseFloat(lines[i].coordinates.end[1])]
                })
            }
        }
    }
    else {
        for (var i = 0; i < lines.length; i++) {
            if (i == 0) {
                markers1.push({
                    markerOffset: 25,
                    coordinates: [parseFloat(lines[i].coordinates.start[0]), parseFloat(lines[i].coordinates.start[1])]
                })
                markers1.push({
                    markerOffset: 25,
                    coordinates: [parseFloat(lines[i].coordinates.end[0]), parseFloat(lines[i].coordinates.end[1])]
                })
            }
            else {
                markers1.push({
                    markerOffset: 25,
                    coordinates: [parseFloat(lines[i].coordinates.end[0]), parseFloat(lines[i].coordinates.end[1])]
                })
            }
        }
    }


    function handleZoomIn() {
        if (zoom >= 4) return;
        setZoom(zoom * 2);
    }

    function handleZoomOut() {
        if (zoom <= 1) return;
        setZoom(zoom / 2);
    }

       
    function AnimateHopsOnTheMap() {
        
        var allHops = document.getElementsByClassName('rsm-lines');        
        var lines = allHops[1].getElementsByClassName('rsm-line');

        for (var i = 0; i < lines.length; i++) {
            var oneLine = lines[i];
            oneLine.setAttribute("class", "rsm-line");
        }

        for (var i = 0; i < lines.length; i++) {            
            var oneLine = lines[i];
            oneLine.setAttribute("class", "rsm-line HideAllHops");
        }

        (            
            function AnimateHops(i) {                            
                setTimeout(function () {                                                    
                    setTimeout(function () {                        
                        var oneLine = lines[i];                        
                        oneLine != undefined && oneLine.setAttribute("class", "rsm-line rsm-line-animation");
                }, 500);                  
                    if (--i) AnimateHops(i);                    
            }, 3000)
            })(lines.length);         
    }

    //function RemoveAllAnimateClassesFromHops()
    //{        
    //    var allHops = document.getElementsByClassName('rsm-lines');
        
    //    var lines = allHops[1].getElementsByClassName('rsm-line');

    //    for (var i = 0; i < lines.length; i++) {                        
    //        var oneLine = lines[i];
    //        oneLine.setAttribute("class", "rsm-line");
    //    }
    //}

    function SetDifferentColorForEachState(stateName) {
        if ((stateName == 'California' || stateName == 'Texas')) {
            return 'rgb(184, 214, 253)';
        }
        else if ((stateName == 'Minnesota' || stateName == 'Wisconsin' || stateName == 'Illinois' || stateName == 'Nebraska' || stateName == 'Michigan' || stateName == 'Florida' || stateName == 'West Virginia' || stateName == 'New York' || stateName == 'Maine')) {
            return 'rgb(191, 218, 253)';
        }
        else if ((stateName == 'Idaho' || stateName == 'Montana' || stateName == 'Wyoming')) {
            return 'rgb(212, 230, 253)';
        }
        else {
            return 'rgb(219, 234, 253)';
        }
    }

    return (
        <>
            {!lines.length && isFetching && <OverlaySpinner />}
            <Motion
                style={{
                    x: lastMoveWasAClick ? spring(center[0]) : center[0],
                    y: lastMoveWasAClick ? spring(center[1]) : center[1],
                    zoom: spring(zoom),
                }}
            >               
                {({ x, y, zoom }) => (
                    <>
                        <div className="Simpluris-map-controls-Play">
                            <a onClick={AnimateHopsOnTheMap}><img src={PlayIcon} /></a>
                        </div>
                        {isZoom &&
                            <div className="Simpluris-map-controls">
                                <button className="Simpluris-map-button" onClick={handleZoomIn}>
                                    <i class="i-con i-con-plus"><i></i></i>
                                </button>
                                <button className="Simpluris-map-button" onClick={handleZoomOut}>
                                    <i class="i-con i-con-minus"><i></i></i>
                                </button>                                
                            </div>
                        }
                        <ComposableMap
                            projection="albersUsa"
                            projectionConfig={{
                                scale: 1000,
                            }}
                            style={{
                                width: '100%',
                                minHeight: '100%',
                            }}
                        >
                            <ZoomableGroup center={[x, y]} zoom={zoom} onMoveEnd={setCenter}>
                                <Geographies geography={statesJSON} disableOptimization>
                                    {(geographies, projection) =>
                                        geographies.map((geography, i) => (
                                            <Geography
                                                cacheId={i}
                                                onMouseDown={(geo, e) =>
                                                    setMouseDownCoords({ x: e.clientX, y: e.clientY })
                                                }
                                                onMouseUp={(geo, e) =>
                                                    setMouseUpCoords({ x: e.clientX, y: e.clientY })
                                                }
                                                key={i}
                                                geography={geography}
                                                projection={projection}
                                                onClick={({ geometry: { coordinates } }) => {
                                                    if (lastMoveWasAClick) {
                                                        const allCoords = _.flattenDeep(coordinates)
                                                        setCenter(allCoords.slice(0, 2))
                                                        setZoom(2)
                                                    }
                                                }}
                                                style={{
                                                    default: {
                                                        fill: SetDifferentColorForEachState(geography.properties.NAME_1),
                                                        stroke: '#fff',
                                                        strokeWidth: 0.75,
                                                        outline: 'none',
                                                        cursor: 'pointer',
                                                    },
                                                    hover: {
                                                        fill: '#CFD8DC',
                                                        stroke: '#fff',
                                                        strokeWidth: 1,
                                                        outline: 'none',
                                                        cursor: 'pointer',
                                                    },
                                                    pressed: {
                                                        fill: '#FF5722',
                                                        stroke: '#fff',
                                                        strokeWidth: 1,
                                                        outline: 'none',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                            />
                                        ))
                                    }
                                </Geographies>
                                <Lines>
                                    {lines.map((line, i) => (
                                        <Line
                                            onClick={line => onLineClick(line)}
                                            preserveMarkerAspect={false}
                                            key={i}
                                            line={{
                                                ...line,
                                                curveStyle:
                                                    line.coordinates.end[1] < line.coordinates.start[1]
                                                        ? 'forceUp'
                                                        : 'forceDown',
                                            }}
                                            style={{
                                                default: {
                                                    stroke: '#137EFF',
                                                    strokeWidth: line.numMailItems >= 0 && line.numMailItems <= 10 ? 1 : line.numMailItems >= 11 && line.numMailItems <= 20 ? 2 : line.numMailItems >= 21 && line.numMailItems <= 50 ? 3 : line.numMailItems >= 51 ? 4 : 5,
                                                    fill: 'none',
                                                    cursor: 'pointer',
                                                },
                                                hover: {
                                                    stroke: 'red',
                                                    strokeWidth: line.numMailItems >= 0 && line.numMailItems <= 10 ? 1 : line.numMailItems >= 11 && line.numMailItems <= 20 ? 2 : line.numMailItems >= 21 && line.numMailItems <= 50 ? 3 : line.numMailItems >= 51 ? 4 : 5,
                                                    fill: 'none',
                                                    cursor: 'pointer',
                                                },
                                                pressed: {
                                                    stroke: 'black',
                                                    strokeWidth: line.numMailItems >= 0 && line.numMailItems <= 10 ? 1 : line.numMailItems >= 11 && line.numMailItems <= 20 ? 2 : line.numMailItems >= 21 && line.numMailItems <= 50 ? 3 : line.numMailItems >= 51 ? 4 : 5,
                                                    fill: 'none',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                            buildPath={buildCurves}
                                        />

                                    ))}
                                </Lines>
                                <Markers>
                                    {
                                        markers1.map((e, i) => (
                                            <Marker marker={{ coordinates: e.coordinates }}>
                                                <circle r={3} class="Simpluris-map-marker blue" />
                                            </Marker>
                                        ))
                                    }
                                </Markers>
                            </ZoomableGroup>
                        </ComposableMap>
                    </>
                )}                
            </Motion>
           
        </>
    )
}

export default connect((state, props) => ({
    isFetching: getHistoryFetching(state, props),
}))(USMap)