import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PageLoader from 'react/components/page-loader';
import ReactCrop from 'react-image-crop';
import { IsNullOrEmptyString } from '../../../global'
import { appMode } from '../../../api-config'
import StatusIconBar from '../../components/dashboard-header'
import 'react-image-crop/dist/ReactCrop.css';
import ButtonLoader from '../../components/button-loader';
import { simplurisFetch } from 'helpers'
import { setTimeout } from 'timers';

var imageExists = require('image-exists');


var imageRef;

function ChangeProfilePictureModalPopup(props) {    
    function CloseChangeProfilePictureModalPopup() {
        props.setShowChangeProfilePicutureRequestModalPopup(false);
        setFilePreview('');
        setError('');
        setShowButtonLoader(false);        
    }
    
    let [isURLExists, setIsURLExists] = useState(false);
    
    useEffect(() => {      
        if (!IsNullOrEmptyString(props.profilePictureValue)) {
            imageExists(props.profilePictureValue, function (exists) {
                if (exists) {
                    setIsURLExists(true);
                }
            });            
        }
    }, [Math.random()]);

    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [filePreview, setFilePreview] = useState('');
    const [uploadedfile, setUploadedfile] = useState('');
    const [error, setError] = useState('');
    const [currentProfileURL, setCurrentProfileURL] = useState(props.profilePictureValue);


    const [showModalPopUpForCroping, setShowModalPopUpForCroping] = useState(false);
    
    const [base64Image, setBase64Image] = useState('');

    const [src, setSrc] = useState('');
    const [croppedImageUrl, setCroppedImageUrl] = useState('');
    const [crop, setCrop] = useState({
        unit: '%',                
        width: 100,
        aspect: 1 / 1,
    });



    function CloseCropingModalPopup() {
        setShowModalPopUpForCroping(false);   
        
        imageRef = '';
        setSrc('');
        setCroppedImageUrl('');
         setCrop({
            unit: '%',
            width: 100,
            aspect: 1 / 1,
        });

    }


    const fileInput = React.createRef();
    const triggerInputFile = () => {
        fileInput.current.click();
    }


    const handleChange = (event) => {        
        if (event.target.files.length > 0) {            
            if (event.target.files[0].name.endsWith('.png') || event.target.files[0].name.endsWith('.jpg') || event.target.files[0].name.endsWith('.jpeg')) {                
                setShowModalPopUpForCroping(true);
                setError('');
                setCurrentProfileURL('');
                setFilePreview(URL.createObjectURL(event.target.files[0]));
                setUploadedfile(event.target.files[0]);                                                
                const reader = new FileReader();
                reader.addEventListener('load', () =>
                    setSrc(reader.result)
                );
                reader.readAsDataURL(event.target.files[0]);                
            }
            else {
                setError("Only image type jpg/png/jpeg is allowed")
            }
        }
    }

    const UploadProfilePic = () => {
        
        var formData = new FormData();
        formData.append('PersonID', props.personID);
        formData.append('file', uploadedfile);
        formData.append('CurrentProfileURL', currentProfileURL);
        formData.append('Base64Image', base64Image);
        setShowButtonLoader(true);
        
        simplurisFetch({
            url: 'auth/updatepersonprofilepicture',
            method: 'POST',
            body: formData,
            json: false,
        })
            .then(data => {
                setShowButtonLoader(false)
                
                localStorage.removeItem(global.ProfilePicURL)
                if (appMode == "sandbox") {
                    localStorage.setItem(
                        global.ProfilePicURL,
                        base64Image
                    )
                    props.setProfilePicURL(base64Image)
                }
                else
                {
                    localStorage.setItem(
                        global.ProfilePicURL,
                        data.resultObject.profilePicURL
                    )
                    props.setProfilePicURL(data.resultObject.profilePicURL)                
                }
                
                CloseChangeProfilePictureModalPopup()     
                props.ChangeProfilePicRandomState();
                CloseCropingModalPopup();
            })
            .catch(e => {
                console.error('Error in update person profile picture', e, e.errorObject)
                setShowButtonLoader(false)
            })
    }

    const OnCropComplete = crop => {                   
            MakeClientCrop(crop);          
    };

    const OnCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };

    var croppedImageUrls = '';
    const MakeClientCrop = (crop) => {
        setCroppedImageUrl('');               
            if (imageRef && crop.width && crop.height) {                
                setBase64Image('');                
                croppedImageUrls = GetCroppedImg(
                    imageRef,
                    crop,
                    'newFile.jpeg'
                );                
                setTimeout(function () {                    
                    croppedImageUrls.then(function (val) {
                        setCroppedImageUrl(val);
                    });

                }, 100)

            }       
    }

    const GetCroppedImg = (image, crop, fileName) => {        
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        
        //const base64Image = canvas.toDataURL('image/jpeg');
        setBase64Image(canvas.toDataURL('image/jpeg'));

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }                
                blob.name = fileName;
                let fileUrl = '';                
                 window.URL.revokeObjectURL(fileUrl);
                 fileUrl = window.URL.createObjectURL(blob);                
                resolve(fileUrl);                
            }, 'image/jpeg', 1);
        });

    }
    
    const OnImageLoaded = (image) => {               
        imageRef = image;
    };

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseChangeProfilePictureModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title> <h5 className="modal-title SimplurisBidRequestTitle SimplurisFontWeight400">Upload Profile Image</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="row">
                        <div className="col-xl-5 col-md-5 col-xs-12 col-sm-5 mb-3 mb-md-0 mb-lg-0 mb-sm-0 mb-lg-0 mb-xl-0">
                            {
                                !IsNullOrEmptyString(props.profilePictureValue) && isURLExists ?
                                    <div className="SimplurisTeamMemberProfilePicturePreview SimplurisheightInherit p-3 SimplurisOpactiyInherit">
                                        <img src={props.profilePictureValue} className="avatar w-100 h-100 position-relative" />
                                    </div> :
                                    <div className="SimplurisTeamMemberProfilePicturePreview p-3 SimplurisProfilePicturePreview">
                                        <div className="SimplurisTeamMemberProfilePicturePreviewCircle"></div>
                                    </div>
                            }
                            <div className="text-danger mt-2"> {error} </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-xs-12 col-sm-12 align-self-center">
                            <div className="modal-title SimplurisCaseBankTitle SimplurisFontWeight400 SimplurisFontSize21">Upload a profile picture.</div>
                            <input type="file" ref={fileInput} className="d-none" onChange={handleChange} />
                            <button type="button" onClick={triggerInputFile} className="btn rounded-0 text-white SimplurisDashbordDisbursementCard w-sm py-3 mt-2 SimplurisFontSize13 font-weight-500">Browse...</button>
                            {/* {
                                !IsNullOrEmptyString(filePreview) ? <button type="button" onClick={UploadProfilePic} disabled={showButtonLoader} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm btn-light py-3 mt-2 ml-3">
                                    {
                                        showButtonLoader && (
                                            <ButtonLoader />
                                        )
                                    }
                                    {showButtonLoader && <span></span>}
                                    {!showButtonLoader && <span>Save</span>}
                                </button> : null
                            }*/}
                            <small className="form-text text-muted">Use a square image, 300 x 300 png for best results</small>
                            <small className="form-text text-muted">Photo will be cropped outside of the circle.</small>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showModalPopUpForCroping} onHide={CloseCropingModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title> <h5 className="modal-title SimplurisBidRequestTitle SimplurisFontWeight400">Crop Profile Image</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="row">                          
                        <div className="col-xl-7 col-md-7 col-xs-12 col-sm-6 mb-3 mb-md-0 mb-lg-0 mb-sm-0 mb-lg-0 mb-xl-0">                            
                            <h5 className="mb-1 modal-title SimplurisBidRequestTitle SimplurisFontWeight400 text-center">Crop Image</h5>
                            {
                                !IsNullOrEmptyString(src) ?
                                    <div className="SimplurisOpactiyInherit">
                                        <ReactCrop src={src} crop={crop}
                                            ruleOfThirds                                            
                                            onComplete={OnCropComplete}
                                            onChange={OnCropChange}
                                            onImageLoaded={OnImageLoaded}
                                            className="w-100 h-100 position-relative" />
                                    </div> :
                                    <div className="SimplurisTeamMemberProfilePicturePreview p-3">
                                        <div className="SimplurisTeamMemberProfilePicturePreviewCircle"></div>
                                    </div>
                            }                            
                        </div>
                        <div className="col-xl-5 col-md-5 col-xs-12 col-sm-6 mb-3 mb-md-0 mb-lg-0 mb-sm-0 mb-lg-0 mb-xl-0">                            
                            <h5 className="mb-1 modal-title SimplurisBidRequestTitle SimplurisFontWeight400 text-center">Preview</h5>
                            {                                
                                croppedImageUrl ? (
                                    <div class="SimplurisTeamMemberProfilePicturePreview SimplurisHeight275 p-3 SimplurisOpactiyInherit d-flex justify-content-center align-self-center">
                                        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} className="avatar SimplurisHeightWidth100 position-relative m-auto" />
                                        </div>
                                ) : <div className="SimplurisCropingImagePreviewLoader"><ButtonLoader /></div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-xs-12 col-sm-12 align-self-center text-center mt-3">                            
                            {
                                !IsNullOrEmptyString(filePreview) ? <button type="button" onClick={UploadProfilePic} disabled={showButtonLoader} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm btn-light py-3 mt-2">
                                    {
                                        showButtonLoader && (
                                            <ButtonLoader />
                                        )
                                    }
                                    {showButtonLoader && <span></span>}
                                    {!showButtonLoader && <span>Save</span>}
                                </button> : null
                            }
                            <button type="button" onClick={CloseCropingModalPopup} className="btn rounded-0 text-white SimplurisDashbordDisbursementCard w-sm py-3 mt-2 ml-3 SimplurisFontSize13 font-weight-500">Cancel</button>
                            <small className="form-text text-muted">Use a square image, 300 x 300 png for best results</small>
                            <small className="form-text text-muted">Photo will be cropped outside of the circle.</small>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeProfilePictureModalPopup
