import * as React from 'react'

const FilterButton = ({ label, active, onClick, className }) => (
  <button
    className={`btn btn-simpluris-response-summary remove-bootstrap-persistent-button-border SimplurisFontSize12 font-weight-500 m-1 ${active ? 'active' : ''} ${className}`}
    onClick={onClick}
  >
    {label}
  </button>
)

export default FilterButton
