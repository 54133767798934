import { API_ROOT, allowPageLog } from './api-config';
import pusher from 'pusher-js';
import { func } from 'prop-types';

global.HOSTNAME = window && window.location && window.location.hostname;
global.API_URL = `${API_ROOT}`
global.EmailRegex = /(^\w.*@\w+\.\w)/
global.StrongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
global.AuthTokenKey = "AuthTokenKey"
global.AccessTokenKey = "AccessTokenKey"
global.FirstName = "FirstName"
global.LastName = "LastName"
global.ProfilePicURL = "ProfilePicURL"
global.PersonID = "PersonID"
global.Email = "Email"
global.PhoneNumber = "PhoneNumber"
global.PhoneNumber2 = "PhoneNumber2"
global.PersonFax = "PersonFax"
global.Title = "Title"
global.CompanyName = "CompanyName"
global.SchemaName = "SchemaName"
global.ExpirationDate = /^(0[1-9]|1[012])[- /.]\d\d$/
global.IsPositiveNumber = /^\+?(0|[1-9]\d*)$/
global.PersonMapID = "PersonMapID"
global.DboPersonID = "DboPersonID"
global.CaseRoleTitle = "CaseRoleTitle"
global.CaseID = "CaseID"
global.PusherAppId = "887203"
global.PusherKey = "e8698ba56d4f17e3f63f"
global.PusherSecret = "44d0b16f707da001ad09"
global.PusherCluster = "us3"
global.Messaging = "MESSAGING"
global.Typing = "TYPINGING"
global.LastAgreedToTerms = "LastAgreedToTerms"
global.viewDashBoardpermission = "viewDashBoardpermission"


global.TopTeamMemberPersonID = "TopTeamMemberPersonID"
global.TopMailingID = "TopMailingID"
global.TopCaseMemberPersonID = "TopCaseMemberPersonID"
global.TESTDRIVEURLCODE = "TESTDRIVEURLCODE"
global.IsSysAdmin = "IsSysAdmin"
global.ReportPDFURL = "ReportPDFURL"
global.CaseIDForReportPDF = "CaseIDForReportPDF"
global.ReportType = "ReportType"
global.OfficialCaseName = "OfficialCaseName"
global.ReportTitle = "ReportTitle"
global.ReportCreatedDate = "ReportCreatedDate"
global.SecureFileURL = "SecureFileURL"
global.CaseIDForSecureFile = "CaseIDForSecureFile"
global.SecureFileIDForSecureFile = "SecureFileIDForSecureFile"
global.SecureFileForCaseOfficialName = "SecureFileForCaseOfficialName"
global.SecureFileName = "SecureFileName"
global.SecureFileSentDate = "SecureFileSentDate"
global.caseOfficialName = "caseOfficialName"
global.PlatformPersonIDForReportUnsubscribe = "PlatformPersonIDForReportUnsubscribe"
global.ReportIDForReportUnsubscribe = "ReportIDForReportUnsubscribe"
global.CreatedReportPersonID = "CreatedReportPersonID"

export function IsNullOrEmptyString(e) {
    switch (e) {
        case "":
        case 0:
        case "0":
        case null:
        case false:
        case undefined:
        case typeof this == "undefined":
            return true;
        default:
            return false;
    }
}

global.Pusher = new pusher(global.PusherKey, {
    cluster: global.PusherCluster,
    forceTLS: true
});


export function RemoveDuplicateObject(arr, prop) {

    if (arr == undefined) {
        return [];
    }

    let obj = {};
    return Object.keys(arr.reduce((prev, next) => {
        if (!obj[next[prop]]) obj[next[prop]] = next;
        return obj;
    }, obj)).map((i) => obj[i]);
}

export function AnlyticsPageLog(message) {
    allowPageLog == true && window.analytics.page(message);
}

export function TrackAnalyticEvent(message, myPropertiesJSON) {
    allowPageLog == true && window.analytics.track(message, myPropertiesJSON);
}

export function FormatPhoneNumberForUS(phoneNumber)
{    
    //const match = phoneNumber != "" && phoneNumber != null ? phoneNumber.replace(/\D+/g, '').match(/([^\d]*\d[^\d]*){1,10}$/)[0] : null;
    
    //if (match != null)
    //{
    //    const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
    //    const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
    //    const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
    //    return `${part1}${part2}${part3}`
    //}
    //else
    //    return null    

    if(phoneNumber != undefined && phoneNumber != null)
    {
        var normalString = phoneNumber.replace(/\D/g, '');
        if (normalString != null && normalString != "")
        {
            var filterPhoneNumber = normalString.substring(0, 10);
            const part1 = filterPhoneNumber.length > 2 ? `(${filterPhoneNumber.substring(0, 3)})` : filterPhoneNumber;
            const part2 = filterPhoneNumber.length > 3 ? ` ${filterPhoneNumber.substring(3, 6)}` : '';
            const part3 = filterPhoneNumber.length > 6 ? `-${filterPhoneNumber.substring(6, 10)}` : '';
            return `${part1}${part2}${part3}`
        }
        else
        {
            return null
        }
    }
    else
    {
            return null
    }
}

export function FormatFaxNumberForUS(faxNumber) {
    if(faxNumber != undefined && faxNumber != null)
    {
        var normalString = faxNumber.replace(/\D/g, '');
        if (normalString != null && normalString != "") {
            var filterFaxNumber = normalString.substring(0, 15);
            const part1 = filterFaxNumber.length > 2 ? `(${filterFaxNumber.substring(0, 3)})` : filterFaxNumber;
            const part2 = filterFaxNumber.length > 3 ? ` ${filterFaxNumber.substring(3, 6)}` : '';
            const part3 = filterFaxNumber.length > 6 ? `-${filterFaxNumber.substring(6, 15)}` : '';
            return `${part1}${part2}${part3}`
        }
        else
            return null;
    }
    else
            return null;
}