import React, { Component, useState, useEffect } from 'react';
import Header from '../../components/outer-header'
import BrandmarkLogo from '../../../static/img/simpluris-logo-brandmark.png'
import { Link } from 'react-router-dom';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';
import PageLoader from '../../components/page-loader';
import { simplurisFetch } from 'helpers'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../global';
import { appMode, allowPageLog } from '../../../api-config'

function RequestAccessPage() {

    const [showStep1, setShowStep1] = useState(true);
    const [showStep2, setShowStep2] = useState(false);
    const [email, setEmail] = useState('');
    const [emailReqError, setEmailReqError] = useState(false);
    const [emailInvalidError, setEmailInvalidError] = useState(false);
    const [pwd, setPwd] = useState('');
    const [pwdError, setPwdError] = useState(false);
    const [strongPasswordError, setStrongPasswordError] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [firstNameReqError, setFirstNameReqError] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameReqError, setLastNameReqError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberReqError, setPhoneNumberReqError] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        AnlyticsPageLog("RequestAccess page");
    }, [])

    const RequestAccessStep1Click = () => {
        if (allowPageLog == true) {
            window.analytics.track("LeadCaptured",
                { Label: "LiveCase" }
            );
        }
        setErrorMessage("");
        setEmail(email);
        setPwd(pwd);
        setFirstName(firstName);
        setLastName(lastName);
        setPhoneNumber(phoneNumber);

        const isValidEmail = global.EmailRegex.test(email)
        const isStrongPassword = global.StrongPasswordRegex.test(pwd)
        //! covers all possible nulls/empty strings
        if (!email || !pwd || !isValidEmail || !isStrongPassword || !firstName || !lastName || !phoneNumber) {

            if (!email) {
                setEmailReqError(true);
            }

            if (email && !isValidEmail) {
                setEmailInvalidError(true);
            }

            if (!pwd) {
                setPwdError(true);
            }

            if (pwd && !isStrongPassword) {
                setStrongPasswordError(true);
            }

            if (!firstName) {
                setFirstNameReqError(true);
            }

            if (!lastName) {
                setLastNameReqError(true);
            }

            if (!phoneNumber) {
                setPhoneNumberReqError(true);
            }
            return;
        }

        var data = {};
        data.email = email;
        data.Password = pwd;
        data.firstName = firstName;
        data.LastName = lastName;
        data.PhoneNumber = phoneNumber;
        data.isRequestAccess = true;
        data.isRequestAccessTestDrive = false;
        data.AccountRequiresApproval = true;
        data.SourceLink = window.location.href;

        setShowPageLoader(true);

        simplurisFetch({
            url: "requestaccess", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {
            setShowPageLoader(false);
            if (data.name != undefined && data.name == "FetchError") {
                if (data.errorObject.resultCode == "ERROR") {


                    if (!IsNullOrEmptyString(data.errorObject.resultMessage)) {
                        setErrorMessage(data.errorObject.resultMessage);
                    }
                    else {
                        setErrorMessage("Something went wrong.");
                    }
                }
                else if (data.errorObject.resultCode == "EXISTS") {
                    setErrorMessage(data.errorObject.resultMessage);
                }
            }
            else {
                setErrorMessage("");
                setShowStep1(false);
                setShowStep2(true);
            }
        }).catch(e => {
            console.error('Error in sing up step1', e, e.errorObject);
            setShowPageLoader(false);
            if (e.errorObject.resultCode == "ERROR") {

                if (e.errorObject.resultMessage) {
                    setErrorMessage(e.errorObject.resultMessage);
                }
                else {
                    setErrorMessage("Something went wrong.");
                }

                //setErrorMessage("Something went wrong.");
            }
            else if (e.errorObject.resultCode == "EXISTS") {
                setErrorMessage(e.errorObject.resultMessage);
            }
        });
    }

    const KeyPressed = (event) => {
        if (event.key === "Enter") {
            RequestAccessStep1Click()
        }
    }

    return (

        <div>
            <div className="layout-column bg-white">
                <Header page="request-access" />
                <div className="d-flex flex-column flex">
                    <div>
                        <div className="p-3">
                            <div className="card p-0 col-lg-8 offset-lg-2 shadow-none border-0">
                                <div className="row no-gutters">
                                    <div className="col-md-5 r-l" >
                                        <div className="p-4 d-flex flex-column">
                                            <div className="d-block">
                                                <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right">
                                                    <img src={BrandmarkLogo} className="w-50" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            appMode == "sandbox" &&
                                            <div className="p-4 d-flex flex-column">
                                                <div className="d-block">
                                                    <div className="animate fadeIn text-center text-lg-right text-md-right text-xl-right">
                                                        <div>
                                                            <span className="SimplurisFontWeight400 SimplurisFontSize17 text-dark"> You're Requesting Access to the SandBox </span><br />
                                                            <span className="SimplurisFontSize13"> Access the full version.</span><br />
                                                            <span className="SimplurisFontSize13"> of LiveCase
                                                                 <Link to={"/sign-in"}> here</Link>
                                                            </span>.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    {showStep1 ?
                                        <div className="col-md-6" id="step1-body">
                                            <div className="p-4">
                                                <h4 className="SimplurisFontWeight400 SimplurisSignupStartedLableW-100"> Request Access to LiveCase</h4>
                                                <p>
                                                    <label className="text-dark">
                                                        Track your case statistics, disbursements, call-centers, and every detail with LiveCase. <br />Already have an account?

                                                        {appMode == "sandbox" ? <Link to={"/login"}> Log in</Link> : <Link to={"/sign-in"}> Log in</Link>}
                                                    </label>
                                                </p>
                                                {ErrorMessage != "" ? <ErrorPlacement items={ErrorMessage} /> : ''}
                                                <form className="" role="form" action="dashboard.html">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className={`form-group mt-2 ${firstNameReqError ? 'has-error' : ''}`}>
                                                                <label>First Name</label>
                                                                <div className="position-relative">
                                                                    <input type="text" name="txtFirstName" id="txtFirstName" className="form-control SimplurisNewCaseMemebersInputBox" value={firstName} onKeyPress={KeyPressed} onChange={e => (setFirstName(e.target.value), setFirstNameReqError(false))} />
                                                                    {firstNameReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                                    {firstNameReqError ? <span>Please enter firstname</span> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className={`form-group mt-2 ${lastNameReqError ? 'has-error' : ''}`}>
                                                                <label>Last Name</label>
                                                                <div className="position-relative">
                                                                    <input type="text" name="txtLastName" id="txtLastName" className="form-control SimplurisNewCaseMemebersInputBox" value={lastName} onKeyPress={KeyPressed} onChange={e => (setLastName(e.target.value), setLastNameReqError(false))} />
                                                                    {lastNameReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                                    {lastNameReqError ? <span>Please enter lastname</span> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`form-group mt-2 ${emailReqError || emailInvalidError ? 'has-error' : ''}`}>
                                                        <label>Email Address</label>
                                                        <div className="position-relative">
                                                            <input type="email" className="form-control SimplurisNewCaseMemebersInputBox" value={email} onKeyPress={KeyPressed} onChange={e => (setEmail(e.target.value), setEmailReqError(false), setEmailInvalidError(false))} />
                                                            {emailReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                            {emailReqError ? <span>Please enter email</span> : null}
                                                            {emailInvalidError ? <span>Please enter valid email</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className={`form-group mt-2 ${pwdError || strongPasswordError ? 'has-error' : ''}`}>
                                                        <label>Password</label>
                                                        <div className="position-relative">
                                                            <input type="password" className="form-control SimplurisNewCaseMemebersInputBox" value={pwd} onKeyPress={KeyPressed} onChange={e => (setPwd(e.target.value), setPwdError(false), setStrongPasswordError(false))} />
                                                            {pwdError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                            {pwdError ? <span>Please enter password</span> : null}
                                                            {strongPasswordError ? <span> Password does not meet security requirements </span> : null}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div className="col-12 col-sm-6">
                                                            <p className="mb-0">
                                                                <small className="text-muted">
                                                                    One lowercase character <br />One uppercase character <br />One number
                                                </small>
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <p className="mb-0">
                                                                <small className="text-muted">
                                                                    One special character <br /> 8 characters minimum
                                                </small>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className={`form-group mt-2 ${phoneNumberReqError ? 'has-error' : ''}`}>
                                                        <label>Phone Number</label>
                                                        <div className="position-relative">
                                                            <Cleave className="form-control SimplurisNewCaseMemebersInputBox" options={{ delimiter: '-', phone: true, phoneRegionCode: 'US', blocks: [3, 3, 4], uppercase: true }}
                                                                onChange={e => (setPhoneNumber(e.target.value), setPhoneNumberReqError(false))} value={phoneNumber} onKeyPress={KeyPressed} name="txtPhoneNumber" maxLength="15" id="txtPhoneNumber" />
                                                            {phoneNumberReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                                            {phoneNumberReqError ? <span>Please enter phonenumber</span> : null}
                                                        </div>
                                                    </div>

                                                    <button type="button" onClick={RequestAccessStep1Click} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor mb-1 btn-light py-3 px-4">Request Access</button>
                                                </form>
                                            </div>
                                        </div>
                                        : null}
                                    {showStep2 ?
                                        <div className="col-md-5 mt-0 mt-md-4 mt-sm-4 mt-xl-4 mt-lg-4" id="step2-body">
                                            <div className="p-4">
                                                <h4>
                                                    Thank you. We will review and approve your request within the next business day.
                                </h4>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPageLoader ? <PageLoader /> : null}
            </div>
        </div>
    )
}

export default RequestAccessPage;

class ErrorPlacement extends Component {
    render() {
        if (this.props.items != "") {
            return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
                {this.props.items}
            </div>
        }
    }
}