import
{
    SELECT_MEMBER,
    SELECT_MEMBER_DETAILS_PERMISSION,
    SELECT_RESPONSES_DETAIL,
    SELECT_PAYMENTS_DETAIL,
    SELECT_MAILING_HISTORY_DETAILS_FROM_FULLMAPSCREEN
} from './actions'
import { FETCH_TEAM_MEMBER_DETAIL} from '../../team-member-detail/actions'
import { start, resolved } from 'state/helpers'
const initialState = {
    selectedMemberID: null,
    membersPermission: null,
    responsesDetail: null,
    fetchingTeamMemberDetailFalge: false,
}

export default (state = initialState, { type, selectedMemberID, membersPermission, responsesDetail, paymentsDetail, mailingHistoryDetail }) => {    
    switch (type) {
        case SELECT_MEMBER:
            return { ...state, selectedMemberID }
        case SELECT_MEMBER_DETAILS_PERMISSION:
            return { ...state, membersPermission }
        case SELECT_RESPONSES_DETAIL:
            return { ...state, responsesDetail }
        case SELECT_PAYMENTS_DETAIL:
            return { ...state, paymentsDetail }
        case start(FETCH_TEAM_MEMBER_DETAIL):            
            return { ...state, fetchingTeamMemberDetailFalge: true }
        case resolved(FETCH_TEAM_MEMBER_DETAIL):            
            return { ...state, fetchingTeamMemberDetailFalge: false }
        default:
            return state
    }
}