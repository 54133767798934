import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { FeatchAllFavoriteCases } from 'state/cases/favorite-cases/actions'

const Wrapper = styled.div`
  padding: 30px 20px;
`
const Title = styled.div`
  margin: 15px 0;
`
const LinkWrapper = styled.div`
  margin: 10px 0;
`
const StyledLink = styled(Link)`
  color: #98a5b0;
  font-size: 14px;
  font-weight: 400;
`


function AllFavortiteCases({ FeatchAllFavoriteCases, favoriteCases }) {    

    useEffect(() => {                
        FeatchAllFavoriteCases()              
    }, [])

    return (
        <>
            {
                favoriteCases.length > 0  ? (
                    <Wrapper>
                        <Title>Favorites</Title>
                        {favoriteCases.map(({ officialName, caseID }) => (
                            <LinkWrapper key={caseID}>
                                <StyledLink to={`/cases/detail/${caseID}`}>{_.truncate(officialName)}</StyledLink>
                            </LinkWrapper>
                        ))}
                    </Wrapper>
                ) : null
        }
            
            </>
        )
}

export default connect((state, props) => ({ 
    favoriteCases: state.cases.favoriteCases,
}), {
        FeatchAllFavoriteCases
    })(AllFavortiteCases)
