import { FETCH_CASE_RESPONSES, CHANGE_CASE_RESPONSE_DATE_RANGE, CHANGE_CASE_RESPONSE_FILTERS } from './actions'

const initialState = {
    dateRange: {
        start: null,
        end: null,
    },
    filters: {}
}

export default (state = initialState, { type, responses, caseID, totalRecords, dateRange, filters }) => {
    switch (type) {
        case FETCH_CASE_RESPONSES:
            
            const stateToUpdate = state[caseID] || {}
            const responsesById = responses.reduce((responsesByID, response) => {
                responsesByID[response.responseID] = response
                return responsesByID
            }, {})

            return { 
                ...state, 
                [caseID]: {
                    ...stateToUpdate,
                    responses: {
                        ...stateToUpdate.responses,
                        ...responsesById,
                    },
                    totalRecords,
                },
            }
        case CHANGE_CASE_RESPONSE_DATE_RANGE:
            return { ...state, dateRange }
        case CHANGE_CASE_RESPONSE_FILTERS:
            return { ...state, filters }
        default:
            return state
    }
}
