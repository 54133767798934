import { simplurisFetch } from 'helpers'
import { start, resolved } from 'state/helpers'
export const FETCH_CASE_CALL_RELEASE = 'FETCH_CASE_CALL_RELEASE'
export const CHANGE_CASE_CALL_RELEASE_DATE_RANGE = 'CHANGE_CASE_CALL_RELEASE_DATE_RANGE'
export const FETCH_ALL_RELEASE_CALLS_FOR_ADMIN = 'FETCH_ALL_RELEASE_CALLS_FOR_ADMIN'

export const fetchCallCenterDataForAdmin = (
    pageIndex,
    pageSize,    
    searchValue,
    startDate,
    endDate,
    sortColumn,
    sortColumnDir
) => async dispatch => {
    try {
        dispatch({ type: start(FETCH_CASE_CALL_RELEASE) })
        searchValue = searchValue == undefined ? null : searchValue
        startDate = startDate == undefined || startDate == "" ? null : startDate
        endDate = endDate == undefined || endDate == "" ? null : endDate

        
        const url = `cases/getallreleasecallsforadmin/${pageIndex}/${pageSize}/${searchValue}/${startDate}/${endDate}/${sortColumn}/${sortColumnDir}`
        const { resultObject, resultObjectRecordCount} = await simplurisFetch({ url })

        
        dispatch({
            type: resolved(FETCH_CASE_CALL_RELEASE),            
            releaseCalls: {
                releaseCalls: resultObject,
                pageIndex: pageIndex,
                pageSize: pageSize,
                searchValue: searchValue,
                startDate: startDate,
                endDate: endDate,
                sortColumn: sortColumn,
                sortColumndir:sortColumnDir,
                totalCount: resultObjectRecordCount
            }           
        })

        
    } catch (e) {
        console.error('Error in get all release calls for admin', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_CASE_CALL_RELEASE),            
            releaseCalls: []            
        })
    }
}
