import React, { Component } from 'react';

class SecureSideBarPage extends Component {

    render() {
        return (
            <div id="aside" className="page-sidenav no-shrink  nav-expand   SimplurisCasePagesSideBarDiv" aria-hidden="true">
                <div className="sidenav h-100 modal-dialog SimplurisDashboardBackgroudColour position-relative">
                    <a href="#" className="SimplurisCloseMenu"> <i className="i-con i-con-close position-absolute d-block d-lg-none d-xl-none SimplurisCloseIconSideBar"></i> </a>
                    <div className="flex scrollable hover">
                        <div className="nav-border b-primary SimplurisCaseBankSidebarMenu" data-nav>
                            <ul className="nav bg SimplurisDashboardBackgroudColour pt-5">
                                <li className="nav-header hidden-folded pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span className="SimplurisSideBarMainTitleLink">Lorem Ipsum vs. Dolor sit</span>
                                    </a>                                    
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Summary</span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Milestones</span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4 active">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Bank Reconciliation</span>
                                        <span className="SimplurisSidebarInfo"><i className="mr-2 i-con i-con-ok"></i></span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Members</span>
                                        <span className="SimplurisSidebarInfo"></span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">                                    
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Mail Tracking</span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Mail Automation</span>
                                        <span className="SimplurisSidebarInfo SimplurisAddLable">Add</span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Team</span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Call Center</span>
                                        <span className="SimplurisSidebarInfo"><i className="mr-2 i-con i-con-ok"></i></span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Case Sites</span>
                                        <span className="SimplurisSidebarInfo"><i className="mr-2 i-con i-con-ok"></i></span>
                                    </a>
                                </li>
                                <li className="nav-header hidden-folded pt-3 pl-4">
                                    <a href={null} className="SimplurisCasesBankSideBar p-0">
                                        <span>Data Scrubbing</span>
                                        <span className="SimplurisSidebarInfo SimplurisAddLable">ADD</span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SecureSideBarPage;