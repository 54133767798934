import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import ButtonLoader from '../../../components/button-loader';
import ActivityDetail from '../../../components/case-activity'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import GlobalImage from '../../../components/global-image-set';
import { SectionSpinner } from 'react/components/spinners'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { labFeatures, appMode } from '../../../../api-config'
import { simplurisFetch } from 'helpers'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { fetchThreads } from 'state/message-threads/actions';
import { fetchTeamMemberDetail } from 'state/team-member-detail/actions'
import { FormatPhoneNumberForUS, FormatFaxNumberForUS } from '../../../../global'

const _pageSize = 10;

function CaseTeamMemberDetail({
    fetchTeamMemberDetail,
    fetchingTeamMemberDetailFalge,
    lastCaseId,
    isHasNewData,
    teamMemberDetails,
    userPermissions,
    changeCaseSubtab,
    previousCaseSubtabs,
    setPreviousCaseSubtab,
    fetchThreads,    
    ...data
}) {
    
    var viewTeamMemberPermission;
    var callTeamMemberPermission;
    var messageTeamMemberPermission;
    
    if (Object.keys(userPermissions).length > 0) {
        viewTeamMemberPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_TEAM_VIEW_TEAM_MEMBERS'
        )
        callTeamMemberPermission = userPermissions.find(
            (permissionCode) => permissionCode == 'LC_TEAM_CALL_TEAM_MEMBER'
        )

        messageTeamMemberPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_TEAM_MESSAGE_TEAM_MEMBER'
        )
    }
    
    const [openNewMessage, setOpenNewMessage] = useState(false);
    const [firstName, setFirstName] = useState();
    const [profileImage, setProfileImage] = useState();
    const [personMapID, setPersonMapID] = useState();
    const OpenNewMessage = (check, firstName, profileImage, personMapID) => {
        setOpenNewMessage(check);
        setFirstName(firstName);
        setProfileImage(profileImage);
        setPersonMapID(personMapID);

        var senderPlatformMapId = localStorage.getItem(global.PersonMapID);
        var recipientPlatformMapId = personMapID;
        var caseID = data.caseID;
        fetchThreads({ senderPlatformMapId, recipientPlatformMapId, caseID })
    };    
    
    //const [teamMemberDetail, setTeamMemberDetail] = useState(teamMemberDetails.TeamMemeberDetails);
    const [showPageLoader, setShowPageLoader] = useState(false);
    //const [lastActivitesID, setlastActivitesID] = useState(0);
    //const [activitiesCount, setactivitesCount] = useState(0);
    const [showButtonLoader, setShowButtonLoader] = useState(false);

    //const fetchTeamMemberDetail = async (caseID, personID, lastActivityID) => {    
    //  simplurisFetch({
    //    url: `cases/${parseInt(
    //      caseID
    //    )}/teammembers/${personID}/${lastActivityID}/${_pageSize}`
    //  })
    //    .then(result => {
    //      setShowPageLoader(false);
    //      setShowButtonLoader(false);
    //        if (result.data.resultCode !== "ERROR") {

    //        setlastActivitesID(
    //          result.data.resultObject.activityEntries != null
    //            ? result.data.resultObject.activityEntries[
    //                result.data.resultObject.activityEntries.length - 1
    //              ].caseActivityID
    //            : 0
    //        );
    //        setactivitesCount(
    //          result.data.resultObject.activityEntries != null
    //            ? result.data.resultObject.activityEntries.length
    //            : 0
    //        );

    //        if (
    //          result.data.resultObject.activityEntries != null &&
    //          result.data.resultObject.activityEntries.length <= _pageSize
    //        ) {

    //          if (Object.keys(teamMemberDetail).length) {
    //            result.data.resultObject.activityEntries = teamMemberDetail.activityEntries.concat(
    //              result.data.resultObject.activityEntries
    //            );
    //            setTeamMemberDetail(result.data.resultObject);
    //          } else {
    //            result.data.resultObject.activityEntries =
    //              result.data.resultObject.activityEntries;
    //            setTeamMemberDetail(result.data.resultObject);
    //          }
    //        } else {

    //            if (Object.keys(teamMemberDetail).length) {

    //            result.data.resultObject.activityEntries =
    //              result.data.resultObject.activityEntries != null
    //                ? teamMemberDetail.activityEntries.concat(
    //                    result.data.resultObject.activityEntries.slice(0, -1)
    //                  )
    //                : null;
    //            setTeamMemberDetail(result.data.resultObject);
    //          } else {

    //            result.data.resultObject.activityEntries =
    //              result.data.resultObject.activityEntries != null
    //                ? result.data.resultObject.activityEntries.slice(0, -1)
    //                : null;
    //            setTeamMemberDetail(result.data.resultObject);
    //          }
    //        }
    //      }
    //    })
    //    .catch(e => {
    //      console.error("Error in team members", e, e.errorObject);
    //      setShowPageLoader(false);
    //      setShowButtonLoader(false);
    //    });
    //};
    
    useEffect(() => {        
        let caseID = data.caseID;
        let personID = data.personID;
        let pageSize = _pageSize;
        let lastActivityID = 0;
        fetchTeamMemberDetail({ caseID, personID, lastActivityID, pageSize });
    }, [data.caseID, data.personID]);

    const LoadMoreActivites = () => {    
        let caseID = data.caseID;
        let personID = data.personID;
        let pageSize = _pageSize;
        let lastActivityID = lastCaseId;
        fetchTeamMemberDetail({ caseID, personID, lastActivityID, pageSize});
    };

    
    let teamMemberDetail;
    if (Object.keys(teamMemberDetails).length > 0) {
        
        if (teamMemberDetails[data.personID] != undefined) {
            
            teamMemberDetail = teamMemberDetails[data.personID].memberDetails 
        }
    }
   
    function columnClassNameFormat() {
        return "td-column";
    }
    
    if (viewTeamMemberPermission && viewTeamMemberPermission.length <= 0) {
        // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
        changeCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
        setPreviousCaseSubtab(previousCaseSubtabs[previousCaseSubtabs.length - 2]);
    }
    
    if (teamMemberDetail != undefined && teamMemberDetail.personId !== undefined) {        
        let fullName = teamMemberDetail.firstName + " " + teamMemberDetail.lastName;
        return (
            <div>
                <div className="card">
                    <div className="b-t" id="feed-1">
                        {showPageLoader ? (
                            <div className="row"> <SectionSpinner /></div>
                        ) : (
                                <>
                                    <div className="row">
                                        <div className="col-md-6 col-xs-12 col-sm-12">
                                            <div className="d-flex p-4">
                                                <a href="#">
                                                    <GlobalImage
                                                        isSandbox={appMode == "sandbox" ? "true" : "false"}
                                                        firstname={fullName}
                                                        image={teamMemberDetail.profilePicURL}
                                                        email={teamMemberDetail.personEmail}
                                                        isTeam="true"
                                                        dynamicClass="w-90 avatar SimplurisCaseTeamDetailAvatar circle Simpluris-bg-gray-lt"
                                                    />
                                                </a>

                                                <div className="my-auto mx-4">
                                                    <div className="SimplurisFontSize19 font-weight-500 SimplurisSearchUniversalResult">
                                                        {teamMemberDetail.firstName}{" "}
                                                        {teamMemberDetail.lastName}
                                                    </div>
                                                    <div className="text-muted SimplurisFontSize14 SimplurisFontWeight400">
                                                        {teamMemberDetail.roleTitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12 col-sm-12">
                                            <div className="d-flex h-100">
                                                {labFeatures && (
                                                    <div className="my-auto mx-auto">
                                                        <input
                                                            type="button"
                                                            className={`btn w-sm py-2 mb-2 mb-sm-0 mb-xl-0 mb-lg-0 mb-md-0 SimplurisTeamDetailsButtons SimplurisFontSize14 SimplurisFontWeight700 SimplurisTextLighPrimary ${
                                                                callTeamMemberPermission &&
                                                                    callTeamMemberPermission.length > 0
                                                                    ? ""
                                                                    : "d-none"
                                                                }`}
                                                            value="Call"
                                                            name="btnCall"
                                                            id="btnCall"
                                                        />
                                                        <input
                                                            type="button"
                                                            onClick={() =>
                                                                OpenNewMessage(
                                                                    true,
                                                                    fullName,
                                                                    teamMemberDetail.profilePicURL,
                                                                    teamMemberDetail.personMapID
                                                                )
                                                            }
                                                            className={`btn w-sm py-2 mt-0 ml-1 mt-md-2 mt-lg-2 mt-xl-0 ml-lg-1 mb-2 mb-sm-0 mb-xl-0 mb-lg-0 mb-md-0 SimplurisTeamDetailsButtons SimplurisFontSize14 SimplurisFontWeight700 SimplurisTextLighPrimary ${
                                                                messageTeamMemberPermission &&
                                                                    messageTeamMemberPermission.length > 0
                                                                    ? ""
                                                                    : "d-none"
                                                                }`}
                                                            value="Message"
                                                            name="btnMessage"
                                                            id="btnMessage"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="p-3">
                                        <ul className="nav" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active SimplurisFontSize12 SimplurisFontWeight400"
                                                    id="activity-tab"
                                                    data-toggle="tab"
                                                    href="#activity"
                                                    role="tab"
                                                    aria-controls="activity"
                                                    aria-selected="true"
                                                >
                                                    Activity
                      </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link SimplurisFontSize12 SimplurisFontWeight400"
                                                    id="contactinfo-tab"
                                                    data-toggle="tab"
                                                    href="#contactinfo"
                                                    role="tab"
                                                    aria-controls="contactinfo"
                                                    aria-selected="false"
                                                >
                                                    Contact Info
                      </a>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}
                    </div>
                </div>
                <div className="tab-content">
                    <div
                        className="tab-pane fade show active"
                        id="activity"
                        role="tabpanel"
                        aria-labelledby="activity-tab"
                    >
                        {(() => {
                            if (
                                teamMemberDetail.activityEntries !== null &&
                                teamMemberDetail.activityEntries !== undefined
                            ) {
                                return teamMemberDetail.activityEntries.map(activity => (
                                    <ActivityDetail data={activity} parentScreen="CaseTeamMemberDetails" teamMemberDetailPersonID={data.personID} />
                                ));
                            } else {
                                return (
                                    <div className="text-center">
                                        Activities not found for this case member
                                    </div>
                                );
                            }
                        })()}

                        <div className="col-md-12 mt-2 text-center">
                            {isHasNewData ? (
                                <button
                                    className="btn w-md mb-4 mb-md-1 btn-light SimplurisDashbordPostText p-3"
                                    onClick={LoadMoreActivites}
                                    disabled={fetchingTeamMemberDetailFalge}>
                                    {
                                        fetchingTeamMemberDetailFalge && (
                                            <ButtonLoader />
                                        )
                                    }
                                    {fetchingTeamMemberDetailFalge && <span></span>}
                                    {!fetchingTeamMemberDetailFalge && <span>Load More</span>}
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="contactinfo"
                        role="tabpanel"
                        aria-labelledby="contactinfo-tab"
                    >
                        <div className="card SimplurisDashboardCard">
                            <div className="p-1">
                                <div className="px-4 py-4">
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <small className="text-muted SimplurisFontWeight500">
                                                Phone Number
                      </small>
                                            <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                {teamMemberDetail.phoneNumber1 !== null &&
                                                    teamMemberDetail.phoneNumber1 !== "" && teamMemberDetail.phoneNumber1 !== undefined
                                                    ? FormatPhoneNumberForUS(teamMemberDetail.phoneNumber1)
                                                    : "N/A"}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <small className="text-muted SimplurisFontWeight500">
                                                Person Fax
                      </small>
                                            <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                {teamMemberDetail.personFax !== null &&
                                                    teamMemberDetail.personFax !== "" && teamMemberDetail.personFax !== undefined
                                                    ? FormatFaxNumberForUS(teamMemberDetail.personFax)
                                                    : "N/A"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <small className="text-muted SimplurisFontWeight500">
                                                Email
                      </small>
                                            <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                {teamMemberDetail.personEmail !== null &&
                                                    teamMemberDetail.personEmail !== "" && teamMemberDetail.personEmail !== undefined
                                                    ? teamMemberDetail.personEmail
                                                    : "N/A"}
                                            </div>
                                        </div>
                                        <div className="col-6 d-none">
                                            <small className="text-muted SimplurisFontWeight500">
                                                Second Email
                      </small>
                                            <div className="my-1 SimplurisFontWeight500 SimplurisFontSize15">
                                                {teamMemberDetail.SecondEmail !== null &&
                                                    teamMemberDetail.SecondEmail !== "" && teamMemberDetail.SecondEmail !== undefined
                                                    ? teamMemberDetail.SecondEmail
                                                    : "N/A"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-none">
                                        {teamMemberDetail.addresses != null ? (
                                            <BootstrapTable
                                                data={teamMemberDetail.addresses}
                                                hover
                                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover"
                                                bodyContainerClass="text-grid-td"
                                            >
                                                <TableHeaderColumn
                                                    dataField="personId"
                                                    isKey
                                                    hidden
                                                    searchable={false}
                                                >
                                                    Person ID
                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="address1"
                                                    columnClassName={columnClassNameFormat}
                                                    dataSort
                                                >
                                                    Address
                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="city"
                                                    columnClassName={columnClassNameFormat}
                                                    searchable={false}
                                                >
                                                    City
                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="zip"
                                                    columnClassName={columnClassNameFormat}
                                                    searchable={false}
                                                >
                                                    Zip
                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="state"
                                                    columnClassName={columnClassNameFormat}
                                                    searchable={false}
                                                >
                                                    State
                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="country"
                                                    columnClassName={columnClassNameFormat}
                                                    searchable={false}
                                                >
                                                    Country
                        </TableHeaderColumn>
                                            </BootstrapTable>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="SimplurisCaseDetailsLoaderSection">
                {fetchingTeamMemberDetailFalge? (
                    <div className="row"> <SectionSpinner /></div>
                ) : (
                        <div className="d-flex flex row">
                            <div className="col-md-12">
                                <div className="card d-flex flex">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="text-center mb-2">
                                                    No team member details available
                      </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default connect((state, props) => ({
    teamMemberDetails: state.teamMemberDetailScreen,
    lastCaseId: state.teamMemberDetailScreen.lastCaseId,
    isHasNewData: state.teamMemberDetailScreen.isHasNewData,
    fetchingTeamMemberDetailFalge: state.caseDetailView.members.fetchingTeamMemberDetailFalge,
    userPermissions: getPermissionsForCase(state, props),
    previousCaseSubtabs: state.previousSubTab.subTabNames,    
    threads: state.threads,
}),
    { changeCaseSubtab, setPreviousCaseSubtab, fetchThreads, fetchTeamMemberDetail }
)(CaseTeamMemberDetail)
