import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ChatBox from './index'


function ChatSection({ threads, caseManagerName, caseManagerPersonMapId }) {
    
    const [, updateChatSectionState] = React.useState();

    const RefreshChatSection = () => {
        updateChatSectionState({})
    }
        
    if (Object.keys(threads).length > 0) {
        return (
            <div className={"aside-deck "}>
                {
                    Object.keys(threads).map((item, i) => {
                        return (<>
                            <ChatBox item={threads[item]}
                                random={Math.random()}
                                RefreshChatSection={RefreshChatSection}
                                openNewMessage={Object.keys(threads).length > 0 ? true : false}
                                isCaseManager={caseManagerName != '' || caseManagerName != null ? true : false}
                                caseManagerName={caseManagerName}
                                caseManagerPersonMapId={caseManagerPersonMapId} />
                        </>)
                    })
                }
            </div>
        )
    } else {
        return <></>
    }
}

export default connect(
    (state, props) => ({
        threads: state.threads,
    })
)(ChatSection)
