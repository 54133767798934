import { FETCH_EXCEPTIONLOG, FETCH_APPLOG} from './actions'
import { resolved } from 'state/helpers'

const initialState = {  
}

export default (state = initialState, { type, adminExceptionLogObject, adminAppLogObject }) => {
    switch (type) {        
        case resolved(FETCH_EXCEPTIONLOG):                         
            return {
                ...state,                  
                adminExceptionLogObject,
            }        
        case resolved(FETCH_APPLOG):
            return {
                ...state,
                adminAppLogObject,
            }        
        default:
            return state
    }
}
