import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Lock from '../../../static/img/lock.png'
import ButtonLoader from '../button-loader';
import ChangeRequestMessageModalPopup from '../change-request-message';
import { BindDropdownsForMailings } from 'state/mailings/other/actions'
import { IsNullOrEmptyString } from '../../../global'
import { simplurisFetch } from 'helpers'

function ChangeRequestModalPopup({ props, OpenModalPopup, caseManagerEmail, caseID, caseName, AttributesValue, setShowChangeRequestModalPopup, BindDropdownsForMailings, MailingsDropdowns }) {
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    let typeValue = [];
    const [newAttributeValue, setNewAttributeValue] = useState('');
    const [newAttributeValueddl, setNewAttributeValueddl] = useState('');
    const [newAttributeKeyddl, setNewAttributeKeyddl] = useState('');
    const [newAttributeValueReqError, setNewAttributeValueReqError] = useState(false);
    const [newAttributeValueddlReqError, setNewAttributeValueddlReqError] = useState(false);
    const [showChangeRequestMessageModalPopup, setShowChangeRequestMessageModalPopup] = useState(false);
    const [message, setMessage] = useState('');

    if (!IsNullOrEmptyString(AttributesValue) && AttributesValue.controlType == 2) {
        if (MailingsDropdowns.other[AttributesValue.Type] != null) {
            typeValue = MailingsDropdowns.other[AttributesValue.Type];
        }
    }

    function CloseChangeRequestModalPopup() {
        setNewAttributeValue('');
        setNewAttributeValueddl('');
        setNewAttributeKeyddl('');
        setNewAttributeValueReqError(false);
        setNewAttributeValueddlReqError(false);
        setShowChangeRequestModalPopup(false);
    }

    function OpenChangeRequestMessageModalPopup(message) {
        setShowChangeRequestModalPopup(false);
        setShowChangeRequestMessageModalPopup(true);
        setMessage(message);
    }

    const RequestClick = () => {

        if (!IsNullOrEmptyString(AttributesValue) && AttributesValue.controlType == 2) {
            if (IsNullOrEmptyString(newAttributeValueddl) && !newAttributeValueddl) {
                setNewAttributeValueddlReqError(true);
                return;
            }
        }
        else {
            if (IsNullOrEmptyString(newAttributeValue)) {
                setNewAttributeValueReqError(true);
                return;
            }
        }

        var data = {};
        data.OptionalCaseID = caseID;
        data.SubmittingPersonID = localStorage.getItem(global.PersonID);
        data.SubmittingPersonID = data.SubmittingPersonID != 'undefined' ? data.SubmittingPersonID : 0;
        data.InvolvedRecordID = AttributesValue.mailingID;
        data.TableName = "dbo.Mailing";
        data.TableColumn = AttributesValue.tableColumn;
        if (!IsNullOrEmptyString(AttributesValue) && AttributesValue.controlType == 2) {
            data.LookupTableName = AttributesValue.tableName;
            data.LookupTableOldValue = AttributesValue.data;
            data.LookupTableNewValue = newAttributeKeyddl;
            data.OldAttributeValue = AttributesValue.involvedRecordID;
            data.NewAttributeValue = newAttributeValueddl;
        }
        else if (!IsNullOrEmptyString(AttributesValue) && AttributesValue.controlType == 1) {
            data.OldAttributeValue = AttributesValue.data;
            data.NewAttributeValue = newAttributeValue;
        }
        data.ChangeRequestStatusTypeID = 1;
        data.Activity = "@P_" + localStorage.getItem(global.PersonID) + "_" + localStorage.getItem(global.FirstName).toLowerCase() + " submitted a data variable change request for case " + "@C_" + caseID + "_" + caseName;
        //"@P_" + props.personID + "_" + localStorage.getItem(global.FirstName).toLowerCase() + " submitted a data variable change request for case " + "@C_" + props.caseID + "_" + props.caseName;
        data.ActivityDate = new Date();
        data.CaseManagerEmail = caseManagerEmail != null && caseManagerEmail != 'undefined' ? caseManagerEmail : "";
        setShowButtonLoader(true);


        simplurisFetch({
            url: "cases/" + caseID + "/newchangerequest", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowButtonLoader(false);
            if (data.name != undefined && data.name == "FetchError") {                
                if (data.errorObject.errorMessage == "ERROR") {
                    CloseChangeRequestModalPopup();
                    OpenChangeRequestMessageModalPopup("Alert! Your change request not submitted.");
                }
            }
            else {                
                CloseChangeRequestModalPopup();
                OpenChangeRequestMessageModalPopup("Thank you! Your change request has been submitted successfully. Your Project Manager will process the request ASAP.");
            }
            }).catch(e => {
                console.error('Error in new change request', e, e.errorObject);
                setShowButtonLoader(false);
                if (e.errorObject.errorMessage == "ERROR") {
                    CloseChangeRequestModalPopup();
                    OpenChangeRequestMessageModalPopup("Alert! Your change request not submitted.");
                }
            });       
    }

    const fetchRemailType = async () => {        
        simplurisFetch({
            url: "cases/bindremailtype/general"
        }).then((mailingsData) => {            
            if (mailingsData.resultCode == "SUCCESS") {                
                BindDropdownsForMailings(mailingsData.resultObject, "RemailType");
            }
            }).catch(e => { console.error('Error in bind remail type', e, e.errorObject);});
    };

    const fetchMailingPostageType = async () => {

        simplurisFetch({
            url: "cases/bindmailingpostagetype/general"
        }).then((mailingsData) => {            
            if (mailingsData.resultCode == "SUCCESS") {                
                BindDropdownsForMailings(mailingsData.resultObject, "MailingPostageType");
            }
            }).catch(e => { console.error('Error in bind mailing postage type', e, e.errorObject); });
    };

    const fetchMailingTranslationLanguage = async () => {



        simplurisFetch({
            url: "cases/bindmailingtranslationlanguage/general"
        }).then((mailingsData) => {            
            if (mailingsData.resultCode == "SUCCESS") {                
                BindDropdownsForMailings(mailingsData.resultObject, "MailingTranslationLanguage");
            }
            }).catch(e => { console.error('Error in bind mailing translation language', e, e.errorObject); });
    };

    const fetchMailingType = async () => {


        simplurisFetch({
            url: "cases/bindmailingtype/general"
        }).then((mailingsData) => {            
            if (mailingsData.resultCode == "SUCCESS") {                
                BindDropdownsForMailings(mailingsData.resultObject, "MailingType");
            }
            }).catch(e => { console.error('Error in bind mailing type', e, e.errorObject); });
    };

    const fetchNotificationType = async () => {

        simplurisFetch({
            url: "cases/bindnotificationtype/general"
        }).then((mailingsData) => {            
            if (mailingsData.resultCode == "SUCCESS") {                
                BindDropdownsForMailings(mailingsData.resultObject, "NotificationType");
            }
            }).catch(e => { console.error('Error in bind notification type', e, e.errorObject);  });     
    };

    const fetchMailingEnvelopeType = async () => {

        simplurisFetch({
            url: "cases/bindmailingenvelopetype/general"
        }).then((mailingsData) => {            
            if (mailingsData.resultCode == "SUCCESS") {                
                BindDropdownsForMailings(mailingsData.resultObject, "MailingEnvelopeType");
            }
            }).catch(e => { console.error('Error in bind envelope type', e, e.errorObject);  });            
    };

    const fetchMailingServiceType = async () => {

        simplurisFetch({
            url: "cases/bindmailingservicetype/general"
        }).then((mailingsData) => {
            if (mailingsData.resultCode == "SUCCESS") {
                BindDropdownsForMailings(mailingsData.resultObject, "MailingServiceType");
            }
            }).catch(e => { console.error('Error in bind service type', e, e.errorObject);  });            
    };

    const setDropDownValue = (e) => {
        let selectIndex = e.nativeEvent.target.selectedIndex;
        setNewAttributeKeyddl(e.nativeEvent.target[selectIndex].text);
        setNewAttributeValueddl(e.target.value);
        setNewAttributeValueddlReqError(false);
    }

    useEffect(() => {
        if (!IsNullOrEmptyString(AttributesValue) && AttributesValue.controlType == 2) {
            if (AttributesValue.tableName == "dbo.RemailType") {
                fetchRemailType();
            }
            else if (AttributesValue.tableName == "dbo.MailingPostageType") {
                fetchMailingPostageType();
            }
            else if (AttributesValue.tableName == "dbo.MailingTranslationLanguage") {
                fetchMailingTranslationLanguage();
            }
            else if (AttributesValue.tableName == "dbo.MailingType") {
                fetchMailingType();
            }
            else if (AttributesValue.tableName == "dbo.NotificationType") {
                fetchNotificationType();
            }
            else if (AttributesValue.tableName == "dbo.MailingEnvelopeType") {
                fetchMailingEnvelopeType();
            }
            else if (AttributesValue.tableName == "dbo.Mailing" && AttributesValue.Type == "MailingServiceType") {
                fetchMailingServiceType();
            }
        }
    }, [AttributesValue.tableColumn]);

    return (
        <>
            <Modal show={OpenModalPopup} onHide={CloseChangeRequestModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title> <h5 className="modal-title SimplurisBidRequestTitle SimplurisFontWeight400">Change Request</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-10 col-xs-10 col-sm-12 ml-3">
                            <p className="modal-title">
                                To ensure the highest level of quality, updates to this data variable will require approval from the project manager.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-xl-12 col-md-6 col-xs-12 col-sm-12">
                            <div className="col-xl-7 col-md-12 col-12 position-relative">
                                <label className="SimplurisBidRequestTitle SimplurisFontWeight400 SimplurisFontSize16">Attribute Title</label>
                            </div>
                            <div className="col-xl-7 col-md-12 col-12 position-relative">
                                <label className="SimplurisRequestChangeLabel SimplurisFontWeight400 SimplurisFontSize14">Old Value:</label>
                                <input type="text" className="form-control SimplurisMatrialTextBox" defaultValue={AttributesValue.data} disabled />
                                <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank" />
                            </div>
                            {(
                                () => {
                                    if (AttributesValue != "" && AttributesValue.controlType == 1) {
                                        return <div className={`col-xl-7 col-md-12 col-12 position-relative mt-md-3 mt-0 ${newAttributeValueReqError ? 'has-error' : ''}`}>
                                            <label className="SimplurisRequestChangeLabel SimplurisFontWeight400 SimplurisFontSize14">New Value:</label>
                                            <input type="text" onChange={e => (setNewAttributeValue(e.target.value), setNewAttributeValueReqError(false))} className="form-control SimplurisMatrialTextBox" placeholder="New Attribute Value" />
                                            {newAttributeValueReqError ? <span className="SimplurisRequired">REQUIRED</span> : null}
                                        </div>
                                    }
                                    else {
                                        return <div className={`col-xl-7 col-md-12 col-12 position-relative mt-md-3 mt-0 ${newAttributeValueddlReqError ? 'has-error' : ''}`}>
                                            <label>New Value:</label>
                                            <div className="position-relative">
                                                {
                                                    <select value={newAttributeValueddl} className="form-control SimplurisNewCaseMemebersInputBox"
                                                        onChange={setDropDownValue}>
                                                        <option>Select New Value</option>
                                                        {typeValue.map(item => (
                                                            <option value={item.key}>
                                                                {item.value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                }
                                                {newAttributeValueddlReqError ? <span className="text-danger">Please select new value</span> : null}
                                            </div>
                                        </div>
                                    }
                                }
                            )()}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-10 col-md-6 col-xs-12 col-sm-12 mt-lg-3 mt-0 ml-lg-3">
                            <button type="button" onClick={RequestClick} className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w btn-light py-3 mb-5 mt-3">
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Submit Request</span>}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ChangeRequestMessageModalPopup OpenModalPopup={showChangeRequestMessageModalPopup} setShowChangeRequestMessageModalPopup={setShowChangeRequestMessageModalPopup} message={message} />
        </>
    )
}
//export default ChangeRequestModalPopup


export default connect(
    (state) => ({
        MailingsDropdowns: state.mailings
    }),
    { BindDropdownsForMailings }
)(ChangeRequestModalPopup)