import * as React from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'

import MoreInfoPopover from 'react/components/more-info-popover'

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NumberCard = ({ title, number, percentChange, popoverContent }) => {
    
  const hasChange = !!percentChange
  const isNegativeChange = hasChange && percentChange < 0
  return (
    <div className="col-md-6">
      <div className="card p-3">
        <TitleBar>
          <span className="text-md text-dark">
            {title}
            <MoreInfoPopover popoverContent={popoverContent} />
          </span>
        </TitleBar>
        <div className="d-flex">
          <div className="i-con-h-a">
            <div className="mt-1">
              <span className={`text-lg text-primary`}>
                {number && (
                  <>
                    <CountUp end={number} duration={0.75} separator="," />
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NumberCard
