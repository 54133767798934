import * as React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import USMap from '../us-map'
import TitleBar from './title-bar'
import { getSelectedCase } from 'state/cases/data/selectors'
import TopMailingInfo from './top-mailing-info'
import { getLines } from '../helpers'
import MailingInfo from './mailing-hops-info'
import MailingHistoryInfo from './mailing-history-info'

const ANIMATION_MS = 250

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
`
const Wrapper = styled.div`
  background: white;
  height: 100vh;
  width: 100vw;
  transform: ${props => (props.show ? 'scale(1)' : 'scale(0)')};
  transition: transform ${ANIMATION_MS}ms;
`
const TopSection = styled.div`
  width: 100%;
  height: 160px;
`
const RightSection = styled.div`
  position: absolute;
  right: 52px; 
  bottom: 35px;
`
const MapWrapper = styled.div`
  height: calc(100% - 160px);
`

const FullScreenMap = ({
    caseID,
    selectedCase,
    exitFullScreen,
    mailings,
    mailTrackingTopLevelHopsData,
    mailTrackingHopsDetailData,
    apiFlageFetchingMailingHopsDetails,
    apiFlageFetchingMailingHistoryDetails,
    defaultMailing,
    fullScreenMapInfo,
    onLineClick,
    getMailTrackingDdataByMailingHistoryID,
    mailHistoryDetail,
    showMailingHistoryRightSideSection,
    setShowMailingHistoryRightSideSection,
    getCaseMemberDetailComponent
}) => {
    const [show, setShow] = React.useState(false)
    React.useEffect(() => {
        setShow(true)
    }, [])

    const [selectedMailing, setSelectedMailing] = React.useState(fullScreenMapInfo)
    
    return ReactDOM.createPortal(
        <Overlay className="ZoomedMailTrackingOverlay">
            <Wrapper show={show}>
                <TopSection className="ZoomedMailTrackingTopSection">
                    <TitleBar
                        title={selectedCase.project}
                        exit={() => {
                            setShow(false)
                            setTimeout(exitFullScreen, ANIMATION_MS)                            
                        }}
                    />
                    <TopMailingInfo mailings={mailings} />
                </TopSection>                
                <RightSection className="ZoomedMailTrackingRightSection">
                    {
                        showMailingHistoryRightSideSection ?
                            <MailingHistoryInfo
                                setShowMailingHistoryRightSideSection={setShowMailingHistoryRightSideSection}
                                selectedMailing={fullScreenMapInfo}
                                apiFlageFetchingMailingHistoryDetails={apiFlageFetchingMailingHistoryDetails}
                                mailTrackingHopsDetailData={mailTrackingHopsDetailData}
                                mailHistoryDetail={mailHistoryDetail}
                                caseID={caseID}
                                getCaseMemberDetailComponent={getCaseMemberDetailComponent}/>
                            :
                            <MailingInfo
                                selectedMailing={fullScreenMapInfo}
                                apiFlageFetchingMailingHopsDetails={apiFlageFetchingMailingHopsDetails}
                                mailTrackingHopsDetailData={mailTrackingHopsDetailData}
                                caseID={caseID}
                                getMailTrackingDdataByMailingHistoryID={getMailTrackingDdataByMailingHistoryID} />                    
                    } 
                </RightSection>                                
                <MapWrapper className="ZoomedMailTrackingMapSection">
                    <USMap
                        lines={getLines(mailTrackingTopLevelHopsData)}
                        linesdetails={getLines(mailTrackingHopsDetailData)}
                        onLineClick={onLineClick}
                        startingZoom={0.75}
                        isZoom={true}
                    />
                </MapWrapper>
            </Wrapper>
        </Overlay>,
        document.body
    )
}

export default connect((state, props) => ({
    selectedCase: getSelectedCase(state, props),
}))(FullScreenMap)
