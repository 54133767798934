import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import Header from '../../components/home-header'
import { SectionSpinner } from 'react/components/spinners';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ActivityDetail from '../../components/case-activity';
import ButtonLoader from '../../components/button-loader';
import { simplurisFetch } from 'helpers'
//import { labFeatures } from 'appSettings.json'
import { AnlyticsPageLog } from '../../../global';
import { appMode, labFeatures, fakeAPIData, allowPageLog } from '../../../api-config'
import { fetchHomeLoggedinCaseActivity } from 'state/home-loggedin-case/actions'

JavascriptTimeAgo.locale(en)
let _pageSize = 10

function HomeLoggedinCase({
    caseActivity,
    fetchHomeLoggedinCaseActivity,
    fetchingHomeLoggedinCaseActivity,
    lastCaseId,
    isHasNewData,
    props
}) {

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const d = new Date();
    const day = d.getDate();
    const currentDate = (('' + day).length < 2 ? '0' : '') + day;
    const [CurrentDate, setCurrentDate] = useState(monthNames[(new Date()).getMonth()] + ' ' + currentDate + ', ' + new Date().getFullYear());


    let activityToShow;
    if (appMode != "sandbox") {
        activityToShow = Object.values(caseActivity)
            .filter(activity => {
                return activity;
            })
            .reverse();
    }
    else {
        activityToShow = Object.values(caseActivity)
            .filter(activity => {
                return activity;
            });

        activityToShow.sort(function (a, b) {
            var key1 = new Date(a.activityDate);
            var key2 = new Date(b.activityDate);

            if (key2 < key1) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
        });
    }

    activityToShow = activityToShow.filter(function (v) {
        return v.caseActivityID > 0;
    });

    const [showPageLoaderForLoadMore, setShowPageLoaderForLoadMore] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [firstName, setFirstName] = useState(localStorage.getItem(global.FirstName) || '');

    useEffect(() => {

        fetchHomeLoggedinCaseActivity({ lastCaseId, _pageSize })
        AnlyticsPageLog("Home-loggedin-case page");
    }, []);

    const LoadMoreCases = () => {
        setShowPageLoaderForLoadMore(true);
        fetchHomeLoggedinCaseActivity({ lastCaseId, _pageSize })
        setShowPageLoaderForLoadMore(false);
    }

    return (
        <div>
            <Header />
            <div id="main" className="layout-row flex">
                <div id="content" className="flex">
                    <div className="d-flex">
                        <div className="d-md-flex flex bg-white">
                            <div className="col-md-5 col-12 text-white position-relative">
                                <div className="d-flex flex h-100">
                                    <div className="col-md-12 my-auto SimplurisAutoMargin">
                                        <div className="d-flex flex row">
                                            <div className="offset-md-1 col-xl-9 col-md-12 SimplurisFullWidth-768 col-12">
                                                <label className="SimplurisMutedText SimplurisFontWeight400 SimplurisFontSize11">
                                                    {CurrentDate}
                                                </label>
                                                <h3 className="SimplurisWelcomeLable SimplurisFontWeight900">Welcome back, {firstName}!</h3>

                                            </div>
                                            <div className="offset-md-1 col-xl-9 col-md-12 col-xs-12 col-sm-12 mt-2">
                                                <div className="row">
                                                    <div className="col-xl-6 col-md-12 col-xs-12 col-sm-12 mt-2">
                                                        <Link to={`/cases`} name="btnViewMyCases" value="View my Cases" className="btn SimplurisHomeLoggedinCaseButton btn-lg btn-block SimplurisFontWeight700">View my Cases</Link>
                                                    </div>
                                                    {labFeatures &&
                                                        <div className="col-xl-6 col-md-12 col-xs-12 col-sm-12 mt-2">
                                                            <Link to={''} name="btnNewBidRequest" value="New Bid Request" className="btn SimplurisHomeLoggedinCaseButton btn-lg btn-block SimplurisFontWeight700" data-toggle="modal" data-target="#bidRequest" data-toggle-class="fade-down" data-toggle-class-target="#animate">New Bid Request</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="offset-md-1 col-xl-9 col-md-12 col-xs-12 col-sm-12 mt-4">
                                                <div className="SimplurisNewCaseTextColor SimplurisFontSize17 SimplurisFontWeight400">
                                                    <div>
                                                        Have questions about Livecase or prefer to discuss your case over the phone?
                                                    </div>
                                                    {
                                                        appMode != "sandbox" ? <Link to={`/request-call`} className="SimplurisPrimaryLink"> Request Free Consultation.</Link> : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 pt-3">
                                <label className="mb-3 SimplurisTitle position-relative">
                                    While you were away...
                                </label>

                                {/* {
                                    caseActivity.map(post =>
                                        <React.Fragment key={post.caseActivityID}>
                                            <ActivityDetail data={post} />
                                        </React.Fragment>
                                    )
                                }*/}
                                {
                                    fetchingHomeLoggedinCaseActivity && (
                                        <div className="mt-3 row pl-3 pr-3 pr-lg-5 pr-xl-5 pr-md-3 pr-sm-3">
                                            <div className="col-md-12 col-12">
                                                <div className="row">
                                                    <SectionSpinner />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    activityToShow.length > 0 ?
                                        activityToShow.map(post =>
                                            <React.Fragment key={post.caseActivityID}>
                                                <ActivityDetail data={post} parentScreen="HomeLoggedInScreen" />
                                            </React.Fragment>
                                        ) : <div>
                                            Seems your dashboard is empty. Go to{' '}
                                            <Link to="/cases">my cases</Link> to see your information.
                                        </div>

                                }
                                <div className={`col-md-12 mt-2 mb-5 text-center ${appMode == "sandbox" ? 'd-none' : 'd-block'}`}>
                                    {isHasNewData ? (
                                        <button
                                            className="btn w-md mb-4 mb-md-1 btn-light SimplurisDashbordPostText p-3"
                                            onClick={LoadMoreCases}
                                            disabled={fetchingHomeLoggedinCaseActivity}>
                                            {
                                                fetchingHomeLoggedinCaseActivity && (
                                                    <ButtonLoader />
                                                )
                                            }
                                            {fetchingHomeLoggedinCaseActivity && <span></span>}
                                            {!fetchingHomeLoggedinCaseActivity && <span>Load More</span>}
                                        </button>
                                    ) : null}

                                </div>

                                {<div className="pt-1 pl-1 pb-5">
                                    <a href={null}>
                                        <i className="mr-2 ml-2 i-con i-con-arrow-right"><i></i></i>
                                        <span>See all recent activity</span>
                                    </a>
                                </div>}

                                {/*showPageLoader ? <div className="row"> <SectionSpinner /> </div>: null*/}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default connect(
    (state, props) => ({
        caseActivity: state.homeLoggedinCase,
        lastCaseId: state.homeLoggedinCase.lastCaseId,
        isHasNewData: state.homeLoggedinCase.isHasNewData,
        fetchingHomeLoggedinCaseActivity: state.dashboardView.fetchingHomeLoggedinCaseActivity,
    }),
    {
        fetchHomeLoggedinCaseActivity,
    }
)(HomeLoggedinCase)
