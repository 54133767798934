import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn, SearchField, } from 'react-bootstrap-table'
import {
    getToolbarWithTotal,
    getSearchField,
} from 'react/components/react-bootstrap-table/get-custom-components'
import { getDateRange } from 'state/call-center/selectors'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import AccountRequestsDatePicker from './datepicker/account-requests-date-picker'
import { fetchAccountRequestsDataForAdmin } from 'state/admin/account-requests/actions'
import { simplurisFetch } from 'helpers'
import {
    getAllAccountRequestForAdmin
} from 'state/case-detail-view/admin/selectors'
import { setTimeout } from 'timers';
import AddDeniedReasonModalPopup from './add-denied-reason'
import { func } from 'prop-types';

var personIDsForChangeStatus = [];
var personArrayForChangeStatus = [];

const CallReleasePage = ({
    isFetchingAccountRequests,
    fetchAccountRequestsDataForAdmin,
    allAccountRequests = {},
    dateRange,
    userPermissions,
}) => {
    const style = {
        osition: 'absolute',
        transform: 'translate3d(0px, 34px, 0px)',
        top: '0px',
        left: '0px',
        'will-change': 'transform',
    };


    var {
        pageIndex = 1,
        pageSize = 10,
        totalCount = 0,
        pendingStatus = true,
        approveStatus = true,
        denyStatus = true,
        liveCaseRequest = true,
        sandboxRequest = true,
        searchValue,
        startDate = startDate,
        endDate = endDate,
        enrichedStatus = true,
        allEnrichedStatus = false,
        data = [],
        sortColumn = Object.keys(allAccountRequests).length > 0 ? allAccountRequests.sortColumn : 'platformPersonID',
        sortColumnDir = Object.keys(allAccountRequests).length > 0 ? allAccountRequests.sortColumnDir : 'asc',
    } = allAccountRequests


    var personIDForBlocked = [];

    const [sortName, setSortName] = useState(Object.keys(allAccountRequests).length > 0 ? allAccountRequests.sortColumn : 'platformPersonID');
    const [sortOrder, setSortOrder] = useState(Object.keys(allAccountRequests).length > 0 ? allAccountRequests.sortColumn : 'asc');

    const [Text, setText] = useState('')
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [pending, setPending] = useState(true);
    const [approved, setApproved] = useState(false);
    const [denied, setDenied] = useState(false);
    const [liveCase, setLiveCase] = useState(true);
    const [sandbox, setSandbox] = useState(true);
    const [isVisibale, setIsVisibale] = useState(false);
    const [showAddDeniedReasonModalPopup, setShowAddDeniedReasonPopup] = useState(false);
    const [allEnriched, setAllEnriched] = useState(false);
    const [enriched, setEnriched] = useState(true);

    const [personIDForLiveCaseAccountORSandBoxAccount, setPersonIDForLiveCaseAccountORSandBoxAccount] = useState('');
    const [accountType, setAccountType] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');


    function OpenAddDeniedReasonModalPopup() {
        setShowAddDeniedReasonPopup(true);
    }

    function OpenAddDeniedReasonModalPopupForUpdateStatusByPersonID(email, name, accountType, personID) {
        setShowAddDeniedReasonPopup(true);
        setPersonIDForLiveCaseAccountORSandBoxAccount(personID);
        setAccountType(accountType);
        setName(name);
        setEmail(email);
    }

    if (Object.keys(allAccountRequests).length > 0) {
        for (var i = 0; i < allAccountRequests.accountRequests.length; i++) {
            if (allAccountRequests.accountRequests[i].isBlocked == true || allAccountRequests.accountRequests[i].isPotentialCompetitor == true) {
                personIDForBlocked.push(allAccountRequests.accountRequests[i].platformPersonID);
            }
        }
    }


    useEffect(() => {
        try {
            allAccountRequests = [];
            fetchAccountRequestsDataForAdmin(1, 10, true, false, false, true, true, null, null, null, true, false, sortName, sortOrder)
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [])

    const CreateCustomSearchField = props => {
        return (
            <SearchField
                className="form-control SimplurisCaseBanktxtSearchCaseBank"
                placeholder=" "
                defaultValue={Text}
            />
        )
    }

    const createCustomToolBar = props => {
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="dropdown mb-2 d-inline-block pr-2">
                        <button className="btn btn-white SimplurisCaseBankAllDisbursementsDropdown px-4 py-2">
                            All {totalCount}{' '}
                        </button>
                        <div
                            className="dropdown-menu bg-dark"
                            role="menu"
                            x-placement="bottom-start"
                        ></div>
                    </div>
                    <div className="d-inline-block position-relative">
                        {props.components.searchField}
                        <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                    </div>
                </div>
            </>
        )
    }

    const tableOptions = {
        toolBar: createCustomToolBar,
        onlyOneExpanding: true,
        expandBy: 'column',
        onSizePerPageList: onSizePerPageList,
        onSearchChange: onSearchChange,
        onPageChange: onPageChange,
        onSortChange: onSortChange,
        sortName: sortName,
        sortOrder: sortOrder,
        page: pageIndex,
        sizePerPage: pageSize,
        searchField: CreateCustomSearchField,
        searchDelayTime: 1000,
        noDataText: showTableLoader()
    }

    function onSearchChange(searchText) {
        allAccountRequests.accountRequests = [];
        searchValue = searchText
        setText(searchText)

        if (searchText == '') setText('')

        searchText = searchText == '' ? null : searchText
        fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, denyStatus, liveCaseRequest, sandboxRequest, searchText, startDate, endDate, enrichedStatus, allEnrichedStatus, sortName, sortOrder)
        setTimeout(function () {
            setText('')
        }, 100000)
    }

    function onPageChange(page, sizePerPage) {
        allAccountRequests.accountRequests = [];
        fetchAccountRequestsDataForAdmin(
            page,
            sizePerPage,
            pendingStatus,
            approveStatus,
            denyStatus,
            liveCaseRequest,
            sandboxRequest,
            searchValue,
            startDate,
            endDate,
            enrichedStatus,
            allEnrichedStatus, 
            sortName, 
            sortOrder
        )
    }

    function onSizePerPageList(sizePerPage) {
        allAccountRequests.accountRequests = [];
        fetchAccountRequestsDataForAdmin(1, sizePerPage, pendingStatus, approveStatus, denyStatus, liveCaseRequest, sandboxRequest, searchValue, startDate, endDate, enrichedStatus, allEnrichedStatus, sortName, sortOrder)
    }

    function onSortChange(sortName, sortOrder) {
        allAccountRequests.accountRequests = [];
        setSortName(sortName);
        setSortOrder(sortOrder);
        fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, denyStatus, liveCaseRequest, sandboxRequest, searchValue, startDate, endDate, enrichedStatus, allEnrichedStatus, sortName, sortOrder)
    }

    function updateDateRangeFilter(dateRange) {
        allAccountRequests.accountRequests = [];
        setShowPageLoader(true);
        fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, denyStatus, liveCaseRequest, sandboxRequest, searchValue, dateRange["start"], dateRange["end"], enrichedStatus, allEnrichedStatus, sortName, sortOrder)
        if (!isFetchingAccountRequests) {
            personIDsForChangeStatus = [];
            setIsVisibale(false);
            personArrayForChangeStatus = [];
        }
        setShowPageLoader(false);
    }

    //if (isFetchingAccountRequests) {
    //    return <div className="row"> <SectionSpinner /></div>
    //}

    //if (showPageLoader) {
    //    return <div className="row"> <SectionSpinner /></div>
    //}

    function showTableLoader() {
        if (isFetchingAccountRequests || showPageLoader) {
            return <div className="row"> <SectionSpinner /></div>
        } else {
            return  <div className='SimplurisAccountRequestsNoDataFoundMsg'>No data found</div>;
        }
    }


    const customMultiSelect = props => {

        const { type, checked, disabled, onChange, rowIndex } = props;
        /*
        * If rowIndex is 'Header', means this rendering is for header selection column.
        */
        if (rowIndex === 'Header') {
            if (props.checked == true) {
                return (
                    <label className="ui-check">
                        <input className='react-bs-select-all'
                            type='checkbox'
                            name={'checkbox' + props.rowIndex}
                            id={'checkbox' + props.rowIndex}
                            checked={props.checked}
                            onChange={props.onChange} />
                        <i></i>
                    </label>);
            }
            else {
                return (
                    <label className="ui-check">
                        <input className='react-bs-select-all'
                            type='checkbox'
                            name={'checkbox' + props.rowIndex}
                            id={'checkbox' + props.rowIndex}
                            onChange={props.onChange} />
                        <i></i>
                    </label>);
            }

        } else {
            return (
                <label className="ui-check">
                    <input
                        type={type}
                        name={'checkbox' + rowIndex}
                        id={'checkbox' + rowIndex}
                        checked={checked}
                        disabled={disabled}
                        onChange={e => onChange(e, rowIndex)}
                        ref={input => {
                            if (input) {
                                input.indeterminate = props.indeterminate;
                            }
                        }} /> <i></i>
                </label>);
        }
    }

    const selectRowProp = {
        mode: 'checkbox',
        onSelect: onRowSelect,
        onSelectAll: onSelectAll,
        columnWidth: '60px',
        customComponent: customMultiSelect,
        unselectable: personIDForBlocked.toString(),
        className: function (row) {
            if (row.isBlocked == true || row.isPotentialCompetitor == true) {
                return 'SimplurisAccountRequestsGridBackgroudColorForBlocked';
            }
            else
                return '';
        }
    };

    function onRowSelect(row, isSelected, e) {

        if (isSelected) {

            personIDsForChangeStatus.push(row.platformPersonID);

            personArrayForChangeStatus.push({
                'PlatformPersonID': row.platformPersonID,
                'Email': row.email,
                'FullName': row.firstName + " " + row.lastName,
                'AccountActivationCode': row.accountActivationCode,
                'IsRequestAccessTestDrive': row.isRequestAccessTestDrive,
                'IsRequestAccess': row.isRequestAccess
            });
        }
        else {

            personIDsForChangeStatus = arrayRemove(personIDsForChangeStatus, row.platformPersonID);

            personArrayForChangeStatus = personArrayForChangeStatus.filter(x => x.PlatformPersonID != row.platformPersonID);
        }

        if (personIDsForChangeStatus.length > 0) {
            setIsVisibale(true);
        }
        else {
            setIsVisibale(false);
        }
    }

    function arrayRemove(arr, value) {

        return arr.filter(function (ele) {
            return ele != value;
        });

    }

    var i;
    function onSelectAll(isSelected, rows) {

        for (i = 0; i < rows.length; ++i) {

            if (isSelected) {

                personIDsForChangeStatus.push(rows[i].platformPersonID);

                personArrayForChangeStatus.push({
                    'PlatformPersonID': rows[i].platformPersonID,
                    'Email': rows[i].email,
                    'FullName': rows[i].firstName + " " + rows[i].lastName,
                    'AccountActivationCode': rows[i].accountActivationCode,
                    'IsRequestAccessTestDrive': rows[i].isRequestAccessTestDrive,
                    'IsRequestAccess': rows[i].isRequestAccess
                });
            }
            else {

                personIDsForChangeStatus = arrayRemove(personIDsForChangeStatus, rows[i].platformPersonID);
                personArrayForChangeStatus = personArrayForChangeStatus.filter(x => x.PlatformPersonID != rows[i].platformPersonID);
            }
        }


        if (personIDsForChangeStatus.length > 0) {
            setIsVisibale(true);
        }
        else {
            setIsVisibale(false);
        }

    }

    const onFilterChange = status => {
        allAccountRequests.accountRequests = [];
        if (status == 2) {
            setPending(!pending);
            fetchAccountRequestsDataForAdmin(1, pageSize, !pending, approveStatus, denyStatus, liveCaseRequest, sandboxRequest, searchValue, startDate, endDate, enrichedStatus, allEnrichedStatus);
        } else if (status == 1) {
            setApproved(!approved);
            fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, !approved, denyStatus, liveCaseRequest, sandboxRequest, searchValue, startDate, endDate, enrichedStatus, allEnrichedStatus);
        } else if (status == 0) {
            setDenied(!denied);
            fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, !denied, liveCaseRequest, sandboxRequest, searchValue, startDate, endDate, enrichedStatus, allEnrichedStatus);
        }
        else if (status == 3) {
            setLiveCase(!liveCase);
            fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, denyStatus, !liveCase, sandboxRequest, searchValue, startDate, endDate, enrichedStatus, allEnrichedStatus);
        }
        else if (status == 4) {
            setSandbox(!sandbox);
            fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, denyStatus, liveCaseRequest, !sandbox, searchValue, startDate, endDate, enrichedStatus, allEnrichedStatus);
        }
        else if (status == 5) {
            setEnriched(!enriched);
            setAllEnriched(false);
            fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, denyStatus, liveCaseRequest, sandboxRequest, searchValue, startDate, endDate, !enriched, false);
        }
        else if (status == 6) {
            setAllEnriched(!allEnriched);
            setEnriched(false);
            fetchAccountRequestsDataForAdmin(1, pageSize, pendingStatus, approveStatus, denyStatus, liveCaseRequest, sandboxRequest, searchValue, startDate, endDate, false, !allEnriched);
        }
    };

    function formatCellForIsAccountApproved(cell, row) {
        if (row.isAccountApproved == true) {
            return (<span className='badge bg-primary-lt p-1'>Approved</span>);
        }
        else if (row.isAccountApproved == false) {
            return (<span className='badge bg-danger-lt p-1'>Denied</span>);
        }
        else {
            return (<span className='badge badge-warning p-1'>Pending</span>);
        }
    }

    //Sandbox Account
    function formatCellForIsRequestAccessTestDrive(cell, row) {
        if (row.isApprovedTestDrive == true || row.isApprovedTestDrive == false || row.isRequestAccessTestDrive == true) {
            return (
                <div className="position-relative">
                    <div className="d-inline-block mr-2">
                        {(
                            () => {
                                if (row.isApprovedTestDrive == true) {
                                    return <span className='badge bg-success-lt p-1'>Approved</span>
                                }
                                if (row.isApprovedTestDrive == false) {
                                    return <span className='badge bg-danger-lt p-1'>Denied</span>
                                }
                                if (row.isRequestAccessTestDrive == true) {
                                    return <span className='badge bg-primary-lt p-1'>Requested</span>
                                }
                            }
                        )()}
                    </div>
                    <span className="badge bg-secondary-lt SimplurisAdminAccountRequestsGridInlineDropdown position-absolute">
                        <div className="btn-group">
                            <div className="btn-group dropdown">
                                <i className="i-con i-con-more-h" data-toggle="dropdown" aria-expanded="false"><i></i></i>
                                <ul className={`dropdown-menu ${(row.isBlocked == true || row.isPotentialCompetitor == true) ? 'hide' : ''}`} x-placement="bottom-start" style={style}>
                                    <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(null, row.email, row.firstName + " " + row.lastName, 'SandboxAccount', row.platformPersonID, true) }}>Approved</a></li>
                                    <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(null, row.email, row.firstName + " " + row.lastName, 'SandboxAccount', row.platformPersonID, false) }}>Denied</a></li>
                                    <li className="dropdown-item"><a onClick={e => { OpenAddDeniedReasonModalPopupForUpdateStatusByPersonID(row.email, row.firstName + " " + row.lastName, 'SandboxAccount', row.platformPersonID) }}>Denied With Reason</a></li>
                                </ul>
                            </div>
                        </div>
                    </span>
                </div>
            )
        }
    }

    //Sandbox Activated
    function formatCellForIsApprovedTestDrive(cell, row) {
        if (row.isApprovedTestDrive == true) {
            return (<span className='badge bg-success-lt p-1'>Yes</span>);
        }
        else if (row.isApprovedTestDrive == false) {
            return (<span className='badge bg-danger-lt p-1'>No</span>);
        }
        else {
            return (<span className='badge badge-warning p-1'></span>);
        }
    }

    //LiveCase Account
    function formatCellForLiveCaseAccount(cell, row) {
        if (row.isAccountApproved == true || row.isAccountApproved == false || row.isRequestAccess == true) {
            return (
                <div className="position-relative">
                    <div className="d-inline-block mr-2">
                        {(
                            () => {
                                if (row.isAccountApproved == true) {
                                    return <span className='badge bg-success-lt p-1'>Approved</span>;

                                }
                                if (row.isAccountApproved == false) {
                                    return <span className='badge bg-danger-lt p-1'>Denied</span>;
                                }
                                if (row.isRequestAccess == true) {
                                    return <span className='badge bg-primary-lt p-1'>Requested</span>
                                }
                            }
                        )()}
                    </div>
                    <span className="badge bg-secondary-lt position-absolute SimplurisAdminAccountRequestsGridInlineDropdown">
                        <div className="btn-group">
                            <div className="btn-group dropdown">
                                <i className="i-con i-con-more-h" data-toggle="dropdown" aria-expanded="false"><i></i></i>
                                <ul className={`dropdown-menu ${(row.isBlocked == true || row.isPotentialCompetitor == true) ? 'hide' : ''}`} x-placement="bottom-start" style={style}>
                                    <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(row.accountActivationCode, row.email, row.firstName + " " + row.lastName, 'LiveCaseAccount', row.platformPersonID, true) }}>Approved</a></li>
                                    <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(null, row.email, row.firstName + " " + row.lastName, 'LiveCaseAccount', row.platformPersonID, false) }}>Denied</a></li>
                                    <li className="dropdown-item"><a onClick={e => { OpenAddDeniedReasonModalPopupForUpdateStatusByPersonID(row.email, row.firstName + " " + row.lastName, 'LiveCaseAccount', row.platformPersonID) }}>Denied With Reason</a></li>
                                </ul>
                            </div>
                        </div>
                    </span>
                </div>
            );
        }
    }

    //LiveCase Activated
    function formatCellForLiveCaseActivated(cell, row) {

        if (row.isAccountApproved == true) {
            return (<span className='badge bg-success-lt p-1'>Yes</span>);
        }
        if (row.isAccountApproved == false) {
            return (<span className='badge bg-danger-lt p-1'>No</span>);
        }
        else {
            return (<span className='badge badge-warning p-1'></span>);
        }

    }

    function buttonFormatterForSandboxAccount(cell, row) {
        if (row.isRequestAccessTestDrive == true) {
            return (
                <span className="badge bg-secondary-lt">
                    <div className="btn-group">
                        <div className="btn-group dropdown">
                            <i className="i-con i-con-more-h" data-toggle="dropdown" aria-expanded="false"><i></i></i>
                            <ul className={`dropdown-menu ${(row.isBlocked == true || row.isPotentialCompetitor == true) ? 'hide' : ''}`} x-placement="bottom-start" style={style}>
                                <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(null, row.email, row.firstName + " " + row.lastName, 'SandboxAccount', row.platformPersonID, true) }}>Approved</a></li>
                                <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(null, row.email, row.firstName + " " + row.lastName, 'SandboxAccount', row.platformPersonID, false) }}>Denied</a></li>
                                <li className="dropdown-item"><a onClick={e => { OpenAddDeniedReasonModalPopupForUpdateStatusByPersonID(row.email, row.firstName + " " + row.lastName, 'SandboxAccount', row.platformPersonID) }}>Denied With Reason</a></li>
                            </ul>
                        </div>
                    </div>
                </span>
            );
        }
        else
            return <></>
    }

    function buttonFormatterForLiveCaseAccount(cell, row) {
        if (row.isRequestAccess == true) {
            return (
                <span className="badge bg-secondary-lt">
                    <div className="btn-group">
                        <div className="btn-group dropdown">
                            <i className="i-con i-con-more-h" data-toggle="dropdown" aria-expanded="false"><i></i></i>
                            <ul className={`dropdown-menu ${(row.isBlocked == true || row.isPotentialCompetitor == true) ? 'hide' : ''}`} x-placement="bottom-start" style={style}>
                                <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(row.accountActivationCode, row.email, row.firstName + " " + row.lastName, 'LiveCaseAccount', row.platformPersonID, true) }}>Approved</a></li>
                                <li className="dropdown-item"><a onClick={e => { UpdateStatusForLiveCaseAccountByPersonID(null, row.email, row.firstName + " " + row.lastName, 'LiveCaseAccount', row.platformPersonID, false) }}>Denied</a></li>
                                <li className="dropdown-item"><a onClick={e => { OpenAddDeniedReasonModalPopupForUpdateStatusByPersonID(row.email, row.firstName + " " + row.lastName, 'LiveCaseAccount', row.platformPersonID) }}>Denied With Reason</a></li>
                            </ul>
                        </div>
                    </div>
                </span>
            );
        }
        else
            return <></>
    }

    function UpdateStatusByPersonID(status, reason) {
        allAccountRequests.accountRequests = [];
        var data = {};
        data.PersonArrayForChangeStatus = JSON.stringify(personArrayForChangeStatus);
        data.PersonIDsForChangeStatus = personIDsForChangeStatus.toString();
        data.Status = status;
        data.DeniedReason = reason;

        setShowPageLoader(true);
        simplurisFetch({
            url: `auth/updatestatusisaccountapprovedforadmin`, method: 'POST', body: JSON.stringify(data)
        })
            .then(result => {

                setShowPageLoader(false);
                if (result.name != undefined && result.name == "FetchError") {

                } else {

                    personIDsForChangeStatus = [];
                    personArrayForChangeStatus = [];
                    setPending(true);
                    setApproved(true);
                    setDenied(true);
                    setShowAddDeniedReasonPopup(false);
                    setIsVisibale(false);
                    setAllEnriched(false);
                    setEnriched(true);
                    fetchAccountRequestsDataForAdmin(1, pageSize, true, false, false, true, true, searchValue, startDate, endDate, true, false);
                }
            })
            .catch(e => {
                console.error("Error in Update status by personID", e, e.errorObject);
            });
    }

    function UpdateStatusForLiveCaseAccountByPersonID(accountActivationCode, email, name, accountType, personID, status, reason) {
        allAccountRequests.accountRequests = [];

        var data = {};
        //data.AccountActivationCode = accountActivationCode;
        //data.Email = email;
        //data.Name = name;
        data.AccountType = accountType;
        data.PersonID = personID.toString();
        data.Status = status;
        data.DeniedReason = reason == undefined ? "" : reason;

        setShowPageLoader(true);
        simplurisFetch({
            url: `auth/updatestatusforlivecaseaccountorsandboxacountforadmin`, method: 'POST', body: JSON.stringify(data)
        })
            .then(result => {
                setShowPageLoader(false);
                if (result.name != undefined && result.name == "FetchError") {

                } else {
                    setPending(true);
                    setApproved(true);
                    setDenied(true);
                    setShowAddDeniedReasonPopup(false);
                    setIsVisibale(false);
                    setPersonIDForLiveCaseAccountORSandBoxAccount('');
                    setAccountType('');
                    setEmail('');
                    setName('');
                    setAllEnriched(false);
                    setEnriched(true);
                    fetchAccountRequestsDataForAdmin(1, pageSize, true, false, false, true, true, searchValue, startDate, endDate, true, false);
                }
            })
            .catch(e => {
                console.error("Error in Update status by personID", e, e.errorObject);
            });
    }

    return (
        <div className="position-relative">
            <div className="mb-4">
                <div className="row SimplurisAccountRequestsWrapperDiv">
                    <div className="col-12 col-sm-12 col-md-12 col-xl-3 col-lg-12 mb-3 mb-sm-3 mb-md-3 mb-xl-0 mb-lg-0 SimplurisFontSize16 SimplurisFontWeight500">
                        LiveCase Account Requests
                    </div>

                    <div className="col-12 col-md-12 col-sm-12 col-xl-9 col-lg-12">
                        {
                            <>
                                 <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-2 mt-xl-0 mt-lg-2 ml-2 mr-0 mr-md-4 mr-xl-2 mr-lg-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2 
                            ${ allEnriched ? '' : 'active'
                                    }`}
                                    id="btnAllEnriched"
                                    onClick={e => {
                                        onFilterChange('6')
                                    }}
                                    aria-selected="false">All</button>
                                <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-2 mt-xl-0 mt-lg-2 ml-2 mr-0 mr-md-4 mr-xl-2 mr-lg-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2 
                            ${ enriched ? '' : 'active'
                                    }`}
                                    id="btnEnriched"
                                    onClick={e => {
                                        onFilterChange('5')
                                    }}
                                    aria-selected="false">Enriched</button>
                                <span class="float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-2 mt-xl-0 mt-lg-2 pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2">|</span>
                            </>}
                        {
                            <>
                                <button className={`float-left float-md-left float-sm-left  float-xl-right float-lg-right mr-2 mr-md-2 mr-sm-0 mr-lg-0 mr-xl-0 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                            ${ denied ? '' : 'active'}`}
                                    id="btnDeniedcall"
                                    onClick={e => {
                                        onFilterChange('0')
                                    }}
                                    aria-selected="false">Denied</button>
                                <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right ml-2 ml-md-2 ml-sm-0 ml-lg-0 ml-xl-0 mr-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2
                            ${ approved ? '' : 'active'}`}
                                    id="btnApprovedcall"
                                    onClick={e => {
                                        onFilterChange('1')
                                    }}
                                    aria-selected="false">Approved</button>
                                <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right ml-2 mr-0 mr-md-4 mr-xl-2 mr-lg-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2 
                            ${ pending ? '' : 'active'
                                    }`}
                                    id="btnPendingcall"
                                    onClick={e => {
                                        onFilterChange('2')
                                    }}
                                    aria-selected="false">Pending</button>
                            </>}
                        <AccountRequestsDatePicker updateDateRangeFilter={updateDateRangeFilter} />
                        {
                            <>
                                <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-2 mt-xl-0 mt-lg-2 ml-2 mr-0 mr-md-4 mr-xl-2 mr-lg-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2 
                            ${ liveCase ? '' : 'active'
                                    }`}
                                    id="btnLiveCaseRequestcall"
                                    onClick={e => {
                                        onFilterChange('3')
                                    }}
                                    aria-selected="false">LiveCase Request</button>
                                <button className={`float-left float-md-left float-sm-left float-xl-right float-lg-right mt-2 mt-md-2 mt-xl-0 mt-lg-2 ml-2 mr-0 mr-md-4 mr-xl-2 mr-lg-2 btn btnDisbursementOverview pull-right SimplurisFontSize12 SimplurisFontWeight400 remove-bootstrap-persistent-button-border py-1 px-2 
                            ${ sandbox ? '' : 'active'
                                    }`}
                                    id="btnSandboxRequestcall"
                                    onClick={e => {
                                        onFilterChange('4')
                                    }}
                                    aria-selected="false">Sandbox Request</button>
                            </>}
                    </div>
                    {
                        isVisibale && <div className="SimplurisAccountRequestsDropDownWrapper">
                            <div className="btn-group mb-3">
                                <div className="btn-group dropdown">
                                    <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                        Status
                                    </button>
                                    <ul className="dropdown-menu" x-placement="bottom-start" style={style}>
                                        <li className="dropdown-item"><a onClick={e => { UpdateStatusByPersonID(true) }}>Approved</a></li>
                                        <li className="dropdown-item"><a onClick={e => { UpdateStatusByPersonID(false) }}>Denied</a></li>
                                        <li className="dropdown-item"><a onClick={e => { OpenAddDeniedReasonModalPopup() }}>Denied With Reason</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
            <div>
                <BootstrapTable
                    
                    data={allAccountRequests.accountRequests}
                    options={tableOptions}
                    tableContainerClass="table table-theme v-middle SimplurisAccountRequests table-hover table1"
                    bodyContainerClass="text-grid-td"
                    hover
                    remote={true}
                    pagination={true}
                    search={true}
                    fetchInfo={{ dataTotalSize: totalCount }}
                    trClassName="SimplurisStatisticsGridMainTr SimplurisAdminCallReleaseChildMainTr SimplurisAccountRequestsGridTr"
                    wrapperClasses="table-responsive"
                >
                    <TableHeaderColumn
                        dataField="platformPersonID"
                        isKey
                        hidden={true}
                        dataSort={true}
                        expandable={false}>
                        Platform Person ID
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn dataField='button' width="2%" dataFormat={buttonFormatterForSandboxAccount}>
                        Sandbox Action
                        </TableHeaderColumn>
                    <TableHeaderColumn dataField='button' width="2%" dataFormat={buttonFormatterForLiveCaseAccount}>
                        LiveCase Action
                        </TableHeaderColumn>*/}
                    <TableHeaderColumn
                        expandable={false}
                        width="160px"
                        dataField="firstName"
                        dataSort={true}
                        dataFormat={(firstName, { lastName }) =>
                            `${firstName} ${lastName}`
                        }>
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        expandable={false}
                        dataField="email"
                        dataSort={true}
                        width="230px">
                        Email
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        expandable={false}
                        dataSort={true}
                        dataField="personPhone1">
                        Phone Number
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyName"
                        dataSort={true}
                        expandable={false}>
                        Company Name
                    </TableHeaderColumn>

                    <TableHeaderColumn 
                        expandable={false}
                        dataFormat={formatCellForIsRequestAccessTestDrive}
                        dataSort={true}
                        dataField="isAccountActivated">
                        Sandbox Account
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false}
                        dataFormat={formatCellForIsApprovedTestDrive}
                        dataSort={true}
                        dataField="isApprovedTestDrive">
                        Sandbox Activated
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false}
                        dataFormat={formatCellForLiveCaseAccount}
                        dataField="accountRequiresApproval">
                        LiveCase Account
                    </TableHeaderColumn>
                    {/*<TableHeaderColumn 
                        expandable={false}
                        dataFormat={formatCellForLiveCaseActivated}
                        dataSort={true}
                        dataField="accountRequiresApproval">
                        LiveCase Activated
                    </TableHeaderColumn>*/}

                    {/* 
                    <TableHeaderColumn
                        dataField="personMapID"
                        expandable={false}>
                        Person Map ID
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="dboPersonID"
                        expandable={false}>
                        Dbo Person ID
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="externalPersonID"
                        expandable={false}>
                        External Person ID
                </TableHeaderColumn>*/}

                    {/* <TableHeaderColumn expandable={false}
                        dataFormat={formatCellForIsAccountApproved}
                        dataField="isAccountApproved">
                        Account Approved
                </TableHeaderColumn>*/}


                    {/*  <TableHeaderColumn expandable={false}

                        dataFormat={(isAccountActivated) =>
                            isAccountActivated == true ? `<span className='badge bg-primary-lt p-1'>Yes</span>` : `<span className='badge bg-danger-lt p-1'>No</span>`
                        }
                        dataField="isAccountActivated">
                        Account Activated
                </TableHeaderColumn>*/}
                    <TableHeaderColumn
                        dataField="sandboxDeniedReason"
                        dataSort={true}
                        expandable={false}>
                        Sandbox Denied Reason
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="liveCaseDeniedReason"
                        dataSort={true}
                        expandable={false}>
                        LiveCase Denied Reason
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="businessName"
                        dataSort={true}
                        expandable={false}>
                        Business Name
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        expandable={false} 
                        dataField="createdDate"
                        dataSort={true}
                        dataFormat={createdDate => new Date(createdDate).toLocaleDateString()} >
                        Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="4%"
                        dataField="accountActivationCode"
                        dataSort={true}
                        expandable={false}>
                        Account Activation Code
                    </TableHeaderColumn>

                    {/*<TableHeaderColumn
                        dataField="accountRequiresApproved"
                        expandable={false}>
                        Account Requires Approved
                    </TableHeaderColumn>*/}

                    <TableHeaderColumn
                        dataField="linkedInURL"
                        dataSort={true}
                        expandable={false}>
                        LinkedIn URL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="linkedInUserName"
                        dataSort={true}
                        expandable={false}>
                        LinkedIn UserName
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="twitterUserName"
                        dataSort={true}
                        expandable={false}>
                        Twitter UserName
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="twitterURL"
                        dataSort={true}
                        expandable={false}>
                        Twitter URL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fullName"
                        dataSort={true}
                        expandable={false}>
                        full Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="twitterFollowers"
                        dataSort={true}
                        expandable={false}>
                        Twitter Followers
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="gitHubUserName"
                        dataSort={true}
                        expandable={false}>
                        Git Hub User Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="facebookUserName"
                        dataSort={true}
                        expandable={false}>
                        Facebook User Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="gravatarUserName"
                        dataSort={true}
                        expandable={false}>
                        Gravatar User Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="personalWebsite"
                        dataSort={true}
                        expandable={false}>
                        Personal Website
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="jobTitle"
                        dataSort={true}
                        expandable={false}>
                        Job Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="headline"
                        dataSort={true}
                        expandable={false}>
                        Head line
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="bio"
                        dataSort={true}
                        expandable={false}>
                        Bio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="location"
                        dataSort={true}
                        expandable={false}>
                        Location
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="country"
                        dataSort={true}
                        expandable={false}>
                        Country
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="latitude"
                        dataSort={true}
                        expandable={false}>
                        Latitude
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="longitude"
                        dataSort={true}
                        expandable={false}>
                        Longitude
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyCategory"
                        dataSort={true}
                        expandable={false}>
                        Company Category
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="companyDescription"
                        dataSort={true}
                        expandable={false}>
                        Company Descripation
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyFunding"
                        dataSort={true}
                        expandable={false}>
                        Company Funding
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyEmployees"
                        dataSort={true}
                        expandable={false}>
                        Company Employees
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyAnnualRevenue"
                        dataSort={true}
                        expandable={false}>
                        Company Annual Revenue
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyType"
                        dataSort={true}
                        expandable={false}>
                        Company Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyDomain"
                        dataSort={true}
                        expandable={false}>
                        Company Domain
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyLocation"
                        dataSort={true}
                        expandable={false}>
                        Company Location
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyLinkedInURL"
                        dataSort={true}
                        expandable={false}>
                        Company LinkedIn URL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyLinkedInUserName"
                        dataSort={true}
                        expandable={false}>
                        Company LinkedIn User Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyTwitterURL"
                        dataSort={true}
                        expandable={false}>
                        Company Twitter URL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyTwitterUserName"
                        dataSort={true}
                        expandable={false}>
                        Company Twitter User Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyTwitterFollowers"
                        dataSort={true}
                        expandable={false}>
                        Company Twitter Followers
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="companyFacebookUserName"
                        dataSort={true}
                        expandable={false}>
                        Company Facebook User Name
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
            <AddDeniedReasonModalPopup OpenModalPopup={showAddDeniedReasonModalPopup} setShowAddDeniedReasonPopup={setShowAddDeniedReasonPopup} UpdateStatusByPersonID={UpdateStatusByPersonID} personIDForLiveCaseAccountORSandBoxAccount={personIDForLiveCaseAccountORSandBoxAccount} accountType={accountType} email={email} name={name} UpdateStatusForLiveCaseAccountByPersonID={UpdateStatusForLiveCaseAccountByPersonID} />
        </div>

    )
}


export default connect(
    (state, props) => ({
        isFetchingAccountRequests: getAllAccountRequestForAdmin(state, props),
        allAccountRequests: state.adminData.AllAccountRequests.accountRequests,
        dateRange: getDateRange(state),
        userPermissions: getPermissionsForCase(state, props)
    }),
    {
        fetchAccountRequestsDataForAdmin
    }
)(CallReleasePage)
