import { createSelector } from 'reselect'

import { stateSelector as caseDataStateSelector } from '../data/selectors'

export const stateSelector = state => state.cases.view

export const getSearchTerm = createSelector(
  [stateSelector],
  state => state.searchTerm
)

export const getFilteredCases = createSelector(
  [getSearchTerm, caseDataStateSelector],
    (searchTerm, caseDataState) => {        
    if (!searchTerm) {
      return caseDataState
    }      
      if (searchTerm.length >= 3) {     
          return Object.values(caseDataState).filter(({ officialName, caseID }) => {
              return (
                  (officialName && officialName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                  (caseID && String(caseID).includes(searchTerm))
              )
          })
      }
      else {          
          return caseDataState
      }
  }
)

export const getLastCaseID = createSelector(
  [stateSelector],
  state => state.lastCaseID
)

export const getIsHasNewData = createSelector(
  [stateSelector],
  state => state.isHasNewData
)
