import * as React from 'react'
import HeaderContent from './content'

const CaseDetailHeader = ({ caseDetail }) => (
	<>	
		<div className="page-title padding pb-0 pt-0">
			<div className="col-md-8 col-xs-12 col-sm-12 pl-0">
                <HeaderContent caseDetail={caseDetail} />
			</div>
		</div>
	</>
)

export default CaseDetailHeader