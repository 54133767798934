import { createSelector } from 'reselect'

const stateSelector = state => state.caseDetailView.mailings.history

export const getHistoryFetching = createSelector(
	[stateSelector],
	state => state.fetching
)

export const getMailingHopsDetailsFetching = createSelector(
    [stateSelector],
    state => state.fetchingMailingHopDetails
)

export const getMailingHistoryDetailsFetching = createSelector(
    [stateSelector],
    state => state.fetchingMailingHistoryDetails
)
