import React, { useState, useEffect, useRef } from 'react'
import AddPaymentMethodModalPopup from './add-payment-method-modal'
import RemoveCardConfirmationModalPopup from './delete-confirmation-modal'
import SetDefaultPaymentMethodCardConfirmationModalPopup from './set-default-payment-method'
import { SectionSpinner } from 'react/components/spinners'
import EditBankModalPopup from './edit-bank-modal'
import { simplurisFetch } from 'helpers'
import {  AnlyticsPageLog } from '../../../global'

function BillingPage() {

    const [showAddPaymentMethodModalPopup, setShowAddPaymentMethodModalPopup] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [cards, setCards] = useState([]);
    const fetchAllCards = async () => {
        setShowPageLoader(true);




        simplurisFetch({
            url: "auth/cardsDetails"
        }).then((cardsData) => {
            setShowPageLoader(false);            
            if (cardsData.name == undefined && cardsData.name != "FetchError") {                
                if (cardsData.resultCode == "SUCCESS") {                    
                    setCards(cardsData.resultObject);
                }
            }
            }).catch(e => { console.error('Error in cards details', e, e.errorObject); setShowPageLoader(false); });       
    };


    useEffect(() => {
        fetchAllCards();
        AnlyticsPageLog("Billing Tab");
    }, []);

    function OpenAddPaymentMethodModalPopup() {
        AnlyticsPageLog("Click on Add Payment Method Modal Popup from Billing tab");
        setShowAddPaymentMethodModalPopup(true);
    }

    return (
        <div>
            {
                cards.length > 0 && cards != null ? <div className="card SimplurisDashboardCard">
                    <div className="SimplurisCaseDetailsLoaderSection SimplurisProfileArea">
                        {showPageLoader ? <SectionSpinner /> : null}
                    </div>
                    <div className="px-4 py-3 SimplurisSubTabTitle">
                        Basic Information
                                        </div>
                    <hr className="m-0" />
                    <div className="px-4 py-0">
                        <BindCardGrid items={cards} fetchAllCards={fetchAllCards} />
                        <div className="row py-3 mb-2">
                            <div className="col-12">
                                <button type="button" onClick={() => OpenAddPaymentMethodModalPopup()} className="btn rounded-0 text-white w SimplurisDashbordDisbursementCard font-weight-500 SimplurisFontSize13 py-3 mt-2">Add payment Method</button>
                            </div>
                        </div>
                    </div>
                </div> : <div className="card SimplurisDashboardCard">
                        <div className="SimplurisCaseDetailsLoaderSection SimplurisProfileArea">
                            {showPageLoader ? <SectionSpinner /> : null}
                        </div>
                        <div className="px-4 py-3 SimplurisSubTabTitle">
                            Basic Information
                                        </div>
                        <hr className="m-0" />
                        <div className="px-4 py-3">
                            <div className="row">
                                <div className="col-12 SimplurisMutableTitle SimplurisFontSize15 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                    <label className="d-flex font-weight-500 SimplurisFontSize16 SimplurisCasesPageTitle">You have no saved cards.</label>
                                    <label className="d-flex mb-0 SimplurisFontSize12 SimplurisCasesPageTitle SimplurisFontWeight400">You can add a payment method by clicking the button below.</label>
                                </div>
                            </div>
                            <div className="row pb-3 pt-1 mb-2">
                                <div className="col-12">
                                    <button type="button" onClick={() => OpenAddPaymentMethodModalPopup()} className="btn rounded-0 text-white w SimplurisDashbordDisbursementCard font-weight-500 SimplurisFontSize13 py-3 mt-3">Add payment Method</button>
                                </div>
                            </div>
                        </div>
                    </div>

            }
            <AddPaymentMethodModalPopup OpenModalPopup={showAddPaymentMethodModalPopup} fetchAllCards={fetchAllCards} setShowAddPaymentMethodModalPopup={setShowAddPaymentMethodModalPopup} />
        </div>
    )
}

export default BillingPage

function BindCardGrid(data) {
    const [showRemoveCardConfirmationModalPopup, setShowRemoveCardConfirmationModalPopup] = useState(false);
    const [showSetDefaultPaymentMethodCardConfirmationModalPopup, setShowSetDefaultPaymentMethodCardConfirmationModalPopup] = useState(false);
    const [cardAttributesValue, setCardAttributesValue] = useState('');
    let [paymentMethodID, setPaymentMethodID] = useState('');
    const [showEditBankModalPopup, setShowEditBankModalPopup] = useState(false);
    let [bankDetails, setBankDetails] = useState([]);

    function OpenRemoveCardConfirmationModalPopup(data, customerID, cardID, paymentMethodTypeID) {        
        AnlyticsPageLog("Click on Remove Card Confirmation Modal Popup from Billing tab");
        //setPaymentMethodID(data);
        setCardAttributesValue({ data, customerID, cardID, paymentMethodTypeID })
        setShowRemoveCardConfirmationModalPopup(true);
    }

    function OpenSetDefaultPaymentMethodCardConfirmationModalPopup(data) {
        AnlyticsPageLog("Click on Set Default Payment Method Modal Popup from Billing tab");
        setPaymentMethodID(data);
        setShowSetDefaultPaymentMethodCardConfirmationModalPopup(true);
    }

    function OpenEditBankModalPopup(paymentMethodID, bankAccountNumber, bankRoutingNumber) {        
        AnlyticsPageLog("Click on Edit Bank Method Modal Popup from Billing tab");
        setBankDetails({ paymentMethodID, bankAccountNumber, bankRoutingNumber })
        setShowEditBankModalPopup(true);
    }

    if (data.items != null && data.items.length > 0) {
        return <div>
            {
                data.items.map((post) =>
                    <>
                        {(
                            () => {
                                if (post.paymentMethodTypeID == 1) {
                                    return <>
                                        <div className="row py-4">
                                            <div className="col-xl-8 col-lg-6 col-md-6 SimplurisMutableTitle SimplurisFontSize15 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                                <label className="d-flex font-weight-500 SimplurisFontSize16 SimplurisCasesPageTitle"> {post.brand} card ending in ****{post.cardNumber}</label>
                                                <label className="d-flex mb-0 SimplurisFontSize14 SimplurisMutableTitle SimplurisFontWeight400">{post.nameOfCard}</label>
                                                <label className="d-flex mb-0 SimplurisFontSize14 SimplurisMutableTitle SimplurisFontWeight700">
                                                    {
                                                        post.isDefault ? "Default Payment Method" :
                                                            <a href={null} onClick={() => OpenSetDefaultPaymentMethodCardConfirmationModalPopup(post.paymentMethodID)} className="SimplurisBlueColor">Make default payment method?</a>
                                                    }

                                                </label>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-3 align-self-center mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                                {/*    <input type="button" name="Edit" value="Edit" className="btn w-xs py-3 rounded-0 SimplurisBillingButtonBackGroundColor SimplurisFontSize13 font-weight-500" />*/}
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-3 pl-md-2 pl-lg-2 pl-xl-2 align-self-center">
                                                <input type="button" name="Remove" onClick={() => OpenRemoveCardConfirmationModalPopup(post.paymentMethodID, post.stripeCustomerID, post.cardID, post.paymentMethodTypeID)} value="Remove" className="btn w-xs py-3 rounded-0 SimplurisBillingButtonBackGroundColor SimplurisFontSize13 font-weight-500" />
                                            </div>
                                        </div>
                                        <hr className="m-0" />
                                    </>
                                }
                                else {
                                    return <>
                                        <div className="row py-4">
                                            <div className="col-xl-8 col-lg-6 col-md-6 SimplurisMutableTitle SimplurisFontSize15 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                                <label className="d-flex font-weight-500 SimplurisFontSize16 SimplurisCasesPageTitle">Bank Account Number {post.bankAccountNumber}</label>
                                                <label className="d-flex mb-0 SimplurisFontSize14 SimplurisMutableTitle SimplurisFontWeight400">Bank Routing Number {post.bankRoutingNumber}</label>
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-3 align-self-center mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                                <input type="button" name="Edit" value="Edit" onClick={() => OpenEditBankModalPopup(post.paymentMethodID, post.bankAccountNumber, post.bankRoutingNumber)} className="btn w-xs py-3 rounded-0 SimplurisBillingButtonBackGroundColor SimplurisFontSize13 font-weight-500" />
                                            </div>
                                            <div className="col-xl-2 col-lg-3 col-md-3 pl-md-2 pl-lg-2 pl-xl-2 align-self-center">
                                                <input type="button" name="Remove" onClick={() => OpenRemoveCardConfirmationModalPopup(post.paymentMethodID, post.stripeCustomerID, post.cardID, post.paymentMethodTypeID)} value="Remove" className="btn w-xs py-3 rounded-0 SimplurisBillingButtonBackGroundColor SimplurisFontSize13 font-weight-500" />
                                            </div>
                                        </div>
                                        <hr className="m-0" />

                                    </>

                                }

                            }
                        )()}

                    </>
                )
            }
            <RemoveCardConfirmationModalPopup fetchAllCards={data.fetchAllCards} OpenModalPopup={showRemoveCardConfirmationModalPopup} cardAttributesValue={cardAttributesValue} setShowRemoveCardConfirmationModalPopup={setShowRemoveCardConfirmationModalPopup} />
            <SetDefaultPaymentMethodCardConfirmationModalPopup fetchAllCards={data.fetchAllCards} OpenModalPopup={showSetDefaultPaymentMethodCardConfirmationModalPopup} paymentMethodID={paymentMethodID} setShowSetDefaultPaymentMethodCardConfirmationModalPopup={setShowSetDefaultPaymentMethodCardConfirmationModalPopup} />
            <EditBankModalPopup OpenModalPopup={showEditBankModalPopup} fetchAllCards={data.fetchAllCards} setShowEditBankModalPopup={setShowEditBankModalPopup} bankDetails={bankDetails} />
        </div>
    }
    else {
        return <div></div>
    }

}