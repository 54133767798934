import { USER_LOGIN } from './actions'

const initialState = {}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case USER_LOGIN:
            return { ...payload }
        default:
            return state
    }
}
