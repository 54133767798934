import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	background-color: red;
	padding: 0 20px;
	height: 30px;
	border-radius: 10px 10px 0 0;
	color: white;
	display: flex;
	align-items: center;
	font-weight: bold;
	justify-content: space-between;
`
const MailingStatus = styled.div`
	text-transform: uppercase;
`

const TitleBar = ({ trackingID, status }) => (
    <Container className="ZoomedMailTrackingMailingTitleContainer">
		<div>Tracking ID: {trackingID}</div>
		<MailingStatus>{status}</MailingStatus>
	</Container>
)

export default TitleBar
