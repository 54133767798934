import React, { useState, useRef, useLayoutEffect } from 'react'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Link } from 'react-router-dom'

function Milestone(data) {
    const targetRef = useRef();
    const [popoverMilestoneID, setPopoverMilestoneID] = useState(-1);
    var totalBarSize = 480; // 480px is fixed width of plotting bar
    const [MilestonesData, setMilestonesData] = useState([]);
    //const [size, setSize] = useState([0, 0]);

    function DateFormater(date) {
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var year = date.getFullYear();

        return month + '/' + day + '/' + year;
    }

    function ParseMilestoneJSON(events) {
        var dateArrays = [];
        events.map((item, key) => {
            let newDate = new Date(item.MilestonesDate);
            dateArrays.push({ "MilestonesDate": newDate, "MilestonesDateString": item.MilestonesDateString, "MilestonesTitle": item.MilestonesTitle, "MilestoneID": item.MilestoneID, "MilestoneStatus": item.MilestoneStatus, "MarginLeft": item.MarginLeft, "CaseID": item.CaseID });
        });
        return dateArrays;
    }

    const ToggleMilstonePopover = (id) => {
        setPopoverMilestoneID(id)
    }

    useLayoutEffect(() => {

        window.addEventListener('resize', DrawMilestones);

        DrawMilestones();

    }, [targetRef.current]);

    const DrawMilestones = () => {

        var arrayData = [];
        if (targetRef.current != undefined) {
            if (window.innerWidth < 768) {
                totalBarSize = targetRef.current.offsetWidth - 12;
                //let total = ((totalBarSize * 25) / 100);
                //totalBarSize = totalBarSize - total;
            }
            else {
                totalBarSize = targetRef.current.offsetWidth - 12;
            }
        }

        ////Format MM/DD/YYYY into string

        data.items.sort(function (a, b) {

            var key1 = new Date(a.deadlineDateOnly);
            var key2 = new Date(b.deadlineDateOnly);

            if (key1 < key2) {
                return -1;
            } else if (key1 === key2) {
                return 0;
            } else {
                return 1;
            }
        });

        if (data.items.length === 0) {
            setMilestonesData([]);
        }
        else {

            let one_day = 1000 * 60 * 60 * 24; // USED TO CALCULATE WIDTH OF PER DAY ON BAR
            let startDate = new Date(data.items[0].deadlineDateOnly);
            let endDate = new Date(data.items[data.items.length - 1].deadlineDateOnly);
            let totalDayDifference = (Math.round(endDate.getTime() - startDate.getTime()) / one_day).toFixed(0);
            let sizePerDay = totalBarSize / totalDayDifference; // BAR SIZE FOR 1 DAY

            let previousDate = startDate;
            let previousMargin = 0;

            data.items.map(x => {

                if (new Date(x.deadlineDateOnly).toDateString() != startDate.toDateString()) {

                    var datDifference = (Math.round(new Date(x.deadlineDateOnly).getTime() - previousDate.getTime()) / one_day).toFixed(0); // GET TOTAL DAY DEFFERENCE BETWEEN PREVIOUS AND CURRENT MILESTONE
                    previousMargin = previousMargin + (datDifference * sizePerDay); // CALCULATE WIDTH BETWEEN PREVIOUS AND CURRENT MILESTONE

                    previousDate = new Date(x.deadlineDateOnly);
                }

                arrayData.push({ "MilestonesDate": x.deadlineDateOnly, "MilestonesDateString": x.formattedDeadlineDateOnly, "MilestonesTitle": x.milestoneTitle, "MilestoneID": x.milestoneID, "MilestoneStatus": x.milestoneStatusID, "MarginLeft": previousMargin, "CaseID": x.caseID });
            });

            setMilestonesData(arrayData);

        }
    }

    let todayDate = new Date();

    if (MilestonesData != null) {

        if (MilestonesData.length > 0) {
            return (
                <div>
                    {(
                        () => {
                            let milestonesArray = ParseMilestoneJSON(MilestonesData);
                            // MilestoneStatus = 3 === COMPLETED MILESTONES
                            let lastDate;
                            let firstDate;
                            if (data.isMilestone === true) {
                                //lastDate = milestonesArray.filter(x => +(x.MilestonesDate) <= +(new Date(data.nextMilestoneDate))).length > 0 ? milestonesArray.filter(x => +(x.MilestonesDate) <= +(new Date(data.nextMilestoneDate))).slice(-1)[0].MilestonesDate : null;
                                lastDate = milestonesArray.filter(x => +(x.MilestonesDate) <= +todayDate).length > 0 ? milestonesArray.filter(x => +(x.MilestonesDate) <= +todayDate).slice(-1)[0].MilestonesDate : null;
                                firstDate = milestonesArray.filter(x => +(x.MilestonesDate) <= +(new Date(data.nextMilestoneDate))).length == 0 ? milestonesArray[0].MilestonesDate : null;

                            } else {
                                lastDate = milestonesArray.filter(x => +(x.MilestonesDate) <= +todayDate).length > 0 ? milestonesArray.filter(x => +(x.MilestonesDate) <= +todayDate).slice(-1)[0].MilestonesDate : null;
                                firstDate = milestonesArray.filter(x => +(x.MilestonesDate) <= +todayDate).length == 0 ? milestonesArray[0].MilestonesDate : null;
                            }


                            let previousMilestoneDate;
                            let previouseMarginRatio = 1.15;
                            return (
                                <div id="dvTimeLine" ref={targetRef}>
                                    {
                                        milestonesArray.map((item) => {
                                            return (
                                                <div key={item.MilestoneID}>
                                                    {(
                                                        () => {

                                                            if (+(previousMilestoneDate) == +(item.MilestonesDate)) {
                                                                item.MarginLeft = item.MarginLeft + (5 * previouseMarginRatio);
                                                                previouseMarginRatio = previouseMarginRatio + 1.15;
                                                            } else {
                                                                previouseMarginRatio = 1.15;
                                                            }

                                                            previousMilestoneDate = item.MilestonesDate;

                                                            if (item.MilestonesDate === lastDate) {
                                                                data.setLastMileStonePosition(item.MarginLeft);
                                                                return (
                                                                    <div className="completed-milestone-bar" style={{ "left": item.MarginLeft + "px", "right": 0, "bottom": -1 }}></div>
                                                                )
                                                            }

                                                            if (item.MilestonesDate === firstDate) {
                                                                return (
                                                                    <div className="completed-milestone-bar" style={{ "left": item.MarginLeft + "px", "right": 0, "bottom": -1 }}></div>
                                                                )
                                                            }
                                                        }
                                                    )()}

                                                    {(
                                                        () => {

                                                            return (
                                                                <Link className="SimplurisMouseRollOver" to={{
                                                                    pathname: `/cases/detail/${item.CaseID}`,
                                                                    state: [{ TabType: "MilestoneDetail" }]
                                                                }}>
                                                                    <div className={`dvTimeLinecircle ${lastDate == item.MilestonesDate ? 'active' : item.MilestonesDate < lastDate ? 'completed' : ''}`}
                                                                        id={"circle" + item.MilestoneID}
                                                                        onMouseOut={() => ToggleMilstonePopover(-1)}
                                                                        data-date={item.MilestonesDate}
                                                                        onMouseOver={() => ToggleMilstonePopover(item.MilestoneID)}
                                                                        style={{ "left": item.MarginLeft + "px" }}
                                                                    ></div>
                                                                </Link>
                                                            )
                                                        }
                                                    )()}


                                                    <Popover placement="top" isOpen={popoverMilestoneID == item.MilestoneID ? true : false} target={"circle" + item.MilestoneID} toggle={() => ToggleMilstonePopover(item.MilestoneID)}>
                                                        <PopoverHeader></PopoverHeader>
                                                        <PopoverBody>
                                                            <div>{item.MilestonesTitle}</div>
                                                            <div>{item.MilestonesDateString}</div>
                                                        </PopoverBody>
                                                    </Popover>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })()}
                </div>
            );
        }
        else {
            return (
                <div>
                </div>
            );
        }
    }
    else {
        return (
            <div>
            </div>
        );
    }

}

export default Milestone