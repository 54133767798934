import { FETCH_CASES_STAGECOUNT } from './actions'

const initialState = {}

export default (
    state = initialState,
    { type, casesStageCount }
) => {
    
    switch (type) {
        case FETCH_CASES_STAGECOUNT:
            
            return casesStageCount;            
        default:
            return state
    }
}
