import React, { useState } from 'react'
import { connect } from 'react-redux'
import Milestone from '../../cases/cases-milestones'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { getPermissionsForCase } from 'state/permissions/selectors'

function CaseMilestones({ userPermissions, ...data }) {
  var milestonePermission
  if (Object.keys(userPermissions).length > 0) {
      milestonePermission = userPermissions.find(
          (permissionCode) => permissionCode == 'LC_MILESTONES_VIEW_IN_MENU'
      )
  }

  const [lastMileStonePosition, setLastMileStonePosition] = useState(-1)

  //Format Month dd,yyyy into string
  function DateFormater(date) {
    var date = new Date(date)
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Augest',
      'September',
      'October',
      'November',
      'December',
    ]
    var day = ('0' + date.getDate()).slice(-2)
    var month = months[date.getMonth()]
    var year = date.getFullYear()

    return month + ' ' + day + ',' + year
  }

  if (milestonePermission && milestonePermission.length <= 0) {
    // TODO: IF USER HAVE NO PERMISSION, THEN EXECUTE THIS PART.
    data.changeCaseSubtab(
      data.previousCaseSubtabs[data.previousCaseSubtabs.length - 2]
    )
    data.setPreviousCaseSubtab(
      data.previousCaseSubtabs[data.previousCaseSubtabs.length - 2]
    )
  }
  return (
    <div>
      {
        <>
          {(() => {
            if (
              data.dataForALLMilestones &&
              data.dataForALLMilestones.length > 0
            ) {
              return (
                <div className="d-flex flex row">
                  <div
                    className="col-md-12 col-xs-12 col-sm-12"
                    data-id={lastMileStonePosition}
                  >
                    <div className="card d-flex flex SimplurisCardTopBorderStatusDisbursement">
                      <div
                        className={`${
                          lastMileStonePosition >= 0
                            ? 'SimplurisCardprogressBG'
                            : ''
                        }`}
                        style={{ width: +(lastMileStonePosition + 28) + 'px' }}
                      ></div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-8 col-md-7 col-xs-12 col-sm-12 px-0">
                            <div className="list list-row block animates animates-fadeIn shadow-none">
                              <div className="list-item">
                                <div></div>
                                <div className="flex">
                                  <a href={null}>
                                    <h4 className="SimplurisStatusBoxesTitle">
                                      {data.dataForWeeklyMilestones &&
                                      data.dataForWeeklyMilestones.length > 0
                                        ? 'Next Milestone: ' +
                                          data.dataForWeeklyMilestones[0]
                                            .milestoneTitle
                                       : (data.nextRemainingMilestones && data.nextRemainingMilestones.length > 0 ? 'Milestones' : 'All Milestones Completed')}
                                    </h4>
                                  </a>
                                  {data.dataForWeeklyMilestones &&
                                  data.dataForWeeklyMilestones.length > 0 ? (
                                    <label className="font-weight-500 SimplurisFontSize14 SimplurislblModalityPrimary">
                                      <span className="mr-1 SimplurisModalityPrimaryStatus"></span>
                                      {DateFormater(
                                        data.dataForWeeklyMilestones[0]
                                          .deadlineDateOnly
                                      )}
                                    </label>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-xs-12 col-sm-12 pr-0">
                            <Milestone
                              items={data.dataForALLMilestones}
                              setLastMileStonePosition={
                                setLastMileStonePosition
                              }
                              isMilestone={true}
                              nextMilestoneDate={
                                data.dataForWeeklyMilestones &&
                                data.dataForWeeklyMilestones.length > 0
                                  ? data.dataForWeeklyMilestones[0]
                                      .deadlineDateOnly
                                  : new Date()
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })()}

          <div className="d-flex flex row">
            <div className="col-md-12">
              <div className="card d-flex flex">
                <div className="card-body">
                  <div className="row mt-3">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <span className="SimplurisSubTabTitle">
                        Next Milestone
                      </span>
                    </div>
                    <CurrentWeekMilestonesData
                      items={data.dataForWeeklyMilestones}
                    />
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <span className="SimplurisSubTabTitle">
                        All Milestones
                      </span>
                    </div>
                    {(() => {
                      return (
                        <AllMilestonesData items={data.dataForALLMilestones} />
                      )
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default connect(
  (state, props) => ({
    userPermissions: getPermissionsForCase(state, props),
    previousCaseSubtabs: state.previousSubTab.subTabNames,
  }),
  {
    changeCaseSubtab,
    setPreviousCaseSubtab,
  }
)(CaseMilestones)

function CurrentWeekMilestonesData(data) {
  if (data.items == null || data.items.length <= 0) {
    return (
      <div className="col-md-12">
        <div className="text-center mb-2 mt-3">
          No milestone available in the next 30 days
        </div>
      </div>
    )
  } else {
    var CurrentWeekMilestoneData = data.items.sort(function(a, b) {
      var key1 = new Date(a.deadlineDateOnly)
      var key2 = new Date(b.deadlineDateOnly)

      if (key1 < key2) {
        return -1
      } else if (key1 == key2) {
        return 0
      } else {
        return 1
      }
    })

    return (
      <>
        {CurrentWeekMilestoneData.map(item => (
          <div className="col-md-12 col-sm-12 col-xs-12 mt-3">
            <div className="row">
                        <div className="pl-3 col-lg-2 col-md-3 col-sm-12 col-xs-12 SimplurisMilestonesDateBox">
                <div className="SimplurisCaseMilstonesDateGridSqure r avatar bg-primary-lt m-auto">
                  <div className="text-center">
                    <label className="d-block SimplurisCaseMilstonesDateLableGridSqure mb-0">
                      {new Intl.DateTimeFormat('en-GB', {
                        day: '2-digit',
                      }).format(new Date(item.deadlineDateOnly))}
                    </label>
                    <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                      {new Intl.DateTimeFormat('en-GB', {
                        month: 'long',
                      }).format(new Date(item.deadlineDateOnly))}
                    </label>
                    <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                      {new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                      }).format(new Date(item.deadlineDateOnly))}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-9 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0 pl-lg-0">
                <div className="SimplurisMilestoneLables SimplurisFontSize14">
                  {item.milestoneTitle}
                </div>
                <div className="SimplurisMilestoneGridDescription">
                  {(() => {
                    if (item.description != null) {
                      return <p>{item.description}</p>
                    } else {
                      return <p></p>
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}

function AllMilestonesData(data) {
  if (data.items == null || data.items.length <= 0) {
    return (
      <div className="col-md-12">
        <div className="text-center mb-2">No milestone available</div>
      </div>
    )
  } else {
    let lastCompletedMilestoneDate
    let todayDate = new Date()

    var lastCompletedMilestones = data.items.filter(
      x => +new Date(x.deadlineDate) <= +todayDate
    )
    if (lastCompletedMilestones.length > 0) {
      lastCompletedMilestoneDate =
        lastCompletedMilestones[lastCompletedMilestones.length - 1].deadlineDate
    }
    var milestonesForLoop = data.items
    return (
      <>
        {milestonesForLoop.reverse().map((item, i) => (
          // Key should eventually be `milestoneID` on the milestone but right now the API returns id `0` for each milestone
          <div className="col-md-12 col-sm-12 col-xs-12 mt-3" key={i}>
            <div className="row">
                        <div className="pl-3 col-lg-2 col-md-3 col-sm-12 col-xs-12 SimplurisMilestonesDateBox">
                <span
                  className={`SimplurisCaseMilstonesDateGridSqure ${
                    new Date(lastCompletedMilestoneDate) >=
                    new Date(item.deadlineDate)
                      ? 'active'
                      : ''
                  } r avatar bg-primary-lt m-auto`}
                >
                  <div className="text-center">
                    <label
                      className={`d-block SimplurisCaseMilstonesDateLableGridSqure  ${
                        new Date(lastCompletedMilestoneDate) >=
                        new Date(item.deadlineDate)
                          ? 'active'
                          : ''
                      } mb-0`}
                    >
                      {new Intl.DateTimeFormat('en-GB', {
                        day: '2-digit',
                      }).format(new Date(item.deadlineDateOnly))}
                    </label>
                    <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                      {new Intl.DateTimeFormat('en-GB', {
                        month: 'long',
                      }).format(new Date(item.deadlineDateOnly))}
                    </label>
                    <label className="SimplurisCaseMilstonesMonthLableGridSqure d-block mb-1">
                      {new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                      }).format(new Date(item.deadlineDateOnly))}
                    </label>
                  </div>
                </span>
              </div>
              <div className="col-lg-8 col-md-9 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0 pl-lg-0">
                <div className="SimplurisMilestoneLables SimplurisFontSize14">
                  {item.milestoneTitle}
                </div>
                <div className="SimplurisMilestoneGridDescription">
                  {(() => {
                    if (item.description != null) {
                      return <p>{item.description}</p>
                    } else {
                      return <p></p>
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}
