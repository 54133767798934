import React, { useState } from 'react';
import { connect } from 'react-redux'

import { changeCaseStageFilter } from 'state/my-cases-view/actions'
import SideMenuFakeLoader from '../../components/sidemenu-fake-loader';
import { getCasesCountForStage } from 'state/case-stage-filter-count/helper'
import RecentlyViewed from './recently-viewed'
import FavoriteCases from './favorite-cases'

function CaseSideBar({ data,changeCaseStageFilter, caseStageFilter }) {
    
    const [allCasesHighLightMenu, setAllCases] = useState(['0']);
    const [pendingHighLightMenu, setPending] = useState(['3,12']);
    const [preliminarilyApprovedHighLightMenu, setPreliminarilyApproved] = useState(['4,13']);
    const [pendingFinalApprovalHighLightMenu, setPendingFinalApproval] = useState(['6,15']);
    const [delayedHighLightMenu, setDelayed] = useState(['22,23,24']);
    const [noticingHighLightMenu, setNoticing] = useState(['5,14']);
    const [pendingDistributionHighLightMenu, setPendingDistribution] = useState(['8,17']);
    const [distributionCompleteHighLightMenu, setDistributionComplete] = useState(['10,19']);
    const [pendingAdditionalDistributionHighLightMenu, setPendingAdditionalDistribution] = useState(['21']);
    const [pendingConfirmationOfFinalApprovalHighLightMenu, setPendingConfirmationOfFinalApproval] = useState(['7,16']);
    const [appealsPeriodHighLightMenu, setAppealsPeriod] = useState(['9,18']);
    const [noneHighLightMenu, setNone] = useState(['1,2']);
    const [closedHighLightMenu, setClosed] = useState(['20,11']);
    const [otherHighLightMenu, setOther] = useState(['99']);

    var pendingCasesCount = getCasesCountForStage({ data, stage: pendingHighLightMenu });
    var preliminarilyApprovedCasesCount = getCasesCountForStage({ data, stage: preliminarilyApprovedHighLightMenu });
    var pendingFinalApprovalCasesCount = getCasesCountForStage({ data, stage: pendingFinalApprovalHighLightMenu });
    var delayedCasesCount = getCasesCountForStage({ data, stage: delayedHighLightMenu });
    var noticingCasesCount = getCasesCountForStage({ data, stage: noticingHighLightMenu });
    var pendingDistributionCasesCount = getCasesCountForStage({ data, stage: pendingDistributionHighLightMenu });
    var distributionCompleteCasesCount = getCasesCountForStage({ data, stage: distributionCompleteHighLightMenu });
    var pendingAdditionalDistributionCasesCount = getCasesCountForStage({ data, stage: pendingAdditionalDistributionHighLightMenu });
    var pendingConfirmationOfFinalApprovalCasesCount = getCasesCountForStage({ data, stage: pendingConfirmationOfFinalApprovalHighLightMenu });
    var appealsPeriodCasesCount = getCasesCountForStage({ data, stage: appealsPeriodHighLightMenu });
    var noneCasesCount = getCasesCountForStage({ data, stage: noneHighLightMenu });
    var closedCasesCount = getCasesCountForStage({ data, stage: closedHighLightMenu });
    var otherCasesCount = getCasesCountForStage({ data, stage: otherHighLightMenu });

    const allCasesCount = Object.keys(data).length > 0 ? data.reduce(function (p, c) {
        return p + c.caseCount;
    }, 0) : 0;

    return (
        <div id="aside" className="page-sidenav no-shrink  nav-expand" aria-hidden="true">
            <div className="sidenav h-100 modal-dialog SimplurisDashboardBackgroudColour SimplurisAllCasePagesSideBarDiv position-relative">
                <a href="#" className="SimplurisCloseMenu"> <i className="i-con i-con-close position-absolute d-block d-lg-none d-xl-none SimplurisCloseIconSideBar"></i> </a>
                <div className="flex scrollable hover SimplurisPaddingTop83">
                    <div className="nav-border b-primary" data-nav>
                        <ul className="nav bg SimplurisDashboardBackgroudColour">
                            {(
                                () => {
                                    if (!Array.isArray(data)) {
                                        return (
                                            <SideMenuFakeLoader />
                                        )
                                    }
                                    else {
                                        return (<>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${allCasesHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter(0)} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>All Cases</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill SimplurisSidebarBadge">{allCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${pendingHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('3,12')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Pending</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{pendingCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${preliminarilyApprovedHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('4,13')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Preliminarily Approved</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{preliminarilyApprovedCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${pendingFinalApprovalHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('6,15')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Pending Final Approval</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{pendingFinalApprovalCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>

                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${delayedHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('22,23,24')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Delayed</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{delayedCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${noticingHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('5,14')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Noticing</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill SimplurisSidebarBadge">{noticingCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${pendingDistributionHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('8,17')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Pending Disbursement</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{pendingDistributionCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${distributionCompleteHighLightMenu.indexOf(caseStageFilter.toString()) != -1  ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('10,19')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Disbursement</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{distributionCompleteCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${pendingAdditionalDistributionHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('21')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition" title="Pending Addtl Disbursement">
                                                    <span>Pending Addtl Disburse...</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{pendingAdditionalDistributionCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${pendingConfirmationOfFinalApprovalHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('7,16')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition" title="Pending Conf Final Approval">
                                                    <span>Pending Conf Final App...</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{pendingConfirmationOfFinalApprovalCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${appealsPeriodHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('9,18')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Appeals Period</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{appealsPeriodCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${noneHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('1,2')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>None</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{noneCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${closedHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}`}>
                                                <a onClick={() => changeCaseStageFilter('20,11')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Closed</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{closedCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className={`nav-header SimplurisMouseRollOver hidden-folded pt-0 ${otherHighLightMenu.indexOf(caseStageFilter.toString()) != -1 ? 'active' : ''}
                                                               ${otherCasesCount > 0 ? 'd-block' : 'd-none'}`}>
                                                <a onClick={() => changeCaseStageFilter('99')} className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                    <span>Other</span>
                                                    <span className="nav-badge SimplurisSidebarInfo">
                                                        <b className="badge badge-sm badge-pill bg-secondary-lt SimplurisSidebarBadge">{otherCasesCount}</b>
                                                    </span>
                                                </a>
                                            </li>
                                        </>)
                                    }
                                }
                            )()}

                        </ul>
                    </div>
                    <FavoriteCases />
                    <RecentlyViewed />
                </div>
            </div>
        </div>
    );

}

export default connect(({ cases, myCasesView}) => ({
    caseStageFilter: myCasesView.caseStageFilter,
}), { changeCaseStageFilter })(CaseSideBar)