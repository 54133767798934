import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ReportColorImage from '../../../../../static/img/report_inverse.png'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import { fetchCaseReportsData } from 'state/case-report/actions'
import { SectionSpinner } from 'react/components/spinners'

function ReportsList({ caseID, caseReportsData, getReportsDetailComponent, getReportsCount }) {

    getReportsCount(caseReportsData != undefined && caseReportsData != null ? caseReportsData.length : 0);
    useEffect(() => {

    }, []);

    if (!caseReportsData) {
        return <div className="row"> <SectionSpinner /></div>
    }

    if (caseReportsData.length <= 0) {
        return (
            <div className="d-flex flex row">
                <div className="col-md-12 mb-2 SimplurisCursorPointer">
                    <div className="card d-flex flex py-3">
                        <div className="col-md-12 text-center">
                            no data found!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex flex row">
            {
                caseReportsData.map((item, i) =>
                    <React.Fragment key={i}>
                        <CaseReportBox
                            items={item}
                            caseID={caseID}
                            getReportsDetailComponent={getReportsDetailComponent}
                        />
                    </React.Fragment>
                )
            }
        </div>
    )
}

export default connect(
    (state, props) => ({
        caseReportsData: state.caseReports[props.caseID],
    }),
    {
        fetchCaseReportsData,
    }
)(ReportsList)


function CaseReportBox({ items, caseID, getReportsDetailComponent }) {
    
    if (items != null) {
        return (
            <>
                <div className="col-md-4 mb-2">
                    <div className="card d-flex flex py-3">
                        <div className="col-md-12">
                            <img src={ReportColorImage} className="SimplurisReportTypeIcon" />
                            <a href="javascript:void(0)" className="text-secondary">
                                <div className="float-right">
                                    <div className="item-action dropdown simplris-detail-dropdown">
                                        <a href="#" data-toggle="dropdown" className="text-muted">
                                            <i className="i-con i-con-more-h w-32 "><i></i></i></a>
                                        <div className="dropdown-menu dropdown-menu-right bg-dark" role="menu">
                                            <a className="dropdown-item" onClick={() => getReportsDetailComponent(items.caseID, items.reportID, items.url, items.title, items.createdDate)} >
                                                View
                                            </a>
                                            <a className="dropdown-item" href={items.url}
                                                target="_blank"
                                                download >
                                                Download
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </a>

                        </div>

                        <div className="col-12 mt-1">
                            <div className="SimplurisReportBody">
                                <div className="SimplurisReportTitle">{items.title}</div>
                                <div className="SimplurisReportSubTitle ">{items.reportType}</div>
                                <p className="SimplurisReportContent SimplurisFontSize12">
                                    {items.description}
                                </p>

                                <p className="text-muted m-0 SimplurisReportDate">Date Posted:
                                {/*{new Intl.DateTimeFormat(undefined, {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'                                    
                                }).format(new Date(items.createdDate))}*/}
                                    {items.reportListDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}