import * as React from 'react'
import styled, { keyframes } from 'styled-components'

const SpinnerSvg = styled.svg`
	width: ${props => props.size}px;
	opacity: 0.8;
	overflow: visible;
`
const rotateFrame = keyframes`
  to {
    transform: rotate(270deg);
  }
`
const drawFrame = keyframes`
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -1320.5;
  }
`
const Frame = styled.polygon`
	animation: ${rotateFrame} 2s ease-in-out both infinite;
	transform-origin: 50%;
`
const FrameMask = styled.path`
	stroke-width: 40;
	stroke-miterlimit: 10;
	animation: ${drawFrame} 2s cubic-bezier(0.77, 0, 0.175, 1) both infinite;
	stroke-dasharray: 1320.5;
	stroke-dashoffset: 1320.5;
`

const LogoSpinner = ({ size = 50, fill = '#4a90e2' }) => {
	return (
		<SpinnerSvg id="logo-loader" viewBox="0 0 380 381" size={size} fill={fill}>
			<defs>
				<mask
					id="mask"
					x="-2.54"
					y="-2.58"
					width="385.91"
					height="386.77"
					maskUnits="userSpaceOnUse"
				>
					<FrameMask
						d="M153.52,364.19H17.46V17.42h345.9V364.19h-145"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="40"
					/>
				</mask>
			</defs>
			<path
				id="letter-s"
				d="M251.48,161.6a162,162,0,0,0-26.57-12.81A71.49,71.49,0,0,0,200.37,144q-7.77,0-12.06,3t-4.29,7.4q0,6.83,8.58,10.61t20.45,8.21q7.77,2.79,15.94,6.6a63.65,63.65,0,0,1,14.73,9.4,49.07,49.07,0,0,1,10.83,13.2c2.86,5.08,4.29,11.2,4.29,18.4q0,28.42-20.85,44.22t-54.39,15.8A144.06,144.06,0,0,1,138,273.44q-22.31-7.4-37.42-18.21l25.76-40.81a150.87,150.87,0,0,0,29.24,16.2q15.74,6.6,28,6.61c6.28,0,10.7-1.07,13.3-3.21a10.37,10.37,0,0,0,3.88-8.4q0-6.8-8.79-10.41t-20.24-8q-7.37-2.81-15.13-6.41a61.32,61.32,0,0,1-14.1-9,41.88,41.88,0,0,1-10.23-13q-3.89-7.6-3.88-18.41a54.36,54.36,0,0,1,4.91-22.8,56.63,56.63,0,0,1,14.1-19,68.86,68.86,0,0,1,22.29-13,85,85,0,0,1,29.44-4.8q23.29,0,40.48,5A168.32,168.32,0,0,1,273.56,120L251.48,161.6"
				fillRule="evenodd"
			/>
			<g>
				<Frame
					mask="url(#mask)"
					points="34.09 347.51 153.52 347.51 147.01 380.87 0.82 380.87 0.82 0.75 380 0.75 380 380.87 218.37 380.87 224.86 347.51 346.73 347.51 346.73 34.1 34.09 34.1 34.09 347.51"
				/>
			</g>
		</SpinnerSvg>
	)
}

export default LogoSpinner
