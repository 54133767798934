import _ from 'lodash'

export const start = action => `${action}_START`
export const resolved = action => `${action}_RESOLVED`
export const rejected = action => `${action}_REJECTED`

export const caseIDFromPropsSelector = (state, { caseID, match }) => {
  if (caseID) {
    return Number(caseID)
  }

  const caseIDFromURL = _.get(match, 'params.caseID')
  return caseIDFromURL ? Number(caseIDFromURL) : null
}
