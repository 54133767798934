import { createSelector } from 'reselect'

import { caseIDFromPropsSelector } from 'state/helpers'

export const stateSelector = state => state.cases.caseData

export const getAllCases = createSelector([stateSelector], state => state)

export const getSelectedCase = createSelector(    
  [stateSelector, caseIDFromPropsSelector],
  (state, caseID) => state[caseID]
)
