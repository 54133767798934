import { simplurisFetch } from 'helpers'
export const FETCH_CASE_CALL_CENTER = 'FETCH_CASE_CALL_CENTER'
export const CHANGE_CASE_CALL_CENTER_DATE_RANGE = 'CHANGE_CASE_CALL_CENTER_DATE_RANGE'

export const fetchCallCenterData = ({ caseID }) => async dispatch => {
    try {
        
        const url = `cases/${caseID}/null/null/getcallsforcase`
        const { resultObject } = await simplurisFetch({ url })
        
        dispatch({
            type: FETCH_CASE_CALL_CENTER,
            caseCallCenter: resultObject,
            caseID,
        })
        
    } catch (e) {
        console.error('Error in call center support overview', e, e.errorObject)

        dispatch({
            type: FETCH_CASE_CALL_CENTER,
            caseCallCenter: [],
            caseID,
        })
    }
}

export const changeCallCenterDateRange = dateRange => ({
    type: CHANGE_CASE_CALL_CENTER_DATE_RANGE,
    dateRange,
})
