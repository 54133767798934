import React, { useState, useEffect, useRef } from 'react'
import { SectionSpinner } from 'react/components/spinners'
import ButtonLoader from '../../components/button-loader';
import LockIcon from '../../../static/img/lock.png'
import ChangeRequestModalPopup from 'react/components/change-request'
import { FormatPhoneNumberForUS, IsNullOrEmptyString, AnlyticsPageLog, FormatFaxNumberForUS } from '../../../global'
import { simplurisFetch } from 'helpers'
import { appMode } from 'api-config';

function ProfilePage(props) {
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    let [fullName, setFullName] = useState('');
    let [firstName, setFirstName] = useState('');
    let [lastName, setLastName] = useState('');
    let [title, setTitle] = useState('');
    let [email, setEmail] = useState('');
    let [phoneNumber1, setPhoneNumber1] = useState('');
    let [phoneNumber2, setPhoneNumber2] = useState('');
    let [personFax, setPersonFax] = useState('');
    let [profilePicURL, setProfilePicURL] = useState('');
    let [schemaName, setSchemaName] = useState('');
    let [companyName, setCompanyName] = useState('');
    const [personID, setPersonID] = useState(localStorage.getItem(global.PersonID) || '');
    const [showPageLoaderForUpdateTeamMeberDetail, setShowPageLoaderForUpdateTeamMeberDetail] = useState(false);
    const [showChangeRequestModalPopup, setShowChangeRequestModalPopup] = useState(false);
    const [AttributesValue, setAttributesValue] = useState('');
    const [personMapSettings, setPersonMapSettings] = useState([]);
    let [phoneCellNumber, setCellPhoneNumber] = useState('');
    let [phoneOfficeNumber, setOfficePhoneNumber] = useState('');;

    const [curpwd, setCurpwd] = useState('');
    const [newpwd, setNewpwd] = useState('');
    const [confpwd, setConfpwd] = useState('');
    const [curpwdError, setCurpwdError] = useState(false);
    const [newpwdError, setNewpwdError] = useState(false);
    const [confpwdError, setConfpwdError] = useState(false);
    //const [errorMessage, setErrorMessage] = useState('');
    //const [successMessage, setSuccessMessage] = useState('');
    const [strongPasswordError, setStrongPasswordError] = useState(false);
    const [confirmMatchPasswordError, setConfirmMatchPasswordError] = useState(false);
    const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState('')
    const [changePasswordSuccessMessage, setChangePasswordSuccessMessage] = useState('')
    const [showButtonLoader, setShowButtonLoader] = useState(false);

    const fetchPersonProfileDetails = async (personID) => {
        setShowPageLoaderForUpdateTeamMeberDetail(true);
        setFullName('');
        setFirstName('');
        setLastName('');
        setTitle('');
        setEmail('');
        setPhoneNumber1('');
        setPhoneNumber2('');
        setPersonFax('');
        setProfilePicURL('');
        setSchemaName('');
        setCompanyName('');
        setPersonMapSettings('');
         
        simplurisFetch({
            url: "auth/getpersonprofiledetail"
        }).then((data) => {          
            setShowPageLoaderForUpdateTeamMeberDetail(false);
            if (data.name == undefined && data.name != "FetchError") {                
                if (data.resultCode == "SUCCESS") {                        
                    setFullName((!IsNullOrEmptyString(data.resultObject.firstName) ? data.resultObject.firstName : '') + " " + (!IsNullOrEmptyString(data.resultObject.lastName) ? data.resultObject.lastName : ''));
                    setFirstName((!IsNullOrEmptyString(data.resultObject.firstName) ? data.resultObject.firstName : ''));
                    setLastName((!IsNullOrEmptyString(data.resultObject.lastName) ? data.resultObject.lastName : ''));
                    setTitle((!IsNullOrEmptyString(data.resultObject.title) ? data.resultObject.title : ''));
                    setEmail((!IsNullOrEmptyString(data.resultObject.email) ? data.resultObject.email : ''));
                    setPhoneNumber1((!IsNullOrEmptyString(data.resultObject.phoneNumber) ? data.resultObject.phoneNumber : ''));
                    setPhoneNumber2((!IsNullOrEmptyString(data.resultObject.phoneNumber2) ? data.resultObject.phoneNumber2 : ''));
                    setPersonFax((!IsNullOrEmptyString(data.resultObject.personFax) ? data.resultObject.personFax : ''));
                    setProfilePicURL((!IsNullOrEmptyString(data.resultObject.profilePicURL) ? data.resultObject.profilePicURL : ''));
                    setSchemaName((!IsNullOrEmptyString(data.resultObject.schemaName) ? data.resultObject.schemaName : ''));
                    setCompanyName((!IsNullOrEmptyString(data.resultObject.businessName) ? data.resultObject.businessName : ''));
                    setPersonMapSettings(data.resultObject.personMapSettings);

                    localStorage.removeItem(global.FirstName);
                    localStorage.removeItem(global.LastName);
                    if (appMode != "sandbox") {
                        localStorage.removeItem(global.ProfilePicURL);
                    }
                    
                    localStorage.removeItem(global.Email);
                    localStorage.removeItem(global.PhoneNumber);
                    localStorage.removeItem(global.PhoneNumber2);
                    localStorage.removeItem(global.PersonFax);
                    localStorage.removeItem(global.Title);
                    localStorage.removeItem(global.CompanyName);

                    localStorage.setItem(global.FirstName, (!IsNullOrEmptyString(data.resultObject.firstName) ? data.resultObject.firstName : ''));
                    localStorage.setItem(global.LastName, (!IsNullOrEmptyString(data.resultObject.lastName) ? data.resultObject.lastName : ''));
                    if (appMode != "sandbox") {
                        localStorage.setItem(global.ProfilePicURL, (!IsNullOrEmptyString(data.resultObject.profilePicURL) ? data.resultObject.profilePicURL : ''));
                    }
                    localStorage.setItem(global.Email, (!IsNullOrEmptyString(data.resultObject.email) ? data.resultObject.email : ''));
                    localStorage.setItem(global.PhoneNumber, (!IsNullOrEmptyString(data.resultObject.phoneNumber) ? data.resultObject.phoneNumber : ''));
                    localStorage.setItem(global.PhoneNumber2, (!IsNullOrEmptyString(data.resultObject.phoneNumber2) ? data.resultObject.phoneNumber2 : ''));
                    localStorage.setItem(global.PersonFax, (!IsNullOrEmptyString(data.resultObject.personFax) ? data.resultObject.personFax : ''));
                    localStorage.setItem(global.Title, (!IsNullOrEmptyString(data.resultObject.title) ? data.resultObject.title : ''));
                    localStorage.setItem(global.CompanyName, (!IsNullOrEmptyString(data.resultObject.businessName) ? data.resultObject.businessName : ''));
                    props.setFullName((!IsNullOrEmptyString(data.resultObject.firstName) ? data.resultObject.firstName : '') + " " + (!IsNullOrEmptyString(data.resultObject.lastName) ? data.resultObject.lastName : ''));
                    props.setFirstName((!IsNullOrEmptyString(data.resultObject.firstName) ? data.resultObject.firstName : ''));
                    props.setLastName((!IsNullOrEmptyString(data.resultObject.lastName) ? data.resultObject.lastName : ''));
                    props.setTitle((!IsNullOrEmptyString(data.resultObject.title) ? data.resultObject.title : ''));
                    props.setProfilePicURL((!IsNullOrEmptyString(data.resultObject.profilePicURL) ? data.resultObject.profilePicURL : ''));
                    setShowPageLoader(false);            
                }
            }            
            }).catch(e => {
                console.error('Error in get person profile detail', e, e.errorObject);
                setShowPageLoader(false);
                setShowPageLoaderForUpdateTeamMeberDetail(false);
            });       
    };

    useEffect(() => {
        setShowPageLoader(true);
        fetchPersonProfileDetails(personID);
        AnlyticsPageLog("Profile page");
    }, []);


    const SaveChangePasswordClick = () => {              
        setChangePasswordErrorMessage('');
        setChangePasswordSuccessMessage('');

        //either const or var is ok, but const doesn't let you reassign it in memory
        setCurpwd(curpwd);
        setNewpwd(newpwd);
        setConfpwd(confpwd);

        const isStrongPassword = global.StrongPasswordRegex.test(newpwd)
        
        //! covers all possible nulls/empty strings
        if (!curpwd || !newpwd || !confpwd || !isStrongPassword) {

            if (!curpwd) {
                setCurpwdError(true);
            }

            if (!newpwd) {
                setNewpwdError(true);
            }

            if (!confpwd) {
                setConfpwdError(true);
            }

            if (newpwd && !isStrongPassword) {
                setStrongPasswordError(true);
            }

            return;
        }

        if (newpwd != confpwd) {
            setConfirmMatchPasswordError(true);
            return;
        }
        
        setShowButtonLoader(true);
        var data = {};
        data.OldPassword = curpwd;
        data.NewPassword = confpwd;
        
        simplurisFetch({
            url: "auth/changepassword", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {            
            setShowButtonLoader(false)
            if (data.name == undefined && data.name != "FetchError") {
                if (data.resultCode == "SUCCESS") {                    
                    setCurpwd('');
                    setNewpwd('');
                    setConfpwd('');
                    setConfirmMatchPasswordError(false);
                    setChangePasswordSuccessMessage("Password changed.");
                }                
            }            
            }).catch(e => {
                setCurpwd('');
                setNewpwd('');
                setConfpwd('');
                console.error('Error in chang password', e, e.errorObject);
                setShowButtonLoader(false)
                setChangePasswordErrorMessage(e.errorObject.resultMessage);
            });               
    };

    function OpenChangeRequestModalPopup(data, tableName, tableColumn, involvedRecordID) {
        setAttributesValue({ data, tableName, tableColumn, involvedRecordID });
        setShowChangeRequestModalPopup(true);
    }

    const UpdateTeamMeberDetailByPersonID = () => {  
        setSuccessMessage('');
        setErrorMessage('');
        var data = {};
        data.PlatformPersonID = parseInt(personID);
        data.BusinessName = companyName;
        data.FirstName = firstName;
        data.LastName = lastName;
        data.Title = title;
        data.Email = email;
        data.PhoneNumber1 = phoneNumber1;
        data.PhoneNumber2 = phoneNumber2;
        data.PersonFax = personFax;        
        setShowButtonLoader(true);        
        simplurisFetch({
            url: "auth/updatepersonprofiledetails", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {       
            setShowButtonLoader(false);            
            if (data.name != undefined && data.name == "FetchError") {                
                if (data.errorObject.resultCode == "ERROR") {                    
                    setErrorMessage("Something went wrong.");
                }                
            }
            else {         
                setSuccessMessage("Updated successfully");
                setTimeout(function () {
                    fetchPersonProfileDetails(personID);   
                },100)
                
            }
            }).catch(e => {
                setShowButtonLoader(false);
                console.error('Error in update person profile details', e, e.errorObject);                
                if (e.errorObject.resultCode == "ERROR") {
                    setErrorMessage("Something went wrong.");
                }   
            });               
    };

  

    const savePersonNotifications = (personID, value, personMapSettingID, settingTypeID) => { 
        var data = {};
        data.PersonID = parseInt(personID);
        data.SettingValue = value.toString();
        data.PersonMapSettingID = personMapSettingID;
        data.SettingTypeID = settingTypeID;
        //setShowPageLoaderForUpdateTeamMeberDetail(true);    

        simplurisFetch({
            url: "auth/updatepersonnotifications", method: 'POST', body: JSON.stringify(data)
        }).then((data) => {  
            if (data.name != undefined && data.name == "FetchError") {                
                if (data.errorObject.resultCode == "ERROR") {                    
                    setErrorMessage("Something went wrong.");
                }
            }
            else {                                
                if (personMapSettings.length > 0) {
                    personMapSettings.filter(p => {
                        if (p.platformPersonID == personID &&
                            p.personMapSettingID == personMapSettingID &&
                            p.settingTypeID == settingTypeID) {
                            return p.settingValue = value;
                        }
                    });
                }
                setPersonMapSettings(personMapSettings);
            }
        }).catch(e => {
                console.error('Error in update person notification', e, e.errorObject);
                setShowPageLoader(false);
                if (e.errorObject.resultCode == "ERROR") {
                    setErrorMessage("Something went wrong.");
                }
            });               
    }


    let USER_REPORT_WEEKLY_TEXT;
    let USER_REPORT_WEEKLY_EMAIL;
    let USER_CHATS_TEXT_ALL;
    let USER_CHATS_EMAIL_ALL;
    let USER_CHATS_TEXT_FIRST;
    let USER_CHATS_EMAIL_FIRST;
    let USER_MESSAGES_TEXT_ALL;
    let USER_MESSAGES_EMAIL_ALL;
    let USER_MESSAGES_TEXT_FIRST;
    let USER_MESSAGES_EMAIL_FIRST;
    
    if (personMapSettings.length > 0) {        
        USER_REPORT_WEEKLY_TEXT = personMapSettings.filter(p => {
            return p.settingTypeCode == 'REPORT_WEEKLY_TEXT'
        });

        USER_REPORT_WEEKLY_EMAIL = personMapSettings.filter(p => {
            return p.settingTypeCode == 'REPORT_WEEKLY_EMAIL'
        });        
        USER_CHATS_TEXT_ALL = personMapSettings.filter(p => {
            return p.settingTypeCode == 'CHATS_TO_TEXT_ALL'
        });

        USER_CHATS_EMAIL_ALL = personMapSettings.filter(p => {
            return p.settingTypeCode == 'CHATS_TO_EMAIL_ALL'
        });        
        USER_CHATS_TEXT_FIRST = personMapSettings.filter(p => {
            return p.settingTypeCode == 'CHATS_TO_TEXT_FIRST'
        });

        USER_CHATS_EMAIL_FIRST = personMapSettings.filter(p => {
            return p.settingTypeCode == 'CHATS_TO_EMAIL_FIRST'
        });        
        USER_MESSAGES_TEXT_ALL = personMapSettings.filter(p => {
            return p.settingTypeCode == 'MESSAGES_TO_TEXT_ALL'
        });

        USER_MESSAGES_EMAIL_ALL = personMapSettings.filter(p => {
            return p.settingTypeCode == 'MESSAGES_TO_EMAIL_ALL'
        });        
        USER_MESSAGES_TEXT_FIRST = personMapSettings.filter(p => {
            return p.settingTypeCode == 'MESSAGES_TO_TEXT_FIRST'
        });

        USER_MESSAGES_EMAIL_FIRST = personMapSettings.filter(p => {
            return p.settingTypeCode == 'MESSAGES_TO_EMAIL_FIRST'
        });
    }    
    let weeklyTextPermission;
    let weeklyEmailPermission;
    let chatTextPermission;
    let chatEmailPermission;
    let chatTextFirstPermission;
    let chatEmailFirstPermission;
    let messageTextPermission;
    let messageEmailPermission;
    let messageTextFirstPermission;
    let messageEmailFirstPermission;    
    if (personMapSettings.length > 0) {
        weeklyTextPermission = USER_REPORT_WEEKLY_TEXT[0].settingValue == "1" ? true : false;
        weeklyEmailPermission = USER_REPORT_WEEKLY_EMAIL[0].settingValue == "1" ? true : false;
        chatTextPermission = USER_CHATS_TEXT_ALL[0].settingValue == "1" ? true : false;
        chatEmailPermission = USER_CHATS_EMAIL_ALL[0].settingValue == "1" ? true : false;
        chatTextFirstPermission = USER_CHATS_TEXT_FIRST[0].settingValue == "1" ? true : false;
        chatEmailFirstPermission = USER_CHATS_EMAIL_FIRST[0].settingValue == "1" ? true : false;
        messageTextPermission = USER_MESSAGES_TEXT_ALL[0].settingValue == "1" ? true : false;
        messageEmailPermission = USER_MESSAGES_EMAIL_ALL[0].settingValue == "1" ? true : false;
        messageTextFirstPermission = USER_MESSAGES_TEXT_FIRST[0].settingValue == "1" ? true : false;
        messageEmailFirstPermission = USER_MESSAGES_EMAIL_FIRST[0].settingValue == "1" ? true : false;
    }

    const HandleWeeklyTextPermissionChange = (value, personMapSettingID, settingTypeID) => {                
        
        weeklyTextPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleWeeklyEmailPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        weeklyEmailPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleChatTextPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        chatTextPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleChatEmailPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        chatEmailPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleChatTextFirstPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        chatTextFirstPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleChatEmailFirstPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        chatEmailFirstPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleMessageTextPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        messageTextPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleMessageEmailPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        messageEmailPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleMessageTextFirstPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        messageTextFirstPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }
    const HandleMessageEmailFirstPermissionChange = (value, personMapSettingID, settingTypeID) => {
        
        messageEmailFirstPermission = value;
        savePersonNotifications(personID, value, personMapSettingID, settingTypeID);
    }

    return (
        <div>

            <div className="SimplurisCaseDetailsLoaderSection SimplurisProfileArea">
                {showPageLoader ? <div className="row"> <SectionSpinner /></div> : null}
            </div>

            <div className={showPageLoader ? 'd-none':''}>
            <div className="card SimplurisDashboardCard">                
                    <div className="px-4 py-3 SimplurisSubTabTitle">
                    Profile
                                        </div>
                <hr className="m-0" />
                <div className="SimplurisTeamMemberProfileDetailLoaderSection">
                        {showPageLoaderForUpdateTeamMeberDetail ? <SectionSpinner /> : null}
                </div>
                <div className="p-1">
                    <div className="px-4 py-4">
                        {successMessage != "" ? <SuccessPlacement items={successMessage} /> : ''}
                        {errorMessage != "" ? <ErrorPlacement items={errorMessage} /> : ''}
                        <div className="row mb-4">
                            <div className="col-lg-6 position-relative SimplurisChangeRequestDiv">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">First Name</label>
                                <input type="text" className="form-control SimplurisMatrialTextBox" onChange={e => (setFirstName(e.target.value))} defaultValue={firstName} readOnly />                                
                            </div>
                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Last Name</label>
                                    <input type="text" className="form-control SimplurisMatrialTextBox" onChange={e => (setLastName(e.target.value))} defaultValue={lastName} readOnly />                               
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-6">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Title/Role</label>
                                    <input type="text" className="form-control SimplurisMatrialTextBox" onChange={e => (setTitle(e.target.value))} value={title} />
                            </div>
                            <div className="col-lg-6 mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Company</label>
                                <input type="text" className="form-control SimplurisMatrialTextBox" onChange={e => (setCompanyName(e.target.value))} value={companyName} />                               
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-6">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Email Address</label>
                                <input type="text"  className="form-control SimplurisMatrialTextBox" onChange={e => (setEmail(e.target.value))} defaultValue={email} readOnly />
                            </div>
                            <div className="col-lg-6 mt-2 mt-lg-0">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Phone Number1</label>
                                    <input type="text" className="form-control SimplurisMatrialTextBox" onChange={e => (setPhoneNumber1(e.target.value))} value={FormatPhoneNumberForUS(phoneNumber1)} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-6">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Fax Number</label>
                                    <input type="text" className="form-control SimplurisMatrialTextBox" onChange={e => (setPersonFax(e.target.value))} value={FormatFaxNumberForUS(personFax)} />
                            </div>
                            <div className="col-lg-6 mt-2 mt-lg-0">
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Phone Number2</label>
                                    <input type="text" className="form-control SimplurisMatrialTextBox" onChange={e => (setPhoneNumber2(e.target.value))} value={FormatPhoneNumberForUS(phoneNumber2)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-right">
                                    <button type="button" onClick={UpdateTeamMeberDetailByPersonID} disabled={showButtonLoader}  className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm btn-light py-3 mt-3">
                                        {
                                            showButtonLoader && (
                                                <ButtonLoader />
                                            )
                                        }
                                        {showButtonLoader && <span></span>}
                                        {!showButtonLoader && <span>Save</span>}
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="accordion">
                <div className="card">
                        <div className="px-4 py-3 border-bottom SimplurisSubTabTitle">
                        Password
                                            </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center px-4 py-3  pointer" data-toggle="collapse" data-parent="#accordion" data-target="#c_2">
                        <div className="SimplurisMutableTitle SimplurisFontSize14">
                            Change password
                                                </div>
                        <div className="flex"></div>
                        <div>
                            <i className="i-con i-con-right text-muted"><i></i></i>
                        </div>
                    </div>
                        <div className="collapse p-4" id="c_2">

                            {changePasswordErrorMessage != "" ? <ErrorPlacement items={changePasswordErrorMessage} /> : ''}
                            {changePasswordSuccessMessage != "" ? <SuccessPlacement items={changePasswordSuccessMessage} /> : ''}                            


                        <form role="form">
                            <div className={`form-group ${curpwdError ? 'has-error' : ''}`}>
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Current Password</label>

                                <input type="password" value={curpwd} onChange={e => (setCurpwd(e.target.value), setCurpwdError(false))} id="txtCurrentPassword" name="CurrentPassword" className="form-control SimplurisNewCaseMemebersInputBox" />
                                {curpwdError ? <span>Please enter current password</span> : null}
                            </div>
                            <div className={`form-group ${newpwdError || strongPasswordError ? 'has-error' : ''}`}>
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">New Password</label>
                                    <input type="password" value={newpwd} onChange={e => (setNewpwd(e.target.value), setNewpwdError(false), setStrongPasswordError(false))} id="txtNewPassword" name="NewPassword" className="form-control SimplurisNewCaseMemebersInputBox" />
                                {newpwdError ? <span>Please enter new password</span> : null}
                                    {strongPasswordError ? <span> Password does not meet security requirements </span> : null}
                            </div>
                            <div className="form-group row">
                                <div className="col-12 col-sm-6">
                                    <p className="mb-0">
                                        <small className="text-muted">
                                            One lowercase character <br />One uppercase character <br />One number
                                                                            </small>
                                    </p>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <p className="mb-0">
                                        <small className="text-muted">
                                            One special character <br /> 8 characters minimum
                                                                            </small>
                                    </p>
                                </div>
                            </div>
                            <div className={`form-group ${confpwdError || confirmMatchPasswordError ? 'has-error' : ''}`}>
                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Confirm New Password</label>
                                    <input type="password" value={confpwd} onChange={e => (setConfpwd(e.target.value), setConfpwdError(false))} id="txtConfirmPassword" name="ConfirmPassword" className="form-control SimplurisNewCaseMemebersInputBox" />
                                {confpwdError ? <span>Please enter confirm password</span> : null}
                                {confirmMatchPasswordError ? <span>New password and confirm password does not match</span> : null}
                            </div>
                                <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3" disabled={showButtonLoader} onClick={SaveChangePasswordClick}>

                                    {
                                        showButtonLoader && (
                                            <ButtonLoader />
                                        )
                                    }
                                    {showButtonLoader && <span></span>}
                                    {!showButtonLoader && <span>Update</span>}
                                    </button>
                        </form>
                    </div>
                </div>

                <div className="card">
                        <div className="px-4 py-3 border-bottom SimplurisSubTabTitle">
                        <div className="row">
                            <div className="col-xl-10 col-lg-8 col-md-8">
                                Notifications
                                                    </div>
                            <div className="col-xl-1 col-lg-2 col-md-2 text-xl-right d-none d-lg-block d-sm-block d-md-block SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                Text
                                                    </div>
                            <div className="col-xl-1 col-lg-2 col-md-2 pl-0 text-xl-right col-lg-2 d-none d-lg-block d-sm-block d-md-block SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                Email
                                                    </div>
                        </div>
                    </div>
                    
                        {/* <hr className="m-0" /> <div className="align-items-center px-4 py-3">
                           <div className="row">
                            <div className="col-xl-10 col-lg-8 col-md-8 SimplurisMutableTitle SimplurisFontSize14 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                New Weekly Reports
                                                        </div>
                            <div className="col-xl-1 col-lg-2 col-md-2 pl-md-2 pl-lg-2 pl-xl-2 mt-2 mt-md-0 mt-sm-0 mt-lg-0 mt-xl-0">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-4 pr-md-3 pr-lg-3 pr-xl-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Text
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleWeeklyTextPermissionChange(!weeklyTextPermission, USER_REPORT_WEEKLY_TEXT && USER_REPORT_WEEKLY_TEXT[0].personMapSettingID, USER_REPORT_WEEKLY_TEXT && USER_REPORT_WEEKLY_TEXT[0].settingTypeID)} defaultChecked={weeklyTextPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                            <div className="col-xl-1 col-lg-2 col-md-2">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Email
                                                        </div>
                                <span className="">
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleWeeklyEmailPermissionChange(!weeklyEmailPermission, USER_REPORT_WEEKLY_EMAIL && USER_REPORT_WEEKLY_EMAIL[0].personMapSettingID, USER_REPORT_WEEKLY_EMAIL && USER_REPORT_WEEKLY_EMAIL[0].settingTypeID)} defaultChecked={weeklyEmailPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>*/}
                    <div className="align-items-center px-4 py-3 b-t">
                        <div className="row">
                            <div className="col-xl-10 col-lg-8 col-md-8 SimplurisMutableTitle SimplurisFontSize14 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                Receive / relay chats via
                                                    </div>
                            <div className="col-xl-1 col-lg-2 col-md-2 pl-md-2 pl-lg-2 pl-xl-2 mt-2 mt-md-0 mt-sm-0 mt-lg-0 mt-xl-0">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-4 pr-md-3 pr-lg-3 pr-xl-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Text
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleChatTextPermissionChange(!chatTextPermission, USER_CHATS_TEXT_ALL && USER_CHATS_TEXT_ALL[0].personMapSettingID, USER_CHATS_TEXT_ALL && USER_CHATS_TEXT_ALL[0].settingTypeID)} defaultChecked={chatTextPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                            <div className="col-xl-1 col-lg-2 col-md-2">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Email
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleChatEmailPermissionChange(!chatEmailPermission, USER_CHATS_EMAIL_ALL && USER_CHATS_EMAIL_ALL[0].personMapSettingID, USER_CHATS_EMAIL_ALL && USER_CHATS_EMAIL_ALL[0].settingTypeID)} defaultChecked={chatEmailPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="align-items-center px-4 py-3 b-t">
                        <div className="row">
                            <div className="col-xl-10 col-lg-8 col-md-8 SimplurisMutableTitle SimplurisFontSize14 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                Receive / relay only the first chat message after 1 hour of inactivity
                                                    </div>
                            <div className="col-xl-1 col-lg-2 col-md-2 pl-md-2 pl-lg-2 pl-xl-2 mt-2 mt-md-0 mt-sm-0 mt-lg-0 mt-xl-0">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-4 pr-md-3 pr-lg-3 pr-xl-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Text
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleChatTextFirstPermissionChange(!chatTextFirstPermission, USER_CHATS_TEXT_FIRST && USER_CHATS_TEXT_FIRST[0].personMapSettingID, USER_CHATS_TEXT_FIRST && USER_CHATS_TEXT_FIRST[0].settingTypeID)} defaultChecked={chatTextFirstPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                            <div className="col-xl-1 col-lg-2 col-md-2">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Email
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleChatEmailFirstPermissionChange(!chatEmailFirstPermission, USER_CHATS_EMAIL_FIRST && USER_CHATS_EMAIL_FIRST[0].personMapSettingID, USER_CHATS_EMAIL_FIRST && USER_CHATS_EMAIL_FIRST[0].settingTypeID)} defaultChecked={chatEmailFirstPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="align-items-center px-4 py-3 b-t">
                        <div className="row">
                            <div className="col-xl-10 col-lg-8 col-md-8 SimplurisMutableTitle SimplurisFontSize14 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                Receive / relay all full messages via
                                                    </div>
                            <div className="col-xl-1 col-lg-2 col-md-2 pl-md-2 pl-lg-2 pl-xl-2 mt-2 mt-md-0 mt-sm-0 mt-lg-0 mt-xl-0">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-4 pr-md-3 pr-lg-3 pr-xl-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Text
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleMessageTextPermissionChange(!messageTextPermission, USER_MESSAGES_TEXT_ALL && USER_MESSAGES_TEXT_ALL[0].personMapSettingID, USER_MESSAGES_TEXT_ALL && USER_MESSAGES_TEXT_ALL[0].settingTypeID)} defaultChecked={messageTextPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                            <div className="col-xl-1 col-lg-2 col-md-2">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Email
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleMessageEmailPermissionChange(!messageEmailPermission, USER_MESSAGES_EMAIL_ALL && USER_MESSAGES_EMAIL_ALL[0].personMapSettingID, USER_MESSAGES_EMAIL_ALL && USER_MESSAGES_EMAIL_ALL[0].settingTypeID)} defaultChecked={messageEmailPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="align-items-center px-4 py-3 b-t">
                        <div className="row">
                            <div className="col-xl-10 col-lg-8 col-md-8 SimplurisMutableTitle SimplurisFontSize14 mb-2 mb-md-0 mb-lg-0 mb-xl-0">
                                Receive / relay only the first full message after 1 hour of inactivity
                                                    </div>
                            <div className="col-xl-1 col-lg-2 col-md-2 pl-md-2 pl-lg-2 pl-xl-2 mt-2 mt-md-0 mt-sm-0 mt-lg-0 mt-xl-0">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-4 pr-md-3 pr-lg-3 pr-xl-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Text
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleMessageTextFirstPermissionChange(!messageTextFirstPermission, USER_MESSAGES_TEXT_FIRST && USER_MESSAGES_TEXT_FIRST[0].personMapSettingID, USER_MESSAGES_TEXT_FIRST && USER_MESSAGES_TEXT_FIRST[0].settingTypeID)} defaultChecked={messageTextFirstPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                            <div className="col-xl-1 col-lg-2 col-md-2">
                                <div className="d-inline d-sm-none d-md-none d-xl-none d-lg-none pr-3 SimplurisMutableTitle SimplurisFontSize10 SimplurisFontWeight700 SimplurisOpacity07">
                                    Email
                                                        </div>
                                <span>
                                    <label className="ui-switch ui-switch-md">
                                        <input type="checkbox" onClick={() => HandleMessageEmailFirstPermissionChange(!messageEmailFirstPermission, USER_MESSAGES_EMAIL_FIRST && USER_MESSAGES_EMAIL_FIRST[0].personMapSettingID, USER_MESSAGES_EMAIL_FIRST && USER_MESSAGES_EMAIL_FIRST[0].settingTypeID)} defaultChecked={messageEmailFirstPermission} />
                                        <i></i>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <ChangeRequestModalPopup OpenModalPopup={showChangeRequestModalPopup} setShowChangeRequestModalPopup={setShowChangeRequestModalPopup} AttributesValue={AttributesValue} caseID={0} personID={personID} />

                </div>
            </div>
        </div>
    )
}

export default ProfilePage

function ErrorPlacement(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvFailedMessage" className="alert alert-danger" role="alert">
            <button type="button" className="close" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}

function SuccessPlacement(data) {

    const [isElementHidden, setIsElementHidden] = useState(false);

    const HideElement = () => {
        setIsElementHidden(true)
    }

    if (data.items != "") {
        return (!isElementHidden ? <div id="dvsuccessMessage" className="alert alert-success" role="success">
            <button type="button" className="close" onClick={() => HideElement()}>&times;</button>
            {data.items}
        </div> : <></>)
    }
}