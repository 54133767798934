import * as React from 'react'
import { connect } from 'react-redux'

import { selectMailing } from 'state/case-detail-view/mailings/other/actions'
import EnvelopeBlue from '../../../../../../static/img/envelope-upload-blue.png'

const Mailing = ({ selectMailing, mailingTitle, mailingDescription, mailingTypes, mailingID, mailedCount, returnedCount, remailedCount, undeliverableCount, viewDeliverableStatusPermission, editExistingMailPermission, viewMailingIdPermission, mailingTotalSent,
	mailingUndeliverable, 
	mailingInitialReceived,
	mailingRemailed, }) => (
    	<>
		<hr className="m-0" />
		<div className="row">
			<div className="card-body SimpulrisMailingCardBody">
				<div className="row">
					<div className="pl-3">
						<span className="SimplurisMailingsEnvelopeSqure r avatar bg-primary-lt">
							<img
                                src={EnvelopeBlue}
								className="SimplurisMailingsEnvelopeImage"
							/>
						</span>
                    </div>
                    {
                        viewMailingIdPermission && viewMailingIdPermission.length>0 ?
                        <div className="col-8 col-md-8 col-sm-12 col-xs-12 align-self-center pt-lg-0">
                            {
                                viewDeliverableStatusPermission && viewDeliverableStatusPermission.length > 0 ?
                                    <div className="SimplurisMailingTitle">{mailingTitle || '[No Title]'}</div> : <></>
									
                            }
								{ <div className="SimplurisMailingSubTitle">{mailingTypes}</div> }
                            </div>
                            : <></>
                    }
                    <div className="d-inline-block float-lg-right float-md-right mt-2 mt-lg-1 mt-xl-2 mt-md-2 ml-3 ml-lg-3 ml-xl-4">
                        {editExistingMailPermission && editExistingMailPermission.length>0 ?
						<input
                            type="button"
                            className="btn btnMailingManage py-2 px-4"
                            value="Manage"
                            id="btnManage"
                            name="btnManage"
                            onClick={() => selectMailing(mailingID)} /> : <></>
                        }            
					</div>
				</div>
                <hr />

                {
                    viewDeliverableStatusPermission && viewDeliverableStatusPermission.length > 0 ?
                        		<div className="row px-3 pt-3">
					<div className="col-lg-3 col-6 px-0">
						<div className="d-flex align-items-center i-con-h-a my-1">
							<div className="d-flex">
								<div
									className="peity"
									data-plugin="peity"
									data-tooltip="true"
									data-title="Notice Sent"
									data-option="
                                                  'donut',
                                                  {
                                                      height: 50,
                                                      width: 50,
                                                      padding: 0.3,
                                                      fill: [theme.color.primary, 'rgba(120, 130, 140, 0.1)']
                                                  }"
								>
									15,85
								</div>
								<div className="mx-3">
									<small className="text-primary SimplurisFontSize19 SimplurisFontWeight400">
                                        {mailingTotalSent}
									</small>
									<small className="text-muted d-block">Sent</small>
								</div>
							</div>
                            {/*<div class="ml-4">
								<div class="text-right">
									<i class="i-con i-con-arrow-down text-danger SimplurisFontWeight400">
										<i />
									</i>
									<small class="SimplurisFontWeight700">24%</small>
								</div>
							</div>*/}
						</div>
					</div>
					<div className="col-lg-3 col-6 px-0">
						<div className="d-flex align-items-center i-con-h-a my-1">
							<div className="d-flex">
								<div
									className="peity"
									data-plugin="peity"
									data-tooltip="true"
									data-title="Notice Response"
									data-option="
                                                  'donut',
                                                  {
                                                      height: 50,
                                                      width: 50,
                                                      padding: 0.3,
                                                      fill: [theme.color.danger, 'rgba(120, 130, 140, 0.1)']
                                                  }"
								>
									55,45
								</div>
								<div className="mx-3">
									<small className="text-danger SimplurisFontSize19 SimplurisFontWeight400">
                                        {returnedCount} 
												</small><sup>{!isNaN(parseInt((returnedCount / mailingTotalSent) * 100)) ? parseInt((returnedCount / mailingTotalSent) * 100) + "%" : "" }</sup>
									<small className="text-muted d-block">Returned</small>
								</div>
							</div>
                            {/*<div class="ml-4">
								<div class="text-right">
									<i class="i-con i-con-arrow-down text-danger SimplurisFontWeight400">
										<i />
									</i>
									<small class="SimplurisFontWeight700">24%</small>
								</div>
							</div>*/}
						</div>
					</div>
					<div className="col-lg-3 col-6 px-0">
						<div className="d-flex align-items-center i-con-h-a my-1">
							<div className="d-flex">
								<div
									className="peity"
									data-plugin="peity"
									data-tooltip="true"
									data-title="Notice Returned"
									data-option="
                                                  'donut',
                                                  {
                                                      height: 50,
                                                      width: 50,
                                                      padding: 0.3,
                                                      fill: [theme.color.info,'rgba(120, 130, 140, 0.1)']
                                                  }"
								>
									20,5
								</div>
								<div className="mx-3">
									<small className="text-info SimplurisFontSize19 SimplurisFontWeight400">
                                        {mailingRemailed}
									</small>
									<small className="text-muted d-block">Remailed</small>
								</div>
							</div>
                            {/*<div class="ml-4">
								<div class="text-right">
									<i class="i-con i-con-arrow-down text-danger SimplurisFontWeight400">
										<i />
									</i>
									<small class="SimplurisFontWeight700">24%</small>
								</div>
							</div>*/}
						</div>
                    </div>
                    	<div className="col-lg-3 col-6 px-0">
						<div className="d-flex align-items-center i-con-h-a my-1">
							<div className="d-flex">
								<div
									className="peity"
									data-plugin="peity"
									data-tooltip="true"
									data-title="Notice Returned"
									data-option="
                                                  'donut',
                                                  {
                                                      height: 50,
                                                      width: 50,
                                                      padding: 0.3,
                                                      fill: [theme.color.info,'rgba(120, 130, 140, 0.1)']
                                                  }"
								>
									20,5
								</div>
								<div className="mx-3">
                                    <small className="text-info SimplurisFontSize19 SimplurisFontWeight400">
                                        {mailingUndeliverable} 
												</small><sup>{!isNaN(parseInt((mailingUndeliverable / mailingTotalSent) * 100)) ? parseInt((mailingUndeliverable / mailingTotalSent) * 100) + "%" : "" }</sup>
									<small className="text-muted d-block">Undeliverable</small>
								</div>
							</div>
                            {/*<div class="ml-4">
								<div class="text-right">
									<i class="i-con i-con-arrow-down text-danger SimplurisFontWeight400">
										<i />
									</i>
									<small class="SimplurisFontWeight700">24%</small>
								</div>
							</div>*/}
						</div>
					</div>
				</div> : <></>
                }
		
			</div>
		</div>
	</>
)

export default connect(null, { selectMailing })(Mailing)