import * as React from 'react'
import _ from 'lodash'

import {
	getLines,
	getDoughnutChart,
	getTotalsForMailings,
	chartColors,
	chartTitles,
} from './helpers'
import USMap from './us-map'
import { getPercentage } from 'helpers'
import { SectionSpinner } from 'react/components/spinners'

const MapSection = ({ mailings, mailTrackingTopLevelHopsData, mailTrackingHopsDetailData, onLineClick }) => {    	
    if (!mailTrackingTopLevelHopsData || !mailings) {
		return <SectionSpinner />
	}

	const { allMailingsTypesTotal, ...mailingsTotals } = getTotalsForMailings(
		mailings
	)

	return (
		<div className="row mt-1">
			<div className="col-xl-3 col-md-4 col-xs-12 col-sm-12 pl-4">
				<div className="card mb-0 SimplurisNoBoxShadow">
					<div className="mt-5">
						{_.map(mailingsTotals, (total, type) =>
							getDoughnutChart({
								number: getPercentage({
									total: allMailingsTypesTotal,
									part: total,
								}),
								color: chartColors[type],
								title: chartTitles[type],
							})
						)}
					</div>
				</div>
			</div>
			<div className="col-xl-9 col-md-8 col-xs-12 col-sm-12 pr-0 mb-5">
				<USMap
                    lines={getLines(mailTrackingTopLevelHopsData)}
                    //linesdetails={getLines(mailTrackingHopsDetailData)}
					onLineClick={onLineClick}
				/>
			</div>
		</div>
	)
}

export default MapSection
