import { FETCH_CASE_MAILINGS_STATISTICS, FETCH_CASE_MAILINGS_ACTIVITIES, FETCH_CASE_MAILINGS_RECIPIENTS, FETCH_CASE_MAILINGS_GENERAL, FETCH_CASE_MAILINGS_DOCUMENTS } from 'state/mailings/other/actions'
import { SELECT_MAILING } from 'state/case-detail-view/mailings/other/actions'
import { CHANGE_CASE_SUBTAB } from 'state/case-detail-view/subtab/actions'

import { start, resolved } from 'state/helpers'


const initialState = {
    fetching: false,
    fetchingMailingActivities: false,
    fetchingMailingRecipients: false,
    fetchingMailingGeneral: false,
    fetchingMailingDocuments:false,
    selectedMailingID: null,
    mailingsActivities: null    
}

export default (state = initialState, { type, selectedMailingID, caseMailingsActivities, caseMailingsRecipients, caseMailingsGeneral, caseMailingsDocuments }) => {        
    
    switch (type) {
        case start(FETCH_CASE_MAILINGS_STATISTICS):
            return { ...state, fetching: true }
        case resolved(FETCH_CASE_MAILINGS_STATISTICS):
            return { ...state, fetching: false }        
        case SELECT_MAILING:            
            return { ...state, selectedMailingID }
        case CHANGE_CASE_SUBTAB:
            return { ...state, selectedMailingID: null }
        case start(FETCH_CASE_MAILINGS_ACTIVITIES):
            
            return { ...state, fetchingMailingActivities: true }
        case resolved(FETCH_CASE_MAILINGS_ACTIVITIES):
            
            var newCaseMailingsActivitiesObj = state.caseMailingsActivities != undefined ? state.caseMailingsActivities : caseMailingsActivities;
            newCaseMailingsActivitiesObj.caseMailingsActivities = state.caseMailingsActivities != undefined ? newCaseMailingsActivitiesObj.caseMailingsActivities.concat(caseMailingsActivities.caseMailingsActivities) : newCaseMailingsActivitiesObj.caseMailingsActivities;
            newCaseMailingsActivitiesObj.isHasNewData = caseMailingsActivities.isHasNewData;
            newCaseMailingsActivitiesObj.lastRecordIds = caseMailingsActivities.lastRecordIds;

            caseMailingsActivities = newCaseMailingsActivitiesObj;

            
            
            
            return { ...state, fetchingMailingActivities: false, caseMailingsActivities }
        case start(FETCH_CASE_MAILINGS_RECIPIENTS):
            return { ...state, fetchingMailingRecipients: true }
        case resolved(FETCH_CASE_MAILINGS_RECIPIENTS):
            return { ...state, fetchingMailingRecipients: false, caseMailingsRecipients }
        case start(FETCH_CASE_MAILINGS_GENERAL):
            return { ...state, fetchingMailingGeneral: true }
        case resolved(FETCH_CASE_MAILINGS_GENERAL):
            return { ...state, fetchingMailingGeneral: false, caseMailingsGeneral }
        case start(FETCH_CASE_MAILINGS_DOCUMENTS):
            return { ...state, fetchingMailingDocuments: true }
        case resolved(FETCH_CASE_MAILINGS_DOCUMENTS):
            return { ...state, fetchingMailingDocuments: false, caseMailingsDocuments }
        default:
            return state
    }
}