import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import {
    BootstrapTable,
    TableHeaderColumn
} from "react-bootstrap-table";
import { fetchCaseFilesData } from 'state/case-file/actions'
import { SectionSpinner } from 'react/components/spinners'
import LinkBlueImage from '../../../../../static/img/link-blue-img.png'
import DownloadImage from '../../../../../static/img/download-img.png'
import ExistingShareFileModalPopup from './existing-share-case-file'
import { simplurisFetch } from 'helpers'

function FilesTable({ caseID, caseFilesData, getSecureFileDetailComponent, getSecureFileCount, viewFilePermission }) {
    getSecureFileCount(caseFilesData != undefined && caseFilesData != null ? caseFilesData.length : 0);
    const [secureFileID, setSecureFileID] = useState('');
    const [isFetchCaseManager, setIsFetchCaseManager] = useState(false);
    useEffect(() => {
    }, []);

    function setTableOption() {
        return "no data found!";
    }

    const SaveSecureFileHistoryClick = (row) => {
        
        var formData = new FormData();
        formData.append('SecureFileID', row.secureFileID);
        formData.append('CaseID', caseID);
        formData.append('PersonMapID', localStorage.getItem(global.PersonMapID));
        formData.append('SentByPersonMapID', row.sentByPersonMapID);
        formData.append('AttachmentID', row.attachmentID);
        formData.append('VendorUniqueID', row.vendorUniqueID);

        simplurisFetch({
            url: `cases/${caseID}/savesecurefilehistory`,
            method: 'POST',
            body: formData,
            json: false,
        }).then((result) => {
            if (result.resultCode == "SUCCESS") {
            } else {
            }
        }).catch(e => {
        });

    }

    function imageFormatter(cell, row, i) {
        return (
            <>
                <a href={cell} onClick={() => SaveSecureFileHistoryClick(row)} target="_blank" download> <img src={DownloadImage} /> </a>
                {(row.sentByPersonMapID == localStorage.getItem(global.PersonMapID)) &&
                    <a onClick={() => OpenExistingShareFileModalPopup(row.secureFileID)}> <img src={LinkBlueImage} className="ml-3" /> </a>
                }
            </>
        );
    }

    const [showExistingShareFile, setShowExistingShareFile] = useState(false);

    function OpenExistingShareFileModalPopup(secureFileID) {
        setIsFetchCaseManager(true);
        setSecureFileID(secureFileID);
        AnlyticsPageLog("Click on existing share file Modal Popup from File Upload");
        setShowExistingShareFile(true);
    }

    const handleCellClick = (row) => {
        if (row) {
            getSecureFileDetailComponent(row.caseID, row.secureFileID, row, caseFilesData.length);
        }
    };

    function linkCellFormatter(cell, row) {
        return (
            <>
                {(row.sentByPersonMapID == localStorage.getItem(global.PersonMapID)) ?
                    <a
                        className="SimplurisBlueColor SimplurisPointer"
                        href={null}
                        onClick={() => handleCellClick(row)}
                    >
                        {cell}
                    </a>
                    :
                    <a href={null}>
                        {cell}
                    </a>
                }
            </>
        );
    }

    if (!caseFilesData) {
        return <div className="row"> <SectionSpinner /></div>
    }

    return (
        <>
            <div className="card SimplurisDashboardCard">
                <div className="d-flex flex row">
                    <div className="col-md-12">
                        <div className="card d-flex flex p-3 mb-0">
                            <div className="table-responsive">
                                <BootstrapTable
                                    data={caseFilesData}
                                    options={{
                                        noDataText: setTableOption(),
                                    }}
                                    style={{ "margin-bottom": "0px" }}
                                    tableContainerClass="table table-theme v-middle SimplurisCaseReportsGrid"
                                    bodyContainerClass="text-grid-td"
                                >
                                    <TableHeaderColumn
                                        width="80"
                                        dataField="secureFileID"
                                        isKey
                                        dataFormat={linkCellFormatter}
                                    >
                                        File ID
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        columnTitle={true}
                                        dataField="title"
                                        width="170"
                                    >
                                        Title
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fileType"
                                        width="140"
                                    >
                                        Type
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="sentDate"
                                        dataFormat={date => new Date(date).toLocaleString('en-US')}
                                        width="160"
                                    >
                                        Date
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="CreatedBy" hidden
                                    >
                                        Created By
                                </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="attachmentUrl"
                                        dataFormat={imageFormatter}
                                        searchable={false}
                                        width="90"
                                        hidden={
                                            viewFilePermission && viewFilePermission.length > 0
                                                ? false
                                                : true
                                        }
                                    ></TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ExistingShareFileModalPopup
                OpenModalPopup={showExistingShareFile}
                setShowExistingShareFile={setShowExistingShareFile}
                caseID={caseID}
                secureFileID={secureFileID}
                random={Math.random()}
                isFetchCaseManager={isFetchCaseManager}
                setIsFetchCaseManager={setIsFetchCaseManager}
            />
        </>
    )
}

export default connect(
    (state, props) => ({
        caseFilesData: state.caseFiles[props.caseID],
    }),
    {
        fetchCaseFilesData,
    }
)(FilesTable)