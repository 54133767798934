import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CashDashboard from "./react/features/cases";
import SignInPage from "./react/features/sign-in";
import ForgotPasswordPage from "./react/features/forgot-password";
import SignUpPage from "./react/features/sign-up";
import AccountActivation from "./react/features/account-activation";
import AdminAccountActivation from "./react/features/admin-account-activation";
import ChangePassword from "./react/features/change-password";
import HomeLoggedinCase from "./react/features/home-loggedin-case";
import Dashboard from "./react/features/dashboard";
import CaseDetail from "./react/features/case-detail";
import NotAllowedToCase from "./react/features/not-allowed-to-case";
import NotAllowedToCaseSecureFile from "./react/features/case-detail/content/case-files/not-allowed-to-case-secure-file";
import RequestCallPage from "./react/features/request-call";
import ProfilePage from "./react/features/profile";
import { PrivateRoute } from "./react/components/private-route";
import APIStatusPage from "react/features/api-status-page";
import AgreeTermsConditions from "react/features/terms-conditions";
import AdminPage from "react/features/admin";
import RequestAccessPage from "react/features/request-access";
import ReportUnsubscribe from "./react/features/report-unsubscribe";
import { appMode } from './api-config';
import ReportQuickView  from './react/features/report-quick-view';

const AppRouter = () => (
    <Router>
        <Switch>
            <Route exact path="/" component={SignInPage} />
            <PrivateRoute
                exact
                path="/cases/:casestatus?"
                component={CashDashboard}
            />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/sign-up" component={SignUpPage} />
            <Route
                exact
                path="/account-activation/:code"
                component={AccountActivation}
            />
            <Route
                exact
                path="/admin-account-activation/:code"
                component={AdminAccountActivation}
            />
            <Route exact path="/login/:code?" component={SignInPage} />

            <Route exact path="/reportunsubscribe/:reportCode?" component={ReportUnsubscribe} />
            <Route exact path="/report/:reportCode?" component={SignInPage} />
            <Route exact path="/secure-file-transfer/:fileCode?" component={SignInPage} />
            <Route exact path="/sign-in" component={SignInPage} />

            <PrivateRoute exact path="/change-password" component={ChangePassword} />
            <PrivateRoute
                exact
                path="/home-logged-in-case"
                component={HomeLoggedinCase}
            />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/profile" component={ProfilePage} />
            <PrivateRoute exact path="/cases/detail/:caseID" component={CaseDetail} />
            <PrivateRoute exact path="/request-call" component={RequestCallPage} />
            <PrivateRoute exact path="/api-status" component={APIStatusPage} />
            <PrivateRoute exact path="/terms-conditions" component={AgreeTermsConditions} />
            <PrivateRoute exact path="/admin" component={AdminPage} />
            <Route exact path="/not-allowed-to-case" component={NotAllowedToCase} />
            <Route exact path="/not-allowed-to-case-secure-file" component={NotAllowedToCaseSecureFile} />            
            <Route exact path="/request-access" component={RequestAccessPage} />
            <Route exact path="/viewreport" component={ReportQuickView} />
        </Switch>
    </Router>
);

export default AppRouter;
