import * as React from 'react'
import { connect } from 'react-redux'

import Mailing from './mailing'
import { SectionSpinner } from 'react/components/spinners'
import { getMailingsForCase } from 'state/mailings/other/selectors'
import { labFeatures } from '../../../../../../api-config'

//var mailingcount;
const AllMailings = ({ caseID, isFetching, mailings, viewDeliverableStatusPermission, editExistingMailPermission, viewMailingIdPermission, caseLiveReportMailingHistory, }) => {
	const noMailings = !mailings || !mailings.length
	const showLoader = isFetching && noMailings
	const showNullState = !isFetching && noMailings

	
	let mailingcount = caseLiveReportMailingHistory && caseLiveReportMailingHistory.mailings.length > 0 ? caseLiveReportMailingHistory.mailings : [];
	
	//var mailingTotalSent = 0;
	//var mailingUndeliverable = 0;
	//var mailingInitialReceived = 0;
	//var mailingRemailed = 0;	
	//if (mailingcount != undefined) {
	//	debugger;
	//	for (var i = 0; i < mailingcount.length; i++) {
	//		debugger;
	//		mailingTotalSent = mailingcount[i].distinctTotalSent;
	//		mailingUndeliverable = mailingcount[i].distinctUndeliverable;
	//		mailingInitialReceived = mailingcount[i].distinctRemailed;
	//		mailingRemailed = mailingcount[i].distinctRemailed;
	//	}
	//	debugger;
	//}
	
	return (
		<div className="card d-flex flex">
			<div className="row align-items-center p-3">
				<div className="d-inline-block col-md-10 col-xl-10 col-9">
					<div className="text-md SimplurisFontSize19 SimplurisFontWeight400 SimplurisMilestoneLables">
						All Mailings
					</div>
                </div>
                {labFeatures &&
				<div className="d-inline-block col-md-2 col-xl-2 col-3 float-lg-right float-md-right mt-2 mt-lg-0 mt-md-0">
					<input
						type="button"
						className="btn btnDisbursementOverview SimplurisFontSize12 font-weight-500 py-2 px-4"
						value="Add"
						id="btnAdd"
						name="btnAdd"
					/>
                    </div>
                    }
			</div>
			{showLoader && <SectionSpinner />}
            {showNullState && <div className="text-center p-2">No mailings to show</div>}
			{!noMailings &&
				mailings.map(mailing => (					
					<Mailing {...mailing}
						mailingTotalSent={mailingcount.filter(x => x.mailingID == mailing.mailingID)[0] != null ? mailingcount.filter(x => x.mailingID == mailing.mailingID)[0].distinctTotalSent : 0}
						mailingUndeliverable={mailingcount.filter(x => x.mailingID == mailing.mailingID)[0] != null ? mailingcount.filter(x => x.mailingID == mailing.mailingID)[0].distinctUndeliverable :0}
						mailingInitialReceived={mailingcount.filter(x => x.mailingID == mailing.mailingID)[0] != null ? mailingcount.filter(x => x.mailingID == mailing.mailingID)[0].distinctRemailed : 0}
						mailingRemailed={mailingcount.filter(x => x.mailingID == mailing.mailingID)[0] != null ? mailingcount.filter(x => x.mailingID == mailing.mailingID)[0].distinctRemailed : 0}
						key={mailing.mailingID}
						viewDeliverableStatusPermission={viewDeliverableStatusPermission}
						editExistingMailPermission={editExistingMailPermission}
						viewMailingIdPermission={viewMailingIdPermission} />
				))}
		</div>
	)
}

export default connect((state, props) => ({
	isFetching: state.caseDetailView.mailings.fetching,
	mailings: getMailingsForCase(state, props),
	caseLiveReportMailingHistory: state.liveReports.mailings && state.liveReports.mailings[props.caseID],
}))(AllMailings)
