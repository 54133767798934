import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import { SectionSpinner } from 'react/components/spinners'
import { getSelectedMemberID } from 'state/case-detail-view/members/selectors'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

function CallsDetailView({ caseID, selectedMemberID }) {
    const [callsDetailView, setCallsDetailView] = useState([]);
    const [isShowLoader, setIsShowLoader] = useState(false);

    const fetchCallsDetailByMemeberPersonID = () => {        
        setIsShowLoader(true);
        simplurisFetch({
            url: `cases/${parseInt(caseID)}/${parseInt(selectedMemberID)}/getcallsformemberbymemberID`
        })
            .then(result => {                
                setIsShowLoader(false);

                if (result.name != undefined && result.name == "FetchError") {
                } else {
                    setCallsDetailView(result.resultObject);
                }
            })
            .catch(e => {                
                setIsShowLoader(false);
                console.error("Error in case members", e, e.errorObject);
            });
    };

    useEffect(() => {
        fetchCallsDetailByMemeberPersonID();
    }, []);

    const tableOptions = {
               onRowClick: onRowClick,
        onlyOneExpanding: true,
        expandBy: 'column'
    }

    function onRowClick(row, columnIndex, rowIndex, e) {
        if (e.target.classList.value.indexOf("SimplurisCallCenterMemeberDrillDownLink") > -1) {
            return false;
        }

        if (e.target.classList.value.indexOf("SimplurisCallCenterExpandLink") > -1) {
            const collepsedTag = document.getElementsByClassName("expandDiv");
            for (var i = 0; i < collepsedTag.length; i++) {
                collepsedTag[i].style.height = "0px";
            }

            const audioEl = document.getElementsByClassName("audio-" + row.callID)[0]
            if (audioEl.paused) {
                audioEl.play();

                setTimeout(function () {
                    document.getElementsByClassName("expandDiv-" + row.callID)[0].style.height = "50px";
                }, 100);

            } else {
                audioEl.pause();
                document.getElementsByClassName("expandDiv-" + row.callID)[0].style.height = "0px";
            }

            const audioTag = document.getElementsByTagName("audio");
            for (var i = 0; i < audioTag.length; i++) {
                if (audioTag[i].className != "audio-" + row.callID) {
                    audioTag[i].pause();
                }
            }
        }
        else
            return false;
    }

    function isExpandableRow(row) {
        if (row.isReleased == true) {
            return true
        }
        else {
            return false;
        }

    }

    function expandColumnComponent({ isExpandableRow, isExpanded }) {
        if (isExpandableRow) {
            if (!isExpanded) {
                return (
                    <i className="SimplurisCallCenterPlayPauseIcon fa fa-play-circle-o SimplurisCallCenterExpandLink"></i>)
            }
            else {
                return (
                    <i className="SimplurisCallCenterPlayPauseIcon fa fa-pause-circle-o SimplurisCallCenterExpandLink"></i>)
            }
        }
        else {
            return <div>&nbsp;</div>;
        }
    }


    function expandComponent(row) {
        return (
            <>
                <tr className="p-0">
                    <td colSpan="6" className="p-0 border-0 shadow-none">
                        <table className="table table-theme v-middle m-0 callCenterStatisticsGrid">
                            {(() => {
                                return <tr>
                                    <td className="p-0">
                                        <div className={`SimplurisFadeInAnimation expandDiv ${"expandDiv-" + row.callID}`}>
                                            {(
                                                () => {
                                                    if (row.audioURL != undefined) {
                                                        return <>  <audio controls loop controlsList="nodownload" width="100%" className={"audio-" + row.callID}>
                                                            <source src={row.audioURL} type="audio/wav" />
                                                        </audio></>
                                                    }
                                                    else {
                                                        return <div>&nbsp;</div>
                                                    }
                                                }
                                            )()}
                                        </div>
                                    </td>
                                </tr>

                            })()}
                        </table>
                    </td>
                </tr></>
        )
    }


    if (!isShowLoader) {        
            return (
                <>
                    <div className="card SimplurisDashboardCard">
                        <div className="p-3">
                            <div className="SimplurisFontSize10 SimplurisFontWeight500 SimplurisOpacity03 SimplurisCallCenterActiveStatusMutedText text-uppercase">
                                Call Statistics
                                                </div>
                            <BootstrapTable
                                data={callsDetailView}
                                options={tableOptions}
                                expandBy="column"
                                expandableRow={isExpandableRow}
                                expandComponent={expandComponent}
                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent: expandColumnComponent,
                                    columnWidth: 60
                                }}
                                tableContainerClass="table table-theme v-middle SimplurisCasesDetailsGrid table-hover callCenterStatisticsMainGrid table1"
                                bodyContainerClass="text-grid-td"
                                hover
                                trClassName="SimplurisStatisticsGridMainTr"
                            >
                                <TableHeaderColumn
                                    dataField="callID"
                                    isKey
                                    hidden={true}
                                    expandable={false}
                                    columnClassName="SimplurisCallCenterStatisticsAudioGrid"
                                >
                                    CallID
                </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} dataField="date"
                                    columnClassName="SimplurisCallCenterStatisticsAudioGrid" width="16%"
                                    dataFormat={date => new Date(date).toLocaleDateString()}
                                >
                                    Date
                    </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} columnClassName="SimplurisCallCenterStatisticsAudioGrid" dataField="date" width="16%" dataFormat={date => new Date(date).toLocaleTimeString()}>
                                    Time
                    </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} columnClassName="SimplurisCallCenterStatisticsAudioGrid" width="11%"
                                    dataField="durationSeconds"
                                >
                                    Length
            </TableHeaderColumn>
                                <TableHeaderColumn expandable={false} columnClassName="SimplurisCallCenterStatisticsAudioGrid" dataField="fromCity" width="30%" dataFormat={(fromCity, { fromState }) =>
                                    `${fromCity} ${fromState}`
                                }>
                                    Call Location
            </TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>                   
                </>
            )       
    }
    else {
        return (
            <div className="card SimplurisDashboardCard"><SectionSpinner /></div>
        )
    }
    
}

export default connect((state, props) => ({
    selectedMemberID: getSelectedMemberID(state, props),
}))(CallsDetailView)