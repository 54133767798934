import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'

const Wrapper = styled.div`
  display: flex;
`
const ClickableDiv = styled.div`
  cursor: pointer;
  color: #157eff;
`
const Separator = styled.div`
  width: 30px;
  text-align: center;
`

const DistributionsTreemapBreadcrumbs = ({
  breadCrumbs,
  setBreadCrumbs,
  setFilteredIDs,
}) => {
  return (
    <Wrapper>
      {breadCrumbs.map(({ title, ids }, i) => (
        <React.Fragment key={title}>
          <ClickableDiv
            onClick={() => {
              setFilteredIDs(ids)
              setBreadCrumbs(breadCrumbs.slice(0, i + 1))
            }}
          >
            {title}
          </ClickableDiv>
          {i < breadCrumbs.length - 1 ? <Separator>></Separator> : null}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

export default connect(null, { setFilteredIDs })(
  DistributionsTreemapBreadcrumbs
)
