import { CHANGE_CASE_SEARCH_TERM } from './actions'
import { FETCH_CASES } from '../data/actions'

const initialState = {
  searchTerm: '',
  lastCaseID: 0,
  isHasNewData: false,
}

export default (state = initialState, { type, searchTerm, cases }) => {
  switch (type) {
    case CHANGE_CASE_SEARCH_TERM:
      return {
        ...state,
        searchTerm,
      }
    case FETCH_CASES:
      return {
        ...state,
        lastCaseID: cases.lastCaseId,
        isHasNewData: cases.isHasNewData,
      }
    default:
      return state
  }
}
