import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PageLoader from 'react/components/page-loader';
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../global'
import CreditCardPNG from '../../../static/img/creditcard.png'
import BankPNG from '../../../static/img/bank.png'
import AddCardMethodModalPopup from './add-card-method-modal'
import AddBankModalPopup from './add-bank-modal'

function AddPaymentMethodModalPopup(props) {    
    function CloseAddPaymentMethodModalPopup() {
        props.setShowAddPaymentMethodModalPopup(false);        
    }    
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    const [showAddCardMethodModalPopup, setShowAddCardMethodModalPopup] = useState(false);
    const [showAddBankModalPopup, setShowAddBankModalPopup] = useState(false);
    
    function OpenAddCardMethodModalPopup() {
        AnlyticsPageLog("Click on Add Card Method Modal Popup from Add Payment Method ModalPopup");
        props.setShowAddPaymentMethodModalPopup(false);
        setShowAddCardMethodModalPopup(true);
    }

    function OpenAddBankModalPopup() {
        AnlyticsPageLog("Click on Add Bank Method Modal Popup from Add Payment Method ModalPopup");
        props.setShowAddPaymentMethodModalPopup(false);
        setShowAddBankModalPopup(true);
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseAddPaymentMethodModalPopup} size="lg">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle pl-3">Add Payment Method</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4 mb-2">
                    <div className="row pb-3">
                        <div className="col-12">
                            <p className="modal-title SimplurisFontSize19 SimplurisFontWeight400 SimplurisNewCaseTextColor">
                                Add one of the following payment methods:
                            </p>
                        </div>
                    </div>
                    <hr className="m-0" />
                    <div className="row py-4 justify-content-center align-items-center">
                        <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12 pr-0">
                            <img src={CreditCardPNG} />
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-9 col-xs-12 pl-3 pl-md-0 pl-xl-0 pl-lg-0">
                            <label className="d-flex mb-0 SimplurisFontSize18 SimplurisFontWeight700 SimplurisNewCaseTextColor">
                                Credit or Debit Cards
                            </label>
                            <label className="d-flex SimplurisFontSize18 SimplurisNewCaseTextColor">
                                We accept all major credit and debit cards.
                            </label>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 text-left text-lg-right text-xl-right mt-md-2">
                            <input type="button" onClick={() => OpenAddCardMethodModalPopup()}  name="Add" value="Add" className="btn text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3 rounded-0"/>
                        </div>
                    </div>
                    <hr className="m-0" />
                    <div className="row py-4 justify-content-center align-items-center">
                        <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12 pr-0">
                            <img src={BankPNG} />
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-9 col-xs-12 pl-3 pl-md-0 pl-xl-0 pl-lg-0">
                            <label className="d-flex mb-0 SimplurisFontSize18 SimplurisFontWeight700 SimplurisNewCaseTextColor">
                                Personal Checking Accounts
                            </label>
                            <label className="d-flex SimplurisFontSize18 SimplurisNewCaseTextColor">
                                Use your US based personal checking account.
                            </label>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-xs-12 text-left text-lg-right text-xl-right mt-md-2">
                            <input type="button" onClick={() => OpenAddBankModalPopup()} name="Add" value="Add" className="btn text-white SimplurisEggBlueBackGroudColor w-sm mb-1 btn-light py-3 rounded-0" data-toggle="modal" data-target="#AddCardsModalPopup" data-toggle-className="fade-down" data-toggle-class-target="#animate" data-dismiss="modal" />
                        </div>
                    </div>
                    <hr className="m-0" />
                </Modal.Body>
            </Modal>
            <AddCardMethodModalPopup OpenModalPopup={showAddCardMethodModalPopup} fetchAllCards={props.fetchAllCards} setShowAddCardMethodModalPopup={setShowAddCardMethodModalPopup} setShowAddPaymentMethodModalPopup={props.setShowAddPaymentMethodModalPopup} />
            <AddBankModalPopup OpenModalPopup={showAddBankModalPopup} setShowAddBankModalPopup={setShowAddBankModalPopup} fetchAllCards={props.fetchAllCards} setShowAddPaymentMethodModalPopup={props.setShowAddPaymentMethodModalPopup} />
        </>
    )
}

export default AddPaymentMethodModalPopup
