import {
	FETCH_DASHBOARD_CASE_ACTIVITY,
	ADD_CASE_ACTIVITY_POST,
} from './actions'
import { resolved } from 'state/helpers'

const initialState = {}

export default (state = initialState, { type, caseActivity, post }) => {
	switch (type) {
        case resolved(FETCH_DASHBOARD_CASE_ACTIVITY):                        
			return {
				...state,
                ...caseActivity.caseActivity.reduce((obj, activity) => {
					obj[activity.caseActivityID] = activity
					return obj
                }, {}),
                lastCaseId: caseActivity.lastCaseId,
                isHasNewData: caseActivity.isHasNewData
			}
		case ADD_CASE_ACTIVITY_POST:     			
			const activityToUpdate = state[post.caseActivityID]

			if (!activityToUpdate) {
				return state
			}

			return {
				...state,
				[post.caseActivityID]: {
					...activityToUpdate,
					posts: [...activityToUpdate.posts, post],
				},
			}
		default:
			return state
	}
}
