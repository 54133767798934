import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import ReactTimeAgo from 'react-time-ago';
import sendImage from 'static/img/message-send-button.png'
import sendBlueImage from 'static/img/message-send-blue-button.png'
import downloadButtonImage from 'static/img/download-button.png'
import ScrollArea from 'react-scrollbar'
import { updateMessages, getThreadMessgaes, deleteThread } from 'state/message-threads/actions'
import ButtonLoader from '../../components/button-loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { updateTeamMemberUnreadMessage } from 'state/cases/data/actions'
import { IsNullOrEmptyString } from '../../../global'
import autosize from 'autosize'

function ChatBox({
    openNewMessage,
    item,
    isCaseManager,
    caseManagerPersonMapId,
    caseManagerName,
    updateMessages,
    getThreadMessgaes,
    deleteThread,
    RefreshChatSection,
    random,
    updateTeamMemberUnreadMessage
}) {
    
    const [messageText, setMessageText] = useState('');
    const [originalImage, setOriginalImage] = useState(sendImage);
    const [minimizeChatbox, setMinimizeChatbox] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const [attachFileName, setAttachFileName] = useState('');
    const [uploadedfile, setUploadedfile] = useState('');
    const senderPersonMapID = localStorage.getItem(global.PersonMapID);
    const [messageThreadID, setMessageThreadID] = useState(item.messageThreadID);
    var loginPersonMapID = localStorage.getItem(global.PersonMapID);
    const [showCCDiv, setShowCCDiv] = useState(false);

    useEffect(() => {
        var lastMessage = item.messages[item.messages.length - 1];
        if (lastMessage != null && lastMessage != undefined) {
            if (lastMessage.senderPersonMapID == senderPersonMapID) {
                setIsRead(true);
            } else {
                setIsRead(false);
            }
        } else {
            setIsRead(true);
        }

        if (document.getElementById('chatbox-typing-wrapper-' + messageThreadID) != null) {
            document.getElementById('chatbox-typing-wrapper-' + messageThreadID).hidden = true;
        }

        if (document.getElementById('chatbox-buttonloader-wrapper-' + messageThreadID) != null) {
            document.getElementById('chatbox-buttonloader-wrapper-' + messageThreadID).hidden = true;
        }

        autosize(document.getElementsByClassName('SimplurisMessagetext'))

    }, [random])

    const updateRead = () => {
        simplurisFetch({
            url: `thread/${item.messageThreadID}/${loginPersonMapID}/messagemarkasread`
        });
        // Update unread message count in redux
        var messageCaseID = item.optionalCaseID;
        var personMapID = item.senderPersonMapID == loginPersonMapID ? item.recipientPersonMapID : item.senderPersonMapID;
        updateTeamMemberUnreadMessage({ messageCaseID, personMapID });
    }

    if (item.messages.length == 0) {
        var personMapID = item.senderPersonMapID == loginPersonMapID ? item.recipientPersonMapID : item.senderPersonMapID
        simplurisFetch({
            url: `thread/${item.messageThreadID}/${personMapID}/detail`
        }).then((result) => {
            if (result.error == null) {
                getThreadMessgaes(result.resultObject);
                updateRead();
            }
        });
        setTimeout(function () {
            scrollToBottom();
        }, 1000);
    } else {
        setTimeout(function () {
            scrollToBottom();
        }, 1000);
    }

    const SendMessageCallClick = () => {
        if (messageText == '') return;
        var data = {};
        data.HTMLContent = messageText;
        data.RecipientPersonMapID = item.senderPersonMapID == loginPersonMapID ? item.recipientPersonMapID : item.senderPersonMapID;
        data.RecipientName = item.senderPersonMapID == loginPersonMapID ? item.recipientName : item.senderName;
        data.RecipientProfileURL = item.senderPersonMapID == loginPersonMapID ? item.recipientProfileURL : item.senderProfileURL;
        data.SlackThreadID = item.slackThreadID;
        data.SenderName = localStorage.getItem(global.FirstName) + " " + localStorage.getItem(global.LastName);
        data.SenderProfileURL = localStorage.getItem(global.ProfilePicURL);
        data.SenderPersonMapID = localStorage.getItem(global.PersonMapID);
        data.CaseManagerName = caseManagerName;
        data.IsCaseManager = isCaseManager;
        data.MessageThreadID = item.messageThreadID;
        data.OriginalFileName = '';
        data.FileName = '';
        data.MessageAttachmentID = 0;
        data.CaseManagerPersonMapId = caseManagerPersonMapId;

        var reduxMessageData = {
            htmlContent: messageText,
            messageThreadID: item.messageThreadID,
            senderName: data.SenderName,
            senderPersonMapID: data.SenderPersonMapID,
            senderProfileURL: data.SenderProfileURL,
            recipientName: data.RecipientName,
            recipientProfileURL: data.RecipientProfileURL,
            recipientPersonMapID: data.RecipientPersonMapID,
            Timestamp: new Date(),
            originalFileName: '',
            fileName: '',
            messageAttachmentID: 0,
            CaseManagerPersonMapId: data.CaseManagerPersonMapId,
            slackThreadID: data.SlackThreadID,
        };

        updateMessages(reduxMessageData);
        setMessageText('');
        scrollToBottom();
        setShowCCDiv(false);

        var formData = new FormData();
        formData.append('MessageThreadID', item.messageThreadID);
        formData.append('file', '');
        formData.append('HTMLContent', messageText);
        formData.append('RecipientPersonMapID', item.senderPersonMapID == loginPersonMapID ? item.recipientPersonMapID : item.senderPersonMapID);
        formData.append('RecipientName', item.senderPersonMapID == loginPersonMapID ? item.recipientName : item.senderName);
        formData.append('RecipientProfileURL', item.senderPersonMapID == loginPersonMapID ? item.recipientProfileURL : item.senderProfileURL);
        formData.append('SenderName', localStorage.getItem(global.FirstName) + " " + localStorage.getItem(global.LastName));
        formData.append('SenderProfileURL', localStorage.getItem(global.ProfilePicURL));
        formData.append('SenderPersonMapID', localStorage.getItem(global.PersonMapID));
        formData.append('CaseManagerName', caseManagerName);
        formData.append('IsCaseManager', isCaseManager);
        formData.append('CaseManagerPersonMapId', caseManagerPersonMapId);
        formData.append('SlackThreadID', item.slackThreadID);
        simplurisFetch({
            url: "thread/pushmessage",
            method: 'POST',
            body: formData,
            json: false,
        }).then((result) => {
            if (result.error == null) {
                scrollToBottom();
            }
        });
    }

    const keyDown = (event) => {
        if (event == undefined) { return false; }
        if (event.key == "Backspace" && event.currentTarget.value == "") {
            event.currentTarget.rows = 1;
            setShowCCDiv(false);
        }
    }

    const keyPressed = (event) => {
        if (event == undefined) { return false; }

        setShowCCDiv(true);
        var maxrows = 5;
        var txt = event.currentTarget.value;
        var cols = event.currentTarget.cols;
        var arraytxt = txt.split('\n');
        var rows = arraytxt.length;
        for (var i = 0; i < arraytxt.length; i++)
            rows += parseInt(arraytxt[i].length / cols);
        //if (rows > maxrows) event.currentTarget.rows = maxrows;
        //else event.currentTarget.rows = rows;
        if (event.charCode == 13 && event.shiftKey) {
            /* Check MaxRow For Message Box */
            var maxrows = 5;
            var txt = event.currentTarget.value;
            var cols = event.currentTarget.cols;
            var arraytxt = txt.split('\n');
            var rows = arraytxt.length;
            for (var i = 0; i < arraytxt.length; i++)
                rows += parseInt(arraytxt[i].length / cols);
            //if (rows > maxrows) event.currentTarget.rows = maxrows;
            //else event.currentTarget.rows = rows;
        }
        else if (event.charCode === 13) {
            event.preventDefault();
            SendMessageCallClick();
            event.currentTarget.rows = 1;
        }
        else {
            setIsRead(true);

            var data = {};
            data.ProfileImage = localStorage.getItem(global.ProfilePicURL);
            data.RecipientPersonMapID = item.senderPersonMapID == loginPersonMapID ? item.recipientPersonMapID : item.senderPersonMapID; //item.recipientPersonMapID;
            data.SenderPersonMapID = localStorage.getItem(global.PersonMapID);
            data.MessageThreadID = item.messageThreadID;
            simplurisFetch({
                url: "thread/typingsignal",
                method: 'POST',
                body: JSON.stringify(data)
            });
        }
    }

    const OpenNewMessage = (check) => {
        deleteThread(item);
        RefreshChatSection();
    }

    const MinimizeChatbox = e => {
        if (e) {
            setMinimizeChatbox(false);
            updateRead();
        } else {
            setMinimizeChatbox(true);
        }
    };

    const ChangeImage = e => {
        if (e == false) {
            setOriginalImage(sendImage);
        } else {
            setOriginalImage(sendBlueImage);
        }
    };

    let scrollbarStyles = { borderRadius: 4 };

    const fileInput = React.createRef();
    const triggerInputFile = () => {
        fileInput.current.click();
    }

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        if (messagesEndRef.current != null) {
            messagesEndRef.current.parentNode.scrollIntoView({ behavior: "smooth", block: "end" })
        }
    };

    const handleChange = (event) => {
        if (event.target.files.length > 0) {
            setAttachFileName(event.target.files[0].name);
            setUploadedfile(event.target.files[0]);
        }

        if (document.getElementById('chatbox-buttonloader-wrapper-' + item.messageThreadID) != null) {
            document.getElementById('chatbox-buttonloader-wrapper-' + item.messageThreadID).hidden = false;
        }
        scrollToBottom();

        var formData = new FormData();
        formData.append('MessageThreadID', item.messageThreadID);
        formData.append('file', event.target.files[0]);
        formData.append('HTMLContent', messageText);
        formData.append('RecipientPersonMapID', item.senderPersonMapID == loginPersonMapID ? item.recipientPersonMapID : item.senderPersonMapID);
        formData.append('RecipientName', item.senderPersonMapID == loginPersonMapID ? item.recipientName : item.senderName);
        formData.append('RecipientProfileURL', item.senderPersonMapID == loginPersonMapID ? item.recipientProfileURL : item.senderProfileURL);
        formData.append('SenderName', localStorage.getItem(global.FirstName) + " " + localStorage.getItem(global.LastName));
        formData.append('SenderProfileURL', localStorage.getItem(global.ProfilePicURL));
        formData.append('SenderPersonMapID', localStorage.getItem(global.PersonMapID));
        formData.append('CaseManagerName', caseManagerName);
        formData.append('IsCaseManager', isCaseManager);
        formData.append('CaseManagerPersonMapId', caseManagerPersonMapId);
        formData.append('SlackThreadID', item.slackThreadID);

        simplurisFetch({
            url: "thread/pushmessage",
            method: 'POST',
            body: formData,
            json: false,
        }).then((result) => {
            if (result.error == null) {
                updateMessages(result.messages[0]);
                scrollToBottom();

                if (document.getElementById('chatbox-buttonloader-wrapper-' + item.MessageThreadID) != null)
                    document.getElementById('chatbox-buttonloader-wrapper-' + item.MessageThreadID).hidden = true;
            }
        });

    }

    var MessageChannel = global.Pusher.subscribe('platform-messaging-' + localStorage.getItem(global.PersonMapID));
    MessageChannel.unbind(global.Typing).bind(global.Typing, function (data) {
        if (document.getElementById('chatbox-typing-profile-pic-' + data.MessageThreadID) != null) {
            document.getElementById('chatbox-typing-profile-pic-' + data.MessageThreadID).src = data.ProfileImage;
        }

        if (document.getElementById('chatbox-typing-wrapper-' + data.MessageThreadID) != null)
            document.getElementById('chatbox-typing-wrapper-' + data.MessageThreadID).hidden = false;

        setTimeout(function () {
            if (document.getElementById('chatbox-typing-wrapper-' + data.MessageThreadID) != null)
                document.getElementById('chatbox-typing-wrapper-' + data.MessageThreadID).hidden = true;
        }, 2000);

        scrollToBottom();
    });

    const style = {
        maxHeight: '112px',
        minHeight: '38px',
        resize: 'none',
        boxSizing: 'border-box',
    };

    return (<>
        <div className={"chatbox card card-origin alert p-0 m-0 b-a rounded-0 mr-1 SimplurisMouseRollOver " + (openNewMessage != true ? 'hide' : '') + " " + (minimizeChatbox != true ? '' : 'card-hide-body')}>
            <div className={"card-header d-flex align-items-center px-2 py-0 rounded-0 " + (isRead == true ? 'SimplurisReadChatHeaderBackground' : 'SimplurisChatHeaderBackground')}>
                <div className="list list-row">
                    <div className="list-item pl-0 position-relative py-2">
                        <div className="px-0">
                            <a className="SimplurisMouseRollOver">
                                <div>
                                    {
                                        caseManagerPersonMapId != loginPersonMapID ?
                                            <img src={item.senderPersonMapID == senderPersonMapID ? item.recipientProfileURL : item.senderProfileURL}
                                                className="w-36 avatar circle bg-warning-lt avatar-status b-white avatar-right" />
                                            :
                                            <></>
                                    }
                                </div>
                            </a>
                        </div>
                        <div className="flex">
                            <div className="SimplurisFontSize14">{caseManagerPersonMapId != loginPersonMapID ? item.senderPersonMapID == senderPersonMapID ? item.recipientName : item.senderName : item.recipientName + " - " + item.senderName}</div>
                            {isCaseManager ? caseManagerPersonMapId != loginPersonMapID ?
                                <div className="SimplurisFontSize11 SimplurisOpacity06">
                                    CC: {caseManagerName}
                                </div> : <></> : <></>
                            }
                        </div>
                    </div>
                </div>
                <div className="ml-auto no-wrap">
                    <button onClick={() => MinimizeChatbox(minimizeChatbox)} className={"btn btn-sm btn-link btn-icon no-shadow i-con-h-a SimplurisButton " + (minimizeChatbox != true ? '' : 'active ') + (isRead == true ? 'SimplurisHeaderButton ' : 'text-white SimplurisOpacity05 ')} data-toggle-class="card-hide-body" data-target=".card" data-target-closest="true">
                        <i className="i-con i-con-minus w-24"></i>
                    </button>

                    <button onClick={() => OpenNewMessage(false)} className={"btn btn-sm btn-link btn-icon no-shadow i-con-h-a SimplurisButton " + (isRead == true ? 'SimplurisHeaderButton ' : 'text-white SimplurisOpacity05 ')}>
                        <i className="i-con i-con-close w-24"></i>
                    </button>
                </div>
            </div>
            <div className="card-body p-0">
                <div className="SimplurisCard-body-chat scrollbar-inner" >
                    <ScrollArea
                        className="area"
                        contentClassName="content"
                        vertical={true}
                        verticalScrollbarStyle={scrollbarStyles}
                        verticalContainerStyle={scrollbarStyles}
                        horizontal={false}
                        smoothScrolling={true}
                        style={{ height: '50vh' }}>
                        <div className="py-2 px-0" ref={messagesEndRef}>
                            <div className="chat-list">
                                {
                                    item.messages.map((message, i) =>
                                        <React.Fragment key={i}>
                                            <ChatMessages items={message} details={item} caseManagerPersonMapId={caseManagerPersonMapId} />
                                        </React.Fragment>
                                    )
                                }
                                <div className="chat-item mb-2" data-class="null" id={`chatbox-typing-wrapper-` + item.messageThreadID}>
                                    <a href="#" className="avatar w-36" data-pjax-state="">
                                        <img id={`chatbox-typing-profile-pic-` + item.messageThreadID} className="w-36 avatar circle bg-info-lt" />
                                    </a>
                                    <div className="chat-body">
                                        <div className="SimpulrisChatTyping SimplurisOpacity04 pt-2">
                                            Typing…
                                        </div>
                                    </div>
                                </div>

                                <div className="chat-item mb-2 chat-box-loader-wrapper float-right mr-3" data-class="null" id={`chatbox-buttonloader-wrapper-` + item.messageThreadID}>
                                    <ButtonLoader />
                                </div>

                            </div>
                        </div>
                    </ScrollArea>
                </div>
                <hr className="Simplurischat-line my-0" />
                <div className="p-2 white lt mt-auto" id="chat-input">
                    <div className={"SimplurisMessageSubtext mb-1 " + (showCCDiv ? '' : 'd-none')}>
                        For quality assurance, your project manager is CC'd on all correspondence.
                    </div>
                    <div className="input-group">
                        <textarea type="text" className="form-control message-text-b-n SimplurisMessagetext"
                            value={messageText} onChange={e => (setMessageText(e.target.value))}
                            onKeyPress={keyPressed.bind(this)}
                            onKeyDown={keyDown.bind(this)}
                            style={style} 
                            placeholder="Type a message..." id="openmessagetext" rows="1"></textarea>
                        <span className="input-group-append">
                            <a className="btn no-shadow i-con-h-a p-1 SimplurisPaperClipFlip SimplurisOpacity05" onClick={triggerInputFile}>
                                <FontAwesomeIcon icon={faPaperclip} rotation={180} />
                            </a>
                            <input type="file" ref={fileInput} className="d-none" onChange={handleChange} />
                            <a className="btn no-shadow i-con-h-a p-1 ml-1 mt-1" id="newBtn" onClick={SendMessageCallClick} >
                                {showCCDiv ? 
                                    <img src={sendBlueImage} />
                                    :
                                    <img src={originalImage}
                                        onMouseOver={() => ChangeImage(true)}
                                        onMouseOut={() => ChangeImage(false)} />
                                }
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default connect(
    (state, props) => ({
        threads: state.threads,
    }),
    {
        updateMessages,
        getThreadMessgaes,
        deleteThread,
        updateTeamMemberUnreadMessage,
    }
)(ChatBox)


function ChatMessages({ items, details, caseManagerPersonMapId }) {
    var isHtmlContent = Object.keys(items).filter(x => {
        return x == 'htmlContent'
    });
    const senderPersonMapID = localStorage.getItem(global.PersonMapID);
    if (items != null) {

        if (caseManagerPersonMapId == items.recipientPersonMapID) {
            return <></>
        }
        if (items.senderPersonMapID == senderPersonMapID) {

            if (items.messageAttachmentID == 0) {
                return (
                    <div className="chat-item mb-2" data-class="alt">
                        <div className="chat-body">
                            <div className="SimpulrisChatItemTextAlt mb-1">
                                {isHtmlContent.length > 0 ? items.htmlContent : items.hTMLContent}
                            </div>
                            <div className="SimpulrisChatdate">
                                {(
                                    () => {
                                        if (items.timestamp != null) {
                                            var date = new Date(items.timestamp);
                                            return (
                                                <ReactTimeAgo date={date} />
                                            )
                                        }
                                    }
                                )()}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="chat-item mb-2" data-class="alt">
                        <div className="chat-body">
                            <div className="SimplurisChatAttachmentSuccessBox SimpulrisChatTyping mt-1 SimplurisMouseRollOver">
                                <a href={items.originalFileName} target="_blank" download>
                                <div className="d-flex align-items-center">
                                    <span className="mr-lg-2  d-lg-block text-right">
                                        <span className="text-uppercase SimplurisOpacity04 SimplurisFontSize10">Attachment</span>
                                        <small className="d-block SimplurisSuccessAttachText SimplurisFontSize14 SimplurisFontWeight400">
                                            {items.fileName}
                                        </small>
                                    </span>
                                    <div className="avatar w-28">
                                        
                                            <img src={downloadButtonImage} />
                                        
                                    </div>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            if (items.messageAttachmentID == 0) {
                return (
                    <div className="chat-item mb-2" data-class="null">
                        <a href="#" className="avatar w-36" data-pjax-state="">
                            <img src={items.senderProfileURL} className="w-36 avatar circle bg-info-lt" />
                        </a>
                        <div className="chat-body">
                            <div className="SimpulrisChatItemText">
                                {isHtmlContent.length > 0 ? items.htmlContent : items.hTMLContent}
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <div className="chat-item mb-2" data-class="null">
                            <a href="#" className="avatar w-36" data-pjax-state="">
                                <img src={items.senderProfileURL} className="w-36 avatar circle bg-info-lt" />
                            </a>
                            <div className="chat-body">
                                <div className="SimplurisChatReceiverAttachmentSuccessBox SimpulrisChatTyping mt-1 SimplurisMouseRollOver">
                                    <a href={items.originalFileName} target="_blank" download>
                                    <div className="d-flex align-items-center">
                                        <span className="d-lg-block text-left">
                                            <span className="text-uppercase SimplurisOpacity04 SimplurisFontSize10">Attachment</span>
                                            <small className="d-block SimplurisSuccessAttachText SimplurisFontSize14 SimplurisFontWeight400">
                                                {items.fileName}
                                            </small>
                                        </span>
                                        <div className="avatar w-28 ml-1">
                                            
                                                <img src={downloadButtonImage} />
                                            
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {isHtmlContent.length > 0 && !IsNullOrEmptyString(items.htmlContent) ?
                            <div className="chat-item mb-2" data-class="null">
                                <a href="#" className="avatar w-36" data-pjax-state="">
                                    <img src={items.senderProfileURL} className="w-36 avatar circle bg-info-lt" />
                                </a>
                                <div className="chat-body">
                                    <div className="SimpulrisChatItemText">
                                        {isHtmlContent.length > 0 ? items.htmlContent : items.hTMLContent}
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                )
            }
        }
    } else {
        return <></>;
    }
}