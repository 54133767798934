import React from 'react'
import ContentLoader from 'react-content-loader'

const SideMenuFakeLoader = () => (
    <ContentLoader
        height={700}
        width={200}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb" >
        <rect x="20" y="16" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="23" r="10" />
        <rect x="20" y="51" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="58" r="10" />
        <rect x="20" y="86" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="93" r="10" />
        <rect x="20" y="121" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="128" r="10" />
        <rect x="20" y="156" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="163" r="10" />
        <rect x="20" y="191" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="198" r="10" />
        <rect x="20" y="226" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="233" r="10" />
        <rect x="20" y="261" rx="0" ry="0" width="100" height="15" />
        <circle cx="170" cy="268" r="10" />
    </ContentLoader>
)

export default SideMenuFakeLoader
