import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { appMode } from '../../../../../../api-config'
import { Doughnut } from "react-chartjs-2";

export const buildCurves = (start, end, line) => {    
    if (!start || !end || !line) {
        return null;
    }    
    //var calculatedDistance = calculateDistanceUsingLatitudeLongitude(start[0], start[1], end[1], end[0]).toFixed(1);
    var calculatedDistance = calculateDistanceUsingLatitudeLongitude(line.coordinates.start[1], line.coordinates.start[0], line.coordinates.end[1], line.coordinates.end[0]).toFixed(1);

    const x0 = start[0];
    const x1 = end[0];
    const y0 = start[1];
    const y1 = end[1];

    const curve = {
        forceUp: `${x1} ${y0}`,
        forceDown: `${x0} ${y1}`
    }[line.curveStyle];

    if (appMode == "sandbox") {
        if (calculatedDistance >= 1 && calculatedDistance < 300) {
            return `M ${start.join(" ")} Q 550,400 ${end.join(" ")}`;
        }
        else if (calculatedDistance >= 300 && calculatedDistance < 600) {
            return `M ${start.join(" ")} Q 550,250 ${end.join(" ")}`;
        }
        else if (calculatedDistance >= 600 && calculatedDistance < 750) {
            return `M ${start.join(" ")} Q 550,250 ${end.join(" ")}`;
        }
        else if (calculatedDistance >= 750 && calculatedDistance < 3400) {
            return `M ${start.join(" ")} Q 250,80 ${end.join(" ")}`;

        }
        else if (calculatedDistance >= 3401 && calculatedDistance < 15000) {
            return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        }
        else {
            return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        }
        //if (calculatedDistance < 5000) {
        //    return `M ${start.join(" ")} Q 100,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 5000 && calculatedDistance < 7000) {
        //    return `M ${start.join(" ")} Q 150,60 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 7000 && calculatedDistance < 7500) {
        //    return `M ${start.join(" ")} Q 500,10 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 7500 && calculatedDistance < 8000) {
        //    return `M ${start.join(" ")} Q 150,60 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 8000 && calculatedDistance < 8400) {
        //    return `M ${start.join(" ")} Q 150,60 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 8400 && calculatedDistance < 9000) {
        //    return `M ${start.join(" ")} Q 545,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 9000 && calculatedDistance < 11000) {
        //    return `M ${start.join(" ")} Q 150,60 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 11000 && calculatedDistance < 12000) {
        //    return `M ${start.join(" ")} Q 545,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 13000 && calculatedDistance < 15000) {
        //    return `M ${start.join(" ")} Q 300,250 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 15000 && calculatedDistance < 20000) {
        //    return `M ${start.join(" ")} Q 180,200 ${end.join(" ")}`;
        //}
    }
    else
    { 
        if (calculatedDistance >= 1 && calculatedDistance < 300) {            
            return `M ${start.join(" ")} Q 550,400 ${end.join(" ")}`;
        }
        else if (calculatedDistance >= 300 && calculatedDistance < 600) {            
            return `M ${start.join(" ")} Q 550,250 ${end.join(" ")}`;
        }
        else if (calculatedDistance >= 600 && calculatedDistance < 750) {
            return `M ${start.join(" ")} Q 550,250 ${end.join(" ")}`;
        }
        else if (calculatedDistance >= 750 && calculatedDistance < 3400) {            
            return `M ${start.join(" ")} Q 250,80 ${end.join(" ")}`;
            
        }
        else if (calculatedDistance >= 3401 && calculatedDistance < 15000) {
            return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        }
        else
        {
            return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        }
        //else if (calculatedDistance >= 1000 && calculatedDistance < 3000) {
        //    return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 3000 && calculatedDistance < 7000) {
        //    return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 7000 && calculatedDistance < 7500) {
        //    return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 7500 && calculatedDistance < 8000) {
        //    return `M ${start.join(" ")} Q 250,80 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 8000 && calculatedDistance < 8400) {
        //    return `M ${start.join(" ")} Q 150,60 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 8400 && calculatedDistance < 9000) {
        //    return `M ${start.join(" ")} Q 545,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 9000 && calculatedDistance < 11000) {
        //    return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 11000 && calculatedDistance < 12000) {
        //    return `M ${start.join(" ")} Q 545,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 8000 && calculatedDistance < 15000) {
        //    debugger;
        //    return `M ${start.join(" ")} Q 380,100 ${end.join(" ")}`;
        //}
        //else if (calculatedDistance >= 15000 && calculatedDistance < 20000) {
        //    return `M ${start.join(" ")} Q 250,100 ${end.join(" ")}`;
        //}
        //else {
        //    return `M ${start.join(" ")} Q 250,100 ${end.join(" ")}`;
        //}
    }
        
};
function calculateDistanceUsingLatitudeLongitude(lat1, lon1, lat2, lon2) {    
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2); var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}

export const getLines = history =>
    history != undefined ? history.reduce((lines, { points, ...mailing }) => {        
        var res = points;
        const coordsForItems =
            points != null
                ? points
                    .map(({ latitude, longitude, localkey, city, state, ...points }, i) => {                                                
                        if (i === 0) {
                            return null;
                        }
                        
                        const previousPoint = res[i - 1];                        
                        
                        return {
                            coordinates: {
                                start: [
                                    previousPoint != undefined ? Number(previousPoint.longitude) : 0,
                                    previousPoint != undefined ? Number(previousPoint.latitude) :0
                                ],
                                end: [Number(longitude), Number(latitude)]
                            },
                            localkeys: {
                                from: [previousPoint.localkey],
                                to: [localkey]
                            },
                            fromandtocitys: {
                                from: [{ 'city': previousPoint.city, 'state': previousPoint.state }],
                                to: [{ 'city': city, 'state': state }]
                            },
                            ...points,                            
                            ...mailing
                        };                        
                    })
                    .filter(x => x)
                : {
                    coordinates: {
                        start: [0, 0],
                        end: 0
                    }
                };        
        return lines.concat(coordsForItems);
    }, []) : [];

export const chartColors = {
    mailed: "#137eff",
    remailed: "#fe4d62",
    undeliverable: "#ffd14d"
};
export const chartTitles = {
    mailed: "Presumed Delivery",
    remailed: "Returned & Remailed",
    undeliverable: "Returned & Undeliverable"
};
const ChartTitle = styled.div`
  white-space: nowrap;
  position: absolute;
  left: 60px;
`;
const ChartPercentage = styled.div`
  color: ${props => props.color};
  font-size: 19px;
  font-weight: 400;
`;
export const getDoughnutChart = ({ number, color, title }) => {
    return (
        <div className="d-flex my-4" key={title}>
            <div>
                <Doughnut
                    height={50}
                    width={50}
                    data={{
                        datasets: [
                            {
                                data: [number, 100 - number],
                                backgroundColor: [color]
                            }
                        ]
                    }}
                    options={{
                        cutoutPercentage: 80,
                        legend: { display: false },
                        tooltips: { enabled: false },
                        hover: { mode: null },
                        maintainAspectRatio: false
                    }}
                />
            </div>
            <ChartTitle className="mx-3">
                <ChartPercentage color={color}>{number}%</ChartPercentage>
                <small className="text-muted d-block">{title}</small>
            </ChartTitle>
        </div>
    );
};

export const getTotalsForMailings = mailings => {
    const totals = mailings.reduce(
        (totals, mailing) => {
            const mailed = mailing.mailedCount - mailing.returnedCount;
            const remailed = mailing.remailedCount;
            const undeliverable = mailing.undeliverableCount;
            return {
                mailed: totals.mailed + mailed,
                remailed: totals.remailed + remailed,
                undeliverable: totals.undeliverable + undeliverable
            };
        },
        {
            mailed: 0,
            remailed: 0,
            undeliverable: 0
        }
    );
    const allMailingsTypesTotal = _.reduce(totals, _.add, 0);
    return {
        ...totals,
        allMailingsTypesTotal
    };
};
