import { simplurisFetch } from 'helpers'
import { start, resolved } from 'state/helpers'

export const FETCH_MAILINGS_HISTORY = 'FETCH_MAILINGS_HISTORY'
export const FETCH_MAILINGS_TOP_LEVEL_HOPS = 'FETCH_MAILINGS_TOP_LEVEL_HOPS'
export const FETCH_MAILINGS_HOP_DETAILS = 'FETCH_MAILINGS_HOP_DETAILS'
export const FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID = 'FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID'

// Update this when we have a url to return all data needed to display mail tracking map
export const fetchMailingsHistory = ({ caseID }) => async dispatch => {
    try {
        
		dispatch({ type: start(FETCH_MAILINGS_HISTORY) })
        
        const url = `cases/${caseID}/mailtrackingdata`
		const { resultObject } = await simplurisFetch({ url })
        
		dispatch({
			type: resolved(FETCH_MAILINGS_HISTORY),
			history: resultObject,
        })
        
    } catch (e) {
        
		console.error('Error in mailings history', e, e.errorObject)

		dispatch({
			type: resolved(FETCH_MAILINGS_HISTORY),
			history: [],
		})
	}
}

export const fetchMailTrackingDataByDrillDownTopLevelHops = ({ caseID }) => async dispatch => {
    try {        
        dispatch({ type: start(FETCH_MAILINGS_TOP_LEVEL_HOPS) })        
        const url = `cases/${caseID}/mailtrackingdatabydrilldowntoplevelhops`
        const { resultObject } = await simplurisFetch({ url })        
        dispatch({
            type: resolved(FETCH_MAILINGS_TOP_LEVEL_HOPS),
            MailTrackingTopLevelHops: resultObject,
        })

    } catch (e) {

        console.error('Error in mailtrackingdatabydrilldowntoplevelhops ', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_MAILINGS_TOP_LEVEL_HOPS),
            MailTrackingTopLevelHops: [],
        })
    }
}

export const fetchMailTrackingDataByDrillDownGetHopDetail = ({ caseID,fromlocalkey,tolocalkey }) => async dispatch => {
    try {        
        dispatch({ type: start(FETCH_MAILINGS_HOP_DETAILS) })        
        const url = `cases/${caseID}/mailtrackingdatabydrilldowngethopdetail/${fromlocalkey}/${tolocalkey}`
        const { resultObject } = await simplurisFetch({ url })        
        dispatch({
            type: resolved(FETCH_MAILINGS_HOP_DETAILS),
            MailTrackingHopsDetail: resultObject,
        })

    } catch (e) {

        console.error('Error in mailtrackingdatabydrilldowntoplevelhops ', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_MAILINGS_HOP_DETAILS),
            MailTrackingHopsDetail: [],
        })
    }
}

export const fetchMailTrackingDataByDrillDownGetDetailsByMailingHistoryID = (caseID, mailinghistoryID) => async dispatch => {
    try {        
        dispatch({ type: start(FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID) })        
        const url = `cases/${caseID}/mailtrackingdatabydrilldowngetdetailsbymailinghistoryid/${mailinghistoryID}`
        const { resultObject } = await simplurisFetch({ url })        
        dispatch({
            type: resolved(FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID),
            MailingHistoryDetail: resultObject,
        })        
    } catch (e) {

        console.error('Error in mailtrackingdatabydrilldowngetdetailsbymailinghistoryid ', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_MAILING_TRACKING_DETAILS_BY_MAILINGHISTORYID),
            MailingHistoryDetail: [],
        })
    }
}
