import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import AppRouter from './router'
import rootReducer from './state/rootReducer.js'
import HttpsRedirect from 'https-redirect';
import './global.js'

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f
    )
)

const render = Component => {
    return ReactDOM.render(
        <Provider store={store}>
            <HttpsRedirect>
                <Component />
            </HttpsRedirect>
        </Provider>,
        document.getElementById('root')
    )
}

render(AppRouter)

if (module.hot) {
    module.hot.accept('./router', () => {
        const NextApp = require('./router').default
        render(NextApp)
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
