import { createSelector } from 'reselect'
import _ from 'lodash'

const stateSelector = state => state.caseDetailView.distributions

export const getFilteredIDs = createSelector(
	[stateSelector],
	({ filteredIDs }) => filteredIDs
)

export const getDistributionIDFilters = createSelector(
	[stateSelector],
	({ distributionIDFilters }) => distributionIDFilters
)

export const getActiveDistributionIDFilters = createSelector(
	[stateSelector],
	({ distributionIDFilters }) =>
		_.map(distributionIDFilters, (value, key) => (value ? key : null)).filter(x => x)
)

export const getPaymentStatusFilters = createSelector(
	[stateSelector],
	({ paymentStatusFilters }) => paymentStatusFilters
)