import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../components/button-loader';

function AddDeniedReasonModalPopup(props) {
    const [showButtonLoader, setShowButtonLoader] = useState(false);
    
    
    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState(false);
    

    const [ErrorMessage, setErrorMessage] = useState('');
    function CloseAddBankModalPopup() {
        setErrorMessage('');
        setReason('');
        setReasonError(false);                
        props.setShowAddDeniedReasonPopup(false);
    } 
  
    const SaveDeniedReason = () => {        
        
        if (!reason ) {           
            setReasonError(true);
            return;
        }
        
        if (props.accountType == 'LiveCaseAccount') {
            props.UpdateStatusForLiveCaseAccountByPersonID(null,props.email,props.name,props.accountType, props.personIDForLiveCaseAccountORSandBoxAccount, false, reason);
        }
        else if ((props.accountType == 'SandboxAccount')) {
            props.UpdateStatusForLiveCaseAccountByPersonID(null,props.email, props.name,props.accountType, props.personIDForLiveCaseAccountORSandBoxAccount, false, reason);
        }
        else {
            props.UpdateStatusByPersonID(false, reason);
        }
        
    }

    return (
        <>
            <Modal show={props.OpenModalPopup} onHide={CloseAddBankModalPopup} size="md">
                <Modal.Header closeButton className="request-modal-title-b-b">
                    <Modal.Title>
                        <h5 className="modal-title SimplurisBidRequestTitle">                            
                            Add Reason
                    </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5">
                    <div className="row">
                        <div className="col-md-12 mb-1">
                            {ErrorMessage != "" ? <ErrorPlacement items={ErrorMessage} /> : ''}
                        </div>
                    </div>
                    <div className="d-flex flex row mt-2">
                      
                        <div className="col-12">
                            <div className={`form-group ${reasonError ? 'has-error' : ''}`}>
                                <label className="SimplurisFontSize16 SimplurisFontWeight500">Reason</label>
                                <div className="position-relative">
                                    <textarea type="text" className="form-control  SimplurisNewCaseMemebersInputBox"
                                        value={reason} onChange={e => (setReason(e.target.value), setReasonError(false))}
                                        placeholder="Enter Reason" rows="5"></textarea>                                    
                                    {reasonError ? <span>Please enter Reason</span> : null}
                                </div>
                            </div>

                         
                        </div>
                       
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w-sm btn-light py-3 mb-4 mt-3" onClick={SaveDeniedReason}>
                                {
                                    showButtonLoader && (
                                        <ButtonLoader />
                                    )
                                }
                                {showButtonLoader && <span></span>}
                                {!showButtonLoader && <span>Save</span>}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddDeniedReasonModalPopup

function ErrorPlacement(data) {
    if (data.items != "") {
        return <div id="dvLoginFailedMessage" className="alert alert-danger" role="alert">
            {data.items}
        </div>
    }
}
