import { FETCH_CASE_CALL_CENTER, CHANGE_CASE_CALL_CENTER_DATE_RANGE } from './actions'

const initialState = {
    dateRange: {
        start: null,
        end: null,
    },
}

export default (state = initialState, { type, caseCallCenter, dateRange }) => {
    switch (type) {        
        case FETCH_CASE_CALL_CENTER:
            return {
                ...state,                    
                    caseCallCenter,                   
            }            
        case CHANGE_CASE_CALL_CENTER_DATE_RANGE:            
            return { ...state, dateRange }
        default:
            return state
    }
}
