import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { simplurisFetch } from 'helpers'
import FakeSignImage from 'static/img/fake-signature-2.png'
import { SectionSpinner } from 'react/components/spinners'
import { getSelectedMemberID } from 'state/case-detail-view/members/selectors'
import CountUp from 'react-countup'

function PaymentDetailView({ caseID, selectedMemberID }) {
    const [paymentViewDetail, setPaymentViewDetail] = useState([]);
    const [isShowLoader, setIsShowLoader] = useState(false);

    const fetchresponsesDetail = () => {        
        setIsShowLoader(true);
        simplurisFetch({
            url: `cases/${caseID}/casemembers/paymentdetail/${selectedMemberID}`
        })
            .then(result => {                
                setIsShowLoader(false);                
                if (result.name != undefined && result.name == "FetchError") {
                } else {                    
                    setPaymentViewDetail(result.resultObject);
                }
            })
            .catch(e => {
                setIsShowLoader(false);
                console.error("Error in case members", e, e.errorObject);
            });
    };

    useEffect(() => {
        fetchresponsesDetail();
    }, []);


    if (!isShowLoader) {
        if (paymentViewDetail.length > 0) {
            return (
                <>
                    <div className="card SimplurisDashboardCard SimplurisDashbordSecondaryCard">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 col-sm-12">
                                <div className="p-4">
                                    <div className="SimplurisFontSize12 SimplurisFontWeight400 SimplurisDashbordSecondaryHeaderText SimplurisOpacity03">
                                        {paymentViewDetail[0].checkDateFormat}
                                                    </div>
                                    <div className="SimplurisFontSize16 SimplurisFontWeight400 SimplurisDashbordSecondaryHeaderText">
                                        Outbound Payment
                                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 col-sm-12">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 text-xl-right">
                                        <button
                                            className={
                                                'btn mr-0 mr-xl-4 ml-4 ml-xl-0 mb-4 mb-md-0 text-uppercase ' +
                                                (paymentViewDetail[0].paymentStatusTitle == 'Approved'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Printed'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Issued'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Cleared'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Reconciled'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Scheduled'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Processed'
                                                    ? 'Simpluris-btnGreen'
                                                    : '') +
                                                (paymentViewDetail[0].paymentStatusTitle == 'Void'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Stop Payment'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Cancelled'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Failed'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Returned'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Fraudulent'
                                                    ? 'red'
                                                    : '') +
                                                (paymentViewDetail[0].paymentStatusTitle == 'Pending'
                                                    || paymentViewDetail[0].paymentStatusTitle == 'Initiated'
                                                    ? 'Simluris-bg-orange-lt'
                                                    : '')
                                            }>
                                            {paymentViewDetail[0].paymentStatusTitle}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="col-md-12 col-xs-12 col-sm-12 p-4 bg-white SimplurisBorderRadius9">
                                <div className="p-1 SimplurisResponseDetailCheckBackgroudColor">
                                    <div className="m-2 p-3 SimplurisResponseDetailCheckBackgroudBorderColor">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="SimplurisFontSize14 SimplurisFontWeight700 Simpluris-text-color my-0">
                                                    {paymentViewDetail[0].officialCaseName}
                                                </div>
                                                <div className="SimplurisFontSize11 SimplurisFontWeight400 Simpluris-text-color my-0">c/o Simpluris, Inc.</div>
                                                <div className="SimplurisFontSize11 SimplurisFontWeight400 Simpluris-text-color my-0">PO Box 26170</div>
                                                <div className="SimplurisFontSize11 SimplurisFontWeight400 Simpluris-text-color my-0">Santa Ana, CA 92799</div>
                                                <div className="SimplurisFontSize11 SimplurisFontWeight400 Simpluris-text-color my-0">888.369.3780</div>
                                            </div>
                                            <div className="col-lg-5 text-md-right">
                                                <div className="SimplurisFontSize14 SimplurisFontWeight700 Simpluris-text-color my-0">{paymentViewDetail[0].checkNumber}</div>
                                                <div className="SimplurisFontSize14 Simpluris-text-color my-0">Bank Name Redacted</div>
                                                <div className="SimplurisFontSize11 SimplurisFontWeight400 Simpluris-text-color my-0">
                                                    {paymentViewDetail[0].checkDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <label className="SimplurisFontSize9 SimplurisFontWeight400 Simpluris-small-text-color my-0">Pay to the Order of</label>
                                                <label className="SimplurisFontSize29 SimplurisFontWeight400 Simpluris-text-color my-0 ml-1">
                                                    {paymentViewDetail[0].payeeName}
                                                </label>
                                                <hr className="Simpluris-payment-hr" />
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="SimplurisFontSize22 SimplurisFontWeight700 Simpluris-small-text-color my-0">$</label>
                                                <label className="SimplurisFontSize19 SimplurisFontWeight400 Simpluris-text-color my-0 ml-1 bg-white w-80">                                                    
                                                    <CountUp end={paymentViewDetail[0].checkAmount} separator="," duration={0.50} decimals={2}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10 mt-1 mt-md-0">
                                                <label className="SimplurisFontSize18 SimplurisFontWeight500 Simpluris-text-color my-0">
                                                    PAY {paymentViewDetail[0].amountText}
                                                </label>
                                                <hr className="Simpluris-payment-hr" />
                                            </div>
                                            <div className="col-lg-2">
                                                <label className="SimplurisFontSize17 SimplurisFontWeight400 Simpluris-small-text-color my-0">Dollars</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7 offset-md-5">
                                                <div class="d-flex align-items-end">
                                                    <label className="SimplurisFontSize9 SimplurisFontWeight400 Simpluris-small-text-color">Simulated Signature</label>
                                                    <img className="Simpluris-Signature ml-2" src={FakeSignImage} />
                                                </div>
                                                <hr className="Simpluris-payment-hr" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 offset-md-1 order-2 order-md-1">
                                                <div className="SimplurisFontSize14 SimplurisFontWeight400 Simpluris-text-color my-0">
                                                    52857269266 24646436673
                                                </div>
                                            </div>
                                            <div className="col-lg-5 text-md-right order-1 order-md-2">
                                                <div className="SimplurisFontSize11 SimplurisFontWeight400 Simpluris-text-color my-0">VOID AFTER {paymentViewDetail[0].voidAfterDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>
            )
        }
        else {
            return (
                <div className="card SimplurisDashboardCard p-4 mb-2 text-center">
                    No data found
            </div>
            )
        }
    }
    else {
        return (
            <div className="card SimplurisDashboardCard"><SectionSpinner /></div>
        )
    }
    
}

export default connect((state, props) => ({
    selectedMemberID: getSelectedMemberID(state, props),
}))(PaymentDetailView)