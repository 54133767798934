import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getDateRange } from 'state/call-center/selectors'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'
import CallCenterDatePicker from './date-picker'
import { fetchCallCenterData } from 'state/call-center/actions'
import CallStatisticsGrid from './call-statistics-grid'
import CallSummary from './call-summary'
import CallMapGrid from './call-map-grid'
import CallMap from './call-map'

const CaseDetailCallCenter = ({
    fetchCallCenterData,    
    caseCallCenter,
    dateRange,
    caseID,
    userPermissions,    
}) => {           
    useEffect(() => {
        try {
            fetchCallCenterData({ caseID })
        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [fetchCallCenterData, caseID])


    var viewPersonIdPermission    
    var viewFirstNamePermission
    var viewLastNamePermission
    var viewMemberDrilDownToDetail

    if (Object.keys(userPermissions).length > 0) {
       
        viewPersonIdPermission = userPermissions.find(
            permissionCode =>
                permissionCode == 'LC_CALLCENTER_VIEWDATA_PERSONID'
        )

        viewFirstNamePermission = userPermissions.find(
            permissionCode =>
                permissionCode == 'LC_CALLCENTER_VIEWDATA_FIRSTNAME'
        )
        viewLastNamePermission = userPermissions.find(
            permissionCode =>
                permissionCode == 'LC_CALLCENTER_VIEWDATA_LASTNAME'
        )

        viewMemberDrilDownToDetail = userPermissions.find(
            permissionCode =>
                permissionCode == 'LC_MEMBER_DRILL_DOWN_TO_DETAIL'
        )
    }


    if (!caseCallCenter) {
        return <div className="row"> <SectionSpinner /></div>
    }
    
    let caseCallCenterData = caseCallCenter;

    let minusSign = "-";

    
    // Filter call center data based on date filters selected if there are any   
    let ivrCalls, lpCalls, SpokenCalls, Bilingual = [];

    
    let callMap = [];
    let callMapForAbbrev = [];
    let callMapForDuration = [];
    let callMapForAudio = [];
    let callMapFinalArray = [];
    let allCallDataByState = [];
    let callDataAvailableCountForReleased;
    let totalCallsCountForDateRange;
    let countPerecentageForPerviousDays;

    
    if (dateRange.start && dateRange.end) {               
        const startDate = new Date(dateRange.start)
        const endDate = new Date(dateRange.end)
        
        caseCallCenter = caseCallCenter.filter((callData) => {
            return (new Date(callData.date) >= new Date(startDate)) && (new Date(callData.date) <= new Date(endDate))
        })

        ivrCalls = caseCallCenter.filter((x) => {
            if ((new Date(x.date) >= new Date(startDate)) && (new Date(x.date) <= new Date(endDate))) {
                return x.callTypeCode == "IVR"
            }
        })

        lpCalls = caseCallCenter.filter((x) => {
            if ((new Date(x.date) >= new Date(startDate)) && (new Date(x.date) <= new Date(endDate))) {
                return x.callTypeCode == "LP"
            }
        })

        let countForReleasedCall = caseCallCenter.filter((x) => {
            if ((new Date(x.date) >= new Date(startDate)) && (new Date(x.date) <= new Date(endDate))) {
                return x.isReleased == true
            }
        })
               
        if (parseInt(countForReleasedCall.length) > 0) {            
            callDataAvailableCountForReleased = parseFloat(((parseInt(caseCallCenter.length) - parseInt(countForReleasedCall.length)) / parseInt(countForReleasedCall.length)) * 100).toFixed(2)            
        }
        else
            callDataAvailableCountForReleased = 0;
        
        for (var i = 0; i < caseCallCenter.length; i++) {            
            var groupName = caseCallCenter[i].fromState;            
            if (!callMap[groupName]) {            
                callMap[groupName] = [];
                callMapForDuration[groupName] = [];
                callMapForAudio[groupName] = [];
                allCallDataByState[groupName] = [];
                callMapForAbbrev[groupName] = [];
            }
            
            callMap[groupName].push(caseCallCenter[i].callID);
            callMapForDuration[groupName].push(caseCallCenter[i].durationSeconds);
            callMapForAudio[groupName].push(caseCallCenter[i].audioURL);
            allCallDataByState[groupName].push(caseCallCenter[i]);
            callMapForAbbrev[groupName].push(caseCallCenter[i].abbrev);
        }

        var totalCountEveryStateWise = 0;
        for (var test in callMap) {
            totalCountEveryStateWise = parseInt(totalCountEveryStateWise + callMapForDuration[test].reduce(function (a, b) { return parseInt(a) + parseInt(b); }));
        }        

        var counter = 0;
        for (var groupName in callMap) {
            
            var total = 0;
            total = callMapForDuration[groupName].reduce(function (a, b) { return parseInt(a) + parseInt(b); });

            
            var totalPerecentageForStateWise = parseFloat(((parseInt(totalCountEveryStateWise) - parseInt(total)) / parseInt(total)) * 100).toFixed(2)

            var totalPerecentageForStateWise = parseFloat((parseInt(total) * 100) / parseInt(totalCountEveryStateWise)).toFixed(2);

            
            var totalCallTime = (total / callMap[groupName].length).toFixed(2);
            
            callMapFinalArray.push({
                fromState: groupName,
                noOfCalls: callMap[groupName].length + " Total Calls",
                avgCallTime: totalCallTime,
                resources: totalPerecentageForStateWise + "%",
                abbrev: callMapForAbbrev[groupName],
                callMapData: ({
                    counter: counter++,
                    data: allCallDataByState[groupName],
                    callMapAudioData: callMapForAudio[groupName]
                })    
            });
        }

        totalCallsCountForDateRange = parseInt(caseCallCenter.length);

        // To calculate the time difference of two dates 
        var Difference_In_Time = endDate.getTime() - startDate.getTime();
        
        // To calculate the no. of days between two dates 
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 

        var newdate = new Date(startDate);

        newdate.setDate(newdate.getDate() - Difference_In_Days);

        var lastDateForGenerateBeforeDays = new Date(newdate); 
        
        var newStartDate = new Date(startDate);

        newStartDate.setDate(newStartDate.getDate() - 1);

        let countAllReleaseCallForBeforeDates = caseCallCenterData.filter((x) => {
            if ((new Date(x.date) >= new Date(lastDateForGenerateBeforeDays)) && (new Date(x.date) <= new Date(newStartDate))) {
                return x.isReleased == true
            }
        })
        
        if (parseInt(countAllReleaseCallForBeforeDates.length) > 0) {                        
            countPerecentageForPerviousDays = parseFloat(((parseInt(caseCallCenter.length) - parseInt(countAllReleaseCallForBeforeDates.length)) / parseInt(countAllReleaseCallForBeforeDates.length)) * 100).toFixed(2)                        
        }
        else
            countPerecentageForPerviousDays = 0;

    }
    else
    {      

        
        for (var i = 0; i < caseCallCenter.length; i++) {                        
            var groupName = caseCallCenter[i].fromState;            
            if (!callMap[groupName]) {                
                callMap[groupName] = [];
                callMapForDuration[groupName] = [];
                callMapForAudio[groupName] = [];
                allCallDataByState[groupName] = [];
                callMapForAbbrev[groupName] = [];
            }
            
            callMap[groupName].push(caseCallCenter[i].callID);
            callMapForDuration[groupName].push(caseCallCenter[i].durationSeconds);
            callMapForAudio[groupName].push(caseCallCenter[i].audioURL);
            allCallDataByState[groupName].push(caseCallCenter[i]);
            callMapForAbbrev[groupName].push(caseCallCenter[i].abbrev);
        }

        
        var totalCountEveryStateWise=0;
        for (var test in callMap) {            
            totalCountEveryStateWise = parseInt(totalCountEveryStateWise + callMapForDuration[test].reduce(function (a, b) { return parseInt(a) + parseInt(b); }));
        }        

        var counter = 0;
        for (var groupName in callMap) {
            var total = 0;
            total = callMapForDuration[groupName].reduce(function (a, b) { return parseInt(a) + parseInt(b); });            
            var totalCallTime = (total / callMap[groupName].length).toFixed(2);
            
             var totalPerecentageForStateWise = parseFloat((parseInt(total) * 100) / parseInt(totalCountEveryStateWise)).toFixed(2);
            //var totalPerecentageForStateWise = parseFloat(((parseInt(totalCountEveryStateWise) - parseInt(total)) / parseInt(total)) * 100).toFixed(2)
            
            callMapFinalArray.push({
                fromState: groupName,
                noOfCalls: callMap[groupName].length + " Total Calls",
                avgCallTime: totalCallTime,
                resources: totalPerecentageForStateWise + "%",
                abbrev: callMapForAbbrev[groupName],
                callMapData: ({
                    counter: counter++,
                    data: allCallDataByState[groupName],
                    callMapAudioData: callMapForAudio[groupName]
                })                
            });
        }
        
        if (caseCallCenter.length > 0) {

            ivrCalls = caseCallCenter.filter((x) => x.callTypeCode == "IVR")
            lpCalls = caseCallCenter.filter((x) => x.callTypeCode == "LP")
            
            let countForReleasedCall = caseCallCenter.filter((x) => x.isReleased == true)
            
            callDataAvailableCountForReleased = parseFloat(((parseInt(caseCallCenter.length) - parseInt(countForReleasedCall.length)) / parseInt(countForReleasedCall.length)) * 100).toFixed(2)
        }

    }

    return (
        <div>
            <div className="mb-4">
                <CallCenterDatePicker />
            </div>
            <div>
                <div className="tab-content">
                    <div
                        className="tab-pane fade show active"
                        id="callsupport"
                        role="tabpanel"
                        aria-labelledby="callsupport-tab"
                    >
                <div className="d-flex flex row">
                    <div className="col-md-12">
                        <div className="card d-flex flex">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="p-3">
                                    <div className="SimplurisFontSize10 SimplurisFontWeight500 SimplurisOpacity03 SimplurisCallCenterActiveStatusMutedText">
                                        CALL DATA
                                     </div>
                                    <div className="d-flex my-3"><div className="SimpluriCallStatisticsRoundDiv mr-1 justify-content-center align-self-center"></div><div className="SimplurisCaseRightSideSectionTitle mb-0"># Of Calls </div></div>
                                    <CallSummary callDataResponse={caseCallCenter} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12 col-sm-12">

                        <div className="card d-flex flex">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="p-3">
                                    <div className="SimplurisFontSize10 SimplurisFontWeight500 SimplurisOpacity03 SimplurisCallCenterActiveStatusMutedText text-uppercase">
                                        Call Statistics
                                                </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-xl-6">
                                            <div className="py-4 d-flex align-items-center">
                                                <div>
                                                    <div className="mt-1 SimplurisFontSize26 SimplurisFontWeight500 SimplurisTextLighPrimary"><strong>{ivrCalls != undefined && ivrCalls.length}</strong></div>
                                                    <small className="text-muted SimplurisFontSize12 SimplurisFontWeight400 SimplurisOpacity08">IVR Calls</small>
                                                </div>
                                                <div className="flex"></div>                                              
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-xl-6">
                                            <div className="py-4 d-flex align-items-center">
                                                <div>
                                                    <div className="mt-1 SimplurisFontSize26 SimplurisFontWeight500 SimplurisTextLighPrimary"><strong>{lpCalls != undefined && lpCalls.length}</strong></div>
                                                    <small className="text-muted SimplurisFontSize12 SimplurisFontWeight400 SimplurisOpacity08">LP Calls</small>
                                                </div>
                                                <div className="flex"></div>                                                
                                            </div>
                                        </div>                                        
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                {
                                            <CallStatisticsGrid caseCallCenterData={caseCallCenter} viewPersonIdPermission={viewPersonIdPermission} viewFirstNamePermission={viewFirstNamePermission} viewLastNamePermission={viewLastNamePermission} viewMemberDrilDownToDetail={viewMemberDrilDownToDetail}/>
                                }
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="callmap" role="tabpanel" aria-labelledby="callmap-tab"> 
                        <div className="d-flex flex row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <div className="card d-flex flex">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="row align-items-center p-3 b-b">
                                            <div className="col-md-8 col-sm-8 col-lg-8 col-8">
                                                <div className="SimplurisFontSize19 SimplurisFontWeight700">
                                                    Caller Map
                                                    </div>
                                                <div className="SimplurisFontSize10 text-muted pt-1">
                                                    {parseFloat(callDataAvailableCountForReleased) > 0 ? callDataAvailableCountForReleased  : 0.00}% of call data available
                                                    </div>
                                            </div>
                                            {
                                                (dateRange.start && dateRange.end) &&                                               
                                                <div className="col-md-4 col-sm-4 col-sm-4 col-4">
                                                    <div className="SimplurisFontSize21 d-inline SimplurisFontWeight700">
                                                        {totalCallsCountForDateRange > 0 ? totalCallsCountForDateRange : 0}
                                                    </div>
                                                    <div className="d-inline pl-0 pl-md-0 pl-xl-3">
                                                        <span className="badge bg-secondary-lt px-2 py-2 SimplurisFontSize16">                                                                                                                        
                                                            {
                                                               countPerecentageForPerviousDays > 0 && countPerecentageForPerviousDays.indexOf(minusSign) != -1 ?
                                                                    <i class="mr-2 i-con i-con-arrow-down"><i></i></i> :
                                                                    <i className="mr-1 i-con i-con-arrow-up"><i></i></i>
                                                            }
                                                            {countPerecentageForPerviousDays}%
                                                        </span>
                                                    </div>
                                                </div>                                                    
                                            }                                            
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xs-12 col-sm-12">
                                        {
                                            <CallMap callMapData={callMapFinalArray} />
                                        }
                                    </div>
                                    <div className="col-md-12 col-xs-12 col-sm-12">
                                        {
                                            <CallMapGrid callMapGridData={callMapFinalArray} viewPersonIdPermission={viewPersonIdPermission} viewFirstNamePermission={viewFirstNamePermission} viewLastNamePermission={viewLastNamePermission} viewMemberDrilDownToDetail={viewMemberDrilDownToDetail}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(
    (state, props) => ({
        caseCallCenter: state.callCenter.caseCallCenter,
        dateRange: getDateRange(state),
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
    }),
    {
        fetchCallCenterData,
        changeCaseSubtab,
        setFilteredIDs,
        setPreviousCaseSubtab,
    }
)(CaseDetailCallCenter)
