import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import FeatureComingSoonModal from './feature-coming-soon-modal'
import SideMenuFakeLoader from '../../components/sidemenu-fake-loader'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { labFeatures } from '../../../api-config'

const GrayTextContent = styled.div`
  span:first-child {
    color: lightgray !important;
  }
`

function CaseDetailSidebar({
    caseDetailCounts,
    caseSubtab,
    changeCaseSubtab,
    userPermissions,
    caseID,
    setPreviousCaseSubtab,
    data
}) {

    const [showComingSoonModal, setShowComingSoonModal] = useState(false)

    var caseActivityPermission
    var mailTrackingPermission
    var distributionsPermission
    var callCenterPermission
    var caseWebsitePermission
    var liveReportPermission
    var mailTrackingPermission
    var memberPermission
    var milestonePermission
    var responsesPermission
    var teamPermission
    var filesPermission
    var reportPermission
    var campaignPermission

    if (Object.keys(userPermissions).length > 0) {

        caseActivityPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_ACTIVITY_VIEW_IN_MENU'
        )

        mailTrackingPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'MAILINGS_PAGE_READONLY'
        )

        distributionsPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_BANKINGDISTRIBUTION_VIEW_IN_MENU'
        )

        callCenterPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_CALLCENTER_VIEW_IN_MENU'
        )

        caseWebsitePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_CASEWEBSITE_VIEW_IN_MENU'
        )

        liveReportPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_LIVEREPORT_VIEW_IN_MENU'
        )

        mailTrackingPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MAILTRACKING_VIEW_IN_MENU'
        )


        memberPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MEMBER_VIEW_IN_MENU'
        )

        milestonePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MILESTONES_VIEW_IN_MENU'
        )

        responsesPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_RESPONSES_VIEW_IN_MENU'
        )

        teamPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_TEAM_VIEW_IN_MENU'
        )

        filesPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_FILES_VIEW_IN_MENU'
        )

        reportPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_REPORTS_VIEW_IN_MENU'
        )

        campaignPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_CAMPAIGNS_VIEW_IN_MENU'
        )


        if (caseSubtab == null) {
            if (liveReportPermission && liveReportPermission.length > 0)
                caseSubtab = 'live-report'
            else
                caseSubtab = 'activity'
        }

    }
    let isClosedStage = false;
    if (data && data.stage == "Closed") {
        isClosedStage = true;
    }

    return (
        <>
            <div
                id="aside"
                className="page-sidenav no-shrink  nav-expand   SimplurisCasePagesSideBarDiv"
                aria-hidden="true"
            >
                <div className="sidenav h-100 modal-dialog SimplurisDashboardBackgroudColour position-relative">
                    <a href="#" className="SimplurisCloseMenu"> <i className="i-con i-con-close position-absolute d-block d-lg-none d-xl-none SimplurisCloseIconSideBar"></i> </a>
                    <div className="flex scrollable hover">
                        <div
                            className="nav-border b-primary SimplurisCaseBankSidebarMenu"
                            data-nav
                        >
                            <ul className="nav bg SimplurisDashboardBackgroudColour SimplurisPaddingTop110">
                                {(() => {

                                    if (
                                        caseDetailCounts == undefined ||
                                        caseDetailCounts.length <= 0
                                    ) {
                                        return <SideMenuFakeLoader />
                                    } else {
                                        return (
                                            <>
                                                <li className="nav-header SimplurisCursorText hidden-folded pl-4">
                                                    <span className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition">
                                                        <span className="SimplurisSideBarMainTitleLink">
                                                            Case Menu
                            </span>
                                                    </span>
                                                </li>
                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4 ${
                                                        caseSubtab === 'live-report' ? 'active' : ''
                                                        } ${
                                                        liveReportPermission &&
                                                            liveReportPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('live-report')
                                                            setPreviousCaseSubtab('live-report')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition"
                                                    >
                                                        <span>Live Report</span>
                                                    </a>
                                                </li>
                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4 ${
                                                        caseSubtab === 'activity' ? 'active' : ''
                                                        } ${
                                                        caseActivityPermission &&
                                                            caseActivityPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('activity')
                                                            setPreviousCaseSubtab('activity')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0"
                                                    >
                                                        <span>Case Activity</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {caseDetailCounts.caseActivityCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>

                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                        caseSubtab === 'milestone' ? ' active' : ''
                                                        } ${
                                                        milestonePermission &&
                                                            milestonePermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('milestone')
                                                            setPreviousCaseSubtab('milestone')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition"
                                                    >
                                                        <span>Milestones</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {caseDetailCounts.milestonesCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>
                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                        caseSubtab === 'responses' ? ' active' : ''
                                                        } ${
                                                        responsesPermission &&
                                                            responsesPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('responses')
                                                            setPreviousCaseSubtab('responses')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition"
                                                    >
                                                        <span>Responses</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {caseDetailCounts.responsesCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>

                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                        caseSubtab === 'member' ||
                                                            caseSubtab === 'member-detail'
                                                            ? ' active'
                                                            : ''
                                                        } ${
                                                        memberPermission && memberPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('member')
                                                            setPreviousCaseSubtab('member')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition"
                                                    >
                                                        <span>Members</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {caseDetailCounts.membersCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>
                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4 ${
                                                        caseSubtab === 'mail-tracking' ? 'active' : ''
                                                        } ${
                                                        mailTrackingPermission &&
                                                            mailTrackingPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('mail-tracking')
                                                            setPreviousCaseSubtab('mail-tracking')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition"
                                                    >
                                                        <span>Mail Tracking</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {caseDetailCounts.mailTrackingCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>
                                                <li
                                                    className={
                                                        'nav-header SimplurisMouseRollOver hidden-folded pl-4' +
                                                        (caseSubtab === 'case-info' ? ' active' : '')
                                                    }
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('case-info')
                                                            setPreviousCaseSubtab('case-info')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition"
                                                    >
                                                        <span>Case Info</span>
                                                    </a>
                                                </li>
                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                        caseSubtab === 'team' ||
                                                            caseSubtab === 'team-member-detail'
                                                            ? ' active'
                                                            : ''
                                                        } ${
                                                        teamPermission && teamPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('team')
                                                            setPreviousCaseSubtab('team')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0 SimplurisCasesBankSideBarTransition"
                                                    >
                                                        <span>Team</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {caseDetailCounts.teamCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>
                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                        caseSubtab === 'distributions' ? ' active' : ''
                                                        } ${
                                                        distributionsPermission &&
                                                            distributionsPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('distributions')
                                                            setPreviousCaseSubtab('distributions')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0"
                                                    >
                                                        <span>Distributions</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {isClosedStage == true ? 0 : caseDetailCounts.distributionsCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>
                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                        caseSubtab === 'files' || caseSubtab === 'case-file-detail' ? ' active' : ''}
                                                        ${filesPermission && filesPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('files')
                                                            setPreviousCaseSubtab('files')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0"
                                                    >
                                                        <span>Files</span>
                                                        {(() => {
                                                            if (caseDetailCounts != null) {
                                                                return (
                                                                    <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                        <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                            {caseDetailCounts.secureFileCount}
                                                                        </b>
                                                                    </span>
                                                                )
                                                            }
                                                        })()}
                                                    </a>
                                                </li>

                                                <li
                                                    className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                        caseSubtab === 'reports' || caseSubtab === 'case-report-detail' ? ' active' : ''}
                                                        ${reportPermission && reportPermission.length > 0
                                                            ? ''
                                                            : 'd-none'
                                                        }`}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            changeCaseSubtab('reports')
                                                            setPreviousCaseSubtab('reports')
                                                        }}
                                                        className="SimplurisCasesBankSideBar p-0"
                                                    >
                                                        <span>Reports</span>
                                                        {(() => {
                                                            {/*if (caseDetailCounts != null) {*/ }
                                                            return (
                                                                <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                    <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                        {caseDetailCounts.reportCount}
                                                                    </b>
                                                                </span>
                                                            )
                                                            {/*}*/ }
                                                        })()}
                                                    </a>
                                                </li>

                                                {labFeatures &&
                                                    <>
                                                        <li
                                                            className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                                caseSubtab === 'campaigns' || caseSubtab === 'campaign-detail' ? ' active' : ''}
                                                        ${campaignPermission && campaignPermission.length > 0
                                                                    ? ''
                                                                    : 'd-none'
                                                                }`}
                                                        >
                                                            <a
                                                                onClick={() => {
                                                                    changeCaseSubtab('campaigns')
                                                                    setPreviousCaseSubtab('campaigns')
                                                                }}
                                                                className="SimplurisCasesBankSideBar p-0"
                                                            >
                                                                <span>Campaigns</span>
                                                            </a>
                                                        </li>


                                                        <li
                                                            className={`nav-header SimplurisMouseRollOver hidden-folded pl-4" ${
                                                                caseSubtab === 'call center' ? ' active' : ''
                                                                } ${
                                                                callCenterPermission &&
                                                                    callCenterPermission.length > 0
                                                                    ? ''
                                                                    : 'd-none'
                                                                }`}
                                                        >
                                                            <a
                                                                onClick={() => {
                                                                    changeCaseSubtab('call center')
                                                                    setPreviousCaseSubtab('call center')
                                                                }}
                                                                className="SimplurisCasesBankSideBar p-0"
                                                            >
                                                                <span>Call Center</span>
                                                                {(() => {
                                                                    if (caseDetailCounts != null) {
                                                                        return (
                                                                            <span className="nav-badge SimplurisSidebarInfo m-0">
                                                                                <b className="badge badge-sm badge-pill badge-primary SimplurisSidebarBadge">
                                                                                    {caseDetailCounts.callCenterCount}
                                                                                </b>
                                                                            </span>
                                                                        )
                                                                    }
                                                                })()}
                                                            </a>
                                                        </li>
                                                    </>}

                                            </>
                                        )
                                    }
                                })()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(
    (state, props) => ({
        caseSubtab: state.caseDetailView.subtab == '' ? null : state.caseDetailView.subtab,
        userPermissions: getPermissionsForCase(state, props),
    }),
    {
        changeCaseSubtab,
        setPreviousCaseSubtab,
    }
)(CaseDetailSidebar)
