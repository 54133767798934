import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { SectionSpinner } from 'react/components/spinners'
import { ReactComponent as Circle } from 'icons/circle.svg'
import { teal, lightGray, primaryBlue, red } from 'shared/colors'
import ScrollArea from 'react-scrollbar'

const Container = styled.div`
  //padding: 20px;
`
const Spacer = styled.div`
  height: 10px;
`
const SectionContainer = styled.div`
  flex: 1 1 0%;
`
const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Flex = styled.div`
  display: flex;
`
const StyledCircle = styled(Circle)`
  height: 20px;
  width: 20px;
  ellipse {
    stroke: ${props => (props.status === 'Mailed' ? teal : '#9fc1dd')};
  }
`
const Date = styled.div`
  color: ${lightGray};
`
const Status = styled.div`
  color: ${teal};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`

const SimplurisRecipientsMainWrraperDiv = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(135, 150, 165, 0.1);
  cursor: pointer;
&:hover {    
    background-color: rgb(246, 249, 254);
  }
`

const SimplurisRecipientsMailingHistoryTypeDiv = styled.div`
  color:${props => (props.status.toLowerCase() == 'sent' || props.status.toLowerCase() == 'forwarded' ? primaryBlue : props.status.toLowerCase() == 'returned' ? red : teal)};
  position: absolute;
  right: 26px;
    top: 15px;
`

const SimplurisRecipientsFilterWrraperUL = styled.ul`
`

const SimplurisRecipientsFilterLIDiv = styled.li`
list-style-type: none;
cursor: pointer;
color:${primaryBlue}

&.active{
color:${lightGray}
}
`
const MailingRecipientsList = ({ mailTrackingHopsDetailData, caseID, MailingRecipientsData, getMailTrackingDdataByMailingHistoryID, apiFlageFetchingMailingHopsDetails }) => {    
    let scrollbarStyles = { borderRadius: 4 };
    const [filterActiveClass, setFilterActiveClass] = React.useState('All');    

    function FilterMailingrecipients(status) {
        setFilterActiveClass(status);                       
    }   


    if (mailTrackingHopsDetailData != undefined && mailTrackingHopsDetailData.length > 0) {           
        const newFilterArray = [...new Set(MailingRecipientsData.map(x => x.mailingHistoryTypeTitle))];                        
        if (filterActiveClass != 'All') {
            MailingRecipientsData = MailingRecipientsData.filter(function (itm) {
                return filterActiveClass == itm.mailingHistoryTypeTitle;
            });
        }    
        return (
            <Container className="">
                <div className="b-b SimplurisFullScreenMapSectionRightSideSectionFilterMailing">
                    <ul className="d-flex pl-0 mb-1">
                        <SimplurisRecipientsFilterLIDiv onClick={() => FilterMailingrecipients('All')} className={`pr-1 SimplurisFontSize12 ${filterActiveClass == 'All' ? 'active' : ''}`}>All</SimplurisRecipientsFilterLIDiv>
                        {
                            newFilterArray.map(item => (
                                <SimplurisRecipientsFilterLIDiv onClick={() => FilterMailingrecipients(item)} className={`px-1 SimplurisFontSize12 ${filterActiveClass == item ? 'active' : ''}`}> {item === 'Mailed'
                                    ? 'Delivered'
                                    : item}</SimplurisRecipientsFilterLIDiv>
                            ))}
                    </ul>
                </div>
                <ScrollArea
                    className="area"
                    contentClassName="content"
                    vertical={true}
                    verticalScrollbarStyle={scrollbarStyles}
                    verticalContainerStyle={scrollbarStyles}
                    horizontal={false}
                    smoothScrolling={true}
                    style={{ height: 'calc(100vh - 410px)' }}>
                    {
                        MailingRecipientsData.map(item => (
                            <SimplurisRecipientsMainWrraperDiv onClick={() => getMailTrackingDdataByMailingHistoryID(item.mailingHistoryID)} status={item.mailingHistoryTypeTitle} className="SimplurisRecipientsMainWrraperDiv SimplurisFullScreenMapSectionRightSideSectionFilterMailing">
                                <div className="SimplurisFontSize12 SimplurisFontWeight500">
                                    {item.recipientName}
                                </div>
                                <div className="SimplurisFontSize12 SimplurisFontWeight400">
                                    {item.mailingTitle}
                                </div>
                                <div className="SimplurisFontSize12 SimplurisFontWeight400">
                                    Case #{caseID} - {localStorage.getItem(global.caseOfficialName)}
                                </div>
                                <SimplurisRecipientsMailingHistoryTypeDiv status={item.mailingHistoryTypeTitle} className="SimplurisRecipientsMailingHistoryTypeDiv text-uppercase SimplurisFontWeight500 SimplurisFontSize10">
                                    {item.mailingHistoryTypeTitle === 'Mailed'
                                        ? 'Delivered'
                                        : item.mailingHistoryTypeTitle}
                                </SimplurisRecipientsMailingHistoryTypeDiv>
                            </SimplurisRecipientsMainWrraperDiv>
                        ))}
                </ScrollArea>
                
            </Container>
        )
    }
    else {        
        return <></>
    }

}

export default MailingRecipientsList
