import {
    FETCH_CASE_LIVE_REPORT,
    CHANGE_CASE_LIVE_REPORT_DATE_RANGE,
    FETCH_CASE_LIVE_REPORT_RESPONSE_SUMMARY,
    FETCH_CASE_LIVE_REPORT_DISTRIBUTION,
    FETCH_CASE_LIVE_REPORT_MAILINGHISTORY_SUMMARY
} from './actions'

const initialState = {
    dateRange: {
        start: null,
        end: null,
    },
}


export default (state = initialState, { type, caseLiveReport, dateRange, caseLiveReportResponses, caseLiveReportDistribution, caseLiveReportMailingHistory }) => {
    switch (type) {
        case FETCH_CASE_LIVE_REPORT:
            return {
                ...state,
                [caseLiveReport.caseID]: caseLiveReport,
            }
        case CHANGE_CASE_LIVE_REPORT_DATE_RANGE:            
            return { ...state, dateRange }
        case FETCH_CASE_LIVE_REPORT_RESPONSE_SUMMARY:
            return {
                ...state,
                responses: {
                    [caseLiveReportResponses.caseID]: caseLiveReportResponses,
                }
            }
        case FETCH_CASE_LIVE_REPORT_DISTRIBUTION:
            return {
                ...state,
                [caseLiveReportDistribution.caseID]: {
                    distributions: caseLiveReportDistribution.distributions,
                }
            }
        case FETCH_CASE_LIVE_REPORT_MAILINGHISTORY_SUMMARY:
            return {
                ...state,
                mailings: {
                    [caseLiveReportMailingHistory.caseID]: caseLiveReportMailingHistory,
                }
            }
        default:
            return state
    }
}
