import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

import ButtonLoader from '../../components/button-loader'
import CaseSideBar from '../../components/case-sidebar'
import DashboardHeader from '../../components/dashboard-header'
import { fetchCases, resetCases } from 'state/cases/data/actions'
import { fetchCasesCountByStage } from 'state/case-stage-filter-count/actions'
import { getCasesForStage } from './helpers'
import CasesSection from './case-section'
import { SectionSpinner } from 'react/components/spinners'
import { RemoveDuplicateObject, AnlyticsPageLog } from '../../../global'
import { labFeatures, appMode } from '../../../api-config'
import { changeCaseSearchTerm } from 'state/cases/view/actions'
import { getSearchTerm, getLastCaseID, getIsHasNewData, getFilteredCases } from 'state/cases/view/selectors'

const SearchWrapper = styled.div`
  display: inline-block;
  margin-left: 40px;
  width: 500px;
  max-width: 50%;
  position: relative;
`
const ClearSearchButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  svg {
    display: block;
  }
`

// even though we want the total counts in the sidebar, the pagination for each call should be limited to 100 since it is slow to populate in react.
let _pageSize = 100

function CasesDashboard({
    cases,
    fetchCases,
    caseStageFilter,
    lastCaseId,
    isHasNewData,
    fetchCasesCountByStage,
    caseStageCounts,
    changeCaseSearchTerm,
    caseSearchTerm,
    resetCases,
}) {
    
    let casesForStage = getCasesForStage({ cases, stage: caseStageFilter })
    //let lastCaseId = 0
    let hasNewPage = false
    let isHasRecordInRedux = false
    
    const [showPageLoader, setShowPageLoader] = useState(false)
    const [showButtonLoader, setShowButtonLoader] = useState(false)
    const [isCloseClick, setIsCloseClick] = useState(false)
    
    if (casesForStage.length) {
        if (cases[caseStageFilter] == undefined) {
            isHasRecordInRedux = false
        } else {
            isHasRecordInRedux = true
        }
    } else {
        isHasRecordInRedux = false
    }

    if (casesForStage.length > 0) casesForStage.reverse()

    casesForStage = casesForStage.filter(function (v) {
        return v.caseID > 0
    })

    casesForStage = RemoveDuplicateObject(casesForStage, 'caseID')

    casesForStage = RemoveDuplicateObject(casesForStage, 'caseID')

    //lastCaseId =
    //  casesForStage.length > 0
    //    ? casesForStage[casesForStage.length - 1].caseID
    //    : 0

    //if (
    //  casesForStage.length >= _pageSize &&
    //  casesForStage.length % _pageSize == 1
    //) {
    //  casesForStage = casesForStage.slice(0, -1)
    //  hasNewPage = true
    //}
    const deboucedChangeSearchTerm = _.debounce(changeCaseSearchTerm, 250)
    const inputRef = React.useRef(null)

    useEffect(() => {
        fetchCasesCountByStage()
    }, [])

    useEffect(() => { 
        resetCases(cases);
        lastCaseId = 0
        if (caseSearchTerm.length >= 3) {
            setShowPageLoader(true);
            fetchCases({
                caseStageFilter,
                lastCaseId,
                _pageSize,
                searchTerm: caseSearchTerm,
            }).then(() => setShowPageLoader(false))
        }
        else if (!isCloseClick) {
            setShowPageLoader(true);
            fetchCases({
                caseStageFilter,
                lastCaseId,
                _pageSize,
                searchTerm: caseSearchTerm,
            }).then(() => setShowPageLoader(false))
        } else if (!caseSearchTerm) {
            setShowPageLoader(true);
            fetchCases({
                caseStageFilter,
                lastCaseId,
                _pageSize,
                searchTerm: caseSearchTerm,
            }).then(() => setShowPageLoader(false))
        }    
        AnlyticsPageLog('Cases page')
    }, [caseStageFilter, caseSearchTerm])

    const LoadMoreCases = async () => {
        setShowButtonLoader(true)
        await fetchCases({ caseStageFilter, lastCaseId, _pageSize })
        setShowButtonLoader(false)
    }

    const ClearSearchTextBox = () => {
        setIsCloseClick(true)
        inputRef.current.value = ''    
        setTimeout(function () {
            changeCaseSearchTerm('')
        }, 500);        
        
        setTimeout(function () {
            setIsCloseClick(false);
        }, 1000);
    }
    
    return (
        <div>
            <DashboardHeader />
            <div
                id="main"
                className="layout-row flex simplurisallcasesBodyBackgroudColour"
            >
                <CaseSideBar data={caseStageCounts} />
                <div id="content" className="flex ">
                    <div className="page-container" id="page-container">
                        <div className="page-title padding pb-0 pt-0">
                            <div className="text-md mb-2 SimplurisFontSize26 SimplurisMilestoneLables">
                                My Cases
                <SearchWrapper>
                                    <input
                                        ref={inputRef}
                                        value={caseSearchTerm}
                                        disabled={isCloseClick}
                                        className="form-control SimplurisNewCaseMemebersInputBox"
                                        placeholder="Search Cases"
                                        onChange={e => {
                                            const inputValue = e.target.value                                            
                                            if (inputValue) {
                                                changeCaseSearchTerm(e.target.value)
                                            } else {                                                
                                                changeCaseSearchTerm('')
                                            }
                                        }}
                                    />
                                    {!!caseSearchTerm && (
                                        <ClearSearchButton
                                            onClick={() => ClearSearchTextBox()}
                                        >
                                            <FontAwesomeIcon icon={faTimesCircle} size="xs" />
                                        </ClearSearchButton>
                                    )}
                                </SearchWrapper>
                            </div>
                            {!showPageLoader &&
                                isHasRecordInRedux &&
                                casesForStage.length === 0 && (
                                    <div className="text-md mb-0 SimplurisCasesPageTitle">
                                        no cases to display
                  </div>
                                )}
                        </div>
                        <div className="padding pt-3">
                            <div className="row">
                                <div className="col-md-8 col-xs-12 col-sm-12">
                                    {showPageLoader ? (
                                        <div className="col-md-12 col-12">
                                            <div className="row">
                                                <SectionSpinner height={510} />
                                            </div>
                                        </div>
                                    ) : null}
                                    {isCloseClick ? (
                                        <div className="col-md-12 col-12">
                                            <div className="row">
                                                <SectionSpinner height={510} />
                                            </div>
                                        </div>
                                    ) : null}
                                    {casesForStage.length > 0
                                        ? casesForStage.map(item => (
                                            <React.Fragment key={item.caseID}>
                                                <CasesSection item={item} searchTerm={caseSearchTerm} />
                                            </React.Fragment>
                                        ))
                                        : null}
                                    <div className="col-md-12 mt-2 text-center">
                                        {isHasNewData ? (
                                            <button
                                                className="btn w-md mb-4 mb-md-1 btn-light SimplurisDashbordPostText p-3"
                                                onClick={LoadMoreCases}
                                                disabled={showButtonLoader}
                                            >
                                                {showButtonLoader && <ButtonLoader />}
                                                {showButtonLoader && <span></span>}
                                                {!showButtonLoader && <span>Load More</span>}
                                            </button>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-3 col-xs-12 col-sm-12">
                                    {labFeatures && (
                                        <div>
                                            <input
                                                type="button"
                                                name="btnNewBidRequest"
                                                value="New Bid Request"
                                                className="btn SimplurisAllCasesRightSideButton btn-lg btn-block"
                                            />
                                        </div>
                                    )}
                                    <div className="pt-3">
                                        {
                                            appMode != "sandbox" ? <Link
                                                to={`/request-call`}
                                                className="btn SimplurisAllCasesRightSideButton btn-lg btn-block"
                                            >
                                                Schedule Consult
                    </Link> : <></>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(
    (state, props) => ({
        cases: getFilteredCases(state, props),
        lastCaseId: getLastCaseID(state, props),
        isHasNewData: getIsHasNewData(state, props),
        caseStageFilter: state.myCasesView.caseStageFilter,
        caseStageCounts: state.casesStageFilterCount,
        caseSearchTerm: getSearchTerm(state, props),
    }),
    {
        fetchCases,
        fetchCasesCountByStage,
        changeCaseSearchTerm,
        resetCases,
    }
)(CasesDashboard)
