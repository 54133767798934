import { simplurisFetch } from 'helpers'

export const FETCH_CASE_RESPONSES = 'FETCH_CASE_RESPONSES'
export const CHANGE_CASE_RESPONSE_DATE_RANGE = 'CHANGE_CASE_RESPONSE_DATE_RANGE'
export const CHANGE_CASE_RESPONSE_FILTERS = 'CHANGE_CASE_RESPONSE_FILTERS'

// Defaults requested by Shawn for now. Might need to update in the future based on payload size.
export const fetchResponses = ({
	caseID,
	page = 1,
	pageSize = 9999999,
	responseID = 0,
	sortOrder = 'desc',
	searchText = null,
}) => async dispatch => {
    try {
        
        const url = `cases/${caseID}/responses/${page}/${pageSize}/${responseID}/${sortOrder}/${searchText}`
        const { resultObject } = await simplurisFetch({ url })
        
		dispatch({
			type: FETCH_CASE_RESPONSES,
            ...resultObject,
            caseID
		})
	} catch (e) {
        console.error('Error in responses', e, e.errorObject);
	}
}

export const changeResponseDateRange = dateRange => ({
    type: CHANGE_CASE_RESPONSE_DATE_RANGE,
    dateRange,
})

export const changeResponseFilters = filters => ({
  type: CHANGE_CASE_RESPONSE_FILTERS,
  filters,
})