import { createSelector } from 'reselect'

import {
  getFilteredIDs,
  getActiveDistributionIDFilters,
  getPaymentStatusFilters,
} from 'state/case-detail-view/distributions/selectors'

const stateSelector = state => state.distributions
const caseIDSelector = (state, { caseID }) => caseID

const getDistributionsForCase = createSelector(
  [stateSelector, caseIDSelector],
  (state, caseID) => state[caseID]
)

export const getPaymentsForCase = createSelector(
  [stateSelector, caseIDSelector],
  (state, caseID) =>
    state[caseID] ? Object.values(state[caseID].payments) : null
)

export const getDistributionDataForCase = createSelector(
  [stateSelector, caseIDSelector],
  (state, caseID) =>
    state[caseID] ? Object.values(state[caseID].distributionData) : null
)

export const getPaymentsForCaseLength = createSelector(
  [getPaymentsForCase],
  payments => (payments ? payments.length : 0)
)

export const getPaymentsByIDForCase = createSelector(
  [stateSelector, caseIDSelector],
  (state, caseID) => (state[caseID] ? state[caseID].payments : null)
)

export const getFilteredPaymentsForCase = createSelector(
  [
    getPaymentsByIDForCase,
    getFilteredIDs,
    getActiveDistributionIDFilters,
    getPaymentStatusFilters,
  ],
  (
    payments,
    filteredIDs,
    activeDistributionIDFilters,
    paymentStatusFilters
  ) => {
    if (!payments) {
      return null
    }

    const filteredByID =
      filteredIDs && filteredIDs.length
        ? filteredIDs.map(id => payments[id])
        : Object.values(payments)

    return filteredByID
      .filter(payment =>
        activeDistributionIDFilters.includes(String(payment.distributionID))
      )
      .filter(({ paymentStatus }) => paymentStatusFilters[paymentStatus])
  }
)

export const getUncashedPayments = createSelector(
  [getDistributionsForCase],
  distributions => distributions ? distributions.uncashedPayments : null
)

export const getTotalDisbursed = createSelector(
  [getDistributionsForCase],
  distributions => distributions ? distributions.totalDisbursed : null
)