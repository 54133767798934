import { createSelector } from 'reselect'

import { caseIDFromPropsSelector } from 'state/helpers'

const stateSelector = state => state.permissions

export const getPermissionsForCase = createSelector(
  [stateSelector, caseIDFromPropsSelector],
  (state, caseID) => state[caseID] || []
)
