import { FETCH_CASE_CALL_RELEASE} from 'state/admin/call-release/actions'
import { FETCH_ACCOUNT_REQUESTS } from 'state/admin/account-requests/actions'
import { FETCH_MYCASES,FETCH_USERS } from 'state/admin/user-management/actions'
import { FETCH_EXCEPTIONLOG, FETCH_APPLOG } from 'state/admin/log/actions'

import { start, resolved } from 'state/helpers'


const initialState = {
    fetching: false,   
    isFetchingAccountRequests: false,
    isFetchingMyCases: false,
    isFetchingAllUsers:false,
 isFetchinAllExceptionLog: false,
    isFetchinAllAppLog:false
}

export default (state = initialState, { type}) => {        
    
    switch (type) {        
        case start( FETCH_CASE_CALL_RELEASE):            
            return { ...state, fetching: true }
        case resolved(FETCH_CASE_CALL_RELEASE):            
            return { ...state, fetching: false }
        case start(FETCH_ACCOUNT_REQUESTS):
            return { ...state, isFetchingAccountRequests: true }
        case resolved(FETCH_ACCOUNT_REQUESTS):
            return { ...state, isFetchingAccountRequests: false }
        case start(FETCH_MYCASES):
            return { ...state, isFetchingMyCases: true }
        case resolved(FETCH_MYCASES):
            return { ...state, isFetchingMyCases: false }
        case start(FETCH_USERS):
            return { ...state, isFetchingAllUsers: true }
        case resolved(FETCH_USERS):
            return { ...state, isFetchingAllUsers: false }
case start(FETCH_EXCEPTIONLOG):
            return { ...state, isFetchinAllExceptionLog: true }
        case resolved(FETCH_EXCEPTIONLOG):
            return { ...state, isFetchinAllExceptionLog: false }
        case start(FETCH_APPLOG):
            return { ...state, isFetchinAllAppLog: true }
        case resolved(FETCH_APPLOG):
            return { ...state, isFetchinAllAppLog: false }
        default:
            return state
    }
}