import * as React from 'react'
import { connect } from 'react-redux'
import CaseActivityContent from './case-activity'

const HeaderContent = ({ caseDetail }) => {    
    return <CaseActivityContent caseDetail={caseDetail} />			
}

export default connect(state => ({	
}))(HeaderContent)
