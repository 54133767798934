import React, { useState, useEffect } from 'react';
import { fetchMailingsGeneral } from 'state/mailings/other/actions'
import { connect } from 'react-redux'
import Lock from '../../../../../static/img/lock.png'
import ChangeRequestModalPopup from 'react/components/change-request'
import { SectionSpinner } from 'react/components/spinners';
import { getFetchingGeneral, getMailingsGeneral, getSelectedMailingID } from 'state/case-detail-view/mailings/other/selectors'
import { appMode } from '../../../../../api-config'
import { IsNullOrEmptyString } from '../../../../../global';

const GeneralTab = ({ data, isFetchingMailingGeneral, fetchMailingsGeneral, MailingsGeneral, setMailingTypeTitle, setMailingTitlePrefixTitle, setMailingTitle, caseID, caseName, personID, caseManagerEmail, selectedMailingID }) => {
    const noMailingsGeneral = !MailingsGeneral || !MailingsGeneral.length
    const showLoader = isFetchingMailingGeneral && noMailingsGeneral
    const showNullState = !isFetchingMailingGeneral && noMailingsGeneral

    if (!noMailingsGeneral) {
        setMailingTypeTitle(MailingsGeneral[0].mailingTypeTitle)
        setMailingTitlePrefixTitle(MailingsGeneral[0].mailingTitlePrefixTitle)
        setMailingTitle(MailingsGeneral[0].mailingTitle)
    }

    const [showChangeRequestModalPopup, setShowChangeRequestModalPopup] = useState(false);
    const [AttributesValue, setAttributesValue] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    //CONTROL TYPE 1=> TEXTBOX, 2=> DROPDOWN
    function OpenChangeRequestModalPopup(data, tableName, tableColumn, involvedRecordID, controlType, Type, mailingID) {
        setAttributesValue({ data, tableName, tableColumn, involvedRecordID, controlType, Type, mailingID });
        setShowChangeRequestModalPopup(true);
        setShowMessage(false);
    }

    React.useEffect(() => {
        fetchMailingsGeneral({ caseID, mailingID: selectedMailingID })
    }, [])


    return (
        <>
            {showLoader && <div className="card SimplurisDashboardCard">
                <SectionSpinner /></div>}
            {showNullState && <div className="text-center">No mailings generals available</div>}
            {
                !noMailingsGeneral &&
                <>
                    <div className="d-flex flex row">
                        <div className="col-md-12">
                            <div className="card d-flex flex mb-0">
                                <div className="col-md-12 px-4">
                                    <span className="SimplurisSubTabTitle">Settings</span>
                                </div>

                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Perform NCOA Before Sending Out Mailing?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].runNCOABeforeMailing == 1 ? "checked" : null} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Affects Deliverable Status?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].returnMailAffectPersonDeliverableStatus == 1 ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Limit Remails to Previously Mailed To History?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].isLimitedRemail == 1 ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Remails May Be Sent Out Via Mail?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].isAllowedViaMail == true ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Remails May Be Sent Out Via Fax?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].isAllowedViaFax == true ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Remails May Be Sent Out Via Email?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].isAllowedViaEmail == true ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Responses Returned Via No. 9 Envelope?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].isReturnMethodNo9Envelope == 1 ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Responses Returned Via Postcard?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].isReturnMethodPostCard == 1 ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="col-md-12 px-4 py-2">
                                    <div className="row">
                                        <label className="col-sm-11 col-form-label">Capture Writing on Outside of Response Envelope?</label>
                                        <div className="col-sm-1">
                                            <label className="ui-switch ui-switch-md info m-t-xs">
                                                <input type="checkbox" checked={MailingsGeneral[0].isReturnMethodWritingOnEnvelope == 1 ? "checked" : false} />
                                                <i></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex row mt-4">
                        <div className="col-md-12">
                            <div className="card d-flex flex mb-0">
                                <div className="col-md-12 px-4">
                                    <span className="SimplurisSubTabTitle">Attributes</span>
                                </div>
                                <hr className="m-0" />
                                <div className="p-1">
                                    <div className="px-4 py-4">
                                        <div className="row mb-4">
                                            <div className="col-lg-6 position-relative">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Mailing Title</label>
                                                <input type="text" readOnly defaultValue={`${MailingsGeneral[0].mailingTitlePrefixTitle}` + (!IsNullOrEmptyString(MailingsGeneral[0].mailingTitlePrefixTitle) && !IsNullOrEmptyString(MailingsGeneral[0].mailingTitle) ? ' - ' : '') + (!IsNullOrEmptyString(MailingsGeneral[0].mailingTitle) ? `${MailingsGeneral[0].mailingTitle}` : '') } className="form-control SimplurisMatrialTextBox" />
                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Mailing Description</label>
                                                <label className={"float-right SimplurisEggBlueText SimplurisFontSize12 " + (showMessage == true ? 'd-block' : 'd-none')}>Saved</label>
                                                <textarea defaultValue={MailingsGeneral[0].mailingDescription} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].mailingDescription, "dbo.Mailing", "MailingDescription", MailingsGeneral[0].mailingID, 1, null, MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Special Instructions</label>
                                                <textarea defaultValue={MailingsGeneral[0].specialInstructions}  className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].specialInstructions, "dbo.Mailing", "SpecialInstructions", MailingsGeneral[0].mailingID, 1, null, MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Mailiing Type</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].mailingTypeTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].mailingTypeTitle, "dbo.MailingType", "MailingTypeID", MailingsGeneral[0].mailingTypeID, 2, "MailingType", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Instruct USPS to</label>
                                                <textarea defaultValue={MailingsGeneral[0].uspsacsServiceTypeTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].uspsacsServiceTypeTitle, "dbo.Mailing", "USPSACSServiceTypeID", MailingsGeneral[0].uspsacsServiceTypeID, 2, "MailingServiceType", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Notification Type</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].notificationTypeTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].notificationTypeTitle, "dbo.NotificationType", "NotificationTypeID", MailingsGeneral[0].notificationTypeID, 2, "NotificationType", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Remail Type</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].remailTypeTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].remailTypeTitle, "dbo.RemailType", "ReMailTypeID", MailingsGeneral[0].remailTypeID, 2, "RemailType", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Return Mail to</label>
                                                <input type="text" readOnly defaultValue={MailingsGeneral[0].mailingReturnMailAddressTitle} className="form-control SimplurisMatrialTextBox" />
                                                {/*<span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a href="void:javasacript(0);" onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].mailingReturnMailAddressTitle, "dbo.MailingReturnMailAddress", "Title", MailingsGeneral[0].mailingReturnMailAddressID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>*/}
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">#9 Envelope Postage Type</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].no9EnvelopeMailingPostageTypeTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].no9EnvelopeMailingPostageTypeTitle, "dbo.MailingPostageType", "No9EnvelopeMailingPostageTypeID", MailingsGeneral[0].no9EnvelopeMailingPostageTypeID, 2, "MailingPostageType", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Postcard Postage Type</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].postCardMailingPostageTypeTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].postCardMailingPostageTypeTitle, "dbo.MailingPostageType", "PostCardMailingPostageTypeID", MailingsGeneral[0].postCardMailingPostageTypeID, 2, "MailingPostageType", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Translation Included</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].mailingTranslationLanguageTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].mailingTranslationLanguageTitle, "dbo.MailingTranslationLanguage", "MailingTranslationLanguageID", MailingsGeneral[0].mailingTranslationLanguageID, 2, "MailingTranslationLanguage", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Envelope Type</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].mailingEnvelopeTypeTitle} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].mailingEnvelopeTypeTitle, "dbo.MailingEnvelopeType", "MailingEnvelopeTypeID", MailingsGeneral[0].mailingEnvelopeTypeID, 2, "MailingEnvelopeType", MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 ">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Mailing Status</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].mailingStatusTitle} className="form-control SimplurisMatrialTextBox" />

                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 ">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09">Initial Mail Date</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].initialFormatedMailDate} className="form-control SimplurisMatrialTextBox" />

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09"># Skip Traces Allowed</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].skipTraceAllowed} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].skipTraceAllowed, "dbo.Mailing", "SkipTraceAllowed", MailingsGeneral[0].mailingID, 1, null, MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                            <div className="col-lg-6 position-relative mt-2 mt-lg-0 SimplurisChangeRequestDiv">
                                                <label className="text-muted SimplurisFontWeight700 SimplurisFontSize12 SimplurisOpacity09"># Remails Allowed</label>
                                                <input type="text" defaultValue={MailingsGeneral[0].remailAllowed} className="form-control SimplurisMatrialTextBox" />
                                                <span className="SimplurisRequestChange SimplurisFontSize16 SimplurisFontWeight400">
                                                    <img src={Lock} className="position-absolute SimplurisCaseBankLockIconCaseBank SimplurisChangeRequestLinkIcon" />
                                                    <a onClick={() => OpenChangeRequestModalPopup(MailingsGeneral[0].remailAllowed, "dbo.Mailing", "ReMailAllowed", MailingsGeneral[0].mailingID, 1, null, MailingsGeneral[0].mailingID)} className="SimplurisBlueColor SimplurisChangeRequestLink">
                                                        Request change? </a>
                                                </span>
                                            </div>
                                        </div>
                                        {/*<div className="row">
                                            <div className="col-12 text-right">
                                                <button type="button" className="btn rounded-0 text-white SimplurisEggBlueBackGroudColor w btn-light py-3 mt-3">Save</button>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChangeRequestModalPopup OpenModalPopup={showChangeRequestModalPopup} setShowChangeRequestModalPopup={setShowChangeRequestModalPopup} AttributesValue={AttributesValue} caseID={caseID} caseName={caseName} personID={personID} setShowMessage={setShowMessage} caseManagerEmail={caseManagerEmail} />
                </>
            }
        </>
    )
}

//export default GeneralTab
export default connect(
    (state, props) => ({
        isFetchingMailingGeneral: getFetchingGeneral(state, props),
        MailingsGeneral: getMailingsGeneral(state, props),
        selectedMailingID: getSelectedMailingID(state, props)
    }),
    { fetchMailingsGeneral }
)(GeneralTab)