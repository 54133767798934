import { start, resolved } from 'state/helpers'
import { simplurisFetch } from 'helpers'

export const FETCH_HOME_LOGGEDIN_CASE_ACTIVITY = 'FETCH_HOME_LOGGEDIN_CASE_ACTIVITY'
export const ADD_HOME_LOGGEDIN_CASE_ACTIVITY_POST = 'ADD_HOME_LOGGEDIN_CASE_ACTIVITY_POST'

export const fetchHomeLoggedinCaseActivity = ({
	lastCaseId = 0,
	_pageSize = 10,
}) => async dispatch => {
    try {     
        
        dispatch({ type: start(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY) })        
        const url = `cases/welcomecardsmodality/1/${lastCaseId}/${_pageSize}`
        let { resultObject } = await simplurisFetch({ url })           
        
        let lastCaseIds = resultObject.length > 0 ? resultObject[resultObject.length - 1].caseActivityID : 0
        
        let isHasNewData = false;
        
        if (resultObject.length > _pageSize) {
            
            resultObject = resultObject.slice(0, -1)
            isHasNewData = true;
        }
        
		dispatch({
            type: resolved(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY),
            HomeLoggedinCaseActivity: {
                caseActivity: resultObject,
                lastCaseId: lastCaseIds,
                isHasNewData: isHasNewData
            }
		})
    } catch (e) {
        console.error('Error in HomeLoggedin Case activity', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_HOME_LOGGEDIN_CASE_ACTIVITY),
            HomeLoggedinCaseActivity: {
                caseActivity: [],
                lastCaseId: 0,
                isHasNewData: false
            }
        })
	}
}

export const addCaseActivityPostForHomeLoggedInScreen = post => ({
    type: ADD_HOME_LOGGEDIN_CASE_ACTIVITY_POST,
	post,
})
