import { simplurisFetch } from 'helpers'

export const FETCH_THREADS = 'FETCH_THREADS'
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES'
export const UPDATE_THREADS = 'UPDATE_THREADS'
export const GET_THREADS = 'GET_THREADS'
export const DELETE_THREAD = 'DELETE_THREAD'

//Fetch Message Threads
export const fetchThreads = ({ senderPlatformMapId, recipientPlatformMapId, caseID }) => async dispatch => {
    try {
        const url = `thread/${senderPlatformMapId}/${recipientPlatformMapId}/${caseID}`
        let { resultObject } = await simplurisFetch({ url })
        dispatch({
            type: FETCH_THREADS,
            threads: {
                threads: resultObject
            }
        })
    } catch (e) {
        console.error('Error in fetch threads', e, e.errorObject);

        dispatch({
            type: FETCH_THREADS,
            threads: [],
        })
    }
}

export const updateMessages = singlethread => ({
    type: UPDATE_MESSAGES,
    singlethread,
})


export const updateThreads = singlethread => ({
    type: UPDATE_THREADS,
    singlethread,
})

export const getThreadMessgaes = messages => ({
    type: GET_THREADS,
    messages,
})

export const deleteThread = threads => ({
    type: DELETE_THREAD,
    threads,
})
