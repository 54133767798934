import React from 'react'
import { Link, NavLink } from 'react-router-dom';

export default function Nav() {
    return <div className="collapse navbar-collapse order-2 mt-3 mt-lg-0 order-lg-1 SimplurisHomeLoggedHeader" id="navbarToggler">
        <ul className="navbar-nav mx-auto" data-nav="">            
            <li className="nav-item">
                <NavLink to={"/dashboard"} className="nav-link SimplurisNavLink">
                    <span className="nav-text">Welcome to Livecase</span>
                </NavLink>
            </li>
            
        </ul>
    </div>
}


//<li className="nav-item">
//    <a href={null} className="nav-link SimplurisNavLink" data-pjax-state="">
//        <span className="nav-text">Data Analysis</span>
//    </a>
//</li>
//    <li className="nav-item">
//        <a href={null} className="nav-link SimplurisNavLink" data-pjax-state="">
//            <span className="nav-text">Skip Trace</span>
//        </a>
//    </li>
//    <li className="nav-item">
//        <a href={null} className="nav-link SimplurisNavLink" data-pjax-state="">
//            <span className="nav-text">Ediscovery</span>
//        </a>
//    </li>
//    <li className="nav-item">
//        <a href={null} className="nav-link SimplurisNavLink" data-pjax-state="">
//            <span className="nav-text">Mailings</span>
//        </a>
//    </li>
