import { FETCH_ACCOUNT_REQUESTS} from './actions'
import { resolved } from 'state/helpers'

const initialState = {
    dateRange: {
        start: null,
        end: null,
    },
}

export default (state = initialState, { type, accountRequests, dateRange }) => {
    switch (type) {        
        case resolved(FETCH_ACCOUNT_REQUESTS): 
            state.dateRange.start = accountRequests.startDate;
            state.dateRange.end = accountRequests.endDate;
            return {
                ...state,                  
                accountRequests,
            }        
        default:
            return state
    }
}
