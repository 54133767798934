import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PdfReportViewer from './PdfReportViewer'
import ReportImage from '../../../../../static/img/report_inverse.png'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { IsNullOrEmptyString } from '../../../../../global'

const CaseReportDetailPage = ({ caseID, reportID, caseReportData, reportTitle, reportGeneratedDate, changeCaseSubtab }) => {
    if (!IsNullOrEmptyString(caseReportData)) {
        return (
            <div>
                <div className="d-flex flex row">
                    <div className="col-md-12 col-xs-12 col-sm-12">
                        <div className="card d-flex flex mb-0">
                            <div className="card-body SimpulrisMailingCardBody">
                                <table className="w-100 SimplurisCardHeaderTable">
                                    <tr>
                                        <td>
                                            <span className="SimplurisMailingsEnvelopeSqure r avatar">
                                                <img src={ReportImage} className="SimplurisMailingsEnvelopeImage" />
                                            </span>
                                        </td>
                                        <td>
                                            <div className="SimplurisMailingTitle">
                                                {reportTitle}
                                            </div>
                                            <div className="SimplurisMailingSubTitle">
                                                {localStorage.getItem(global.caseOfficialName)}
                                            </div>
                                            <div className="SimplurisMailingSubTitle">
                                                {new Intl.DateTimeFormat('en-GB', {
                                                    month: 'long'
                                                }).format(new Date(reportGeneratedDate))} {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(reportGeneratedDate))}, {new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(new Date(reportGeneratedDate))}
                                            </div>
                                        </td>
                                        <td>

                                            <a className="nav-link btnRunReport"
                                                id="pdfreport"
                                                href={caseReportData}
                                                target="_blank"
                                                download>Download</a>
                                        </td>
                                    </tr>
                                </table>
                                {/* <div className="row">
                                    <div className="col-2 col-xl-1 pl-0 px-xl-0 px-lg-2">
                                        <span className="SimplurisMailingsEnvelopeSqure r avatar">
                                            <img src={ReportImage} className="SimplurisMailingsEnvelopeImage" />
                                        </span>
                                    </div>
                                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 align-self-center pl-xl-4 pl-lg-3 pl-md-3 SimplurisPaddingLeft23">
                                        <div className="SimplurisMailingTitle">
                                            {reportTitle}
                                        </div>
                                        <div className="SimplurisMailingSubTitle">
                                            {localStorage.getItem(global.caseOfficialName)}
                                        </div>
                                        <div className="SimplurisMailingSubTitle">
                                            {new Intl.DateTimeFormat('en-GB', {
                                                month: 'long'
                                            }).format(new Date(reportGeneratedDate))} {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(reportGeneratedDate))}, {new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(new Date(reportGeneratedDate))}
                                        </div>
                                        <a className="nav-link SimplurisDownloadPDFReportButton btnRunReport btnDownloadReport"
                                            id="pdfreport"
                                            href={caseReportData}
                                            target="_blank"
                                            download>Download</a>
                                    </div>
                                </div>*/}

                            </div>

                        </div>
                    </div>
                </div>
                <div class="my-2 px-3">

                </div>


                <PdfReportViewer PDFURL={caseReportData} />

            </div>
        )
    }
    else {
        changeCaseSubtab('reports');
        return <></>;
    }

}

//export default CaseReportDetailPage

export default connect(
    (state, props) => ({
    }), { changeCaseSubtab })(CaseReportDetailPage)
