import { combineReducers } from 'redux'

import history from './history/reducer'
import other from './other/reducer'

// Other should have originally been split into separate reducers, but in a hurry at the moment
// so that should happen in the future when you read this
export default combineReducers({
  history,
  other,
})
