import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { SectionSpinner } from 'react/components/spinners'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { setPreviousCaseSubtab } from 'state/previous-tab/actions'
import { changeCaseSubtab } from 'state/case-detail-view/subtab/actions'
import { setFilteredIDs } from 'state/case-detail-view/distributions/actions'
import SecureFileImage from '../../../../../static/img/securefile_icon.png'
import GridViewImage from '../../../../../static/img/grid-view.png'
import ListViewImage from '../../../../../static/img/list-view.png'
import { IsNullOrEmptyString, AnlyticsPageLog } from '../../../../../global'
import FilesTable from './files-table'
import FilesList from './files-list'
import SecureFileUploadModalPopup from './secure-file-upload'
import { fetchCaseFilesData, resetCaseFilesData, fetchCaseDataCounts } from 'state/case-file/actions'
import { simplurisFetch } from 'helpers'
import { Redirect } from 'react-router-dom'

const CaseFiles = ({
    caseID,
    userPermissions,
    getSecureFileDetailComponent,
    fetchCaseFilesData,
    resetCaseFilesData,
    caseFilesData,
    fetchCaseDataCounts,
    updateSidebarMenuCounts
}) => {

    const [showSecureFileUploadModalPopup, setShowSecureFileUploadModalPopup] = useState(false);
    const [fileCount, setFileCount] = useState(0)
    const [searchValue, setSearchValue] = useState(null);
    const [dateSortOrder, setDateSortOrder] = useState(null);
    const [typeSortOrder, setTypeSortOrder] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('Date');
    const [fileFlag, setFileFlag] = useState('My Files');
    const [dateSortActive, setDateSortActive] = useState(false);
    const [typeSortActive, setTypeSortActive] = useState(false);
    let localStorageSecureFileURL = localStorage.getItem(global.SecureFileURL);
    const [caseFileDetail, setCaseFileDetail] = useState([]);

    var viewFilePermission
    var sendFilePermission

    if (Object.keys(userPermissions).length > 0) {
        viewFilePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_FILES_VIEW'
        )

        sendFilePermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_FILES_SEND'
        )
    }

    const getSecureFileCount = function (Count) {
        setFileCount(Count)
    }

    function onSearchChange(searchValue) {
        resetCaseFilesData(caseID);
        if (searchValue == "") {
            searchValue = null;
        }
        setSearchValue(searchValue);
    }

    const onSortChange = (sortColumn) => {
        
        resetCaseFilesData(caseID);
        let sortOrder;
        if (sortColumn == "Date") {
            
            if (dateSortOrder == "asc") {
                
                setDateSortOrder('desc');
                sortOrder = 'desc';
            } else {
                
                setDateSortOrder('asc');
                sortOrder = 'asc';
            }
            
            setDateSortActive(true);
            setTypeSortActive(false);
        }
        else {
            
            if (typeSortOrder == "asc") {
                
                setTypeSortOrder('desc');
                sortOrder = 'desc';
            } else {
                
                setTypeSortOrder('asc');
                sortOrder = 'asc';
            }
            
            setTypeSortActive(true);
            setDateSortActive(false);
        }
        setSortColumn(sortColumn);
        fetchCaseFilesData({
            caseID,
            sortColumn,
            sortOrder,
            fileFlag,
            searchValue,
        });
    }

    const onTabChange = (tab) => {
        setTypeSortOrder('')
        setDateSortOrder('')
        resetCaseFilesData(caseID);
        var fileFlag = tab;
        fetchCaseFilesData({
            caseID,
            sortColumn,
            sortOrder,
            fileFlag,
            searchValue,
        });
        setFileFlag(tab);
    }

    useEffect(() => {
        try {

            if (!IsNullOrEmptyString(localStorageSecureFileURL)) {
                simplurisFetch({
                    url: `cases/${parseInt(caseID)}/securefiledetailfordownload/${localStorage.getItem(global.SecureFileIDForSecureFile)}`
                }).then(result => {                    
                    if (result.resultCode == "SUCCESS") {
                        getSecureFileDetailComponent(caseID, localStorage.getItem(global.SecureFileIDForSecureFile), result.resultObject);
                    }
                }).catch(e => {
                    if (e.errorObject.resultCode == "ERROR") {
                        localStorage.setItem(global.SecureFileURL, "");
                        window.location.href = "/not-allowed-to-case-secure-file";
                        //<Redirect to="/not-allowed-to-case-secure-file" />
                    }

                    console.error("Error in case files ", e, e.errorObject);
                });
            }
            else {
                setFileFlag('My Files');
            }


            fetchCaseFilesData({
                caseID,
                sortColumn,
                sortOrder,
                fileFlag,
                searchValue
            });

        } catch (e) {
            console.error('New Error : ' + e)
        }
    }, [fetchCaseFilesData, caseID, searchValue])

    function OpenSecureFileUploadModalPopup() {
        AnlyticsPageLog("Click on secure file upload Modal Popup from files tab");
        setShowSecureFileUploadModalPopup(true);
    }

    return (
        <div>
            <div className="d-flex flex row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                    <div className="card d-flex flex mb-0">
                        <div className="card-body SimpulrisCardBody">

                            <table className="w-100 SimplurisCardHeaderTable">
                                <tr>
                                    <td>
                                        <span className="SimplurisCaseFilesSquareWhite r avatar">
                                            <img src={SecureFileImage} className="SimplurisMailingsEnvelopeImage" />
                                        </span>
                                    </td>
                                    <td>
                                        <div className="SimplurisMailingTitle">
                                            Files
                                                    </div>
                                        <div className="SimplurisMailingSubTitle">
                                            {fileCount} Shared File
                                                    </div>
                                    </td>
                                    <td>
                                        {sendFilePermission && sendFilePermission.length > 0 && <input type="button" onClick={() => OpenSecureFileUploadModalPopup()} className="btn btnShareFile" value="Share File" data-toggle="modal" data-target="#SelectShareFiles" data-toggle-class="fade-down" data-toggle-class-target="#animate" />}
                                    </td>
                                </tr>
                            </table>

                            <div className="row d-none">
                                <div className="">
                                    <span className="SimplurisCaseFilesSquareWhite r avatar">
                                        <img src={SecureFileImage} className="SimplurisMailingsEnvelopeImage" />
                                    </span>

                                </div>
                                <div className="col-md-10 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0">
                                    <div className="SimplurisMailingTitle">
                                        Files
                                                    </div>
                                    <div className="SimplurisMailingSubTitle">
                                        {fileCount} Shared File
                                                    </div>

                                    {sendFilePermission && sendFilePermission.length > 0 && <input type="button" onClick={() => OpenSecureFileUploadModalPopup()} className="btn btnRunReport" value="Share File" data-toggle="modal" data-target="#SelectShareFiles" data-toggle-class="fade-down" data-toggle-class-target="#animate" />}
                                </div>
                            </div>
                        </div>
                        <div className="px-3 pt-3 pb-3 b-t">
                            <ul className="nav border-0 d-inline-flex" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link SimplurisReportLink " id="allfiles-tab"
                                        data-toggle="tab" href="#allfiles" role="tab" aria-controls="allfiles"
                                        aria-selected="true"
                                        onClick={() => onTabChange('All Files')}
                                    >All Files</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link SimplurisReportLink active show" id="myfiles-tab"
                                        data-toggle="tab" href="#myfiles" role="tab" aria-controls="myfiles"
                                        aria-selected="false"
                                        onClick={() => onTabChange('My Files')}
                                    >My Files</a>
                                </li>
                            </ul>

                            <div className="d-inline-block position-relative float-left float-md-right">
                                <input type="text" name="txtSearchCaseBank" id="txtSearchCaseBank"
                                    onChange={e => (setSearchValue(e.target.value), onSearchChange(e.target.value))}
                                    className="form-control SimplurisCaseBanktxtSearchCaseBank" />
                                <i className="mr-2 i-con i-con-search position-absolute SimplurisCaseBankSearchIconCaseBank"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* START All Files - My Files View Tabs */}
            <div className="tab-content">
                {(
                    () => {
                        if (!IsNullOrEmptyString(fileFlag)) {
                            switch (fileFlag) {
                                case 'My Files':
                                    return (
                                        <>
                                            {/* START (MY-FILES) List-Grid View Tabs */}
                                            <div className="d-flex flex row">
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <div className="row my-2 px-3 justify-content-center align-items-center">
                                                        <div className="col-md-6 col-8 col-sm-6">
                                                            <ul className="nav border-0 d-inline-flex" role="tablist">
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (dateSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Date')}>
                                                                        <div className={"th-inner sortable both " + dateSortOrder}>
                                                                            Date
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (typeSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Type')}
                                                                    >
                                                                        <div className={"th-inner sortable both " + typeSortOrder}>
                                                                            Type
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 col-4 col-sm-6 text-right">
                                                            <ul className="nav border-0 d-inline-flex float-md-right ml-3 ml-md-0">
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink active show"
                                                                        id="fileList-tab" data-toggle="tab" href="#fileList" role="tab"
                                                                        aria-controls="fileList" aria-selected="true">
                                                                        <i className="mr-2 i-con i-con-list"><i></i></i>
                                                                        {/* <img src={ListViewImage} className="p-2" /> */}
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink"
                                                                        id="fileGrid-tab" data-toggle="tab" href="#fileGrid" role="tab"
                                                                        aria-controls="fileGrid" aria-selected="false">
                                                                        <i className="i-con i-con-grid"><i></i></i>
                                                                        {/* <img src={GridViewImage} className="p-1" /> */}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id="fileList" role="tabpanel" aria-labelledby="fileList-tab">
                                                    <FilesTable
                                                        caseID={caseID}
                                                        getSecureFileDetailComponent={getSecureFileDetailComponent}
                                                        getSecureFileCount={getSecureFileCount}
                                                        viewFilePermission={viewFilePermission}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="fileGrid" role="tabpanel" aria-labelledby="fileGrid-tab">
                                                    <FilesList
                                                        caseID={caseID}
                                                        getSecureFileDetailComponent={getSecureFileDetailComponent}
                                                        getSecureFileCount={getSecureFileCount}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                    break;
                                case 'All Files':
                                    return (
                                        <>
                                            {/* START (ALL-FILES) List-Grid View Tabs */}
                                            <div className="d-flex flex row">
                                                <div className="col-md-12 col-xs-12 col-sm-12">
                                                    <div className="row my-2 px-3 justify-content-center align-items-center">
                                                        <div className="col-md-6 col-8 col-sm-6">
                                                            <ul className="nav border-0 d-inline-flex" role="tablist">
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (dateSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Date')}>
                                                                        <div className={"th-inner sortable both " + dateSortOrder}>
                                                                            Date
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className={"nav-link SimplurisReportSubLink text-uppercase " + (typeSortActive == true ? 'active' : '')}
                                                                        onClick={() => onSortChange('Type')}
                                                                    >
                                                                        <div className={"th-inner sortable both " + typeSortOrder}>
                                                                            Type
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 col-4 col-sm-6 text-right">
                                                            <ul className="nav border-0 d-inline-flex float-md-right ml-3 ml-md-0">
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink active show"
                                                                        id="fileList1-tab" data-toggle="tab" href="#fileList1" role="tab"
                                                                        aria-controls="fileList1" aria-selected="true">
                                                                        <i className="mr-2 i-con i-con-list"><i></i></i>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link-sub SimplurisReportSubLink"
                                                                        id="fileGrid1-tab" data-toggle="tab" href="#fileGrid1" role="tab"
                                                                        aria-controls="fileGrid1" aria-selected="false">
                                                                        <i className="i-con i-con-grid"><i></i></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id="fileList1" role="tabpanel" aria-labelledby="fileList1-tab">
                                                    <FilesTable
                                                        caseID={caseID}
                                                        getSecureFileDetailComponent={getSecureFileDetailComponent}
                                                        getSecureFileCount={getSecureFileCount}
                                                        viewFilePermission={viewFilePermission}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="fileGrid1" role="tabpanel" aria-labelledby="fileGrid1-tab">
                                                    <FilesList
                                                        caseID={caseID}
                                                        getSecureFileDetailComponent={getSecureFileDetailComponent}
                                                        getSecureFileCount={getSecureFileCount}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                    break;
                            }
                        } else {
                            return <></>
                        }
                    }
                )()}
            </div>


            <SecureFileUploadModalPopup
                OpenModalPopup={showSecureFileUploadModalPopup}
                setShowSecureFileUploadModalPopup={setShowSecureFileUploadModalPopup}
                caseID={caseID}
                fetchCaseDataCounts={fetchCaseDataCounts}
                updateSidebarMenuCounts={updateSidebarMenuCounts}
            />
        </div>
    )
}

export default connect(
    (state, props) => ({
        userPermissions: getPermissionsForCase(state, props),
        previousCaseSubtabs: state.previousSubTab.subTabNames,
        caseFilesData: state.caseFiles[props.caseID],
    }),
    {
        changeCaseSubtab,
        setFilteredIDs,
        setPreviousCaseSubtab,
        fetchCaseFilesData,
        resetCaseFilesData,
        fetchCaseDataCounts
    }
)(CaseFiles)