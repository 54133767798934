import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import MailingActivity from './activity'
import Envelope from '../../../../../static/img/envelope-upload.png'
import MailingRecipients from './recipients'
import Documents from './documents'
import GeneralTab from './general'
import { getSelectedMailing } from 'state/mailings/other/selectors'
import { getPermissionsForCase } from 'state/permissions/selectors'
import { AnlyticsPageLog } from "../../../../../global";


function CaseMailingsData({ caseID, selectedMailing, data, userPermissions }) {
    var viewMailingIdPermission
    if (Object.keys(userPermissions).length > 0) {
        viewMailingIdPermission = userPermissions.find(
            (permissionCode) => permissionCode === 'LC_MAILTRACKING_VIEWDATA_MAILINGID'
        )
    }

    useEffect(() => {
        AnlyticsPageLog("Mailing page");
    }, [])

    const [mailingTypeTitle, setMailingTypeTitle] = useState('')
    const [mailingTitlePrefixTitle, setMailingTitlePrefixTitle] = useState('')
    const [mailingTitle, setMailingTitle] = useState('')
    const [isFirstClickActivity, setIsFirstClickActivity] = useState(false);
    const [isFirstClickActivityCount, setIsFirstClickActivityCount] = useState(1);

    const [selectedTab, setSelectedTab] = useState('general')
    const setCurrentSelectedTab = tab => {        
        if (tab == 'activity' && isFirstClickActivityCount == 1) {            
            setIsFirstClickActivity(true)
            setIsFirstClickActivityCount(1);
        }
        if (tab != 'activity') {            
            setIsFirstClickActivity(false)
            setIsFirstClickActivityCount((isFirstClickActivityCount + 1));
        }
        setSelectedTab(tab)
    }
    return (
        <div>
            <div className="d-flex flex row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                    <div className="card d-flex flex">
                        <div className="card-body SimpulrisMailingCardBody">
                            <div className="row">
                                <div className="pl-3">
                                    <span className="SimplurisMailingsEnvelopeSqure r avatar bg-primary-lt">
                                        <img
                                            src={Envelope}
                                            className="SimplurisMailingsEnvelopeImage"
                                        />
                                    </span>
                                </div>
                                <div className="col-md-9 col-sm-12 col-xs-12 align-self-center pt-3 pt-lg-0">
                                    {viewMailingIdPermission &&
                                        viewMailingIdPermission.length > 0 ? (
                                            <>
                                                {' '}
                                                <div className="SimplurisMailingTitle">
                                                        {mailingTitlePrefixTitle} {!mailingTitle || '-'} {mailingTitle}
                                                </div>
                                                <div className="SimplurisMailingSubTitle">
                                                    {mailingTypeTitle}
                                                </div>{' '}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="px-3 pt-3 pb-3 b-t">
                            <ul className="nav border-0" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className="nav-link SimplurisFontSize14 SimplurisFontWeight400 active show"
                                        id="general-tab"
                                        data-toggle="tab"
                                        href="#general"
                                        role="tab"
                                        onClick={() => setCurrentSelectedTab('general')}
                                        aria-controls="general"
                                        aria-selected="false"
                                    >
                                        General
                  </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link SimplurisFontSize14 SimplurisFontWeight400"
                                        id="documents-tab"
                                        data-toggle="tab"
                                        href="#documents"
                                        onClick={() => setCurrentSelectedTab('documents')}
                                        role="tab"
                                        aria-controls="documents"
                                        aria-selected="true"
                                    >
                                        Documents
                  </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link SimplurisFontSize14 SimplurisFontWeight400"
                                        onClick={() => setCurrentSelectedTab('activity')}
                                        href="#activity"
                                        id="activity-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="activity"
                                        aria-selected="false"
                                    >
                                        Activity
                  </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link SimplurisFontSize14 SimplurisFontWeight400"
                                        onClick={() => setCurrentSelectedTab('recipients')}
                                        href="#recipients"
                                        id="recipients-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="recipients"
                                        aria-selected="false"
                                    >
                                        Recipients
                  </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content">
                {(() => {
                    switch (selectedTab) {
                        case 'general':
                            return (
                                <div
                                    className="tab-pane fade active show"
                                    id="general"
                                    role="tabpanel"
                                    aria-labelledby="general-tab"
                                >
                                    <GeneralTab
                                        data={selectedMailing}
                                        setMailingTypeTitle={setMailingTypeTitle}
                                        setMailingTitlePrefixTitle={setMailingTitlePrefixTitle}
                                        setMailingTitle={setMailingTitle}
                                        caseID={caseID}
                                        caseName={data && data.name}
                                        personID={data && data.caseManagerPersonID}
                                        caseManagerEmail={data && data.personEmail}
                                    />
                                </div>
                            )
                            break
                        case 'activity':
                            return (
                                <div
                                    className="tab-pane fade active show"
                                    id="activity"
                                    role="tabpanel"
                                    aria-labelledby="activity-tab"
                                >
                                    <MailingActivity data={selectedMailing} caseID={caseID} isFirstClickActivity={isFirstClickActivity}/>
                                </div>
                            )
                        case 'recipients':
                            return (
                                <div
                                    className="tab-pane fade active show"
                                    id="recipients"
                                    role="tabpanel"
                                    aria-labelledby="recipients-tab"
                                >
                                    <MailingRecipients data={selectedMailing} CaseID={caseID} />
                                </div>
                            )
                        case 'documents':
                            return (
                                <div
                                    className="tab-pane fade active show"
                                    id="documents"
                                    role="tabpanel"
                                    aria-labelledby="documents-tab"
                                >
                                    <Documents data={selectedMailing} caseID={caseID} />
                                </div>
                            )
                        default:
                    }
                })()}
            </div>
        </div>
    )
}

export default connect((state, props) => ({
    selectedMailing: getSelectedMailing(state, props),
    userPermissions: getPermissionsForCase(state, props),
}))(CaseMailingsData)
