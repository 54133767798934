import { start, resolved } from 'state/helpers'
import { simplurisFetch } from 'helpers'

export const FETCH_TEAM_MEMBER_DETAIL = 'FETCH_TEAM_MEMBER_DETAIL'
export const ADD_FETCH_TEAM_MEMBER_DETAIL_ACTIVITY_POST = 'ADD_FETCH_TEAM_MEMBER_DETAIL_ACTIVITY_POST'

export const fetchTeamMemberDetail = ({
    caseID,
    personID,
    lastActivityID,
    pageSize
}) => async dispatch => {
    try {
        
        dispatch({ type: start(FETCH_TEAM_MEMBER_DETAIL) })
        const url = `cases/${parseInt(caseID)}/teammembers/${personID}/${lastActivityID}/${pageSize}`
        let { resultObject } = await simplurisFetch({ url })        
        let lastCaseIds = resultObject.activityEntries != undefined && resultObject.activityEntries.length > 0 ? resultObject.activityEntries[resultObject.activityEntries.length - 1].caseActivityID : 0        
        let isHasNewData = false;        
        if (resultObject.activityEntries != undefined && resultObject.activityEntries.length > pageSize) {
            resultObject.activityEntries = resultObject.activityEntries.slice(0, -1)
            isHasNewData = true;
        }        
        dispatch({
            type: resolved(FETCH_TEAM_MEMBER_DETAIL),
            memberDetails: resultObject,
            lastCaseId: lastCaseIds,
            isHasNewData: isHasNewData,
            personID: personID,
        })
    } catch (e) {
        console.error('Error in Team Member Detail', e, e.errorObject)

        dispatch({
            type: resolved(FETCH_TEAM_MEMBER_DETAIL),
            TeamMemeberDetails: {
                memberDetails: [],
                lastCaseId: 0,
                isHasNewData: false
            }
        })
    }
}

export const addCaseActivityPostForTeamMemberDetailScreen = (post, teamMemberDetailPersonID )=> ({
    type: ADD_FETCH_TEAM_MEMBER_DETAIL_ACTIVITY_POST,
    post,
    teamMemberDetailPersonID
})
