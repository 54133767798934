import React from 'react'
import ContentLoader from 'react-content-loader'

const SquareFakeLoader = () => (
    <ContentLoader
        height={50}
        width={200}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb" >
        <rect x="50" y="22" rx="4" ry="4" width="85" height="5" />
        <rect x="50" y="33" rx="3" ry="3" width="110" height="5" />
        <rect x="15" y="15" rx="0" ry="0" width="30" height="30" />
    </ContentLoader>
)

export default SquareFakeLoader
